import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import { Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import * as employeeService from "../../../services/employeeService";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import { useForm, Form } from "../../../components/useForm";
import "../styles/filter-box.css";
import { Api } from "../../../api/Api";
import { COMAPNY_API } from "../../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_INACTIVE_CAP_SMALL,
} from "../../../constants/AppConstants";

const initialFValues = {
  country: "",
  category: "",
  status: "",
};

export default function SupplierFilter(props) {
  const {
    setSuppliersList,
    countries,
    dropdownSupplierCategory,
    showFilterBox,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      if (i == 0) {
        string += `?${key}=${value}`;
      } else {
        string += `&${key}=${value}`;
      }
      //
      i++;
    }
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;
    localStorage.setItem("supplierFilter", JSON.stringify(values));
    const response = await Api(
      `${COMAPNY_API}/${companyId}/suppliers${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let suppliersList = response.data.suppliers;
        setSuppliersList(suppliersList);
        showFilterBox(false);
      } else {
        setSuppliersList([]);
        showFilterBox(false);
      }
    } else {
      setSuppliersList([]);
      showFilterBox(false);
    }

    // await axiosInstance
    //   .get(`api/buyer/company/${companyId}/suppliers${string}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let suppliersList = response.data.suppliers;
    //       setSuppliersList(suppliersList);
    //       showFilterBox(false);
    //     } else {
    //       setSuppliersList([]);
    //       showFilterBox(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setSuppliersList([]);
    //     showFilterBox(false);
    //   });
  };

  const clearFilter = async () => {
    resetForm();
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;
    const response = await Api(
      `${COMAPNY_API}/${companyId}/suppliers`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let suppliersList = response.data.suppliers;
        setSuppliersList(suppliersList);
        showFilterBox(false);
        localStorage.removeItem("supplierFilter");
      } else {
        setSuppliersList([]);
      }
    } else {
      setSuppliersList([]);
    }

    // await axiosInstance
    //   .get(`api/buyer/company/${companyId}/suppliers`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let suppliersList = response.data.suppliers;
    //       setSuppliersList(suppliersList);
    //       showFilterBox(false);
    //       localStorage.removeItem("supplierFilter");
    //     } else {
    //       setSuppliersList([]);
    //     }
    //   })
    //   .catch((err) => {
    //     setSuppliersList([]);
    //   });
  };

  React.useEffect(() => {
    const filterData = localStorage.getItem("supplierFilter");

    if (filterData) {
      const filterObj = JSON.parse(filterData);
      setValues(filterObj);
    }
  }, []);

  return (
    // Block Filter
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item md={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <InputLabel className="mui-label" id="model-filter-input">
                Country
              </InputLabel>
              <Controls.Select
                labelId="position-input"
                name="country"
                value={values.country}
                autoWidth
                onChange={handleInputChange}
                options={countries}
                className="w-100 b-600 ml-0"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <InputLabel className="mui-label" id="manufacturer-filter-input">
                Category
              </InputLabel>
              <Controls.Select
                labelId="position-input"
                name="category"
                value={values.category}
                autoWidth
                onChange={handleInputChange}
                options={dropdownSupplierCategory}
                className="w-100 b-600 ml-0"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <InputLabel className="mui-label" id="status-filter-input">
                Status
              </InputLabel>
              <Controls.Select
                labelId="status-filter-input"
                name="status"
                value={values.status}
                onChange={handleInputChange}
                autoWidth
                options={[
                  { name: AUCTION_ACTIVE, _id: AUCTION_ACTIVE },
                  {
                    name: AUCTION_INACTIVE_CAP_SMALL,
                    _id: AUCTION_INACTIVE_CAP_SMALL,
                  },
                ]}
                className="w-100 b-600 ml-0"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Controls.Button
                text="Clear"
                disableElevation
                color="default"
                className="btn draft btn-mui w-100 "
                onClick={() => clearFilter()}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controls.Button
                type="submit"
                disableElevation
                text="Apply Filter"
                className="btn-mui w-100 "
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}
