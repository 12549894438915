import React, { useEffect } from "react";
import { Typography, Button, Grid, CssBaseline } from "@mui/material";
import { Link } from "react-router-dom";
import faceLogo from "../../assets/auth/Logo_Oktio_last.png";
import { email_verification_tost } from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";

const EmailVerificationToast = () => {
  const emaiVerify = useSelector((state) => state.auth.emaiVerify);

  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);

  const token = queryParams.get("token");

  useEffect(() => {
    dispatch(email_verification_tost(token));
  }, [token, dispatch]);

  return (
    <div className="auth-box-container" style={{ width: "100%" }}>
      <CssBaseline />
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={8}
          xs={11}
          style={{ textAlign: "-webkit-center" }}
        >
          <Grid
            item
            sm={12}
            xl={12}
            lg={12}
            md={12}
            style={{ textAlign: "-webkit-center" }}
          >
            <div className="success-page-logo">
              <img src={faceLogo} alt="Logo" />
            </div>
          </Grid>
          <Grid container justifyContent="center" alignItems="end">
            <Grid item sm={12} xl={12} lg={12} md={12}>
              <div className="form-box-container text-center">
                <Typography variant="h6" gutterBottom>
                  {emaiVerify}
                </Typography>

                <Link to="/signin">
                  <Button variant="contained" color="primary">
                    Go to Login
                  </Button>
                </Link>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmailVerificationToast;
