import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Form } from "../../../components/useForm";
import FormInput from "../../../components/FormComponents/FormInput";
import { MESSAGE_TITLE_REQUIRED } from "../../../constants/Rules";
import Controls from "../../../components/controls/Controls";
import { Api } from "../../../api/Api";
import { POST_CREATE_MESSAGE_API } from "../../../constants/ApiUrl";
import { store } from "../../../store/store";
import { useSelector } from "react-redux";

const CreateMessageModal = ({
  open,
  handleClose,
  getMessageList,
  setShowMessageSidebar,
  setMessageId,
}) => {
  const rfxSupplierDetails = useSelector((state) => state.supplierRfx.rfx);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const createMessageHanlder = async (values) => {
    const { auth } = store.getState();

    const companyId = auth?.auth?.user?.company?._id;
    const UserId = auth?.auth?.user?._id;

    const payload = {
      rfxId: rfxSupplierDetails?._id,
      message: values.message,
      companyId,
      userId: UserId,
    };

    const response = await Api(POST_CREATE_MESSAGE_API, payload, "post");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        handleClose();
        getMessageList();
        setMessageId(response?.data?.rfxMessage?._id);
        setShowMessageSidebar(true);
      }
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="popup-form-container">
        <Grid container>
          <Grid item xs={9}>
            <p className="profile-title"> Add Message</p>
          </Grid>
          <Grid item xs={3} className="text-end">
            <CloseIcon
              onClick={() => {
                handleClose();
              }}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        <Form onSubmit={handleSubmit(createMessageHanlder)} className="mt-5">
          <FormInput
            name="message"
            id="message"
            register={register}
            rules={MESSAGE_TITLE_REQUIRED}
            errors={errors}
            inputLabel="Title"
          />
          <Controls.Button
            type="submit"
            disableElevation
            text="Save"
            className="btn-mui w-100 mt-3"
          />
        </Form>
      </Box>
    </Modal>
  );
};

export default CreateMessageModal;
