import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Search from "@material-ui/icons/Search";
import UserFilter from "./filters/userFilter";
import UserTable from "./userTable";
import { useForm, Form } from "../../components/useForm";
import { store } from "../../store/store";
import Notification from "../../components/Notification";
import Modal from "@mui/material/Modal";
import Loader from "../../components/Loader";
import { InputLabel } from "@mui/material";
import "./styles/form.css";
import { Api } from "../../api/Api";
import { ONLY_COMAPNY_API } from "../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "100%",
    border: "0px solid #ccc",
    [`& fieldset`]: {
      borderRadius: 6,
    },
  },
  selectAction: {
    "& .MuiInputLabel-root": {
      display: "none",
    },
  },
  toolbars: {
    "& .MuiToolbar-gutters": {
      padding: "12px",
      margin: "0px",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },

  // Forms

  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    "& .MuiDialogTitle-root": {
      padding: "1px",
    },
  },
  dialogTitle: {
    paddingRight: "0px",
    fontWeight: "600px",
  },
  dialog: {
    "& .MuiDialogContent-dividers ": {
      borderTop: "0px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "0px solid rgba(0, 0, 0, 0.12)",
    },
  },

  sectionText: {
    fontWeight: "600",
    fontSize: "24px",
  },
  // Header

  root: {
    backgroundColor: "transparent",
  },
  wrapper: {
    borderRadius: "12px",
    padding: "0 12px",
    backgroundColor: "red",
  },
  pageHeader: {
    padding: theme.spacing(1),
    display: "flex",
    marginBottom: theme.spacing(0),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#2170ff",
  },
  pname: { color: theme.palette.primary.main, fontWeight: "bold" },
  pdesc: { opacity: "0.5" },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  pageTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  warning: { backgroundColor: theme.palette.primary.warning },
  actionbtnRight: {
    position: "relative",
    top: 10,
    right: 10,
  },
  menupos: {},
  filterDiv: {
    width: "100%",
    padding: "1rem",
    margin: ".3rem",
  },
  searchBar: {
    width: "100%",
    margin: "1rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  sort: {
    display: "flex",
  },
  switchToggle: {
    backgroundColor: "red",
  },
}));

const getNewUserRole = [
  { id: "seller", title: "Seller" },
  { id: "buyer", title: "Buyer" },
  { id: "both", title: "Buyer / Seller" },
];

const initialFValues = {
  first_name: "",
  last_name: "",
  email: "",
  position: "",
  role: "",
  // is_admin: ""
};

const UserList = () => {
  const classes = useStyles();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [dropDownVals, setDropDownVals] = React.useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [newUserAsAdmin, setNewUserAsAdmin] = useState(false);
  const [isEditRecord, setIsEditRecord] = useState(false);
  const [editUserId, setEditUserId] = useState(undefined);
  const [userList, setUserList] = useState([]);
  const [fullLoder, setFullLoder] = useState(false);
  const [tablePagination, setTablePagination] = useState(10);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name ? "" : "First Name is required.";
    if ("last_name" in fieldValues)
      temp.last_name = fieldValues.last_name ? "" : "Last Name is required.";
    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "Email is required.";
    if ("position" in fieldValues)
      temp.position = fieldValues.position ? "" : "Position is required.";
    if ("role" in fieldValues)
      temp.role = fieldValues.role ? "" : "Role is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const adminSwichHandle = () => {
    if (newUserAsAdmin) {
      setNewUserAsAdmin(false);
    } else if (!newUserAsAdmin) {
      setNewUserAsAdmin(true);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    const { auth } = store.getState();
    const companyName = auth?.auth?.user?.company?.company_name;
    const companyId = auth?.auth?.user?.company?._id;
    e.preventDefault();
    if (validate()) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
        const paylod = {
          first_name: values.first_name,
          last_name: values.last_name,
          role: values.role,
          position: values.position,
          company_name: companyId,
          // company : companyId,
          email: values.email,
          // isAdmin: newUserAsAdmin,
          isSeller:
            values.role === "seller"
              ? true
              : values.role === "both"
              ? true
              : false,
          isBuyer:
            values.role === "buyer"
              ? true
              : values.role === "both"
              ? true
              : false,
        };
        if (!isEditRecord) {
          const response = await Api(
            `${ONLY_COMAPNY_API}/${companyId}/addMember`,
            paylod,
            "post"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setNewUserAsAdmin(false);
              resetForm();
              setOpenPopup(false);
              setIsEditRecord(false);
              getAllUser();
            }
          }
        } else {
          paylod.status = "Inactive";
          const response = await Api(
            `${ONLY_COMAPNY_API}/${companyId}/editMember/${editUserId}`,
            paylod,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setNewUserAsAdmin(false);
              resetForm();
              setOpenPopup(false);
              setIsEditRecord(false);
              getAllUser();
            }
          }
        }
      } else {
        toast.error(ValidationMessage.validEmail, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };
  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const clickClosePopup = () => {
    setOpenPopup(false);
    resetForm();
    setIsEditRecord(false);
  };

  const getAllUser = async (searchText) => {
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText.replace(/ +/g, "")}`;
    }
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      setFullLoder(true);
      const response = await Api(
        `${ONLY_COMAPNY_API}/${companyId}/members${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let users = response.data.companyTeamMembers;
          setUserList(users);
          setTimeout(() => {
            setFullLoder(false);
          }, 300);
        } else {
          setUserList([]);
          setTimeout(() => {
            setFullLoder(false);
          }, 300);
        }
      } else {
        setUserList([]);
        setTimeout(() => {
          setFullLoder(false);
        }, 300);
      }
    }
  };

  const requestSearch = (e) => {
    const searchText = e.target.value;
    getAllUser(searchText);
  };

  useEffect(() => {
    getAllUser();
  }, []);

  return (
    <>
      <Paper elevation={0} square className={classes.root}>
        <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
        <Grid container className="filter-table-header">
          <Grid item md={4} sm={5} xs={12}>
            <div className="title"> Company Users</div>
          </Grid>
          <Grid item md={8} sm={7} xs={12} className="buttons-box">
            <Controls.Button
              variant="contained"
              className={
                "btn btn-white btn-mui" +
                (showFilterCategories ? " active" : "")
              }
              text="Filter"
              onClick={toggleFilterCategory}
              startIcon={<FilterListSharpIcon style={{ color: "#2170FF" }} />}
            />
            <Controls.Button
              text="Add User"
              className="btn-mui"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenPopup(true);
                // setRecordForEdit(null);
              }}
            />
          </Grid>
        </Grid>
        <Grid container className="filter-box-container">
          {showFilterCategories && (
            <Paper elevation={1} className="filter-box">
              <UserFilter
                setUserList={setUserList}
                setShowFilterCategories={setShowFilterCategories}
              />
            </Paper>
          )}
        </Grid>
      </Paper>
      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Grid xs={12}>
            <Box sx={{ display: "inline" }}>
              <TextField
                // onChange={getSearchString}
                onChange={requestSearch}
                placeholder="Search Using Keyword"
                className="select-box select-box-primary search-box"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box style={{ float: "right" }}>
              <Select
                value={rowsPerPage}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="items-count-dropdown"
              >
                <MenuItem value={10} selected>
                  10
                </MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </Box>
          </Grid>

          <UserTable
            setIsEditRecord={setIsEditRecord}
            setOpenPopup={setOpenPopup}
            setValues={setValues}
            setEditUserId={setEditUserId}
            userList={userList}
            tablePagination={tablePagination}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            getAllUser={getAllUser}
            setUserList={setUserList}
          />
        </Box>
      </Paper>
      <Modal
        open={openPopup}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <div className="title">{!isEditRecord ? "Add" : "Edit"} User</div>

          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <InputLabel id="first-name-input">First Name</InputLabel>
                <Controls.Input
                  labelId="first-name-input"
                  name="first_name"
                  fullWidth
                  disabled={!isEditRecord ? false : true}
                  value={values.first_name}
                  onChange={handleInputChange}
                  error={errors.first_name}
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputLabel id="last-name-input">Last Name</InputLabel>
                <Controls.Input
                  labelId="first-name-input"
                  name="last_name"
                  fullWidth
                  disabled={!isEditRecord ? false : true}
                  value={values.last_name}
                  onChange={handleInputChange}
                  error={errors.last_name}
                  className="StepControlInput"
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="email-input">Email</InputLabel>
                <Controls.Input
                  labelId="email-input"
                  name="email"
                  disabled={!isEditRecord ? false : true}
                  fullWidth
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  className="StepControlInput"
                  inputProps={{ maxLength: 100 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="position-input">Position</InputLabel>
                <Controls.Input
                  labelId="position-input"
                  name="position"
                  fullWidth
                  value={values.position}
                  onChange={handleInputChange}
                  error={errors.position}
                  className="StepControlInput"
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="position-input">Role</InputLabel>
                <Controls.Select
                  labelId="role-input"
                  name="role"
                  value={values.role}
                  fullWidth
                  onChange={handleInputChange}
                  options={getNewUserRole}
                  error={errors.role}
                  className="StepControlInput"
                />
              </Grid>
              {/* <Grid item xs={12} style={{ margin: "1em" }}>
                <FormControlLabel
                  control={
                    <SwitchUnstyled
                      name="is_admin"
                      value={values.is_admin}
                      onChange={() => adminSwichHandle()}
                      className="switchable-toggle"
                    />
                  }
                  label="Set as an Admin"
                />
              </Grid> */}
            </Grid>
            <Box
              className="BottomModal"
              display="flex"
              p={1}
              bgcolor="background.paper"
            >
              <Grid istem xs={4} className="BottomModal-left">
                <Box p={0} flexGrow={1}>
                  <Controls.Button
                    text="Cancel"
                    disableElevation
                    onClick={() => {
                      clickClosePopup();
                    }}
                    style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                    color="default"
                    className="btn-mui"
                  />
                </Box>
              </Grid>
              <Grid item xs={8} className="BottomModal-right">
                <Box p={0}>
                  <Controls.Button
                    type="submit"
                    disableElevation
                    text={!isEditRecord ? "Add User" : "Update User"}
                    className="btn-mui"
                  />
                </Box>
              </Grid>
            </Box>
          </Form>
        </Box>
      </Modal>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default UserList;
