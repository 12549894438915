import React, { useEffect, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import { Paper, Grid } from "@material-ui/core";
import Menu from "@mui/material/Menu";
import axiosInstance from "../../services/AxiosInstance";
import { Form, useForm } from "../../components/useForm";
import Moment from "moment";
import { HtmlUtilService } from "../../services/HtmlUtilService";
import { Api } from "../../api/Api";
import { GET_SELLER_DETAIL_API } from "../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_INACTIVE_CAP_SMALL,
} from "../../constants/AppConstants";

const SupplierDetails = (props) => {
  const { setViewDetailsId, setSupplierDetailView, viewDetailsId } = props;

  const [supplierData, setSupplierData] = useState();
  const [editMode, setEditMode] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name =
        fieldValues.name.length !== 0 ? "" : "Project Name is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const initialFValues = {
    userId: supplierData,
    email: supplierData?.email,
    country: supplierData?.supplier_company?.company_name,
    category: "",
    company_name: "",
    supplier_company_name: supplierData?.supplier_company?.company_name,
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const GetSupplierDetails = async () => {
    const response = await Api(
      `${GET_SELLER_DETAIL_API}/${viewDetailsId._id}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let suppliersList = response.data.supplier;
        setSupplierData(suppliersList);
      }
    } else {
    }

    // await axiosInstance
    //   .get(`api/buyer/company/suppliers/${viewDetailsId._id}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let suppliersList = response.data.supplier;

    //       setSupplierData(suppliersList);
    //     }

    //   })
    //   .catch((err) => {});
  };

  const clickToggleBtn = (event) => {
    setEditMode(!editMode);
    const descriptionView = document.querySelector("#description__view");
    const descriptionEdit = document.querySelector("#description__edit");

    if (editMode == true) {
      descriptionView.style.display = "block";
      descriptionEdit.style.display = "none";
      // redy to send data to back-end
    } else {
      descriptionView.style.display = "none";
      descriptionEdit.style.display = "block";
    }
  };

  useEffect(() => {
    GetSupplierDetails();
    HtmlUtilService.truncate();
  }, []);

  return (
    <>
      <div className="details-banner">
        <img className="banner-cover" src="" />
        <div className="banner-container">
          <Grid
            container
            style={{
              position: "relative",
            }}
            spacing={1}
          >
            <Grid item xs={12}>
              <h2 className="project-title">
                {`${supplierData?.main_contact?.first_name} ${supplierData?.main_contact?.last_name}`}
              </h2>

              <div className="project-title__smal">
                <span> Supplier / </span>
                <span>
                  {`${supplierData?.main_contact?.first_name} ${supplierData?.main_contact?.last_name}`}
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <button
              className="btn btn-primary go-back-button"
              onClick={() => setSupplierDetailView(false)}
            >
              <i class="fas fa-angle-left"></i> Go Back
            </button>
          </Grid>
          <Paper elevation={1} square style={{ marginBottom: "2em" }}>
            <Grid container style={{ padding: "20px" }}>
              <Grid item xs={6}>
                <p style={{ fontWeight: "bold" }}>Supplier Details</p>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end" }}>
                <Button
                  className={
                    "btn btn-primary go-back-button" +
                    (editMode === true ? "__save" : "__edit")
                  }
                  style={{ height: "30px" }}
                  variant="contained"
                  onClick={clickToggleBtn}
                >
                  {editMode ? "Save" : "Edit"}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <small>Supplier ID</small>
                <p style={{ marginBlockStart: "0px" }}>
                  {"R" + Math.floor(100000 + Math.random() * 900000)}
                </p>
              </Grid>
              <Grid item xs={3}>
                <small>Supplier Status</small>
                <br />
                <PopupState variant="popover">
                  {(popupState) => (
                    <>
                      <Button
                        className="active-btn-item status"
                        variant="contained"
                        {...bindTrigger(popupState)}
                        endIcon={
                          <ArrowDropDownIcon className="icn-active-btn" />
                        }
                      >
                        {AUCTION_ACTIVE}
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {/* {item.status === "Active" ? (<div><button className="active-btn-popup" disabled onClick={() => itemClickOnActive(item)} >Active</button></div>) : (<div><button className="active-btn-popup" onClick={() => itemClickOnActive(item)} >Active</button></div>)} */}
                        <button className="active-btn-popup">
                          {AUCTION_ACTIVE}
                        </button>
                        <br />
                        <button className="inactive-btn-popup">
                          {AUCTION_INACTIVE_CAP_SMALL}
                        </button>
                        {/* {item.status === "Inactive" ? (<button className="inactive-btn-popup" disabled onClick={() => itemClickOnInactive(item)} >Inactive</button>) : (<button className="inactive-btn-popup" onClick={() => itemClickOnInactive(item)} >Inactive</button>)} */}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Grid>
              <Grid item xs={3}>
                <div className="lable">Company</div>

                <textarea
                  className={
                    "field" + " " + (editMode === true ? "__edit" : "__view")
                  }
                  name="supplier_company_name"
                  style={{ fontSize: "1em" }}
                  readOnly={!editMode}
                  spellcheck="false"
                  onChange={handleInputChange}
                  rows="1"
                >
                  {supplierData?.supplier_company?.company_name}
                </textarea>
                {/* <p style={{ marginBlockStart: "0px" }}>{supplierData?.supplier_company?.company_name}</p> */}
              </Grid>
              <Grid item xs={3}>
                <small>Country</small>
                <textarea
                  className={
                    "field" + " " + (editMode === true ? "__edit" : "__view")
                  }
                  name="country"
                  style={{ fontSize: "1em" }}
                  readOnly={!editMode}
                  spellcheck="false"
                  onChange={handleInputChange}
                  rows="1"
                >
                  {supplierData?.country}
                </textarea>
                {/* <p style={{ marginBlockStart: "0px" }}>{supplierData?.country}</p> */}
              </Grid>
              <Grid item xs={3}>
                <small>Last Update On: </small>
                <p style={{ marginBlockStart: "0px" }}>{supplierData?.email}</p>
              </Grid>
              <Grid item xs={3}>
                <small>Category</small>
                <textarea
                  className={
                    "field" + " " + (editMode === true ? "__edit" : "__view")
                  }
                  name="category"
                  style={{ fontSize: "1em" }}
                  readOnly={!editMode}
                  spellcheck="false"
                  onChange={handleInputChange}
                  rows="1"
                >
                  {supplierData?.category?.name}
                </textarea>
                {/* <p style={{ marginBlockStart: "0px" }}>{supplierData?.category?.name}</p> */}
              </Grid>
              <Grid item xs={3}>
                <small>Email</small>
                {/* <textarea
                                    // className={
                                    //     "field" + " " + (editMode === true ? "__edit" : "__view")
                                    // }
                                    className="field__view"
                                    name="email"
                                    style={{ fontSize: "1em" }}
                                    readOnly={!editMode}
                                    spellcheck="false"
                                    onChange={handleInputChange}
                                    rows="1"
                                >
                                    {supplierData.email}
                                </textarea> */}
                <p style={{ marginBlockStart: "0px" }}>{supplierData?.email}</p>
              </Grid>
              <Grid item xs={3}>
                <small>Main Contact</small>
                <p
                  style={{ marginBlockStart: "0px" }}
                >{`${supplierData?.main_contact?.first_name} ${supplierData?.main_contact?.last_name}`}</p>
              </Grid>
              <Grid item xs={3}>
                <small>Last Update On: </small>
                <p style={{ marginBlockStart: "0px" }}>
                  {Moment(supplierData?.updatedAt).format("DD/MMMM/YYYY")}
                </p>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default SupplierDetails;
