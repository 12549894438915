import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import { makeStyles } from "@material-ui/styles";
import Formsteps from "../../components/Formsteps";
import { useDispatch } from "react-redux";
import { addFirstForm } from "../../store/actions/ItemActions";
import InputLabel from "@mui/material/InputLabel";
import { store } from "../../store/store";
import { CLEAR_ITEM_ON_SAVE } from "../../store/constant/itemConstants";
import "./style/create-item-step1.css";
import { Api } from "../../api/Api";
import {
  GET_COMPANY_ITEM_CATEGORY_API,
  GET_COMPANY_TAG_API,
} from "../../constants/ApiUrl";

const useStyles = makeStyles((theme) => ({
  formPart1: {
    "& .MuiFormControl-root": {
      width: "90%",
    },
  },
  Button: {
    padding: ".4rem 3rem",
    borderRadius: "6px",
    fontWeight: "700",
    fontSize: "14px",
  },
}));

export default function EmployeeForm(props) {
  const [currency, setCurrency] = React.useState("EUR");
  const [saveFormAsDraft, setSaveFormAsDraft] = useState(false);
  const [dropdownCategoryList, setDropdownCategoryList] = useState([]);
  const [dropdownGroupList, setDropdownGroupList] = useState([]);
  const [dropdownTagsList, setDropdownTagsList] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { itemss } = store.getState();
  const initialFValues = {
    item_name: itemss?.ItemsFirstForm?.item_name || "",
    manufacturer: itemss?.ItemsFirstForm?.manufacturer || "",
    notes: itemss?.ItemsFirstForm?.notes || "",
    unit: itemss?.ItemsFirstForm?.unit || "",
    category: itemss?.ItemsFirstForm?.category || "",
    model: itemss?.ItemsFirstForm?.model || "",
    group: itemss?.ItemsFirstForm?.group || "",
    tags: itemss?.ItemsFirstForm?.tags || "",
  };
  const {
    recordForEdit,
    // openPopup,
    setOpenPopup,
    openPopup2,
    setOpenPopup2,
    stepLabels,
    setNotify,
    getAllItemsByCompanyName,
  } = props;
  //  const [openPopup, setOpenPopup] = (false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("item_name" in fieldValues)
      temp.item_name = fieldValues.item_name ? "" : "This field is required.";

    // if ("manufacturer" in fieldValues)
    //   temp.manufacturer = fieldValues.manufacturer
    //     ? ""
    //     : "This field is required.";

    // if ("notes" in fieldValues)
    //   temp.notes = fieldValues.notes ? "" : "This field is required.";

    if ("unit" in fieldValues)
      temp.unit = fieldValues.unit ? "" : "This field is required.";

    // if ("category" in fieldValues)
    //   temp.category = fieldValues.category ? "" : "This field is required.";

    // if ("group" in fieldValues)
    //   temp.group = fieldValues.group ? "" : "This field is required.";

    // if ("model" in fieldValues)
    //   temp.model = fieldValues.model ? "" : "This field is required.";

    // if ("tags" in fieldValues)
    //   temp.tags = fieldValues.tags ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let dataToSend = values;
      Object.keys(dataToSend).forEach(
        (k) => dataToSend[k].length === 0 && delete dataToSend[k]
      );

      const res = await dispatch(
        addFirstForm(values, { isSaveAsDraft: saveFormAsDraft })
      );

      if (saveFormAsDraft) {
        // if (itemss?.SaveSuccessDraft === true) {
        if (res?.data?.responseCode === "00") {
          setOpenPopup(false);
          resetForm();
          getAllItemsByCompanyName();
        }
      } else {
        setOpenPopup(false);
        setOpenPopup2(true);
        resetForm();
      }
      // setRecordForEdit(null);
    }
  };

  const getAllCategory = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COMPANY_ITEM_CATEGORY_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let CatAndGroups = response.data.itemsCatAndGroups;
          let groupArr = [];
          let cateArr = [];
          CatAndGroups.map((group) => {
            if (group.itemType === "group") {
              groupArr.push(group);
            } else if (group.itemType === "category") {
              cateArr.push(group);
            }
          });
          setDropdownCategoryList(cateArr);
          setDropdownGroupList(groupArr);
        }
      }

      // await axiosInstance
      //   .get(`api/buyer/company/getItemsCatGroups/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let CatAndGroups = response.data.itemsCatAndGroups;
      //       let groupArr = [];
      //       let cateArr = [];
      //       CatAndGroups.map((group) => {
      //         if (group.itemType === "group") {
      //           groupArr.push(group);
      //         } else if (group.itemType === "category") {
      //           cateArr.push(group);
      //         }
      //       });
      //       setDropdownCategoryList(cateArr);
      //       setDropdownGroupList(groupArr);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const getAllTags = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COMPANY_TAG_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let TagsList = response.data.tag;

          setDropdownTagsList(TagsList);
        }
      } else {
      }

      // await axiosInstance
      //   .get(`/api/buyer/company/getTag/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let TagsList = response.data.tag;

      //       setDropdownTagsList(TagsList);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const cancelAddItem = () => {
    dispatch({
      type: CLEAR_ITEM_ON_SAVE,
      payload: {},
    });
    setOpenPopup(false);
  };

  useEffect(() => {
    const { itemss } = store.getState();

    if (recordForEdit !== null) {
      setValues({
        ...recordForEdit,
      });
    } else {
      if (itemss && itemss.ItemsFirstForm && itemss.ItemsFirstForm.category) {
        setValues({
          ...itemss.ItemsFirstForm,
        });
      }
    }
    getAllCategory();
    getAllTags();
  }, []);

  return (
    <>
      {/* className={classes.formPart1} */}
      <Form onSubmit={handleSubmit} className="create-form-container">
        <Grid container justifyContent="center">
          <Grid item xl={5} lg={6} md={7} sm={8} xs={12}>
            <Formsteps
              steps={stepLabels}
              currStep={1}
              className="mt-5 mb-5"
            ></Formsteps>
          </Grid>
        </Grid>

        <Grid container spacing={8}>
          <Grid item sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel id="name-input">
                  Item Name
                  <span className="mandatorySign">*</span>
                </InputLabel>
                <Controls.Input
                  name="item_name"
                  labelId="name-input"
                  fullWidth
                  value={values.item_name}
                  onChange={handleInputChange}
                  error={errors.item_name}
                  className="StepControlInput"
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="notes-input">Notes</InputLabel>
                <Controls.Input
                  labelId="notes-input"
                  name="notes"
                  value={values.notes}
                  onChange={handleInputChange}
                  error={errors.notes}
                  multiline="multiline"
                  Rows={4}
                  className="StepControlInput"
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="category-input">Category</InputLabel>
                <Controls.Select
                  name="category"
                  labelId="category-input"
                  value={values.category}
                  fullWidth
                  onChange={handleInputChange}
                  options={dropdownCategoryList}
                  error={errors.category}
                  className="StepControlInput"
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel id="group-input">Group Name</InputLabel>
                <Controls.Select
                  name="group"
                  labelId="group-input"
                  value={values.group}
                  onChange={handleInputChange}
                  options={dropdownGroupList}
                  error={errors.group}
                  className="StepControlInput"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel id="manufacturer-input">Manufacturer</InputLabel>
                <Controls.Input
                  name="manufacturer"
                  labelId="manufacturer-input"
                  fullWidth
                  value={values.manufacturer}
                  onChange={handleInputChange}
                  error={errors.manufacturer}
                  className="StepControlInput"
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="unit-input">
                  Unit
                  <span className="mandatorySign">*</span>
                </InputLabel>
                <Controls.Input
                  name="unit"
                  labelId="unit-input"
                  value={values.unit}
                  onChange={handleInputChange}
                  error={errors.unit}
                  className="StepControlInput"
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="model-input">Model</InputLabel>
                <Controls.Input
                  name="model"
                  labelId="model-input"
                  value={values.model}
                  onChange={handleInputChange}
                  error={errors.model}
                  className="StepControlInput"
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="tags-input">Tags</InputLabel>
                <Controls.Select
                  name="tags"
                  labelId="tags-input"
                  value={values.tags}
                  onChange={handleInputChange}
                  options={dropdownTagsList}
                  error={errors.tags}
                  className="StepControlInput"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div id="bottom-button-box-container">
          <div id="left-side">
            <Controls.Button
              text="Cancel"
              disableElevation
              onClick={() => {
                cancelAddItem();
              }}
              style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
              color="default"
              className="btn-mui fix-w-12em m-0 w-100"
            />
          </div>
          <div id="right-side">
            <div>
              <Controls.Button
                text="Save as Draft"
                type="submit"
                disableElevation
                color="default"
                style={{ margin: "0 10px 0 0" }}
                onClick={() => setSaveFormAsDraft(true)}
                className="btn draft btn-mui fix-w-12em w-100"
              />
            </div>
            <div>
              <Controls.Button
                type="submit"
                disableElevation
                text="Next"
                className="btn-mui fix-w-12em m-0 w-100"
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
