import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import { RFX_STATUS_LIST } from "../../../constants/AppConstants";

const RfxSellerFilter = (props) => {
  const { filterValues, setFilterValues, setResendApi, currentUser } = props;
  const [statusList, setStatusList] = React.useState([...RFX_STATUS_LIST]);

  return (
    <>
      <Grid container spacing={2} className="filter-field-container">
        <Grid item md={3} sm={6} xl={12}>
          <InputLabel className="mui-label">Status</InputLabel>
          <Controls.Select
            name="status"
            value={filterValues.status}
            fullWidth
            onChange={(event) =>
              setFilterValues({
                ...filterValues,
                status: event.target.value,
              })
            }
            emptyOption={false}
            options={statusList}
            className="w-100 b-600"
          />
        </Grid>

        <Grid item md={3} sm={6} xl={12}>
          <InputLabel className="mui-label">Manager</InputLabel>
          <Controls.Input
            name="manager"
            value={filterValues.manager}
            onChange={(event) =>
              setFilterValues({
                ...filterValues,
                manager: event.target.value,
              })
            }
            className="w-100 b-600"
          />
        </Grid>
        <Grid item md={2} sm={6} xl={12}>
          <Box p={0}>
            <InputLabel className="mui-label">RFX Type</InputLabel>
            <Controls.Select
              name="type"
              value={filterValues.type}
              fullWidth
              onChange={(event) =>
                setFilterValues({
                  ...filterValues,
                  type: event.target.value,
                })
              }
              emptyOption={false}
              options={[{ _id: "-1", name: "All Type" }]}
              className="w-100 b-600"
            />
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          sm={6}
          xl={12}
          className="select-field"
          style={{ fontWeight: "600" }}
        >
          <Controls.Button
            text="Clear"
            disableElevation
            color="default"
            style={{
              width: "100%",
              margin: "auto",
              backgroundColor: "#F1F0F2",
            }}
            className="btn-mui"
            onClick={() => {
              setFilterValues({
                status: "-1",
                client: "-1",
                manager: "",
                type: "-1",
              });
              setResendApi(true);
            }}
          />
        </Grid>
        <Grid
          item
          md={2}
          sm={6}
          xl={12}
          className="select-field"
          style={{ fontWeight: "600" }}
        >
          <Controls.Button
            type="submit"
            disableElevation
            text="Apply Filter"
            style={{ width: "100%", margin: "auto" }}
            className="btn-mui"
            onClick={() => setResendApi(true)}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(RfxSellerFilter));
