import { Box, Grid, InputAdornment, Paper, TextField } from "@material-ui/core";
import {
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Modal,
} from "@mui/material";

import { Search } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import Notification from "../../../../components/Notification";
import "../../../Auction/styles/details-attachment.css";
import TableOptionList from "../../../../components/TableOptionList";
import TableHeadOptionList from "../../../../components/TableHeadOptionList";
import TablePaginationBox from "../../../../components/TablePaginationBox";
import "../../../../styles/image-uploader.css";
import CloseIcon from "@mui/icons-material/Close";
import { store } from "../../../../store/store";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Api } from "../../../../api/Api";
import {
  COMAPNY_API,
  DELETE_AUCTION_ATTACHMENT_API,
  GET_RFX_ATTACHMENT_API,
  REMOVE_ATTACH_RFX_API,
} from "../../../../constants/ApiUrl";
import {
  AUCTION_DRAFT,
  TOAST_AUTO_CLOSE,
} from "../../../../constants/AppConstants";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";
import { useSelector } from "react-redux";
import AddLibrary from "../../../Auction/components/tabs/AddDocsLibrary";
import RfxDocumentAttachment from "./RfxDocumentAttachment";
import AddFromLibraryPopup from "./AddLibraryRfx";
import LinkAttachmentPopupRfx from "./LinkAttachmentPopupRfx";
import {
  downloadDocumentFile,
  downloadDocumentFile2,
} from "../../../../utility/utils";

const columns = [
  {
    label: "Attachments",
  },
  {
    label: "Name",
  },
  {
    label: "Notes",
  },
  {
    label: "",
  },
];

const RfxAttachmentTab = (props) => {
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const [attachments, setAttachments] = React.useState([]);
  
  const [documentPopup, steDocumentPopup] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [optionType, setOptionType] = React.useState(null);
  const [linkAttachmentPopup, setLinkAttachmentPopup] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editDocumentId, setEditDocumentId] = React.useState(null);
  const [editLinkId, setEditLinkId] = React.useState(null);
  const [editDocumentData, setEditDocumentData] = React.useState({});
  const [editLinkData, setEditLinkData] = React.useState({});
  const [openPopupLib, setOpenPopupLib] = useState(false);
  const [docsList, setDocsList] = useState([]);
  const [deleteAttachmentData, setDeleteAttachmentData] = useState({});
  const [alertDailog, setAlertDailog] = useState(false);
  const handleClose = () => setAlertDailog(false);
  const { smScreen } = useWindowDimensions();

  const openMenu = Boolean(anchorEl);
  /// Please be carefull !!!
  /// must start with (1) for pagination issue ....
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  /// minior chnage to get page without increment by one
  const emptyRows =
    page > 0 ? Math.max(0, page * rowsPerPage - attachments.length) : 0;

  const numOfPages = Math.ceil(attachments.length / rowsPerPage);

  const setAttachmentOptionType = (type) => {
    setOptionType(type);
    if (type === "document") {
      setAnchorEl(null);
      steDocumentPopup(true);
    } else if (type === "link") {
      setAnchorEl(null);
      setLinkAttachmentPopup(true);
    } else if (type === "library") {
      addDocumentFromLib();
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
    }
  };

  const getDocumets = async (searchText) => {
    if (rfxDetails !== undefined && rfxDetails._id !== undefined) {
      let queryString = "";
      if (searchText) {
        queryString = `?search=${searchText}`;
      }
      const response = await Api(
        `${GET_RFX_ATTACHMENT_API}/${rfxDetails._id}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let AllDocs = response.data.attachments.documents;
          let linkArray = response.data?.attachments?.links;
          let libraryDocs = response.data?.attachments?.docs;
          if (AllDocs && AllDocs.length > 0) {
            setAttachments(AllDocs);
          }
          if (linkArray && linkArray.length > 0) {
            setAttachments(linkArray);
          }
          if (libraryDocs && libraryDocs.length > 0) {
            setAttachments(libraryDocs);
          }
          if (linkArray.length > 0 && AllDocs.length > 0) {
            const combine = [...AllDocs, ...linkArray];
            setAttachments(combine);
          }
          if (libraryDocs.length > 0 && AllDocs.length > 0) {
            const combine = [...AllDocs, ...libraryDocs];
            setAttachments(combine);
          }
          if (libraryDocs.length > 0 && linkArray.length > 0) {
            const combine = [...libraryDocs, ...linkArray];
            setAttachments(combine);
          }
          if (
            linkArray.length > 0 &&
            AllDocs.length > 0 &&
            libraryDocs.length > 0
          ) {
            const combine = [...AllDocs, ...linkArray, ...libraryDocs];
            setAttachments(combine);
          }
        } else {
          setAttachments([]);
        }
      } else {
        setAttachments([]);
      }
    }
  };

  const EditDocs = (attachment) => {
    if (attachment && attachment.fileName) {
      setEditDocumentData(attachment);
      setEditDocumentId(attachment?._id);
      setIsEditMode(true);
      steDocumentPopup(true);
    } else if (attachment && attachment.link) {
      setEditLinkId(attachment?._id);
      setEditLinkData(attachment);
      setIsEditMode(true);
      setLinkAttachmentPopup(true);
    } else {
      toast.error(ValidationMessage.updateDocument, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const addDocumentFromLib = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${COMAPNY_API}/${companyId}/library`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allDocs = response.data.library;
          setDocsList(allDocs);
          setOpenPopupLib(true);
          setAnchorEl(null);
        } else {
          setDocsList([]);
          toast.error(ValidationMessage.addDocumentSetting, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      } else {
        setDocsList([]);
      }
    }
  };

  const RemoveSelectedAttchment = (attachment) => {
    setAlertDailog(true);
    setDeleteAttachmentData(attachment);
  };

  const confirmDeleteAttachment = async () => {
    if (deleteAttachmentData) {
      const response = await Api(
        `${REMOVE_ATTACH_RFX_API}/${rfxDetails._id}/${deleteAttachmentData._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getDocumets();
          setAlertDailog(false);
          setDeleteAttachmentData({});
        }
      }
    } else {
      toast.error(ValidationMessage.somethingWrong, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const requestSearch = (e) => {
    const searchText = e.target.value;
    getDocumets(searchText);
  };

  useEffect(() => {
    getDocumets();
  }, []);

  return (
    <Paper elevation={0} square>
      <Grid container>
        <Grid
          item
          md={6}
          xs={12}
          style={{ textAlign: smScreen ? "center" : "start" }}
        >
          <TextField
            onChange={requestSearch}
            placeholder="Search Using Keyword"
            className="select-box select-box-primary search-box"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
          style={{
            textAlign: smScreen ? "center" : "end",
            marginTop: smScreen ? "10px" : "0px",
          }}
        >
          {rfxDetails?.status === AUCTION_DRAFT && (
            <Button
              aria-controls="demo-customized-menu"
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={(event) => setAnchorEl(event.currentTarget)}
              className="btn-mui-table-option"
              endIcon={<KeyboardArrowDownIcon />}
            >
              <AddOutlinedIcon style={{ fontSize: "20px" }} />
              <span>Add Attachment</span>
            </Button>
          )}
          <TableHeadOptionList
            id="demo-customized-menu"
            MenuListProps={{
              "aria-label": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAttachmentOptionType(null)}
          >
            <MenuItem
              onClick={() => setAttachmentOptionType("document")}
              disableRipple
            >
              Attach Document
            </MenuItem>
            <MenuItem
              onClick={() => setAttachmentOptionType("library")}
              disableRipple
            >
              Add from Library
            </MenuItem>
            <MenuItem
              onClick={() => setAttachmentOptionType("link")}
              disableRipple
            >
              URL Link
            </MenuItem>
          </TableHeadOptionList>
        </Grid>
      </Grid>

      <Paper elevation={0} square>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? attachments.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : attachments
              ).map((attachment, index) => (
                <TableRow key={attachment._id}>
                  <TableCell className="clr-2170ff">
                    <div className="file-name-container">
                      <div className="icon">
                        {attachment.file_type === "file" ? (
                          <FilePresentOutlinedIcon />
                        ) : (
                          <LinkOutlinedIcon />
                        )}
                      </div>
                      <div
                        className="name simple-word-break library-doc"
                        onClick={() =>
                          downloadDocumentFile2(
                            attachment?.fileName,
                            attachment?.link,
                            attachment?.displayName
                          )
                        }
                        download={attachment?.fileName}
                      >
                        {attachment && attachment.displayName
                          ? attachment?.displayName
                          : attachment && attachment.link
                          ? attachment.link
                          : attachment && attachment?.document
                          ? attachment?.document?.name
                          : ""}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{attachment?.name || attachment?.title}</TableCell>
                  <TableCell>{attachment?.notes}</TableCell>
                  <TableCell>
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem
                                className="bold-line-600"
                                onClick={() => EditDocs(attachment)}
                              >
                                Edit Attachment
                              </MenuItem>
                              {/* <MenuItem className="bold-line-600">
                                Duplicate
                              </MenuItem> */}
                              <Divider />
                              <MenuItem
                                className="bold-line-600 clr-ff0000"
                                onClick={() =>
                                  RemoveSelectedAttchment(attachment)
                                }
                              >
                                Remove
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

              {attachments.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={attachments.length + " Attachments Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>

      <Modal
        open={documentPopup}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <div className="title">Attach Document</div>
          <RfxDocumentAttachment
            documentPopup={documentPopup}
            steDocumentPopup={steDocumentPopup}
            setNotify={setNotify}
            setAttachments={setAttachments}
            editDocumentData={editDocumentData}
            setEditDocumentData={setEditDocumentData}
            isEditMode={isEditMode}
            editDocumentId={editDocumentId}
            setIsEditMode={setIsEditMode}
            getDocumets={getDocumets}
          />
        </Box>
      </Modal>

      <Modal
        open={linkAttachmentPopup}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <div className="title">Add Link</div>
          <LinkAttachmentPopupRfx
            setNotify={setNotify}
            linkAttachmentPopup={linkAttachmentPopup}
            setLinkAttachmentPopup={setLinkAttachmentPopup}
            setAttachments={setAttachments}
            setIsEditMode={setIsEditMode}
            isEditMode={isEditMode}
            editLinkData={editLinkData}
            editLinkId={editLinkId}
            getDocumets={getDocumets}
            setEditLinkData={setEditLinkData}
          />
        </Box>
      </Modal>

      {/* add from library  */}

      <Modal
        open={openPopupLib}
        aria-label="modal-add-document-from-library"
        aria-describedby="modal-modal-description"
        // className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Library</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopupLib(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          {/* <div className="title"> Library </div> */}
          <AddFromLibraryPopup
            setOpenPopupLib={setOpenPopupLib}
            docsList={docsList}
            setDocsList={setDocsList}
            rfxDetails={rfxDetails}
            setNotify={setNotify}
            getDocumets={getDocumets}
          />
        </Box>
      </Modal>
      {/* add from library  */}

      <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete attachment?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setAlertDailog(false)}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={() => confirmDeleteAttachment()}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default RfxAttachmentTab;
