/*eslint-disable */
import React, { useEffect, useState } from "react";
import { Paper, Box, Grid, TextField, InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import Controls from "../../../components/controls/Controls";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import AuctionFilter from "./filters/AuctionFilter";
import AuctionTable from "./AuctionTable";
import Notification from "../../../components/Notification";
import useWindowDimensions from "../../../components/useWindowDimensions";
import Loader from "../../../components/Loader/Loader";
import { SupplierAuctionRecords } from "../../../data/SupplierAuctionRecords";
import "../../../styles/filter-box.css";
import "./styles/auction-table.css";
import GetAllAuctionBL from "./BL/GetAllAuctionBL";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { currentUser } from "../../../store/selectors/AuthSelectors";

const SupplierAuctionList = (props) => {
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [fullLoder, setFullLoder] = useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchKey, setSearchKey] = React.useState("");
  const [filterValues, setFilterValues] = React.useState({
    status: "-1",
    client: "-1",
    manager: "",
    type: "-1",
  });
  const [resendApi, setResendApi] = React.useState(true);
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const [anchorNumEntriesEl, setAnchorNumEntriesEl] = React.useState(null);
  const openNumEntriesMenu = Boolean(anchorNumEntriesEl);
  const [anchorSortTypeEl, setAnchorSortTypeEl] = React.useState(null);
  const openAnchorSortTypeMenu = Boolean(anchorSortTypeEl);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [list, setList] = useState([]);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setResendApi(true);
  }, [rowsPerPage]);

  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };

  const openSortList = () => {
    setAnchorSortTypeEl(null);
  };

  const searchHandler = (text) => {
    setFullLoder(true);

    GetAllAuctionBL({
      currentUser: props.currentUser,
      searchKey: text,
      page: page,
      rowsPerPage: rowsPerPage,
      searchKey: text,
      filterValues: filterValues,
    }).then((data) => {
      setList(data.list);
      setLimit(data.limit);
      setFullLoder(false);
      setResendApi(false);
    });
  };

  return (
    <>
      {fullLoder && <Loader />}
      <Grid container style={{ marginTop: "1em" }}>
        <Grid item container xs={12} style={{ alignItems: "center" }}>
          <Grid
            item
            xs={12}
            style={{ textAlign: smScreen ? "center" : "start" }}
          >
            <h1 className="mt-4 fz-34">Auctions</h1>
          </Grid>
          <Grid xs={12} style={{ textAlign: smScreen ? "center" : "end" }}>
            <Box className="mb-4 mt-5">
              <Controls.Button
                variant="contained"
                className={
                  "btn btn-white btn-mui mr-2" +
                  (showFilterCategories ? " active" : "")
                }
                text="Filter"
                onClick={toggleFilterCategory}
                startIcon={<FilterListSharpIcon style={{ color: "#2170FF" }} />}
              />
              {/* <Controls.Button
                text="Export List"
                className="btn btn-primary btn-mui"
                variant="contained"
                startIcon={
                  <SystemUpdateAltIcon
                    style={{ transform: "rotate(180deg)" }}
                  />
                }
              /> */}
            </Box>
          </Grid>
        </Grid>
        <Grid container className="filter-box-container">
          {showFilterCategories && (
            <Paper elevation={1} className="filter-box">
              <AuctionFilter
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                resendApi={resendApi}
                setResendApi={setResendApi}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Grid container>
            <Grid
              item
              md={4}
              xs={12}
              style={{ textAlign: smScreen ? "center" : "start" }}
            >
              <TextField
                placeholder="Search Using Keyword or Tags..."
                className="select-box select-box-primary search-box"
                value={searchKey}
                onChange={(event) => {
                  searchHandler(event.target.value);
                  setSearchKey(event.target.value);
                  // setResendApi(true);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
              style={{
                textAlign: smScreen ? "center" : "end",
                marginTop: smScreen ? "10px" : "0px",
              }}
            >
              <Controls.Select
                value={rowsPerPage}
                fullWidth
                onChange={(event) => setRowsPerPage(event.target.value)}
                emptyOption={false}
                options={[
                  { _id: 10, name: "Show 10 entries" },
                  { _id: 20, name: "Show 20 entries" },
                  { _id: 30, name: "Show 30 entries" },
                ]}
                className="outline"
              />
              {/* <Button
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={openAnchorSortTypeMenu ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={(event) => setAnchorSortTypeEl(event.currentTarget)}
                className="btn-mui-table-option outline"
                endIcon={<KeyboardArrowDownIcon />}
              >
                <span className="fz-16">Sort By Latest</span>
              </Button> */}
              {/* <TableHeadOptionList
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-label": "demo-customized-button",
                }}
                anchorEl={anchorSortTypeEl}
                open={openAnchorSortTypeMenu}
                onClose={() => openSortList()}
              >
                <MenuItem onClick={() => openSortList()} disableRipple>
                  Sort By Latest
                </MenuItem>
                <MenuItem onClick={() => openSortList()} disableRipple>
                  Sort By another Standard
                </MenuItem>
                <MenuItem onClick={() => openSortList()} disableRipple>
                  Sort By another Standard
                </MenuItem>
              </TableHeadOptionList> */}
            </Grid>
          </Grid>
          <AuctionTable
            auctionList={SupplierAuctionRecords}
            rowsPerPage={rowsPerPage}
            fullLoder={fullLoder}
            setFullLoder={setFullLoder}
            searchKey={searchKey}
            filterValues={filterValues}
            resendApi={resendApi}
            setResendApi={setResendApi}
            page={page}
            setPage={setPage}
            list={list}
            setList={setList}
            limit={limit}
            setLimit={setLimit}
          />
        </Box>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};
export default withRouter(connect(mapStateToProps)(SupplierAuctionList));
