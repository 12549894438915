import { Api } from "../../../api/Api";
import { COMAPNY_API } from "../../../constants/ApiUrl";

const GetAllContractsApi = async (props) => {
  try {
    const { companyId, filters, search, page, rowsPerPage } = props;
    let list = [];
    let limit = 0;
    let Page = 1;
    let RowsPerPage = Number.MAX_SAFE_INTEGER;

    if (page && page !== "") {
      Page = page;
    }

    if (rowsPerPage && rowsPerPage !== "") {
      RowsPerPage = rowsPerPage;
    }

    try {
      let path = `${COMAPNY_API}/${companyId}/contract?per_page=${RowsPerPage}&page=${Page}`;

      if (search && search !== "") {
        path += `&search=${search}`;
      }

      if (filters && filters.supplierName && filters.supplierName !== "") {
        path += `&supplier=${filters.supplierName}`;
      }

      if (
        filters &&
        filters.dateRange &&
        Array.isArray(filters.dateRange) &&
        filters.dateRange.length > 0
      ) {
        path += `&startDate=${filters.dateRange[0]}&endDate=${filters.dateRange[1]}`;
      }

      if (filters && filters.owner && filters.owner !== "") {
        path += `&owner=${filters.owner}`;
      }

      // let response = await axiosInstance.get(path);
      const response = await Api(path, {}, "get");
      if (response.status === 200) {
        list = response.data.contracts;
        limit = response.data.totalRecords;
      } else {
      }
    } catch (err) {}

    return {
      list,
      limit,
    };
  } catch (error) {}
};

export default GetAllContractsApi;
