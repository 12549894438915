import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Controls from "../controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import {
  buyerRolePermission,
  changeCurrentSelectedRole,
} from "../../store/actions/authorizationAction";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../services/AxiosInstance";
import { setRoleInLocalStorage, updateUserDetails } from "../../utility/utils";
import { roleUpdateAction } from "../../store/actions/authActions";
import { Api } from "../../api/Api";
import { GET_USER_API } from "../../constants/ApiUrl";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(4),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function BuyerPermissionModal(props) {
  const { confirmDialog, setConfirmDialog, setToggleRole } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector((state) => state.auth.auth.user);
  const confirmBuyer = async () => {
    // let res = dispatch(
    //   buyerRolePermission(
    //     users._id,
    //     confirmDialog,
    //     setConfirmDialog,
    //     history,
    //     setToggleRole
    //   )
    // );
    const postData = {
      role: confirmDialog.role,
    };
    const response = await Api(
      `${GET_USER_API}/${users._id}/role/update`,
      postData,
      "patch"
    );
    if (response.status === 200) {
      setConfirmDialog({ ...confirmDialog, isOpen: false });

      // let userDetail = getUserDetails();
      updateUserDetails(
        response?.data?.updatedData?.isBuyer,
        response?.data?.updatedData?.role
      );

      setRoleInLocalStorage(confirmDialog.role);
      setToggleRole(
        confirmDialog.role === "seller" ? "supplier" : confirmDialog.role
      );
      dispatch(changeCurrentSelectedRole(response?.data?.updatedData?.role));

      dispatch(
        roleUpdateAction({
          role: response?.data?.updatedData?.role,
          isSeller: response?.data?.updatedData?.isSeller,
          isBuyer: response?.data?.updatedData?.isBuyer,
        })
      );
      if (confirmDialog.role === "buyer") {
        history.push("/dashboard");
      } else {
        history.push("/supplier/auction");
      }
    }

    // axiosInstance
    //   .patch(`/api/users/${users._id}/role/update`, postData)
    //   .then((response) => {
    //     setConfirmDialog({ ...confirmDialog, isOpen: false });

    //     // let userDetail = getUserDetails();
    //     updateUserDetails(
    //       response?.data?.updatedData?.isBuyer,
    //       response?.data?.updatedData?.role
    //     );

    //     setRoleInLocalStorage(confirmDialog.role);
    //     setToggleRole(
    //       confirmDialog.role === "seller" ? "supplier" : confirmDialog.role
    //     );
    //     dispatch(changeCurrentSelectedRole(response?.data?.updatedData?.role));

    //     dispatch(
    //       roleUpdateAction({
    //         role: response?.data?.updatedData?.role,
    //         isSeller: response?.data?.updatedData?.isSeller,
    //         isBuyer: response?.data?.updatedData?.isBuyer,
    //       })
    //     );
    //     if (confirmDialog.role === "buyer") {
    //       history.push("/dashboard");
    //     } else {
    //       history.push("/supplier/auction");
    //     }
    //   })
    //   .catch((error) => {});
  };
  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      {/* <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className={classes.titleIcon}>
                    <NotListedLocationIcon />
                </IconButton>
            </DialogTitle> */}
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Controls.Button
          text="No"
          color="default"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
        <Controls.Button
          text="Yes"
          color="primary"
          onClick={() => confirmBuyer()}
        />
      </DialogActions>
    </Dialog>
  );
}
