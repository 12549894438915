import { Box, Grid, Paper } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import { Form, useForm } from "../../../../components/useForm";
import { Slide, toast } from "react-toastify";
import { TOAST_AUTO_CLOSE } from "../../../../constants/AppConstants";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { store } from "../../../../store/store";
import {
  GET_AUCTION_ITEM_API,
  GET_ITEM_CATEGORY_API,
  POST_RFX_ADD_ITEM_API,
} from "../../../../constants/ApiUrl";
import { Api } from "../../../../api/Api";
import CloseIcon from "@mui/icons-material/Close";
import Formsteps from "../../../../components/Formsteps";
import Controls from "../../../../components/controls/Controls";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import UploadIcon from "@mui/icons-material/Upload";
import Search from "@material-ui/icons/Search";
import Autocomplete from "@mui/material/Autocomplete";
import ImportItemsPopup from "../../../Auction/components/ImportItems";
import {
  RfxFormStep5Add,
  rfxSaveAsDraft,
} from "../../../../store/actions/rfxAction";
import CommonButton from "../../../../components/controls/CommonButton";

const initialValues = {
  itemName: "",
  category: "",
  unit: "",
  quantity: "",
};

const columns = [
  {
    label: "Item Name",
  },
  {
    label: "Category",
  },
  {
    label: "Unit",
  },
  {
    label: "Qty",
  },
  {
    label: "",
  },
];

const RfxFormStep5 = ({
  openPopupStep5,
  setOpenPopupStep4,
  setOpenPopupStep5,
  setOpenPopupStep6,
  discardChanges,
  stepLabels,
  setResendApi,
  fromEditTab,
  getItemList,
  rfxDetails,
}) => {
  const rfxData = useSelector((state) => state.rfx);
  const { rfx } = store.getState();
  const { auth } = store.getState();
  const companyId = auth?.auth?.user?.company?._id;
  const dispatch = useDispatch();
  const tableRowsRef = useRef([]);
  const [allItemList, setAllItemList] = useState([]);
  const [itemForTable, setItemForTable] = useState([]);
  const [importItemsPopup, setImportItemsPopup] = useState(false);
  const [items, setItems] = useState([]);
  const [dropdownCategory, setDropdownCategory] = useState([]);

  const changeHandler1 = (e, index) => {
    let newArr = [...items]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value;
    setItems(newArr);
  };

  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("itemName" in fieldValues)
      temp.itemName = fieldValues.itemName ? "" : "This field is required.";
    if ("category" in fieldValues)
      temp.category = fieldValues.category ? "" : "This field is required.";
    if ("unit" in fieldValues)
      temp.unit = fieldValues.unit ? "" : "This field is required.";
    if ("quantity" in fieldValues)
      temp.quantity = fieldValues.quantity ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const getCategoryName = (category) => {
    const CateItem = dropdownCategory.find(
      (element) => element._id === category
    );
    return CateItem && CateItem.name ? CateItem.name : "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const sameItem = allItemList.find(
        (item) => item.label.trim() === values.itemName.trim()
      );
      const newItem = items.find(
        (item) => item.itemName.trim() === values.itemName.trim()
      );
      if ((sameItem && sameItem.id) || (newItem && newItem.itemName)) {
        toast.error(ValidationMessage.itemExist, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
      const uniqId = Math.floor(100000 + Math.random() * 900000);
      values.category = values.category;
      values.itemId = "R" + uniqId;
      values.itemName = values.itemName;
      items.push(values);
      setItems(items);
      resetForm();
      let newItems = [];

      items.map((it) => {
        let tmp = {
          itemName: it.itemName,
          category: it.category,
          unit: it.unit,
          quantity: it.quantity,
          itemId: it.itemId,
        };
        newItems.push(tmp);
      });
      if (!fromEditTab) dispatch(RfxFormStep5Add({ Items: newItems }));
    }
  };

  const clickOnNext = async () => {
    if (items && items.length > 0) {
      let qtyCheck = false;
      let categoryCheck = false;
      items.map((item) => {
        if (item.quantity === "") {
          qtyCheck = true;
          return item;
        }
        // else if (!item?.category || item?.category.length === 0) {
        //   categoryCheck = true;
        //   return item;
        // }
      });
      if (qtyCheck) {
        toast.error(ValidationMessage.itemQuantity, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
      // if (categoryCheck) {
      //   toast.error(ValidationMessage.itemCategory, {
      //     transition: Slide,
      //     autoClose: TOAST_AUTO_CLOSE,
      //   });
      //   return;
      // }

      if (!fromEditTab) {
        dispatch(RfxFormStep5Add({ Items: items }));
        setOpenPopupStep5(false);
        setOpenPopupStep6(true);
      } else {
        const response = await Api(
          `${POST_RFX_ADD_ITEM_API}/${rfxDetails?._id}/${companyId}`,
          { items: [...items] },
          "post"
        );
        if (response.status === 200) {
          if (response?.data?.responseCode === "00") {
            setOpenPopupStep5(false);
            getItemList();
          }
        }
      }
    } else {
      toast.error(ValidationMessage.addItem, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const saveAsDraft = async () => {
    if (items && items.length > 0) {
      dispatch(RfxFormStep5Add({ Items: items }));

      let res = await dispatch(rfxSaveAsDraft(values));

      if (res?.data?.responseCode === "00") {
        if (setResendApi) {
          setResendApi(true);
        }
        setOpenPopupStep5(false);
      } else {
        setOpenPopupStep5(false);
      }
    }
  };

  const toggleEditableRowMode = (ref) => {
    ref.classList.toggle("__select");
    // toggle option list
    const optionBoxes = ref.querySelectorAll(".option-cell");
    for (let index = 0; index < optionBoxes.length; index++) {
      const box = optionBoxes[index];
      box.classList.toggle("__active");
    }
    // toggle editable field
    const fieldBoxes = ref.querySelectorAll(".editable-field");
    for (let index = 0; index < fieldBoxes.length; index++) {
      const field = fieldBoxes[index];
      field.classList.toggle("__active");
    }
  };

  const getAllItem = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(
      `${GET_AUCTION_ITEM_API}/${companyId}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.items;
        let newItemArr = [];
        allItems.map((item) => {
          let temp = {
            category: item.category,
            label: item.item_name,
            unit: item.unit,
            id: item._id,
            itemId: item.itemId,
          };
          newItemArr.push(temp);
        });
        setAllItemList(newItemArr);
      } else {
        setAllItemList([]);
      }
    } else {
      setAllItemList([]);
    }
  };

  const getItemCategory = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_ITEM_CATEGORY_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let CatAndGroups = response.data.itemsCatAndGroups;
          setDropdownCategory(CatAndGroups);
        }
      }
    }
  };

  const getSelectedItem = (data) => {
    if (data) {
      const alreadyAddedItem = items.find((obj) => obj?.id === data?.id);
      if (alreadyAddedItem && alreadyAddedItem.id) return;
      const rendomNum = Math.floor(100000 + Math.random() * 900000);
      const selectedItem = {
        category: data?.category,
        itemId: data.itemId,
        id: data?.id,
        itemName: data.label,
        unit: data.unit,
        quantity: "",
      };

      items.push(selectedItem);
      const neItems = [...items];
      setItems(neItems);
      itemForTable.push(items);
      setItemForTable(itemForTable);
      if (!fromEditTab) dispatch(RfxFormStep5Add({ Items: neItems }));
    }
  };

  const removeSelectIndex = (id) => {
    // const removeIndex = items.pop(index, 1);

    const filterData = items.filter((obj) => obj.id !== id);
    const neItems = [...filterData];
    setItems(neItems);
  };

  const pressBack = () => {
    setOpenPopupStep5(false);
    setOpenPopupStep4(true);
  };

  useEffect(() => {
    getAllItem();
    getItemCategory();
    if (rfx?.RfxForm1?.Items && !fromEditTab) {
      setItems(rfx?.RfxForm1?.Items);
    }
  }, []);

  const getImportBoxSize = (dir = "left") => {
    if (dir === "left") {
      if (xlScreen) {
        return "w83-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w60-percent-box";
      } else if (mdScreen) {
        return "w73-percent-box";
      } else if (lgScreen) {
        return "w79-percent-box";
      }
    } else {
      if (xlScreen) {
        return "w16-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w40-percent-box";
      } else if (mdScreen) {
        return "w26-percent-box";
      } else if (lgScreen) {
        return "w20-percent-box";
      }
    }
  };

  useEffect(() => {
    if (!rfxData?.RfxForm1?.Items || fromEditTab) {
      setItems([]);
      resetForm();
    }
  }, [openPopupStep5]);

  return (
    <Modal
      open={openPopupStep5}
      aria-label="create-auction-fourth-step-buyer-aspect"
      aria-describedby="modal-modal-description"
      className="auction-form-container"
    >
      <Box className="popup-form-container">
        <>
          <Grid container>
            <Grid item xs={9}>
              {!fromEditTab ? (
                <p className="profile-title">
                  Create {rfxData?.RfxForm1?.rfx_type}
                </p>
              ) : (
                <p className="profile-title">Add Item</p>
              )}
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  if (!fromEditTab) discardChanges();
                  else setOpenPopupStep5(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <div className="create-form-container">
            {!fromEditTab && (
              <Grid container justifyContent="center">
                <Grid item md={11} xs={12}>
                  <Formsteps
                    steps={stepLabels}
                    currStep={5}
                    className="mt-5 mb-5"
                  />
                </Grid>
              </Grid>
            )}
            <Paper elevation={0} square>
              {/* import item Section  */}
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid className={getImportBoxSize("left")}>
                  <Autocomplete
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    labelId="category-input"
                    options={allItemList}
                    openOnFocus={true}
                    onChange={(event, value) => getSelectedItem(value)}
                    getOptionLabel={(option) => (option ? option.label : "")}
                    renderInput={(params) => (
                      <Controls.Input fullWidth {...params} />
                    )}
                    popupIcon={<Search />}
                  />
                </Grid>
                <Grid className={getImportBoxSize("right")}>
                  <Controls.Button
                    component="span"
                    text="Import Items"
                    onClick={() => setImportItemsPopup(true)}
                    disableElevation
                    color="default"
                    startIcon={<UploadIcon />}
                    className="btn-mui btn-white auto-complete-btn"
                  />
                </Grid>
              </Grid>
              {/* import item Section  */}
              <TableContainer
                className="table-container editable-table"
                style={{ position: "relative" }}
                component={Paper}
              >
                {/* Dynamic item form  */}
                <Form onSubmit={handleSubmit}>
                  <div id="dynamic-item-form-container">
                    <TableCell>
                      <Controls.Input
                        name="itemName"
                        fullWidth
                        value={values.itemName}
                        error={errors.itemName}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <Controls.Select
                        name="category"
                        labelId="category-input"
                        value={values.category}
                        fullWidth
                        onChange={handleInputChange}
                        options={dropdownCategory}
                        error={errors.category}
                      />
                    </TableCell>
                    <TableCell>
                      <Controls.Input
                        name="unit"
                        labelId="starting_price-input"
                        fullWidth
                        value={values.unit}
                        error={errors.unit}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <Controls.Input
                        name="quantity"
                        labelId="starting_price-input"
                        fullWidth
                        value={values.quantity}
                        error={errors.quantity}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell className="text-center">
                      <Controls.Button
                        type="submit"
                        disableElevation
                        className="btn-mui add-btn"
                        endIcon={<AddIcon className="add-icon m-0" />}
                      />
                    </TableCell>
                  </div>
                </Form>
                {/* Dynamic item form  */}

                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item, index) => (
                      <TableRow
                        key={item._id}
                        ref={(el) => (tableRowsRef.current[index] = el)}
                        className="editable-row"
                      >
                        <TableCell>{item.itemName}</TableCell>
                        <TableCell>
                          <Box className="editable-field ">
                            {getCategoryName(item.category)}
                          </Box>
                          <Box className="editable-field __active">
                            <Controls.Select
                              fullWidth
                              options={dropdownCategory}
                              name="category"
                              value={item.category || ""}
                              onChange={(event) => changeHandler1(event, index)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell className="clr-2170ff">
                          <Box className="editable-field ">{item.unit}</Box>
                          <Box className="editable-field __active">
                            <TextField
                              fullWidth
                              name="unit"
                              value={item.unit || ""}
                              onChange={(event) => changeHandler1(event, index)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className="editable-field ">{item.quantity}</Box>
                          <Box className="editable-field __active">
                            <TextField
                              fullWidth
                              name="quantity"
                              value={item.quantity || ""}
                              onChange={(event) => changeHandler1(event, index)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className="option-cell ">
                            <EditIcon
                              className="icon __edit"
                              onClick={() =>
                                toggleEditableRowMode(
                                  tableRowsRef.current[index]
                                )
                              }
                            />
                            <DeleteOutlineIcon
                              className="icon __edit clr-ff0000"
                              onClick={() => removeSelectIndex(item.id)}
                            />
                          </Box>
                          <Box className="option-cell __active">
                            <CheckIcon
                              className="icon __check"
                              onClick={() =>
                                toggleEditableRowMode(
                                  tableRowsRef.current[index]
                                )
                              }
                            />
                            <CloseIcon
                              className="icon __close"
                              onClick={() =>
                                toggleEditableRowMode(
                                  tableRowsRef.current[index]
                                )
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            {!fromEditTab ? (
              <Box
                className="BottomModal"
                display="flex"
                p={1}
                bgcolor="background.paper"
                style={{ marginTop: "35px" }}
              >
                <Grid istem xs={4} className="BottomModal-left">
                  <Box p={0} flexGrow={1}>
                    <Controls.Button
                      text="Back"
                      disableElevation
                      onClick={() => {
                        pressBack();
                      }}
                      className="btn-mui fix-w-12em"
                      color="default"
                      style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={8} className="BottomModal-right">
                  <Box p={0}>
                    <Controls.Button
                      text="Save as Draft"
                      // type="submit"
                      disableElevation
                      color="default"
                      style={{ margin: "0 20px 0 0" }}
                      onClick={() => saveAsDraft()}
                      className="btn draft btn-mui fix-w-12em"
                    />
                  </Box>
                  <Box p={0}>
                    <Controls.Button
                      type="submit"
                      disableElevation
                      text="Next"
                      onClick={() => clickOnNext()}
                      className="btn-mui fix-w-12em"
                    />
                  </Box>
                </Grid>
              </Box>
            ) : (
              <Box p={0} className="global-flex-center">
                <CommonButton
                  disableElevation
                  type="submit"
                  text="Save"
                  className="btn-mui fix-w-12em "
                  onClick={() => clickOnNext()}
                />
              </Box>
            )}
          </div>

          <ImportItemsPopup
            importItemsPopup={importItemsPopup}
            setImportItemsPopup={setImportItemsPopup}
            items={items}
            setItems={setItems}
          />
        </>
      </Box>
    </Modal>
  );
};

export default RfxFormStep5;
