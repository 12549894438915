import { GOIN_LIVE_BID_GROUP_EVENT } from "../constants";
import SocketHandler from "../SocketHandler";
import SocketResponse from "../SocketResponse";

const GoinBidGroupEvent = (data = {}) => {
  SocketHandler.instance.emit(
    GOIN_LIVE_BID_GROUP_EVENT,
    new SocketResponse(data).transform()
  );
  return true;
};

export default GoinBidGroupEvent;
