/*eslint-disable */
import { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import * as employeeService from "../../services/employeeService";
import { makeStyles } from "@material-ui/styles";
import Formsteps from "../../components/Formsteps";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { connect, useDispatch } from "react-redux";
import { store } from "../../store/store";
import { saveSecondFormValue } from "../../store/actions/ProjectActions";
import "./styles/create-project-step2.css";
import { InputLabel } from "@mui/material";
import {
  ACCEPT_ONLY_IMAGE,
  ITEM_ATTACHMENTS_SIZE,
  ONLY_APLPHABET_REGEX,
  TOAST_AUTO_CLOSE,
} from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { Api } from "../../api/Api";
import { GET_BUISNESS_UNIT_API } from "../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";

const initialFValues = {
  business_unit: "",
  department: "",
  project_reference_number: "",
  project_manager: "",
  currency: "",
  project_status: "",
  image: "",
};

const useStyles = makeStyles((theme) => ({
  errMsg: {
    color: "red",
    fontSize: "14px",
    margin: "3px 0",
  },
  imgPreview: {
    width: "100%",
    height: "460px",
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#ccc",
    padding: "100px",
    border: "1px solid #000",
    background: "#cccccc",
  },

  customFileUpload: {
    color: "#55d6d6",
    fontSize: "22px",
    fontWeight: "500",
    padding: "6px 12px",
    cursor: "pointer",
  },
  files: { padding: "5px" },
  customeFileUpload: {
    position: "absolute",
    padding: "47px 20px",
    cursor: "pointer",
  },
  formPart1: {
    "& .MuiFormControl-root": {
      width: "90%",
    },
  },
  Button: {
    padding: ".4rem 2rem",
    borderRadius: "10px",
    fontWeight: "700",
    fontSize: "14px",
  },
}));

const ProjectFormstep2 = (props) => {
  const classes = useStyles();
  const {
    addOrEdit,
    recordForEdit,
    openPopup,
    setOpenPopup,
    openPopup2,
    setOpenPopup2,
    projects,
    setSaveProjectDraft,
    stepLabels,
    setNotify,
  } = props;
  const dispatch = useDispatch();
  const [imgPreview, setImgPreview] = useState("");
  const [img, setImg] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [error, setError] = useState();
  const [imgError, setImgError] = useState("");
  const [businessUnitList, setBuisnessUnitList] = useState([]);
  // const [notify, setNotify] = useState({
  //   isOpen: false,
  //   message: "",
  //   type: "",
  // });
  const countryList = employeeService.currencyList();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    // if ("business_unit" in fieldValues)
    //   temp.business_unit = fieldValues.business_unit
    //     ? ""
    //     : "Business Unit is required.";

    if ("currency" in fieldValues)
      temp.currency =
        fieldValues.currency && fieldValues.currency.length > 0
          ? ""
          : "Currency is required.";

    if ("project_status" in fieldValues)
      temp.project_status =
        fieldValues.project_status && fieldValues.project_status.length > 0
          ? ""
          : "Project Status is required.";

    if ("project_manager" in fieldValues) {
      if (
        fieldValues.project_manager &&
        fieldValues.project_manager.length > 0
      ) {
        if (ONLY_APLPHABET_REGEX.test(fieldValues.project_manager)) {
          temp.project_manager = "";
        } else {
          temp.project_manager = ValidationMessage.alphabetOnly;
        }
      } else {
        temp.project_manager = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      values.image = img ? img : " ";
      let formData = new FormData();

      if (imgFile && imgFile.type) {
        values.document = imgFile;
      }
      addOrEdit(values, resetForm);
     
    }
  };

  const maxSize = 200;

  const validateImageSize = (file) => {
    if (file) {
      const fsize = file.size / 1024;
      if (fsize > maxSize) {
        setImgError(`File too large, ${maxSize}, ${fsize}`);
        return false;
      } else {
        setImgError("");
        return true;
      }
    }
  };

  const saveProjectAsDraft = () => {
    if (validate()) {
      if (imgError) {
        toast.error(ValidationMessage.notCaptureImage, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
      setSaveProjectDraft(true);
      values.image = img ? img : null;
      if (imgFile && imgFile.type) values.document = imgFile;
      addOrEdit(values, resetForm);
      // dispatch(saveAsDraft(values));
    } else {
      toast.error(ValidationMessage.somethingWrong, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && !ACCEPT_ONLY_IMAGE.includes(file.type)) {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (file && Math.round(file.size / 1024) > ITEM_ATTACHMENTS_SIZE) {
      toast.error(ValidationMessage.itemAttachmentFileSize, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (file) {
      setImgFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        // set the state to the base64string
        setImgPreview(base64String);
        setImg(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const getBusinessUnit = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_BUISNESS_UNIT_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let businessUnit = response.data.businessUnits;
          setBuisnessUnitList(businessUnit);
        }
      }

      // await axiosInstance
      //   .get(`api/company/getBusinessUnits/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let businessUnit = response.data.businessUnits;
      //       setBuisnessUnitList(businessUnit);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const pressBack = () => {
    if (validate()) {
      if (img !== "") {
        values.selectedImage = img;
      }
      dispatch(saveSecondFormValue({ form2project: values }));
    }
    setOpenPopup(true);
    setOpenPopup2(false);
  };

  useEffect(() => {
    setSaveProjectDraft(false);
    const { projects } = store.getState();
    if (projects.projectsFirstForm) {
      setValues({
        business_unit: projects?.projectsFirstForm?.business_unit,
        department: projects?.projectsFirstForm?.department,
        project_reference_number:
          projects?.projectsFirstForm?.project_reference_number,
        project_manager: projects?.projectsFirstForm?.project_manager,
        currency: projects?.projectsFirstForm?.currency,
        project_status: projects?.projectsFirstForm?.project_status,
      });
      if (
        projects &&
        projects.projectsFirstForm &&
        projects.projectsFirstForm.selectedImage
      ) {
        setImgPreview(projects?.projectsFirstForm?.selectedImage);
        setImg(projects?.projectsFirstForm?.selectedImage);
      }
    }
    getBusinessUnit();
  }, [recordForEdit]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container justifyContent="center">
          <Grid item xl={5} lg={6} md={7} sm={8} xs={12}>
            <Formsteps
              steps={stepLabels}
              currStep={2}
              className="mt-5 mb-5"
            ></Formsteps>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="mt-3">
          <Grid item sm={6} xs={12}>
            <InputLabel>Select Business Unit</InputLabel>
            <Controls.Select
              name="business_unit"
              value={values.business_unit}
              onChange={handleInputChange}
              options={businessUnitList}
              error={errors.business_unit}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel>Internal Project Reference ID</InputLabel>
            <Controls.Input
              name="project_reference_number"
              value={values.project_reference_number}
              onChange={handleInputChange}
              error={errors.project_reference_number}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel>Department</InputLabel>
            <Controls.Input
              name="department"
              value={values.department}
              onChange={handleInputChange}
              error={errors.department}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel>Project Manager</InputLabel>
            <Controls.Input
              name="project_manager"
              value={values.project_manager}
              onChange={handleInputChange}
              error={errors.project_manager}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mt-5">
          <Grid item md={6} xs={12}>
            <InputLabel>Image Upload</InputLabel>
            <Box display="flex">
              <Box
                style={{
                  maxWidth: "150px",
                  height: "141px",
                  marginRight: "20px",
                }}
              >
                <div className="container">
                  {imgError && (
                    <p
                      className="errMsg"
                      style={{
                        color: "red",
                        fontSize: "11px",
                        margin: "3px 0",
                        paddingLeft: "10px",
                      }}
                    >
                      {imgError}
                    </p>
                  )}
                  <div
                    className="imgPreview attach_box"
                    style={{
                      background: imgPreview
                        ? `url("${imgPreview}") no-repeat center/cover`
                        : "#e2f2ff",
                    }}
                  >
                    {!imgPreview && (
                      <>
                        <p>
                          <AddCircleIcon color="primary" />
                        </p>
                        <label
                          htmlFor="fileUploadButton"
                          className="customeFileUpload"
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                          }}
                        >
                          &nbsp;
                        </label>
                        <input
                          type="file"
                          id="fileUploadButton"
                          hidden
                          onChange={(e) => handleImageChange(e)}
                          // value={values?.image}
                          accept={ACCEPT_ONLY_IMAGE}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Box>
              <Box>
                <label htmlFor="fileUploadButton">
                  <Controls.Button
                    component="span"
                    text="Choose File"
                    disableElevation
                    style={{
                      width: "150px",
                      borderRadius: "25px",
                      fontWeight: "600",
                      height: "40px",
                    }}
                  />
                  <input
                    type="file"
                    id="fileUploadButton"
                    hidden
                    onChange={(e) => handleImageChange(e)}
                    // value={values?.image}
                    accept={ACCEPT_ONLY_IMAGE}
                  />
                </label>
                {imgPreview && (
                  <Controls.Button
                    text="Delete"
                    color="default"
                    onClick={() => setImgPreview(null)}
                    className="btn draft"
                    style={{
                      width: "97px",
                      height: "40px",
                      borderRadius: "25px",
                      fontWeight: "600",
                    }}
                  />
                )}
                <div
                  className="mt-2"
                  style={{
                    fontSize: "12px",
                    color: "#A19B9D",
                  }}
                >
                  540x540 px recommended. JPG or PNG. Max size of 10MB
                </div>
              </Box>
            </Box>
          </Grid>

          <Grid item md={6} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={12} sm={6} xs={12}>
                <InputLabel>
                  Currency<span className="mandatorySign">*</span>
                </InputLabel>
                <Controls.Select
                  name="currency"
                  value={values.currency}
                  onChange={handleInputChange}
                  options={countryList}
                  error={errors.currency}
                />
              </Grid>
              <Grid item md={12} sm={6} xs={12}>
                <InputLabel>
                  Project Status<span className="mandatorySign">*</span>
                </InputLabel>
                <Controls.Select
                  name="project_status"
                  value={values.project_status}
                  onChange={handleInputChange}
                  options={employeeService.projectStatus()}
                  error={errors.project_status}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div id="bottom-button-box-container">
          <div id="right-side">
            <Controls.Button
              text="Back"
              onClick={() => pressBack()}
              disableElevation
              className="btn btn-mui w-100 m-0"
              style={{
                backgroundColor: "#E2F2FF",
                color: "#2170FF",
              }}
            />
          </div>
          <div id="left-side">
            <div>
              <Controls.Button
                text="Save as Draft"
                type="submit"
                disableElevation
                color="default"
                // onClick={() => saveProjectAsDraft()}
                onClick={() => setSaveProjectDraft(true)}
                className="btn btn-mui draft w-100 m-0"
              />
            </div>
            <div>
              <Controls.Button
                disableElevation
                type="submit"
                text="Create"
                className="btn btn-mui w-100 m-0"
              />
            </div>
          </div>
        </div>
      </Form>
      {/* <Notification notify={notify} setNotify={setNotify} /> */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    projects: state.projects.projectsFirstForm,
  };
};

export default connect(mapStateToProps)(ProjectFormstep2);
