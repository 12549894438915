import { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { InputLabel } from "@mui/material";
import Controls from "../../../../components/controls/Controls";
import { useForm, Form } from "../../../../components/useForm";
import { Api } from "../../../../api/Api";
import {
  POST_ADD_ATTACH_LINK_RFX_API,
  POST_EDIT_ATTACH_LINK_RFX_API,
} from "../../../../constants/ApiUrl";
import { useSelector } from "react-redux";
const initialFValues = {
  link: "",
  name: "",
  notes: "",
};
const LinkAttachmentPopupRfx = (props) => {
  const {
    setLinkAttachmentPopup,
    editLinkId,
    editLinkData,
    isEditMode,
    setIsEditMode,
    getDocumets,
    setEditLinkData,
  } = props;
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const clickClosePopup = () => {
    setLinkAttachmentPopup(false);
    resetForm();
    setEditLinkData({});
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("link" in fieldValues)
      temp.link = fieldValues.link ? "" : "This field is required.";
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("notes" in fieldValues)
      temp.notes = fieldValues.notes ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (rfxDetails !== undefined && rfxDetails._id !== undefined) {
        const payload = {
          link: values.link,
          title: values.name,
          notes: values.notes,
        };
        if (!isEditMode) {
          const response = await Api(
            `${POST_ADD_ATTACH_LINK_RFX_API}/${rfxDetails._id}`,
            payload,
            "post"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              resetForm();
              setLinkAttachmentPopup(false);
              getDocumets();
            }
          }
        } else {
          payload.type = "link";
          const response = await Api(
            `${POST_EDIT_ATTACH_LINK_RFX_API}/${rfxDetails._id}/${editLinkId}`,
            payload,
            "post"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setLinkAttachmentPopup(false);
              getDocumets();
              setIsEditMode(false);
              setEditLinkData({});
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (editLinkData && editLinkData.link) {
      editLinkData.name = editLinkData.title;
      setValues(editLinkData);
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel className="mui-label">Link</InputLabel>
          <Controls.Input
            className="mui-input"
            fullWidth
            name="link"
            onChange={handleInputChange}
            value={values.link}
            error={errors.link}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className="mui-label">Name</InputLabel>
          <Controls.Input
            className="mui-input"
            fullWidth
            name="name"
            onChange={handleInputChange}
            value={values.name}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className="mui-label">Notes</InputLabel>
          <Controls.Input
            fullWidth
            name="notes"
            onChange={handleInputChange}
            className="mui-input fix-h-8em"
            value={values.notes}
            error={errors.notes}
          />
        </Grid>
      </Grid>
      <Box display="flex" mt={4} bgcolor="background.paper">
        <Grid istem xs={4} className="BottomModal-left">
          <Box p={0} flexGrow={1}>
            <Controls.Button
              text="Cancel"
              disableElevation
              onClick={() => {
                clickClosePopup();
              }}
              style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
              color="default"
              className="btn-mui"
            />
          </Box>
        </Grid>
        <Grid item xs={8} className="BottomModal-right">
          <Box p={0}>
            <Controls.Button
              type="submit"
              disableElevation
              text="Attach"
              className="btn-mui"
              style={{ minWidth: "197px" }}
            />
          </Box>
        </Grid>
      </Box>
    </Form>
  );
};

export default LinkAttachmentPopupRfx;
