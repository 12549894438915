import { Api } from "../../../../api/Api";
import { POST_SUPPLIER_INVITATION_STATUS_API } from "../../../../constants/ApiUrl";
import { AUCTION_DECLINED } from "../../../../constants/AppConstants";

const DeclineAuctionBL = async (props) => {
  const { auctionId, supplierId } = props;

  let success = false;
  let message = "You have been declined in auction successfully !";
  try {
    const response = await Api(
      `${POST_SUPPLIER_INVITATION_STATUS_API}/${auctionId}`,
      {
        status: AUCTION_DECLINED,
      },
      "post"
    );
    if (response.status === 200) {
      success = true;
      message = response.data.responseDescription
        ? response.data.responseDescription
        : message;
    } else {
      message = response?.data?.responseDescription
        ? response?.data?.responseDescription
        : "You can not decline from this auction";
    }

    // let response = await axiosInstance.post(
    //   `api/company/supplierInvitationStatus/${auctionId}`,
    //   {
    //     status: "declined",
    //   }
    // );

    // if (response.status == 200) {
    //   success = true;
    //   message = response.data.responseDescription
    //     ? response.data.responseDescription
    //     : message;
    // } else {
    //   message = response.data.responseDescription
    //     ? response.data.responseDescription
    //     : "You can not decline from this auction";
    // }
  } catch (err) {
    // Handle Error Here

    // message = err.response.data.responseDescription
    //   ? err.response.data.responseDescription
    //   : err.message;
  }
  return {
    success: success,
    message: message,
  };
};

export default DeclineAuctionBL;
