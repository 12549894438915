import { Api } from "../../../../api/Api";

const ToggleUserStatusBL = async (props) => {
  const { user } = props;

  let success = false;
  let message = "";

  try {
    let path = `/api/users/admin/${user._id}/toggle`;
    const response = await Api(path, {}, "post");
    if (response.status === 200) {
      message = response.data.responseDescription;
      success = true;
    } else {
      message = response?.data?.responseDescription
        ? response?.data?.responseDescription
        : response?.message;
    }

    // const response = await axiosInstance.post(path);

    // if (response.status == 200) {
    //   message = response.data.responseDescription;
    //   success = true;
    // }
  } catch (err) {
    // Handle Error Here
    // if (err.response) {
    //   message = err.response.data.responseDescription
    //     ? err.response.data.responseDescription
    //     : err.message;
    // }
  }

  return {
    success: success,
    message: message,
  };
};

export default ToggleUserStatusBL;
