import { useState, useEffect } from "react";
import FormSteps from "../../../../components/Formsteps";
import { Box, Grid, Tooltip } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import * as employeeService from "../../../../services/employeeService";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import "../../../Auction/styles/create-auction-step2.css";
import { Form, useForm } from "../../../../components/useForm";
import Controls from "../../../../components/controls/Controls";
import { Api } from "../../../../api/Api";
import { GET_COST_CENTER_API } from "../../../../constants/ApiUrl";
import { store } from "../../../../store/store";
import { removeNullValueForPaylaod } from "../../../../utility/utils";
import NumericSeparatorInput from "../../../../components/NumericSeparatorInput";
import {
  RFXFormStep2Add,
  rfxSaveAsDraft,
} from "../../../../store/actions/rfxAction";
import Modal from "@mui/material/Modal";
import moment from "moment";

const initialValues = {
  const_center: "",
  currency: "",
  budget: "",
  deliveryDate: "",
  supplier_to_provide: "",
};
const dropdownSupplierProvider = [
  { id: 1, title: "Price for each items" },
  { id: 2, title: "Lumpsum" },
];
const RfxFormStep2 = ({
  setOpenPopupStep1,
  setOpenPopupStep2,
  setOpenPopupStep3,
  setResendApi,
  stepLabels,
  openPopupStep2,
  projects,
  discardChanges,
}) => {
  const [dropdownCostCenter, setDropdownCostCenter] = useState([]);
  const [auctionSaveDraft, setauctionSaveDraft] = useState(false);
  const [uploadDocumentCheckbox, setUploadDocumentCheckbox] = useState(false);
  const [sealedBidCheckbox, setSealedBidCheckbox] = useState(false);
  const dispatch = useDispatch();
  const rfxData = useSelector((state) => state.rfx);
  const [deliveryDate, setDeliveryDate] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("deliveryDate" in fieldValues) {
      let todayDate = moment().format("MM-DD-YYYY");

      let deliveryDateFormatted = moment(deliveryDate).format("MM-DD-YYYY");

      if (moment(todayDate).isAfter(deliveryDateFormatted)) {
        temp.deliveryDate = "Delivery date must be greater than current date";
      } else {
        temp.deliveryDate = "";
      }
    }

    if ("currency" in fieldValues)
      temp.currency = fieldValues.currency ? "" : "This field is required.";
    if ("supplier_to_provide" in fieldValues)
      temp.supplier_to_provide = fieldValues.supplier_to_provide
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      if (!auctionSaveDraft) {
        values.sealedBidCheckbox = sealedBidCheckbox;
        values.uploadDocumentCheckbox = uploadDocumentCheckbox;
        values.deliveryDate = deliveryDate;
        const finalValues = removeNullValueForPaylaod(values);

        dispatch(RFXFormStep2Add(finalValues));
        setOpenPopupStep2(false);
        setOpenPopupStep3(true);
      } else {
        values.sealedBidCheckbox = sealedBidCheckbox;
        values.uploadDocumentCheckbox = uploadDocumentCheckbox;
        values.deliveryDate = deliveryDate;
        const finalValues = removeNullValueForPaylaod(values);
        dispatch(RFXFormStep2Add(finalValues));
        let res = await dispatch(rfxSaveAsDraft(finalValues));
        if (res?.data?.responseCode === "00") {
          if (setResendApi) {
            setResendApi(true);
          }
          setOpenPopupStep2(false);
        } else {
          setOpenPopupStep2(false);
        }
      }
      setauctionSaveDraft(false);
    } else {
      setauctionSaveDraft(false);
    }
  };

  const PressBack = () => {
    setOpenPopupStep2(false);
    setOpenPopupStep1(true);
  };

  const getAllCostCenter = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COST_CENTER_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let costCenterList = response.data.costCenter;
          setDropdownCostCenter(costCenterList);
        }
      }
    }
  };

  useEffect(() => {
    if (!rfxData?.RfxForm1?.currency) resetForm();
  }, [openPopupStep2]);

  useEffect(() => {
    getAllCostCenter();
    const { rfx } = store.getState();

    if (rfx && rfx?.RfxForm1?.currency) {
      setValues(rfx.RfxForm1);
      if (rfx?.RfxForm1?.sealedBidCheckbox) {
        setSealedBidCheckbox(true);
      }
      if (rfx?.RfxForm1?.deliveryDate) {
        setDeliveryDate(rfx?.RfxForm1?.deliveryDate);
      }
      if (rfx?.RfxForm1?.uploadDocumentCheckbox) {
        setUploadDocumentCheckbox(true);
      }
    } else {
      let selectedProject = projects.find(
        (project) => project._id === rfx.RfxForm1?.project_id
      );
      initialValues.currency = selectedProject
        ? selectedProject.currency
        : initialValues.currency;
      setValues(initialValues);
    }
  }, []);

  return (
    <Modal
      open={openPopupStep2}
      aria-label="create-auction-second-step-buyer-aspect"
      aria-describedby="modal-modal-description"
      className="auction-form-container"
    >
      <Box className="popup-form-container">
        <>
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title">
                Create {rfxData?.RfxForm1?.rfx_type}
              </p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => discardChanges()}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Form onSubmit={handleSubmit}>
            <Grid container justifyContent="center">
              <Grid item md={11} xs={12}>
                <FormSteps
                  steps={stepLabels}
                  currStep={2}
                  className="mt-5 mb-5"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item lg={4} sm={6} xs={12}>
                <InputLabel id="category-input">Cost Center</InputLabel>
                <Controls.Select
                  name="const_center"
                  // labelId="category-input"
                  value={values.const_center}
                  fullWidth
                  onChange={handleInputChange}
                  options={dropdownCostCenter}
                  error={errors.const_center}
                  // onChange={getBudgetForAuction}
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <InputLabel id="currency-input">
                  Currency <span className="mandatorySign">*</span>
                </InputLabel>
                <Controls.Select
                  name="currency"
                  // labelId="currency-input"
                  value={values.currency}
                  fullWidth
                  onChange={handleInputChange}
                  options={employeeService.currencyList()}
                  error={errors.currency}
                  // disabled={true}
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <InputLabel id="budget-input">Budget</InputLabel>
                <Controls.Input
                  name="budget"
                  // labelId="budget-input"
                  fullWidth
                  value={values.budget}
                  onChange={handleInputChange}
                  error={errors.budget}
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  InputProps={{
                    inputComponent: NumericSeparatorInput,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center" className="mt-5">
              <Grid
                item
                xs={4}
                className="datetime-picker-cotainer delivery-date"
              >
                <InputLabel id="date-input">Delivery Date</InputLabel>
                <Controls.BasicDatePicker
                  name="deliveryDate"
                  value={deliveryDate}
                  onChange={(e) => {
                    setDeliveryDate(e);
                  }}
                  format="dd-mm-yyyy"
                  error={errors.deliveryDate}
                  className="mui-date-picker-reverse"
                />
              </Grid>
              <Grid item lg={4} sm={6} className="">
                <InputLabel id="category-input">
                  Supplier to provide
                  <span className="mandatorySign">*</span>
                </InputLabel>
                <Controls.Select
                  name="supplier_to_provide"
                  value={values.supplier_to_provide}
                  fullWidth
                  onChange={handleInputChange}
                  options={dropdownSupplierProvider}
                  error={errors.supplier_to_provide}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center" className="mt-5">
              <Grid item lg={4} sm={6} xs={12} className="mt-3">
                <div className="checkbox-container rfx-checkbox">
                  <div className="d-flex">
                    <div
                      // className={
                      //   "checkbox checkbox-primary" +
                      //   (uploadDocumentCheckbox ? "checked" : "")
                      // }
                      className={`checkbox checkbox-primary rfx-step-2-checkbox ${
                        uploadDocumentCheckbox ? "checked" : ""
                      }`}
                      onClick={() => {
                        setUploadDocumentCheckbox(!uploadDocumentCheckbox);
                      }}
                    >
                      <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                      <CheckIcon aria-label="check-mark" />
                    </div>
                    Allow Supplier To Upload Documents
                  </div>
                </div>
              </Grid>
              <Grid item lg={4} md={6} sm={8} xs={12} className="mt-3">
                <div className="checkbox-container rfx-checkbox">
                  <Grid item xs={12} className="disclose-option">
                    <div className="d-flex">
                      <div
                        className={`checkbox checkbox-primary rfx-step-2-checkbox ${
                          sealedBidCheckbox ? "checked" : ""
                        }`}
                        onClick={() => {
                          setSealedBidCheckbox(!sealedBidCheckbox);
                        }}
                      >
                        <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                        <CheckIcon aria-label="check-mark" />
                      </div>
                      Sealed Bid
                      <Tooltip
                        title="To be determined"
                        classes={{ popper: "mui-tooltip mui-tooltip-primary" }}
                        placement="top-start"
                        arrow
                      >
                        <HelpIcon className="hint-icon" />
                      </Tooltip>
                    </div>
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Box
              className="BottomModal"
              display="flex"
              p={1}
              bgcolor="background.paper"
              style={{ marginTop: "20px" }}
            >
              <Grid item xs={4} className="BottomModal-left">
                <Box p={0} flexGrow={1}>
                  <Controls.Button
                    text="Back"
                    disableElevation
                    onClick={() => {
                      PressBack();
                    }}
                    style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                    color="default"
                    className="btn-mui fix-w-12em"
                  />
                </Box>
              </Grid>
              <Grid item xs={8} className="BottomModal-right">
                <Box p={0}>
                  <Controls.Button
                    text="Save as Draft"
                    type="submit"
                    disableElevation
                    color="default"
                    style={{ margin: "0 20px 0 0" }}
                    onClick={() => {
                      setauctionSaveDraft(true);
                    }}
                    className="btn draft btn-mui fix-w-12em"
                  />
                </Box>
                <Box p={0}>
                  <Controls.Button
                    type="submit"
                    disableElevation
                    text="Next"
                    className="btn-mui fix-w-12em"
                  />
                </Box>
              </Grid>
            </Box>
          </Form>
        </>
      </Box>
    </Modal>
  );
};

export default RfxFormStep2;
