import React from "react";
import {
  CircularProgress,
  Button as MuiButton,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    textTransform: "none",
  },
}));

export default function CommonButton(props) {
  const {
    text,
    size,
    color,
    variant,
    onClick,
    loader,
    loaderSize,
    disabled = false,
    ...other
  } = props;
  const classes = useStyles();

  return (
    <MuiButton
      variant={variant || "contained"}
      size={size || "large"}
      color={color || "primary"}
      onClick={onClick}
      {...other}
      classes={{ root: classes.root, label: classes.label }}
      disabled={loader || disabled}
    >
      {loader ? (
        <CircularProgress color="inherit" size={loaderSize || 18} />
      ) : (
        <>{text}</>
      )}
    </MuiButton>
  );
}
