/*eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Search from "@material-ui/icons/Search";

import { useForm } from "../../components/useForm";
import Notification from "../../components/Notification";
import Loader from "../../components/Loader";
import { store } from "../../store/store";
import axiosInstance from "../../services/AxiosInstance";
import "../../styles/filter-box.css";
import { connect, useDispatch } from "react-redux";
import "../Auction/styles/create-auction-step0.css";
import { withRouter } from "react-router-dom";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { currentUser } from "../../store/selectors/AuthSelectors";

import usePrevious from "../../components/usePrevious";
import ContractsTable from "./ContractsTable";
import AddContractModal from "./AddContractModal";
import ContractFilter from "./ContractFilter";
import GetAllContractsApi from "./CommonFunction/GetAllContractsApi";
import moment from "moment";
import { COMAPNY_API, PROJECT_LIST_API } from "../../constants/ApiUrl";
import { Api } from "../../api/Api";
import { downloadDocumentFile } from "../../utility/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
}));

const initialFValues = {};

const ContractsList = (props) => {
  const { isAdmin, isSeller, isBuyer, currentUser } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openAddContract, setOpenAddContract] = useState(false);

  const [isEditRecord, setIsEditRecord] = useState(false);
  const [editUserId, setEditUserId] = useState(undefined);
  const [contractList, setContractList] = useState([]);
  const [fullLoder, setFullLoder] = useState(false);
  const [dropdownProject, setDropdownProject] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [resendApi, setResendApi] = useState(false);
  const [numOfPages, setNumOfPages] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const prevPageValue = usePrevious(page);
  const [searchText, setSearchText] = useState(null);
  const [filters, setFilters] = useState(null);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getProjectListForDropdown = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(`${PROJECT_LIST_API}/${companyId}`, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.projectData;
        setDropdownProject(allItems);
      }
    }

    // await axiosInstance
    //   .get(`api/buyer/getProjectsList/${companyId}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allItems = response.data.projectData;
    //       setDropdownProject(allItems);
    //     }
    //   })
    //   .catch((err) => {});
    setOpenPopup(true);
    setFullLoder(false);
  };

  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
    getAllContractList(true, event.target.value);
  };

  useEffect(() => {
    if (resendApi) {
      setFullLoder(true);
      setResendApi(false);
      getAllContractList();
    }
  }, [resendApi]);

  useEffect(() => {
    if (currentUser) {
      setResendApi(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && prevPageValue !== undefined && prevPageValue !== page) {
      setResendApi(true);
    }
  }, [page]);

  const openAddContractModal = async () => {
    setOpenAddContract(true);
    resetForm();
  };

  const getSearchString = (e) => {
    setSearchText(e.target.value);
    setResendApi(true);
  };

  // TODO : use GetAllAuctionBL function instead .
  const ExportToExcel = async () => {
    setFullLoder(true);
    let contractFilter = JSON.parse(localStorage.getItem("contractFilter"));
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    let string = "?exportToExcel=1";
    let i = 0;
    const values = contractFilter;

    if (values) {
      for (let [key, value] of Object.entries(values)) {
        string += `&${key}=${value}`;
      }
    }

    if (
      values &&
      values.dateRange &&
      values.dateRange[0] !== null &&
      values.dateRange[1] !== null
    ) {
      string += `&startDate=${Moment(values.dateRange[0]).format(
        "YYYY-MM-DD 00:00:00"
      )}&endDate=${moment(values.dateRange[1]).format("YYYY-MM-DD 00:00:00")}`;
    }
    const response = await Api(
      `${COMAPNY_API}/${companyId}/contract${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        if (!response.data.data) {
          setFullLoder(false);
          return;
        }
        downloadDocumentFile(response?.data?.data?.resourceUrl, "Contracts");
      }
      setFullLoder(false);
    } else {
      setFullLoder(false);
    }

    // axiosInstance
    //   .get(`api/buyer/company/${companyId}/contract${string}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       if (!response.data.data) {
    //         setNotify({
    //           isOpen: true,
    //           message: response?.data?.responseDescription,
    //           type: "error",
    //         });
    //         setFullLoder(false);

    //         return;
    //       }
    //       setNotify({
    //         isOpen: true,
    //         message: response?.data?.responseDescription,
    //         type: "success",
    //       });
    //       const link = document.createElement("a");
    //       link.href = response.data.data.resourceUrl;
    //       link.target = "_blank";
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     } else {
    //       setNotify({
    //         isOpen: true,
    //         message: response?.data?.responseDescription,
    //         type: "error",
    //       });
    //     }
    //     setFullLoder(false);
    //   })
    //   .catch((err) => {
    //     setFullLoder(false);
    //     setNotify({
    //       isOpen: true,
    //       message: err?.response?.data?.responseDescription,
    //       type: "error",
    //     });
    //   });
  };

  const getAllContractList = (totalEntry = false, totalCount) => {
    GetAllContractsApi({
      companyId: currentUser.company._id,
      rowsPerPage: totalEntry ? totalCount : rowsPerPage,
      page: page,
      search: searchText,
      filters: filters,
    })
      .then((data) => {
        setFullLoder(false);
        setContractList(data.list);
        setLimit(data.limit);
        setNumOfPages(Math.ceil(data.limit / rowsPerPage));
      })
      .catch((error) => {
        setFullLoder(false);
      });
  };
  return (
    <>
      <Paper elevation={0} square className={classes.root}>
        <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
        {/* <Loader fullLoder={false} /> */}
        <Grid container style={{ marginTop: "1em" }}>
          <Grid item container xs={12} style={{ alignItems: "center" }}>
            <Grid item xs={3}>
              <h1 className="item-container">Contracts</h1>
            </Grid>
            <Grid item xs={9}>
              <Box style={{ float: "right" }}>
                <Controls.Button
                  variant="contained"
                  className={
                    "btn btn-white btn-mui" +
                    (showFilterCategories ? " active" : "")
                  }
                  text="Filter"
                  onClick={toggleFilterCategory}
                  startIcon={
                    <FilterListSharpIcon style={{ color: "#2170FF" }} />
                  }
                />
                <Controls.Button
                  text="Export"
                  className="btn btn-success btn-mui"
                  startIcon={<SystemUpdateAltIcon />}
                  onClick={() => {
                    ExportToExcel();
                  }}
                />
                {!isBuyer ? (
                  <></>
                ) : (
                  <>
                    <Controls.Button
                      text="Add Contract"
                      className="btn btn-primary btn-mui"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        openAddContractModal();
                      }}
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container className="filter-box-container">
            {showFilterCategories && (
              <Paper elevation={1} className="filter-box">
                <ContractFilter
                  setContractList={setContractList}
                  setShowFilterCategories={setShowFilterCategories}
                  setResendApi={setResendApi}
                  setPage={setPage}
                  setFilters={setFilters}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Grid item xs={12}>
            <Box sx={{ display: "inline" }}>
              <TextField
                onChange={getSearchString}
                placeholder="Search Using Keyword"
                className="select-box select-box-primary search-box"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box style={{ float: "right" }}>
              <Select
                value={rowsPerPage}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="items-count-dropdown"
              >
                {/* <MenuItem value="">
                  <em>Show 10 entries</em>
                </MenuItem> */}
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </Box>
          </Grid>
          <ContractsTable
            setIsEditRecord={setIsEditRecord}
            setOpenPopup={setOpenPopup}
            setValues={setValues}
            setEditUserId={setEditUserId}
            contractList={contractList}
            setRowsPerPage={setRowsPerPage}
            setResendApi={setResendApi}
            page={page}
            setPage={setPage}
            limit={limit}
            numOfPages={numOfPages}
          />
        </Box>
      </Paper>

      {openAddContract && (
        <AddContractModal
          openPopup={openAddContract}
          clickClosePopup={() => setOpenAddContract(!openAddContract)}
          setFullLoder={setFullLoder}
          getAllContractList={getAllContractList}
        />
      )}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(ContractsList));
