import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";

export default function CollapsedPage(props) {
  const {
    openPopup,
    setOpenPopup,
    children,
    bodyWidth,
    getAllItemsByCompanyName,
    fromRfx,
  } = props;
  const collapsedRef = React.createRef();

  const handleChange = () => {
    let collapse = collapsedRef.current;
    let collapseBody = collapse.querySelector(".collapsed-overlayed-page-body");

    if (openPopup) {
      collapse.classList.add("active");
      collapseBody.classList.add("active");
    } else {
      collapse.classList.remove("active");
      collapseBody.classList.remove("active");
    }
  };

  const getBodyWidth = () => {
    if (openPopup) {
      return bodyWidth + "vw";
    } else {
      return "0vw";
    }
  };

  React.useEffect(() => {
    handleChange();
  }, [openPopup]);

  const closeSider = () => {
    setOpenPopup(false);
    if (!fromRfx) {
      getAllItemsByCompanyName();
    } else {
    }
  };

  return (
    <div ref={collapsedRef} className="collapsed-overlayed-page-contaier">
      <CloseIcon className="close-icon" onClick={() => closeSider()} />
      <div
        className="collapsed-overlayed-page-body"
        style={{ width: getBodyWidth() }}
      >
        {
          children
        }
      </div>
    </div>
  );
}
