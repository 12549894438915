import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import GetRfxById from "./Components/RfxProcessFunction/GetRfxById";
import { setLiveRfxDetails } from "../../store/actions/rfxAction";
import { setHTTPError } from "../../store/actions/errorHandlerAction";
import RfxCompareBanner from "./Components/RfxCompareComponent/RfxCompareBanner";
import Copyright from "../../components/Copyright";
import RfxCompareTabContainer from "./Components/RfxCompareComponent/RfxCompareTabContainer";
// import "./styles/live-index.css";

const RfxComapre = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const UnAuthorizedRoute = useSelector(
    (state) => state.errorHandler.UnAuthorizedRoute
  );

  const getLiveRfxDetails = async () => {
    setLoading(true);
    await GetRfxById({ id: id }).then((result) => {
      setLoading(false);
      if (result.success) {
        dispatch(setLiveRfxDetails(result.rfx));
      } else {
        if (result.code === 403) {
          dispatch(
            setHTTPError({
              code: 403,
              message: result.message,
            })
          );
          history.push(UnAuthorizedRoute);
        } else {
          dispatch(
            setHTTPError({
              code: result.code,
              message: result.message,
            })
          );
        }
      }
    });
  };

  useEffect(() => {
    getLiveRfxDetails();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <main className="app-main-section-container live-auction">
          <Container maxWidth="lg">
            <RfxCompareBanner />
            <RfxCompareTabContainer />
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </>
  );
};

export default RfxComapre;
