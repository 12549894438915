import { setLiveServerClock } from "../../../store/actions/serverClockAction";
import { updateEventStack } from "../../../store/actions/socketAction";
import { SERVER_SYNC_CLOCK } from "../constants";
import SocketHandler from "../SocketHandler";

const ServerSyncClockEvent = () => {
  SocketHandler.instance.on(SERVER_SYNC_CLOCK, (response) => {
    SocketHandler.dispatcher(
      setLiveServerClock({
        timestamp: response?.data?.timestamp,
        timestampStr: response?.meta?.message,
      })
    );
    SocketHandler.dispatcher(updateEventStack(SERVER_SYNC_CLOCK));
  });
  return true;
};

export default ServerSyncClockEvent;
