import { Paper } from "@material-ui/core";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import "../../SupplierAspect/Auction/styles/details-general-info.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckIcon from "@mui/icons-material/Check";
import ReadMoreText from "../../../components/readmoretext/ReadMoreText";
const RfxSellerDetailCard = () => {
  const rfxSupplierDetails = useSelector((state) => state.supplierRfx.rfx);

  const formatStartDate = () => {
    return rfxSupplierDetails && rfxSupplierDetails._id
      ? new Date(rfxSupplierDetails?.startDate).toUTCString()
      : "---";
  };

  const formatEtartDate = () => {
    return rfxSupplierDetails && rfxSupplierDetails._id
      ? new Date(rfxSupplierDetails?.endDate).toUTCString()
      : "---";
  };

  const formatDeliveryDate = () => {
    const date = new Date(
      rfxSupplierDetails?.deliveryDate || rfxSupplierDetails?.deliveryDateTS
    );
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");

    return `${month}/${day}/${year}`;
  };

  return (
    <Paper>
      <div className="details-form-container mui-card supp-auction-details-general-info-container rfx-seller-detail-card">
        <h3 className="mt-0 mb-4" style={{ fontSize: "1.3em" }}>
          RFX Details
        </h3>
        <div className="mui-label"> RFX ID</div>
        <h3 className="mt-0 mb-3">{rfxSupplierDetails?.rfxId}</h3>
        <div className="mui-label"> Start Date</div>
        <h3 className="mt-0 mb-3">{formatStartDate()}</h3>
        <div className="mui-label"> End Date</div>
        <h3 className="mt-0 mb-3">{formatEtartDate()}</h3>

        {(rfxSupplierDetails?.deliveryDate ||
          rfxSupplierDetails?.deliveryDateTS) && (
          <>
            <div className="mui-label"> Delivery Date</div>
            <h3 className="mt-0 mb-3">{formatDeliveryDate()}</h3>
          </>
        )}

        <div className="mui-label">Client Name</div>
        <h3 className="mt-0 mb-2">
          {rfxSupplierDetails?.companyId?.company_name}
        </h3>
        {/* <div className="mui-label">Cost Center</div>
        <h3 className="mt-0 mb-2">
          {rfxSupplierDetails?.cost_center
            ? rfxSupplierDetails?.cost_center.name
            : "--"}
        </h3> */}
        <div className="mui-label">Currency</div>
        <h3 className="mt-0 mb-2">{rfxSupplierDetails?.currency || "--"}</h3>
        {/* <div className="mui-label">Budget</div>
        <h3 className="mt-0 mb-2">{rfxSupplierDetails?.budget}</h3> */}
        <div className="mui-label">Supplier to Provide</div>
        <h3 className="mt-0 mb-2">
          {rfxSupplierDetails?.supplier_to_provide === "1"
            ? "Price for each items"
            : "Lumpsum"}
        </h3>
        <div className="mui-label mt-5">Description</div>
        <h3
          className="mt-0 show-wrap-text mw600 eight-line"
          style={{ fontSize: "1em" }}
        >
          <ReadMoreText text={rfxSupplierDetails?.description} />
        </h3>
        <Grid item xs={12} className="mb-3 mt-3">
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <div
                className={`checkbox checkbox-primary-outline
                      ${
                        rfxSupplierDetails?.allow_supplier_document_upload
                          ? "checked"
                          : ""
                      }`}
              >
                <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                <CheckIcon aria-label="check-mark" />
              </div>
            </Grid>
            <Grid item xs={10} style={{ marginLeft: "5px" }}>
              <span className="label text-bold">Upload Documents</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mb-3">
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <div
                className={`checkbox checkbox-primary-outline
                      ${rfxSupplierDetails?.sealed_bid ? "checked" : ""}`}
              >
                <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                <CheckIcon aria-label="check-mark" />
              </div>
            </Grid>
            <Grid item xs={10} style={{ marginLeft: "5px" }}>
              <span className="label text-bold">Sealed bid</span>
            </Grid>
          </Grid>
        </Grid>

        <div className="mui-label mt-5">Attachments</div>

        <div className="file-name-container">
          {rfxSupplierDetails?.documents &&
            rfxSupplierDetails?.documents.map((document) => (
              <div key={document?._id} className="d-flex">
                <div className="icon">
                  <FilePresentOutlinedIcon />
                </div>
                <div className="name">
                  <a
                    href={document?.fileName}
                    target="_blank"
                    style={{ color: "var(---2170ff-p-)" }}
                  >
                    {document?.displayName}
                  </a>
                </div>
              </div>
            ))}
        </div>
        <div className="file-name-container">
          {rfxSupplierDetails?.docs &&
            rfxSupplierDetails?.docs.map((document) => (
              <div key={document?._id} className="d-flex">
                <div className="icon">
                  <FilePresentOutlinedIcon />
                </div>
                <div className="name">
                  <a
                    href={document?.document?.fileName}
                    target="_blank"
                    style={{ color: "var(---2170ff-p-)" }}
                  >
                    {document?.document?.name}
                  </a>
                </div>
              </div>
            ))}
        </div>
        <div className="file-name-container">
          {rfxSupplierDetails?.links &&
            rfxSupplierDetails?.links.map((link) => (
              <div key={link?._id} className="d-flex">
                <div className="icon">
                  <LinkOutlinedIcon />
                </div>
                <div className="name">
                  <a
                    href={link?.link}
                    target="_blank"
                    style={{ color: "var(---2170ff-p-)" }}
                  >
                    {link?.title}
                  </a>
                </div>
              </div>
            ))}
        </div>
        {rfxSupplierDetails?.documents &&
          rfxSupplierDetails?.documents.length === 0 &&
          rfxSupplierDetails?.links &&
          rfxSupplierDetails?.links.length === 0 &&
          rfxSupplierDetails?.docs &&
          rfxSupplierDetails?.docs.length === 0 && (
            <div className="text-bold">--</div>
          )}
      </div>
    </Paper>
  );
};

export default RfxSellerDetailCard;
