import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Divider, Popover } from "@material-ui/core";
import PopupState, {
  bindTrigger,
  bindMenu,
  bindPopover,
} from "material-ui-popup-state";
import Notification from "../../components/Notification";
import "../Auction/styles/auction-table.css";
import TableOptionList from "../../components/TableOptionList";
import TablePaginationBox from "../../components/TablePaginationBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import ConfirmDialog from "../../components/ConfirmDialog";
import { store } from "../../store/store";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { currentUser } from "../../store/selectors/AuthSelectors";
import { v1 as uuidv1 } from "uuid";
import { thousandSepratorHandler } from "../../utility/utils";
import { Api } from "../../api/Api";
import {
  COMAPNY_API,
  POST_RFX_STATUS_CHANGE_API,
  POST_SUPPLIER_SAVE_IMPORT_DATA_API,
} from "../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_ARCHIEVED,
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_CANCELLED,
  AUCTION_COMPLETED,
  AUCTION_DRAFT,
  AUCTION_ENDED,
  AUCTION_ON_CANCELLED,
  AUCTION_ON_HOLD,
  AUCTION_RUNNING,
  AUCTION_SMALL_PUBLISHED,
  BLUE_BACKGROUND,
  BLUE_HEX_COLOUR_CODE,
  DANGER_BACKGROUND,
  DISABELED_BACKGROUND,
  GREEN_BACKGROUND,
  GREEN_HEX_COLOUR_CODE,
  GREY_BACKGROUND,
  PRIMARY_BACKGROUND,
  PURPLE_BACKGROUND,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  SUPPLIER_AUCTION_RUNNING,
  TYPE_ONE,
  TYPE_THREE,
  TYPE_TWO,
  WARNING_BACKGROUND,
  WARNING_OUTLINE_BACKGROUND,
} from "../../constants/AppConstants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import rfpCover from "../../assets/Rfx/RfpCover.png";
import rfiCover from "../../assets/Rfx/RfiCover.png";
import rfqCover from "../../assets/Rfx/RfqCover.png";
import rfxCover from "../../assets/Rfx/RfxCover.png";
import { RFI_TYPE, RFP_TYPE, RFQ_TYPE } from "../../services/socket/constants";

var columns = [
  {
    label: "ID",
  },
  {
    label: "Type",
  },
  {
    label: "RFX Name",
  },
  {
    label: "Confirmed/Invited",
  },
  {
    label: "Submitted Bid",
  },
  {
    label: "Open Messages",
  },
  {
    label: "End Date",
  },
  {
    label: "Budget",
  },
  {
    label: "Best Bid",
  },
  {
    label: "Best Bidder",
  },
  {
    label: "Savings%",
  },
  {
    label: "Status",
  },
  {
    label: "Owner",
  },
  {
    label: "",
  },
];

const AuctionTable = (props) => {
  const {
    rfxList,
    isSeller,
    isBuyer,
    currentUser,
    setResendApi,
    limit,
    page,
    setPage,
    numOfPages,
  } = props;

  const history = useHistory();
  const [deleteAuctionId, setDeleteAuctionId] = React.useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const editAuction = (auctionId) => {
    history.push("/rfx/" + auctionId + "/details");
  };

  const DeleteRfx = (rfx) => {
    if (rfx._id && isBuyer) {
      setDeleteAuctionId(rfx?._id);
      setConfirmDialog({
        isOpen: true,
        title: rfx?.rfx_name,
        subTitle: "Are you sure to delete RFX?",
      });
    }
  };

  const confirmDeleteProject = async () => {
    if (deleteAuctionId && isBuyer) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;

      const response = await Api(
        `${COMAPNY_API}/${companyId}/rfx/${deleteAuctionId}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setConfirmDialog({
            isOpen: false,
            title: "",
            subTitle: "",
          });
          if (rfxList.length - 1 === 0) {
            setPage((prevPage) => prevPage - 1);
            // setPage(page - 1);
          } else {
            setResendApi(true);
          }
        }
      }
    }
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case TYPE_ONE:
        return BLUE_BACKGROUND;
      case TYPE_TWO:
        return GREY_BACKGROUND;
      case TYPE_THREE:
        return GREEN_BACKGROUND;
      default:
        return DISABELED_BACKGROUND;
    }
  };

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ENDED:
        return WARNING_OUTLINE_BACKGROUND;
      case AUCTION_SMALL_PUBLISHED:
        return SUCCESS_BACKGROUND;
      case AUCTION_DRAFT:
        return SECONDARY_BACKGROUND;
      case SUPPLIER_AUCTION_RUNNING:
        return PRIMARY_BACKGROUND;
      case AUCTION_ON_HOLD:
        return WARNING_BACKGROUND;
      case AUCTION_ON_CANCELLED:
        return DANGER_BACKGROUND;
      case AUCTION_ARCHIEVED:
        return PURPLE_BACKGROUND;
      default:
        return PRIMARY_BACKGROUND;
    }
  };

  const getColor = (status) => {
    if (status === AUCTION_ACTIVE) {
      return BLUE_HEX_COLOUR_CODE;
    } else {
      return GREEN_HEX_COLOUR_CODE;
    }
  };

  const getStatusChange = async (status, rfxId, currentStatus) => {
    const payload = {
      status: status,
    };
    if (rfxId) {
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;

      if (
        currentStatus &&
        currentStatus === AUCTION_DRAFT &&
        status === AUCTION_ACTIVE
      ) {
        const response = await Api(
          `${POST_RFX_STATUS_CHANGE_API}/${rfxId}`,
          {},
          "post"
        );
        setResendApi(true);
        return;
      }
      const response = await Api(
        `${POST_SUPPLIER_SAVE_IMPORT_DATA_API}/${companyId}/rfx/${rfxId}`,
        payload,
        "patch"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setResendApi(true);
        }
      }
    }
  };
  return (
    <>
      <Paper elevation={0} square>
        <TableContainer
          className="table-container buyer-aspect"
          component={Paper}
        >
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id ?? uuidv1()}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rfxList.map((rfx) => (
                <TableRow
                  key={rfx._id}
                  className="cursor-p"
                  onDoubleClick={() => editAuction(rfx._id)}
                >
                  <TableCell>{rfx.rfxId}</TableCell>
                  <TableCell>
                    <div>
                      <img
                        className="rfx-cover-image"
                        src={
                          rfx.rfx_type === RFP_TYPE
                            ? rfpCover
                            : rfx.rfx_type === RFQ_TYPE
                            ? rfqCover
                            : rfx.rfx_type === RFI_TYPE
                            ? rfiCover
                            : rfxCover
                        }
                        alt=""
                      />
                    </div>
                  </TableCell>
                  <TableCell className="rfx-title">
                    <h3 className="show-wrap-table-text">{rfx.rfx_name} </h3>
                    <p className="show-wrap-table-text">
                      {rfx?.projectId?.project_name}{" "}
                    </p>
                  </TableCell>
                  <TableCell>
                    {rfx?.totalAccepted}/{rfx?.totalInvited}
                  </TableCell>
                  <TableCell>
                    {rfx?.bidsSubmitted}/{rfx?.totalAccepted}
                  </TableCell>
                  <TableCell>
                    <p
                      className={`${
                        rfx?.openMessage > 0 ? "text-warning" : ""
                      }`}
                    >
                      {rfx?.openMessage}
                    </p>
                  </TableCell>
                  <TableCell className="rfx-datetime">
                    {Moment(rfx?.endDate).format("DD/MM/YYYY LT")}
                  </TableCell>
                  <TableCell>
                    {rfx?.budget ? thousandSepratorHandler(rfx?.budget) : "-"}{" "}
                    {rfx?.budget ? rfx?.currency : ""}
                  </TableCell>
                  <TableCell>
                    {rfx?.bestBid !== "-"
                      ? thousandSepratorHandler(rfx?.bestBid?.bid)
                      : "-"}{" "}
                    {rfx?.bestBid !== "-" ? rfx?.currency : ""}
                  </TableCell>
                  <TableCell>
                    {rfx?.bestBid &&
                    rfx?.bestBid?.supplierCompanyId &&
                    rfx?.bestBid?.supplierCompanyId?.company_name
                      ? rfx?.bestBid?.supplierCompanyId?.company_name
                      : "-"}
                  </TableCell>
                  <TableCell>{rfx?.savings}</TableCell>
                  {rfx?.status === AUCTION_COMPLETED && (
                    <TableCell>
                      <div>
                        <button className="badge badge-primary-outline select-colored-option">
                          {AUCTION_COMPLETED}
                        </button>
                      </div>
                    </TableCell>
                  )}
                  {rfx?.status === AUCTION_CANCELLED && (
                    <TableCell>
                      <div>
                        <button className="badge badge-danger select-colored-option">
                          {AUCTION_CANCELLED}
                        </button>
                      </div>
                    </TableCell>
                  )}
                  {rfx?.status === AUCTION_RUNNING && (
                    <TableCell>
                      <div>
                        <button className="badge badge-success select-colored-option">
                          {AUCTION_RUNNING}
                        </button>
                      </div>
                    </TableCell>
                  )}
                  {rfx?.status !== AUCTION_RUNNING &&
                    rfx?.status !== AUCTION_COMPLETED &&
                    rfx?.status !== AUCTION_CANCELLED && (
                      <TableCell className="rfx-status">
                        <PopupState variant="popover" popupId="demoPopover">
                          {(popupState) => (
                            <div>
                              <Button
                                className={
                                  "badge badge-" +
                                  getStatusLabel(rfx?.status) +
                                  " status"
                                }
                                variant="contained"
                                {...bindTrigger(popupState)}
                                endIcon={<ArrowDropDownIcon />}
                              >
                                {rfx?.status}
                              </Button>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <div className="px-3 py-2">
                                  {(rfx?.status === AUCTION_ARCHIEVED_CAPITAL ||
                                    rfx?.status === AUCTION_DRAFT) && (
                                    <div>
                                      <button
                                        className="badge badge-info select-colored-option"
                                        onClick={() =>
                                          getStatusChange(
                                            AUCTION_ACTIVE,
                                            rfx?._id,
                                            rfx?.status
                                          )
                                        }
                                      >
                                        {AUCTION_ACTIVE}
                                      </button>
                                    </div>
                                  )}

                                  {(rfx?.status === AUCTION_ACTIVE ||
                                    rfx?.status === AUCTION_ARCHIEVED ||
                                    rfx?.status === AUCTION_DRAFT) && (
                                    <div>
                                      <button
                                        className="badge badge-danger select-colored-option"
                                        onClick={() => {
                                          popupState.close();

                                          getStatusChange(
                                            AUCTION_CANCELLED,
                                            rfx?._id
                                          );
                                        }}
                                      >
                                        {AUCTION_CANCELLED}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      </TableCell>
                    )}

                  <TableCell>
                    {rfx?.projectId?.createdBy?.first_name}{" "}
                    {rfx?.projectId?.createdBy?.last_name}
                  </TableCell>

                  {/* {false ? ( */}
                  <TableCell className="rfx-action-tab">
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem
                                className="bold-line-600"
                                onClick={() => {
                                  editAuction(rfx._id);
                                  popupState.close();
                                }}
                              >
                                <div>View Details</div>
                              </MenuItem>

                              <div>
                                <Divider />
                                <MenuItem
                                  className="bold-line-600 clr-ff0000"
                                  onClick={() => {
                                    DeleteRfx(rfx);
                                    popupState.close();
                                  }}
                                >
                                  <div>Delete</div>
                                </MenuItem>
                              </div>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                  {/* ) : (
                    <></>
                  )} */}
                </TableRow>
              ))}
              {rfxList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={limit + " RFX Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmDeleteProject={confirmDeleteProject}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(AuctionTable));
