import { Api } from "../../../../api/Api";
import { RFX_API } from "../../../../constants/ApiUrl";

const GetAllSupplierListRfx = async (props) => {
  const { currentRfx, page, rowsPerPage } = props;

  let list = [];
  let limit = 0;
  let bestBid = "";

  try {
    let rfxId = currentRfx._id;
    const response = await Api(
      `${RFX_API}/${rfxId}/supplier/response/list?per_page=${rowsPerPage}&page=${page}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data && response.data.supplierList !== undefined) {
        list = response.data.supplierList;
        limit = response.data.totalRecord;
        bestBid = response.data.bestBid;
      }
    }
  } catch (err) {}

  return {
    list: list,
    limit: limit,
    bestBid: bestBid,
  };
};

export default GetAllSupplierListRfx;
