import { updateEventStack } from "../../../store/actions/socketAction";
import { updateLiveBiddingRank } from "../../../store/actions/supplierAspect/liveBidAction";
import { SET_MY_LIVE_BIDDING_RANK_RESPONSE_EVENT } from "../constants";
import SocketHandler from "../SocketHandler";

const SetLiveBiddingRankResEvent = () => {
  SocketHandler.instance.on(
    SET_MY_LIVE_BIDDING_RANK_RESPONSE_EVENT,
    (response) => {
      if (response && response.data) {
        if (
          SocketHandler.user &&
          String(SocketHandler.user._id) === String(response.data.userId)
        ) {
          SocketHandler.dispatcher(
            updateLiveBiddingRank({
              rank: response.data.rank,
            })
          );
        }
      }

      SocketHandler.dispatcher(
        updateEventStack(SET_MY_LIVE_BIDDING_RANK_RESPONSE_EVENT)
      );
    }
  );
  return true;
};

export default SetLiveBiddingRankResEvent;
