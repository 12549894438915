import React from "react";
import CommonSignup from "./CommonSignup";

const SignUpSeller = ({ notify, setNotify }) => {
  return (
    <CommonSignup fromBuyer={false} notify={notify} setNotify={setNotify} />
  );
};

export default SignUpSeller;
