import { CssBaseline, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link as Links } from "react-router-dom";
// import faceLogo from "../../assets/auth/Oktio_loko.png";
import faceLogo from "../../assets/auth/Logo_Oktio_last.png";
import "./styles/success-page.css";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { toast, Slide } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(18),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fdfdfd",
    padding: theme.spacing(4),
    borderRadius: "15px",
  },

  linktxt: {
    textDecoration: "none",
    color: "#2170ff",
  },
}));

const Success = (props) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const classes = useStyles();
  const [spacing, setSpacing] = React.useState(0);
  const [userEmail, setUserEmail] = React.useState("");

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
    const email = localStorage.getItem("email");
    setUserEmail(email);
  }, []);
  return (
    <div className="auth-box-container">
      <CssBaseline />
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        {/* <Grid item xl={4} lg={4} md={4} sm={8} xs={11}></Grid> */}
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={8}
          xs={11}
          style={{ textAlign: "-webkit-center" }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="end"
            style={{ flexDirection: "column" }}
          >
            <Grid
              item
              sm={12}
              xl={12}
              lg={12}
              md={12}
              style={{ textAlign: "-webkit-center" }}
            >
              <div className="success-page-logo">
                <img src={faceLogo} />
              </div>
            </Grid>
            <Grid item sm={12} xl={12} lg={12} md={12}>
              <div className="text-logo-box-container-success">
                <div className="text-logo-box-success">
                  <span>oktio</span>
                </div>
              </div>
            </Grid>

            <Grid item sm={12} xl={12} lg={12} md={12}>
              <div className="form-box-container text-center">
                <Typography
                  component="h3"
                  variant="h5"
                  style={{ marginBottom: "15px" }}
                >
                  <span>Verify your email</span>
                </Typography>
                <Typography component="h5" variant="subtitle2">
                  <span className="email-content-text-email">
                    An email been sent to {userEmail || ""} <br />
                    with a link to verify your account.
                  </span>
                </Typography>

                <p className="text-contact-support">
                  <span>
                    {" "}
                    If you have not received the email, Please check your spam{" "}
                    <br /> folder or{" "}
                    <span className="contact-support-color">
                      contact support
                    </span>{" "}
                  </span>
                </p>

                <p className="footer-alredy-account-text">
                  <span>
                    {" "}
                    Email already verified?
                    <Links to="/signin" variant="body1">
                      <span className="contact-support-color"> Sign In</span>{" "}
                    </Links>{" "}
                  </span>
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <div style={{ flexGrow: 1, marginBottom: "25px" }}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xl={4} lg={5} md={6} sm={8} xs={11}>
            <div className="form-box-container text-center">
              <Typography component="h3" variant="h5">
                {props.successMessage}
              </Typography>
              <Typography component="h6" variant="subtitle2">
                Login to Access ....
                <Typography variant="caption" gutterBottom>
                  <Links
                    to="/signin"
                    variant="caption"
                    className={classes.linktxt}
                  >
                    Sign In
                  </Links>
                </Typography>
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4} md={3} xs={8}>
            <Grid
              container
              justifyContent="center"
              alignItems="end"
              style={{ flexDirection: "column" }}
            >
              <Grid item sm={12} style={{ textAlign: "-webkit-center" }}>
                <div className="face-logo">
                  <img src={faceLogo} />
                </div>
              </Grid>
              <Grid item sm={12}>
                <div className="text-logo-box-container">
                  <div className="text-logo-box">
                    <span>Oktio</span>
                  </div>
                 
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div> */}
    </div>
    // <Grid container className={classes.root}>
    //   <Grid item xs={12}>
    //     <Grid container justifyContent="center" spacing={spacing}>
    //       <Grid item>
    //         {/* <Paper className={classes.paper} > */}
    //         <Container component="main" maxWidth="xs">
    //           <CssBaseline />

    //           {/* <Box mt={8}>
    //       <Copyright />
    //     </Box> */}
    //         </Container>
    //       </Grid>
    //       <Hidden only="sm">
    //         <Grid item>
    //           <Paper className={classes.paperRight}>
    //             <div className={classes.logo}>
    //               <img src={logopng} alt="Logo" width="100%"></img>
    //             </div>
    //             <div className={classes.logosvg}>
    //               <img src={logo} alt="Logo" width="100%"></img>
    //             </div>
    //           </Paper>
    //         </Grid>
    //       </Hidden>
    //     </Grid>
    //   </Grid>
    // </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
  };
};

export default connect(mapStateToProps)(Success);
