export const VALID_EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;
export const SPACE_RULES = /^[^ ]/;

//FILE-TYPE
export const ACCEPT_ONLY_IMAGE = ["image/png", "image/jpg", "image/jpeg"];
export const ACCEPT_ITEM_ATTACHMENTS = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export const ACCEPT_PROJECT_ATTACHMENTS = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/gzip",
  "application/zip",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
];
export const ACCEPT_DOCUMENT_ATTACHMENTS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/gzip",
  "application/zip",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
];

export const ACEPT_IMPORT_ATTACHMENTS = [
  ".csv",
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
//FILE-SIZE
export const DOCUMENT_FILE_SIZE = 20000;
export const ITEM_FILE_SIZE = 800;
export const ITEM_ATTACHMENTS_SIZE = 10000;
export const SUPPLIER_ATTACHMENTS_SIZE = 8000;
export const PROJECT_FILE_SIZE = 2000;
export const PROJECT_ATTACHMENTS_SIZE = 5000;

//BUDGET
export const BUDGET_MAX_VALUE = 999999999;
//AUCTION-STAUTS
export const AUCTION_RUNNING = "Running";
export const AUCTION_ACCEPTED = "accepted";
export const AUCTION_ACCEPTED_CAPITAL = "Accepted";
export const SUPPLIER_AUCTION_RUNNING = "running";
export const SUPPLIER_AUCTION_PENDING = "pending";
export const SUPPLIER_AUCTION_PENDING_CAPITAL = "Pending";
export const AUCTION_PUBLISHED = "Published";
export const AUCTION_SMALL_PUBLISHED = "published";
export const AUCTION_DECLINED = "declined";
export const AUCTION_DECLINED_CAPITAL = "Declined";
export const AUCTION_ENDED = "ended";
export const AUCTION_ENDED_CAPITAL = "Ended";
export const AUCTION_DRAFT_SMALL = "draft";
export const AUCTION_DRAFT = "Draft";
export const AUCTION_ON_HOLD = "on_hold";
export const AUCTION_CAPITAL_ON_HOLD = "On_hold";
export const AUCTION_DASH_ON_HOLD = "On-hold";
export const AUCTION_DASH_CAPITAL_ON_HOLD = "On-Hold";
export const AUCTION_ONHOLD = "Onhold";
export const AUCTION_ON_CANCELLED = "cancelled";
export const AUCTION_CANCELLED = "Cancelled";
export const AUCTION_ARCHIEVED = "archived";
export const AUCTION_ARCHIEVED_CAPITAL = "Archived";
export const AUCTION_COMPLETE = "Complete";
export const AUCTION_COMPLETED = "Completed";
export const AUCTION_COMPLETED_SMALL = "completed";
export const AUCTION_ACTIVE = "Active";
export const AUCTION_ACTIVE_SMALL = "active";
export const AUCTION_INACTIVE = "InActive";
export const AUCTION_INACTIVE_CAP_SMALL = "Inactive";
export const AUCTION_INACTIVE_SMALL = "inActive";
export const AUCTION_INACTIVE_ALL_SMALL = "inactive";
export const AUCTION_INVITED = "invited";
export const AUCTION_INVITED_CAPITAL = "Invited";
export const AUCTION_WON = "won";
export const AUCTION_LOST = "lost";
export const AUCTION_WIN_LOST = "win_lost";
export const AUCTION_DASH_WIN_LOST = "Win-Lost";
export const AUCTION_LABEL_SUCCESS = "Success";
export const AUCTION_LABEL_DECLINE = "Decline";
export const RFX_INVITE_STATUS = "I";
export const CONTRACT_ACTIVE = "Active";
export const CONTRACT_INACTIVE = "Inactive";
export const CONTRACT_INACTIVE_CAP_SMALL = "Inactive";

//SUPER ADMIN
export const SUPER_ADMIN_STATUS_ACTIVE = "Active";
export const SUPER_ADMIN_STATUS_INACTIVE = "Inactive";
export const SUPER_ADMIN_STATUS_PENDING = "Pending";

//REGEX
export const OLD_LINK_REGEX =
  /^(https?:\/\/|(www\.))[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,12}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i;
// export const OLD_LINK_REGEX = (https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?,
export const ONLY_APLPHABET_REGEX = /^[a-z\s]+$/i;
export const TOAST_AUTO_CLOSE = 6000;

//TYPE-NUMBER
export const TYPE_ONE = "1";
export const TYPE_TWO = "2";
export const TYPE_THREE = "3";

//STATUS
export const STATUS_OPEN = "Open";
export const STATUS_INACTIVE = "Inactive";
export const STATUS_INACTIVE_SMALL = "inactive";

//BADGE-COLOUR
export const BLUE_BACKGROUND = "blue";
export const GREY_BACKGROUND = "grey";
export const GREEN_BACKGROUND = "green";
export const DISABELED_BACKGROUND = "disabled";
export const WARNING_OUTLINE_BACKGROUND = "warning-outline";
export const PRIMARY_OUTLINE_BACKGROUND = "primary-outline";
export const DANGER_OUTLINE_BACKGROUND = "danger-outline";
export const SUCCESS_BACKGROUND = "success";
export const SECONDARY_BACKGROUND = "secondary";
export const PRIMARY_BACKGROUND = "primary";
export const WARNING_BACKGROUND = "warning";
export const DANGER_BACKGROUND = "danger";
export const PURPLE_BACKGROUND = "purple";

//HEX-COLOUR-CODE
export const BLUE_HEX_COLOUR_CODE = "#E2EEF2";
export const GREEN_HEX_COLOUR_CODE = "#55B972";

export const RFX_TYPE_MAP = new Map();
RFX_TYPE_MAP.set("RFI", "Request For Information");
RFX_TYPE_MAP.set("RFP", "Request For Proposal");
RFX_TYPE_MAP.set("RFQ", "Request For Quotation");
export const RFX_PROGRESS_MAP = new Map();
RFX_PROGRESS_MAP.set("P", "Pending");
RFX_PROGRESS_MAP.set("I", "Invited");
RFX_PROGRESS_MAP.set("A", "Accepted");
RFX_PROGRESS_MAP.set("D", "Declined");
// RFX_PROGRESS_MAP.set("R", "Running");
RFX_PROGRESS_MAP.set("R", "Offer In Progress");
RFX_PROGRESS_MAP.set("OIP", "Offer In Progress");
RFX_PROGRESS_MAP.set("OS", "Offer Submitted");
RFX_PROGRESS_MAP.set("W", "Won");
RFX_PROGRESS_MAP.set("L", "Lost");
RFX_PROGRESS_MAP.set("C", "Cancelled");
RFX_PROGRESS_MAP.set("E", "Ended");
RFX_PROGRESS_MAP.set("SFR", "Shortlist For Next Rfx");
RFX_PROGRESS_MAP.set("SFA", "Shortlist For Auction");

export const QUESTION_TYPE = new Map();
QUESTION_TYPE.set("FT", "Free Text");
QUESTION_TYPE.set("SC", "Single Choice");
QUESTION_TYPE.set("MC", "Multi Choice");

export const RFP_TYPE = "RFP";
export const RFQ_TYPE = "RFQ";
export const RFI_TYPE = "RFI";
export const RFX_TYPE = "RFX";
export const QUESTION_OPEN_TYPE = "Open";
export const QUESTION_ACTIVE_TYPE = "Active";
export const QUESTION_CLOSED_TYPE = "Closed";

export const RFX_DECLINED = "Declined";

export const RFX_STATUS_LIST = [
  { id: "P", title: "Pending" },
  { id: "I", title: "Invited" },
  { id: "A", title: "Accepted" },
  { id: "D", title: "Declined" },
  { id: "R", title: "Running" },
  { id: "OIP", title: "Offer In Progress" },
  { id: "OS", title: "Offer Submitted" },
  { id: "W", title: "Won" },
  { id: "L", title: "Lost" },
  { id: "C", title: "Cancelled" },
  { id: "E", title: "Ended" },
  { id: "SFR", title: "ShortlistForNextRfx" },
  { id: "SFA", title: "ShortlistForAuction" },
];

export const ROLE_BUYER = "buyer";
export const ROLE_SUPPLIER = "supplier";
export const BUYER = "B";
export const SELLER = "S";
export const FREE_TEXT = "FT";
export const SINGLE_CHOICE = "SC";
export const MULTI_CHOICE = "MC";
export const WON_RFX = "W";
export const CANCELLED_RFX = "C";
export const LOST_RFX = "L";
export const RFX_SUPPLIER_DECLINED = "D";
export const SHORTLIST_FOR_NEXT_RFX = "SFR";
export const SHORTLIST_FOR_NEXT_AUCTION = "SFA";
export const RFX_MESSAGE_OPEN = "Open";
export const RFX_MESSAGE_CLOSE = "Closed";
export const MAX_TEXT_DISPLAY_LENGTH = 90;

//ROLE
export const SUPER_ADMIN_ROLE = "super_admin";
export const BOTH_ROLE = "both";
