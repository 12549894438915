import { Api } from "../../../api/Api";
import { BUYER_PROJECT_LIST_API } from "../../../constants/ApiUrl";

const GetAllProjectsBL = async (props) => {
  const { companyId, rowsPerPage } = props;
  let projects = [];

  const response = await Api(
    `${BUYER_PROJECT_LIST_API}/${companyId}?sorting=-1&per_page=${rowsPerPage}`,
    {},
    "get"
  );
  if (response.status === 200) {
    projects = response.data.project;
  }

  // let response = await axiosInstance.get(
  //   `api/buyer/getAllProjects/${companyId}?sorting=-1&per_page=${rowsPerPage}`
  // );

  return projects;
};

export default GetAllProjectsBL;
