import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Appbars from "../../components/Appbar";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { isAuthenticated } from "../../store/selectors/AuthSelectors";
import Copyright from "../../components/Copyright";
import ProjectList from "./ProjectList";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const Projects = (props) => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Appbars></Appbars>
      <main className="app-main-section-container">
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <ProjectList />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(Projects));
