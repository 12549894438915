import { Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import TimeClockImage from "../../../assets/Time-Remaining@2x.png";
import "../../SupplierAspect/Auction/styles/details-live-bid.css";
import NumericSeparatorInput from "../../../components/NumericSeparatorInput";
import {
  isGlobalRfxDisabled,
  thousandSepratorHandler,
} from "../../../utility/utils";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Slide, toast } from "react-toastify";
import {
  ACCEPT_DOCUMENT_ATTACHMENTS,
  AUCTION_COMPLETED,
  AUCTION_RUNNING,
  DOCUMENT_FILE_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Api } from "../../../api/Api";
import {
  DELETE_BID_ATTACHMENT_REMOVE_API,
  POST_BID_ATTACHMENT_UPLOAD_API,
  POST_FINAL_BID_API,
  SEND_LUMPSUM_BID_API,
} from "../../../constants/ApiUrl";
import { store } from "../../../store/store";
import EditIcon from "@mui/icons-material/Edit";
import CommonButton from "../../../components/controls/CommonButton";
import moment from "moment";
import RfxSellerSubmitModal from "./RfxSellerSubmitModal";
import { Tooltip } from "@mui/material";

const RfxSellerBidCard = ({ getRfxDetails }) => {
  const rfxSupplierDetails = useSelector((state) => state.supplierRfx.rfx);
  const state = store.getState();

  const companyId = state?.auth?.auth?.user?.company?._id;
  const userId = state?.auth?.auth?.user?._id;
  const [lumpsumBid, setLumpusumBid] = useState(null);
  const [lumpsumBidError, setLumpusumBidError] = useState(false);
  const [editLumpsum, setEditLumpsum] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [timerStart, setTimerStart] = useState(false);
  const [countdown, setCountdown] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const currentTime = new Date().getTime();
      const startTime = rfxSupplierDetails?.startDateTS;
      const endTime = rfxSupplierDetails?.endDateTS;

      if (currentTime >= endTime) {
        clearInterval(countdownInterval);
        setTimerStart(false);
        if (timerStart) getRfxDetails();
        return;
      }

      if (currentTime >= startTime && currentTime <= endTime) {
        if (!timerStart) {
          setTimerStart(true);
          getRfxDetails();
        }
        const remainingTime = endTime - currentTime;

        const seconds = Math.floor(remainingTime / 1000) % 60;
        const minutes = Math.floor(remainingTime / 1000 / 60) % 60;
        const hours = Math.floor(remainingTime / 1000 / 60 / 60) % 24;
        const days = Math.floor(remainingTime / 1000 / 60 / 60 / 24);
        const remainingHours = Math.floor(days * 24);
        const totalHours = hours + remainingHours;

        setCountdown({ hours: totalHours, minutes, seconds });
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [rfxSupplierDetails]);

  const handleFileChange = async (e) => {
    const selected = e.target.files[0];

    const ALLOWED_TYPES = ACCEPT_DOCUMENT_ATTACHMENTS;
    if (selected && !ALLOWED_TYPES.includes(selected.type)) {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }
    if (selected && Math.round(selected.size / 1024) > DOCUMENT_FILE_SIZE) {
      toast.error(ValidationMessage.documentAttachmentFileSize, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      // setDocument(selected);
      const formData = new FormData();
      formData.append("rfxId", rfxSupplierDetails?._id);
      formData.append("userId", userId);
      formData.append("companyId", companyId);
      formData.append("attachment", selected);
      const response = await Api(
        POST_BID_ATTACHMENT_UPLOAD_API,
        formData,
        "postMultipart"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getRfxDetails();
        }
      }
    }
    e.target.value = "";
  };

  const lumpsumBidChangeHandler = (e) => {
    setLumpusumBid(e.target.value);
    if (e.target.value.length === 0) setLumpusumBidError(true);
    else setLumpusumBidError(false);
  };
  const sendLumpsumBid = async () => {
    if (!lumpsumBid) {
      setLumpusumBidError(true);
      return;
    }
    const payload = {
      rfxId: rfxSupplierDetails?._id,
      bid: parseInt(lumpsumBid),
      companyId,
      userId,
    };

    const response = await Api(SEND_LUMPSUM_BID_API, payload, "post");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setEditLumpsum(false);
        getRfxDetails();
      }
    }
  };

  const deleteDocumentHandler = async () => {
    const payload = {
      rfxId: rfxSupplierDetails?._id,
      userId,
      companyId,
    };
    const response = await Api(
      DELETE_BID_ATTACHMENT_REMOVE_API,
      payload,
      "delete"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        getRfxDetails();
      }
    }
  };
  const finalBidSumitHandler = async () => {
    const payload = {
      rfxId: rfxSupplierDetails?._id,
      userId,
      companyId,
    };
    const response = await Api(POST_FINAL_BID_API, payload, "post");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        getRfxDetails();
      }
    }
    setShowSubmitModal(false);
  };
  const onSubmitClickHandler = () => {
    if (rfxSupplierDetails?.supplierRfx?.isSubmitted) return;
    if (rfxSupplierDetails?.status !== AUCTION_RUNNING) {
      toast.error(ValidationMessage.rfxNotRunnig, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (rfxSupplierDetails?.bid === "-") {
      toast.error(ValidationMessage.addBid, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    setShowSubmitModal(true);
  };
  return (
    <>
      {/* {refresLiveBiddingData && <Loader />} */}
      <div className="mui-card supp-auction-details-live-bid-container rfx-seller-bid-card">
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={12}
            style={{ textAlign: "center" }}
            className="d-block"
          >
            <div className="text-gray text-bold">
              <span>Total Price</span>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center" }}
            className="d-block"
          >
            <div className="reserve-price-container rfx-bid-body">
              <div
                className={`price price-body w-100 ${
                  rfxSupplierDetails?.supplier_to_provide === "1"
                    ? "text-warning"
                    : "text-primary"
                }`}
              >
                <Tooltip
                  title={
                    rfxSupplierDetails?.bid !== "-"
                      ? thousandSepratorHandler(rfxSupplierDetails?.bid)
                      : 0 + " " + rfxSupplierDetails?.currency
                  }
                  placement="top"
                >
                  <p
                    className={`w-100 simple-word-break ${
                      rfxSupplierDetails?.supplier_to_provide === "2" &&
                      !editLumpsum &&
                      rfxSupplierDetails?.bid !== "-" &&
                      rfxSupplierDetails?.supplier_to_provide === "2" &&
                      !isGlobalRfxDisabled(
                        rfxSupplierDetails?.supplierRfx?.isSubmitted,
                        rfxSupplierDetails.status,
                        rfxSupplierDetails?.supplierRfx?.rfxStatus
                      )
                        ? "bid-number"
                        : ""
                    }`}
                  >
                    {rfxSupplierDetails?.bid !== "-"
                      ? thousandSepratorHandler(rfxSupplierDetails?.bid)
                      : 0}{" "}
                    {rfxSupplierDetails?.currency}
                  </p>
                </Tooltip>
                {!editLumpsum &&
                  rfxSupplierDetails?.bid !== "-" &&
                  rfxSupplierDetails?.supplier_to_provide === "2" &&
                  !isGlobalRfxDisabled(
                    rfxSupplierDetails?.supplierRfx?.isSubmitted,
                    rfxSupplierDetails.status,
                    rfxSupplierDetails?.supplierRfx?.rfxStatus
                  ) && (
                    <div
                      className={`bid-edit-container global-flex-center ${
                        rfxSupplierDetails?.supplier_to_provide === "1"
                          ? "border-warning"
                          : "border-primary"
                      }`}
                      onClick={() => setEditLumpsum(true)}
                    >
                      <EditIcon className="bid-edit-icon" />
                    </div>
                  )}
              </div>
              {/* <p
                className={`${
                  rfxSupplierDetails?.supplier_to_provide === "1"
                    ? "text-warning"
                    : "text-primary"
                }`}
              >
                {rfxSupplierDetails?.supplier_to_provide === "1"
                  ? "Total Price of Price per Item"
                  : "Lumpsum Price"}
              </p> */}
            </div>
          </Grid>

          <Grid item xs={10}>
            <div className="mui-card mui-card-primary item-stc-container mt-3">
              <Grid container>
                <Grid item sm={4} className="icon-container">
                  <img
                    src={TimeClockImage}
                    alt=""
                    className="icon clr-ffffff"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Grid container className="content-container timer">
                    <Grid item sm={12}>
                      <div className="mui-label">Time Remaining</div>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="text">
                        {countdown?.hours.toString().padStart(2, "0")}:
                        {countdown?.minutes.toString().padStart(2, "0")}:
                        {countdown?.seconds.toString().padStart(2, "0")}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} justifyContent="space-between" alignItems="center">
            <div className="mt-4">
              {rfxSupplierDetails?.supplier_to_provide === "2" && (
                <>
                  <Grid container>
                    {(editLumpsum || rfxSupplierDetails?.bid === "-") && (
                      <>
                        <Grid item xs={9}>
                          <Controls.Input
                            className="w-100"
                            fullWidth
                            name="price"
                            defaultValue={
                              rfxSupplierDetails?.bid !== "-"
                                ? rfxSupplierDetails?.bid
                                : ""
                            }
                            onChange={lumpsumBidChangeHandler}
                            autoComplete="off"
                            InputProps={{
                              inputComponent: NumericSeparatorInput,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controls.Button
                            text={
                              rfxSupplierDetails?.bid === "-" ? "Add" : "Edit"
                            }
                            color="default"
                            className="btn btn-mui add-lumpsum-btn w-100 ml-2 m-0"
                            onClick={() => sendLumpsumBid()}
                            disabled={
                              isGlobalRfxDisabled(
                                rfxSupplierDetails?.supplierRfx?.isSubmitted,
                                rfxSupplierDetails.status,
                                rfxSupplierDetails?.supplierRfx?.rfxStatus
                              )
                              // &&
                              // countdown?.hours === 0 &&
                              // countdown?.minutes === 0 &&
                              // countdown?.seconds === 0
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
              {lumpsumBidError && (
                <div className="error-msg">
                  {ValidationMessage.fieldRequired}
                </div>
              )}
              {rfxSupplierDetails?.allow_supplier_document_upload &&
                !isGlobalRfxDisabled(
                  rfxSupplierDetails?.supplierRfx?.isSubmitted,
                  rfxSupplierDetails.status,
                  rfxSupplierDetails?.supplierRfx?.rfxStatus
                ) && (
                  <>
                    <Grid container className="mt-3">
                      <Grid item xs={9}>
                        <div className="attach-name-display">
                          <p className="simple-word-break text-gray">
                            Attachment
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <label htmlFor="attactBid">
                          <div
                            className={`btn-mui btn-outline-primary add-bid-attach w-100 ml-2 m-0 ${
                              rfxSupplierDetails?.supplierRfx?.isSubmitted
                                ? "item-disabeled"
                                : ""
                            }`}
                            // onClick={() => sendNewBid()}
                          >
                            Browse
                          </div>
                        </label>
                        {!rfxSupplierDetails?.supplierRfx?.isSubmitted && (
                          <input
                            type="file"
                            hidden
                            id="attactBid"
                            onChange={handleFileChange}
                          />
                        )}
                      </Grid>
                    </Grid>
                    {rfxSupplierDetails?.supplierRfx?.attachment?.name &&
                      rfxSupplierDetails?.supplierRfx?.attachment?.name.length >
                        0 && (
                        <div className="bid-doc-display d-flex mt-2 ">
                          <AttachFileIcon className="attach-icon" />
                          <p className="simple-word-break px-2">
                            {rfxSupplierDetails?.supplierRfx?.attachment.name}
                          </p>
                          <div
                            className="delete-icon-body cursor-p global-flex-center"
                            onClick={() => deleteDocumentHandler()}
                          >
                            <DeleteIcon className="delete-icon" />
                          </div>
                        </div>
                      )}
                  </>
                )}

              <Grid item xs={12}>
                <CommonButton
                  text={`${
                    rfxSupplierDetails?.supplierRfx?.isSubmitted
                      ? "Submitted"
                      : "Submit"
                  }`}
                  color="default"
                  className={`btn btn-mui w-100 mt-5 place-btn ${
                    isGlobalRfxDisabled(
                      rfxSupplierDetails?.supplierRfx?.isSubmitted,
                      rfxSupplierDetails.status,
                      rfxSupplierDetails?.supplierRfx?.rfxStatus
                    )
                      ? "button-disabled item-disabeled"
                      : "btn-primary"
                  }`}
                  onClick={() => {
                    onSubmitClickHandler();
                  }}
                />

                {rfxSupplierDetails?.supplierRfx?.isSubmitted && (
                  <p className="text-center text-gray mt-3">
                    Submitted on{" "}
                    {moment(rfxSupplierDetails?.supplierRfx?.submitDate).format(
                      "ll"
                    )}
                  </p>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <RfxSellerSubmitModal
        showSubmitModal={showSubmitModal}
        setShowSubmitModal={setShowSubmitModal}
        finalBidSumitHandler={finalBidSumitHandler}
      />
    </>
  );
};

export default RfxSellerBidCard;
