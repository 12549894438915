// WRITE RULES HERE

import { SPACE_RULES } from "./AppConstants";
import { ValidationMessage } from "./ValidationMessage";

export const QUESTION_RULES = {
  //required: ValidationMessage.questionRequired,
};
export const ANSWER_RULES = {
  required: ValidationMessage.questionRequired,
};
export const QUESTION_TYPE_RULES = {
  required: ValidationMessage.questionRequired,
};
export const MESSAGE_TITLE_REQUIRED = {
  required: ValidationMessage.fieldRequired,
};
export const SUPPORT_ROLE_REQUIRED = {
  required: ValidationMessage.fieldRequired,
};
export const SUPPORT_CATEGORY_REQUIRED = {
  required: ValidationMessage.fieldRequired,
};
export const SUPPORT_AREA_REQUIRED = {
  required: ValidationMessage.fieldRequired,
};
export const SUPPORT_SUBJECT_REQUIRED = {
  required: ValidationMessage.fieldRequired,
  pattern: {
    value: SPACE_RULES,
    message: ValidationMessage.spaceNotRequired,
  },
};
export const SUPPORT_MESSAGE_REQUIRED = {
  required: ValidationMessage.fieldRequired,
  pattern: {
    value: SPACE_RULES,
    message: ValidationMessage.spaceNotRequired,
  },
};
