export const GET_PROJECTS_REQUEST = "[Items Action] Get Items Request";
export const GET_PROJECTS = "[Items Action] Get Items";
export const GET_PROJECTS_FAIL = "[Items Action] Get Items Fail";
export const CONFIRMED_GET_PROJECTS = "[Items Action] Confirmed Get Items";
export const ADD_FIRST_FORM = "[Items Action] Add First Form";
export const PROJECTS_ADD_REQUEST = "[Items Action] Add First Form Request";
export const ADD_SECOND_FORM = "[Items Action] Add Second Form Request";
export const GET_COMPANY_ITEM = "[Items Action] List of items available on company";
export const ADD_DOCS_FILE_REQUEST = "[Items Action] Save Document file request";
export const ADD_DOCS_FILE = "[Items Action] Save Document file";
export const CLEAR_ITEM_ON_SAVE = "[Items Action] Clear redux on save success";
export const CLEAR_ITEM_ON_SAVE_DOCS = "[Items Action] Clear document redux on save success";
export const REQUEST_FORM_TWO = "[Items Action] Clear document redux on save success";
export const REQUEST_FORM_TWO_DATA_SAVE = "[Items Action] Clear document redux on save success";
export const ADD_PROJECT_DRAFT_SAVE_STATUS = "[Items Action] Save item as draft status";