import { Api } from "../../../../api/Api";
import { GET_SELLER_RFX_API } from "../../../../constants/ApiUrl";

const GetAllRfxSeller = async (props) => {
  const { companyId, page, rowsPerPage, searchKey, filterValues } = props;
  let list = [];
  let limit = 0;

  let path = `${GET_SELLER_RFX_API}/${companyId}?per_page=${rowsPerPage}&page=${page}`;

  if (searchKey !== "") {
    path += `&searchKey=${searchKey}`;
  }

  if (filterValues.status !== "-1") {
    path += `&status=${filterValues.status}`;
  }

  if (filterValues.client !== "-1") {
    path += `&client=${filterValues.client}`;
  }

  if (filterValues.manager !== "") {
    path += `&manager=${filterValues.manager}`;
  }

  if (filterValues.type !== "-1") {
    path += `&type=${filterValues.type}`;
  }

  const response = await Api(path, {}, "get");

  if (response.status === 200) {
    if (response.data && response.data.rfx !== undefined) {
      list = response.data.rfx;
      for (let index = 0; index < list.length; index++) {
        let item = list[index];
        if (item.rfxId && item.rfxId.startDate && item.rfxId.endDate) {
          let startDate = new Date(item.rfxId.startDate);
          let endDate = new Date(item.rfxId.endDate);
          let diffHours = ((endDate - startDate) / 1000 / 3600).toFixed(1);

          list[index]["dueTime"] =
            startDate.toLocaleDateString() +
            " " +
            (diffHours > 1
              ? diffHours + "h"
              : Math.round(diffHours * 60) + "m");
        }
      }

      limit = response.data.totalRecord;
    }
  }

  return {
    list: list,
    limit: limit,
  };
};

export default GetAllRfxSeller;
