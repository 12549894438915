export class HtmlUtilService {
  static exceedLimit = false;

  static __truncate(value, limit = 50) {
    let result = value || "";
    if (value) {
      const words = value.split(/\s+/);
      if (words.length > Math.abs(limit)) {
        if (limit < 0) {
          limit *= -1;
          result = words.slice(words.length - limit, words.length).join(" ");
        } else {
          result = words.slice(0, limit).join(" ");
        }
      }
    }
    return result;
  }

  static truncate() {
    const elements = document.querySelectorAll("[truncate]");
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const elementWordNum = Number(element.getAttribute("truncate"));
      const children = element.children;
      for (let index = 0; index < children.length; index++) {
        const child = children[index];
        if (child.classList.contains("show__less__text")) {
          let trucatedText = this.__truncate(child.textContent, elementWordNum);
          if (trucatedText.length === child.textContent.length) {
            let siblings = this.getAllSiblings(child);
            for (let sibInx = 0; sibInx < siblings.length; sibInx++) {
              const sibling = siblings[sibInx];
              if (sibling.classList.contains("show__more__link")) {
                sibling.style.display = "none";
              }
            }
          }
          child.textContent = trucatedText;
        }

        if (child.classList.contains("show__more__link")) {
          if (child.style.display !== "none") {
            child.onclick = (event) => {
              let siblings = this.getAllSiblings(event.target);
              for (let sibInx = 0; sibInx < siblings.length; sibInx++) {
                const sibling = siblings[sibInx];
                if (sibling.classList.contains("show__less__text")) {
                  if (sibling.style.display == "none") {
                    sibling.style.display = "inline";
                  } else {
                    sibling.style.display = "none";
                  }
                }
                if (sibling.classList.contains("show__complete__text")) {
                  if (sibling.style.display == "none") {
                    sibling.style.display = "inline";
                    child.textContent = " ...less";
                  } else {
                    sibling.style.display = "none";
                    child.textContent = " ...more";
                  }
                }
              }
            };
          }
        }
      }
    }
  }

  static getAllSiblings(element) {
    // for collecting siblings
    let siblings = [];
    // if no parent, return no sibling
    if (!element.parentNode) {
      return siblings;
    }
    // first child of the parent node
    let sibling = element.parentNode.firstChild;

    // collecting siblings
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== element) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }
    return siblings;
  }
}
