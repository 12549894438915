import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { store } from "../../store/store";
import { Grid, InputAdornment, InputLabel, TextField } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../components/Notification";
import Modal from "@mui/material/Modal";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Search from "@material-ui/icons/Search";
import Moment from "moment";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import useWindowDimensions from "../../components/useWindowDimensions";
import TableOptionList from "../../components/TableOptionList";
import TablePaginationBox from "../../components/TablePaginationBox";
import "../Items/item.css";
import "./styles/tag.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import { Api } from "../../api/Api";
import {
  DELETE_TAG_API,
  GET_COMPANY_TAG_API,
  PATCH_EDIT_TAG_API,
  POST_ADD_TAG_API,
} from "../../constants/ApiUrl";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { toast, Slide } from "react-toastify";

var columns = [
  {
    label: "Tag Name",
    minWidth: 270,
  },
  {
    label: "Date Added",
    minWidth: 270,
  },
  // {
  //   label: "",
  // },
];

const initialFValues = {
  name: "",
};

const Tags = (props) => {
  const { setFullLoder, isAdmin, isSeller, isBuyer, currentRole } = props;
  const { xsScreen, smScreen } = useWindowDimensions();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tagsList, setTagsList] = React.useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editItemId, setEditItemId] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    resetForm();
    setIsEditMode(false);
  };
  const numOfPages = Math.ceil(tagsList.length / rowsPerPage);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() && isAdmin) {
      setFullLoder(true);
      const { auth } = store.getState();
      if (!isEditMode) {
        const payload = {
          name: values.name,
          userId: auth?.auth?.user?._id,
          companyId: auth?.auth?.user?.company?._id,
        };
        const response = await Api(POST_ADD_TAG_API, payload, "post");
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            setOpen(false);
            resetForm();
            getAllTags();
            setIsEditMode(false);
          }
          setFullLoder(false);
        } else {
          setFullLoder(false);
        }
      } else {
        if (editItemId !== "") {
          const updatePaylod = {
            name: values.name,
            userId: auth?.auth?.user?._id,
          };
          const response = await Api(
            `${PATCH_EDIT_TAG_API}/${editItemId}`,
            updatePaylod,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setOpen(false);
              resetForm();
              getAllTags();
              setEditItemId("");
              setIsEditMode(false);
            }
            setFullLoder(false);
          } else {
            setFullLoder(false);
          }
        } else {
          setFullLoder(false);
          toast.error(ValidationMessage.somethingWrong, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      }
    }
  };

  const getAllTags = async (searchText) => {
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      setFullLoder(true);
      const response = await Api(
        `${GET_COMPANY_TAG_API}/${companyId}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let TagsList = response.data.tag;

          setTagsList(TagsList);
        } else {
          setTagsList([]);
        }
        setFullLoder(false);
      } else {
        setFullLoder(false);
        setTagsList([]);
      }
    }
  };

  const editRow = async (itemData) => {
    if (itemData && isAdmin) {
      setIsEditMode(true);
      setValues({
        name: itemData.name,
      });
      setEditItemId(itemData._id);
      setOpen(true);
    }
  };

  const deleteItem = async (itemData) => {
    const itemId = itemData?._id;
    if (itemId && isAdmin) {
      setFullLoder(true);
      const response = await Api(`${DELETE_TAG_API}/${itemId}`, {}, "delete");
      if (response.status === 200) {
        setFullLoder(false);
        if (response.data.responseCode === "00") {
          const newTagsList = tagsList.filter((item) => item._id !== itemId);
          setTagsList(newTagsList);
          const numOfPages = Math.ceil(newTagsList.length / rowsPerPage);
          if (page > numOfPages) {
            setPage(numOfPages);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (tagsList.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [tagsList, page]);

  const clickCancel = () => {
    setOpen(false);
    setIsEditMode(false);
    resetForm();
  };

  const getSearchString = (e) => {
    const searchText = e.target.value;
    getAllTags(searchText);
  };

  useEffect(() => {
    if (isAdmin) {
      columns.push({
        label: "",
      });
    }
    getAllTags();
  }, []);

  return (
    <>
      <p className="profile-title">Tags</p>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          style={{ textAlign: xsScreen ? "center" : "start" }}
        >
          <TextField
            onChange={getSearchString}
            placeholder="Search Using Keyword or Tags..."
            className="select-box select-box-primary search-box"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {!isAdmin ? (
          <></>
        ) : (
          <Grid
            item
            sm={6}
            xs={12}
            style={{ textAlign: xsScreen ? "center" : "end" }}
          >
            <Button
              variant="contained"
              className="setting-button"
              onClick={() => setOpen(true)}
            >
              <AddOutlinedIcon
                style={{ fontSize: "21px", margin: "0 4px 0 0" }}
              />
              <span style={{ textTransform: "capitalize" }}>Add Tag</span>
            </Button>
          </Grid>
        )}
      </Grid>
      <TableContainer className="table-container mt-5" component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "700" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tagsList.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
              : tagsList
            ).map((tag) => (
              <TableRow key={tag._id}>
                <TableCell>{tag.name}</TableCell>
                <TableCell>
                  {Moment(tag.createdDate).format("DD/MM/YYYY LT")}
                </TableCell>
                {!isAdmin ? (
                  <></>
                ) : (
                  <TableCell>
                    <Box>
                      <PopupState variant="popover" popupId="option-list-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem
                                className="bold-line-600"
                                onClick={() => editRow(tag)}
                              >
                                Edit tag
                              </MenuItem>
                              <MenuItem
                                className="bold-line-600 clr-ff0000"
                                onClick={() => deleteItem(tag)}
                              >
                                Delete
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {tagsList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                  No records found <br />
                  {/* <i style={{ color: "var(---2170ff-p-)" }}>
                    Please add some tag
                  </i> */}
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePaginationBox
          label={tagsList.length + " tags Found"}
          numOfPages={numOfPages}
          onChangePage={(event, newPage) => setPage(newPage)}
          page={page}
        />
      </TableContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-label="add-edit-tag-settings-modal"
      >
        <Box className="popup-form-container">
          <div className="title"> {!isEditMode ? "Add" : "Edit"} Tags</div>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <InputLabel className="mui-label">Tags Name</InputLabel>
              <Controls.Input
                name="name"
                fullWidth
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                className="StepControlInput"
                inputProps={{ maxLength: 30 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
            <Grid container justifyContent="space-between" className="mt-5">
              <Grid item md={4} sm={12}>
                <Controls.Button
                  text="Cancel"
                  type="submit"
                  disableElevation
                  color="default"
                  onClick={() => clickCancel()}
                  className="btn btn-primary-lite btn-mui m-0 w-100"
                />
              </Grid>
              <Grid item md={5} sm={12} className={smScreen ? "mt-2" : ""}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text={!isEditMode ? "Add" : "Save"}
                  className="btn btn-primary btn-mui m-0 w-100"
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
  };
};

export default withRouter(connect(mapStateToProps)(Tags));
