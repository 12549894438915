import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import CollapsedPage from "../../../components/collapsedPage";
import ItemListDetails from "./itemListDetails";
import "../style/item-list-details.css";
import useWindowDimensions from "../../../components/useWindowDimensions";
import ItemEditboardDetails from "./itemEditboardDetails";
import Notification from "../../../components/Notification";

export default function DetailsSlider(props) {
  const { openPopup, setOpenPopup, item, getAllItemsByCompanyName } = props;
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getBodyWidth = () => {
    if (xlScreen) {
      return 50;
    } else if (xsScreen) {
      return 95;
    } else if (smScreen) {
      return 90;
    } else if (mdScreen) {
      return 80;
    } else if (lgScreen) {
      return 70;
    }
  };

  return (
    <>
      <CollapsedPage
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        bodyWidth={getBodyWidth()}
        getAllItemsByCompanyName={getAllItemsByCompanyName}
      >
        <Grid
          container
          className="item-list-details-container"
          style={{ backgroundColor: "#fff", minHeight: "100vh" }}
        >
          <Grid item xs={8} className="p-5 scroll-page">
            <ItemListDetails item={item} setNotify={setNotify} />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ backgroundColor: "#F8FCFF" }}
            className="p-5 scroll-page"
          >
            <ItemEditboardDetails item={item} setNotify={setNotify} />
          </Grid>
        </Grid>
      </CollapsedPage>
      <Notification
        notify={notify}
        setNotify={setNotify}
        id="item-slider-details-buyer-aspect-notification-box"
      />
    </>
  );
}
