import { Box, Grid, InputLabel } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import EnglishAuctionPopup from "../pages/Auction/addEngAuctionPopup";
import Step2Form from "../pages/Auction/FormStep2";
import Step3Form from "../pages/Auction/FormStep3";
import Step4Form from "../pages/Auction/FormStep4";
import Step5Form from "../pages/Auction/FormStep5";
import axiosInstance from "../services/AxiosInstance";
import { projectDetails } from "../store/actions/auctionAction";
import { getCurrentRole } from "../store/selectors/AuthorSelector";
import { isAdmin, isBuyer, isSeller } from "../store/selectors/RoleSelector";
import { store } from "../store/store";
import Notification from "./Notification";
import { Form, useForm } from "./useForm";
import ConfirmationDailog from "../pages/Auction/components/confirmationDailog";
import Controls from "./controls/Controls";
import { Api } from "../api/Api";
import { AUCTION_LIST_API, PROJECT_LIST_API } from "../constants/ApiUrl";

const CreateAuctionButton = (props) => {
  const stepLabels = [
    "General Information",
    "Setting",
    "Attachment",
    "Item",
    "Suppliers",
  ];

  const projectType = [
    { id: "1", title: "English Reverse Auction" },
    // { id: "2", title: "type #2" },
    // { id: "3", title: "Type #3" },
  ];

  const initialFValues = {
    project_id: "",
    rfx_id: "",
    auction_type: "1",
  };

  const { isAdmin, isSeller, isBuyer, currentRole, open } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupEnglishAuction, setOpenPopupEnglishAuction] = useState(false);
  const [openPopupStep2, setOpenPopupStep2] = useState(false);
  const [openPopupStep3, setOpenPopupStep3] = useState(false);
  const [openPopupStep4, setOpenPopupStep4] = useState(false);
  const [openPopupStep5, setOpenPopupStep5] = useState(false);
  const [dropdownProject, setDropdownProject] = useState([]);
  const [isEditRecord, setIsEditRecord] = useState(false);
  const [openConfrmationDailog, setOpenConfrmationDailog] = useState(false);
  const [auctionList, setAuctionList] = useState([]);
  const [fullLoder, setFullLoder] = useState(true);
  const dispatch = useDispatch();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("project_id" in fieldValues)
      temp.project_id = fieldValues.project_id ? "" : "Project is required.";

    if ("auction_type" in fieldValues)
      temp.auction_type = fieldValues.auction_type ? "" : "Type is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate() && isBuyer) {
      dispatch(projectDetails(values));
      addAuctionPopup();
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getProjectListForDropdown = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(`${PROJECT_LIST_API}/${companyId}`, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.projectData;
        setDropdownProject(allItems);
      }
    }
    // await axiosInstance
    //   .get(`api/buyer/getProjectsList/${companyId}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allItems = response.data.projectData;
    //       setDropdownProject(allItems);
    //     }
    //   })
    //   .catch((err) => {});
  };

  const clickClosePopup = () => {
    setOpenConfrmationDailog(true);
    setOpenPopup(false);
    resetForm();
    setIsEditRecord(false);
  };

  const getAllAuction = async (searchText) => {
    setFullLoder(true);
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${AUCTION_LIST_API}/${companyId}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allAuctionList = response.data.auction;
          const loader = setTimeout(() => {
            setFullLoder(false);
          }, 600);
          setAuctionList(allAuctionList);
          return () => {
            clearTimeout(loader);
          };
        } else {
          setAuctionList([]);
          setFullLoder(false);
        }
      } else {
        setFullLoder(false);
        setAuctionList([]);
      }
      // await axiosInstance
      //   .get(`api/buyer/auctionList/${companyId}${queryString}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let allAuctionList = response.data.auction;
      //       const loader = setTimeout(() => {
      //         setFullLoder(false);
      //       }, 600);
      //       setAuctionList(allAuctionList);
      //       return () => {
      //         clearTimeout(loader);
      //       };
      //     } else {
      //       setAuctionList([]);
      //       setFullLoder(false);
      //     }
      //   })
      //   .catch((err) => {
      //     setFullLoder(false);
      //     setAuctionList([]);
      //   });
    } else {
      setFullLoder(false);
      setAuctionList([]);
    }
  };

  const openPPAddAuction = async () => {
    setFullLoder(true);
    resetForm();
    await getProjectListForDropdown();
    setOpenPopup(true);
    setFullLoder(false);
  };

  const addAuctionPopup = () => {
    setOpenPopup(false);
    setOpenPopupEnglishAuction(true);
  };

  return (
    <div style={{ display: "block" }}>
      <button
        className={
          "btn btn-success " +
          (open ? "add-auction-animation-show" : "add-auction-animation-hide")
        }
        onClick={() => {
          openPPAddAuction();
        }}
      >
        <AddIcon />
        <span>Add Auction</span>
      </button>

      <Modal
        open={openPopup}
        aria-label="create-auction-zero-step-buyer-aspect"
        aria-describedby="create-auction-zero-step-buyer-aspect-desc"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Add Auction</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  // clickClosePopup();
                  setOpenPopup(false);
                  resetForm();
                  setIsEditRecord(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Form onSubmit={handleSubmit} className="mt-5">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel>Project</InputLabel>
                <Controls.Select
                  name="project_id"
                  value={values.project_id}
                  fullWidth
                  onChange={handleInputChange}
                  options={dropdownProject}
                  error={errors.project_id}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <InputLabel>RFX</InputLabel>
                <Controls.Select
                  name="rfx_id"
                  value={values.rfx_id}
                  fullWidth
                  onChange={handleInputChange}
                  options={[]}
                  error={errors.rfx_id}
                />
              </Grid> */}
              <Grid item xs={12}>
                <InputLabel>Type</InputLabel>
                <Controls.Select
                  name="auction_type"
                  value={values.auction_type}
                  fullWidth
                  onChange={handleInputChange}
                  options={projectType}
                  error={errors.auction_type}
                />
              </Grid>
            </Grid>
            <Box
              className="BottomModal"
              display="flex"
              justifyContent="center"
              p={1}
              bgcolor="background.paper"
            >
              <Grid item sm={6}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text={!isEditRecord ? "Add Auction" : "Update Auction"}
                  className="btn-mui w-100"
                  // onClick={() => addAuctionPopup()}
                />
              </Grid>
            </Box>
          </Form>
        </Box>
      </Modal>

      {/* step 1 */}
      <Modal
        open={openPopupEnglishAuction}
        aria-label="create-auction-first-step-buyer-aspect"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <EnglishAuctionPopup
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            stepLabels={stepLabels}
          />
        </Box>
      </Modal>

      {/* step 2 */}
      <Modal
        open={openPopupStep2}
        aria-label="create-auction-second-step-buyer-aspect"
        aria-describedby="modal-modal-description"
        className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Step2Form
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            setOpenPopupStep3={setOpenPopupStep3}
            stepLabels={stepLabels}
            setNotify={setNotify}
            projects={dropdownProject}
          />
        </Box>
      </Modal>

      {/* step3 */}
      <Modal
        open={openPopupStep3}
        aria-label="create-auction-third-step-buyer-aspect"
        aria-describedby="modal-modal-description"
        className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Step3Form
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            setOpenPopupStep3={setOpenPopupStep3}
            setOpenPopupStep4={setOpenPopupStep4}
            stepLabels={stepLabels}
          />
        </Box>
      </Modal>

      {/* step4 */}
      <Modal
        open={openPopupStep4}
        aria-label="create-auction-fourth-step-buyer-aspect"
        aria-describedby="modal-modal-description"
        className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Step4Form
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            setOpenPopupStep3={setOpenPopupStep3}
            setOpenPopupStep4={setOpenPopupStep4}
            setOpenPopupStep5={setOpenPopupStep5}
            setNotify={setNotify}
            stepLabels={stepLabels}
          />
        </Box>
      </Modal>

      {/* step5 */}
      <Modal
        open={openPopupStep5}
        aria-label="create-auction-fifth-step-buyer-aspect"
        aria-describedby="modal-modal-description"
        className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Step5Form
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            setOpenPopupStep3={setOpenPopupStep3}
            setOpenPopupStep4={setOpenPopupStep4}
            setOpenPopupStep5={setOpenPopupStep5}
            setAuctionList={setAuctionList}
            setNotify={setNotify}
            setFullLoder={setFullLoder}
            stepLabels={stepLabels}
          />
        </Box>
      </Modal>

      <ConfirmationDailog
        openConfrmationDailog={openConfrmationDailog}
        setOpenConfrmationDailog={setOpenConfrmationDailog}
        setDiscardAndClose={setOpenPopup}
        eraseEverything={false}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
  };
};

export default withRouter(connect(mapStateToProps)(CreateAuctionButton));
