import { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { InputLabel } from "@mui/material";
import Controls from "../../../../components/controls/Controls";
import { useForm, Form } from "../../../../components/useForm";
import uploadIcon from "../../../../assets/icons/Upload@2x.png";
import attachIcon from "../../../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../../../assets/icons/CloseX.png";
import {
  ACCEPT_ITEM_ATTACHMENTS,
  ITEM_ATTACHMENTS_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../../../constants/AppConstants";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { Api } from "../../../../api/Api";
import { POST_ADD_ATTACH_RFX_API, POST_EDIT_ATTACH_RFX_API } from "../../../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";
import { useSelector } from "react-redux";

const initialFValues = {
  name: "",
  notes: "",
};
const RfxDocumentAttachment = (props) => {
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const {
    steDocumentPopup,
    editDocumentData,
    isEditMode,
    editDocumentId,
    setIsEditMode,
    getDocumets,
    setEditDocumentData,
  } = props;

  const [documentFile, setDocumentFile] = useState({});

  const clickClosePopup = () => {
    steDocumentPopup(false);
    resetForm();
    setDocumentFile({});
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("notes" in fieldValues)
      temp.notes = fieldValues.notes ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let formData = new FormData();
      if (!isEditMode) {
        if (documentFile && documentFile.name) {
          if (rfxDetails !== undefined && rfxDetails._id !== undefined) {
            formData.append("name", values.name);
            formData.append("notes", values.notes);
            formData.append("type", "view");
            formData.append("documents", documentFile);

            const response = await Api(
              `${POST_ADD_ATTACH_RFX_API}/${rfxDetails._id}`,
              formData,
              "postMultipart"
            );
            if (response.status === 200) {
              if (response.data.responseCode === "00") {
                resetForm();
                setDocumentFile({});
                steDocumentPopup(false);
                getDocumets();
              }
            }
          }
        } else {
          toast.error(ValidationMessage.selectDocument, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      } else {
        if (
          rfxDetails !== undefined &&
          rfxDetails._id !== undefined &&
          editDocumentId
        ) {
          formData.append("name", values.name);
          formData.append("notes", values.notes);
          formData.append("type", "attachment");
          if (documentFile && documentFile.name) {
            formData.append("documents", documentFile);
          }
          const response = await Api(
            `${POST_EDIT_ATTACH_RFX_API}/${rfxDetails._id}/${editDocumentId}`,
            formData,
            "postMultipart"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              resetForm();
              setDocumentFile({});
              setIsEditMode(false);
              setEditDocumentData({});
              steDocumentPopup(false);
              getDocumets();
            }
          }
        }
      }
    }
  };

  const RemoveDocument = () => {
    setDocumentFile({});
  };

  async function handleImageChange(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ACCEPT_ITEM_ATTACHMENTS;
    if (selected && !ALLOWED_TYPES.includes(selected.type)) {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }
    if (selected && Math.round(selected.size / 1024) > ITEM_ATTACHMENTS_SIZE) {
      toast.error(ValidationMessage.itemAttachmentFileSize, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setDocumentFile(selected);
    }
  }

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  useEffect(() => {
    if (editDocumentData && editDocumentData.fileName) {
      setValues({
        name: editDocumentData.name,
        notes: editDocumentData.notes,
      });
      delete editDocumentData.name;
      setDocumentFile(editDocumentData);
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="image-uploader-container">
            {documentFile && (
              <>
                <div className="uploader-header">
                  <img src={uploadIcon} className="upload-icon" alt="" />
                  <p className="upload-title">Drag or drop files here</p>

                  <p className="upload-description">
                    Max. file size is 10mb <br />
                    Files must be .pdf, .exl, .png, or .jpg
                  </p>

                  <label htmlFor="fileUpload">
                    <Controls.Button
                      component="span"
                      text="Choose File"
                      disableElevation
                      className="mui-btn upload-btn"
                    />
                  </label>
                </div>
                <input
                  type="file"
                  id="fileUpload"
                  onChange={handleImageChange}
                  // value={attachment?.image}
                  accept={ACCEPT_ITEM_ATTACHMENTS}
                />
              </>
            )}
          </div>
        </Grid>

        {documentFile && (documentFile.name || documentFile?.displayName) ? (
          <Grid item xs={12} style={{ alignItems: "flex-end" }}>
            <Box component="div" className="uploaded-files">
              <Grid container>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  <img src={attachIcon} className="icon" alt="" />
                </Grid>
                <Grid item xs={9} className="details">
                  <Grid container>
                    <Grid item xs={12}>
                      <box className="name">
                        {documentFile?.name || documentFile?.displayName}
                      </box>
                    </Grid>
                    <Grid item xs={12}>
                      <box className="size">
                        {bytesToSize(documentFile?.size || 50000)}
                      </box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    textAlign: "end",
                    margin: "auto",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={() => RemoveDocument()}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12}>
          <InputLabel className="mui-label">Name</InputLabel>
          <Controls.Input
            labelId="notes-input"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
            className="StepControlInput"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className="mui-label">Notes</InputLabel>
          <Controls.Input
            labelId="notes-input"
            name="notes"
            value={values.notes}
            onChange={handleInputChange}
            error={errors.notes}
            multiline="multiline"
            Rows={4}
            className="StepControlInput"
          />
        </Grid>
      </Grid>
      <Box display="flex" mt={4} bgcolor="background.paper">
        <Grid istem xs={4} className="BottomModal-left">
          <Box p={0} flexGrow={1}>
            <Controls.Button
              text="Cancel"
              disableElevation
              onClick={() => {
                clickClosePopup();
              }}
              style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
              color="default"
              className="btn-mui"
            />
          </Box>
        </Grid>
        <Grid item xs={8} className="BottomModal-right">
          <Box p={0}>
            <Controls.Button
              type="submit"
              disableElevation
              text={!isEditMode ? "Attach" : "Update"}
              className="btn-mui"
              style={{ minWidth: "197px" }}
            />
          </Box>
        </Grid>
      </Box>
    </Form>
  );
};

export default RfxDocumentAttachment;
