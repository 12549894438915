import { Api } from "../../api/Api";
import {
  AUCTION_SAVE_API,
  POST_RFX_DRAFT_API,
  RFX_SAVE_API,
} from "../../constants/ApiUrl";
import {
  CLEAR_REDUX_ON_CLOSE_RFX,
  CLEAR_REDUX_ON_SAVED_SUCCESS,
  GET_LIVE_RFX_DETAILS,
  GET_RFX_DETAILS,
  REFRESH_LIVE_RFX_BUYER,
  REFRESH_LIVE_RFX_DATA_BUYER,
  RFX_SAVE_LIB_DOCUMENT,
  RFX_SAVE_LIB_DOCUMENT_REQUEST,
  RFX_TEMP_SAVE_LIB_DOCUMENT,
  RFX_TEMP_SAVE_LIB_DOCUMENT_REQUEST,
  SAVE_RFX_PROJECT_DATA,
  SAVE_RFX_PROJECT_DATA_REQUEST,
  SAVE_RFX_PROJECT_LINK,
  SAVE_RFX_PROJECT_LINK_REQUEST,
  SAVE_RFX_STATUS,
  SAVE_RFX_STEP_1,
  SAVE_RFX_STEP_1_REQUEST,
  SAVE_RFX_STEP_2,
  SAVE_RFX_STEP_2_REQUEST,
  SAVE_RFX_STEP_3,
  SAVE_RFX_STEP_3_REQUEST,
  SAVE_RFX_STEP_4,
  SAVE_RFX_STEP_4_REQUEST,
  SAVE_RFX_STEP_5,
  SAVE_RFX_STEP_5_REQUEST,
  SAVE_RFX_STEP_6,
  SAVE_RFX_STEP_6_REQUEST,
} from "../constant/rfxConstant";

export const rfxDetails = (values) => (dispatch, getState) => {
  dispatch({
    type: SAVE_RFX_PROJECT_DATA_REQUEST,
  });
  dispatch({
    type: SAVE_RFX_PROJECT_DATA,
    payload: values,
  });
};

export const RfxFormStep1Add = (values) => (dispatch, getState) => {
  const { rfx } = getState();
  const projectData = Object.assign(rfx.RfxForm1, values);
  dispatch({
    type: SAVE_RFX_STEP_1_REQUEST,
  });
  dispatch({
    type: SAVE_RFX_STEP_1,
    payload: projectData,
  });
};

export const RFXFormStep2Add = (values) => (dispatch, getState) => {
  const { rfx } = getState();
  const form1Data = Object.assign(rfx.RfxForm1, values);
  dispatch({
    type: SAVE_RFX_STEP_2_REQUEST,
  });
  dispatch({
    type: SAVE_RFX_STEP_2,
    payload: form1Data,
  });
};

export const RFXFormStep3Add = (values) => (dispatch, getState) => {
  const { rfx } = getState();
  const form1Data = Object.assign(rfx.RfxForm1, values);
  dispatch({
    type: SAVE_RFX_STEP_3_REQUEST,
  });
  dispatch({
    type: SAVE_RFX_STEP_3,
    payload: form1Data,
  });
};

export const RfxFormStep4Add = (values) => (dispatch, getState) => {
  const { rfx } = getState();
  const form1And2Data = Object.assign(rfx.RfxForm1, values);
  dispatch({
    type: SAVE_RFX_STEP_4_REQUEST,
  });
  dispatch({
    type: SAVE_RFX_STEP_4,
    payload: form1And2Data,
  });
};
export const RfxAddLinkData = (values) => (dispatch, getState) => {
  const { rfx } = getState();
  const form1And2Data = Object.assign(rfx.RfxForm1, values);
  dispatch({
    type: SAVE_RFX_PROJECT_LINK_REQUEST,
  });
  dispatch({
    type: SAVE_RFX_PROJECT_LINK,
    payload: form1And2Data,
  });
};

export const RfxAddLibraryDocs = (values) => (dispatch, getState) => {
  const { rfx } = getState();
  const form1And2Data = Object.assign(rfx.RfxForm1, values);
  dispatch({
    type: RFX_SAVE_LIB_DOCUMENT_REQUEST,
  });
  dispatch({
    type: RFX_SAVE_LIB_DOCUMENT,
    payload: form1And2Data,
  });
};

export const RfxAddLibraryDocsTemp = (values) => (dispatch, getState) => {
  const { rfx } = getState();
  const form1And2Data = Object.assign(rfx.RfxForm1, values);
  dispatch({
    type: RFX_TEMP_SAVE_LIB_DOCUMENT_REQUEST,
  });
  dispatch({
    type: RFX_TEMP_SAVE_LIB_DOCUMENT,
    payload: form1And2Data,
  });
};

export const RfxFormStep5Add = (values) => (dispatch, getState) => {
  const { rfx } = getState();
  const form1And2Data = Object.assign(rfx.RfxForm1, values);
  dispatch({
    type: SAVE_RFX_STEP_5_REQUEST,
  });
  dispatch({
    type: SAVE_RFX_STEP_5,
    payload: form1And2Data,
  });
};
export const RfxSubmit = (values) => (dispatch, getState) => {
  const { rfx } = getState();
  const form1And2Data = Object.assign(rfx.RfxForm1, values);
  dispatch({
    type: SAVE_RFX_STEP_6_REQUEST,
  });
  dispatch({
    type: SAVE_RFX_STEP_6,
    payload: form1And2Data,
  });
};

export const AddSupplierInRfx = (value) => {
  return async (dispatch, getState) => {
    const {
      rfx,
      auth: { auth },
    } = getState();
    const link = [
      {
        link: "",
        title: "",
        notes: "",
      },
    ];
    const form1And2Data = rfx.RfxForm1;
    let formData = new FormData();

    let OldSupplierArray = [];
    let newSupplierArray = [];
    if (form1And2Data && form1And2Data.Suppliers) {
      const supplierArray = form1And2Data.Suppliers;
      supplierArray.forEach((element) => {
        if (element && element.isInvieted) {
          newSupplierArray.push(element);
        } else {
          OldSupplierArray.push(element);
        }
      });
    }
    formData.append("projectId", form1And2Data.project_id);
    formData.append("rfx_type", form1And2Data.rfx_type);
    formData.append("rfx_name", form1And2Data.name);
    if (form1And2Data.description) {
      formData.append("description", form1And2Data.description);
    }
    if (form1And2Data?.deliveryDate) {
      formData.append("deliveryDate", form1And2Data.deliveryDate);
    }
    formData.append("startDate", form1And2Data.startDate);
    formData.append("endDate", form1And2Data.endDate);
    formData.append("currency", form1And2Data.currency);
    if (form1And2Data?.const_center && form1And2Data?.const_center.length > 0)
      formData.append("cost_center", form1And2Data.const_center);
    if (form1And2Data?.budget && form1And2Data?.budget.length > 0)
      formData.append("budget", form1And2Data.budget);

    formData.append("supplier_to_provide", form1And2Data.supplier_to_provide);

    formData.append("sealed_bid", form1And2Data?.sealedBidCheckbox || false);
    formData.append(
      "allow_supplier_document_upload",
      form1And2Data?.uploadDocumentCheckbox || false
    );

    formData.append("items", JSON.stringify(form1And2Data.Items));
    formData.append("questions", JSON.stringify(form1And2Data.question));
    formData.append("suppliers", JSON.stringify(OldSupplierArray));
    if (
      form1And2Data &&
      form1And2Data.files &&
      form1And2Data.files.length > 0
    ) {
      for (let i = 0; i < form1And2Data.files.length; i++) {
        formData.append(`documents[${i}]`, form1And2Data.files[i]);
      }
    }

    if (form1And2Data && form1And2Data.links) {
      formData.append("links", JSON.stringify(form1And2Data.links));
    } else {
      formData.append("links", JSON.stringify(link));
    }
    formData.append("companyId", auth.user?.company?._id);
    formData.append("createdBy", auth.user?._id);

    if (newSupplierArray.length > 0) {
      formData.append(
        "selectedInviteNewSupplier",
        JSON.stringify(newSupplierArray)
      );
    }
    if (
      form1And2Data &&
      form1And2Data.libDocs &&
      form1And2Data.libDocs.length > 0
    ) {
      formData.append("docs", JSON.stringify(form1And2Data.libDocs));
    }
    let returnRes;
    const response = await Api(RFX_SAVE_API, formData, "postMultipart");

    returnRes = response;
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        dispatch({
          type: CLEAR_REDUX_ON_SAVED_SUCCESS,
          payload: {},
        });
        dispatch({
          type: SAVE_RFX_STATUS,
          payload: true,
        });
      } else {
        dispatch({
          type: SAVE_RFX_STATUS,
          payload: false,
        });
      }
    } else {
      dispatch({
        type: SAVE_RFX_STATUS,
        payload: false,
      });
    }
    return returnRes;
  };
};

export const rfxSaveAsDraft = (values) => {
  return async (dispatch, getState) => {
    const {
      rfx,
      auth: { auth },
    } = getState();
    const link = [
      {
        link: "",
        title: "",
        notes: "",
      },
    ];

    const form1And2Data = rfx.RfxForm1;
    let formData = new FormData();

    let OldSupplierArray = [];
    let newSupplierArray = [];
    if (form1And2Data && form1And2Data.Suppliers) {
      const supplierArray = form1And2Data.Suppliers;
      supplierArray.forEach((element) => {
        if (element && element.isInvieted) {
          newSupplierArray.push(element);
        } else {
          OldSupplierArray.push(element);
        }
      });
    }
    formData.append("projectId", form1And2Data.project_id);
    formData.append("rfx_type", form1And2Data.rfx_type);
    formData.append("rfx_name", form1And2Data.name);
    formData.append("description", form1And2Data.description);
    formData.append("startDate", form1And2Data.startDate);
    formData.append("endDate", form1And2Data.endDate);
    formData.append("currency", form1And2Data.currency);
    if (form1And2Data?.const_center && form1And2Data?.const_center.length > 0)
      formData.append("cost_center", form1And2Data.const_center);
    if (form1And2Data?.budget && form1And2Data?.budget.length > 0)
      formData.append("budget", form1And2Data.budget);
    if (form1And2Data?.deliveryDate) {
      formData.append("deliveryDate", form1And2Data.deliveryDate);
    }
    formData.append("supplier_to_provide", form1And2Data.supplier_to_provide);

    formData.append("sealed_bid", form1And2Data?.sealedBidCheckbox || false);
    formData.append(
      "allow_supplier_document_upload",
      form1And2Data?.uploadDocumentCheckbox || false
    );
    if (form1And2Data.Items && form1And2Data.Items.length > 0)
      formData.append("items", JSON.stringify(form1And2Data.Items));
    if (form1And2Data.question && form1And2Data.question.length > 0)
      formData.append("questions", JSON.stringify(form1And2Data.question));
    if (OldSupplierArray && OldSupplierArray.length > 0)
      formData.append("suppliers", JSON.stringify(OldSupplierArray));
    if (
      form1And2Data &&
      form1And2Data.files &&
      form1And2Data.files.length > 0
    ) {
      for (let i = 0; i < form1And2Data.files.length; i++) {
        formData.append(`documents[${i}]`, form1And2Data.files[i]);
      }
    }

    if (form1And2Data && form1And2Data.links) {
      formData.append("links", JSON.stringify(form1And2Data.links));
    } else {
      formData.append("links", JSON.stringify(link));
    }
    formData.append("companyId", auth.user?.company?._id);
    formData.append("createdBy", auth.user?._id);

    if (newSupplierArray.length > 0) {
      formData.append(
        "selectedInviteNewSupplier",
        JSON.stringify(newSupplierArray)
      );
    }
    if (
      form1And2Data &&
      form1And2Data.libDocs &&
      form1And2Data.libDocs.length > 0
    ) {
      formData.append("docs", JSON.stringify(form1And2Data.libDocs));
    }
    let returnRes;
    const response = await Api(POST_RFX_DRAFT_API, formData, "postMultipart");
    if (response.status === 200) {
      returnRes = response;
      if (response.data.responseCode === "00") {
        //  let addedProjects = response.data;
        dispatch({
          type: CLEAR_REDUX_ON_CLOSE_RFX,
          payload: {},
        });
        dispatch({
          type: SAVE_RFX_STATUS,
          payload: true,
          code: 200,
          message: "Auction has been created successfully !",
          success: true,
        });
      } else {
        dispatch({
          type: SAVE_RFX_STATUS,
          payload: false,
        });
      }
    } else {
      dispatch({
        type: SAVE_RFX_STATUS,
        payload: false,
        code: response?.status,
        message: response?.data?.responseDescription
          ? response?.data?.responseDescription
          : response?.message,
        success: false,
      });
    }
    return returnRes;
  };
};

// # live monitoring
export function setLiveRfxDetails(rfx) {
  return {
    type: GET_LIVE_RFX_DETAILS,
    payload: rfx,
  };
}

export function refreshLiveRfxDataBuyer(rfx) {
  return {
    type: REFRESH_LIVE_RFX_DATA_BUYER,
    payload: rfx,
  };
}

export function refreshLiveRfxBuyer(rfx) {
  return {
    type: REFRESH_LIVE_RFX_BUYER,
    payload: rfx,
  };
}
// # live monitoring

export function setRfxDetails(rfx) {
  return {
    type: GET_RFX_DETAILS,
    payload: rfx,
  };
}
