import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@material-ui/core";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../components/Notification";
import "./styles/auction-table.css";
import TableOptionList from "../../components/TableOptionList";
import TablePaginationBox from "../../components/TablePaginationBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AuctionsBgImg from "../../assets/Auctions@2x.png";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import ConfirmDialog from "../../components/ConfirmDialog";
import { store } from "../../store/store";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { currentUser } from "../../store/selectors/AuthSelectors";
import { v1 as uuidv1 } from "uuid";
import { thousandSepratorHandler } from "../../utility/utils";
import { Api } from "../../api/Api";
import { COMAPNY_API } from "../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_ARCHIEVED,
  AUCTION_DRAFT,
  AUCTION_ENDED,
  AUCTION_ON_CANCELLED,
  AUCTION_ON_HOLD,
  AUCTION_SMALL_PUBLISHED,
  BLUE_BACKGROUND,
  BLUE_HEX_COLOUR_CODE,
  DANGER_BACKGROUND,
  DISABELED_BACKGROUND,
  GREEN_BACKGROUND,
  GREEN_HEX_COLOUR_CODE,
  GREY_BACKGROUND,
  PRIMARY_BACKGROUND,
  PURPLE_BACKGROUND,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  SUPPLIER_AUCTION_RUNNING,
  TYPE_ONE,
  TYPE_THREE,
  TYPE_TWO,
  WARNING_BACKGROUND,
  WARNING_OUTLINE_BACKGROUND,
} from "../../constants/AppConstants";
import DuplicateAuctionDialog from "./components/DuplicateAuctionDialog";

var columns = [
  {
    label: "ID",
  },
  {
    label: "Type",
  },
  {
    label: "Auction Name",
  },
  {
    label: "Start Date",
  },
  {
    label: "Duration",
  },
  {
    label: "Best Bid",
  },
  {
    label: "Best Bidder",
  },
  {
    label: "Confirmed/Invited",
  },
  {
    label: "Status",
  },
  {
    label: "Owner",
  },
  {
    label: "",
  },
];

const AuctionTable = (props) => {
  const {
    auctionList,
    isSeller,
    isBuyer,
    currentUser,
    setResendApi,
    limit,
    page,
    setPage,
    numOfPages,
  } = props;
  
  const history = useHistory();
  const [deleteAuctionId, setDeleteAuctionId] = React.useState(null);
  const [duplicateAuctionModal, setDuplicateAuctionModal] =
    React.useState(false);
  const [auctionData, setAuctionData] = useState({});

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const editAuction = (auctionId) => {
    history.push("/auction/" + auctionId + "/details");
  };

  const DeleteAuction = (auction) => {
    if (auction._id && isBuyer) {
      setDeleteAuctionId(auction?._id);
      setConfirmDialog({
        isOpen: true,
        title: auction?.auction_name,
        subTitle: "Are you sure to delete Auction?",
      });
    }
  };

  const confirmDeleteProject = async () => {
    if (deleteAuctionId && isBuyer) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      // const dataFoundOnCurrentPage = visibleAuctions.length > 0;

      const response = await Api(
        `${COMAPNY_API}/${companyId}/auctions/${deleteAuctionId}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setConfirmDialog({
            isOpen: false,
            title: "",
            subTitle: "",
          });

          if (auctionList.length - 1 === 0) {
            setPage((prevPage) => prevPage - 1);
            // setPage(page - 1);
          } else {
            setResendApi(true);
          }
        }
      }
    }
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case TYPE_ONE:
        return BLUE_BACKGROUND;
      case TYPE_TWO:
        return GREY_BACKGROUND;
      case TYPE_THREE:
        return GREEN_BACKGROUND;
      default:
        return DISABELED_BACKGROUND;
    }
  };

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ENDED:
        return WARNING_OUTLINE_BACKGROUND;
      case AUCTION_SMALL_PUBLISHED:
        return SUCCESS_BACKGROUND;
      case AUCTION_DRAFT:
        return SECONDARY_BACKGROUND;
      case SUPPLIER_AUCTION_RUNNING:
        return PRIMARY_BACKGROUND;
      case AUCTION_ON_HOLD:
        return WARNING_BACKGROUND;
      case AUCTION_ON_CANCELLED:
        return DANGER_BACKGROUND;
      case AUCTION_ARCHIEVED:
        return PURPLE_BACKGROUND;
      default:
    }
  };

  const getColor = (status) => {
    if (status === AUCTION_ACTIVE) {
      return BLUE_HEX_COLOUR_CODE;
    } else {
      return GREEN_HEX_COLOUR_CODE;
    }
  };

  return (
    <>
      <Paper elevation={0} square>
        <TableContainer
          className="table-container buyer-aspect"
          component={Paper}
        >
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id ?? uuidv1()}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {auctionList.map((auction) => (
                <TableRow
                  key={auction._id}
                  className="cursor-p"
                  onDoubleClick={() => editAuction(auction._id)}
                >
                  <TableCell>{auction.auctionId}</TableCell>
                  <TableCell>
                    <div
                      className={
                        "aution-img-container " +
                        getTypeLabel(auction.auction_type)
                      }
                    >
                      <img src={AuctionsBgImg} alt="" />
                    </div>
                  </TableCell>
                  <TableCell className="auction-title">
                    <h3 className="show-wrap-table-text">
                      {auction.auction_name}{" "}
                    </h3>
                    <p className="show-wrap-table-text">
                      {auction?.projectId?.project_name}{" "}
                    </p>
                  </TableCell>
                  <TableCell className="auction-datetime">
                    {Moment(auction?.startDate).format("DD/MM/YYYY LT")}
                  </TableCell>
                  <TableCell>{auction?.timeRemaining}</TableCell>
                  <TableCell>
                    {auction?.bestBid?.bid
                      ? thousandSepratorHandler(auction?.bestBid?.bid)
                      : "-"}
                    {auction?.bestBid?.bid ? auction?.currency : ""}
                  </TableCell>
                  <TableCell style={{ color: getColor(auction?.status) }}>
                    {auction?.bestBid?.supplierId?.company_name || "-"}
                  </TableCell>
                  {/* <TableCell>{auction.confirmedInv || "-"}</TableCell> */}
                  <TableCell>
                    {auction?.totalAccepted}/{auction?.totalInvited}
                  </TableCell>
                  <TableCell>
                    <Button
                      className={
                        "badge badge-" +
                        getStatusLabel(auction.status) +
                        " status"
                      }
                      variant="contained"
                    >
                      {auction.status}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {auction?.projectId?.createdBy?.first_name}{" "}
                    {auction?.projectId?.createdBy?.last_name}
                  </TableCell>
                  {!isBuyer ? (
                    <></>
                  ) : (
                    <TableCell className="auction-action-tab">
                      <Box>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <div>
                              <Button
                                className="action-btn"
                                variant="contained"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </Button>
                              <TableOptionList {...bindMenu(popupState)}>
                                <MenuItem
                                  className="bold-line-600"
                                  onClick={() => {
                                    editAuction(auction._id);
                                    popupState.close();
                                  }}
                                >
                                  <div>View Details</div>
                                </MenuItem>
                                {/* {isBuyer &&
                                currentUser &&
                                String(auction.createdBy) ===
                                  String(currentUser._id) ? ( */}
                                <div>
                                  <Divider />
                                  <MenuItem
                                    className="bold-line-600 clr-ff0000"
                                    onClick={() => {
                                      DeleteAuction(auction);
                                      popupState.close();
                                    }}
                                  >
                                    <div>Delete</div>
                                  </MenuItem>
                                </div>
                                {/* ) : (
                                  <div></div>
                                )} */}
                                <div>
                                  <Divider />
                                  <MenuItem
                                    className="bold-line-600"
                                    onClick={() => {
                                      setDuplicateAuctionModal(true);
                                      setAuctionData({ ...auction });
                                      popupState.close();
                                    }}
                                  >
                                    <div>Duplicate</div>
                                  </MenuItem>
                                </div>
                              </TableOptionList>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {auctionList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some auction
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={limit + " Auctions Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => {
              setPage(newPage);
            }}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmDeleteProject={confirmDeleteProject}
      />
      <DuplicateAuctionDialog
        duplicateAuctionModal={duplicateAuctionModal}
        setDuplicateAuctionModal={setDuplicateAuctionModal}
        auctionData={auctionData}
        setResendApi={setResendApi}
        fromTable={true}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(AuctionTable));
