import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import { InputAdornment, TextField } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import TablePaginationBox from "../../../components/TablePaginationBox";
import Search from "@material-ui/icons/Search";
import Moment from "moment";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import "../styles/details-suplliers.css";
import useWindowDimensions from "../../../components/useWindowDimensions";
import { Api } from "../../../api/Api";
import { COMAPNY_API } from "../../../constants/ApiUrl";

export default function AddFromLibraryPopup(props) {
  const {
    setOpenPopupLib,
    docsList,
    setSelectedDocsLibrary,
    setListOfDocsAndLink,
    listOfDocsAndLink,
    tempDocsLibrary,
    setTempDocsLibrary,
    setDocsList,
  } = props;

  const { xsScreen } = useWindowDimensions();
  const [checked, setChecked] = useState([]);

  const [checkedAll, setCheckedAll] = useState(false);

  const checkallBoxes = (e) => {
    if (!e.target.value) {
      setCheckedAll(false);
      const checked = [];
      const docLi = [...checked];
      setChecked(docLi);
    } else {
      setCheckedAll(true);
      let neItems = [];
      docsList.filter((i) => {
        checked.push(i._id);
      });
      const docLi = [...checked];
      setChecked(docLi);
    }
  };

  const columns = [
    {
      label: "Template Document Name",
    },
    {
      label: "Date Added",
    },
    {
      label: (
        <div className="checkbox checkbox-primary header">
          <Controls.Checkbox
            name="checkall"
            color="primary"
            value={checkedAll}
            checked={checkedAll}
            onChange={checkallBoxes}
          />
        </div>
      ),
    },
  ];

  const checkBoxHandle = (e) => {
    const { name, value } = e.target;
    if (value) {
      const index = checked.findIndex((x) => x === name);
      if (!checked || index == -1) {
        checked.push(name);
        const neItems = [...checked];
        setChecked(neItems);
      } else {
        setChecked(checked.filter((item) => item !== name));
      }
    } else {
      const index = checked.findIndex((x) => x === name);
      if (index > -1) {
        checked.splice(index, 1);
        const neItems = [...checked];
        setChecked(neItems);
      }
    }
  };

  const attachSelectedDocs = () => {
    if (checked && checked.length > 0) {
      setSelectedDocsLibrary(checked);
      docsList.map((docs, ind) => {
        if (checked.includes(docs._id)) {
          listOfDocsAndLink.push(docs);
          tempDocsLibrary.push(docs);
        }
      });

      const neItems = [...listOfDocsAndLink];
      const neItemstmp = [...tempDocsLibrary];
      setListOfDocsAndLink(neItems);
      setTempDocsLibrary(neItemstmp);
    }
    setOpenPopupLib(false);
  };

  const requestSearch = async (searchedVal) => {
    const textVal = searchedVal.target.value;
    if (textVal) {
      const filteredRows = docsList.filter((row) => {
        return row?.document?.fileName
          .toLowerCase()
          ?.includes(textVal.toLowerCase());
      });
      setDocsList(filteredRows);
    } else {
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      if (companyId) {
        const response = await Api(
          `${COMAPNY_API}/${companyId}/library`,
          {},
          "get"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            let allDocs = response.data.library;
            setDocsList(allDocs);
          } else {
            setDocsList([]);
          }
        } else {
          setDocsList([]);
        }

        // await axiosInstance
        //   .get(`api/buyer/company/${companyId}/library`)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       let allDocs = response.data.library;
        //       setDocsList(allDocs);
        //     } else {
        //       setDocsList([]);
        //     }
        //   })
        //   .catch((err) => {
        //     setDocsList([]);
        //   });
      }
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <Grid container>
        <Grid
          item
          md={4}
          sm={7}
          xs={12}
          style={{ textAlign: xsScreen ? "center" : "start" }}
        >
          <TextField
            onChange={requestSearch}
            placeholder="Search Using Keyword or Tags..."
            className="select-box select-box-primary search-box"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <img src={SearchIcon} style={{ width: '20px' }} /> */}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer
        className="table-container editable-table"
        style={{ position: "relative" }}
        component={Paper}
      >
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "700" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {docsList.map((item) => (
              <TableRow key={item._id}>
                <TableCell>
                  <AssignmentOutlinedIcon />
                  <span style={{ color: "#2170FF" }} className="TextAfterImage">
                    {item && item.document ? item?.document?.name : ""}
                  </span>
                </TableCell>
                <TableCell>
                  {Moment(item.createdDate).format("DD-MM-YYYY LT")}
                </TableCell>
                <TableCell>
                  <div className="checkbox checkbox-primary">
                    <Controls.Checkbox
                      name={item?._id}
                      color="primary"
                      value={checked.includes(item?._id) ? item?._id : ""}
                      checked={checked.includes(item?._id) ? item?._id : ""}
                      onChange={checkBoxHandle}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p className="table-record-count-libarary">
        {docsList.length} Documents Found
      </p>

      <Box
        className="BottomModal"
        display="flex"
        p={1}
        bgcolor="background.paper"
      >
        <Grid istem xs={4} className="BottomModal-left"></Grid>
        <Grid item xs={8} className="BottomModal-right">
          <Box p={0}>
            <Controls.Button
              text="Cancel"
              disableElevation
              onClick={() => {
                setOpenPopupLib(false);
              }}
              style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
              color="default"
              className="btn-mui"
            />
          </Box>
          <Box p={0}>
            <Controls.Button
              disableElevation
              text="Attach"
              className="btn-mui"
              onClick={() => attachSelectedDocs()}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
}
