import React, { useEffect, useState } from "react";
import { Paper, Box, Grid, TextField, InputAdornment } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Search from "@material-ui/icons/Search";
import SuppliersTable from "./suppliersTable";
import CountryName from "../../assets/ConstantOptionArray/CountryName.json";
import { store } from "../../store/store";
import SupplierDetail from "./supplierDetails";
import Notification from "../../components/Notification";
import SupplierFilter from "./filter/supplierFilter";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import "./styles/create-popup.css";
import { COMAPNY_API, GET_SUPPLIER_CATEGORY_API } from "../../constants/ApiUrl";
import { Api } from "../../api/Api";
import { downloadDocumentFile } from "../../utility/utils";
import InviteSupplierModal from "./components/InviteSupplierModal";

const SuppliersList = (props) => {
  const { setFullLoder } = props;

  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [dropDownVals, setDropDownVals] = React.useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [isEditRecord, setIsEditRecord] = useState(false);
  const [editUserId, setEditUserId] = useState(undefined);
  const [viewDetailsId, setViewDetailsId] = useState({});
  const [supplierDetailView, setSupplierDetailView] = useState(false);
  const [suppliersList, setSuppliersList] = useState([]);
  const [dropdownSupplierCategory, setDropdownSupplierCategory] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const getAllSuppliers = async (searchText) => {
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;

    if (companyId) {
      setFullLoder(true);
      const response = await Api(
        `${COMAPNY_API}/${companyId}/suppliers${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let suppliersList = response.data.suppliers;
          setSuppliersList(suppliersList);
        } else {
          setSuppliersList([]);
        }
        setFullLoder(true);
        setTimeout(() => {
          setFullLoder(false);
        }, 600);
      } else {
        setSuppliersList([]);
      }
    }
  };

  const getAllSuppCategory = async () => {
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_SUPPLIER_CATEGORY_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let suppliersList = response.data.supplierCategories;
          setDropdownSupplierCategory(suppliersList);
        }
      } else {
      }
    }
  };

  const getSearchString = (e) => {
    const searchText = e.target.value;
    getAllSuppliers(searchText);
  };

  const ExportToExcel = async () => {
    setFullLoder(true);

    let supplierFilter = JSON.parse(localStorage.getItem("supplierFilter"));
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    let string = "?exportToExcel=1";
    const values = supplierFilter;

    if (values) {
      for (let [key, value] of Object.entries(values)) {
        string += `&${key}=${value}`;
      }
    }
    const response = await Api(
      `${COMAPNY_API}/${companyId}/suppliers${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        if (!response.data.data) {
          setFullLoder(false);
          return;
        }
        downloadDocumentFile(response?.data?.data?.resourceUrl, "Suppliers");
      }
    } else {
      setFullLoder(false);
    }
  };

  useEffect(() => {
    getAllSuppliers();
    getAllSuppCategory();
  }, []);

  return (
    <>
      {!supplierDetailView ? (
        <div id="supplier-table-list-buyer-aspect">
          <Paper elevation={0} square className="bck-transparent">
            <Grid container className="filter-table-header">
              <Grid item md={4} sm={5} xs={12}>
                <div className="title"> Suppliers</div>
              </Grid>
              <Grid item md={8} sm={7} xs={12} className="buttons-box">
                <Controls.Button
                  variant="contained"
                  className={
                    "btn btn-white btn-mui" +
                    (showFilterCategories ? " active" : "")
                  }
                  text="Filter"
                  onClick={toggleFilterCategory}
                  startIcon={
                    <FilterListSharpIcon style={{ color: "#2170FF" }} />
                  }
                />
                <Controls.Button
                  text="Export"
                  className="btn btn-success btn-mui"
                  startIcon={<SystemUpdateAltIcon />}
                  onClick={() => {
                    ExportToExcel();
                  }}
                />
                <Controls.Button
                  text="Add Supplier"
                  className="btn-mui"
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setOpenPopup(true);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className="filter-box-container">
              {showFilterCategories && (
                <Paper elevation={1} className="filter-box">
                  <SupplierFilter
                    setSuppliersList={setSuppliersList}
                    dropdownSupplierCategory={dropdownSupplierCategory}
                    countries={CountryName}
                    showFilterBox={setShowFilterCategories}
                  />
                </Paper>
              )}
            </Grid>
          </Paper>
          <Paper elevation={0} square>
            <Box sx={{ padding: "24px" }}>
              <Grid container>
                <Grid item md={4}>
                  <TextField
                    onChange={getSearchString}
                    placeholder="Search Using Keyword or Tags..."
                    className="select-box select-box-primary search-box"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={8} style={{ textAlign: "end" }}>
                  <Select
                    value={rowsPerPage}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="items-count-dropdown"
                  >
                    {/* <MenuItem value="">
                      <em>Show 10 entries</em>
                    </MenuItem> */}
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <SuppliersTable
                setIsEditRecord={setIsEditRecord}
                setOpenPopup={setOpenPopup}
                setEditUserId={setEditUserId}
                suppliersList={suppliersList}
                setSupplierDetailView={setSupplierDetailView}
                setViewDetailsId={setViewDetailsId}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                getAllSuppliers={getAllSuppliers}
                setSuppliersList={setSuppliersList}
              />
            </Box>
          </Paper>
        </div>
      ) : (
        <SupplierDetail
          setViewDetailsId={setViewDetailsId}
          setSupplierDetailView={setSupplierDetailView}
          viewDetailsId={viewDetailsId}
        />
      )}

      <InviteSupplierModal
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        getAllSuppliers={getAllSuppliers}
        isEditRecord={isEditRecord}
        setFullLoder={setFullLoder}
        setIsEditRecord={setIsEditRecord}
      />

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default SuppliersList;
