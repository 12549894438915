import React, { useEffect, useState } from "react";
import Controls from "../../../components/controls/Controls";
import Search from "@material-ui/icons/Search";
import {
  Paper,
  Box,
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useForm, Form } from "../../../components/useForm";
import Modal from "@mui/material/Modal";
import { store } from "../../../store/store";
import Notification from "../../../components/Notification";
import TableHeadOptionList from "../../../components/TableHeadOptionList";
import useWindowDimensions from "../../../components/useWindowDimensions";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import AddLibrary from "../../Auction/components/tabs/AddDocsLibrary";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import axios from "axios";
import useDownloadFile from "../../../components/useDownloadFile";
import Moment from "moment";
import uploadIcon from "../../../assets/icons/Upload@2x.png";
import attachIcon from "../../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../../assets/icons/CloseX.png";
import {
  ACCEPT_PROJECT_ATTACHMENTS,
  PROJECT_ATTACHMENTS_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { Api } from "../../../api/Api";
import {
  COMAPNY_API,
  DELETE_COMPANY_ATTACHMENT_API,
  POST_COMPANY_ATTACHMENT_API,
} from "../../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";

var columns = [
  {
    label: "Attachment",
    //   align: 'center'
    // minWidth: 170
  },
  {
    label: "Name",
    // minWidth: 100
  },
  {
    label: "Notes",
    // align: 'right'
  },
];

const initialValDoc = {
  title: "",
  note: "",
  link: "",
};

const Attachment = (props) => {
  const { selectedProject, currentUser } = props;
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const [attachmentList, setAttachmentList] = useState([]);
  /// Please be carefull !!!
  /// must start with (1) for pagination issue ....
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [linkModelOpen, setLinkModelOpen] = React.useState(false);
  const [modelTitle, setModelTitle] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editAttachmentId, setEditAttachmentId] = React.useState(null);
  const handleClose = () => setOpen(false);
  const handleCloseLink = () => setLinkModelOpen(false);
  const [attachType, setAttachType] = useState(null);
  const [alertDailog, setAlertDailog] = useState(false);
  const [deleteAttachmentData, setDeleteAttachmentData] = useState({});
  const [docsList, setDocsList] = useState([]);
  const [openPopupLib, setOpenPopupLib] = useState(false);
  const [downloadAttach, setDownloadAttach] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [error, setError] = useState("");
  const [documentFile, setDocumentFile] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const ALLOWED_TYPES = ACCEPT_PROJECT_ATTACHMENTS;

  // minior chnage to get page without increment by one
  const numOfPages = Math.ceil(attachmentList.length / rowsPerPage);

  const getDownloadAttachmentFileName = () => {
    let name =
      Moment().unix() +
      "_" +
      (downloadAttach && downloadAttach.attachment
        ? downloadAttach?.attachment?.fileName.split(
            process.env.REACT_APP_BACKEND_API_URL + "/uploads/"
          )
        : downloadAttach && downloadAttach.link
        ? downloadAttach.link
        : downloadAttach?.doc?.document?.name);

    return name;
  };

  const getDownloadAttachmentFile = () => {
    let url = null;
    if (downloadAttach && downloadAttach.attachment) {
      url = downloadAttach.attachment.fileName;
    } else if (downloadAttach && downloadAttach.link) {
      url = downloadAttach.link;
    } else if (downloadAttach && downloadAttach.doc) {
      url = downloadAttach.doc?.document?.fileName;
    }

    return axios.get(url, {
      responseType: "blob",
      /* 
        headers: {
          Authorization: "Bearer <token>",
        },
         */
    });
  };

  const { download, downloadRef, downloadUrl, downloadName } = useDownloadFile({
    apiDefinition: getDownloadAttachmentFile,
    preDownloading: null,
    postDownloading: null,
    onError: null,
    getFileName: getDownloadAttachmentFileName,
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";
    if ("note" in fieldValues)
      temp.note = fieldValues.note ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValDoc, true, validate);

  const addAttachment = (attachType) => {
    setDocumentFile({});
    setAttachType(attachType);
    if (attachType === "document") {
      setOpen(true);
      setAnchorEl(null);
      resetForm();
      setModelTitle("Attach Document");
      setValues({
        title: "",
        note: "",
        link: "",
      });
    } else if (attachType === "link") {
      setLinkModelOpen(true);
      setAnchorEl(null);
      setModelTitle("Add Link");
      resetForm();
    } else {
      addDocumentFromLib();
      setAnchorEl(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const projectId = selectedProject._id;
      const { auth } = store.getState();
      const UserId = auth?.auth?.user?._id;
      let formData = new FormData();
      if (!isEditMode) {
        if (attachType === "document") {
          if (documentFile && documentFile.type) {
            formData.append("document", documentFile);
          } else {
            toast.error(ValidationMessage.selectDocument, {
              transition: Slide,
              autoClose: TOAST_AUTO_CLOSE,
            });
          }
        } else if (attachType === "link") {
          formData.append("link", values.link);
        }

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        formData.append("name", values.title);
        formData.append("note", values.note);
        // formData.append("doc", attachType);
        formData.append("projectId", projectId);
        formData.append("userId", UserId);

        const response = await Api(
          `${COMAPNY_API}/${projectId}/addAttachment`,
          formData,
          "postMultipart"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            //  let addedProjects = response.data;
            setOpen(false);
            setLinkModelOpen(false);
            getAllAttachment();
            resetForm();
          }
        }

        // await axiosInstance
        //   .post(
        //     `api/buyer/company/${projectId}/addAttachment`,
        //     formData,
        //     config
        //   )
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       //  let addedProjects = response.data;
        //       setOpen(false);
        //       setLinkModelOpen(false);
        //       getAllAttachment();
        //       resetForm();
        //       setNotify({
        //         isOpen: true,
        //         message: "Submitted Successfully",
        //         type: "success",
        //       });
        //     } else {
        //       setNotify({
        //         isOpen: true,
        //         message: "Submitted Successfully",
        //         type: "success",
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     setNotify({
        //       isOpen: true,
        //       message: err?.response?.data?.responseDescription,
        //       type: "error",
        //     });
        //   });
      } else {
        if (attachType === "document") {
          if (documentFile && documentFile.name) {
            formData.append("document", documentFile);
          } else {
            if (documentFile && !documentFile.fileName) {
              toast.error(ValidationMessage.selectDocument, {
                transition: Slide,
                autoClose: TOAST_AUTO_CLOSE,
              });
              return;
            }
          }
        } else if (attachType === "link") {
          formData.append("link", values.link);
        } else {
        }
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        formData.append("name", values.title);
        formData.append("note", values.note);
        // formData.append("doc", attachType);
        formData.append("projectId", projectId);
        formData.append("userId", UserId);
        if (editAttachmentId) {
          const response = await Api(
            `${POST_COMPANY_ATTACHMENT_API}/${editAttachmentId}`,
            formData,
            "postMultipart"
          );
          if (response.status === 200) {
            if (response?.data?.responseCode === "00") {
              //  let addedProjects = response.data;
              setOpen(false);
              setLinkModelOpen(false);
              setIsEditMode(false);
              getAllAttachment();
              resetForm();
            }
          }

          // await axiosInstance
          //   .post(
          //     `api/buyer/company/editAttachment/${editAttachmentId}`,
          //     formData,
          //     config
          //   )
          //   .then((response) => {
          //     if (response?.data?.responseCode === "00") {
          //       //  let addedProjects = response.data;
          //       setOpen(false);
          //       setLinkModelOpen(false);
          //       setIsEditMode(false);
          //       getAllAttachment();
          //       resetForm();
          //       setNotify({
          //         isOpen: true,
          //         message: "Submitted Successfully",
          //         type: "success",
          //       });
          //     } else {
          //       setNotify({
          //         isOpen: true,
          //         message: response?.data?.responseDescription,
          //         type: "error",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //   });
        }
      }
    }
  };

  async function handleImageChange(e) {
    const selected = e.target.files[0];

    if (selected && !ACCEPT_PROJECT_ATTACHMENTS.includes(selected.type)) {
      setError(ValidationMessage.itemFileType);
      return;
    }
    if (
      selected &&
      Math.round(selected.size / 1024) > PROJECT_ATTACHMENTS_SIZE
    ) {
      setError(ValidationMessage.projectAttachmentFileSize);
      return;
    }

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setDocumentFile(selected);
    }
  }

  const getAllAttachment = async (searchKey = "") => {
    const projectId = selectedProject._id;
    if (projectId) {
      const response = await Api(
        `${COMAPNY_API}/${projectId}/attachments?search=${searchKey}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let AllDocs = response?.data?.attachmentData?.attachmentsList;
          let linkArray = response?.data?.attachmentData?.linkList;
          let libraryDocs = response?.data?.attachmentData?.docs;
          const combine = [...AllDocs, ...linkArray, ...libraryDocs];
          setAttachmentList(combine);
        }
      } else {
        setAttachmentList([]);
      }

      // await axiosInstance
      //   .get(`api/buyer/company/${projectId}/attachments?search=${searchKey}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let AllDocs = response?.data?.attachmentData?.attachmentsList;
      //       let linkArray = response?.data?.attachmentData?.linkList;
      //       let libraryDocs = response?.data?.attachmentData?.docs;
      //       const combine = [...AllDocs, ...linkArray, ...libraryDocs];
      //       setAttachmentList(combine);
      //     }
      //   })
      //   .catch((err) => {
      //     setAttachmentList([]);
      //   });
    }
  };

  const editAttchament = (attachment) => {
    if (attachment && attachment.attachment) {
      setValues({
        title: attachment.name,
        note: attachment.note,
      });
      setIsEditMode(true);
      setEditAttachmentId(attachment._id);
      setOpen(true);
      setDocumentFile(attachment.attachment);
      setAttachType("document");
    } else if (attachment && attachment.link) {
      setValues({
        title: attachment.name,
        note: attachment.note,
        link: attachment.link,
      });
      setIsEditMode(true);
      setEditAttachmentId(attachment._id);
      setLinkModelOpen(true);
      setAttachType("link");
    } else {
      toast.error(ValidationMessage.updateDocument, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const closeDocPopup = () => {
    setOpen();
    resetForm();
    setDocumentFile({});
    setError("");
  };

  function bytesToSize(bytes) {
    //            <10^3 , <10^6 , <10^9 , <10^12
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Bytes";
    // var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    let numRank = Math.floor(Math.log10(bytes));

    // return Math.round(bytes / Math.pow(10, numRank), 2) + " " + sizes[i];
    return (
      (bytes / Math.pow(10, numRank)).toFixed(2) +
      " " +
      sizes[Math.floor(numRank / 3)]
    );
  }

  const deleteDocument = async (attachment) => {
    if (attachment) {
      setAlertDailog(true);
      setDeleteAttachmentData(attachment);
    }
  };

  const confirmDeleteAttachment = async () => {
    if (deleteAttachmentData) {
      const response = await Api(
        `${DELETE_COMPANY_ATTACHMENT_API}/${deleteAttachmentData._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allAttachments = response.data.attachments;
          getAllAttachment();
          setAlertDailog(false);
          setDeleteAttachmentData({});
        }
      }

      // await axiosInstance
      //   .delete(
      //     `api/buyer/company/deleteAttachment/${deleteAttachmentData._id}`,
      //     { projectId: selectedProject._id }
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let allAttachments = response.data.attachments;
      //       getAllAttachment();
      //       setAlertDailog(false);
      //       setDeleteAttachmentData({});
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const selectedDocRemove = () => {
    setDocumentFile({});
  };

  const addDocumentFromLib = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${COMAPNY_API}/${companyId}/library`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allDocs = response.data.library;
          setDocsList(allDocs);
          setOpenPopupLib(true);
          setAnchorEl(null);
        } else {
          setDocsList([]);
        }
      } else {
        setDocsList([]);
      }

      // await axiosInstance
      //   .get(`api/buyer/company/${companyId}/library`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let allDocs = response.data.library;
      //       setDocsList(allDocs);
      //       setOpenPopupLib(true);
      //       setAnchorEl(null);
      //     } else {
      //       setDocsList([]);
      //       setNotify({
      //         isOpen: true,
      //         message: "Please add document on settings.",
      //         type: "error",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setDocsList([]);
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const isEditor = () => {
    if (selectedProject && currentUser) {
      if (Array.isArray(selectedProject.editorIds)) {
        return (
          selectedProject.editorIds.findIndex(
            (Id) => String(Id) === String(currentUser._id)
          ) >= 0
        );
      }
    }
    return false;
  };

  const isViewer = () => {
    if (selectedProject && currentUser) {
      if (Array.isArray(selectedProject.viewerIds)) {
        return (
          selectedProject.viewerIds.findIndex(
            (Id) => String(Id) === String(currentUser._id)
          ) >= 0
        );
      }
    }
    return false;
  };

  const isOwner = () => {
    if (selectedProject && currentUser) {
      return String(selectedProject.createdBy) === String(currentUser._id);
    }
    return false;
  };

  const requestSearch = (e) => {
    const searchText = e.target.value;
    getAllAttachment(searchText);
  };

  useEffect(() => {
    setError("");
    if (isEditor() && columns.length < 4) {
      columns.push({
        label: "",
      });
    }
    getAllAttachment();
  }, []);

  useEffect(() => {
    if (downloadAttach) {
      download();
      setDownloadAttach(null);
    }
  }, [downloadAttach]);

  return (
    <>
      <Paper elevation={0} square>
        <Grid container>
          <Grid
            item
            sm={6}
            xs={12}
            style={{ textAlign: xsScreen ? "center" : "start" }}
          >
            <TextField
              onChange={requestSearch}
              placeholder="Search Using Keyword or Tags..."
              className="select-box select-box-primary search-box"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {isEditor() ? (
            <Grid
              item
              sm={6}
              xs={12}
              style={{ textAlign: xsScreen ? "center" : "end" }}
            >
              <Button
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={(event) => setAnchorEl(event.currentTarget)}
                className="btn-mui-table-option"
                endIcon={<KeyboardArrowDownIcon />}
              >
                <AddOutlinedIcon style={{ fontSize: "20px" }} />
                <span>Add Attachment</span>
              </Button>
              <TableHeadOptionList
                id="buyer-side-project-attachment-dropdown-list"
                MenuListProps={{
                  "aria-label": "buyer-side-project-attachment-dropdown-list",
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => addAttachment(null)}
              >
                <MenuItem
                  onClick={() => addAttachment("document")}
                  disableRipple
                >
                  Attach Document
                </MenuItem>
                <MenuItem
                  onClick={() => addAttachment("library")}
                  disableRipple
                >
                  Add from Library
                </MenuItem>
                <MenuItem onClick={() => addAttachment("link")} disableRipple>
                  URL Link
                </MenuItem>
              </TableHeadOptionList>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        {/* generic download link  */}
        <a
          href={downloadUrl}
          download={downloadName}
          className="hidden"
          ref={downloadRef}
        />
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* minior change - start offestting using (page-1) 
               min value of page is one */}
              {(rowsPerPage > 0
                ? attachmentList.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : attachmentList
              ).map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    <div>
                      {item && (item.attachment || item.doc) ? (
                        <FilePresentOutlinedIcon
                          style={{ color: "#A19B9D", verticalAlign: "middle" }}
                        />
                      ) : (
                        <LinkOutlinedIcon
                          style={{ color: "#A19B9D", verticalAlign: "middle" }}
                        />
                      )}
                      <span
                        style={{
                          color: "#2170FF",
                          marginLeft: "1em",
                          verticalAlign: "bottom",
                        }}
                      >
                        {item && item.attachment
                          ? item?.attachment?.displayName || "---"
                          : item && item.link
                          ? item.link || "---"
                          : item?.doc?.document?.name || "---"}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.name || item?.doc?.notes || "-"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <p title={item?.note || item?.doc?.title || "-"}>
                      {item?.note || item?.doc?.title || "-"}
                    </p>
                  </TableCell>
                  {isEditor() ? (
                    <TableCell>
                      <Box>
                        <PopupState
                          variant="popover"
                          popupId="option-list-menu"
                        >
                          {(popupState) => (
                            <div>
                              <Button
                                className="action-btn"
                                variant="contained"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </Button>
                              <TableOptionList {...bindMenu(popupState)}>
                                <MenuItem
                                  className="bold-line-600"
                                  onClick={() => setDownloadAttach(item)}
                                >
                                  Download
                                </MenuItem>
                                <MenuItem
                                  className="bold-line-600"
                                  onClick={() => editAttchament(item)}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  className="bold-line-600"
                                  style={{ color: "red" }}
                                  onClick={() => deleteDocument(item)}
                                >
                                  Delete
                                </MenuItem>
                              </TableOptionList>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              ))}
              {attachmentList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some attachemnt
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={attachmentList.length + " Attachments Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
      </Paper>

      {/* attached Document  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <div className="title">{modelTitle}</div>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {error && error.length > 0 && (
                  <p
                    className="errMsg"
                    style={{
                      color: "red",
                      fontSize: "11px",
                      margin: "3px 0",
                      paddingLeft: "10px",
                    }}
                  >
                    {error}
                    {/* Selected file foramt is not supported <b>OR</b> size more
                    than <i>5MB</i> <br />
                    Please re choose another one */}
                  </p>
                )}
                <div className="image-uploader-container">
                  {documentFile && (
                    <>
                      <div className="uploader-header">
                        <img src={uploadIcon} className="upload-icon" alt="" />
                        <p className="upload-title">Drag or drop files here</p>

                        <p className="upload-description">
                          Max. file size is 5mb <br />
                          Files must be .png, .jpeg, .jpg, .pdf, .doc, .docx,
                          .xls, .xlsx, .gz, .zip, .ppt, .pptx, .odp
                        </p>

                        <label
                          // htmlFor="outlined-button-file fileUpload"
                          htmlFor="fileUpload"
                        >
                          <Controls.Button
                            component="span"
                            text="Choose File"
                            disableElevation
                            className="mui-btn upload-btn"
                          />
                        </label>
                      </div>
                      <input
                        type="file"
                        id="fileUpload"
                        onChange={handleImageChange}
                        value={values.image}
                        accept={ACCEPT_PROJECT_ATTACHMENTS}
                      />
                    </>
                  )}
                </div>
              </Grid>
              {/* TODO : need to be implemented (binded with uploaded file ) */}
              {documentFile && (documentFile.name || documentFile.fileName) ? (
                <Grid item xs={12} style={{ alignItems: "flex-end" }}>
                  <Box component="div" className="uploaded-files">
                    <Grid container>
                      <Grid item xs={2} style={{ textAlign: "center" }}>
                        <img src={attachIcon} alt="" className="icon" />
                      </Grid>
                      <Grid item xs={9} className="details">
                        <Grid container>
                          <Grid item xs={12}>
                            <box className="name">
                              {documentFile?.name || documentFile?.displayName}
                            </box>
                          </Grid>
                          <Grid item xs={12}>
                            <box className="size">
                              {bytesToSize(documentFile?.size || 20000)}
                            </box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          textAlign: "end",
                          margin: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => selectedDocRemove()}
                      >
                        <img src={closeIcon} alt="" />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12}>
                <InputLabel className="mui-label">Title</InputLabel>
                <Controls.Input
                  className="mui-input"
                  fullWidth
                  name="title"
                  value={values.title}
                  onChange={handleInputChange}
                  error={errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="mui-label">Notes</InputLabel>
                <Controls.Input
                  fullWidth
                  name="note"
                  className="mui-input fix-h-8em"
                  value={values.note}
                  onChange={handleInputChange}
                  error={errors.note}
                />
              </Grid>
            </Grid>

            <Box display="flex" mt={4} bgcolor="background.paper">
              <Grid istem xs={4} className="BottomModal-left">
                <Box p={0} flexGrow={1}>
                  <Controls.Button
                    text="Cancel"
                    disableElevation
                    onClick={() => closeDocPopup()}
                    style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                    color="default"
                    className="btn-mui"
                  />
                </Box>
              </Grid>
              <Grid item xs={8} className="BottomModal-right">
                <Box p={0}>
                  <Controls.Button
                    type="submit"
                    disableElevation
                    text="Attach"
                    className="btn-mui"
                    style={{ minWidth: "197px" }}
                  />
                </Box>
              </Grid>
            </Box>
          </Form>
        </Box>
      </Modal>
      {/* attached Document  */}

      {/* add link modal  */}
      <Modal
        open={linkModelOpen}
        onClose={handleClose}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <div className="title">Add Link</div>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel className="mui-label">Link</InputLabel>
                <Controls.Input
                  className="mui-input"
                  fullWidth
                  name="link"
                  value={values.link}
                  onChange={handleInputChange}
                  error={errors.link}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="mui-label">Name</InputLabel>
                <Controls.Input
                  className="mui-input"
                  fullWidth
                  name="title"
                  value={values.title}
                  onChange={handleInputChange}
                  error={errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="mui-label">Notes</InputLabel>
                <Controls.Input
                  fullWidth
                  name="note"
                  value={values.note}
                  onChange={handleInputChange}
                  error={errors.note}
                  className="mui-input fix-h-8em"
                />
              </Grid>
            </Grid>
            <Box display="flex" mt={4} bgcolor="background.paper">
              <Grid istem xs={4} className="BottomModal-left">
                <Box p={0} flexGrow={1}>
                  <Controls.Button
                    onClick={handleCloseLink}
                    text="Cancel"
                    disableElevation
                    style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                    color="default"
                    className="btn-mui"
                  />
                </Box>
              </Grid>
              <Grid item xs={8} className="BottomModal-right">
                <Box p={0}>
                  <Controls.Button
                    type="submit"
                    disableElevation
                    text="Save"
                    className="btn-mui"
                    style={{ minWidth: "197px" }}
                  />
                </Box>
              </Grid>
            </Box>
          </Form>
        </Box>
      </Modal>
      {/* add link modal  */}

      <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete attachment?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setAlertDailog(false)}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={() => confirmDeleteAttachment()}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Notification notify={notify} setNotify={setNotify} />

      {/* add from library  */}

      <Modal
        open={openPopupLib}
        aria-label="modal-add-document-from-library"
        aria-describedby="modal-modal-description"
        // className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Library</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopupLib(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          {/* <div className="title"> Library </div> */}
          <AddLibrary
            setOpenPopupLib={setOpenPopupLib}
            docsList={docsList}
            setDocsList={setDocsList}
            selectedProject={selectedProject}
            setNotify={setNotify}
            getAllAttachment={getAllAttachment}
            addForm="project"
          />
        </Box>
      </Modal>
      {/* add from library  */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(Attachment));
