import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { InputLabel, Modal } from "@mui/material";
import axiosInstance from "../../../services/AxiosInstance";
import Controls from "../../../components/controls/Controls";
import { useForm, Form } from "../../../components/useForm";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentLiveAuction } from "../../../store/selectors/auctionSelector";
import { Api } from "../../../api/Api";
import {
  POST_AUCTION_ADD_LINK_API,
  POST_AUCTION_EDIT_LINK_API,
} from "../../../constants/ApiUrl";
const initialFValues = {
  link: "",
  name: "",
  notes: "",
};
const LinkAttachmentPopup = (props) => {
  const {
    setNotify,
    linkAttachmentPopup,
    setLinkAttachmentPopup,
    setAttachments,
    editLinkId,
    editLinkData,
    isEditMode,
    setIsEditMode,
    getDocumets,
    currentLiveAuction,
    setEditLinkData,
  } = props;

  const clickClosePopup = () => {
    setLinkAttachmentPopup(false);
    resetForm();
    setEditLinkData({});
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("link" in fieldValues)
      temp.link = fieldValues.link ? "" : "This field is required.";
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("notes" in fieldValues)
      temp.notes = fieldValues.notes ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (
        currentLiveAuction !== undefined &&
        currentLiveAuction._id !== undefined
      ) {
        const payload = {
          link: values.link,
          title: values.name,
          notes: values.notes,
        };
        if (!isEditMode) {
          const response = await Api(
            `${POST_AUCTION_ADD_LINK_API}/${currentLiveAuction._id}`,
            payload,
            "post"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              resetForm();
              setLinkAttachmentPopup(false);
              getDocumets();
            }
          }

          // axiosInstance
          //   .post(
          //     `api/buyer/company/auctions/addAttachmentLink/${currentLiveAuction._id}`,
          //     payload
          //   )
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       resetForm();
          //       setLinkAttachmentPopup(false);
          //       getDocumets();
          //       setNotify({
          //         isOpen: true,
          //         message: "Link created successfully.",
          //         type: "success",
          //       });
          //     } else {
          //       setNotify({
          //         isOpen: true,
          //         message: response?.data?.responseDescription,
          //         type: "error",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //   });
        } else {
          payload.type = "link";
          const response = await Api(
            `${POST_AUCTION_EDIT_LINK_API}/${currentLiveAuction._id}/${editLinkId}`,
            payload,
            "post"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setLinkAttachmentPopup(false);
              getDocumets();
              setIsEditMode(false);
              setEditLinkData({});
            }
          }

          // axiosInstance
          //   .post(
          //     `api/buyer/company/auctions/editAttachment/${currentLiveAuction._id}/${editLinkId}`,
          //     payload
          //   )
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setLinkAttachmentPopup(false);
          //       getDocumets();
          //       setIsEditMode(false);
          //       setEditLinkData({});
          //       setNotify({
          //         isOpen: true,
          //         message: "Link updated successfully.",
          //         type: "success",
          //       });
          //     } else {
          //       setNotify({
          //         isOpen: true,
          //         message: response?.data?.responseDescription,
          //         type: "error",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //   });
        }
      }
    }
  };

  useEffect(() => {
    if (editLinkData && editLinkData.link) {
      editLinkData.name = editLinkData.title;
      setValues(editLinkData);
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel className="mui-label">Link</InputLabel>
          <Controls.Input
            className="mui-input"
            fullWidth
            name="link"
            onChange={handleInputChange}
            value={values.link}
            error={errors.link}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className="mui-label">Name</InputLabel>
          <Controls.Input
            className="mui-input"
            fullWidth
            name="name"
            onChange={handleInputChange}
            value={values.name}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className="mui-label">Notes</InputLabel>
          <Controls.Input
            fullWidth
            name="notes"
            onChange={handleInputChange}
            className="mui-input fix-h-8em"
            value={values.notes}
            error={errors.notes}
          />
        </Grid>
      </Grid>
      <Box display="flex" mt={4} bgcolor="background.paper">
        <Grid istem xs={4} className="BottomModal-left">
          <Box p={0} flexGrow={1}>
            <Controls.Button
              text="Cancel"
              disableElevation
              onClick={() => {
                clickClosePopup();
              }}
              style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
              color="default"
              className="btn-mui"
            />
          </Box>
        </Grid>
        <Grid item xs={8} className="BottomModal-right">
          <Box p={0}>
            <Controls.Button
              type="submit"
              disableElevation
              text="Attach"
              className="btn-mui"
              style={{ minWidth: "197px" }}
            />
          </Box>
        </Grid>
      </Box>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(LinkAttachmentPopup));
