import { connect, useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import "../../../Auction/styles/details-form.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckIcon from "@mui/icons-material/Check";
import TimeClockImage from "../../../../assets/Time-Remaining@2x.png";
import * as employeeService from "../../../../services/employeeService";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  AUCTION_PUBLISHED,
  AUCTION_DRAFT,
  AUCTION_RUNNING,
  TOAST_AUTO_CLOSE,
  AUCTION_COMPLETED,
} from "../../../../constants/AppConstants";
import { Api } from "../../../../api/Api";
import {
  COMAPNY_API,
  GET_COST_BUDGET_API,
  GET_COST_CENTER_API,
  RFX_DETAILS_API,
} from "../../../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import SetupLiveMonitoringBL from "../../../SupplierAspect/Auction/BL/SetupLiveMonitoringBL";
import { thousandSepratorHandler } from "../../../../utility/utils";
import { store } from "../../../../store/store";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { Form, useForm } from "../../../../components/useForm";
import Controls from "../../../../components/controls/Controls";
import NumericSeparatorInput from "../../../../components/NumericSeparatorInput";
import { getServerLiveTimestamp } from "../../../../store/selectors/ServerClockSelector";
import { currentSocketStatus } from "../../../../store/selectors/SocketSelector";
import { currentUser } from "../../../../store/selectors/AuthSelectors";
import RfxInfoOverviewTable from "./RfxInfoOverviewTable";
import questionCover from "../../../../assets/Rfx/messageCover.png";
import bidCover from "../../../../assets/Rfx/bidCover.png";

const initialFValues = {
  cost_center: "",
  currency: "",
  budget: "",
  down_period: "",
  show_to_supplier: "",
  reserve_price: "",
  no_of_participants: "",
  min_step: "",
};

const dropdownSupplierProvider = [
  { id: "1", title: "Price for each items" },
  { id: "2", title: "Lumpsum" },
];

function RfxGeneralInfoTab(props) {
  const { setNotify, serverLiveTS, isConnected, isOwner, getLiveRfxDetails } =
    props;
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const bestLiveBid = useSelector((state) => state.liveBid.best);

  const { smScreen } = useWindowDimensions();
  const [uploadDocCheckbox, setUploadDocCheckbox] = useState(false);
  const [sealedBidCheckbox, setSealedBidCheckbox] = useState(false);
  const [dropdownCostCenter, setDropdownCostCenter] = useState([]);
  const [defVals, setDefVals] = useState();
  const [genralBtnState, setGenralBtnState] = useState(false);
  const [bestBid, setBestBid] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState("");
  const [countdown, setCountdown] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });

    // if ("cost_center" in fieldValues)
    //   temp.cost_center = fieldValues.cost_center
    //     ? ""
    //     : "This field is required.";
    if ("currency" in fieldValues)
      temp.currency = fieldValues.currency ? "" : "This field is required.";
    // if ("budget" in fieldValues)
    //   temp.budget = fieldValues.budget ? "" : "This field is required.";

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const editInformationTggle = () => {
    if (
      (rfxDetails && rfxDetails?.status === AUCTION_PUBLISHED) ||
      (rfxDetails && rfxDetails?.status === AUCTION_DRAFT)
    ) {
      setGenralBtnState(!genralBtnState);
    } else {
      setGenralBtnState(false);
      toast.error(ValidationMessage.notUpdateAuction, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (genralBtnState) return;
    if (validate()) {
      if (
        (!genralBtnState &&
          rfxDetails &&
          rfxDetails?.status === AUCTION_PUBLISHED) ||
        (rfxDetails && rfxDetails?.status === AUCTION_DRAFT)
      ) {
        const { auth } = store.getState();
        const companyId = auth?.auth?.user?.company?._id;
        if (rfxDetails !== undefined && rfxDetails._id !== undefined) {
          values.sealed_bid = sealedBidCheckbox;
          values.allow_supplier_document_upload = uploadDocCheckbox;

          if (values?.cost_center.length === 0) values.cost_center = null;

          const response = await Api(
            `${COMAPNY_API}/${companyId}/rfx/${rfxDetails._id}`,
            values,
            "patch"
          );
        }
      }
    }
  };

  const getAllCostCenter = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COST_CENTER_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let costCenterList = response.data.costCenter;
          setDropdownCostCenter(costCenterList);
        }
      }
    }
  };

  useEffect(() => {
    getCurrentRfx();
  }, [rfxDetails]);

  const getCurrentRfx = async () => {
    if (rfxDetails !== undefined && rfxDetails._id !== undefined) {
      const formValue = {
        cost_center: rfxDetails.cost_center,
        currency: rfxDetails.currency,
        budget: rfxDetails.budget,
        show_to_supplier: rfxDetails.show_to_supplier,
        supplier_to_provide: rfxDetails.supplier_to_provide,
      };
      setSealedBidCheckbox(rfxDetails?.sealed_bid);
      setUploadDocCheckbox(rfxDetails?.allow_supplier_document_upload);

      setValues(formValue);
      setCurrentCurrency(rfxDetails?.currency);
      if (rfxDetails !== undefined && rfxDetails._id !== undefined) {
        const response = await Api(
          `${RFX_DETAILS_API}/${rfxDetails._id}`,
          {},
          "get"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            let rfxData = response.data.rfx;
            setDefVals(rfxData);
            setValues({
              cost_center: rfxData.cost_center,
              currency: rfxData.currency,
              budget: rfxData.budget,
              supplier_to_provide: rfxData.supplier_to_provide,
            });
            setSealedBidCheckbox(rfxDetails?.sealed_bid);
            setUploadDocCheckbox(rfxDetails?.allow_supplier_document_upload);

            setCurrentCurrency(rfxData?.currency);

            setStartTime(rfxData?.startDateTS);
            setEndTime(rfxData?.endDateTS);
          }
        }
      }

      getAllCostCenter();
    }
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const currentTime = new Date().getTime();
      if (currentTime >= endTime) {
        clearInterval(countdownInterval);
        return;
      }

      if (
        currentTime >= startTime &&
        currentTime <= endTime &&
        rfxDetails &&
        rfxDetails?.status !== AUCTION_DRAFT
      ) {
        const remainingTime = endTime - currentTime;

        const seconds = Math.floor(remainingTime / 1000) % 60;
        const minutes = Math.floor(remainingTime / 1000 / 60) % 60;
        const hours = Math.floor(remainingTime / 1000 / 60 / 60) % 24;
        const days = Math.floor(remainingTime / 1000 / 60 / 60 / 24);
        const remainingHours = Math.floor(days * 24);
        const totalHours = hours + remainingHours;

        setCountdown({ hours: totalHours, minutes, seconds });
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [startTime, endTime]);
  return (
    <Grid
      container
      spacing={3}
      style={{ flexDirection: smScreen ? "column-reverse" : "row" }}
      className="rfx-general-info"
    >
      <Grid item md={4}>
        <Form
          onSubmit={handleSubmit}
          className="details-form-container mui-card"
        >
          <Box className="title-container">
            <h3 className="mb-3">RFX Details</h3>
            {isOwner() && rfxDetails?.status === AUCTION_DRAFT ? (
              <Controls.Button
                className={
                  (genralBtnState ? "btn-primary" : "btn-primary-lite") +
                  " edit-btn"
                }
                text={genralBtnState ? "save" : "Edit"}
                type="submit"
                onClick={() => editInformationTggle()}
              />
            ) : (
              <></>
            )}
          </Box>
          <Grid
            container
            spacing={2}
            className="body-container filter-box-container"
          >
            <Grid item xs={12} className="select-field mb-3">
              <InputLabel className="mui-label" id="const-center-input">
                Cost Center
              </InputLabel>
              <Controls.Select
                name="cost_center"
                labelId="const-center-input"
                value={values.cost_center}
                fullWidth
                disabled={!genralBtnState}
                options={dropdownCostCenter}
                error={errors.cost_center}
                onChange={(e) => {
                  setValues({
                    ...values,
                    cost_center: e.target.value,
                  });
                  // getBudgetForAuction()
                }}
              />
            </Grid>
            <Grid item xs={12} className="select-field mb-3">
              <InputLabel className="mui-label" id="currency-input">
                Currency
              </InputLabel>
              <Controls.Select
                name="currency"
                labelId="currency-input"
                value={values.currency}
                fullWidth
                disabled={true}
                onChange={handleInputChange}
                options={employeeService.currencyList()}
                error={errors.currency}
              />
            </Grid>
            <Grid item xs={12} className="mb-3">
              <InputLabel className="mui-label" id="budget-input">
                Budget
              </InputLabel>
              <Controls.Input
                name="budget"
                labelId="budget-input"
                fullWidth
                disabled={!genralBtnState}
                value={values.budget}
                onChange={handleInputChange}
                error={errors.budget}
                InputProps={{
                  inputComponent: NumericSeparatorInput,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={`mb-3 ${!genralBtnState ? "item-disabeled" : ""}`}
            >
              <Grid container spacing={1}>
                <Grid item xs={1}>
                  <div
                    className={
                      "checkbox checkbox-primary-outline " +
                      (uploadDocCheckbox ? "checked" : "")
                    }
                    onClick={() => {
                      if (!genralBtnState) return;
                      setUploadDocCheckbox(!uploadDocCheckbox);
                    }}
                  >
                    <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                    <CheckIcon aria-label="check-mark" />
                  </div>
                </Grid>
                <Grid item xs={10} style={{ marginLeft: "5px" }}>
                  <span className="label">
                    Allow Supplier To Upload Documents
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={`mb-3 ${!genralBtnState ? "item-disabeled" : ""}`}
            >
              <Grid container spacing={1}>
                <Grid item xs={1}>
                  <div
                    className={
                      "checkbox checkbox-primary-outline " +
                      (sealedBidCheckbox ? "checked" : "")
                    }
                    onClick={() => {
                      if (!genralBtnState) return;
                      setSealedBidCheckbox(!sealedBidCheckbox);
                    }}
                  >
                    <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                    <CheckIcon aria-label="check-mark" />
                  </div>
                </Grid>
                <Grid item xs={10} style={{ marginLeft: "5px" }}>
                  <span className="label">Sealed Bid</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="select-field">
              <InputLabel className="mui-label" id="currency-input">
                Supplier to provide
              </InputLabel>

              <Controls.Select
                name="supplier_to_provide"
                labelId="supplierToProvide-input"
                value={parseInt(values.supplier_to_provide)}
                fullWidth
                onChange={handleInputChange}
                options={dropdownSupplierProvider}
                error={errors.supplier_to_provide}
                disabled={!genralBtnState}
              />
            </Grid>
          </Grid>
        </Form>
      </Grid>

      <Grid item md={8}>
        <div style={{ flexGrow: 1 }} className="statistics-card-container">
          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <div className="mui-card" id="statistics-box-first-container">
                <Grid
                  container
                  alignItems="center"
                  className="inner-info-first"
                >
                  <Grid item sm={4}>
                    <img
                      src={questionCover}
                      className="icon-rfx-general"
                      alt=""
                    />
                  </Grid>
                  <Grid item sm={8}>
                    <Grid container>
                      <Grid item sm={12}>
                        <p className="text-bold text-gray question-num-text">
                          Number of questions
                        </p>
                      </Grid>
                      <Grid item sm={12}>
                        <h3 className="text-primary text-bold question-num">
                          {rfxDetails?.questionsCounts}
                        </h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <div className="mui-card" id="statistics-box-second-container">
                <Grid container style={{ marginTop: "10%" }}>
                  <Grid item sm={4}>
                    <img src={TimeClockImage} className="icon" alt="" />
                  </Grid>
                  <Grid item sm={6}>
                    <Grid container>
                      <Grid item sm={12}>
                        <p className="text-bold question-num-text">
                          Time Remaining
                        </p>
                      </Grid>
                      <Grid item sm={12}>
                        <h3 className="time-num">
                          {" "}
                          {countdown?.hours.toString().padStart(2, "0")}h{" "}
                          {countdown?.minutes.toString().padStart(2, "0")}m{" "}
                          {countdown?.seconds.toString().padStart(2, "0")}s
                        </h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <div className="mui-card" id="statistics-box-third-container">
                <Grid
                  container
                  alignItems="center"
                  className="inner-info-first"
                >
                  <Grid item sm={4}>
                    <img src={bidCover} className="icon-rfx-general" alt="" />
                  </Grid>
                  <Grid item sm={8}>
                    <Grid container>
                      <Grid item sm={12}>
                        <p className="text-bold text-gray question-num-text">
                          Best Bid
                        </p>
                      </Grid>
                      <Grid item sm={12}>
                        <h3 className="text-black text-bold question-num simple-word-break">
                          {bestBid !== "-" &&
                          (!rfxDetails?.sealed_bid ||
                            rfxDetails.status === AUCTION_COMPLETED)
                            ? `${thousandSepratorHandler(bestBid)} ${
                                rfxDetails?.currency
                              }`
                            : "--"}
                        </h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* table  */}
        <RfxInfoOverviewTable
          setNotify={setNotify}
          isOwner={isOwner}
          setBestBid={setBestBid}
          totalQuestion={rfxDetails?.questionsCounts}
          totalItems={rfxDetails?.itemsCounts}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    serverLiveTS: getServerLiveTimestamp(state),
    isConnected: currentSocketStatus(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(RfxGeneralInfoTab));
