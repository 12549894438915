/*eslint-disable */
import { Grid, Paper } from "@material-ui/core";
import {
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { AuctionLiveBidRecords } from "../../../../data/AuctionLiveBidRecords";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Notification from "../../../../components/Notification";
import TableHeadOptionList from "../../../../components/TableHeadOptionList";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import TablePaginationBox from "../../../../components/TablePaginationBox";
import usePrevious from "../../../../components/usePrevious";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentLiveAuction } from "../../../../store/selectors/auctionSelector";
import GetAllAuctionBidsBL from "../../BL/GetAllAuctionBidsBL";
import Moment from "moment";
import "../../styles/live-bid-history.css";
import GetLiveGraphSuppliersBL from "../../BL/GetLiveGraphSuppliersBL";
import { thousandSepratorHandler } from "../../../../utility/utils";

const columns = [
  {
    label: "Bid number",
  },
  {
    label: "Time",
  },
  {
    label: "Supplier Name",
  },
  {
    label: "Rank",
  },
  {
    label: "Bid",
  },
];

const LiveBIDHistoryTab = (props) => {
  const { currentLiveAuction, refreshLiveData } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const [numOfPages, setNumOfPages] = useState(0);
  const [bidList, setBidList] = useState([]);
  const [bidOptionList, setBidOptionList] = useState([]);
  const [supplierId, setSupplierId] = useState(-1);
  const [supplierName, setSupplierName] = useState("All Suppliers");
  const [limit, setLimit] = useState(0);
  const [resendApi, setResendApi] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { xsScreen } = useWindowDimensions();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const prevPageValue = usePrevious(page);

  useEffect(() => {
    if (resendApi == true) {
      GetAllAuctionBidsBL({
        currentAuction: currentLiveAuction,
        page: page,
        rowsPerPage: rowsPerPage,
        supplierId: supplierId,
      }).then((data) => {
        setBidList(data.list);
        setLimit(data.limit);
      });
      setResendApi(false);
    }
  }, [resendApi]);

  useEffect(() => {
    if (refreshLiveData == true) {
      setResendApi(true);
    }
  }, [refreshLiveData]);

  useEffect(() => {
    if (currentLiveAuction._id !== undefined) {
      setResendApi(true);
      // GetAllAuctionBidsBL({
      //   currentAuction: currentLiveAuction,
      //   page: page,
      //   rowsPerPage: 1000000,
      // }).then((data) => {
      //
      //   setBidOptionList(data.list);
      // });
      getSupplierList();
    }
  }, [currentLiveAuction]);

  useEffect(() => {
    if (prevPageValue !== undefined) {
      setResendApi(true);
    }
  }, [page]);

  useEffect(() => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  }, [limit, bidList]);

  const getSupplierList = async () => {
    const response = await GetLiveGraphSuppliersBL({
      currentAuction: currentLiveAuction,
    });
    let supplierList = response?.suppliers.splice(
      0,
      response?.suppliers.length - 1
    );
    setBidOptionList([...supplierList]);
  };
  const openSupplierList = (supplierId, optionName) => {
    setSupplierId(supplierId);
    setSupplierName(optionName);
    setAnchorEl(null);
    setResendApi(true);
  };

  return (
    <Paper elevation={0} square>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          style={{ textAlign: xsScreen ? "center" : "start" }}
        >
          <h3 style={{ marginTop: "0px" }}>Bid History </h3>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          style={{
            textAlign: xsScreen ? "center" : "end",
            marginTop: xsScreen ? "10px" : "0px",
          }}
        >
          <Button
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className="btn-mui-table-option outline"
            endIcon={<KeyboardArrowDownIcon />}
          >
            <span>{supplierName}</span>
          </Button>
          <TableHeadOptionList
            id="demo-customized-menu"
            MenuListProps={{
              "aria-label": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => openSupplierList()}
          >
            <MenuItem
              key="a55df55844fd2fd5fd457845484323"
              onClick={() => openSupplierList(-1, "All Suppliers")}
              disableRipple
            >
              All Suppliers
            </MenuItem>
            {bidOptionList.map((bid) => (
              <div>
                <MenuItem
                  key={bid.id}
                  onClick={() => openSupplierList(bid?.id, bid?.name)}
                  disableRipple
                >
                  {bid?.name}
                </MenuItem>
              </div>
            ))}
          </TableHeadOptionList>
        </Grid>
      </Grid>

      <Paper elevation={0} square>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bidList.map((bid) => (
                <TableRow key={bid._id}>
                  <TableCell>{bid.sort}</TableCell>
                  <TableCell>
                    {Moment(bid?.createdDate).format("DD-MM-YYYY HH:mm:ss")}
                  </TableCell>
                  <TableCell>{bid?.supplierId?.company_name}</TableCell>
                  <TableCell>{bid.rank}</TableCell>
                  <TableCell>
                    {thousandSepratorHandler(bid.bid) +
                      " " +
                      currentLiveAuction.currency}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={limit + " Bid Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(LiveBIDHistoryTab));
