import { UPDATE_SERVER_LIVE_CLOCK } from "../constant/serverConstant";

const initialState = {
  timestamp: new Date().getTime(),
  timestampStr: new Date().toLocaleString(),
};

export default function ServerClockReducer(state = initialState, actions) {
  if (actions.type === UPDATE_SERVER_LIVE_CLOCK) {
    return {
      ...state,
      timestamp: actions.payload.timestamp,
      timestampStr: actions.payload.timestampStr,
    };
  }
  return state;
}
