import { updateEventStack } from "../../../store/actions/socketAction";
import {
  updateLiveData,
  updateLiveEventStatus,
} from "../../../store/actions/supplierAspect/liveBidAction";
import { GOIN_LIVE_BID_GROUP_RESPONSE_EVENT } from "../constants";

import SocketHandler from "../SocketHandler";

const GoinBidGroupResEvent = () => {
  SocketHandler.instance.on(GOIN_LIVE_BID_GROUP_RESPONSE_EVENT, (response) => {
    if (response.meta.success === true) {
      if (response && response.data) {
        let liveData = {
          best: response.data.bestBid ? response.data.bestBid : {},
          totalCount: response.data.totalCount ? response.data.totalCount : 0,
        };
        // have a new current bid
        if (response.data.myCurrentBid && response.data.myCurrentBid.bid) {
          // check if this bid belongs to connected supplier
          if (
            SocketHandler.user &&
            response.data.myCurrentBid.createdBy &&
            String(SocketHandler.user._id) ===
              String(response.data.myCurrentBid.createdBy)
          ) {
            liveData["new"] = response.data.myCurrentBid;
          }
        } else {
          liveData["new"] = { bid: 0, rank: 0 };
        }
        SocketHandler.dispatcher(updateLiveData(liveData));
      }
    } else {
      SocketHandler.dispatcher(
        updateLiveEventStatus({
          success: response.meta?.success,
          status: response.meta?.status,
          message: response.meta?.message,
        })
      );
    }

    SocketHandler.dispatcher(
      updateEventStack(GOIN_LIVE_BID_GROUP_RESPONSE_EVENT)
    );
  });
  return true;
};

export default GoinBidGroupResEvent;
