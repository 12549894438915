export const ValidationMessage = {
  itemFileType: "File Not Supported",
  itemFileSize: "File size should not be greater than 800 kb",
  itemAttachmentFileSize: "File size should not be greater than 10 Mb",
  supplierImportFileSize: "File size should not be greater than 8 Mb",
  documentAttachmentFileSize: "File size should not be greater than 20 Mb",
  projectFileSize: "File size should not be greater than 2 Mb",
  projectAttachmentFileSize: "File size should not be greater than 5 Mb",
  endDateError: "Past date is not allowed as end date",
  startDateError: "Past date is not allowed as start date",
  removeAttachment: "Attachment removed successfully",
  somethingWrong: "Something went wrong",
  alphabetOnly: "Only alphabets allowed",
  itemExist: "Item With this name already exist!",
  onlyOneAttachment: "You are allowed to add one attachment only",
  selectDocument: "Please select document file",
  validLink: "Please enter valid link",
  auctionTimeLimit:
    "You can't create auction less than minutes and maximum of 24 hours",
  startEndDate: "Please select valid start and end date",
  itemQuantity: "Item quantity should not be blank",
  addItem: "Please add item",
  selectSupplier: "Please select supplier",
  notUpdateAuction: "You are not able to update auction now",
  validFile: "Please select valid file",
  updateDocument: "Go to the setting for update document",
  addDocumentSetting: "Please add document on settings.",
  auctionWinner: "The auction winner must be automatically assigned !",
  auctionRunningEnded:
    "You can not send reminder after auction is running or ended",
  rfxRunningEnded: "You can not send reminder after RFX is running or ended",
  auctionAddSupplier:
    "You can not add supplier after auction is running or ended",
  auctionRemoveSupplier:
    "You can not remove supplier after auction is running or ended",
  validEmail: "Please enter valid email",
  removeDocument: "Document removed successfully",
  removeLink: "Link removed successfully",
  enterText: "Please enter text",
  notCaptureImage: "Could not capture image",
  passwordSame: "New password and confirm password must be same",
  selectFile: "Please select file",
  notAccessAuction: "You can't access this auction",
  notPlaceBid: "You can not place bid !",
  notEditUser: "You cannot edit user from here",
  budgetLength: "Budget must be less than or equal to 999999999",
  supplierValidation: "Buyer can not invite himself as a supplier",
  questionRequired: "Field is required",
  fieldRequired: "Field is required",
  endDateValidation: "End time should be greater than start time",
  notOnlyAttachment: "Please add your answer",
  addBid: "Please add bid",
  rfxNotRunnig: "Currently RFX is not running",
  itemCategory: "Item category should not be blank",
  spaceNotRequired:
    "There can be no white space at the start of the value entered",
  projectStartDateError: "Start Date cannot be greater than End Date",
  deliveryDate: "Delivery date must be greater than current date",
};
