import {
  GET_AUCTION_DETAILS,
  REFRESH_LIVE_AUCTION_SUPPLIER,
  REFRESH_LIVE_BIDDING_DATA_SUPPLIER,
} from "../../constant/supplierAspect/auctionConstant";

export function setAuctionDetails(auction) {
  return {
    type: GET_AUCTION_DETAILS,
    payload: auction,
  };
}

export function refreshLiveBiddingDataSupplier(auction) {
  return {
    type: REFRESH_LIVE_BIDDING_DATA_SUPPLIER,
    payload: auction,
  };
}

export function refreshLiveAuctionSupplier(auction) {
  return {
    type: REFRESH_LIVE_AUCTION_SUPPLIER,
    payload: auction,
  };
}
