import { GET_RFX_DETAILS } from "../../constant/rfxConstant";

const initialState = {
  rfx: {},
};

const SupplierRfxReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RFX_DETAILS:
      return {
        ...state,
        rfx: action.payload,
      };
    default:
      return state;
  }
};

export default SupplierRfxReducer;
