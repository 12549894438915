import { Api } from "../../../../api/Api";
import { GET_SELLER_AUCTION_CLIENT_LIST_API } from "../../../../constants/ApiUrl";
import axiosInstance from "../../../../services/AxiosInstance";

const GetAllAuctionCompaniesBL = async (props) => {
  const { currentUser } = props;
  let companies = [];

  try {
    // const response = await axiosInstance.get(
    //   `api/seller/auctions/supplierClientList/${currentUser._id}`
    // );
    const response = await Api(
      `${GET_SELLER_AUCTION_CLIENT_LIST_API}/${currentUser._id}`,
      {},
      "get"
    );

    if (response.status === 200) {
      if (response.data && response.data.client !== undefined) {
        companies.push({
          _id: "-1",
          name: "All Client",
        });
        response.data.client.forEach((client) => {
          companies.push({
            _id: client.buyerUserId,
            name: client.company_buyer_name,
          });
        });
      }
    }
  } catch (err) {
    // Handle Error Here
  }

  return {
    companies: companies,
  };
};

export default GetAllAuctionCompaniesBL;
