import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Controls from "../controls/Controls";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(4),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function ContactToAdminModal(props) {
  const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles();

  return (
    <Dialog open={confirmDialog} classes={{ paper: classes.dialog }}>
      {/* <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className={classes.titleIcon}>
                    <NotListedLocationIcon />
                </IconButton>
            </DialogTitle> */}
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">Please contact to Oktio team</Typography>
        {/* <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography> */}
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Controls.Button
          text="Okay"
          color="primary"
          onClick={() => setConfirmDialog(false)}
        />
      </DialogActions>
    </Dialog>
  );
}
