import { Api } from "../../../api/Api";
import { DASHBOARD_STATISTICS_API } from "../../../constants/ApiUrl";

const GetDashboardStatisticsBL = async () => {
  let statistics = {
    totalProjects: 0,
    totalAuctions: 0,
    totalSuppliers: 0,
    totalActiveProjects: 0,
    totalRFX: 0,
  };

  try {
    // let response = await axiosInstance.get(`api/buyer/dashboard/statistics`);
    let response = await Api(DASHBOARD_STATISTICS_API, {}, "get");
    if (response.status === 200) {
      statistics.totalProjects = response?.data?.totalProjects;
      statistics.totalAuctions = response?.data?.totalAuctions;
      statistics.totalSuppliers = response?.data?.totalSuppliers;
      statistics.totalActiveProjects = response?.data?.totalActiveProjects;
      statistics.totalRFX = response?.data?.totalRfxs;
    }
  } catch (err) {}

  return statistics;
};

export default GetDashboardStatisticsBL;
