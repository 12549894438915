export const getServerLiveTimestamp = (state) => {
  if (state.serverClock) {
    return state.serverClock.timestamp;
  }
  return {};
};

export const getServerLiveTimestampStr = (state) => {
  if (state.serverClock) {
    return state.serverClock.timestampStr;
  }
  return {};
};
