import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  EMAIL_VERIFY,
  SIGNUP_EXIST_ACTION,
  FORGOT_PASSWORD_CONFIRMED_ACTION,
  FORGOT_PASSWORD_FAILED_ACTION,
  UPDATE_PROFILE_DETAILS_ACTION,
  ROLE_UPDATE_ACTION,
  USER_UPDATE_ACTION,
} from "../constant/authConstant";
import {
  saveTokenInLocalStorage,
  signUp,
  VerifyEmail,
  EmailVerificationToast,
  SuperAdminPasswordConfirm,
} from "../../services/AuthService";
import Cookies from "universal-cookie";
import { changeCurrentSelectedRole } from "./authorizationAction";
import { CashService } from "../../services/CashService";
import { setRoleInLocalStorage } from "../../utility/utils";
import { LOGIN_API, RESEND_CONFIRMATION_API } from "../../constants/ApiUrl";
import { Api } from "../../api/Api";
const cookies = new Cookies();

export function updateProfileDetails(userDetails) {
  return {
    type: UPDATE_PROFILE_DETAILS_ACTION,
    payload: userDetails,
  };
}

export function signupAction(
  firstName,
  lastName,
  role,
  email,
  companyname,
  country,
  password,
  requestToBeBuyer = false,
  fromBuyer,
  history,
  setNotify
) {
  return (dispatch) => {
    signUp(
      firstName,
      lastName,
      role,
      email,
      companyname,
      country,
      password,
      requestToBeBuyer,
      fromBuyer
    )
      .then((response) => {
        localStorage.setItem("email", email);

        // saveTokenInLocalStorage(response.data);
        // runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        if (response.data.responseCode === "00") {
          dispatch(confirmedSignupAction(response.data));
          history.push("/success");
        }

        if (response.data.responseCode === "99") {
          dispatch(ExistSignupAction(response.data));
        }
      })
      .catch((error) => {
        // var response = error;
        // if (
        //   typeof error.details == "undefined" &&
        //   typeof error.response !== "undefined"
        // ) {
        //   response = error.details;
        // }
        // let message =
        //   typeof response.data !== "undefined" &&
        //   typeof response.data.message !== "undefined"
        //     ? response.data.message
        //     : "Unknow error occured.";

        // const errorMessage = formatError(error);
        // dispatch(signupFailedAction(errorMessage));
        const errorMessage = error?.response?.data?.responseDescription;
        // const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  localStorage.removeItem("userRole");
  CashService.clear();
  history.push("/");
  return {
    type: LOGOUT_ACTION,
  };
}

export function email_verification(token) {
  return (dispatch) => {
    VerifyEmail(token)
      .then((response) => {
        dispatch(emailVerifyConfirm(response.data.ResponseDescription));
      })
      .catch((error) => {});
  };
}

export function email_verification_tost(token) {
  return (dispatch) => {
    EmailVerificationToast(token)
      .then((response) => {
        dispatch(emailVerifyConfirm(response.data.ResponseDescription));
      })
      .catch((error) => {});
  };
}

export function super_admin_password_verify(token, _id) {
  return (dispatch) => {
    SuperAdminPasswordConfirm(token, _id)
      .then((response) => {
        dispatch(emailVerifyConfirm(response.data.ResponseDescription));
      })
      .catch((error) => {});
  };
}
export function loginAction(email, password, history, isRememberMe) {
  return async (dispatch) => {
    const postData = {
      email,
      password,
    };
    // axios
    //   .post(process.env.REACT_APP_BACKEND_API_URL + `/api/auth`, postData)
    const response = await Api(LOGIN_API, postData, "post");
    if (response?.data?.token && response?.data?.token.length > 0) {
      saveTokenInLocalStorage(response.data);
      // localStorage.removeItem("guest");
      if (isRememberMe) {
        cookies.set("myEmail", email, { path: process.env.REACT_APP_URL });
        cookies.set("myPassword", password, {
          path: process.env.REACT_APP_URL,
        });
      }
      // set current role
      const loggedUsr = response.data.user;

      const canToggle =
        !loggedUsr.isSuperAdmin && loggedUsr.isSeller && loggedUsr.isBuyer;

      let defRole = canToggle
        ? "buyer"
        : loggedUsr.isSuperAdmin === false
        ? loggedUsr.isSeller
          ? "supplier"
          : "buyer"
        : "super_admin";

      //
      if (loggedUsr.role === "both") {
        setRoleInLocalStorage("buyer");
      } else {
        setRoleInLocalStorage(
          loggedUsr.role === "seller" ? "supplier" : "buyer"
        );
      }

      dispatch(changeCurrentSelectedRole(defRole));
      dispatch(loginConfirmedAction(response.data));
      let prefix = defRole === "supplier" ? "/supplier" : "";
      prefix === "/supplier"
        ? history.push(`/${defRole}/auction`)
        : history.push("/");
    } else {
      const resCode = response?.data?.responseCode ? true : false;

      const errorMessage = resCode
        ? response?.data?.responseDescription
        : response?.data;

      dispatch(loginFailedAction(errorMessage));
    }
    // .catch((error) => {

    //   const resCode = error?.response?.data?.responseCode ? true : false;

    //   const errorMessage = resCode
    //     ? error?.response?.data?.responseDescription
    //     : error?.response?.data;

    //   dispatch(loginFailedAction(errorMessage));
    // });
  };
}

export function forgotPasswordAction(email, history, dispatch) {
  return async (dispatch) => {
    const response = await Api(RESEND_CONFIRMATION_API, email, "post");
    if (response.status === 200) {
      dispatch(forgotPasswordConfirmedAction(response.data));
    } else {
      const resCode = response?.data?.responseCode ? true : false;

      const errorMessage = resCode
        ? response?.data?.responseDescription
        : response?.data;

      dispatch(forgotPasswordFailedAction(errorMessage));
    }
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function ExistSignupAction(payload) {
  return {
    type: SIGNUP_EXIST_ACTION,
    payload,
  };
}

export function emailVerifyConfirm(payload) {
  return {
    type: EMAIL_VERIFY,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function forgotPasswordConfirmedAction(data) {
  return {
    type: FORGOT_PASSWORD_CONFIRMED_ACTION,
    payload: data,
  };
}

export function forgotPasswordFailedAction(data) {
  return {
    type: FORGOT_PASSWORD_FAILED_ACTION,
    payload: data,
  };
}

export function roleUpdateAction(data) {
  return {
    type: ROLE_UPDATE_ACTION,
    payload: data,
  };
}

export const userProfileUpdateAction = (data) => {
  return {
    type: USER_UPDATE_ACTION,
    payload: data,
  };
};
