import { Api } from "../../../api/Api";
import { BUYER_PROJECT_LIST_API } from "../../../constants/ApiUrl";
import axiosInstance from "../../../services/AxiosInstance";

const GetAllProjectBL = async (props) => {
  try {
    const { companyId, filters, search, page, rowsPerPage, sort } = props;
    let list = [];
    let limit = 0;
    let Page = 1;
    let RowsPerPage = Number.MAX_SAFE_INTEGER;

    if (page && page !== "") {
      Page = page;
    }

    if (rowsPerPage && rowsPerPage !== "") {
      RowsPerPage = rowsPerPage;
    }

    try {
      let path = `${BUYER_PROJECT_LIST_API}/${companyId}?per_page=${RowsPerPage}&page=${Page}&sorting=${
        sort && sort !== "" ? sort : "-1"
      }`;

      if (filters && filters.status && filters.status != "-1") {
        path += `&status=${filters.status}`;
      }

      if (search && search !== "") {
        path += `&search=${search}`;
      }

      if (filters && filters.location && filters.location !== "") {
        path += `&location=${filters.location}`;
      }

      if (filters && filters.manager && filters.manager !== "") {
        path += `&manager=${filters.manager}`;
      }

      if (filters && filters.owner && filters.owner !== "") {
        path += `&owner=${filters.owner}`;
      }

      if (
        filters &&
        filters.dateRange &&
        Array.isArray(filters.dateRange) &&
        filters.dateRange.length > 0 &&
        filters.dateRange[0] !== null &&
        filters.dateRange[1] !== null
      ) {
        path += `&startDate=${filters.dateRange[0]}&endDate=${filters.dateRange[1]}`;
      }

      // let response = await axiosInstance.get(path);
      const response = await Api(path, {}, "get");

      if (response.status === 200) {
        list = response.data.project;
        limit = response.data.totalRecords;
      }
    } catch (err) {}

    return {
      list,
      limit,
    };
  } catch (error) {}
};

export default GetAllProjectBL;
