import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import UsersReducer from "./reducers/userReducer";
import ProjectReducer from "../store/reducers/projectReducers";
import ItemReducer from "../store/reducers/itemReducers";
import thunk from "redux-thunk";
import { AuthReducer } from "../store/reducers/authReducers";
import AuctionReducer from "./reducers/auctionReducers";
import SupplierAuctionReducer from "./reducers/supplierAspect/auctionReducers";
import { AuthorizationReducer } from "./reducers/authorizationReducer";
import SocketReducer from "./reducers/socketReducer";
import liveBidReducer from "./reducers/supplierAspect/liveBidReducer";
import ServerClockReducer from "./reducers/serverClockReducer";
import { ErrorHandlerReducer } from "./reducers/errorHandlerReducer";
import rfxReducer from "./reducers/rfxReducer";
import SupplierRfxReducer from "./reducers/supplierAspect/SupplierRfxReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  users: UsersReducer,
  projects: ProjectReducer,
  itemss: ItemReducer,
  auth: AuthReducer,
  authorization: AuthorizationReducer,
  auction: AuctionReducer,
  rfx: rfxReducer,
  supplierAuction: SupplierAuctionReducer,
  supplierRfx: SupplierRfxReducer,
  socketIO: SocketReducer,
  liveBid: liveBidReducer,
  serverClock: ServerClockReducer,
  errorHandler: ErrorHandlerReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
