/*eslint-disable */
import React, { useEffect, useState } from "react";
// import Link from '@material-ui/core/Link';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, withRouter } from "react-router-dom";
// import AuctionsSmImg from "../assets/Auctions.png";
import AuctionsBgImg from "../assets/Auctions@2x.png";
import RfxBgImg from "../assets/Rfx/RfxSidmenuCover.png";
import DashboardBgImg from "../assets/Dashboard@2x.png";
import ProjectsBgImg from "../assets/Projects@2x.png";
import SuppliersBgImg from "../assets/Suppliers@2x.png";
import ItemsBgImg from "../assets/Items@2x.png";
import contractImg from "../assets/Reports.png";
import CompanyUsersBgImg from "../assets/CompanyUsers@2x.png";
import SettingsBgImg from "../assets/Settings@2x.png";
import { isAdmin, isBuyer, isSeller } from "../store/selectors/RoleSelector";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect, useSelector } from "react-redux";
import { getCurrentRole } from "../store/selectors/AuthorSelector";
import { PermIdentity, SupervisorAccount } from "@material-ui/icons";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  IconColor: {
    color: "#FFFFFF",
  },
});

function SideMenu(props) {
  const classes = useStyles();
  const { currentRole, isAdmin, isSeller, isBuyer } = props;
  const prefix = currentRole === "supplier" ? "/supplier" : "";
  const [currentRoleForMenu, setCurrentRoleForMenu] = useState("");
  const selectedRole = useSelector(
    (state) => state?.authorization?.selectedRole
  );

  useEffect(() => {
    // let res = getRoleFromLocalStorage();
    setCurrentRoleForMenu(selectedRole);
  }, [selectedRole]);

  return (
    <React.Fragment>
      {/* TODO : must be merged => have only on list for all roles in system */}
      {currentRole === "super_admin" ? (
        <Box sx={{ flexGrow: "1" }}>
          <ListItem button component={Link} to={prefix + "/admin/company"}>
            <ListItemIcon aria-label="selected">
              <PermIdentity className="text-white" />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.IconColor}
                >
                  Users
                </Typography>
              }
            />
          </ListItem>
          <ListItem button component={Link} to={prefix + "/admin/user-listing"}>
            <ListItemIcon aria-label="selected">
              <SupervisorAccount className="text-white" />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.IconColor}
                >
                  Super Admins
                </Typography>
              }
            />
          </ListItem>
        </Box>
      ) : (
        <Box sx={{ flexGrow: "1" }}>
          {currentRoleForMenu === "buyer" ? (
            <ListItem button component={Link} to={prefix + "/dashboard"}>
              <ListItemIcon aria-label="selected">
                <img src={DashboardBgImg} style={{ padding: "7px" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.IconColor}
                  >
                    Dashboard
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <></>
          )}

          {currentRoleForMenu === "buyer" ? (
            <ListItem button component={Link} to={prefix + "/projects"}>
              <ListItemIcon className={classes.IconColor}>
                <img src={ProjectsBgImg} style={{ padding: "7px" }} />
              </ListItemIcon>
              <ListItemText
                className={classes.IconColor}
                primary={
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.IconColor}
                  >
                    Projects
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <></>
          )}

          <ListItem button component={Link} to={prefix + "/auction"}>
            <ListItemIcon className={classes.IconColor}>
              <img src={AuctionsBgImg} style={{ padding: "7px" }} />
            </ListItemIcon>
            <ListItemText
              className={classes.IconColor}
              primary={
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.IconColor}
                >
                  Auctions
                </Typography>
              }
            />
          </ListItem>
          {/* {currentRoleForMenu === "buyer" ? ( */}
          <ListItem button component={Link} to={prefix + "/rfx"}>
            <ListItemIcon className={classes.IconColor}>
              <img src={RfxBgImg} style={{ padding: "7px" }} />
            </ListItemIcon>
            <ListItemText
              className={classes.IconColor}
              primary={
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.IconColor}
                >
                  RFX
                </Typography>
              }
            />
          </ListItem>
          {currentRoleForMenu === "buyer" ? (
            <ListItem button component={Link} to={prefix + "/suppliers"}>
              <ListItemIcon className={classes.IconColor}>
                <img src={SuppliersBgImg} style={{ padding: "7px" }} />
              </ListItemIcon>
              <ListItemText
                className={classes.IconColor}
                primary={
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.IconColor}
                  >
                    Suppliers
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <></>
          )}

          {currentRoleForMenu === "buyer" ? (
            <ListItem button component={Link} to={prefix + "/contracts"}>
              <ListItemIcon className={classes.IconColor}>
                <img src={contractImg} style={{ padding: "7px" }} />
              </ListItemIcon>
              <ListItemText
                className={classes.IconColor}
                primary={
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.IconColor}
                  >
                    Contracts
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <></>
          )}
          {currentRoleForMenu === "buyer" ? (
            <ListItem button component={Link} to={prefix + "/items"}>
              <ListItemIcon className={classes.IconColor}>
                <img src={ItemsBgImg} style={{ padding: "7px" }} />
              </ListItemIcon>
              <ListItemText
                className={classes.IconColor}
                primary={
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.IconColor}
                  >
                    Items
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <></>
          )}
          {/* <ListItem button component={Link} to={prefix + "/dashboard"}>
            <ListItemIcon className={classes.IconColor}>
              <img src={ReportsBgImg} style={{ padding: "7px" }} />
            </ListItemIcon>
            <ListItemText
              className={classes.IconColor}
              primary={
                <Typography 
                  variant="body2"
                  gutterBottom
                  className={classes.IconColor}
                >
                  Reports
                </Typography>
              }
            />
          </ListItem> */}
          {currentRoleForMenu === "buyer" ? (
            <ListItem button component={Link} to={prefix + "/companyusers"}>
              <ListItemIcon className={classes.IconColor}>
                <img src={CompanyUsersBgImg} style={{ padding: "7px" }} />
              </ListItemIcon>
              <ListItemText
                className={classes.IconColor}
                primary={
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.IconColor}
                  >
                    Company Users
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <></>
          )}

          <ListItem button component={Link} to={prefix + "/settings"}>
            <ListItemIcon className={classes.IconColor}>
              <img src={SettingsBgImg} style={{ padding: "7px" }} />
            </ListItemIcon>
            <ListItemText
              className={classes.IconColor}
              primary={
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.IconColor}
                >
                  Settings
                </Typography>
              }
            />
          </ListItem>
          {/* <ListItem button component={Link} to={prefix + "/dashboard"}>
            <ListItemIcon className={classes.IconColor}>
              <img src={SupportBgImg} style={{ padding: "7px" }} />
            </ListItemIcon>
            <ListItemText
              className={classes.IconColor}
              primary={
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.IconColor}
                >
                  Supports
                </Typography>
              }
            />
          </ListItem> */}
        </Box>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
  };
};
export default withRouter(connect(mapStateToProps)(SideMenu));
