import { Box, Button, Grid, Paper } from "@mui/material";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { getCurrentRole } from "../../../store/selectors/AuthorSelector";
import {
  isAdmin,
  isBuyer,
  isSeller,
} from "../../../store/selectors/RoleSelector";
import { useEffect } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import AuctionsBgImg from "../../../assets/Auctions@2x.png";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import "../styles/auction-table.css";
import GetAllAuctionBL from "../../Auction/BL/GetAllAuctionBL";
import moment from "moment";
import {
  AUCTION_ACCEPTED,
  AUCTION_CANCELLED,
  AUCTION_DECLINED,
  AUCTION_DRAFT,
  AUCTION_ENDED,
  AUCTION_INVITED,
  AUCTION_LOST,
  AUCTION_ONHOLD,
  AUCTION_PUBLISHED,
  AUCTION_RUNNING,
  AUCTION_WON,
  BLUE_BACKGROUND,
  DANGER_OUTLINE_BACKGROUND,
  DISABELED_BACKGROUND,
  GREY_BACKGROUND,
  PRIMARY_BACKGROUND,
  PRIMARY_OUTLINE_BACKGROUND,
  PURPLE_BACKGROUND,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  TYPE_ONE,
  TYPE_TWO,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../constants/AppConstants";

const columns = [
  {
    label: "ID",
  },
  {
    label: "Auction Type",
  },
  // {
  //   label: "Client Name",
  //   minWidth: 170,
  // },
  {
    label: "Auction Name",
    minWidth: 170,
  },
  {
    label: "Status",
  },
  {
    label: "Start Date",
  },
  {
    label: "Confirmed / Invited",
  },
  {
    label: "Owner",
    minWidth: 170,
  },
];

const RecentAuction = (props) => {
  const { resendApi, setNotify, currentUser } = props;
  const [auctions, setAuctions] = useState([]);
  const [numOfPages, setNumOfPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [limit, setLimit] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (resendApi == true) {
      GetAllAuctionBL({
        companyId: currentUser.company._id,
        rowsPerPage: rowsPerPage,
      })
        .then((data) => {
          setAuctions(data.list);
          setNumOfPages(Math.ceil(data.list.length / rowsPerPage));
          setLimit(data.list.length);
        })
        .catch((error) => {});
    }
  }, [resendApi]);

  const navigateToAuctionDetails = async (auction) => {
    history.push("/auction/" + auction._id + "/details");
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case TYPE_ONE:
        return BLUE_BACKGROUND;
      case TYPE_TWO:
        return GREY_BACKGROUND;
      default:
        return DISABELED_BACKGROUND;
    }
  };

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ENDED:
        return SECONDARY_BACKGROUND;
      case AUCTION_ACCEPTED:
        return SUCCESS_BACKGROUND;
      case AUCTION_WON:
        return SUCCESS_BACKGROUND;
      case AUCTION_PUBLISHED:
        return PRIMARY_BACKGROUND;
      case AUCTION_DECLINED:
        return WARNING_OUTLINE_BACKGROUND;
      case AUCTION_ONHOLD:
        return PRIMARY_OUTLINE_BACKGROUND;
      case AUCTION_DRAFT:
        return PRIMARY_OUTLINE_BACKGROUND;
      case AUCTION_CANCELLED:
        return WARNING_OUTLINE_BACKGROUND;
      case AUCTION_LOST:
        return DANGER_OUTLINE_BACKGROUND;
      case AUCTION_RUNNING:
        return PURPLE_BACKGROUND;
      case AUCTION_INVITED:
        return PURPLE_BACKGROUND;
      default:
        return SUCCESS_BACKGROUND;
    }
  };

  return (
    <>
      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Paper elevation={0} square>
            <Grid container style={{ marginTop: "1em" }}>
              <Grid item container xs={12} style={{ alignItems: "center" }}>
                <Grid item xs={12}>
                  <h1 className="item-container">Top recent events</h1>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer
              className="table-container supplier-aspect"
              component={Paper}
              aria-label="list-all-auction-table-supplier-aspect"
            >
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: "700" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auctions.map((auction) => (
                    // <TableRow key={auction._id}>
                    <TableRow
                      key={auction._id}
                      className="cursor-p"
                      onDoubleClick={() => navigateToAuctionDetails(auction)}
                    >
                      <TableCell className="recent-auction-auction-id">
                        {auction?.auctionId}
                      </TableCell>
                      <TableCell className="recent-auction-auction-type">
                        <div
                          className="items-center d-flex"
                          style={{
                            opacity:
                              getTypeLabel(auction.auction_type) == "disabled"
                                ? "0"
                                : "1",
                          }}
                        >
                          <div
                            className={
                              "aution-img-container " +
                              getTypeLabel(auction.auction_type)
                            }
                          >
                            <img src={AuctionsBgImg} />
                          </div>
                          <div> English Auction </div>
                        </div>
                      </TableCell>
                      {/* <TableCell>
                        {currentUser?.company?.company_name}
                      </TableCell> */}
                      <TableCell className="recent-auction-auction-name">
                        {auction?.auction_name}
                      </TableCell>
                      <TableCell className="recent-auction-auction-date">
                        <Button
                          className={
                            "badge badge-" +
                            getStatusLabel(auction?.status) +
                            " status"
                          }
                          variant="contained"
                        >
                          {auction?.status}
                        </Button>
                      </TableCell>
                      <TableCell className="recent-auction-auction-date">
                        <p>{moment(auction?.startDate).format("DD/MM/YYYY")}</p>
                        <p>{moment(auction?.startDate).format("LT")}</p>
                      </TableCell>
                      <TableCell>
                        {auction.totalAccepted + "/" + auction.totalInvited}
                      </TableCell>
                      <TableCell>
                        <div className="items-center d-flex">
                          <img
                            style={{
                              width: "26px",
                              height: "26px",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                            src={auction?.createdBy?.image_upload}
                          />
                          <div className="ml-2">
                            {auction.createdBy && auction.createdBy.email
                              ? auction?.createdBy?.first_name +
                                " " +
                                auction?.createdBy?.last_name
                              : "---"}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {auctions.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="text-center"
                      >
                        No records found <br />
                        {/* <i style={{ color: "var(---2170ff-p-)" }}>
                          Please add some auction
                        </i> */}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
              {/* <TablePaginationBox
                label={limit + " Auctions Found"}
                numOfPages={numOfPages}
                onChangePage={(event, newPage) => setPage(newPage)}
                page={page}
              /> */}
            </TableContainer>
          </Paper>
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(RecentAuction));
