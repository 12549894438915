import { Api } from "../../../api/Api";
import { AUCTION_LIST_API } from "../../../constants/ApiUrl";

const GetAllAuctionBL = async (props) => {
  try {
    const { companyId, filters, search, page, rowsPerPage } = props;
    let list = [];
    let limit = 0;
    let Page = 1;
    let RowsPerPage = Number.MAX_SAFE_INTEGER;

    if (page && page !== "") {
      Page = page;
    }

    if (rowsPerPage && rowsPerPage !== "") {
      RowsPerPage = rowsPerPage;
    }

    let path = `${AUCTION_LIST_API}/${companyId}?per_page=${RowsPerPage}&page=${Page}`;

    if (filters && filters.status && filters.status != "-1") {
      path += `&status=${filters.status}`;
    }

    if (search && search !== "") {
      path += `&search=${search}`;
    }

    if (filters && filters.bider && filters.bider !== "") {
      path += `&bider=${filters.bider}`;
    }

    if (filters && filters.currency && filters.currency !== "") {
      path += `&currency=${filters.currency}`;
    }

    if (
      filters &&
      filters.dateRange &&
      Array.isArray(filters.dateRange) &&
      filters.dateRange.length > 0
    ) {
      path += `&startDate=${filters.dateRange[0]}&endDate=${filters.dateRange[1]}`;
    }

    if (filters && filters.owner && filters.owner !== "") {
      path += `&owner=${filters.owner}`;
    }

    if (filters && filters.project && filters.project !== "") {
      path += `&project=${filters.project}`;
    }

    // let response = await axiosInstance.get(path);
    let response = await Api(path, {}, "get");
    if (response.status === 200) {
      list = response.data.auction;
      limit = response.data.totalRecords;
    }

    return {
      list,
      limit,
    };
  } catch (error) {}
};

export default GetAllAuctionBL;
