import {
  Box,
  Grid,
  InputLabel,
  Modal,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Form, useForm } from "../../../components/useForm";
import Controls from "../../../components/controls/Controls";
import { store } from "../../../store/store";
import {
  TOAST_AUTO_CLOSE,
  VALID_EMAIL_REGEX,
} from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { Slide, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Api } from "../../../api/Api";
import { COMAPNY_API, GET_SUPPLIER_EMAIL_API } from "../../../constants/ApiUrl";
import ImportSupplierList from "./ImportSupplierList";
import CloseIcon from "@mui/icons-material/Close";
import "../styles/InviteSUpplierModal.css";

const useStyles = makeStyles((theme) => ({
  selectAdornment: {
    "& .MuiButtonBase-root": {
      padding: 0,
      left: "0px",
      top: "calc(50% - 0px)",
    },
  },
  tabs: {
    margin: "20px 0 30px 0",
    "& .MuiTabs-scroller .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
      backgroundColor: "#2170ff",
      padding: "0px",
      // margin: "7px",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      textTransform: "capitalize",
    },
    "& .MuiTab-root": {
      fontWeight: 600,
      color: "#000",
      fontSize: "16px",
      textTransform: "capitalize",
      padding: "0px 15px",
      borderBottom: "1px solid #2170ff",
      opacity: "1",

      // minHeight: "2.7em",
    },
  },
}));

const initialFValues = {
  email: "",
  supplier_company_name: "",
  country: "",
  category: "",
};

const InviteSupplierModal = ({
  openPopup,
  setOpenPopup,
  getAllSuppliers,
  isEditRecord,
  setFullLoder,
  setIsEditRecord,
}) => {
  const userDetails = useSelector((state) => state.auth.auth.user);
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    resetForm();
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-label={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (!VALID_EMAIL_REGEX.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("supplier_company_name" in fieldValues)
      temp.supplier_company_name = fieldValues.supplier_company_name
        ? ""
        : "Supplier company name is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const supplierValidation = async (userEmail) => {
    let isError;
    if (userEmail === userDetails.email) {
      toast.error(ValidationMessage.supplierValidation, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      isError = true;
    } else {
      isError = false;
    }
    return isError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const userId = auth?.auth?.user?.company?._id;
    if (validate()) {
      const invalidEmail = await supplierValidation(values.email);
      if (invalidEmail) return;
      let payload = {
        userId: auth?.auth?.user?._id,
        email: values.email,
        // country: values.country,
        company_name: auth?.auth?.user?.company?.company_name,
        supplier_company: values.supplier_company_name,
        uniqueId: "S" + Math.floor(100000 + Math.random() * 900000),
      };

      const response = await Api(
        `${COMAPNY_API}/${companyId}/suppliers/addSupplier`,
        payload,
        "post"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setOpenPopup(false);
          getAllSuppliers();
          resetForm();
        }
      }
    }
  };

  const getEmail = async (email) => {
    const userEmail = email.target.value;
    if (userEmail) {
      setFullLoder(true);
      const response = await Api(
        `${GET_SUPPLIER_EMAIL_API}/${userEmail}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          const supplierData = response?.data?.supplier;
          setValues({
            email: supplierData?.email,
            supplier_company_name: supplierData?.company_name?.company_name,
            country: supplierData?.company?.country,
            // category: supplierData,
          });
          setTimeout(() => {
            setFullLoder(false);
          }, 1000);
        } else {
          setFullLoder(false);
        }
      }
    } else {
      setFullLoder(false);
    }
  };
  const clickClosePopup = () => {
    setOpenPopup(false);
    resetForm();
    setIsEditRecord(false);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const textFieldRef = useRef(null);
  return (
    <Modal
      open={openPopup}
      aria-label="create-supplier-popup-buyer-aspect"
      aria-describedby="create-supplier-popup-buyer-aspect-desc"
    >
      <Box className="popup-form-container">
        <Grid container>
          <Grid item xs={9}>
            <p className="profile-title">
              {!isEditRecord ? "Invite" : "Edit"} Supplier
            </p>
          </Grid>
          <Grid item xs={3} className="text-end">
            <CloseIcon
              onClick={() => {
                setOpenPopup(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "25px !important",
          }}
        >
          <div className="invite-tab">
            <div
              className={`common-tab ${value === 0 ? "activeTab" : ""}`}
              onClick={() => handleChange(0)}
            >
              <p>Single</p>
            </div>
            <div
              className={`common-tab ${value === 1 ? "activeTab" : ""}`}
              onClick={() => handleChange(1)}
            >
              <p>Multiple</p>
            </div>
          </div>
        </Box>
        {value === 0 ? (
          <div>
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel id="email-input">Email</InputLabel>
                  <Controls.Input
                    inputRef={textFieldRef}
                    labelId="email-input"
                    name="email"
                    disabled={!isEditRecord ? false : true}
                    fullWidth
                    // value={values.email}
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    onBlur={getEmail}
                    inputProps={{ maxLength: 100 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="position-input">Company Name</InputLabel>
                  <Controls.Input
                    labelId="position-input"
                    name="supplier_company_name"
                    fullWidth
                    value={values.supplier_company_name}
                    onChange={handleInputChange}
                    error={errors.supplier_company_name}
                    className="StepControlInput"
                    inputProps={{ maxLength: 40 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                <InputLabel id="position-input">Country</InputLabel>
                <Controls.Select
                  labelId="position-input"
                  name="country"
                  value={values.country}
                  fullWidth
                  onChange={handleInputChange}
                  options={countries}
                  error={errors.country}
                  className="StepControlInput"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="position-input">Category</InputLabel>
                <Controls.Select
                  labelId="position-input"
                  name="category"
                  value={values.category}
                  fullWidth
                  onChange={handleInputChange}
                  options={dropdownSupplierCategory}
                  error={errors.category}
                  className="StepControlInput"
                />
              </Grid> */}
              </Grid>

              <div className="bottom-button-box-container">
                <div className="left-side">
                  <Controls.Button
                    variant="contained"
                    disableElevation
                    className="btn-mui w-100"
                    onClick={() => {
                      clickClosePopup();
                    }}
                    text="Cancel"
                    style={{
                      backgroundColor: "#E2F2FF",
                      color: "#2170FF",
                      marginRight: "2.5rem",
                    }}
                  />
                </div>
                <div className="right-side">
                  <div>
                    <Controls.Button
                      variant="contained"
                      disableElevation
                      className="btn-mui w-100"
                      type="submit"
                      text={!isEditRecord ? "Invite" : "Update Supplier"}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <div>
            <ImportSupplierList
              getAllSuppliers={getAllSuppliers}
              setOpenPopup={setOpenPopup}
            />
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default InviteSupplierModal;
