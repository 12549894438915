/*eslint-disable */
import { Grid, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormControlLabel, SwitchUnstyled } from "@mui/material";
import TablePaginationBox from "../../../../../components/TablePaginationBox";
import useWindowDimensions from "../../../../../components/useWindowDimensions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCurrentAuction } from "../../../../../store/selectors/supplierAspect/auctionSelector";
import { currentUser } from "../../../../../store/selectors/AuthSelectors";
import GetAuctionBidsBL from "../../BL/GetAuctionBidsBL";
import BidListAllModal from "./BidListAllModal";
import "../../styles/details-bid-list.css";
import usePrevious from "../../../../../components/usePrevious";

const BidList = (props) => {
  const { currentAuction, currentUser, refresLiveBiddingData } = props;

  // --- my bid ---
  const [myBidPage, setMyBidPage] = useState(1);
  const prevMyBidPageVal = usePrevious(myBidPage);
  const [myBidList, setMyBidList] = useState([]);
  const [myBidLimit, setMyBidLimit] = useState(0);
  const [emptyRowsMine, setEmptyRowsMine] = useState(0);
  const [numOfPagesMine, setNumOfPagesMine] = useState(0);
  const [myBidRowsPerPage, setMyBidRowsPerPage] = useState(3);

  // --- all bid ---
  const [allBidPage, setAllBidPage] = useState(1);
  const prevAllBidPageVal = usePrevious(allBidPage);
  const [allBidList, setAllBidList] = useState([]);
  const [allBidLimit, setAllBidLimit] = useState(0);
  const [emptyRowsAll, setEmptyRowsAll] = useState(0);
  const [numOfPagesAll, setNumOfPagesAll] = useState(0);
  const [allBidRowsPerPage, setAllBidRowsPerPage] = useState(3);
  const [columnMine, setColumnMine] = useState([]);
  const [columnAll, setColumnAll] = useState([]);
  // const [tableColumns, setTableColumns] = useState([]);
  const [tableLabel, setTableLabel] = useState("");
  const [resendApiMine, setResendApiMine] = useState(false);
  const [resendApiAll, setResendApiAll] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isMyBidMode, setIsMyBidMode] = useState(true);

  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  // --- my bid (APIs & Config)---
  const resetPaginationSettingMine = () => {
    setEmptyRowsMine(Math.max(0, myBidPage * myBidRowsPerPage - myBidLimit));
    setNumOfPagesMine(Math.ceil(myBidLimit / myBidRowsPerPage));
  };

  useEffect(() => {
    if (resendApiMine == true) {
      GetAuctionBidsBL({
        currentAuction: currentAuction,
        myBidPage: myBidPage,
        myBidRowsPerPage: myBidRowsPerPage,
        mine: true,
      }).then((data) => {
        setMyBidList(data.list);
        setMyBidLimit(data.limit);
        setColumnMine(data.columns);
        setTableLabel(data.label);
        setResendApiMine(false);
      });
    }
  }, [resendApiMine]);

  useEffect(() => {
    if (resendApiAll == true) {
      GetAuctionBidsBL({
        currentAuction: currentAuction,
        allBidPage: allBidPage,
        allBidRowsPerPage: allBidRowsPerPage,
        mine: false,
      }).then((data) => {
        setAllBidList(data.list);
        setAllBidLimit(data.limit);
        setColumnAll(data.columns);
        setTableLabel(data.label);
        setResendApiAll(false);
      });
    }
  }, [resendApiAll]);

  const getRowDataForMine = () => {
    let rows = [];
    for (let numOfRow = 0; numOfRow < myBidList.length; numOfRow++) {
      let data = [];
      for (let numOfCol = 1; numOfCol <= columnMine.length; numOfCol++) {
        data.push(<TableCell>{myBidList[numOfRow][numOfCol]}</TableCell>);
      }
      rows.push(<TableRow key={myBidList[numOfRow][0]}>{data}</TableRow>);
    }
    return rows;
  };

  useEffect(() => {
    resetPaginationSettingMine();
  }, [myBidLimit, myBidList]);

  useEffect(() => {
    if (prevAllBidPageVal !== undefined) {
      setResendApiMine(true);
    }
  }, [myBidPage]);

  // --- All bid (APIs & Config)---

  const getRowDataForAll = () => {
    let rows = [];
    for (let numOfRow = 0; numOfRow < allBidList.length; numOfRow++) {
      let data = [];
      for (let numOfCol = 1; numOfCol <= columnAll.length; numOfCol++) {
        data.push(<TableCell>{allBidList[numOfRow][numOfCol]}</TableCell>);
      }
      rows.push(<TableRow key={allBidList[numOfRow][0]}>{data}</TableRow>);
    }
    return rows;
  };

  const resetPaginationSettingAll = () => {
    setEmptyRowsAll(Math.max(0, allBidPage * allBidRowsPerPage - allBidLimit));
    setNumOfPagesAll(Math.ceil(allBidLimit / allBidRowsPerPage));
  };

  useEffect(() => {
    resetPaginationSettingAll();
  }, [allBidLimit, allBidList]);

  useEffect(() => {
    if (prevMyBidPageVal !== undefined) {
      setResendApiAll(true);
    }
  }, [allBidPage]);

  useEffect(() => {
    if (currentAuction._id !== undefined && currentUser._id !== undefined) {
      setResendApiMine(true);
      setResendApiAll(true);
    }
  }, [currentAuction, currentUser]);

  useEffect(() => {
    if (refresLiveBiddingData == true) {
      setResendApiMine(true);
      setResendApiAll(true);
    }
  }, [refresLiveBiddingData]);

  return (
    <>
      <div className="mui-card supp-auction-details-bid-list-container">
        <Grid container>
          <Grid
            item
            sm={4}
            xs={12}
            style={{ textAlign: smScreen ? "center" : "start" }}
          >
            <div className="header-title">{tableLabel}</div>
          </Grid>
          <Grid item sm={8} xs={12} className="options-contaier">
            <div>
              <span className="my-bid">My Bid</span>
              <FormControlLabel
                control={
                  <SwitchUnstyled
                    name="is_admin"
                    value={isMyBidMode}
                    onChange={() => setIsMyBidMode(!isMyBidMode)}
                    className="switchable-toggle"
                  />
                }
                label=""
              />
              <span className="all-bid">All Bid</span>
            </div>
            <div className="header-button">
              <div
                className="btn"
                onClick={() => {
                  setOpenPopup(true);
                }}
              >
                View All
              </div>
            </div>
          </Grid>
        </Grid>

        <div style={{ position: "relative" }}>
          {/* ---- my bid  */}
          <TableContainer
            className={
              "table-container mybid-table " + (isMyBidMode ? "__active" : "")
            }
            component={Paper}
          >
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columnMine.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "700" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getRowDataForMine()}
                {getRowDataForMine().length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={columnMine.length}
                      className="text-center"
                    >
                      No records found <br />
                      {/* <i style={{ color: "var(---2170ff-p-)" }}>
                        Please add some bid
                      </i> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>

            <TablePaginationBox
              label={myBidLimit + " Bids Found"}
              numOfPages={numOfPagesMine}
              onChangePage={(event, newPage) => setMyBidPage(newPage)}
              page={myBidPage}
            />
          </TableContainer>
          {/* ---- all bid  */}
          <TableContainer
            className={
              "table-container allbid-table " + (!isMyBidMode ? "__active" : "")
            }
            component={Paper}
          >
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columnAll.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "700" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getRowDataForAll()}
                {getRowDataForAll().length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={columnAll.length}
                      className="text-center"
                    >
                      No records found <br />
                      {/* <i style={{ color: "var(---2170ff-p-)" }}>
                        Please add some bid
                      </i> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
            <TablePaginationBox
              label={allBidLimit + " Bids Found"}
              numOfPages={numOfPagesAll}
              onChangePage={(event, newPage) => setAllBidPage(newPage)}
              page={allBidPage}
            />
          </TableContainer>
        </div>
      </div>

      <BidListAllModal
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        isMyBidModeInitVal={isMyBidMode}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuction: getCurrentAuction(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(BidList));
