/*eslint-disable */
import { Box, Grid, Paper, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import HelpIcon from "@mui/icons-material/Help";
import TimeClockImage from "../../../assets/Time-Remaining@2x.png";
import CurrentBidImage from "../../../assets/statistics/CurrentBid.png";
import SupplierImage from "../../../assets/statistics/Supplier2.png";
import MinStepImage from "../../../assets/statistics/MinBid.png";
import ReservePriceImage from "../../../assets/statistics/MaskGroup591.png";
import "../styles/live-banner.css";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCurrentLiveAuction } from "../../../store/selectors/auctionSelector";
import { useEffect, useRef, useState } from "react";
import SetupLiveMonitoringBL from "../../SupplierAspect/Auction/BL/SetupLiveMonitoringBL";
import { currentSocketStatus } from "../../../store/selectors/SocketSelector";
import { GOIN_LIVE_BID_GROUP_EVENT } from "../../../services/socket/constants";
import SocketHandler from "../../../services/socket/SocketHandler";
import moment from "moment";
import { thousandSepratorHandler } from "../../../utility/utils";

const LiveAuctionBanner = (props) => {
  const { isConnected, currentLiveAuction, refreshLiveData } = props;

  useEffect(() => {
    if (
      isConnected === true &&
      currentLiveAuction != undefined &&
      currentLiveAuction._id !== undefined
    ) {
      SocketHandler.emit(GOIN_LIVE_BID_GROUP_EVENT, {
        groupId: `auction_${currentLiveAuction?._id}_group`,
        supplierAuctionId: currentLiveAuction?._id,
        auctionId: currentLiveAuction?._id,
        type: "buyer",
      });
    }
  }, [isConnected, currentLiveAuction]);

  return (
    <>
      {/* header of banner */}
      <BannerHeader refreshLiveData={refreshLiveData} />
      {/* header of banner */}

      {/* statistcs of banner */}
      <DetailsStatistics refreshLiveData={refreshLiveData} />
      {/* statistcs of banner */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
    isConnected: currentSocketStatus(state),
  };
};

export default withRouter(connect(mapStateToProps)(LiveAuctionBanner));

function DetailsStatistics(props) {
  const { refreshLiveData } = props;
  const selectedAuction = useSelector((state) => state.auction.liveAuction);
  const bestLiveBid = useSelector((state) => state.liveBid.best);
  const totalCount = useSelector((state) => state.liveBid.totalCount);
  const serverLiveTS = useSelector((state) => state.serverClock.timestamp);
  const isConnected = useSelector((state) => state.socketIO.connected);

  const [resendApi, setResendApi] = useState(false);
  const [duration, setDuration] = useState("--");
  const [timeRemLabel, setTimeRemLabel] = useState("--:--:--");
  const [timeRevLabel, setTimeRevLabel] = useState("0h 0m");

  const calculateTimeLeft = async (dueTime) => {
    if (dueTime >= 0) {
      const hours = Math.floor((dueTime / 3600) % 24);
      const minutes = Math.floor((dueTime / 60) % 60);
      const seconds = Math.floor(dueTime % 60);
      setTimeRemLabel(
        ("0" + hours).substr(-2) +
          ":" +
          ("0" + minutes).substr(-2) +
          ":" +
          ("0" + seconds).substr(-2)
      );
    }
  };

  const calculateRevTimeLeft = async (leftTime) => {
    if (leftTime >= 0) {
      const hours = Math.floor((leftTime / 3600) % 24);
      const minutes = Math.floor((leftTime / 60) % 60);
      const seconds = Math.floor(leftTime % 60);
      setTimeRevLabel(
        ("0" + hours).substr(-2) + ":" + ("0" + minutes).substr(-2)
        // +
        //   ":" +
        //   ("0" + seconds).substr(-2)
      );
    }
  };
  const setupTimer = (timerSetting) => {
    if (!timerSetting.canCount) {
      setTimeRemLabel(timerSetting.dueTime > 0 ? "00:00:00" : "--:--:--");
      setTimeRevLabel(
        timerSetting.revTime < 0
          ? "0h 0m"
          : moment.utc(duration * 1000).format("HH:mm")
      );
    } else {
      calculateTimeLeft(timerSetting.dueTime);
      calculateRevTimeLeft(timerSetting.revTime);
    }
  };

  useEffect(() => {
    if (resendApi == true) {
      if (selectedAuction._id !== undefined) {
        selectedAuction.auctionId = {
          auctionId: selectedAuction.auctionId,
          startDate: selectedAuction.startDate,
          endDate: selectedAuction.endDate,
        };
        setDuration(selectedAuction.totalTimeSec);
        // setDuration((selectedAuction.totalTimeSec / 60).toFixed(1));
      }
      setResendApi(false);
    }
  }, [resendApi]);

  useEffect(() => {
    if (isConnected === true && selectedAuction && selectedAuction._id) {
      let res = SetupLiveMonitoringBL({
        currentAuction: selectedAuction,
        serverLiveTS: serverLiveTS,
      });

      setupTimer(res.timeRemaining);
    }
  }, [serverLiveTS]);

  useEffect(() => {
    if (refreshLiveData === true) {
      setResendApi(true);
    }
  }, [refreshLiveData]);

  useEffect(() => {
    if (selectedAuction._id !== undefined) {
      setResendApi(true);
    }
  }, [selectedAuction]);

  return (
    <Paper>
      <Box style={{ flexGrow: 1 }} className="live-statistics-container">
        <Grid container spacing={2}>
          <Grid
            item
            // xl={2}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            className="item-stc-container"
          >
            <div className="mui-card mui-card-primary">
              <Grid container>
                <Grid item sm={4} className="icon-container">
                  <img src={TimeClockImage} className="icon clr-ffffff" />
                </Grid>
                <Grid item sm={6}>
                  <Grid container className="content-container timer">
                    <Grid item sm={12}>
                      <div className="mui-label">Time Remaining</div>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="text "> {timeRemLabel} </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            // xl={2}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            className="item-stc-container"
          >
            <div className="mui-card">
              <Grid container>
                <Grid item sm={4} className="icon-container">
                  <img src={CurrentBidImage} className="icon" />
                </Grid>
                <Grid item sm={6}>
                  <Grid container className="content-container">
                    <Grid item sm={12}>
                      <div className="mui-label">Current Best Bid</div>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="text ">
                        {bestLiveBid && bestLiveBid.weightedBid
                          ? thousandSepratorHandler(bestLiveBid.weightedBid) +
                            " " +
                            selectedAuction.currency
                          : "--"}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            // xl={2}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            className="item-stc-container"
          >
            <div className="mui-card best-bid">
              <Grid container>
                <Grid item sm={4} className="icon-container">
                  <img src={SupplierImage} className="icon" />
                </Grid>
                <Grid item sm={8}>
                  <Grid
                    container
                    justifyContent="center"
                    className="content-container supplier-name"
                  >
                    <Grid item sm={12}>
                      <div className="mui-label">Best Bid (Supplier)</div>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="text">
                        {bestLiveBid && bestLiveBid.supplierId
                          ? bestLiveBid.supplierId?.company_name
                          : "--"}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            // xl={2}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            className="item-stc-container"
          >
            <div className="mui-card">
              <Grid container>
                <Grid item sm={4} className="icon-container">
                  <img src={SupplierImage} className="icon" />
                </Grid>
                <Grid item sm={8}>
                  <Grid container className="content-container">
                    <Grid item sm={12}>
                      <div className="mui-label">Bid Count | Duration</div>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="text">
                        <Grid container>
                          <Grid item sm={5} xs={12}>
                            {totalCount}
                          </Grid>
                          <Grid item sm={7} xs={12} className="">
                            {timeRevLabel}
                            {/* {moment.utc(duration * 1000).format("HH:mm:ss")} */}
                          </Grid>
                          {/* <Grid item sm={6} className="text-end">
                            {duration > 1
                              ? duration + " hour"
                              : Math.round(duration) + " min"}
                          </Grid> */}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            // xl={2}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            className="item-stc-container"
          >
            <div className="mui-card">
              <Grid container justifyContent="center">
                <Grid item sm={4} className="icon-container">
                  <img src={MinStepImage} className="icon" />
                </Grid>
                <Grid item sm={6}>
                  <Grid container className="content-container">
                    <Grid item sm={12}>
                      <div className="mui-label">Minimum Step</div>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="text">
                        {selectedAuction.minimum_step !== undefined
                          ? thousandSepratorHandler(
                              selectedAuction.minimum_step
                            )
                          : " -- "}

                        <Tooltip
                          title="Minimum price difference compare to the current best BID"
                          classes={{ popper: "mui-tooltip small-box" }}
                          placement="top"
                          arrow
                        >
                          <HelpIcon className="hint-icon" />
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            // xl={2}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            className="item-stc-container"
          >
            <div className="mui-card">
              <Grid container justifyContent="center">
                <Grid item sm={4} className="icon-container">
                  <img src={ReservePriceImage} className="icon" />
                </Grid>
                <Grid item sm={6}>
                  <Grid container className="content-container">
                    <Grid item sm={12}>
                      <div className="mui-label">Reserve Price</div>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="text">
                        {selectedAuction.reserve_price !== undefined
                          ? thousandSepratorHandler(
                              selectedAuction.reserve_price
                            ) +
                            " " +
                            selectedAuction.currency
                          : " -- "}
                        <Tooltip
                          title="Minimum price to be eligible for an awarding"
                          classes={{ popper: "mui-tooltip small-box" }}
                          placement="top"
                          arrow
                        >
                          <HelpIcon className="hint-icon" />
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

function BannerHeader(props) {
  const selectedAuction = useSelector((state) => state.auction.liveAuction);
  return (
    <div style={{ marginTop: "6em" }}>
      <div className="details-banner" style={{ background: "transparent" }}>
        <div className="banner-container">
          <Grid
            container
            style={{
              position: "relative",
            }}
            spacing={1}
          >
            <Grid item xs={12}>
              <h1 className="project-title">
                {/* <img src={selectedAuction.project?.image} className="sm-icon" /> */}
                <span style={{ verticalAlign: "super", marginRight: "0.5em" }}>
                  {selectedAuction.auction_name !== undefined
                    ? selectedAuction?.auction_name +
                      " (" +
                      selectedAuction?.auction_type +
                      ")"
                    : "--"}
                </span>
              </h1>
              <div className="project-title__smal">
                <span> Auctions </span>
                <span>
                  {selectedAuction.auction_name !== undefined
                    ? " / " + selectedAuction?.auction_name
                    : "--"}
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <button className="btn btn-primary go-back-button">
                  <Link
                    to={`/auction/${selectedAuction?._id}/details`}
                    className="mui-link"
                  >
                    <span style={{ color: "var(---2170ff-p-)" }}>
                      <ArrowBackIosNewIcon /> Go Back
                    </span>
                  </Link>
                </button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
