import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Search from "@material-ui/icons/Search";
import CommonButton from "../../../../components/controls/CommonButton";
import { useEffect, useState } from "react";
import { Api } from "../../../../api/Api";
import { store } from "../../../../store/store";
import { useSelector } from "react-redux";
import { POST_SUPPLIER_SAVE_IMPORT_DATA_API } from "../../../../constants/ApiUrl";

const RfxSupplierModal = ({
  showSupplierModal,
  setShowSupplierModal,
  getQuestionCompareList,
  getPriceCompareList,
  setFilteredData,
  filteredData,
  fromAward,
  submitAward,
}) => {
  const { auth } = store.getState();
  const companyId = auth?.auth?.user?.company?._id;
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);
  const [mainSupplierList, setMainSupplierList] = useState([]);
  useEffect(() => {
    getCompanySupplierList();
  }, []);

  const getCompanySupplierList = async (searchText, fromSearch = false) => {
    let queryString = "";
    let path = `${POST_SUPPLIER_SAVE_IMPORT_DATA_API}/${companyId}/rfx/${rfxDetails?._id}/getSuppliers`;

    if (searchText && searchText.length > 0) {
      queryString = `?search=${searchText}`;
      path += queryString;
    }

    const response = await Api(path, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        const companyIdData = [];
        const tempData = [...response?.data?.data?.companyList];
        if (tempData && tempData.length > 0) {
          tempData.map((item) => {
            companyIdData.push(item?._id);
          });
        }
        const temp = [...response?.data?.data?.companyList];

        if (!fromSearch) {
          let isUsed = false;

          if (temp && temp.length > 0) {
            temp.map((item) => {
              let bool = filteredData.includes(item?._id);
              item.checked = bool;
              item.isDisplay = true;

              if (bool) isUsed = true;
            });
            if (!isUsed && !fromAward) {
              temp.map((item) => {
                item.checked = true;

                filteredData.push(item?._id);
              });
            }
          }

          setMainSupplierList([...temp]);
        }
        if (mainSupplierList && mainSupplierList.length > 0) {
          let finalDisplay = [...mainSupplierList];
          finalDisplay.map((obj) => {
            if (companyIdData.includes(obj?._id)) {
              obj.isDisplay = true;
            } else {
              obj.isDisplay = false;
            }
          });
          setMainSupplierList([...finalDisplay]);
        }
      }
    }
  };

  const handleChange = (checked, item) => {
    let temp = [...mainSupplierList];
    temp.map((obj) => {
      if (obj?._id === item?._id) {
        obj.checked = checked;
      }
    });

    setMainSupplierList([...temp]);
  };

  const submitFilteredSupplier = () => {
    const temp = [...mainSupplierList];
    const finalResponse = [];
    const data = temp.map((item) => {
      if (item?.checked) {
        finalResponse.push(item?._id);
      }
    });
    if (finalResponse.length > 0) setFilteredData([...finalResponse]);
    else setFilteredData([]);
    if (fromAward) {
      submitAward(finalResponse);
      return;
    }
    getQuestionCompareList(false, finalResponse);
    getPriceCompareList(false, finalResponse);
    setShowSupplierModal(false);
  };

  return (
    <Modal open={showSupplierModal}>
      <Box className="popup-form-container rfx-invite-supplier">
        <Grid container>
          <Grid item xs={9}>
            <p className="profile-title">Add Supplier</p>
          </Grid>
          <Grid item xs={3} className="text-end">
            <CloseIcon
              onClick={() => {
                setShowSupplierModal(false);
              }}
              className="cursor-p"
            />
          </Grid>
        </Grid>
        <div className="mt-5 mb-5">
          <TextField
            onChange={(e) => getCompanySupplierList(e.target.value, true)}
            placeholder="Search Supplier"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="rfx-supplier-list">
          {mainSupplierList && mainSupplierList.length > 0 ? (
            <>
              {mainSupplierList.map((item) => {
                if (item.isDisplay)
                  return (
                    <Grid container className="supplier-data">
                      <Grid item xs={10}>
                        <p className="text-bold">{item?.company_name}</p>
                        <div className="rfx-supplier-detail d-flex g-10 align-item-center text-gray">
                          <p className="supplier-name">
                            {item?.user?.first_name} {item?.user?.last_name}
                          </p>
                          <div className="dot"></div>
                          <p>{item?.email}</p>
                        </div>
                      </Grid>
                      <Grid item xs={2} className="d-flex justify-content-end">
                        <Checkbox
                          checked={item?.checked || false}
                          onChange={(e) => handleChange(e.target.checked, item)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                    </Grid>
                  );
              })}
            </>
          ) : (
            <p>No Data Found</p>
          )}

          <div className="global-flex-center mt-4">
            <CommonButton
              text="Submit"
              onClick={() => submitFilteredSupplier()}
              className="btn-mui "
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RfxSupplierModal;
