import { Grid, Paper } from "@material-ui/core";
import {
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { AuctionLiveSupplierRecords } from "../../../../data/AuctionLiveSupplierRecords";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Notification from "../../../../components/Notification";
import TableHeadOptionList from "../../../../components/TableHeadOptionList";
import TablePaginationBox from "../../../../components/TablePaginationBox";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import "../../styles/live-bid-history.css";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  getCurrentLiveAuction,
  shouldRefreshLiveBiddingData,
} from "../../../../store/selectors/auctionSelector";
import GetAllSupplierRanksBL from "../../BL/GetAllSupplierRanksBL";
import usePrevious from "../../../../components/usePrevious";
import { thousandSepratorHandler } from "../../../../utility/utils";

const columns = [
  {
    label: "Rank",
  },
  {
    label: "Supplier Name",
  },
  {
    label: "Starting Bid",
  },
  {
    label: "Current Bid",
  },
  {
    label: "Weighted bid",
  },
  {
    label: "Number of Bid",
  },
  {
    label: "Status",
  },
];

const getColorSupplier = (type) => {
  switch (type) {
    case 1:
      return "#65C0FC";
    case 2:
      return "#9765FC";
    case 3:
      return "#15D400";
    case 4:
      return "#DE65FC";
  }
};

const getColorStatus = (status) => {
  switch (status) {
    case "connected":
      return "#00AD84";
    case "offline":
      return "#A19B9D";
  }
};

const LiveSupplierRankTab = (props) => {
  const { currentLiveAuction, refreshLiveData } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { xsScreen } = useWindowDimensions();
  const dispatch = useDispatch();
  const openMenu = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const [numOfPages, setNumOfPages] = useState(0);
  const [supplierList, setSupplierList] = useState([]);
  const [limit, setLimit] = useState(0);
  const [resendApi, setResendApi] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const prevPageValue = usePrevious(page);

  const openSupplierList = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (resendApi == true) {
      GetAllSupplierRanksBL({
        currentAuction: currentLiveAuction,
        page: page,
        rowsPerPage: rowsPerPage,
      }).then((data) => {
        setSupplierList(data.list);
        setLimit(data.limit);
      });
      setResendApi(false);
    }
  }, [resendApi]);

  useEffect(() => {
    if (refreshLiveData == true) {
      setResendApi(true);
    }
  }, [refreshLiveData]);

  useEffect(() => {
    if (prevPageValue !== undefined) {
      setResendApi(true);
    }
  }, [page]);

  useEffect(() => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  }, [limit, setSupplierList]);

  return (
    <Paper elevation={0} square>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          style={{ textAlign: xsScreen ? "center" : "start" }}
        >
          <h3 style={{ marginTop: "0px" }}>Supplier's Rank </h3>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          style={{
            textAlign: xsScreen ? "center" : "end",
            marginTop: xsScreen ? "10px" : "0px",
          }}
        >
          {/* <Button
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className="btn-mui-table-option outline"
            endIcon={<KeyboardArrowDownIcon />}
          > 
            <span>All Suppliers</span>
          </Button>
          <TableHeadOptionList
            id="demo-customized-menu"
            MenuListProps={{
              "aria-label": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => openSupplierList()}
          >
            <MenuItem onClick={() => openSupplierList()} disableRipple>
              Supplier #1
            </MenuItem>
            <MenuItem onClick={() => openSupplierList()} disableRipple>
              Supplier #2
            </MenuItem>
            <MenuItem onClick={() => openSupplierList()} disableRipple>
              Supplier #3
            </MenuItem>
          </TableHeadOptionList> */}
        </Grid>
      </Grid>

      <Paper elevation={0} square>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {supplierList.map((supplier) => (
                <TableRow key={supplier?._id}>
                  <TableCell>{supplier?.rank}</TableCell>
                  <TableCell>{supplier?.supplierId?.company_name}</TableCell>
                  <TableCell>
                    {thousandSepratorHandler(supplier?.startingBid)}
                  </TableCell>
                  <TableCell>
                    {thousandSepratorHandler(supplier?.currentBid)}
                  </TableCell>
                  <TableCell>{thousandSepratorHandler(supplier?.weightedBid)}</TableCell>
                  <TableCell>{supplier?.noOfBid}</TableCell>
                  <TableCell style={{ color: getColorStatus("connected") }}>
                    connected
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={limit + " Suppliers Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(LiveSupplierRankTab));
