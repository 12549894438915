/*eslint-disable */
import { useState, useEffect } from "react";
import { useForm, Form } from "../../components/useForm";
import Controls from "../../components/controls/Controls";
import * as employeeService from "../../services/employeeService";
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import { store } from "../../store/store";
import Notification from "../../components/Notification";
import ChangePassword from "./settingChangePassword";
import UpdateEmail from "./settingChangeEmail";
import UpdatePhone from "./settingChangePhone";
import TimeZones from "../../assets/timezone.json";
import { InputLabel } from "@mui/material";
import MultiLanIcon from "../../assets/icons/multi-language.png";
import TimeZoneIcon from "../../assets/icons/time-zone.png";
import MoneyIcon from "../../assets/icons/money.png";
import PadlockIcon from "../../assets/icons/padlock.png";
import useWindowDimensions from "../../components/useWindowDimensions";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import "./settings.css";
import "./styles/profile.css";
import { updateUserProfileImage } from "../../utility/utils";
import { userProfileUpdateAction } from "../../store/actions/authActions";
import {
  ACCEPT_ONLY_IMAGE,
  PROJECT_FILE_SIZE,
} from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { phone } from "phone";
import { Api } from "../../api/Api";
import {
  GET_BUISNESS_UNIT_API,
  GET_USER_API,
  PATCH_v1_UPDATE_PROFILE_API,
} from "../../constants/ApiUrl";

const useStyles = makeStyles((theme) => ({
  errMsg: {
    color: "red",
    fontSize: "14px",
    margin: "3px 0",
  },
  imgPreview: {
    width: "100%",
    height: "460px",
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#ccc",
    padding: "100px",
    border: "1px solid #000",
    background: "#cccccc",
  },

  customFileUpload: {
    color: "#55d6d6",
    fontSize: "22px",
    fontWeight: "500",
    padding: "6px 12px",
    cursor: "pointer",
  },
  files: { padding: "5px" },
  customeFileUpload: {
    position: "absolute",
    padding: "47px 20px",
    cursor: "pointer",
  },
  formPart1: {
    "& .MuiFormControl-root": {
      width: "90%",
    },
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    padding: ".5rem 2rem",
    fontWeight: "700",
  },
  input: {
    display: "none",
  },
  attachmentText: {
    fontWeight: "700",
  },
  attachment: {
    margin: "2rem 0",
  },
  Button: {
    padding: ".4rem 3rem",
    borderRadius: "6px",
    fontWeight: "700",
    fontSize: "14px",
  },
  imageInfo: {
    color: "#A19B9D",
    fontSize: "12px",
    maxWidth: "10.5rem",
  },
  imageInfoTwo: {
    color: "#A19B9D",
    fontSize: "12px",
  },
  modalContent: {
    width: "100%",
  },
  addLink: {
    color: "#1E1D1D",
    margin: "0 0 30px 0",
    fontSize: "14px",
    fontWeight: "700",
  },
  dragAndDrop: {
    fontWeight: "700",
    fontSize: "18px",
  },
  attachmentBoxes: {
    padding: "10px",
  },
}));

const initialFValues = {
  first_name: "",
  last_name: "",
  position: "",
  role: "",
  business_unit: "",
  email: "",
  mobile: "",
  company_name: "",
  language: "",
  timezone: "",
  currency: "",
};

const getNewUserRole = [
  { id: "seller", title: "Seller" },
  { id: "buyer", title: "Buyer" },
  { id: "both", title: "Buyer/Seller" },
  { id: "super_admin", title: "Super Admin" },
];

const MyProfile = (props) => {
  const { setFullLoder, isAdmin, isSeller, isBuyer, currentRole } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [imgPreview, setImgPreview] = useState(null);
  const [error, setError] = useState("");
  const [img, setImg] = useState("");
  const [imgFile, setImgFile] = useState(null);
  const [changePasswordPopup, setChangePasswordPopup] = useState(false);
  const [changeEmailPopup, setChangeEmailPopup] = useState(false);
  const [changePhonePopup, setChangePhonePopup] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const [dropDownBusinessUnit, setDropDownBusinessUnit] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [errorPhone, setErrorPhone] = useState(false);

  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("company_name" in fieldValues)
      temp.company_name = fieldValues.company_name
        ? ""
        : "This field is required.";

    if ("first_name" in fieldValues) {
      if (fieldValues.first_name && fieldValues.first_name.length > 0) {
        if (fieldValues.first_name.length < 3) {
          temp.first_name = "Minimun 3 character required";
        } else {
          temp.first_name = "";
        }
      } else {
        temp.first_name = "This field is required.";
      }
    }

    if ("last_name" in fieldValues) {
      if (fieldValues.last_name && fieldValues.last_name.length > 0) {
        if (fieldValues.last_name.length < 3) {
          temp.last_name = "Minimun 3 character required";
        } else {
          temp.last_name = "";
        }
      } else {
        temp.last_name = "This field is required.";
      }
    }

    if ("position" in fieldValues) {
      if (fieldValues.position && fieldValues.position.length > 0) {
        temp.position = "";
      } else {
        temp.position = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const removeSelectedImage = () => {
    setImg("");
    setImgPreview(null);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const { xsScreen } = useWindowDimensions();

  const handleSubmit = async (e) => {
    const { auth } = store.getState();
    const userId = auth?.auth?.user?._id;
    e.preventDefault();
    if (validate() && !errorPhone) {
      setFullLoder(true);

      let formData = new FormData();
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("position", values.position);
      formData.append("company_name", values.company_name);

      if (values.timezone) {
        formData.append("preferences[timezone]", values.timezone);
      }
      if (values.language) {
        formData.append("preferences[language]", values.language);
      }
      if (values.currency) {
        formData.append("preferences[currency]", values.currency);
      }

      if (values.role && values.role.length > 0)
        formData.append("role", values.role);
      if (values.business_unit && values.business_unit.length > 0)
        formData.append("business_unit", values.business_unit);
      if (values.email && values.email.length > 0)
        formData.append("email", values.email);
      if (phoneNumber) formData.append("mobile", phoneNumber);
      if (countryCode) formData.append("countryCode", countryCode);
      if (imgFile && imgFile.type) {
        formData.append("profile", imgFile);
      } else {
        formData.append("image_upload", img || null);
      }
      // const userDetails = {
      //   image_upload: img,
      //   first_name: values.first_name,
      //   last_name: values.last_name,
      //   position: values.position,
      //   role: values.role,
      //   business_unit: values.business_unit,
      //   email: values.email,
      //   mobile: phoneNumber,
      //   countryCode: countryCode,
      //   company_name: values.company_name,
      //   preferences: {
      //     language: values.language,
      //     timezone: values.timezone,
      //     currency: values.currency,
      //   },
      // };

      const response = await Api(
        `${PATCH_v1_UPDATE_PROFILE_API}/${userId}`,
        formData,
        "patch"
      );

      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          //  let addedProjects = response.data;
          setImgFile(null);
          dispatch(
            userProfileUpdateAction(
              response?.data?.updatedData?.image_upload,
              response?.data?.updatedData?.first_name,
              response?.data?.updatedData?.last_name
            )
          );

          updateUserProfileImage(
            response?.data?.updatedData?.image_upload,
            response?.data?.updatedData?.first_name,
            response?.data?.updatedData?.last_name
          );
        }
        setFullLoder(false);
      } else {
        setImgFile(null);
        setFullLoder(false);
      }

      // axiosInstance
      //   .patch(`api/users/updateProfile/${userId}`, userDetails)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       //  let addedProjects = response.data;
      //       setNotify({
      //         isOpen: true,
      //         message: response?.data?.responseDescription,
      //         type: "success",
      //       });
      //       dispatch(
      //         userProfileUpdateAction(
      //           response?.data?.updatedData?.image_upload,
      //           response?.data?.updatedData?.first_name,
      //           response?.data?.updatedData?.last_name
      //         )
      //       );

      //       updateUserProfileImage(
      //         response?.data?.updatedData?.image_upload,
      //         response?.data?.updatedData?.first_name,
      //         response?.data?.updatedData?.last_name
      //       );
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: response?.data?.responseDescription,
      //         type: "success",
      //       });
      //     }
      //     setFullLoder(false);
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //     setFullLoder(false);
      //   });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  async function handleImageChange(e) {
    const selected = e.target.files[0];

    const ALLOWED_TYPES = ACCEPT_ONLY_IMAGE;
    if (selected && !ALLOWED_TYPES.includes(selected.type)) {
      setError(ValidationMessage.itemFileType);

      return;
    }
    if (selected && Math.round(selected.size / 1024) > PROJECT_FILE_SIZE) {
      setError(ValidationMessage.projectFileSize);

      return;
    }
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64Image = await getBase64(selected);
      setImgFile(selected);
      setImg(base64Image);
      setImgPreview(base64Image);
      setError("");
    }
  }

  const userDetails = async () => {
    const { auth } = store.getState();
    const userEmail = auth?.auth?.user?._id;
    setFullLoder(true);
    const response = await Api(`${GET_USER_API}/${userEmail}`, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let profileDetails = response.data.users;
        setImgPreview(profileDetails.image_upload || null);
        setImg(profileDetails.image_upload || null);
        setServiceData(profileDetails);
        setValues({
          first_name: profileDetails.first_name,
          last_name: profileDetails.last_name,
          position: profileDetails.position,
          role: profileDetails.role,
          business_unit: profileDetails.business_unit,
          email: profileDetails.email,
          mobile: `${profileDetails?.countryCode}${profileDetails?.mobile}`,
          company_name: profileDetails?.company?.company_name,
          language: profileDetails?.preferences?.language,
          timezone: profileDetails?.preferences?.timezone,
          currency: profileDetails?.preferences?.currency,
        });
        setFullLoder(false);
      }
    } else {
      setFullLoder(false);
    }

    // axiosInstance
    //   .get(`api/users/${userEmail}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let profileDetails = response.data.users;
    //       setImgPreview(profileDetails.image_upload || null);
    //       setImg(profileDetails.image_upload || null);
    //       setServiceData(profileDetails);
    //       setValues({
    //         first_name: profileDetails.first_name,
    //         last_name: profileDetails.last_name,
    //         position: profileDetails.position,
    //         role: profileDetails.role,
    //         business_unit: profileDetails.business_unit,
    //         email: profileDetails.email,
    //         mobile: `${profileDetails?.countryCode}${profileDetails?.mobile}`,
    //         company_name: profileDetails?.company?.company_name,
    //         language: profileDetails?.preferences?.language,
    //         timezone: profileDetails?.preferences?.timezone,
    //         currency: profileDetails?.preferences?.currency,
    //       });
    //       setFullLoder(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setFullLoder(false);
    //   });
  };

  const getAllBusinessUnit = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    setFullLoder(true);

    if (companyId) {
      const response = await Api(
        `${GET_BUISNESS_UNIT_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let businessUnit = response.data.businessUnits;
          setDropDownBusinessUnit(businessUnit);
        }
        setFullLoder(false);
      } else {
        setFullLoder(false);
      }

      // axiosInstance
      //   .get(`api/company/getBusinessUnits/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let businessUnit = response.data.businessUnits;
      //       setDropDownBusinessUnit(businessUnit);
      //     }
      //     setFullLoder(false);
      //   })
      //   .catch((err) => {
      //     setFullLoder(false);
      //   });
    }
  };

  useEffect(() => {
    userDetails();
    getAllBusinessUnit();
  }, []);

  const phoneNumberValidateHandler = (val) => {
    const PlusData = `+${val}`;
    const FinalVal = phone(PlusData);

    return FinalVal;
  };

  const phoneNumberChangeHandler = (phone) => {
    let init = {
      target: {
        name: "mobile",
        value: phone,
      },
    };
    handleInputChange(init);
    let res = phoneNumberValidateHandler(phone);

    if (!res.isValid) {
      setErrorPhone(true);
    } else {
      setCountryCode(res?.countryCode);
      setPhoneNumber(res?.phoneNumber.replace(res?.countryCode, ""));
      setErrorPhone(false);
    }
  };

  return (
    <>
      <Container className="settings-profile-section-contaier">
        <p className="profile-title">Personal Details</p>
        <Form onSubmit={handleSubmit}>
          <Grid container className="containerSettings" justifyContent="center">
            <Grid item md={4} xs={12}>
              <div
                className="leftContent mb-4"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                {error && error.length > 0 && (
                  <p
                    className="errMsg"
                    style={{
                      color: "red",
                      fontSize: "11px",
                      margin: "3px 0",
                      paddingLeft: "10px",
                    }}
                  >
                    {error}
                  </p>
                )}
                <div
                  className="imgPreview"
                  style={{
                    background: imgPreview
                      ? `url("${imgPreview}") no-repeat center/cover`
                      : "#e2f2ff",
                    width: "120px",
                    height: "120px",
                    display: "flex",
                    flexDirection: "column",
                    alignItem: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    backgroundColor: "#E2F2FF",
                    borderRadius: "50%",
                    margin: "0 0 30px",
                    color: "#000000",
                  }}
                >
                  {!imgPreview && (
                    <>
                      <p>
                        <AddCircleIcon color="primary" />
                      </p>
                      <label
                        htmlFor="fileUploadButton"
                        className="customeFileUpload"
                        style={{
                          position: "absolute",
                          padding: "47px 57px",
                          cursor: "pointer",
                        }}
                      >
                        &nbsp;
                      </label>
                      <input
                        type="file"
                        accept={ACCEPT_ONLY_IMAGE}
                        id="fileUploadButton"
                        hidden
                        onChange={(e) => handleImageChange(e)}
                        value={values.image}
                      />
                    </>
                  )}
                </div>
                {/* <input
                  accept="image/*"
                  className={classes.input}
                  id="outlined-button-file"
                  multiple
                  type="file"
                /> */}
                <Controls.Button
                  text="Delete"
                  color="default"
                  disableElevation
                  onClick={() => removeSelectedImage()}
                  className={classes.button}
                />
                <Typography
                  className={classes.imageInfo}
                  style={{ marginTop: "20px", textAlign: "center" }}
                >
                  540x540 px recommended. JPG or PNG. Max size of 2M
                </Typography>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <InputLabel className="mui-label">First Name </InputLabel>
                  <Controls.Input
                    name="first_name"
                    fullWidth
                    value={values.first_name}
                    onChange={handleInputChange}
                    error={errors.first_name}
                    inputProps={{ maxLength: 30 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel className="mui-label">last Name </InputLabel>
                  <Controls.Input
                    name="last_name"
                    fullWidth
                    value={values.last_name}
                    onChange={handleInputChange}
                    error={errors.last_name}
                    inputProps={{ maxLength: 30 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Position </InputLabel>
                  <Controls.Input
                    name="position"
                    className="b-600"
                    fullWidth
                    value={values.position}
                    onChange={handleInputChange}
                    error={errors.position}
                    inputProps={{ maxLength: 30 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Role </InputLabel>
                  <Controls.Select
                    name="role"
                    value={values.role}
                    fullWidth
                    onChange={handleInputChange}
                    options={getNewUserRole}
                    error={errors.role}
                    inputProps={{ maxLength: 30 }}
                    className="b-600"
                    // disabled={values.role !== "both" ? true : false}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Company Name </InputLabel>
                  <Controls.Input
                    name="company_name"
                    fullWidth
                    disabled
                    id="outlined-required"
                    value={values.company_name}
                    onChange={handleInputChange}
                    error={errors.company_name}
                    className="b-600"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Business Unit </InputLabel>
                  <Controls.Select
                    name="business_unit"
                    value={values.business_unit}
                    fullWidth
                    onChange={handleInputChange}
                    options={dropDownBusinessUnit}
                    error={errors.business_unit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Email</InputLabel>
                  <Controls.Input
                    name="email"
                    fullWidth
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    inputProps={{ maxLength: 60 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Mobile Number</InputLabel>
                  <PhoneInput
                    country={"us"}
                    value={values.mobile}
                    onChange={(phone) => {
                      phoneNumberChangeHandler(phone);
                    }}
                  />
                  {errorPhone && (
                    <div className="error-msg">
                      Please enter valid contact number
                    </div>
                  )}
                  {/* <Controls.Input
                    name="mobile"
                    fullWidth
                    value={values.mobile}
                    onChange={handleInputChange}
                    error={errors.mobile}
                    inputProps={{ maxLength: 15 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  /> */}
                  {/* <Controls.Button text="Change" onClick={() => setChangePhonePopup(true)} /> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <p className="profile-title mt-5">Preferences</p>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            className="mt-5 preferences-container"
          >
            <Grid item className="row-label">
              <Grid container alignItems="center">
                <img src={MultiLanIcon} width={25} height={25} />
                <span className="ml-3">Language</span>
              </Grid>
            </Grid>
            <Grid item className="row-field">
              <Controls.Select
                name="language"
                value={values.language}
                onChange={handleInputChange}
                options={employeeService.languageOption()}
                error={errors.language}
              />
            </Grid>
            <Grid item className="row-label">
              <Grid container alignItems="center">
                <img src={TimeZoneIcon} width={25} height={25} />
                <span className="ml-3">Timezone</span>
              </Grid>
            </Grid>
            <Grid item className="row-field">
              <Controls.Select
                name="timezone"
                value={values.timezone}
                onChange={handleInputChange}
                options={TimeZones}
                error={errors.timezone}
              />
            </Grid>
            <Grid item className="row-label">
              <Grid container alignItems="center">
                <img src={MoneyIcon} width={25} height={25} />
                <span className="ml-3">Currency</span>
              </Grid>
            </Grid>
            <Grid item className="row-field">
              <Controls.Select
                name="currency"
                value={values.currency}
                onChange={handleInputChange}
                options={employeeService.currencyList()}
                error={errors.currency}
              />
            </Grid>
          </Grid>
          <p className="profile-title" style={{ marginTop: "25px" }}>
            Password
          </p>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            className="mt-5 password-container"
          >
            <Grid item className="row-label">
              <Grid container alignItems="center">
                <img src={PadlockIcon} width={25} height={25} />
                <span className="ml-3">Password</span>
              </Grid>
            </Grid>
            <Grid item className="row-field">
              <Box display={xsScreen ? "block" : "flex"}>
                <Controls.Input
                  name="password"
                  fullWidth
                  value="•••••••••••"
                  disabled
                  onChange={handleInputChange}
                  error={errors.password}
                  type="password"
                  className="clean-bck-input"
                />
                <Controls.Button
                  text="Change"
                  variant="contained"
                  className="change-password-btn"
                  onClick={() => {
                    setChangePasswordPopup(true);
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box
            display={xsScreen ? "block" : "flex"}
            className={"mt-5 " + (xsScreen ? "text-center" : "")}
            justifyContent="space-between"
          >
            <Controls.Button
              text="Discard Changes"
              className={"btn btn-mui draft " + (xsScreen ? "w-100" : "")}
              color="default"
              disableElevation
              onClick={() => {
                resetForm();
                userDetails();
              }}
            />
            <Controls.Button
              text="Save Changes"
              type="submit"
              className={"btn btn-mui btn-primary " + (xsScreen ? "w-100" : "")}
              variant="contained"
              disableElevation
            />
          </Box>
        </Form>
      </Container>

      <Dialog
        open={changePasswordPopup}
        maxWidth="md"
        classes={{ paper: classes.dialogWrappers }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex" }} className="ModalHeaderTitle">
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              Change Password
            </Typography>
            <Controls.ActionButton
              color="secondary"
              onClick={() => {
                setChangePasswordPopup(false);
              }}
            >
              <CloseIcon />
            </Controls.ActionButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <ChangePassword
            changePasswordPopup={changePasswordPopup}
            setChangePasswordPopup={setChangePasswordPopup}
            setNotify={setNotify}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={changeEmailPopup}
        maxWidth="md"
        classes={{ paper: classes.dialogWrappers }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex" }} className="ModalHeaderTitle">
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              Update Email
            </Typography>
            <Controls.ActionButton
              color="secondary"
              onClick={() => {
                setChangeEmailPopup(false);
              }}
            >
              <CloseIcon />
            </Controls.ActionButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <UpdateEmail
            changeEmailPopup={changeEmailPopup}
            setChangeEmailPopup={setChangeEmailPopup}
            setNotify={setNotify}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={changePhonePopup}
        maxWidth="md"
        classes={{ paper: classes.dialogWrappers }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex" }} className="ModalHeaderTitle">
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              Update Phone
            </Typography>
            <Controls.ActionButton
              color="secondary"
              onClick={() => {
                setChangePhonePopup(false);
              }}
            >
              <CloseIcon />
            </Controls.ActionButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <UpdatePhone
            changePhonePopup={changePhonePopup}
            setChangePhonePopup={setChangePhonePopup}
            setNotify={setNotify}
          />
        </DialogContent>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
  };
};

export default withRouter(connect(mapStateToProps)(MyProfile));
