import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../styles/auction-confirmation-dailog.css";
import Controls from "../../../components/controls/Controls";
import { Box, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

const ConfirmUpdatedBiddingSettings = (props) => {
  const { openConfrmationDailog, setOpenConfrmationDailog, isConfirmed } =
    props;

  const cancelAction = () => {
    setOpenConfrmationDailog(false);
  };

  const confirmAction = () => {
    isConfirmed(true);
    cancelAction();
  };

  return (
    <>
      <Dialog
        open={openConfrmationDailog}
        onClose={cancelAction}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="auction-confirm-dailog-main-div">
          <DialogTitle id="alert-dialog-title" className="text-center">
            You have indicated a bonus/malus for one of selected suppliers.
          </DialogTitle>
          <DialogContent className="text-center">
            <DialogContentText id="alert-dialog-description">
              The advanced parameters will be updated so that the suppliers will
              only see their ranks.
            </DialogContentText>
          </DialogContent>
          <Grid container className="mt-5" justifyContent="space-between">
            <Grid item sm={4} xs={12}>
              <Controls.Button
                text="OK"
                className="btn btn-mui btn-primary-lite w-100"
                variant="contained"
                onClick={confirmAction}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Controls.Button
                text="CANCEL"
                className="btn btn-mui btn-danger w-100"
                variant="contained"
                onClick={cancelAction}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmUpdatedBiddingSettings;
