import React, { useEffect, useState } from "react";
import Controls from "../../../components/controls/Controls";
import Search from "@material-ui/icons/Search";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import AddIcon from "@material-ui/icons/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useWindowDimensions from "../../../components/useWindowDimensions";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import EventFilter from "./filters/EventFilter";
import Loader from "../../../components/Loader";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import "../styles/events.css";
import {
  downloadDocumentFile,
  thousandSepratorHandler,
} from "../../../utility/utils";
import { Api } from "../../../api/Api";
import { GET_BUYER_PROJECT_AUCTION_API } from "../../../constants/ApiUrl";
import {
  AUCTION_RUNNING,
  SUCCESS_BACKGROUND,
  AUCTION_ENDED_CAPITAL,
  AUCTION_ACTIVE,
  AUCTION_PUBLISHED,
  AUCTION_DRAFT,
  AUCTION_CAPITAL_ON_HOLD,
  AUCTION_CANCELLED,
  AUCTION_ARCHIEVED_CAPITAL,
  WARNING_BACKGROUND,
  DANGER_BACKGROUND,
  PRIMARY_BACKGROUND,
  PURPLE_BACKGROUND,
  SECONDARY_BACKGROUND,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../constants/AppConstants.js";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "100%",
    border: "0px solid #ccc",
    [`& fieldset`]: {
      borderRadius: 6,
    },
  },
  selectAction: {
    "& .MuiInputLabel-root": {
      display: "none",
    },
  },
  toolbars: {
    "& .MuiToolbar-gutters": {
      padding: "12px",
      margin: "0px",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  // Forms
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    "& .MuiDialogTitle-root": {
      padding: "1px",
    },
  },
  dialogTitle: {
    paddingRight: "0px",
    fontWeight: "600px",
  },
  dialog: {
    "& .MuiDialogContent-dividers ": {
      borderTop: "0px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "0px solid rgba(0, 0, 0, 0.12)",
    },
  },

  sectionText: {
    fontWeight: "600",
    fontSize: "24px",
  },
  // Header
  root: {
    backgroundColor: "transparent",
  },
  wrapper: {
    borderRadius: "12px",
    padding: "0 12px",
    backgroundColor: "red",
  },
  // .MuiPaper-rounded
  pageHeader: {
    padding: theme.spacing(1),
    display: "flex",
    marginBottom: theme.spacing(0),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#2170ff",
  },
  pname: { color: theme.palette.primary.main, fontWeight: "bold" },
  pdesc: { opacity: "0.5" },
  pageTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  warning: { backgroundColor: theme.palette.primary.warning },
  actionbtnRight: {
    position: "relative",
    top: 10,
    right: 10,
  },
  menupos: {
    // position: "relative",
    // right: "-18px",
  },
  searchBar: {
    width: "100%",
    margin: "1rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  sort: {
    display: "flex",
  },
}));

const columns = [
  {
    label: "ID",
    align: "center",
    // minWidth: 170
  },
  {
    label: "Event",
    // minWidth: 100
  },
  {
    label: "Type",
    // align: 'right'
  },
  {
    label: "Status",
  },
  {
    label: "Open",
  },
  {
    label: "End Date",
  },
  {
    label: "Budget",
  },
  {
    label: "Best Bid/Awarded Bid",
  },
  {
    label: "Best Bidder",
  },
  {
    label: "Savings%",
  },
  {
    label: "Owner",
  },
  {
    label: "",
  },
];

const ProjectEvents = (props) => {
  const { projectDetails, setNotify } = props;
  let history = useHistory();
  const classes = useStyles();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [projectAuctionList, setProjectAuctionList] = useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [fullLoder, setFullLoder] = useState(false);
  const { smScreen } = useWindowDimensions();
  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };

  const numOfPages = Math.ceil(projectAuctionList.length / rowsPerPage);

  const getAllEvents = async (searchText) => {
    setFullLoder(true);
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    const projectId = localStorage.getItem("selectedProjectId");
    if (projectId) {
      const response = await Api(
        `${GET_BUYER_PROJECT_AUCTION_API}/${projectId}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let projectAuc = response.data.auction;
          setProjectAuctionList(projectAuc);
          setTimeout(() => {
            setFullLoder(false);
          }, 200);
        } else {
          setProjectAuctionList([]);
          setFullLoder(false);
        }
      } else {
        setProjectAuctionList([]);
        setFullLoder(false);
      }

      // axiosInstance
      //   .get(`api/buyer/getProjectsAuctionList/${projectId}${queryString}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let projectAuc = response.data.auction;
      //       setProjectAuctionList(projectAuc);
      //       setTimeout(() => {
      //         setFullLoder(false);
      //       }, 200);
      //     } else {
      //       setProjectAuctionList([]);
      //       setFullLoder(false);
      //     }
      //   })
      //   .catch((err) => {
      //     setProjectAuctionList([]);
      //     setFullLoder(false);
      //   });
    } else {
      setProjectAuctionList([]);
      setFullLoder(false);
    }
  };

  const addNewEvent = () => {
    history.push("/auction");
  };

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ENDED_CAPITAL:
        return WARNING_OUTLINE_BACKGROUND;
      case AUCTION_ACTIVE:
        return SUCCESS_BACKGROUND;
      case AUCTION_PUBLISHED:
        return SUCCESS_BACKGROUND;
      case AUCTION_DRAFT:
        return SECONDARY_BACKGROUND;
      case AUCTION_RUNNING:
        return PRIMARY_BACKGROUND;
      case AUCTION_CAPITAL_ON_HOLD:
        return WARNING_BACKGROUND;
      case AUCTION_CANCELLED:
        return DANGER_BACKGROUND;
      case AUCTION_ARCHIEVED_CAPITAL:
        return PURPLE_BACKGROUND;
      default:
        return SUCCESS_BACKGROUND;
    }
  };

  const requestSearch = (e) => {
    const searchText = e.target.value;
    getAllEvents(searchText);
  };

  const editAuction = (auctionId) => {
    if (auctionId) {
      history.push("/auction/" + auctionId + "/details");
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const ExportToExcel = async () => {
    setFullLoder(true);

    let auctionFilter = JSON.parse(localStorage.getItem("projectEventFilter"));

    let string = "?exportToExcel=1";

    const values = auctionFilter;

    if (values) {
      for (let [key, value] of Object.entries(values)) {
        string += `&${key}=${value}`;
      }
    }

    if (
      values &&
      values.dateRange &&
      values.dateRange[0] !== null &&
      values.dateRange[1] !== null
    ) {
      string += `&startDate=${Moment(values.dateRange[0]).format(
        "YYYY-MM-DD 00:00:00"
      )}&endDate=${Moment(values.dateRange[1]).format("YYYY-MM-DD 00:00:00")}`;
    }
    const projectId = localStorage.getItem("selectedProjectId");
    const response = await Api(
      `${GET_BUYER_PROJECT_AUCTION_API}/${projectId}${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        if (!response.data.data) {
          setFullLoder(false);
          return;
        }
        downloadDocumentFile(response?.data?.data?.resourceUrl, "Auctions");

        // const link = document.createElement("a");
        // link.href = response.data.data.resourceUrl;
        // link.target = "_blank";
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      }
      setFullLoder(false);
    } else {
      setFullLoder(false);
    }

    // axiosInstance
    //   .get(`api/buyer/getProjectsAuctionList/${projectId}${string}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       if (!response.data.data) {
    //         setNotify({
    //           isOpen: true,
    //           message: response?.data?.responseDescription,
    //           type: "error",
    //         });
    //         setFullLoder(false);

    //         return;
    //       }
    //       setNotify({
    //         isOpen: true,
    //         message: response?.data?.responseDescription,
    //         type: "success",
    //       });
    //       const link = document.createElement("a");
    //       link.href = response.data.data.resourceUrl;
    //       link.target = "_blank";
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     } else {
    //       setNotify({
    //         isOpen: true,
    //         message: response?.data?.responseDescription,
    //         type: "error",
    //       });
    //     }
    //     setFullLoder(false);
    //   })
    //   .catch((err) => {
    //     setFullLoder(false);
    //     setNotify({
    //       isOpen: true,
    //       message: err?.response?.data?.responseDescription,
    //       type: "error",
    //     });
    //   });
  };

  return (
    <>
      <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
      <Paper elevation={0} square>
        <Grid container alignItems="center">
          <Grid
            item
            md={4}
            xs={12}
            style={{ textAlign: smScreen ? "center" : "start" }}
          >
            <TextField
              onChange={requestSearch}
              placeholder="Search Using Keyword"
              className="select-box select-box-primary search-box"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
            style={{ textAlign: smScreen ? "center" : "end" }}
          >
            <Controls.Button
              variant="contained"
              className={
                "btn-mui btn-white filter-btn " +
                (showFilterCategories ? " active" : "")
              }
              text="Filter"
              onClick={toggleFilterCategory}
              startIcon={<FilterListSharpIcon style={{ color: "#2170FF" }} />}
            />
            <Controls.Button
              text="Export"
              className="btn btn-success btn-mui"
              onClick={() => {
                ExportToExcel();
              }}
            />
            <Controls.Button
              text="Add Auction"
              className="btn-mui"
              variant="contained"
              onClick={() => addNewEvent()}
              startIcon={<AddIcon />}
            />
          </Grid>
        </Grid>
        <Grid container className="filter-box-container">
          {showFilterCategories && (
            <Paper elevation={1} className="filter-box __inner">
              <EventFilter
                setProjectAuctionList={setProjectAuctionList}
                setShowFilterCategories={setShowFilterCategories}
              />
            </Paper>
          )}
        </Grid>
      </Paper>

      <TableContainer className="table-container" component={Paper}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "700" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* minior change - start offestting using (page-1) 
               min value of page is one */}

            {(rowsPerPage > 0
              ? projectAuctionList.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
              : projectAuctionList
            ).map((item) => (
              <TableRow key={item.id}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ textAlign: "center" }}
                >
                  {item?.auctionId}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ color: "#2170FF" }}
                >
                  {item?.auction_name}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <span className="badge badge-info">
                    {item?.auction_type == 1 ? "ERA" : ""}
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    className={"badge badge-" + getStatusLabel(item?.status)}
                  >
                    {item?.status}
                  </span>
                </TableCell>
                <TableCell
                  style={{ color: item.open == 0 ? "red" : "inherit" }}
                >
                  {item?.open}
                </TableCell>
                <TableCell>
                  {Moment(item?.endDate).format("DD-MMM-YYYY hh:mm")}
                </TableCell>
                <TableCell>{thousandSepratorHandler(item?.budget)}</TableCell>
                <TableCell className="best-bid-col">
                  <span>
                    {item && item.bestBid ? (
                      <>
                        {thousandSepratorHandler(item.bestBid.weightedBid)}{" "}
                        <EmojiEventsIcon className="icon" />
                      </>
                    ) : (
                      <span style={{ color: "red" }}>N / A</span>
                    )}
                  </span>
                </TableCell>
                <TableCell>
                  {item && item.bestBid ? (
                    item.bestBid?.supplierId?.company_name
                  ) : (
                    <span style={{ color: "red" }}>N / A</span>
                  )}
                </TableCell>
                <TableCell>{item?.savings || "-"}</TableCell>
                <TableCell>
                  {item?.createdBy?.first_name} {item?.createdBy?.last_name}
                </TableCell>
                <TableCell>
                  <Box>
                    <PopupState variant="popover" popupId="option-list-menu">
                      {(popupState) => (
                        <div>
                          <Button
                            className="action-btn"
                            variant="contained"
                            {...bindTrigger(popupState)}
                          >
                            <MoreVertIcon />
                          </Button>
                          <TableOptionList {...bindMenu(popupState)}>
                            <MenuItem
                              className="bold-line-600"
                              onClick={() => editAuction(item?._id)}
                            >
                              Edit Auction
                            </MenuItem>
                            {/* implement later -- confirm by edouard */}
                            {/* <MenuItem className="bold-line-600">
                              Duplicate
                            </MenuItem> */}
                          </TableOptionList>
                        </div>
                      )}
                    </PopupState>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {projectAuctionList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                  No records found <br />
                  {/* <i style={{ color: "var(---2170ff-p-)" }}>
                    Please add some auction in project
                  </i> */}
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        {/* pagination box  */}
        <TablePaginationBox
          label={projectAuctionList.length + " Events Found"}
          numOfPages={numOfPages}
          onChangePage={(event, newPage) => setPage(newPage)}
          page={page}
        />
        {/* pagiation box  */}
      </TableContainer>
    </>
  );
};

export default ProjectEvents;
