import { Grid } from "@material-ui/core";
import React from "react";
import "../../../Signup/styles/login.css";

const SupplierInvitationShimmer = () => {
  return (
    <Grid container className="form-banner-container">
      <Grid item xs={12} className="invitation-data">
        <div className="w-100">
          <div className="invite-icon-status cu-shimmer thumbnail-center shimmer-thumbnail w100 h100 border-round mb10"></div>
          <div className="main-title cu-shimmer w75 h15 mb10 thumbnail-center"></div>
          <div className="sub-title cu-shimmer w75 h15 mb10 thumbnail-center"></div>
          <div className="sub-title cu-shimmer w75 h15 mb10 thumbnail-center"></div>
        </div>
      </Grid>
    </Grid>
  );
};

export default SupplierInvitationShimmer;
