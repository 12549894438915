import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { store } from "../../store/store";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../components/Notification";
import "../Items/item.css";
import Modal from "@mui/material/Modal";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Search from "@material-ui/icons/Search";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import useWindowDimensions from "../../components/useWindowDimensions";
import { InputLabel } from "@mui/material";
import TablePaginationBox from "../../components/TablePaginationBox";
import TableOptionList from "../../components/TableOptionList";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uploadIcon from "../../assets/icons/Upload@2x.png";
import attachIcon from "../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../assets/icons/CloseX.png";
import {
  ACCEPT_ITEM_ATTACHMENTS,
  ITEM_ATTACHMENTS_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { downloadDocumentFile } from "../../utility/utils";
import { Api } from "../../api/Api";
import {
  COMAPNY_API,
  DELETE_LIBRARY_DOC_API,
  PATCH_LIBRARY_EDIT_DOC_API,
  POST_ADD_LIBRARY_API,
} from "../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";

var columns = [
  {
    label: "Template Document Name",
    minWidth: 270,
  },
  {
    label: "Date Added",
    minWidth: 270,
  },
  // {
  //   label: '',
  // }
];

const initialFValues = {
  title: "",
  notes: "",
};

const Library = (props) => {
  const { setFullLoder, isAdmin, isSeller, isBuyer, currentRole } = props;
  const { xsScreen, smScreen } = useWindowDimensions();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [documentFile, setDocumentFile] = useState({});
  const [docsList, setDocsList] = useState([]);
  
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editAttachmentId, setEditAttachmentId] = React.useState(null);
  const [deleteAttachmentData, setDeleteAttachmentData] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [alertDailog, setAlertDailog] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    resetForm();
    setIsEditMode(false);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";
    // if ("notes" in fieldValues)
    //   temp.notes = fieldValues.notes ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() && isAdmin) {
      setFullLoder(true);
      let formData = new FormData();
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      const userId = auth?.auth?.user?._id;
      formData.append("title", values.title);
      if (values.notes) {
        formData.append("notes", values.notes);
      }
      formData.append("companyId", companyId);
      formData.append("userId", userId);
      if (!isEditMode) {
        if (documentFile && documentFile.name) {
          formData.append("document", documentFile);
          const response = await Api(
            POST_ADD_LIBRARY_API,
            formData,
            "postMultipart"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setOpen(false);
              resetForm();
              getAllDocs();
            }
            setFullLoder(false);
          } else {
            setFullLoder(false);
          }
        } else {
          setFullLoder(false);
          toast.error(ValidationMessage.selectFile, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      } else {
        if (documentFile && documentFile.name) {
          formData.append("document", documentFile);
        }
        if (editAttachmentId) {
          const response = await Api(
            `${PATCH_LIBRARY_EDIT_DOC_API}/${editAttachmentId}`,
            formData,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setOpen(false);
              resetForm();
              setIsEditMode(false);
              setEditAttachmentId(null);
              getAllDocs();
            }
            setFullLoder(false);
          } else {
            setFullLoder(false);
          }
        } else {
          setFullLoder(false);
          toast.error(ValidationMessage.somethingWrong, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      }
    }
  };

  const numOfPages = Math.ceil(docsList.length / rowsPerPage);

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const selectedDocRemove = () => {
    setDocumentFile({});
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const selected = e.dataTransfer.files[0];
    handleFileUpload(selected);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileUpload = async (selected) => {
    const ALLOWED_TYPES = ACCEPT_ITEM_ATTACHMENTS;
    if (selected && !ALLOWED_TYPES.includes(selected.type)) {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (selected && Math.round(selected.size / 1024) > ITEM_ATTACHMENTS_SIZE) {
      toast.error(ValidationMessage.itemAttachmentFileSize, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setDocumentFile(selected);
    }
  };

  const getAllDocs = async (searchText) => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;

    if (companyId) {
      setFullLoder(true);
      let path = `${COMAPNY_API}/${companyId}/library`;
      if (searchText && searchText.length > 0) path += `?search=${searchText}`;
      const response = await Api(path, {}, "get");
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allDocs = response.data.library;
          setDocsList(allDocs);
        } else {
          setDocsList([]);
        }
        setFullLoder(false);
      } else {
        setDocsList([]);
        setFullLoder(false);
      }
    }
  };

  const editAttchament = (attachment) => {
    if (isAdmin) {
      setValues({
        title: attachment.title,
        notes: attachment.notes,
      });
      setIsEditMode(true);
      setEditAttachmentId(attachment._id);
      setOpen(true);
      setDocumentFile(attachment.document);
    }
  };

  const deleteDocument = async (attachment) => {
    if (attachment && isAdmin) {
      setAlertDailog(true);
      setDeleteAttachmentData(attachment);
    }
  };

  const confirmDeleteAttachment = async () => {
    if (deleteAttachmentData && isAdmin) {
      setFullLoder(true);
      const response = await Api(
        `${DELETE_LIBRARY_DOC_API}/${deleteAttachmentData._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        setFullLoder(false);
        setAlertDailog(false);
        setDeleteAttachmentData({});
        if (response.data.responseCode === "00") {
          const newDocsList = docsList.filter(
            (item) => item._id !== deleteAttachmentData._id
          );

          setDocsList(newDocsList);
          const numOfPages = Math.ceil(newDocsList.length / rowsPerPage);
          if (page > numOfPages) {
            setPage(numOfPages);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (docsList.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [docsList, page]);

  const requestSearch = (searchedVal) => {
    const textVal = searchedVal.target.value;
    getAllDocs(textVal);
  };

  const addNewDocs = () => {
    setDocumentFile({});
    setOpen(true);
  };

  useEffect(() => {
    if (isAdmin) {
      columns.push({
        label: "",
      });
    }
    getAllDocs();
  }, []);

  return (
    <>
      <p className="profile-title">Library</p>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          style={{ textAlign: xsScreen ? "center" : "start" }}
        >
          <TextField
            onChange={requestSearch}
            placeholder="Search Using Keyword or Tags..."
            className="select-box select-box-primary search-box"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {!isAdmin ? (
          <></>
        ) : (
          <Grid
            item
            sm={6}
            xs={12}
            style={{ textAlign: xsScreen ? "center" : "end" }}
          >
            <Button
              variant="contained"
              className="setting-button"
              onClick={() => addNewDocs()}
            >
              <AddOutlinedIcon
                style={{ fontSize: "21px", margin: "0 4px 0 0" }}
              />
              <span style={{ textTransform: "capitalize" }}>Add Document</span>
            </Button>
          </Grid>
        )}
      </Grid>
      <TableContainer className="table-container mt-5" component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "700" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? docsList.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
              : docsList
            ).map((item) => (
              <TableRow key={item._id}>
                <TableCell>
                  <AssignmentOutlinedIcon />
                  <span style={{ color: "#2170FF" }} className="TextAfterImage">
                    {/* <a
                      href={item?.document?.fileName}
                      download={item?.document?.fileName}
                    >
                      {item && item.document ? item?.document?.name : ""}
                    </a> */}
                    <span
                      className="library-doc"
                      onClick={() =>
                        downloadDocumentFile(
                          item?.document?.fileName,
                          item?.title
                        )
                      }
                      download={item?.document?.fileName}
                    >
                      {item && item.title ? item?.title : ""}
                    </span>
                  </span>
                </TableCell>
                <TableCell>
                  {Moment(item.createdDate).format("DD/MM/YYYY LT")}
                </TableCell>
                {!isAdmin ? (
                  <></>
                ) : (
                  <TableCell>
                    <Box>
                      <PopupState variant="popover" popupId="option-list-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem
                                className="bold-line-600"
                                onClick={() => editAttchament(item)}
                              >
                                Edit Item
                              </MenuItem>
                              <MenuItem
                                className="bold-line-600 clr-ff0000"
                                onClick={() => deleteDocument(item)}
                              >
                                Delete
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {docsList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                  No records found <br />
                  {/* <i style={{ color: "var(---2170ff-p-)" }}>
                    Please add some document
                  </i> */}
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePaginationBox
          label={docsList.length + " documents Found"}
          numOfPages={numOfPages}
          onChangePage={(event, newPage) => setPage(newPage)}
          page={page}
        />
      </TableContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-label="create-item-second-step-buyer-aspect-upload-docs-file"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Add Document</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon onClick={() => handleClose()} className="cursor-p" />
            </Grid>
          </Grid>
          <Form onSubmit={handleSubmit}>
            <div className="container mb-4">
              <div
                className="image-uploader-container"
                onDrop={(e) => handleDrop(e)}
                onDragOver={(e) => handleDragOver(e)}
              >
                {documentFile && (
                  <>
                    <div className="uploader-header">
                      <img src={uploadIcon} className="upload-icon" alt="" />
                      <p className="upload-title">Drag or drop files here</p>
                      <p className="upload-description">
                        Max. file size is 10mb
                      </p>
                      <p className="upload-description">
                        and files must be .pdf, .exl, .png, or .jpg
                      </p>
                      <label htmlFor="fileUploadButton">
                        <Controls.Button
                          component="span"
                          text="Choose File"
                          disableElevation
                          // htmlFor="fileUpload1"
                          className="mui-btn upload-btn"
                        />
                      </label>
                      <input
                        type="file"
                        id="fileUploadButton"
                        hidden
                        // onChange={(e) => {
                        //   handleFileUpload(e);
                        // }}
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                        // accept={ACCEPT_ITEM_ATTACHMENTS}
                      />
                    </div>
                    <label
                      htmlFor="fileUploadButton"
                      className="customeFileUpload"
                      style={{
                        position: "absolute",
                        padding: "47px 57px",
                        cursor: "pointer",
                      }}
                    >
                      &nbsp;
                    </label>
                    <input
                      type="file"
                      id="fileUploadButton"
                      hidden
                      // onChange={(e) => {
                      //   handleFileUpload(e);
                      // }}
                      onChange={(e) => handleFileUpload(e.target.files[0])}
                      value={values.image}
                      // accept={ACCEPT_ITEM_ATTACHMENTS}
                    />
                    {/* <span>( jpg, jpeg or png)</span> */}
                  </>
                )}
              </div>
            </div>

            {documentFile && (documentFile.name || documentFile.fileName) ? (
              <Grid item xs={12} style={{ alignItems: "flex-end" }}>
                <Box component="div" className="uploaded-files">
                  <Grid container>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      <img src={attachIcon} alt="" className="icon" />
                    </Grid>
                    <Grid item xs={9} className="details">
                      <Grid container>
                        <Grid item xs={12}>
                          <box className="name">
                            {documentFile?.name || documentFile?.fileName}
                          </box>
                        </Grid>
                        <Grid item xs={12}>
                          <box className="size">
                            {bytesToSize(documentFile?.size || 20000)}
                          </box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{ textAlign: "end", margin: "auto" }}
                      onClick={() => selectedDocRemove()}
                    >
                      <img src={closeIcon} alt="" />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              ""
            )}

            <Box className="create-form-container mt-3 q">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel id="title-input">Title</InputLabel>
                  <Controls.Input
                    // className="mui-input"
                    fullWidth
                    // labelId="title-input"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                    error={errors.title}
                    inputProps={{ maxLength: 40 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="notes-input">Notes</InputLabel>
                  <Controls.Input
                    className="mui-input fix-h-8em"
                    labelId="notes-input"
                    name="notes"
                    fullWidth
                    value={values.notes}
                    onChange={handleInputChange}
                    // error={errors.notes}
                    inputProps={{ maxLength: 499 }}
                  />
                </Grid>
              </Grid>
            </Box>

            <div className="bottom-button-box-container">
              <div className="left-side">
                <Controls.Button
                  variant="contained"
                  disableElevation
                  className="btn-mui w-100"
                  onClick={() => handleClose()}
                  text="Cancel"
                  style={{
                    backgroundColor: "#E2F2FF",
                    color: "#2170FF",
                    marginRight: "2.5rem",
                  }}
                />
              </div>
              <div className="right-side">
                <div>
                  <Controls.Button
                    variant="contained"
                    disableElevation
                    className="btn-mui w-100"
                    type="submit"
                    text="Attach"
                  />
                </div>
              </div>
            </div>
          </Form>
        </Box>
      </Modal>

      <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete attachment?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setAlertDailog(false)}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={() => confirmDeleteAttachment()}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
  };
};

export default withRouter(connect(mapStateToProps)(Library));
