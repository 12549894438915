import { Box, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormControlLabel, Modal, SwitchUnstyled } from "@mui/material";
import useWindowDimensions from "../../../../../components/useWindowDimensions";
import Controls from "../../../../../components/controls/Controls";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCurrentAuction } from "../../../../../store/selectors/supplierAspect/auctionSelector";
import { currentUser } from "../../../../../store/selectors/AuthSelectors";
import GetAuctionBidsBL from "../../BL/GetAuctionBidsBL";

const BidListAllModal = (props) => {
  const {
    openPopup,
    setOpenPopup,
    currentAuction,
    currentUser,
    isMyBidModeInitVal,
  } = props;
  // --- my bid ---
  const [myBidList, setMyBidList] = useState([]);
  const [myBidLimit, setMyBidLimit] = useState(0);
  const [columnMine, setColumnMine] = useState([]);
  const [columnAll, setColumnAll] = useState([]);
  // --- all bid ---
  const [allBidList, setAllBidList] = useState([]);
  const [allBidLimit, setAllBidLimit] = useState(0);

  // const [tableColumns, setTableColumns] = useState([]);
  const [tableLabel, setTableLabel] = useState("");
  const [resendApiMine, setResendApiMine] = useState(false);
  const [resendApiAll, setResendApiAll] = useState(false);
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  // --- my bid (APIs & Config)---

  useEffect(() => {
    if (resendApiMine == true) {
      //
      GetAuctionBidsBL({
        currentAuction: currentAuction,
        myBidPage: 1,
        myBidRowsPerPage: -1, // unlimited !
        mine: true,
      }).then((data) => {
        //
        setMyBidList(data.list);
        setMyBidLimit(data.limit);
        setColumnMine(data.columns);
        setTableLabel(data.label);
        setResendApiMine(false);
      });
    }
  }, [resendApiMine]);

  const getRowDataForMine = () => {
    let rows = [];
    for (let numOfRow = 0; numOfRow < myBidList.length; numOfRow++) {
      let data = [];
      for (let numOfCol = 1; numOfCol <= columnMine.length; numOfCol++) {
        data.push(<TableCell>{myBidList[numOfRow][numOfCol]}</TableCell>);
      }
      rows.push(<TableRow key={myBidList[numOfRow][0]}>{data}</TableRow>);
    }

    return rows;
  };

  // --- All bid (APIs & Config)---

  useEffect(() => {
    if (resendApiAll == true) {
      //
      GetAuctionBidsBL({
        currentAuction: currentAuction,
        allBidPage: 1,
        allBidRowsPerPage: -1,
        mine: false,
      }).then((data) => {
        //
        setAllBidList(data.list);
        setAllBidLimit(data.limit);
        setColumnAll(data.columns);
        setTableLabel(data.label);
        setResendApiAll(false);
      });
    }
  }, [resendApiAll]);

  useEffect(() => {
    if (
      currentAuction._id !== undefined &&
      currentUser._id !== undefined &&
      openPopup == true
    ) {
      //
      setResendApiMine(true);
      setResendApiAll(true);
    }
  }, [currentAuction, currentUser, openPopup]);

  const getRowDataForAll = () => {
    let rows = [];
    for (let numOfRow = 0; numOfRow < allBidList.length; numOfRow++) {
      let data = [];
      for (let numOfCol = 1; numOfCol <= columnAll.length; numOfCol++) {
        data.push(<TableCell>{allBidList[numOfRow][numOfCol]}</TableCell>);
      }
      rows.push(<TableRow key={allBidList[numOfRow][0]}>{data}</TableRow>);
    }

    return rows;
  };

  const [isMyBidMode, setIsMyBidMode] = useState(isMyBidModeInitVal);

  return (
    <Modal
      open={openPopup}
      aria-label="auction-details-bid-popup"
      aria-describedby="modal-modal-description"
    >
      <Box className="popup-form-container supp-auction-details-bid-list-container">
        <Grid container>
          <Grid
            item
            sm={4}
            xs={12}
            style={{ textAlign: smScreen ? "center" : "start" }}
          >
            <div className="header-title">{tableLabel}</div>
          </Grid>
          <Grid item sm={8} xs={12} className="options-contaier">
            <div>
              <span className="my-bid">My Bid</span>
              <FormControlLabel
                control={
                  <SwitchUnstyled
                    name="is_admin"
                    value={isMyBidMode}
                    onChange={() => setIsMyBidMode(!isMyBidMode)}
                    className="switchable-toggle"
                  />
                }
                label=""
              />
              <span className="all-bid">All Bid</span>
            </div>
          </Grid>
        </Grid>

        <div style={{ position: "relative" }}>
          {/* ---- my bid  */}
          <TableContainer
            className={
              "table-container mybid-table " + (isMyBidMode ? "__active" : "")
            }
            component={Paper}
          >
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columnMine.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "700" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getRowDataForMine()}
                {getRowDataForMine().length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={columnMine.length}
                      className="text-center"
                    >
                      No records found <br />
                      {/* <i style={{ color: "var(---2170ff-p-)" }}>
                        Please add some bid
                      </i> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* ---- all bid  */}
          <TableContainer
            className={
              "table-container allbid-table " + (!isMyBidMode ? "__active" : "")
            }
            component={Paper}
          >
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columnAll.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "700" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getRowDataForAll()}
                {getRowDataForAll().length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={columnAll.length}
                      className="text-center"
                    >
                      No records found <br />
                      {/* <i style={{ color: "var(---2170ff-p-)" }}>
                        Please add some bid
                      </i> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Box>
          <Grid container justifyContent="center">
            <Grid item sm={4} xs={8}>
              <Controls.Button
                text="Done"
                disableElevation
                onClick={() => setOpenPopup(false)}
                color="default"
                className="btn btn-primary btn-mui w-100 mt-5"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuction: getCurrentAuction(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(BidListAllModal));
