import { useEffect, useState } from "react";
import CollapsedPage from "../../../../components/collapsedPage";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import { Box, Grid, TextField } from "@material-ui/core";
import userImage from "../../../../assets/auth/user_icon_2.png";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  BUYER,
  DANGER_BACKGROUND,
  ITEM_ATTACHMENTS_SIZE,
  PURPLE_BACKGROUND,
  RFX_MESSAGE_CLOSE,
  RFX_MESSAGE_OPEN,
  SUCCESS_BACKGROUND,
  TOAST_AUTO_CLOSE,
} from "../../../../constants/AppConstants";
import { Slide, toast } from "react-toastify";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import attachIcon from "../../../../assets/icons/attach_file_black_24dp@2x.png";
import { bytesToSize, getTimeDiffernce } from "../../../../utility/utils";
import closeIcon from "../../../../assets/icons/CloseX.png";
import { Api } from "../../../../api/Api";
import {
  POST_BUYER_CHAT_API,
  POST_RFX_MESSAGE_CHANGE_API,
  POST_SELLER_CHAT_API,
  RFX_API,
  SELLER_RFX_API,
} from "../../../../constants/ApiUrl";
import { useSelector } from "react-redux";
import { store } from "../../../../store/store";
import { getRoleFromLocalStorage } from "../../../../utility/utils";
import moment from "moment";
import { downloadDocumentFile } from "../../../../utility/utils";
import CommonButton from "../../../../components/controls/CommonButton";
import { Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ChatSkeleton from "./ChatSkeleton";

const RfxMessageDrawer = ({
  showMessageSidebar,
  setShowMessageSidebar,
  getMessageList,
  fromSeller,
  messageId,
  messageData,
  setMessageData,
}) => {
  const rfxSupplierDetails = useSelector((state) => state.supplierRfx.rfx);
  const rfxBuyerDetails = useSelector((state) => state.rfx.liveRfx);
  const { auth } = store.getState();
  const loggedUserId = auth?.auth?.user?._id;
  const companyId = auth?.auth?.user?.company?._id;

  const [chatText, setTextChat] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [role, setRole] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  useEffect(() => {
    getChatHistory();
    const role = getRoleFromLocalStorage();
    setRole(role);
  }, []);

  const getBodyWidth = () => {
    if (xlScreen) {
      return 40;
    } else if (xsScreen) {
      return 80;
    } else if (smScreen) {
      return 80;
    } else if (mdScreen) {
      return 70;
    } else if (lgScreen) {
      return 50;
    }
  };
  const handleFileChange = (e) => {
    let temp = [...document];
    for (let i = 0; i < e.target.files.length; ++i) {
      if (
        e.target.files[i] &&
        Math.round(e.target.files[i].size / 1024) > ITEM_ATTACHMENTS_SIZE
      ) {
        toast.error(ValidationMessage.itemAttachmentFileSize, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
      temp.push(e.target.files[i]);
    }
    setDocument([...temp]);
  };

  const removeAttachedDocument = (file, index) => {
    const temp = [...document];
    const response = temp.filter((item, i) => i !== index);
    setDocument([...response]);
  };

  const getChatHistory = async () => {
    setIsLoading(true);
    const companyId = auth?.auth?.user?.company?._id;
    const rfxId = fromSeller ? rfxSupplierDetails?._id : rfxBuyerDetails?._id;
    const path = fromSeller
      ? `${SELLER_RFX_API}/${rfxId}/conversation/get/${companyId}/${messageId}`
      : `${RFX_API}/${rfxId}/conversation/get/${companyId}/${messageId}`;
    const response = await Api(path, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setChatData([...response?.data?.rfxConversation]);
      }
    }
    setIsLoading(false);
  };

  const sendMessageHandler = async () => {
    const userId = auth?.auth?.user?._id;
    const rfxId = fromSeller ? rfxSupplierDetails?._id : rfxBuyerDetails?._id;

    const formData = new FormData();
    formData.append("rfxId", rfxId);
    formData.append("messageId", messageId);
    formData.append("message", chatText);
    formData.append("companyId", companyId);
    formData.append("userId", userId);
    if (document && document.length > 0) {
      for (let i = 0; i < document.length; i++) {
        formData.append(`attachments[${i}]`, document[i]);
      }
    }

    const path = fromSeller ? POST_SELLER_CHAT_API : POST_BUYER_CHAT_API;
    setShowLoader(true);
    const response = await Api(path, formData, "postMultipart", false);
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setTextChat("");
        setDocument([]);
        getChatHistory();
      }
    }
    setShowLoader(false);
  };

  const getStatusChange = async (status, item) => {
    const payload = {
      rfxId: rfxBuyerDetails?._id,
      messageId: item?._id,
      status: status, //Open , Closed
      companyId,
    };
    if (item?._id) {
      const response = await Api(POST_RFX_MESSAGE_CHANGE_API, payload, "post");
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setMessageData({ ...response.data.rfxMessage });
          getMessageList();
        }
      }
    }
  };

  const getStatusLabel = (type) => {
    switch (type) {
      case RFX_MESSAGE_OPEN:
        return SUCCESS_BACKGROUND;
      case RFX_MESSAGE_CLOSE:
        return DANGER_BACKGROUND;
      default:
        return PURPLE_BACKGROUND;
    }
  };
  return (
    <>
      <CollapsedPage
        openPopup={showMessageSidebar}
        setOpenPopup={setShowMessageSidebar}
        bodyWidth={getBodyWidth()}
        fromRfx={true}
      >
        <Box container className="common-slider chat-box">
          <div className="chat-header">
            <div className="chat-head-body global-flex-center justify-content-between">
              <p className="common-title simple-word-break">
                {messageData?.subject}
              </p>
              {!fromSeller && (
                <div className="mr-5">
                  {messageData?.status === RFX_MESSAGE_OPEN ? (
                    <PopupState variant="popover" popupId="demoPopover">
                      {(popupState) => (
                        <div>
                          <Button
                            className={
                              "badge badge-" +
                              getStatusLabel(messageData?.status) +
                              " status"
                            }
                            variant="contained"
                            {...bindTrigger(popupState)}
                            endIcon={<ArrowDropDownIcon />}
                          >
                            {messageData?.status}
                          </Button>

                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <div className="px-3 py-2 ">
                              <div>
                                <button
                                  className="badge badge-success select-colored-option"
                                  onClick={() => {
                                    popupState.close();
                                    getStatusChange(
                                      RFX_MESSAGE_OPEN,
                                      messageData
                                    );
                                  }}
                                >
                                  {RFX_MESSAGE_OPEN}
                                </button>
                              </div>
                              <div>
                                <button
                                  className="badge badge-danger select-colored-option"
                                  onClick={() => {
                                    popupState.close();
                                    getStatusChange(
                                      RFX_MESSAGE_CLOSE,
                                      messageData
                                    );
                                  }}
                                >
                                  {RFX_MESSAGE_CLOSE}
                                </button>
                              </div>
                            </div>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  ) : (
                    <Button
                      className={
                        "badge badge-" +
                        getStatusLabel(messageData?.status) +
                        " status"
                      }
                      variant="contained"
                    >
                      {messageData?.status}
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="divider-title mt-3"></div>
          </div>
          <div div className="chat-body" style={{ position: "relative" }}>
            {isLoading ? (
              <ChatSkeleton />
            ) : (
              chatData &&
              chatData.length > 0 &&
              chatData.map((item, index) => {
                const rightSide = role.startsWith(
                  item?.messageFrom.toLowerCase()
                );
                const userData = {
                  image:
                    item?.buyerUserId?.image_upload ||
                    item?.supplierUserId?.image_upload ||
                    userImage,
                  first_name:
                    item?.buyerUserId?.first_name ||
                    item?.supplierUserId?.first_name,
                  last_name:
                    item?.buyerUserId?.last_name ||
                    item?.supplierUserId?.last_name,
                };
                const showUser =
                  item?.messageFrom === BUYER
                    ? item?.buyerUserId?._id !==
                      chatData[index - 1]?.buyerUserId?._id
                    : item?.sellerUserId?._id !==
                      chatData[index - 1]?.sellerUserId?._id;
                const diffDays = getTimeDiffernce(
                  item?.createdDate,
                  new Date()
                );
                return (
                  <div className="messages-list w-100 mt-2">
                    <div className="common-chat">
                      {(showUser || index === 0) && (
                        <div
                          className={`d-flex align-item-center mb-2 ${
                            rightSide ? "justify-content-end" : ""
                          }`}
                        >
                          <img
                            className="buyer-chat-profile"
                            src={userData?.image}
                            alt=""
                          />
                          <p className="text-bold">
                            {userData?.first_name} {userData?.last_name}
                          </p>
                        </div>
                      )}
                      <div>
                        {item?.attachments &&
                          item?.attachments.length > 0 &&
                          item?.attachments.map((obj) => (
                            <Box
                              component="div"
                              className={`uploaded-files chat-file d-flex align-item-center mb-3 ${
                                rightSide ? "ml-auto" : ""
                              }`}
                              onClick={() =>
                                downloadDocumentFile(obj?.fileName, "sample")
                              }
                            >
                              <div>
                                <img
                                  src={attachIcon}
                                  className="icon icon-chat-attach-list mr-2"
                                  alt=""
                                />
                              </div>
                              <div className="details">
                                <p>{obj?.name}</p>
                              </div>
                            </Box>
                          ))}
                      </div>
                      <div
                        className={`buyer-chat ${rightSide ? "ml-auto" : ""}`}
                      >
                        {item?.message && item?.message.length > 0 && (
                          <p
                            className={`mui-chat-card ${
                              rightSide
                                ? "bg-grey text-dark"
                                : "bg-primary text-white"
                            }`}
                          >
                            {item?.message}
                          </p>
                        )}
                        <p
                          className={`mt-2 chat-time ${
                            rightSide ? "text-end" : ""
                          }`}
                        >
                          {diffDays > 0
                            ? `${diffDays} day ago`
                            : moment(item?.createdDate).format("LT")}

                          {/* 1 day ago */}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="chat-footer">
            {document && document.length > 0 && (
              <Grid container className="document-list w-100" spacing={2}>
                {document.map((file, index) => (
                  <Grid item md={4} sm={6} xs={12}>
                    <Box component="div" className="uploaded-files">
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          <img
                            src={attachIcon}
                            className="icon icon-chat-attach-list"
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={9} className="details">
                          <Grid container>
                            <Grid item xs={12}>
                              <box className="name">{file?.name}</box>
                            </Grid>
                            {(file?.name || file?.document?.name) && (
                              <Grid item xs={12}>
                                <box className="size">
                                  {bytesToSize(file?.size || 0)}
                                </box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className="cursor-p"
                          style={{ textAlign: "end", margin: "auto" }}
                          onClick={() => removeAttachedDocument(file, index)}
                        >
                          <img src={closeIcon} alt="" />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
            <div className="global-flex-center g-10">
              <div className="common-textarea-input w-100">
                <TextField
                  variant="outlined"
                  placeholder="Type here..."
                  fullWidth
                  value={chatText}
                  onChange={(e) => setTextChat(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <label className="cursor-p" for="chat-attachment">
                        <AttachFileIcon className="attachment-icon" />
                        <input
                          type="file"
                          id="chat-attachment"
                          name="chat-attachment"
                          hidden
                          onChange={handleFileChange}
                          multiple
                        />
                      </label>
                    ),
                  }}
                  type="text"
                  multiline={true}
                  maxRows={chatText.length === 0 ? 1 : 4}
                  minRows={1}
                />
                <div></div>
                <input
                  type="file"
                  id="chat-attachment"
                  name="chat-attachment"
                  hidden
                  onChange={handleFileChange}
                  multiple
                />
              </div>

              <CommonButton
                type="button"
                disableElevation
                text="Send"
                className="btn-mui m-0"
                onClick={() => sendMessageHandler()}
                disabled={chatText.length === 0 && document.length === 0}
                loader={showLoader}
              />
            </div>
          </div>
        </Box>
      </CollapsedPage>
    </>
  );
};

export default RfxMessageDrawer;
