import { useState, useEffect } from "react";
import { Paper, Grid, Tooltip } from "@material-ui/core";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Form, useForm } from "../../../components/useForm";
import { connect } from "react-redux";
import { HtmlUtilService } from "../../../services/HtmlUtilService";
import useWindowDimensions from "../../../components/useWindowDimensions";
import * as employeeService from "../../../services/employeeService";
import { useHistory } from "react-router-dom";
import { store } from "../../../store/store";
import "../styles/details-banner.css";
import { withRouter } from "react-router-dom";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import Controls from "../../../components/controls/Controls";
import {
  ACCEPT_ONLY_IMAGE,
  AUCTION_ACTIVE,
  AUCTION_ACTIVE_SMALL,
  AUCTION_ARCHIEVED,
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_COMPLETED,
  AUCTION_COMPLETED_SMALL,
  AUCTION_DRAFT,
  AUCTION_DRAFT_SMALL,
  AUCTION_ON_HOLD,
  ITEM_ATTACHMENTS_SIZE,
  PRIMARY_OUTLINE_BACKGROUND,
  PURPLE_BACKGROUND,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  TOAST_AUTO_CLOSE,
} from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { Api } from "../../../api/Api";
import {
  GET_BUISNESS_UNIT_API,
  ONLY_COMAPNY_API,
  POST_EDIT_PROJECT_API,
} from "../../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";
import moment from "moment";

const initialFValues = {
  name: "",
  description: "",
  location: "",
  unit: "",
  manager: "",
  department: "",
  owner: "",
  currency: "",
};

const ProjectBanner = (props) => {
  const history = useHistory();
  const { selectedProject, setNotify, currentUser } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [editMode, setEditMode] = useState(false);
  const [projectStatus, setProjectStatus] = useState("");
  const [imgPreview, setImgPreview] = useState(null);
  const [img, setImg] = useState("");
  const [imgFile, setImgFile] = useState(null);
  const { lgScreen, mdScreen, smScreen } = useWindowDimensions();
  const currencyData = employeeService.currencyList();
  const [businessUnitList, setBuisnessUnitList] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const { auth } = store.getState();
  const loginUserId = auth?.auth?.user?._id;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues) {
      if (fieldValues.name && fieldValues.name.length > 0) {
        if (fieldValues.name.length > 25) {
          temp.name = "Project name must not exceed 25 characters";
        } else {
          temp.name = "";
        }
      } else {
        temp.name = "Project Name is required.";
      }
    }
    if ("description" in fieldValues) {
      if (fieldValues.description && fieldValues.description.length > 0) {
        if (fieldValues.description.length > 80) {
          temp.description = "Description must not exceed 80 characters";
        } else {
          temp.description = "";
        }
      } else {
        temp.description = "Description is required.";
      }
    }

    if ("location" in fieldValues)
      temp.location = fieldValues.location ? "" : "Location is required.";
    // if ("unit" in fieldValues)
    //   temp.unit = fieldValues.unit ? "" : "unit is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const [isLoaded, setIsLoaded] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let dateValid = dateValidationHandler(startDate, endDate);
    if (!dateValid) return;
    if (validate()) {
      if (!editMode) {
        let formData = new FormData();
        // const payload = {z
        //   project_name: values.name,
        //   location: values.location,
        //   description: values.description,
        //   project_manager: values.manager,
        //   unit: values.unit,
        //   createdBy: values.owner,
        //   department: values.department,
        //   currency: values.currency,
        //   project_status: projectStatus || selectedProject?.project_status,
        //   image: img,
        //   startDate: startDate,
        //   endDate: endDate,
        // };
        formData.append("project_name", values.name);
        formData.append("location", values.location);
        formData.append("description", values.description);
        formData.append("project_manager", values.manager);
        formData.append("unit", values.unit);
        formData.append("createdBy", values.owner);
        formData.append("department", values.department);
        formData.append("currency", values.currency);
        formData.append(
          "project_status",
          projectStatus || selectedProject?.project_status
        );
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);
        if (imgFile && imgFile.type) {
          formData.append("document", imgFile);
        } else {
          formData.append("image", img);
        }
        if (selectedProject?._id) {
          const { auth } = store.getState();
          const userId = auth?.auth?.user?._id;
          const response = await Api(
            `${POST_EDIT_PROJECT_API}/${selectedProject?._id}/${userId}`,
            formData,
            "patch"
          );
          if (response.status === 200) {
            setImgFile(null);
            if (response.data.responseCode === "00") {
              setImgPreview(response?.data?.project?.image);
              setImg(response?.data?.project?.image);
            }
          }

          // await axiosInstance
          //   .patch(
          //     `api/buyer/editProject/${selectedProject?._id}/${userId}`,
          //     payload
          //   )
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setImgPreview(response?.data?.project?.image);
          //       setImg(response?.data?.project?.image);
          //       // image
          //       setNotify({
          //         isOpen: true,
          //         message: "Details updated successfully.",
          //         type: "success",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //   });
        }
      }
    }
  };

  const getStatusChange = async (status) => {
    const payload = {
      project_status: status,
    };
    const { auth } = store.getState();
    const userId = auth?.auth?.user?._id;
    if (selectedProject?._id) {
      const response = await Api(
        `${POST_EDIT_PROJECT_API}/${selectedProject?._id}/${userId}`,
        payload,
        "patch"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setProjectStatus(status);
        }
      }
    }
  };

  const clickToggleBtn = (event) => {
    let dateValid = dateValidationHandler(startDate, endDate);
    if (!dateValid) {
      toast.error(ValidationMessage.endDateError, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (!validate()) {
      return;
    }
    setEditMode(!editMode);
    const descriptionView = document.querySelector("#description__view");
    const descriptionEdit = document.querySelector("#description__edit");

    if (editMode == true) {
      descriptionView.style.display = "block";
      descriptionEdit.style.display = "none";
    } else {
      descriptionView.style.display = "none";
      descriptionEdit.style.display = "block";
    }
  };

  const goBackToList = () => {
    history.push("/projects");
  };

  const getBusinessUnit = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_BUISNESS_UNIT_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let businessUnit = response.data.businessUnits;
          setBuisnessUnitList(businessUnit);
        }
      } else {
      }
    }
  };

  const companyUserList = async () => {
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;
    const response = await Api(
      `${ONLY_COMAPNY_API}/${companyId}/companyUserList`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allMembers = response.data.companyTeamMembers;
        setCompanyUsers(allMembers);
      }
    } else {
      setCompanyUsers([]);
    }
  };

  useEffect(() => {
    setValues({
      name: selectedProject?.project_name,
      description: selectedProject?.description,
      location: selectedProject?.location,
      unit: selectedProject?.business_unit,
      manager: selectedProject?.project_manager,
      department: selectedProject?.department,
      // owner: selectedProject?.companyId?.company_name,
      owner: selectedProject?.createdBy,
      currency: selectedProject?.currency,
    });

    setStartDate(new Date(selectedProject?.startDate));
    setEndDate(new Date(selectedProject?.endDate));
    if (selectedProject && selectedProject.image) {
      setImgPreview(selectedProject.image);
      setImg(selectedProject.image);
    }
    getBusinessUnit();
    companyUserList();
  }, [selectedProject]);

  useEffect(() => {
    if (values.description !== undefined && values.description !== "") {
      setIsLoaded(true);
      HtmlUtilService.truncate();
    }
  }, [values]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && !ACCEPT_ONLY_IMAGE.includes(file.type)) {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (file && Math.round(file.size / 1024) > ITEM_ATTACHMENTS_SIZE) {
      toast.error(ValidationMessage.itemAttachmentFileSize, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (file) {
      setImgFile(file);

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        // set the state to the base64string
        setImgPreview(base64String);
        setImg(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case AUCTION_ACTIVE_SMALL:
        return SUCCESS_BACKGROUND;
      case AUCTION_COMPLETED_SMALL:
        return PRIMARY_OUTLINE_BACKGROUND;
      case AUCTION_ARCHIEVED:
        return PURPLE_BACKGROUND;
      case AUCTION_ON_HOLD:
        return SECONDARY_BACKGROUND;
      default:
        return SECONDARY_BACKGROUND;
    }
  };

  const dateValidationHandler = (start, end) => {
    const tempStartDate = moment(start).startOf("day").valueOf();
    const tempEndDate = moment(end).endOf("day").valueOf();

    if (start && end) {
      if (tempStartDate > tempEndDate) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="details-banner buyer-aspect">
        <img
          className="banner-cover"
          // src={img}
          alt=""
        />
        <div className="banner-container">
          {/* background image  */}
          <Grid
            container
            style={{
              position: "relative",
            }}
            spacing={1}
          >
            <Grid item xs={12}>
              <h2 className="project-title">
                {selectedProject && selectedProject.project_name
                  ? selectedProject.project_name
                  : "Test"}
              </h2>

              <div className="project-title__smal">
                <span> Projects </span>
                <span>
                  {selectedProject && selectedProject.project_name
                    ? " / " + selectedProject?.project_name
                    : "Test"}
                </span>
              </div>
            </Grid>
          </Grid>
          {/* background image  */}

          {/* Goback Button  */}
          <Grid item xs={12}>
            <button
              className="btn btn-primary go-back-button"
              onClick={() => goBackToList()}
            >
              <span style={{ color: "var(---2170ff-p-)" }}>
                <ArrowBackIosNewIcon /> Go Back
              </span>
            </button>
          </Grid>
          {/* Goback Button  */}
          {/* <Form onSubmit={handleSubmit}> */}
          <Paper
            elevation={1}
            className="project-details-buyer-aspect editable-board-container"
          >
            {/* Strip background  */}
            <div className="img-back-grd"></div>
            {/* Strip background  */}

            <Grid container spacing={2} style={{ position: "relative" }}>
              {editMode ? (
                <Grid item lg={1} sm={2} xs={12} style={{ margin: "auto 0px" }}>
                  <div className="container">
                    <div
                      className="imgPreview attach_box"
                      style={{
                        background: imgPreview
                          ? `url("${imgPreview}") no-repeat center/cover`
                          : "#e2f2ff",
                      }}
                    >
                      <>
                        <div
                          style={{
                            background: "#ffffff8a",
                            borderRadius: "50%",
                          }}
                        >
                          <AutorenewIcon
                            style={{ color: "var(---2170ff-p-)" }}
                          />
                        </div>
                        <label
                          htmlFor="fileUploadButton"
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                          }}
                        ></label>
                        <input
                          type="file"
                          id="fileUploadButton"
                          hidden
                          onChange={(e) => handleImageChange(e)}
                          value={values.image}
                          accept={ACCEPT_ONLY_IMAGE}
                        />
                      </>
                    </div>
                  </div>
                </Grid>
              ) : (
                <Grid item lg={1} sm={2} xs={12} style={{ margin: "auto 0px" }}>
                  <img src={img} alt="" className="image" />
                </Grid>
              )}
              <Grid item lg={2} md={3} sm={4} xs={12}>
                <Grid container>
                  <Grid item sm={12}>
                    <div className="lable">Project Name</div>
                    {editMode ? (
                      <>
                        <textarea
                          className="field __edit"
                          name="name"
                          style={{ fontSize: "1em" }}
                          readOnly={!editMode}
                          spellcheck="false"
                          value={values.name}
                          onChange={handleInputChange}
                          rows="1"
                          maxLength="50"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        >
                          {values?.name}
                        </textarea>
                        {errors && errors.name && (
                          <span className="error-msg">{errors.name}</span>
                        )}
                      </>
                    ) : (
                      <p className="field __view show-wrap-text two-line mw200">
                        {values.name}
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={12} style={{ marginTop: "0.7em" }}>
                    <PopupState variant="popover">
                      {(popupState) => (
                        <>
                          <Button
                            className={`active-btn-item status badge badge-${getStatusColor(
                              projectStatus || selectedProject?.project_status
                            )}`}
                            variant="contained"
                            {...bindTrigger(popupState)}
                            endIcon={
                              <ArrowDropDownIcon className="icn-active-btn" />
                            }
                            disabled={
                              !selectedProject ||
                              (selectedProject &&
                                selectedProject.createdBy !== currentUser._id)
                            }
                          >
                            <span
                              style={{
                                textTransform: "capitalize",
                                marginRight: "3px",
                              }}
                            >
                              {projectStatus || selectedProject?.project_status}
                            </span>
                          </Button>
                          <Menu {...bindMenu(popupState)}>
                            <button
                              className="active-btn-popup badge badge-success"
                              onClick={() =>
                                getStatusChange(AUCTION_ACTIVE_SMALL)
                              }
                            >
                              {AUCTION_ACTIVE}
                            </button>
                            <br />
                            <button
                              className="inactive-btn-popup badge badge-primary-outline"
                              onClick={() =>
                                getStatusChange(AUCTION_COMPLETED_SMALL)
                              }
                            >
                              {AUCTION_COMPLETED}
                            </button>
                            <br />
                            <button
                              className="inactive-btn-popup badge badge-purple"
                              onClick={() => getStatusChange(AUCTION_ARCHIEVED)}
                            >
                              {AUCTION_ARCHIEVED_CAPITAL}
                            </button>
                            <br />
                            <button
                              className="inactive-btn-popup badge badge-secondary"
                              onClick={() =>
                                getStatusChange(AUCTION_DRAFT_SMALL)
                              }
                            >
                              {AUCTION_DRAFT}
                            </button>
                          </Menu>
                        </>
                      )}
                    </PopupState>
                    {!selectedProject ||
                    (selectedProject &&
                      selectedProject.createdBy != currentUser._id) ? (
                      <></>
                    ) : (
                      <Button
                        className={
                          "btn btn-primary go-back-button " +
                          (editMode === true ? "__save" : "__edit")
                        }
                        style={{ height: "30px" }}
                        variant="contained"
                        onClick={clickToggleBtn}
                        type="submit"
                      >
                        {editMode ? "Save Changes" : "Edit Project"}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <div className="lable">Description</div>
                <div
                  id="description__view"
                  className={
                    "field" + " " + (editMode === true ? "__edit" : "__view")
                  }
                  truncate="7"
                >
                  <Tooltip title={values.description} placement="left-start">
                    <span
                      className="show-wrap-text one-line"
                      // style={{ display: "inline" }}
                    >
                      {values.description}
                    </span>
                  </Tooltip>
                  <span
                    className="show__complete__text"
                    style={{ display: "none" }}
                  >
                    {values.description}
                  </span>
                  {/* <span
                    className={"show__more__link "}
                    // style={{ display: isLoaded ? "inline" : "none" }}
                  >
                    ...more
                  </span> */}
                </div>
                <textarea
                  id="description__edit"
                  className={
                    "field" + " " + (editMode === true ? "__edit" : "__view")
                  }
                  readOnly={!editMode}
                  spellcheck="false"
                  name="description"
                  value={values.description}
                  onChange={handleInputChange}
                  rows="2"
                  style={{ display: "none" }}
                  inputProps={{ maxLength: 500 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                >
                  {values.description}
                </textarea>
                {errors && errors.description && (
                  <span className="error-msg">{errors.description}</span>
                )}
                <Grid container>
                  <Grid item sm={6} xs={12}>
                    <div className="lable">Start Date</div>
                    <div
                      className={
                        "datetime-picker-cotainer project-date" +
                        " " +
                        (editMode === true ? "__edit" : "__view")
                      }
                    >
                      <Controls.BasicDatePicker
                        name="startDate"
                        readOnly={!editMode}
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                          // if (newValue > endDate) {
                          //   setEndDate(newValue);
                          // }
                        }}
                        // onChange={setStartDate}
                        format="dd-MM-YYYY"
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="lable">End Date</div>
                    <div
                      className={
                        "datetime-picker-cotainer project-date" +
                        " " +
                        (editMode === true ? "__edit" : "__view")
                      }
                    >
                      <Controls.BasicDatePicker
                        name="endDate"
                        readOnly={!editMode}
                        value={endDate}
                        onChange={setEndDate}
                        minDate={startDate}
                        format="dd-MM-YYYY"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={1}
                style={{ display: smScreen ? "block" : "none" }}
              ></Grid>
              <Grid item lg={2} sm={3} xs={12}>
                <div className="lable">Location</div>
                {editMode ? (
                  <>
                    <textarea
                      className="field __edit"
                      name="location"
                      readOnly={!editMode}
                      value={values.location}
                      spellcheck="false"
                      onChange={handleInputChange}
                      rows="1"
                    >
                      {values.location}
                    </textarea>
                    {errors && errors.location && (
                      <span className="error-msg">{errors.location}</span>
                    )}
                  </>
                ) : (
                  <span className="field __view show-wrap-text one-line mw200">
                    {values.location}
                  </span>
                )}
                <div className="lable">Business Unit</div>
                <select
                  className={
                    "field" + " " + (editMode === true ? "__edit" : "__view")
                  }
                  disabled={!editMode}
                  name="unit"
                  onChange={handleInputChange}
                  value={values.unit}
                >
                  <option value="">None</option>
                  {businessUnitList.map((val) => (
                    <option value={val._id}>{val.name}</option>
                  ))}
                </select>
              </Grid>
              <Grid
                item
                md={2}
                style={{ display: mdScreen ? "block" : "none" }}
              ></Grid>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <div className="lable">Project Manager</div>
                {editMode ? (
                  <>
                    <textarea
                      className="field __edit"
                      name="manager"
                      rows="1"
                      readOnly={!editMode}
                      onChange={handleInputChange}
                      spellcheck="false"
                      value={values.manager}
                    >
                      {values.manager}
                    </textarea>
                  </>
                ) : (
                  <span className="field __view show-wrap-text one-line ">
                    {values.manager && values.manager.length > 0
                      ? values.manager
                      : "---"}
                  </span>
                )}

                <div className="lable">Department</div>
                {editMode ? (
                  <textarea
                    className="field __edit"
                    name="department"
                    rows="1"
                    value={values.department}
                    onChange={handleInputChange}
                    readOnly={!editMode}
                    spellcheck="false"
                  >
                    {values.department}
                  </textarea>
                ) : (
                  <span className="field __view show-wrap-text one-line">
                    {values.department && values.department.length > 0
                      ? values.department
                      : "---"}
                  </span>
                )}
              </Grid>
              <Grid item lg={2} md={3} sm={3} xs={12}>
                <div className="lable">Project Buyer</div>
                <select
                  className={
                    "field" +
                    " " +
                    (loginUserId === values.owner
                      ? editMode
                        ? "__edit"
                        : "__view show-wrap-text"
                      : "__view show-wrap-text")
                  }
                  disabled={
                    loginUserId === values.owner
                      ? editMode
                        ? false
                        : true
                      : true
                  }
                  name="owner"
                  onChange={handleInputChange}
                  value={values.owner}
                >
                  <option value="">None</option>
                  {companyUsers.map((val) => (
                    <option value={val._id}>
                      {val.first_name} {val.last_name}
                    </option>
                  ))}
                </select>

                <div className="lable">Currency</div>

                <select
                  className={
                    "field" +
                    " " +
                    (editMode === true
                      ? "__edit"
                      : "__view show-wrap-text two-line")
                  }
                  disabled={!editMode}
                  name="currency"
                  onChange={handleInputChange}
                  value={values.currency}
                >
                  <option value="">None</option>
                  {currencyData.map((val) => (
                    <option value={val.code}>{val.name}</option>
                  ))}
                </select>
              </Grid>
            </Grid>
          </Paper>
          {/* </Form> */}
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(ProjectBanner));
