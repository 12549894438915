import { styled } from "@material-ui/styles";
import { Menu } from "@mui/material";
import PopupState, { bindMenu } from "material-ui-popup-state";

const CustomStyle = styled((props) => (
  <Menu
    {...props}
    araia-label="table-option-list-of-menu-item"
    PaperProps={{
      sx: {
        marginTop: "13px",
        minWidth: "12em",
      },
    }}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      horizontal: "right",
      vertical: "top",
    }}
  />
))(({ theme }) => ({}));

export default function TableOptionList(props) {
  return <CustomStyle {...props} />;
}
