import React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import { TextareaAutosize } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textarea: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    resize: "vertical",
    // minHeight: "100px",
  },
}));

function NumberFormatTextarea(props) {
  const classes = useStyles();
  return (
    <NumericFormat
      thousandSeparator={true}
      allowNegative={false}
      isNumericString={true}
      customInput={TextareaAutosize}
      className={`${classes.textarea} ${props.customClass}`}
      {...props}
    />
  );
}

// NumberFormatTextarea.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   onChange: PropTypes.func.isRequired,
// };

export default NumberFormatTextarea;
