export const getCurrentLiveAuction = (state) => {
  return state.auction.liveAuction;
};

export const shouldRefreshLiveBiddingData = (state) => {
  return state.auction.refreshLiveBidingData;
};

export const shouldRefreshLiveAuctionBuyer = (state) => {
  return state.auction.refreshLiveAuction;
};
