import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Appbars from "../../components/Appbar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import { connect, useDispatch } from "react-redux";
import { logout } from "../../store/actions/authActions";
import { withRouter } from "react-router-dom";
import {
  currentUser,
  isAuthenticated,
} from "../../store/selectors/AuthSelectors";
import Loader from "../../components/Loader/Loader";
import useWindowDimensions from "../../components/useWindowDimensions";
import { TextField } from "@mui/material";
import Notification from "../../components/Notification";
import StatisticsBoxes from "./components/StatisticsBoxes";
import LiveAuction from "./components/LiveAuction";
import RecentAuction from "./components/RecentAuction";
import RecentProjects from "./components/RecentProjects";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        {"OKTIO "}
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  logo: { position: "absolute", left: "35px", top: "15px" },
}));

const Dashboard = (props) => {
  const { currentUser } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [fullLoder, setFullLoder] = useState(false);
  const [resendApi, setResendApi] = React.useState(false);

  const { smScreen } = useWindowDimensions();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (resendApi) {
      setResendApi(false);
    }
  }, [resendApi]);

  useEffect(() => {
    if (currentUser) {
      setResendApi(true);
    }
  }, [currentUser]);

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Appbars> </Appbars>
      <main className="app-main-section-container">
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <>
            {fullLoder && <Loader />}
            <Grid container spacing="2">
              <Grid item xs="12">
                <StatisticsBoxes resendApi={resendApi} />
              </Grid>
              <Grid item xs="12">
                <LiveAuction resendApi={resendApi} setNotify={setNotify} />
              </Grid>
              <Grid item xs="12">
                <RecentAuction resendApi={resendApi} setNotify={setNotify} />
              </Grid>
              <Grid item xs="12">
                <RecentProjects resendApi={resendApi} setNotify={setNotify} />
              </Grid>
            </Grid>
            <Notification notify={notify} setNotify={setNotify} />
          </>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(Dashboard));
