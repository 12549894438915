import { Box, Grid } from "@material-ui/core";
import { InputLabel, Modal } from "@mui/material";
import * as React from "react";
import Controls from "../../../components/controls/Controls";
import { Form, useForm } from "../../../components/useForm";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import { Api } from "../../../api/Api";
import {
  PATCH_EDIT_CONTACT_SUPPLIER_API,
  POST_ADD_COONTACT_SUPPLIER_API,
} from "../../../constants/ApiUrl";
import { VALID_EMAIL_REGEX } from "../../../constants/AppConstants";

const initialFValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

export default function SupplierNewContactPopup(props) {
  const {
    openPopup,
    setOpenPopup,
    setNotify,
    getAllContact,
    supplier,
    contactEditData,
    setContactEditData,
    isContactEdit,
    setIsContactEdit,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("firstName" in fieldValues)
      temp.firstName = fieldValues.firstName ? "" : "This field is required.";
    if ("lastName" in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "This field is required.";
    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (!VALID_EMAIL_REGEX.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if ("phone" in fieldValues) {
      if (fieldValues.phone !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.phone)) {
          temp.phone = "";
        } else {
          temp.phone = "Only Number allow";
        }
      } else {
        temp.phone = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const { auth } = store.getState();
      const userId = auth?.auth?.user?._id;
      values.supplierId = supplier?._id;
      if (!isContactEdit) {
        values.createdBy = userId;
        const response = await Api(
          POST_ADD_COONTACT_SUPPLIER_API,
          values,
          "post"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            setOpenPopup(false);
            resetForm();
            getAllContact();
          }
        }
      } else {
        if (contactEditData && contactEditData?._id) {
          values.updatedBy = userId;
          const response = await Api(
            `${PATCH_EDIT_CONTACT_SUPPLIER_API}/${contactEditData?._id}`,
            values,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setOpenPopup(false);
              setContactEditData({});
              setIsContactEdit(false);
              resetForm();
              getAllContact();
            }
          }
        }
      }
    }
  };

  const CancelClick = () => {
    setContactEditData({});
    setIsContactEdit(false);
    setOpenPopup(false);
    resetForm();
  };

  React.useEffect(() => {
    if (contactEditData) {
      setValues({
        firstName: contactEditData?.first_name,
        lastName: contactEditData?.last_name,
        email: contactEditData?.email,
        phone: contactEditData?.phone,
      });
    }
  }, [supplier, contactEditData]);

  return (
    <Modal
      open={openPopup}
      aria-label="supplier-list-info-details-contact-popup"
      aria-describedby="supplier-list-info-details-contact-popup"
    >
      <Box className="popup-form-container">
        <div className="title">New Contact</div>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className="first_name-label">First Name</InputLabel>
              <Controls.Input
                labelId="first_name-input"
                name="firstName"
                fullWidth
                value={values.firstName}
                onChange={handleInputChange}
                error={errors.firstName}
                inputProps={{ maxLength: 40 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="last_name-label">Last Name</InputLabel>
              <Controls.Input
                labelId="last_name-input"
                name="lastName"
                fullWidth
                value={values.lastName}
                onChange={handleInputChange}
                error={errors.lastName}
                inputProps={{ maxLength: 40 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="email-label">Email</InputLabel>
              <Controls.Input
                labelId="email-input"
                name="email"
                fullWidth
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="phone-label">Phone</InputLabel>
              <Controls.Input
                labelId="phone-input"
                name="phone"
                fullWidth
                value={values.phone}
                onChange={handleInputChange}
                error={errors.phone}
                inputProps={{ maxLength: 15 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
          </Grid>
          <Box display="flex" mt={4} bgcolor="background.paper">
            <Grid istem xs={4} className="BottomModal-left">
              <Box p={0} flexGrow={1}>
                <Controls.Button
                  text="Cancel"
                  disableElevation
                  onClick={() => CancelClick()}
                  style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                  color="default"
                  className="btn-mui"
                />
              </Box>
            </Grid>
            <Grid item xs={8} className="BottomModal-right">
              <Box p={0}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text="Add Contact"
                  className="btn-mui"
                  style={{ minWidth: "197px" }}
                />
              </Box>
            </Grid>
          </Box>
        </Form>
      </Box>
    </Modal>
  );
}
