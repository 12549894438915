import {
  CLEAR_REDUX_ON_CLOSE_RFX,
  GET_LIVE_RFX_DETAILS,
  REFRESH_LIVE_RFX_BUYER,
  REFRESH_LIVE_RFX_DATA_BUYER,
  RFX_SAVE_LIB_DOCUMENT,
  RFX_SAVE_LIB_DOCUMENT_REQUEST,
  RFX_TEMP_SAVE_LIB_DOCUMENT,
  RFX_TEMP_SAVE_LIB_DOCUMENT_REQUEST,
  SAVE_RFX_PROJECT_DATA,
  SAVE_RFX_PROJECT_DATA_REQUEST,
  SAVE_RFX_PROJECT_LINK,
  SAVE_RFX_PROJECT_LINK_REQUEST,
  SAVE_RFX_STATUS,
  SAVE_RFX_STEP_1,
  SAVE_RFX_STEP_1_REQUEST,
  SAVE_RFX_STEP_2,
  SAVE_RFX_STEP_2_REQUEST,
  SAVE_RFX_STEP_3,
  SAVE_RFX_STEP_3_REQUEST,
  SAVE_RFX_STEP_4,
  SAVE_RFX_STEP_4_REQUEST,
  SAVE_RFX_STEP_5,
  SAVE_RFX_STEP_5_REQUEST,
} from "../constant/rfxConstant";

const initialState = {};
const rfxReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case SAVE_RFX_PROJECT_DATA_REQUEST:
      return { loading: true };
    case SAVE_RFX_PROJECT_DATA:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case SAVE_RFX_STEP_1_REQUEST:
      return { loading: true };

    case SAVE_RFX_STEP_1:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case SAVE_RFX_STEP_2_REQUEST:
      return { loading: true };

    case SAVE_RFX_STEP_2:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case SAVE_RFX_STEP_3_REQUEST:
      return { loading: true };

    case SAVE_RFX_STEP_3:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case SAVE_RFX_STEP_4_REQUEST:
      return { loading: true };

    case SAVE_RFX_STEP_4:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case CLEAR_REDUX_ON_CLOSE_RFX:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case SAVE_RFX_PROJECT_LINK_REQUEST:
      return { loading: true };

    case SAVE_RFX_PROJECT_LINK:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case RFX_SAVE_LIB_DOCUMENT_REQUEST:
      return { loading: true };

    case RFX_SAVE_LIB_DOCUMENT:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case RFX_TEMP_SAVE_LIB_DOCUMENT_REQUEST:
      return { loading: true };

    case RFX_TEMP_SAVE_LIB_DOCUMENT:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case SAVE_RFX_STEP_5_REQUEST:
      return { loading: true };

    case SAVE_RFX_STEP_5:
      return {
        ...state,
        RfxForm1: actions.payload,
        loading: false,
      };
    case SAVE_RFX_STATUS:
      return {
        ...state,
        SaveSuccess: actions.payload,
        loading: false,
      };
    // live monitoring
    case GET_LIVE_RFX_DETAILS:
      return {
        ...state,
        liveRfx: actions.payload,
      };

    case REFRESH_LIVE_RFX_DATA_BUYER:
      return {
        ...state,
        refreshLiveRfxData: actions.payload,
      };

    case REFRESH_LIVE_RFX_BUYER:
      return {
        ...state,
        refreshLiveRfx: actions.payload,
      };
    // live monitoring
   
    default:
      return state;
  }
};
export default rfxReducer;
