import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AUCTION_COMPLETED } from "../../../../constants/AppConstants";
import { isGlobalRfxShow } from "../../../../utility/utils";

const RfxComparePriceTab = ({
  itemPriceList,
  itemCompanyList,
  removeSupplierHandler,
  rfxDetails,
}) => {
  return (
    <>
      <TableContainer
        className={` table-container ${
          rfxDetails.supplier_to_provide === "1"
            ? "rfx-compare-price-tab"
            : "rfx-lumpsum-tab"
        }`}
        component={Paper}
      >
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {rfxDetails?.supplier_to_provide === "1" && (
                <TableCell className="" colSpan={2}></TableCell>
              )}
              {itemCompanyList &&
                itemCompanyList.length > 0 &&
                itemCompanyList.map((item, index) => {
                  return (
                    <>
                      <TableCell
                        TableCell
                        className="minW350 text-bold"
                        colSpan={
                          rfxDetails?.supplier_to_provide === "1" ? 2 : 1
                        }
                      >
                        <div className="d-flex align-item-center justify-content-between w-100">
                          <div className="w-100 pt-3 pb-3">
                            <p className="table-word-break w-100">
                              {item?.company_name}
                            </p>
                            <div className="d-flex text-gray">
                              <Tooltip
                                title={` ${item?.user?.first_name} ${item?.user?.last_name}`}
                                placement="bottom-start"
                              >
                                <p className="table-word-break w-auto">
                                  {/* {`${item?.user?.first_name}
                                  ${item?.user?.last_name}`} */}
                                  {item?.user?.first_name}
                                </p>
                              </Tooltip>
                              <div className="hr-line"></div>
                              <Tooltip
                                title={item?.email}
                                placement="bottom-start"
                              >
                                <p className="table-word-break w-50">
                                  {item?.email}
                                </p>
                              </Tooltip>
                            </div>
                          </div>
                          {itemCompanyList && itemCompanyList.length !== 1 && (
                            <div
                              className="close-icon-body global-flex-center cursor-p"
                              onClick={() => removeSupplierHandler(item)}
                            >
                              <CloseIcon className="close-icon" />
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {rfxDetails?.supplier_to_provide === "1" && (
                <>
                  <TableCell className="sub-header">Unit</TableCell>
                  <TableCell className="sub-header">Quantity</TableCell>
                </>
              )}
              {itemCompanyList &&
                itemCompanyList.length > 0 &&
                itemCompanyList.map((index) => {
                  return (
                    <>
                      {rfxDetails?.supplier_to_provide === "1" && (
                        <TableCell className="sub-header minW175">
                          Unit Price
                        </TableCell>
                      )}
                      <TableCell className="sub-header minW175">
                        Total Price
                      </TableCell>
                    </>
                  );
                })}
            </TableRow>
            {rfxDetails.supplier_to_provide === "1" ? (
              <>
                {itemPriceList &&
                  itemPriceList.length > 0 &&
                  itemPriceList.map((row, index) => (
                    <TableRow
                      key={index}
                      className={`${
                        (index + 1) % 2 === 0 ? "bg-skyBlue" : ""
                      } `}
                    >
                      {rfxDetails?.supplier_to_provide === "1" && (
                        <>
                          <TableCell>
                            <p className="">{row?.itemId?.item_name}</p>
                          </TableCell>
                          <TableCell>
                            <p className="">{row?.quantity}</p>
                          </TableCell>
                        </>
                      )}
                      {itemCompanyList && itemCompanyList.length > 0 ? (
                        itemCompanyList.map((obj, i) => {
                          return (
                            <>
                              {rfxDetails?.supplier_to_provide === "1" && (
                                <TableCell className="minW175">
                                  <p className="">
                                    {row?.response[i]?.bid &&
                                    isGlobalRfxShow(
                                      obj?.isSubmitted,
                                      rfxDetails?.status,
                                      obj?.rfxStatus,
                                      rfxDetails?.sealed_bid
                                    )
                                      ? //   (
                                        //   !rfxDetails?.sealed_bid ||
                                        //     rfxDetails.status === AUCTION_COMPLETED
                                        // )
                                        `${
                                          row?.response[i]?.bid / row?.quantity
                                        } ${rfxDetails?.currency}`
                                      : "--"}
                                  </p>
                                </TableCell>
                              )}
                              {rfxDetails?.supplier_to_provide === "1" ? (
                                <TableCell className="minW175">
                                  <p className="">
                                    {row?.response[i]?.bid &&
                                    isGlobalRfxShow(
                                      obj?.isSubmitted,
                                      rfxDetails?.status,
                                      obj?.rfxStatus,
                                      rfxDetails?.sealed_bid
                                    )
                                      ? // (!rfxDetails?.sealed_bid ||
                                        //   rfxDetails.status === AUCTION_COMPLETED)
                                        `${row?.response[i]?.bid} ${rfxDetails?.currency}`
                                      : "--"}
                                  </p>
                                </TableCell>
                              ) : (
                                <TableCell className="minW175">
                                  {/* <p className="">{obj?.bid || "--"}</p> */}
                                  <p className="">123</p>
                                </TableCell>
                              )}
                              {rfxDetails?.supplier_to_provide !== "1" && (
                                <TableCell className="minW175"></TableCell>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <p>--</p>
                      )}
                      {/* {row?.response &&
                    row?.response.length > 0 &&
                    row?.response.map((item) => (
                   
                    ))} */}
                    </TableRow>
                  ))}
              </>
            ) : (
              <>
                <TableRow className="">
                  {itemCompanyList && itemCompanyList.length > 0 ? (
                    itemCompanyList.map((obj, i) => {
                      return (
                        <>
                          <TableCell className="minW175">
                            <p className="">
                              {obj?.bid &&
                              isGlobalRfxShow(
                                obj?.isSubmitted,
                                rfxDetails?.status,
                                obj?.rfxStatus,
                                rfxDetails?.sealed_bid
                              )
                                ? // (!rfxDetails?.sealed_bid ||
                                  //   rfxDetails.status === AUCTION_COMPLETED)
                                  `${obj?.bid} ${rfxDetails?.currency}`
                                : "--"}
                            </p>
                          </TableCell>

                          {/* <TableCell className="minW175"></TableCell> */}
                        </>
                      );
                    })
                  ) : (
                    <p>--</p>
                  )}
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RfxComparePriceTab;
