import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { INIT_SOCKET_EVENT } from "../services/socket/constants";
import SocketHandler from "../services/socket/SocketHandler";
import {
  intiSocket,
  changeConnectionState,
  updateEventStack,
} from "../store/actions/socketAction";

const Socket = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const currentUsr = useSelector((state) => state?.auth?.auth?.user);

  useEffect(() => {
    const socketInstance = io(process.env.REACT_APP_BASE_URL_FOR_SOCKET, {
      path: process.env.REACT_APP_SOCKETIO_PATH,
      secure: true,
    });

    SocketHandler.setInstance(socketInstance)
      .setDispatcher(dispatch)
      .setConnectedUser(currentUsr);
    socketInstance.on("connect", () => {
      dispatch(changeConnectionState(true));
      dispatch(updateEventStack("connect"));
      SocketHandler.emit(INIT_SOCKET_EVENT);
      SocketHandler.listen();
    });

    socketInstance.on("disconnect", () => {
      dispatch(changeConnectionState(false));
      dispatch(updateEventStack("disconnect"));
    });

    dispatch(intiSocket(socketInstance));
  }, []);

  return children;
};

export default Socket;
