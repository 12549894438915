import { Api } from "../../../api/Api";
import { POST_AUCTION_COMPANY_SUPPLIER_EDIT_API } from "../../../constants/ApiUrl";
import axiosInstance from "../../../services/AxiosInstance";

const EditBidBL = async (props) => {
  const {
    suplierBidId,
    startingPrice,
    bonusMalus,
    bonusMalusAmount,
    supplierCompanyId,
    auctionId,
    createdBy,
  } = props;
  let success = false;
  let code = 200;
  let message = "Values has been updated successfully !";
  const payload = {
    // suplierBidId: suplierBidId,
    startingPrice: startingPrice,
    bonusMalus: bonusMalus,
    bonusMalusAmount: bonusMalusAmount,
    supplierCompanyId: supplierCompanyId,
    auctionId: auctionId,
    createdBy: createdBy,
  };
  const response = await Api(
    POST_AUCTION_COMPANY_SUPPLIER_EDIT_API,
    payload,
    "post"
  );
  if (response.status === 200) {
    success = true;
    message = response.data.responseDescription
      ? response.data.responseDescription
      : message;
  } else {
    if (response) {
      success = false;
      code = response?.status;
      message = response?.data?.responseDescription
        ? response?.data?.responseDescription
        : response?.message;
    }
  }

  // let response = await axiosInstance.post(
  //   `api/buyer/company/auctionCompanySupplierEdit`,
  //   {
  //     // suplierBidId: suplierBidId,
  //     startingPrice: startingPrice,
  //     bonusMalus: bonusMalus,
  //     bonusMalusAmount: bonusMalusAmount,
  //     supplierCompanyId: supplierCompanyId,
  //     auctionId: auctionId,
  //     createdBy: createdBy,
  //   }
  // );
  //   if (response.status == 200) {
  //     success = true;
  //     message = response.data.responseDescription
  //       ? response.data.responseDescription
  //       : message;
  //   }
  // } catch (err) {
  //   // Handle Error Here
  //   if (err.response) {
  //     success = false;
  //     code = err.response.status;
  //     message = err.response.data.responseDescription
  //       ? err.response.data.responseDescription
  //       : err.message;
  //   }
  // }
  return {
    success,
    message,
    code,
  };
};

export default EditBidBL;
