import { Api } from "../../../../api/Api";
import { GET_SELLER_AUCTION_DETAIL_API } from "../../../../constants/ApiUrl";
import axiosInstance from "../../../../services/AxiosInstance";

const GetAuctionByIdBL = async (props) => {
  const { id } = props;
  // status : Invited / Declined / Accepted / Running / On-Hold / Ended / Win-Lost / Cancelled

  let auction = {};
  let message = "Auction details has been fetched successfully !";
  let success = true;
  let code = 200;
  // responseDescription

  // let response = await axiosInstance.get(
  //   `api/seller/auctions/supplierAuctionDetails/${id}`
  // );
  const response = await Api(
    `${GET_SELLER_AUCTION_DETAIL_API}/${id}`,
    {},
    "get"
  );

  code = response.status;

  if (response.status === 200) {
    if (response.data && response.data.auction !== undefined) {
      auction = response.data.auction;

      message = response.data.responseDescription;
    }
  } else {
    success = false;
    code = response.status;
    if (response) {
      message = response.data.responseDescription
        ? response.data.responseDescription
        : response.message;
    }
  }

  return {
    auction,
    message,
    success,
    code,
  };
};

export default GetAuctionByIdBL;
