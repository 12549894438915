/*eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Search from "@material-ui/icons/Search";
import AuctionFilter from "./filters/auctionFilter";
import AuctionTable from "./auctionTable";
import { useForm, Form } from "../../components/useForm";
import Notification from "../../components/Notification";
import Modal from "@mui/material/Modal";
import Loader from "../../components/Loader";
import { store } from "../../store/store";
import axiosInstance from "../../services/AxiosInstance";
import "../../styles/filter-box.css";
import { connect, useDispatch } from "react-redux";
import { projectDetails } from "../../store/actions/auctionAction";
import EnglishPopup from "./addEngAuctionPopup";
import Step2Form from "./FormStep2";
import Step3Form from "./FormStep3";
import Step4Form from "./FormStep4";
import Step5Form from "./FormStep5";
import { InputLabel } from "@mui/material";
import ConfirmationDailog from "./components/confirmationDailog";
import "./styles/create-auction-step0.css";
import { withRouter } from "react-router-dom";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Moment from "moment";
import { currentUser } from "../../store/selectors/AuthSelectors";
import GetAllAuctionBL from "./BL/GetAllAuctionBL";
import usePrevious from "../../components/usePrevious";
import { AUCTION_LIST_API, PROJECT_LIST_API } from "../../constants/ApiUrl";
import { Api } from "../../api/Api";
import { downloadDocumentFile } from "../../utility/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
}));

const stepLabels = [
  "General Information",
  "Setting",
  "Attachment",
  "Item",
  "Suppliers",
];

const projectType = [
  { id: "1", title: "English Reverse Auction" },
  // { id: "2", title: "type #2" },
  // { id: "3", title: "Type #3" },
];

const initialFValues = {
  project_id: "",
  rfx_id: "",
  auction_type: "1",
};

const AuctionList = (props) => {
  const { isAdmin, isSeller, isBuyer, currentUser } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupEnglishAuction, setOpenPopupEnglishAuction] = useState(false);
  const [openPopupStep2, setOpenPopupStep2] = useState(false);
  const [openPopupStep3, setOpenPopupStep3] = useState(false);
  const [openPopupStep4, setOpenPopupStep4] = useState(false);
  const [openPopupStep5, setOpenPopupStep5] = useState(false);
  const [isEditRecord, setIsEditRecord] = useState(false);
  const [editUserId, setEditUserId] = useState(undefined);
  const [auctionList, setAuctionList] = useState([]);
  const [fullLoder, setFullLoder] = useState(false);
  const [dropdownProject, setDropdownProject] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openConfrmationDailog, setOpenConfrmationDailog] = useState(false);
  const [resendApi, setResendApi] = useState(false);
  const [numOfPages, setNumOfPages] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);

  const prevPageValue = usePrevious(page);

  const [searchText, setSearchText] = useState(null);
  const [filters, setFilters] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("project_id" in fieldValues)
      temp.project_id = fieldValues.project_id ? "" : "Project is required.";

    if ("auction_type" in fieldValues)
      temp.auction_type = fieldValues.auction_type ? "" : "Type is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate() && isBuyer) {
      dispatch(projectDetails(values));
      addAuctionPopup();
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getProjectListForDropdown = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(`${PROJECT_LIST_API}/${companyId}`, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.projectData;
        setDropdownProject(allItems);
      }
    }
    setOpenPopup(true);
    setFullLoder(false);
  };

  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const clickClosePopup = () => {
    setOpenConfrmationDailog(true);
    // setOpenPopup(false);
    // resetForm();
    // setIsEditRecord(false);
  };

  useEffect(() => {
    if (resendApi) {
      setFullLoder(true);
      setResendApi(false);
      getAllAuctionList();
    }
  }, [resendApi]);

  useEffect(() => {
    if (currentUser) {
      setResendApi(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && prevPageValue !== undefined && prevPageValue !== page) {
      setResendApi(true);
    }
  }, [page]);

  const openPPAddAuction = async () => {
    setFullLoder(true);
    resetForm();
    getProjectListForDropdown();

    // const loader = setTimeout(() => {
    //   setOpenPopup(true);
    //   setFullLoder(false);
    // }, 600);
    // return () => {
    //   clearTimeout(loader);
    // };
  };

  const addAuctionPopup = () => {
    setOpenPopup(false);
    setOpenPopupEnglishAuction(true);
  };

  const getSearchString = (e) => {
    setSearchText(e.target.value);
    setResendApi(true);
  };

  // TODO : use GetAllAuctionBL function instead .
  const ExportToExcel = async () => {
    setFullLoder(true);
    let auctionFilter = JSON.parse(localStorage.getItem("auctionFilter"));
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    let string = "?exportToExcel=1";
    let i = 0;
    const values = auctionFilter;

    if (values) {
      for (let [key, value] of Object.entries(values)) {
        string += `&${key}=${value}`;
      }
    }

    if (
      values &&
      values.dateRange &&
      values.dateRange[0] !== null &&
      values.dateRange[1] !== null
    ) {
      string += `&startDate=${Moment(values.dateRange[0]).format(
        "YYYY-MM-DD 00:00:00"
      )}&endDate=${Moment(values.dateRange[1]).format("YYYY-MM-DD 00:00:00")}`;
    }
    const response = await Api(
      `${AUCTION_LIST_API}/${companyId}${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        if (!response.data.data) {
          setFullLoder(false);
          return;
        }
        downloadDocumentFile(response?.data?.data?.resourceUrl, "Auctions");
        // const link = document.createElement("a");
        // link.href = response.data.data.resourceUrl;
        // link.target = "_blank";
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      }
      setFullLoder(false);
    } else {
      setFullLoder(false);
    }
  };

  const getAllAuctionList = () => {
    GetAllAuctionBL({
      companyId: currentUser.company._id,
      rowsPerPage: rowsPerPage,
      page: page,
      search: searchText,
      filters: filters,
    })
      .then((data) => {
        setFullLoder(false);
        setAuctionList(data.list);
        setLimit(data.limit);
        setNumOfPages(Math.ceil(data.limit / rowsPerPage));
      })
      .catch((error) => {
        setFullLoder(false);
      });
  };

  return (
    <>
      <Paper elevation={0} square className={classes.root}>
        <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
        {/* <Loader fullLoder={false} /> */}
        <Grid container style={{ marginTop: "1em" }}>
          <Grid item container xs={12} style={{ alignItems: "center" }}>
            <Grid item xs={3}>
              <h1 className="item-container">Auctions</h1>
            </Grid>
            <Grid item xs={9}>
              <Box style={{ float: "right" }}>
                <Controls.Button
                  variant="contained"
                  className={
                    "btn btn-white btn-mui" +
                    (showFilterCategories ? " active" : "")
                  }
                  text="Filter"
                  onClick={toggleFilterCategory}
                  startIcon={
                    <FilterListSharpIcon style={{ color: "#2170FF" }} />
                  }
                />
                <Controls.Button
                  text="Export"
                  className="btn btn-success btn-mui"
                  startIcon={<SystemUpdateAltIcon />}
                  onClick={() => {
                    ExportToExcel();
                  }}
                />
                {!isBuyer ? (
                  <></>
                ) : (
                  <>
                    <Controls.Button
                      text="Add Auction"
                      className="btn btn-primary btn-mui"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        openPPAddAuction();
                      }}
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container className="filter-box-container">
            {showFilterCategories && (
              <Paper elevation={1} className="filter-box">
                <AuctionFilter
                  setAuctionList={setAuctionList}
                  setShowFilterCategories={setShowFilterCategories}
                  setResendApi={setResendApi}
                  setPage={setPage}
                  setFilters={setFilters}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Grid item xs={12}>
            <Box sx={{ display: "inline" }}>
              <TextField
                onChange={getSearchString}
                placeholder="Search Using Keyword"
                className="select-box select-box-primary search-box"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box style={{ float: "right" }}>
              <Select
                value={rowsPerPage}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="items-count-dropdown"
              >
                {/* <MenuItem value="">
                  <em>Show 10 entries</em>
                </MenuItem> */}
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </Box>
          </Grid>
          <AuctionTable
            setIsEditRecord={setIsEditRecord}
            setOpenPopup={setOpenPopup}
            setValues={setValues}
            setEditUserId={setEditUserId}
            auctionList={auctionList}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setResendApi={setResendApi}
            resendApi={resendApi}
            page={page}
            setPage={setPage}
            limit={limit}
            numOfPages={numOfPages}
            getAllAuctionList={getAllAuctionList}
          />
        </Box>
      </Paper>

      <Modal
        open={openPopup}
        aria-label="create-auction-zero-step-buyer-aspect"
        aria-describedby="create-auction-zero-step-buyer-aspect-desc"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Add Auction</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopup(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Form onSubmit={handleSubmit} className="mt-5">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel>Project</InputLabel>
                <Controls.Select
                  name="project_id"
                  value={values.project_id}
                  fullWidth
                  onChange={handleInputChange}
                  options={dropdownProject}
                  error={errors.project_id}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <InputLabel>RFX</InputLabel>
                <Controls.Select
                  name="rfx_id"
                  value={values.rfx_id}
                  fullWidth
                  onChange={handleInputChange}
                  options={[]}
                  error={errors.rfx_id}
                />
              </Grid> */}
              <Grid item xs={12}>
                <InputLabel>Type</InputLabel>
                <Controls.Select
                  name="auction_type"
                  value={values.auction_type}
                  fullWidth
                  onChange={handleInputChange}
                  options={projectType}
                  error={errors.auction_type}
                />
              </Grid>
            </Grid>
            <Box
              className="BottomModal"
              display="flex"
              justifyContent="center"
              p={1}
              bgcolor="background.paper"
            >
              <Grid item xs={6}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text={!isEditRecord ? "Add Auction" : "Update Auction"}
                  className="btn-mui w-100"
                  // onClick={() => addAuctionPopup()}
                />
              </Grid>
            </Box>
          </Form>
        </Box>
      </Modal>

      {/* step 1 */}
      <Modal
        open={openPopupEnglishAuction}
        aria-label="create-auction-first-step-buyer-aspect"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <EnglishPopup
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            stepLabels={stepLabels}
          />
        </Box>
      </Modal>

      {/* step 2 */}
      <Modal
        open={openPopupStep2}
        aria-label="create-auction-second-step-buyer-aspect"
        aria-describedby="modal-modal-description"
        className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Step2Form
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            setOpenPopupStep3={setOpenPopupStep3}
            stepLabels={stepLabels}
            setNotify={setNotify}
            setResendApi={setResendApi}
            projects={dropdownProject}
          />
        </Box>
      </Modal>

      {/* step3 */}
      <Modal
        open={openPopupStep3}
        aria-label="create-auction-third-step-buyer-aspect"
        aria-describedby="modal-modal-description"
        className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Step3Form
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            setOpenPopupStep3={setOpenPopupStep3}
            setOpenPopupStep4={setOpenPopupStep4}
            stepLabels={stepLabels}
            setResendApi={setResendApi}
          />
        </Box>
      </Modal>

      {/* step4 */}
      <Modal
        open={openPopupStep4}
        aria-label="create-auction-fourth-step-buyer-aspect"
        aria-describedby="modal-modal-description"
        className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Step4Form
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            setOpenPopupStep3={setOpenPopupStep3}
            setOpenPopupStep4={setOpenPopupStep4}
            setOpenPopupStep5={setOpenPopupStep5}
            setNotify={setNotify}
            stepLabels={stepLabels}
            setResendApi={setResendApi}
          />
        </Box>
      </Modal>

      {/* step5 */}
      <Modal
        open={openPopupStep5}
        aria-label="create-auction-fifth-step-buyer-aspect"
        aria-describedby="modal-modal-description"
        className="auction-form-container"
      >
        <Box className="popup-form-container">
          <Step5Form
            setOpenPopupEnglishAuction={setOpenPopupEnglishAuction}
            setOpenPopupStep2={setOpenPopupStep2}
            setOpenPopupStep3={setOpenPopupStep3}
            setOpenPopupStep4={setOpenPopupStep4}
            setOpenPopupStep5={setOpenPopupStep5}
            setAuctionList={setAuctionList}
            setNotify={setNotify}
            setFullLoder={setFullLoder}
            fullLoder={fullLoder}
            stepLabels={stepLabels}
            getAllAuctionList={getAllAuctionList}
            setResendApi={setResendApi}
          />
        </Box>
      </Modal>

      <ConfirmationDailog
        openConfrmationDailog={openConfrmationDailog}
        setOpenConfrmationDailog={setOpenConfrmationDailog}
        setDiscardAndClose={setOpenPopup}
        eraseEverything={false}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(AuctionList));
