import {
  CHANGE_CONNECTION_STATE,
  INIT_SOCKET_IO,
  UPDATE_EVENT_STACK,
} from "../constant/socketConstant";

const initialState = {
  instance: null,
  connected: false,
  lastEvent: "",
};

export default function SocketReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_SOCKET_IO:
      return {
        ...state,
        instance: action.payload,
      };

    case CHANGE_CONNECTION_STATE:
      return {
        ...state,
        connected: action.payload,
      };

    case UPDATE_EVENT_STACK:
      return {
        ...state,
        lastEvent: action.payload,
      };
    default:
      return state;
  }
}
