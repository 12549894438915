import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Button, MenuItem, Select } from "@mui/material";
import { Search } from "@material-ui/icons";
import Controls from "../../../components/controls/Controls";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Notification from "../../../components/Notification";
import useWindowDimensions from "../../../components/useWindowDimensions";
import TableHeadOptionList from "../../../components/TableHeadOptionList";
import Loader from "../../../components/Loader/Loader";
import CompanyTable from "./CompanyTable";

const SuperAdminCompanyList = (props) => {
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [fullLoder, setFullLoder] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchKey, setSearchKey] = React.useState("");
  const [filterValues, setFilterValues] = React.useState({});
  const [resendApi, setResendApi] = React.useState(true);
  const { smScreen } = useWindowDimensions();

  const [anchorNumEntriesEl, setAnchorNumEntriesEl] = React.useState(null);
  const openNumEntriesMenu = Boolean(anchorNumEntriesEl);
  const [anchorSortTypeEl, setAnchorSortTypeEl] = React.useState(null);
  const openAnchorSortTypeMenu = Boolean(anchorSortTypeEl);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setResendApi(true);
  }, [rowsPerPage]);

  const openSortList = () => {
    setAnchorSortTypeEl(null);
  };

  return (
    <>
      {fullLoder && <Loader />}
      <Grid container style={{ marginTop: "1em" }}>
        <Grid item container xs={12} style={{ alignItems: "center" }}>
          <Grid
            item
            xs={12}
            style={{ textAlign: smScreen ? "center" : "start" }}
          >
            <h1 className="mt-4 fz-34">Users</h1>
          </Grid>
        </Grid>
      </Grid>
      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Grid container>
            <Grid
              item
              md={4}
              xs={12}
              style={{ textAlign: smScreen ? "center" : "start" }}
            >
              <TextField
                placeholder="Search Using Keyword or Tags..."
                className="select-box select-box-primary search-box"
                value={searchKey}
                onChange={(event) => {
                  setSearchKey(event.target.value);
                  setResendApi(true);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
              style={{
                textAlign: smScreen ? "center" : "end",
                marginTop: smScreen ? "10px" : "0px",
              }}
            >
              <Controls.Select
                value={rowsPerPage}
                fullWidth
                onChange={(event) => setRowsPerPage(event.target.value)}
                emptyOption={false}
                options={[
                  { _id: 10, name: "Show 10 entries" },
                  { _id: 20, name: "Show 20 entries" },
                  { _id: 30, name: "Show 30 entries" },
                ]}
                className="outline"
              />
            </Grid>
          </Grid>
          <CompanyTable
            rowsPerPage={rowsPerPage}
            setFullLoder={setFullLoder}
            searchKey={searchKey}
            filterValues={filterValues}
            setResendApi={setResendApi}
            resendApi={resendApi}
          />
        </Box>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default SuperAdminCompanyList;
