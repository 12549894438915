import { Api } from "../../../api/Api";
import { POST_AUCTION_STATUS_CHANGE_API } from "../../../constants/ApiUrl";
import axiosInstance from "../../../services/AxiosInstance";

const PublishDraftAuctionBL = async (props) => {
  const { auctionId } = props;
  let success = false;
  let message = "Auction has been published successfully !";

  const response = await Api(
    `${POST_AUCTION_STATUS_CHANGE_API}/${auctionId}/Draft`,
    {},
    "post"
  );
  if (response.status === 200) {
    success = true;
    message = response.data.responseDescription
      ? response.data.responseDescription
      : message;
  } else {
    message = response.data.responseDescription
      ? response.data.responseDescription
      : "publishing auction failed !";
  }
  // let response = await axiosInstance.post(
  //   `api/buyer/changeAuctionStatusDraftToPublish/${auctionId}/Draft`
  // );

  // if (response.status == 200) {
  //   success = true;
  //   message = response.data.responseDescription
  //     ? response.data.responseDescription
  //     : message;
  // } else {
  //   message = response.data.responseDescription
  //     ? response.data.responseDescription
  //     : "publishing auction failed !";
  // }

  return {
    success: success,
    message: message,
  };
};

export default PublishDraftAuctionBL;
