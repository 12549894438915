import { updateEventStack } from "../../../store/actions/socketAction";
import { INIT_SOCKET_RESPONSE_EVENT } from "../constants";
import SocketHandler from "../SocketHandler";

const InitSocketResEvent = () => {
  SocketHandler.instance.on(INIT_SOCKET_RESPONSE_EVENT, (response) => {
    if (response.meta && response.meta.success) {
      SocketHandler.authenticted = true;
    }
    SocketHandler.dispatcher(updateEventStack(INIT_SOCKET_RESPONSE_EVENT));
  });
  return true;
};
export default InitSocketResEvent;
