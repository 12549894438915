/*eslint-disable */
import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Modal,
} from "@mui/material";
import { Box, Grid, Paper } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../../../components/useWindowDimensions";
import Controls from "../../../components/controls/Controls";
import InvitedSellerPopup from "../components/InvitedSellerPopup";
import InviteNewSellerPopup from "../components/InviteNewSupplier";
import Search from "@material-ui/icons/Search";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "../styles/create-auction-step5.css";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import LogoIcon from "../../../assets/Logo_2.png";
import { setHTTPError } from "../../../store/actions/errorHandlerAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  COMAPNY_VERSION_API,
  POST_ADD_SUPPLIER_API,
} from "../../../constants/ApiUrl";
import { Api } from "../../../api/Api";
import { TOAST_AUTO_CLOSE } from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const columns = [
  {
    label: "Company Name",
    // minWidth: 250,
  },
  {
    label: "Main Contact Name",
    // minWidth: 400,
  },
  {
    label: "Invited Seller",
  },
  {
    label: "",
  },
];

const AddExistingSupplier = (props) => {
  const {
    openPopup,
    setOpenPopup,
    setNotify,
    setResendApi,
    currentLiveAuction,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const UnAuthorizedRoute = useSelector(
    (state) => state.errorHandler.UnAuthorizedRoute
  );
  const [dropDownSuppliers, setdropDownSuppliers] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState([]);
  const [newInvitedSuppliers, setNewInvitedSuppliers] = React.useState([]);
  const [openSellerPopup, setOpenSellerPopup] = React.useState(false);
  const [openInviteSupplierPopup, setOpenInviteSupplierPopup] =
    React.useState(false);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const [suppliersTable, setSuppliersTable] = React.useState([]);

  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const getImportBoxSize = (dir = "left") => {
    if (dir === "left") {
      if (xlScreen) {
        return "w80-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w60-percent-box";
      } else if (mdScreen) {
        return "w73-percent-box";
      } else if (lgScreen) {
        return "w79-percent-box";
      }
    } else {
      if (xlScreen) {
        return "w19-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w40-percent-box";
      } else if (mdScreen) {
        return "w26-percent-box";
      } else if (lgScreen) {
        return "w20-percent-box";
      }
    }
  };

  const getSellerLabel = (supplier) => {
    return (
      <div
        className="sellers-link-container"
        onClick={() => {
          setOpenSellerPopup(true);
          setSelectedSupplier(supplier);
        }}
      >
        <div className="label">
          {supplier &&
          supplier?.selectedInviteSupplier &&
          supplier?.selectedInviteSupplier.length > 0
            ? `${supplier?.selectedInviteSupplier.length} invited`
            : "All"}
        </div>
        <PlaylistAddIcon className="icon" />
      </div>
    );
  };

  const getAllExistingSupplier = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(
      `${COMAPNY_VERSION_API}/${companyId}/suppliersActive`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        // allItems.map((item) => {
        //   item.supplier_company_name = item?.supplier_company?.company_name;
        //   item.category_name = item && item?.category ? item?.category?.name : "";
        // });

        let allItems = response.data.suppliers;

        let companyData = Object.values(allItems);
        let final = [];
        companyData.map((item) => final.push(item[item.length - 1]));
        final.map((item) => {
          item.supplier_company_name = item?.supplier_company?.company_name;
        });
        setdropDownSuppliers(final);
      }
    }

    // await axiosInstance
    //   .get(`api/buyer/v1/company/${companyId}/suppliersActive`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       // allItems.map((item) => {
    //       //   item.supplier_company_name = item?.supplier_company?.company_name;
    //       //   item.category_name = item && item?.category ? item?.category?.name : "";
    //       // });

    //       let allItems = response.data.suppliers;

    //       let companyData = Object.values(allItems);
    //       let final = [];
    //       companyData.map((item) => final.push(item[item.length - 1]));
    //       final.map((item) => {
    //         item.supplier_company_name = item?.supplier_company?.company_name;
    //       });
    //       setdropDownSuppliers(final);
    //     }
    //   })
    //   .catch((err) => {});
  };

  const getSelectedSupplier = (supplier) => {
    if (supplier) {
      const { auth } = store.getState();
      let oldSuppliers = [...suppliers];

      const companyName = auth?.auth?.user?.company?.company_name;
      const companyId = auth?.auth?.user?.company?._id;

      const MainContactName =
        supplier?.main_contact?.first_name +
        " " +
        supplier?.main_contact?.last_name;
      const supplierEmail = {
        email: supplier.email,
        supplierId: supplier?.supplier_company?._id,
        supplierCompanyName: supplier?.supplier_company?.company_name,
        mainContact: MainContactName,
        companyId: companyId,
        userId: supplier?.main_contact?._id,
        company_buyer_name: companyName,
        supplierCompanyId: supplier?.supplier_company?._id,
        selectedInviteSupplier: [],
      };
      let anyObj = oldSuppliers.find(
        (item) => item.userId === supplierEmail.userId
      );

      if (anyObj && anyObj.userId) {
        return;
      }
      suppliers.push(supplierEmail);

      const neItems = [...suppliers];
      setSuppliers(neItems);
    }
  };

  const removeSelectIndex = (index) => {
    const removeIndex = suppliers.splice(index, 1);
    const neItems = [...suppliers];
    setSuppliers(neItems);
  };

  const saveSupplier = async () => {
    if (suppliers && suppliers.length > 0) {
      let OldSupplierArray = [];
      let newSupplierArray = [];
      suppliers.forEach((element) => {
        if (element && element.isInvieted) {
          newSupplierArray.push(element);
        } else {
          OldSupplierArray.push(element);
        }
      });
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      let payload = {
        companyId: companyId,
      };
      if (OldSupplierArray && OldSupplierArray.length > 0) {
        payload.suppliers = JSON.stringify(OldSupplierArray);
      }
      if (newSupplierArray && newSupplierArray.length > 0) {
        payload.selectedInviteNewSupplier = JSON.stringify(newSupplierArray);
      }
      // if (OldSupplierArray && OldSupplierArray.length > 0) {
      //   payload.suppliers = OldSupplierArray;
      // }
      // if (newSupplierArray && newSupplierArray.length > 0) {
      //   payload.selectedInviteNewSupplier = newSupplierArray
      // }

      if (currentLiveAuction && currentLiveAuction?._id) {
        const response = await Api(
          `${POST_ADD_SUPPLIER_API}/${currentLiveAuction?._id}`,
          payload,
          "post"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            setOpenPopup(false);
            setResendApi(true);
            setSuppliers([]);
          }
        } else {
          let message = response?.data?.responseDescription;
          if (response.status === 403) {
            dispatch(
              setHTTPError({
                code: 403,
                message: message,
              })
            );
            history.push(UnAuthorizedRoute);
          } else {
            dispatch(
              setHTTPError({
                code: response.status,
                message: message,
              })
            );
          }
        }
        // await axiosInstance
        //   .post(`api/buyer/addNewSupplier/${currentLiveAuction?._id}`, payload)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       setOpenPopup(false);
        //       setResendApi(true);
        //       setSuppliers([]);
        //       setNotify({
        //         isOpen: true,
        //         message: "Supplier added successfuly.",
        //         type: "success",
        //       });
        //     } else {
        //       setNotify({
        //         isOpen: true,
        //         message: response?.data?.responseDescription,
        //         type: "error",
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     let message = err?.response?.data?.responseDescription;
        //     if (err.response.status === 403) {
        //       dispatch(
        //         setHTTPError({
        //           code: 403,
        //           message: message,
        //         })
        //       );
        //       history.push(UnAuthorizedRoute);
        //     } else {
        //       dispatch(
        //         setHTTPError({
        //           code: err.response.status,
        //           message: message,
        //         })
        //       );
        //     }
        //     setNotify({
        //       isOpen: true,
        //       message: message,
        //       type: "error",
        //     });
        //   });
      }
    } else {
      toast.error(ValidationMessage.selectSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const CancelPopup = () => {
    setOpenPopup(false);
    setSuppliers([]);
  };

  React.useEffect(() => {
    getAllExistingSupplier();
  }, [currentLiveAuction]);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.category_name || option.supplier_company_name,
  });

  return (
    <>
      <Modal
        open={openPopup}
        aria-label="create-auction-first-step-buyer-aspect"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Add Supplier</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => setOpenPopup(false)}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>

          <div className="create-form-container">
            <Paper elevation={0} square>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid className={getImportBoxSize("left")}>
                  <Autocomplete
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    labelId="category-input"
                    filterOptions={filterOptions}
                    options={dropDownSuppliers}
                    onChange={(event, value) => getSelectedSupplier(value)}
                    getOptionLabel={(option) =>
                      option ? option.supplier_company_name : ""
                    }
                    renderInput={(params) => (
                      <Controls.Input
                        name="budget"
                        labelId="budget-input"
                        fullWidth
                        {...params}
                      />
                    )}
                    popupIcon={<Search />}
                  />
                </Grid>
                <Grid className={getImportBoxSize("right")}>
                  <Controls.Button
                    text="Invite New Supplier"
                    disableElevation
                    color="default"
                    onClick={() => setOpenInviteSupplierPopup(true)}
                    startIcon={<PersonAddAltRoundedIcon />}
                    className="btn-mui btn-white auto-complete-btn"
                  />
                </Grid>
              </Grid>

              <h3 className="b-600 mb-0 mt-3">Added Suppliers</h3>
              <hr style={{ backgroundColor: "#e0e0e0", opacity: "0.5" }} />

              <TableContainer
                className="table-container editable-table"
                style={{ position: "relative" }}
                component={Paper}
              >
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {suppliers.map((supplier, index) => (
                      <TableRow
                        key={supplier?.supplierId}
                        className="editable-row"
                      >
                        <TableCell>{supplier?.supplierCompanyName}</TableCell>
                        <TableCell>{supplier?.mainContact}</TableCell>
                        <TableCell>
                          {/* {supplier && supplier.isInvieted
                            ? "-" */}
                          {/* :  */}
                          {getSellerLabel(supplier)}
                          {/* } */}
                        </TableCell>
                        <TableCell>
                          <Box className="option-cell __active">
                            <DeleteOutlineIcon
                              className="icon __edit clr-ff0000"
                              onClick={() => removeSelectIndex(index)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Modal
                open={openSellerPopup}
                aria-label="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="popup-form-container invite-seller-box">
                  <div className="title"> Edit Sellers Invited </div>
                  <div className="label">Company Name</div>
                  <div className="title">
                    <div className="icon-cotainer">
                      <img src={LogoIcon} className="icon" />
                    </div>
                    <div className="text">
                      {" "}
                      {selectedSupplier?.supplierCompanyName}
                    </div>
                  </div>
                  <InvitedSellerPopup
                    openPopup={openSellerPopup}
                    setOpenPopup={setOpenSellerPopup}
                    supplier={selectedSupplier}
                    suppliers={suppliers}
                    setSuppliers={setSuppliers}
                  />
                </Box>
              </Modal>

              {/* invite new supplier popup start */}
              <Modal
                open={openInviteSupplierPopup}
                aria-label="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="popup-form-container invite-seller-box">
                  <div className="title"> Invite New Supplier </div>
                  <InviteNewSellerPopup
                    openInviteSupplierPopup={openInviteSupplierPopup}
                    setOpenInviteSupplierPopup={setOpenInviteSupplierPopup}
                    newInvitedSuppliers={newInvitedSuppliers}
                    setNewInvitedSuppliers={setNewInvitedSuppliers}
                    suppliersTable={suppliersTable}
                    setSuppliersTable={setSuppliersTable}
                    suppliers={suppliers}
                    setSuppliers={setSuppliers}
                    isDispatch={false}
                  />
                </Box>
              </Modal>

              {/* invite new supplier popup end */}
            </Paper>
          </div>

          <Box
            className="BottomModal"
            display="flex"
            p={1}
            bgcolor="background.paper"
            style={{ marginTop: "20px" }}
          >
            <Grid istem xs={4} className="BottomModal-left">
              <Box p={0} flexGrow={1}>
                <Controls.Button
                  text="Cancel"
                  disableElevation
                  onClick={() => CancelPopup()}
                  style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                  color="default"
                  className="btn-mui fix-w-12em"
                />
              </Box>
            </Grid>
            <Grid item xs={8} className="BottomModal-right">
              <Box p={0}>
                <Controls.Button
                  // type="submit"
                  disableElevation
                  text="Save"
                  onClick={() => saveSupplier()}
                  className="btn-mui fix-w-12em"
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddExistingSupplier;
