import { Box, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Modal } from "@mui/material";
import useWindowDimensions from "../../../../../components/useWindowDimensions";
import Controls from "../../../../../components/controls/Controls";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCurrentAuction } from "../../../../../store/selectors/supplierAspect/auctionSelector";
import GetAllAuctionItemsBL from "../../BL/GetAllAuctionItemsBL";
import defItemImg from "../../../../../assets/Items@2x.png";
import "../../styles/details-item.css";

const columns = [
  {
    label: "ID",
    // minWidth: 170
  },
  {
    label: "Image",
    // minWidth: 100
  },
  {
    label: "Name",
    minWidth: 100,
  },
  {
    label: "Category",
    minWidth: 100,
  },
  {
    label: "Manufacturer",
  },
  {
    label: "Model",
  },
  {
    label: "Unit",
  },
  {
    label: "Quantity",
  },
];

const ItemsAllModal = (props) => {
  const { openPopup, setOpenPopup, currentAuction } = props;
  const [itemList, setItemList] = useState([]);
  const [resendApi, setResendApi] = useState(false);

  useEffect(() => {
    if (resendApi == true) {
      GetAllAuctionItemsBL({
        currentAuction: currentAuction,
        page: 1,
        rowsPerPage: -1,
      }).then((data) => {
        setItemList(data.list);
        setResendApi(false);
      });
    }
  }, [resendApi]);

  useEffect(() => {
    if (currentAuction._id !== undefined) {
      setResendApi(true);
    }
  }, [currentAuction]);

  return (
    <Modal
      open={openPopup}
      aria-label="auction-details-items-popup"
      aria-describedby="modal-modal-description"
    >
      <Box className="popup-form-container supp-auction-details-items-list-container">
        <div className="header-title">All Items</div>
        <Paper elevation={0} square>
          <TableContainer className="table-container" component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "700" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {itemList.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: "center" }}
                    >
                      {item.itemId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.image_upload ? (
                        <img
                          src={item.image_upload}
                          className="table-item-img"
                        />
                      ) : (
                        <img src={defItemImg} className="table-item-img" />
                      )}
                    </TableCell>
                    <TableCell style={{ color: "#2170FF" }}>
                      {item.item_name}
                    </TableCell>
                    <TableCell>{item.category}</TableCell>
                    <TableCell>{item.manufacturer}</TableCell>
                    <TableCell>{item.model}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                  </TableRow>
                ))}
                {itemList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="text-center">
                      No records found <br />
                      {/* <i style={{ color: "var(---2170ff-p-)" }}>
                        Please add some item
                      </i> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <Grid container justifyContent="center">
              <Grid item sm={4} xs={8}>
                <Controls.Button
                  text="Done"
                  disableElevation
                  onClick={() => setOpenPopup(false)}
                  color="default"
                  className="btn btn-primary btn-mui w-100 mt-5"
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuction: getCurrentAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(ItemsAllModal));
