import { useState, useEffect } from "react";
import {
  XS_SCREEN,
  SM_SCREEN,
  MD_SCREEN,
  LG_SCREEN,
  XL_SCREEN,
} from "../store/constant/themeBreakPointConstant";

export default function useWindowDimensions(props) {
  const hasWindow = typeof window !== "undefined";

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [currentScreen, setCurrentScreen] = useState(setScreenSize());
  const [xlScreen, setXlScreen] = useState(fitInScreen(XL_SCREEN));
  const [lgScreen, setLgScreen] = useState(fitInScreen(LG_SCREEN));
  const [mdScreen, setMdScreen] = useState(fitInScreen(MD_SCREEN));
  const [smScreen, setSmScreen] = useState(fitInScreen(SM_SCREEN));
  const [xsScreen, setXsScreen] = useState(fitInScreen(XS_SCREEN));

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  function fitInScreen(refLabel) {
    let fit = false;
    switch (refLabel) {
      case XS_SCREEN:
        if (currentScreen < SM_SCREEN) fit = true;
        break;

      case SM_SCREEN:
        if (currentScreen < MD_SCREEN) fit = true;
        break;

      case MD_SCREEN:
        if (currentScreen < LG_SCREEN) fit = true;
        break;

      case LG_SCREEN:
        if (currentScreen < XL_SCREEN) fit = true;
        break;

      case XL_SCREEN:
        if (currentScreen >= XL_SCREEN) fit = true;
        break;
    }
    return fit;
  }

  function setScreenSize() {
    let { width, height } = getWindowDimensions();
    let result;
    if (XS_SCREEN <= width && width < SM_SCREEN) {
      result = XS_SCREEN;
    } else if (SM_SCREEN <= width && width < MD_SCREEN) {
      result = SM_SCREEN;
    } else if (MD_SCREEN <= width && width < LG_SCREEN) {
      result = MD_SCREEN;
    } else if (LG_SCREEN <= width && width < XL_SCREEN) {
      result = LG_SCREEN;
    } else {
      result = XL_SCREEN;
    }
    return result;
  }

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
        setCurrentScreen(setScreenSize());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  useEffect(() => {
    setXlScreen(fitInScreen(XL_SCREEN));
    setLgScreen(fitInScreen(LG_SCREEN));
    setMdScreen(fitInScreen(MD_SCREEN));
    setSmScreen(fitInScreen(SM_SCREEN));
    setXsScreen(fitInScreen(XS_SCREEN));
  }, [currentScreen]);

  return {
    windowDimensions,
    currentScreen,
    fitInScreen,
    xlScreen,
    lgScreen,
    mdScreen,
    smScreen,
    xsScreen,
  };
}
