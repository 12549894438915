import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../Auction/styles/details-banner.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import rfpCover from "../../../assets/Rfx/RfpCover.png";
import rfiCover from "../../../assets/Rfx/RfiCover.png";
import rfqCover from "../../../assets/Rfx/RfqCover.png";
import rfxCover from "../../../assets/Rfx/RfxCover.png";
import {
  AUCTION_ACCEPTED,
  AUCTION_ACCEPTED_CAPITAL,
  AUCTION_CANCELLED,
  AUCTION_DASH_CAPITAL_ON_HOLD,
  AUCTION_DASH_WIN_LOST,
  AUCTION_DECLINED,
  AUCTION_DECLINED_CAPITAL,
  AUCTION_INVITED,
  AUCTION_INVITED_CAPITAL,
  AUCTION_RUNNING,
  DANGER_BACKGROUND,
  PURPLE_BACKGROUND,
  RFI_TYPE,
  RFP_TYPE,
  RFQ_TYPE,
  RFX_PROGRESS_MAP,
  RFX_TYPE_MAP,
  SUCCESS_BACKGROUND,
  SUPPLIER_AUCTION_PENDING,
  WARNING_BACKGROUND,
} from "../../../constants/AppConstants";
import { Api } from "../../../api/Api";
import { POST_RFX_INVITATION_STATUS_API } from "../../../constants/ApiUrl";

const RfxSellerBanner = ({ getRfxDetails }) => {
  const rfxSupplierDetails = useSelector((state) => state.supplierRfx.rfx);

  const history = useHistory();
  const dispatch = useDispatch();

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_INVITED_CAPITAL:
        return WARNING_BACKGROUND;
      case AUCTION_ACCEPTED_CAPITAL:
        return SUCCESS_BACKGROUND;
      case AUCTION_DASH_WIN_LOST:
        return SUCCESS_BACKGROUND;
      case AUCTION_CANCELLED:
        return WARNING_BACKGROUND;
      case AUCTION_DECLINED_CAPITAL:
        return DANGER_BACKGROUND;
      case AUCTION_RUNNING:
        return DANGER_BACKGROUND;
      case AUCTION_DASH_CAPITAL_ON_HOLD:
        return PURPLE_BACKGROUND;
      default:
        return SUCCESS_BACKGROUND;
    }
  };

  const participateRfx = async () => {
    const response = await Api(
      `${POST_RFX_INVITATION_STATUS_API}/${rfxSupplierDetails?._id}`,
      {
        status: AUCTION_ACCEPTED,
      },
      "post"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        getRfxDetails();
      }
    }
  };

  const declineRfx = async () => {
    const response = await Api(
      `${POST_RFX_INVITATION_STATUS_API}/${rfxSupplierDetails?._id}`,
      {
        status: AUCTION_DECLINED,
      },
      "post"
    );
    if (response.status === 200) {
      history.push("/supplier/rfx/");
    }
  };
  return (
    <div style={{ marginTop: "6em" }}>
      <div
        className="details-banner rfx-seller-banner supplier-aspect"
        style={{ background: "transparent" }}
      >
        <div className="banner-container">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="project-title d-flex align-item-center mt-3">
                <img
                  src={
                    rfxSupplierDetails.rfx_type === RFP_TYPE
                      ? rfpCover
                      : rfxSupplierDetails.rfx_type === RFQ_TYPE
                      ? rfqCover
                      : rfxSupplierDetails.rfx_type === RFI_TYPE
                      ? rfiCover
                      : rfxCover
                  }
                  className="rfx-cover-image"
                  alt=""
                />
                <h1 className="px-2">{rfxSupplierDetails?.rfx_name}</h1>
                <span className="mt-2">
                  <Button
                    className={
                      "badge badge-" +
                      getStatusLabel(rfxSupplierDetails?.status) +
                      " status"
                    }
                    variant="contained"
                  >
                    {RFX_PROGRESS_MAP.get(
                      rfxSupplierDetails?.supplierRfx?.rfxStatus
                    )}
                  </Button>
                </span>
              </div>
              <div className="project-title__smal">
                <span> RFX </span>
                <span>{" / " + rfxSupplierDetails?.rfx_name}</span>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <button className="btn btn-primary go-back-button">
                  <Link to="/supplier/rfx" className="mui-link">
                    <span style={{ color: "var(---2170ff-p-)" }}>
                      <ArrowBackIosNewIcon /> Go Back
                    </span>
                  </Link>
                </button>
              </Grid>
              {rfxSupplierDetails?.supplierStatus &&
              (rfxSupplierDetails?.supplierStatus === AUCTION_INVITED ||
                rfxSupplierDetails?.supplierStatus ===
                  SUPPLIER_AUCTION_PENDING) ? (
                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <button
                    className="btn btn-success btn-mui"
                    onClick={() => participateRfx()}
                  >
                    <span style={{ color: "#fff" }}>Participate</span>
                  </button>

                  <button
                    className="btn btn-danger btn-mui"
                    onClick={() => declineRfx()}
                  >
                    <span style={{ color: "#fff" }}>Decline</span>
                  </button>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RfxSellerBanner;
