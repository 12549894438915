import { Api } from "../../../../api/Api";
import { POST_SUPPLIER_INVITATION_STATUS_API } from "../../../../constants/ApiUrl";
import { AUCTION_ACCEPTED } from "../../../../constants/AppConstants";

const ParticipateInAuctionBL = async (props) => {
  const { auctionId, supplierId } = props;

  let success = false;
  let message = "You have been participated in auction successfully !";

  const response = await Api(
    `${POST_SUPPLIER_INVITATION_STATUS_API}/${auctionId}`,
    {
      status: AUCTION_ACCEPTED,
    },
    "post"
  );
  if (response.status === 200) {
    if (response.status === 200) {
      success = true;
      message = response.data.responseDescription
        ? response.data.responseDescription
        : message;
    } else {
      message = response.data.responseDescription
        ? response.data.responseDescription
        : "You can not participate in this auction";
    }
  } else {
    message = response?.data?.responseDescription
      ? response?.data?.responseDescription
      : response?.message;
  }

  // let response = await axiosInstance.post(
  //   `api/company/supplierInvitationStatus/${auctionId}`,
  //   {
  //     status: "accepted",
  //   }
  // );

  // if (response.status === 200) {
  //   success = true;
  //   message = response.data.responseDescription
  //     ? response.data.responseDescription
  //     : message;
  // } else {
  //   message = response.data.responseDescription
  //     ? response.data.responseDescription
  //     : "You can not participate in this auction";
  // }

  return {
    success: success,
    message: message,
  };
};

export default ParticipateInAuctionBL;
