import React, { useState } from "react";
import { Grid, Box, Tabs, Tab, Paper, Typography } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import useWindowDimensions from "../../../components/useWindowDimensions";
import GeneralInfoTab from "./tabs/GeneralInfoTab";
import SupplierTab from "./tabs/SupplierTab";
import AttachmentTab from "./tabs/AttachmentTab";
import CommentsTab from "./tabs/CommentsTab";
import ItemTab from "./tabs/ItemTab";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentLiveAuction } from "../../../store/selectors/auctionSelector";
import Notification from "../../../components/Notification";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import CancelAuctionBydBL from "../BL/CancelAuctionBydBL";
import { AUCTION_CANCELLED, AUCTION_ENDED_CAPITAL, AUCTION_RUNNING } from "../../../constants/AppConstants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-label={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AuctionTabContainer = (props) => {
  const { currentLiveAuction, refreshLiveAuction, currentUser, getLiveAuctionDetails } = props;
  const [value, setValue] = useState(0);
  const GNInfoTabIX = 0;
  const { mdScreen, smScreen, xsScreen } = useWindowDimensions();
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cancelAuction = () => {
    if (currentLiveAuction && currentLiveAuction.projectId && currentUser) {
      CancelAuctionBydBL({
        auctionId: currentLiveAuction._id,
      }).then((data) => {
        // if (data.success) {
        //   setNotify({
        //     isOpen: true,
        //     message: data.message,
        //     type: "success",
        //   });
        // } else {
          
        //   setNotify({
        //     isOpen: true,
        //     message: data.message,
        //     type: "error",
        //   });
        // }
      });
    }
  };

  const getMargeinTopValue = () => {
    if (xsScreen) {
      return "calc(30em + 4vw)";
    } else if (smScreen) {
      return "calc(12em + 5vw)";
    } else if (mdScreen) {
      return "calc(9em + 1vw)";
    }
  };

  const isOwner = () => {
    if (currentLiveAuction && currentUser) {
      return String(currentLiveAuction.createdBy) === String(currentUser._id);
    }
    return false;
  };

  const isEditor = () => {
    if (currentLiveAuction && currentLiveAuction.projectId && currentUser) {
      if (Array.isArray(currentLiveAuction.projectId.editorIds)) {
        return (
          currentLiveAuction.projectId.editorIds.findIndex(
            (Id) => String(Id) === String(currentUser._id)
          ) >= 0
        );
      }
    }

    return false;
  };

  return (
    <>
      <Grid container className="tab-header-container">
        <Box
          sx={{
            borderRadius: "25px !important",
            marginTop: getMargeinTopValue(),
          }}
        >
          <Tabs
            className="tab-header"
            id="auction-tab-list"
            value={value}
            onChange={handleChange}
          >
            <Tab label="General Information" />
            <Tab label="Suppliers" />
            <Tab label="Items" />
            <Tab label="Attachments" />
            <Tab label="Comments" />
          </Tabs>
        </Box>
        <Box style={{ marginTop: mdScreen ? "2em" : "0em" }}>
          {/* <Controls.Button
            style={{
              backgroundColor: "#424143",
              borderRadius: "12px",
            }}
            text="Put on Hold"
          /> */}
          {currentLiveAuction?.status !== AUCTION_ENDED_CAPITAL &&
          currentLiveAuction?.status !== AUCTION_RUNNING &&
          currentLiveAuction?.status !== AUCTION_CANCELLED ? (
            <Controls.Button
              text="Cancel Auction"
              style={{ backgroundColor: "#dc3545", borderRadius: "12px" }}
              onClick={cancelAuction}
            />
          ) : (
            <></>
          )}
        </Box>
      </Grid>
      <Paper
        className={
          "tab-body-container " + (value === GNInfoTabIX ? "__clear-page" : "")
        }
      >
        <TabPanel value={value} index={0} labelAria="no-padding">
          <GeneralInfoTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
            refreshLiveAuction={refreshLiveAuction}
            getLiveAuctionDetails={getLiveAuctionDetails}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SupplierTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
            refreshLiveAuction={refreshLiveAuction}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ItemTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
            refreshLiveAuction={refreshLiveAuction}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AttachmentTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
            refreshLiveAuction={refreshLiveAuction}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CommentsTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
            refreshLiveAuction={refreshLiveAuction}
          />
        </TabPanel>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(AuctionTabContainer));
