import React, { useEffect, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import { Paper, Typography, Box, Grid, makeStyles } from "@material-ui/core";
import Menu from "@mui/material/Menu";
import { store } from "../../store/store";
import axiosInstance from "../../services/AxiosInstance";
import { Form, useForm } from "../../components/useForm";
import Moment from "moment";
import "../../styles/details-banner.css";
import "../../styles/button.css";
import { HtmlUtilService } from "../../services/HtmlUtilService";
import { AUCTION_ACTIVE, AUCTION_INACTIVE_CAP_SMALL } from "../../constants/AppConstants";

const ItemDetails = (props) => {
  const { setIsShowItemDetails, setRecordForEdit } = props;
  const [editMode, setEditMode] = useState(false);
  const [itemDetails, setItemDetails] = useState({});

  useEffect(() => {
    setItemDetails(setRecordForEdit);
    HtmlUtilService.truncate();
  }, []);

  return (
    <>
      <div className="details-banner">
        <img className="banner-cover" src="" />
        <div className="banner-container">
          <Grid
            container
            style={{
              position: "relative",
            }}
            spacing={1}
          >
            <Grid item xs={12}>
              <h2 className="project-title">
                {/* {`${supplierData?.main_contact?.first_name} ${supplierData?.main_contact?.last_name}`} */}
                dfdfdfdfdfdf
              </h2>

              <div className="project-title__smal">
                <span> Supplier / </span>
                <span>
                  {/* {`${supplierData?.main_contact?.first_name} ${supplierData?.main_contact?.last_name}`} */}
                  dsfsddddd
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <button
              className="btn btn-primary go-back-button"
              onClick={() => setIsShowItemDetails(false)}
            >
              <i class="fas fa-angle-left"></i> Go Back
            </button>
          </Grid>
          <Paper elevation={1} square style={{ marginBottom: "2em" }}>
            <Grid container style={{ padding: "20px" }}>
              <Grid item xs={6}>
                <p style={{ fontWeight: "bold" }}>Supplier Details</p>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end" }}>
                <Button variant="contained">Edit</Button>
              </Grid>
              <Grid item xs={12}>
                <small>Supplier ID</small>
                <p style={{ marginBlockStart: "0px" }}>
                  {"R" + Math.floor(100000 + Math.random() * 900000)}
                </p>
              </Grid>
              <Grid item xs={3}>
                <small>Supplier Status</small>
                <br />
                <PopupState variant="popover">
                  {(popupState) => (
                    <>
                      <Button
                        className="active-btn-item status"
                        variant="contained"
                        {...bindTrigger(popupState)}
                        endIcon={
                          <ArrowDropDownIcon className="icn-active-btn" />
                        }
                      >
                        {AUCTION_ACTIVE}
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {/* {item.status === "Active" ? (<div><button className="active-btn-popup" disabled onClick={() => itemClickOnActive(item)} >Active</button></div>) : (<div><button className="active-btn-popup" onClick={() => itemClickOnActive(item)} >Active</button></div>)} */}
                        <button className="active-btn-popup">
                          {AUCTION_ACTIVE}
                        </button>
                        <br />
                        <button className="inactive-btn-popup">{AUCTION_INACTIVE_CAP_SMALL}</button>
                        {/* {item.status === "Inactive" ? (<button className="inactive-btn-popup" disabled onClick={() => itemClickOnInactive(item)} >Inactive</button>) : (<button className="inactive-btn-popup" onClick={() => itemClickOnInactive(item)} >Inactive</button>)} */}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Grid>
              <Grid item xs={3}>
                <div className="lable">Company</div>
                <textarea
                  className={
                    "field" + " " + (editMode === true ? "__edit" : "__view")
                  }
                  name="company_name"
                  style={{ fontSize: "1em" }}
                  readOnly={!editMode}
                  spellcheck="false"
                  // onChange={handleInputChange}
                  rows="1"
                >
                  {/* {supplierData?.supplier_company?.company_name} */}
                  sdfsdfdsf
                </textarea>
                {/* <p style={{ marginBlockStart: "0px" }}>{supplierData?.supplier_company?.company_name}</p> */}
              </Grid>
              <Grid item xs={3}>
                <small>Country</small>
                {/* <p style={{ marginBlockStart: "0px" }}>{supplierData?.country}</p> */}
                dsfsdfdf
              </Grid>
              <Grid item xs={3}>
                <small>Last Update On: </small>
                {/* <p style={{ marginBlockStart: "0px" }}>{supplierData?.email}</p> */}
                ddddd
              </Grid>
              <Grid item xs={3}>
                <small>Category</small>
                {/* <p style={{ marginBlockStart: "0px" }}>{supplierData?.category?.name}</p> */}
                sdfdsfffff
              </Grid>
              <Grid item xs={3}>
                <small>Email</small>
                {/* <p style={{ marginBlockStart: "0px" }}>{supplierData?.email}</p> */}
                yyyyyyyyyyyy
              </Grid>
              <Grid item xs={3}>
                <small>Main Contact</small>
                {/* <p style={{ marginBlockStart: "0px" }}>{`${supplierData?.main_contact?.first_name} ${supplierData?.main_contact?.last_name}`}</p> */}
                yyyyyyyyyyyyyy
              </Grid>
              <Grid item xs={3}>
                <small>Last Update On: </small>
                {/* <p style={{ marginBlockStart: "0px" }}>{Moment(supplierData?.updatedAt).format('DD/MMMM/YYYY')}</p> */}
                dddddddddddddd
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default ItemDetails;
