import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import faceLogo from "../../assets/auth/Logo_Oktio_last.png";
import textLogo from "../../assets/auth/logotxt.png";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import { useHistory, useParams } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Notification from "../../components/Notification";
import useWindowDimensions from "../../components/useWindowDimensions";
import { Api } from "../../api/Api";
import { PATCH_USER_PASSWORD_CONFIRM_API } from "../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(18),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fdfdfd",
    padding: theme.spacing(4),
    borderRadius: "15px",
  },
  paperRight: {
    marginTop: theme.spacing(15),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "transparent",
    padding: theme.spacing(0),
    borderRadius: "15px",
    boxShadow: "none",
    position: "relative",
  },
  logo: {
    background: "rgb(236 236 236 / 25%)",
    padding: "30px 70px",
    opacity: 0.8,
    borderRadius: "50px 0",
    position: "absolute",
    left: "-80px",
    top: "-30px",
    width: "270px",
    // opacity: "0.6",
  },
  logosvg: {
    marginTop: "100px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    border: "0px solid #ccc",
    borderRadius: "30px",
    textTransform: "none",
  },
  linktxt: {
    textDecoration: "none",
    color: "#2170ff",
  },
  normal: {
    textDecoration: "none",
    fontSize: "11px",
    fontWeight: "bold",
    opacity: "0.7",
  },

  textField: {
    border: "0px solid #ccc",
    [`& fieldset`]: {
      borderRadius: 30,
    },
  },

  txtcheckbox: {
    "& .MuiTypography-body1": {
      fontSize: "13px",
    },
  },
  forgot: {
    display: "flex",
    padding: "10px 0 0 0",
    flexDirection: "column",
    justifyContent: "center",
  },
  input: {
    color: "white",
  },
  error: {
    color: "red",
    fontSize: "11px",
    paddingLeft: "43px",
  },
}));

const initialFValues = {
  password: "",

  cpassword: "",
};
const ResetPassword = (props) => {
  const history = useHistory();
  let { token } = useParams();

  const [errors, setErrors] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { xsScreen, smScreen } = useWindowDimensions();

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleClickShowOpassword = () => {
    setOpassword({ ...cpassword, showOpassword: !cpassword.showOpassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [password, setPassword] = useState({
    password: "",
    showPassword: false,
  });
  const [cpassword, setOpassword] = useState({
    cpassword: "",
    showOpassword: false,
  });
  const classes = useStyles();
  const [values, setValues] = useState(initialFValues);
  const [pass, setPass] = useState("");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("password" in fieldValues) {
      if (fieldValues.password !== "") {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 character";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                if (!/[!@#$*%&_^{}[\]/|\\()?-]/.test(fieldValues.password)) {
                  temp.password = "Password  must be 1 special character";
                } else {
                  setPass(fieldValues.password);
                  temp.password = "";
                }
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }

    if ("cpassword" in fieldValues) {
      if (fieldValues.cpassword !== "") {
        if (String(fieldValues.cpassword) !== String(pass)) {
          temp.cpassword = "Password not matched.";
        } else {
          temp.cpassword = "";
        }
      } else {
        temp.cpassword = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setNotify({
    //   ...notify,
    //   isOpen: false,
    // });
  };

  async function onReset(e) {
    e.preventDefault();

    if (validate()) {
      const payload = {
        new_password: values.cpassword,
      };
      const response = await Api(
        `${PATCH_USER_PASSWORD_CONFIRM_API}/${token}`,
        payload,
        "patch"
      );
      if (response.status === 200) {
        if (response.data.ResponseCode === "00") {
          history.push("/");
        }
      }

      // axiosInstance
      //   .patch(`api/users/passwordConfirm/${token}`, payload)
      //   .then((response) => {
      //     if (response.data.ResponseCode === "00") {
      //       setNotify({
      //         isOpen: true,
      //         message: "Password saved successfully.",
      //         type: "success",
      //       });
      //       history.push("/");
      //     }
      //   })
      //   .catch((err) => {});
    } else {
      toast.error(ValidationMessage.passwordSame, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  }

  return (
    <div className="auth-box-container reset-forgot">
      <CssBaseline />
      <div style={{ flexGrow: 1, marginBottom: "25px" }}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          style={{ flexDirection: smScreen ? "column-reverse" : "row" }}
        >
          {/* <Grid container spacing={2} justifyContent="center"> */}
          <Grid item xl={4} lg={5} md={6} sm={8} xs={11}>
            <div className="form-box-container">
              {props.showLoading && <Loader />}
              <Grid container className="form-banner-container">
                <Grid item xs={12}>
                  <div className="main-title">Reset Password</div>
                </Grid>
              </Grid>
              <form onSubmit={onReset} noValidate>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item sm={10} xs={12}>
                    <TextField
                      variant="outlined"
                      className="mui-input"
                      required
                      fullWidth
                      name="password"
                      id="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      type={cpassword.showOpassword ? "text" : "password"}
                      // onChange={handleOpasswordChange("cpassword")}
                      onChange={handleInputChange}
                      value={values.password}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon color="primary" fontSize="small" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowOpassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {cpassword.showOpassword ? (
                                <Visibility color="primary" fontSize="small" />
                              ) : (
                                <VisibilityOff
                                  color="primary"
                                  fontSize="small"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.password && (
                      <div className={classes.error}>{errors.password}</div>
                    )}
                  </Grid>
                  <Grid item sm={10} xs={12}>
                    <TextField
                      variant="outlined"
                      className="mui-input"
                      required
                      fullWidth
                      name="cpassword"
                      id="cpassword"
                      placeholder="Confirm Password"
                      autoComplete="current-password"
                      type={password.showPassword ? "text" : "password"}
                      // onChange={handlePasswordChange("password")}
                      onChange={handleInputChange}
                      value={values.cpassword}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon color="primary" fontSize="small" />
                          </InputAdornment>
                        ),

                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {password.showPassword ? (
                                <Visibility color="primary" fontSize="small" />
                              ) : (
                                <VisibilityOff
                                  color="primary"
                                  fontSize="small"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.cpassword && (
                      <div className={classes.error}>{errors.cpassword}</div>
                    )}
                  </Grid>
                  <Grid item sm={8} xs={12} className="mt-4">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      // disabled={!vcaptcha}
                      className="submit-btn mt-3"
                    >
                      Save Change
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
          <Grid item lg={4} md={3} xs={8}>
            <Grid
              container
              justifyContent="center"
              alignItems="end"
              style={{ flexDirection: "column" }}
            >
              <Grid item sm={12} style={{ textAlign: "-webkit-center" }}>
                <div className="face-logo">
                  <img src={faceLogo} />
                </div>
              </Grid>
              <Grid item sm={12}>
                <div className="text-logo-box-container">
                  <div className="text-logo-box"></div>
                  <img src={textLogo} className="text-logo-img" />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Snackbar
            // open={notify.isOpen}
            open={false}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={handleClose}
          >
            <Alert severity={notify.type} onClose={handleClose}>
              {notify.message}
            </Alert>
          </Snackbar>
        </Grid>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ResetPassword);
