/*eslint-disable*/
import { Box, Grid, Paper } from "@material-ui/core";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
} from "@mui/material";
import React, { useEffect } from "react";
import LogoIcon from "../../assets/Logo_2.png";
import Search from "@material-ui/icons/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import Controls from "../../components/controls/Controls";
import useWindowDimensions from "../../components/useWindowDimensions";
import InvitedSellerPopup from "./components/InvitedSellerPopup";
import InviteNewSellerPopup from "./components/InviteNewSupplier";
// import FormSteps from "../../components/FormStepAuction";
import FormSteps from "../../components/Formsteps";
import { store } from "../../store/store";
import axiosInstance from "../../services/AxiosInstance";
import {
  SubmitAuction,
  AddSupplier,
  auctionSaveAsDraft,
} from "../../store/actions/auctionAction";
import { useDispatch } from "react-redux";
import ConfirmationDailog from "./components/confirmationDailog";
import CloseIcon from "@mui/icons-material/Close";
import "./styles/details-suplliers.css";
import "./styles/create-auction-step5.css";
import { COMAPNY_VERSION_API } from "../../constants/ApiUrl";
import { Api } from "../../api/Api";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";
import CommonButton from "../../components/controls/CommonButton";

const columns = [
  {
    label: "Company Name",
    minWidth: 250,
  },
  {
    label: "Main Contact Name",
    minWidth: 400,
  },
  {
    label: "Invited Seller",
  },
  {
    label: "",
  },
];

export default function Step5Form(props) {
  const {
    setOpenPopupStep4,
    setOpenPopupStep5,
    setAuctionList,
    setNotify,
    setFullLoder,
    stepLabels,
    setResendApi,
    fullLoder,
  } = props;
  const dispatch = useDispatch();
  const [suppliers, setSuppliers] = React.useState([]);
  const [newInvitedSuppliers, setNewInvitedSuppliers] = React.useState([]);
  const [suppliersTable, setSuppliersTable] = React.useState([]);
  const [dropDownSuppliers, setdropDownSuppliers] = React.useState([]);
  const [openSellerPopup, setOpenSellerPopup] = React.useState(false);
  const [openInviteSupplierPopup, setOpenInviteSupplierPopup] =
    React.useState(false);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  React.useState(null);
  const [openConfrmationDailog, setOpenConfrmationDailog] =
    React.useState(false);
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  /*V1 */
  const getAllItem = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(
      `${COMAPNY_VERSION_API}/${companyId}/suppliersActive`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.suppliers;

        let companyData = Object.values(allItems);
        let final = [];
        companyData.map((item) => final.push(item[item.length - 1]));
        final.map((item) => {
          item.supplier_company_name = item?.supplier_company?.company_name;
        });
        setdropDownSuppliers(final);
      }
    }

    // await axiosInstance
    //   .get(`api/buyer/v1/company/${companyId}/suppliersActive`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allItems = response.data.suppliers;

    //       let companyData = Object.values(allItems);
    //       let final = [];
    //       companyData.map((item) => final.push(item[item.length - 1]));
    //       final.map((item) => {
    //         item.supplier_company_name = item?.supplier_company?.company_name;
    //       });
    //       setdropDownSuppliers(final);
    //     }
    //   })
    //   .catch((err) => {});
  };

  const getSelectedSupplier = (supplier) => {
    if (supplier) {
      const { auth } = store.getState();
      let oldSuppliers = [...suppliers];
      const companyName = auth?.auth?.user?.company?.company_name;
      const companyId = auth?.auth?.user?.company?._id;
      const MainContactName =
        supplier?.main_contact?.first_name +
        " " +
        supplier?.main_contact?.last_name;
      const supplierEmail = {
        email: supplier.email,
        supplierId: supplier?.supplier_company?._id,
        supplierCompanyName: supplier?.supplier_company?.company_name,
        mainContact: MainContactName,
        userId: supplier?.main_contact?._id,
        companyId: companyId,
        company_buyer_name: companyName,
        supplierCompanyId: supplier?.supplier_company?._id,
        selectedInviteSupplier: [],
      };

      let anyObj = oldSuppliers.find(
        (item) => item.userId === supplierEmail.userId
      );

      if (anyObj && anyObj.userId) {
        return;
      }
      suppliers.push(supplierEmail);
      const neItems = [...suppliers];
      setSuppliers(neItems);
      dispatch(SubmitAuction({ Suppliers: suppliers }));
    }
  };

  const submitAuction = async () => {
    if (suppliers && suppliers.length > 0) {
      setFullLoder(true);

      let res = await dispatch(AddSupplier(setFullLoder));

      if (res?.data?.responseCode === "00") {
        setFullLoder(false);
        if (setResendApi) {
          setResendApi(true);
        }
        setOpenPopupStep5(false);
      } else {
        setFullLoder(false);
        setOpenPopupStep5(false);
      }
      // const saveQuery = setTimeout(() => {
      //   const { auction } = store.getState();
      //   if (auction?.SaveSuccess === true) {
      //     setNotify({
      //       isOpen: true,
      //       message: "Auction created successfully..",
      //       type: "success",
      //     });
      //     setOpenPopupStep5(false);
      //     getAllAuction();
      //   } else {
      //     setNotify({
      //       isOpen: true,
      //       message: auction.httpMessage
      //         ? auction.httpMessage
      //         : "creating a new auction has been failed !",
      //       type: "error",
      //     });
      //   }
      // }, 1500);

      // const loader = setTimeout(() => {
      //   setFullLoder(false);
      // }, 3000);

      // return () => {
      //   // clearTimeout(saveQuery);
      //   // clearTimeout(loader);
      // };
    } else {
      toast.error(ValidationMessage.selectSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const saveAsDraft = async () => {
    if (suppliers && suppliers.length > 0) {
      setFullLoder(true);

      let res = await dispatch(auctionSaveAsDraft());
      if (res?.data?.responseCode === "00") {
        setFullLoder(false);
        setOpenPopupStep5(false);
      } else {
        setFullLoder(false);
        setOpenPopupStep5(false);
      }
      // const saveQuery = setTimeout(() => {
      //   const { auction } = store.getState();
      //   if (auction?.SaveSuccess === true) {
      //     setNotify({
      //       isOpen: true,
      //       message: "Auction created as draft successfully..",
      //       type: "success",
      //     });
      //     setOpenPopupStep5(false);
      //     getAllAuction();
      //   } else {
      //     setNotify({
      //       isOpen: true,
      //       message: "Auction create as draft failed",
      //       type: "error",
      //     });
      //   }
      // }, 1500);

      // const loader = setTimeout(() => {
      //   setFullLoder(false);
      // }, 3000);

      // return () => {
      //   clearTimeout(saveQuery);
      //   clearTimeout(loader);
      // };
    } else {
      toast.error(ValidationMessage.selectSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const getAllAuction = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(`${AUCTION_LIST_API}/${companyId}`, {}, "get");
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allAuctionList = response.data.auction;
          setAuctionList(allAuctionList);
        }
      }

      // await axiosInstance
      //   .get(`api/buyer/auctionList/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let allAuctionList = response.data.auction;
      //       setAuctionList(allAuctionList);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const pressBack = () => {
    dispatch(SubmitAuction({ Suppliers: suppliers }));
    setOpenPopupStep5(false);
    setOpenPopupStep4(true);
  };

  const getImportBoxSize = (dir = "left") => {
    if (dir === "left") {
      if (xlScreen) {
        return "w80-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w60-percent-box";
      } else if (mdScreen) {
        return "w73-percent-box";
      } else if (lgScreen) {
        return "w79-percent-box";
      }
    } else {
      if (xlScreen) {
        return "w19-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w40-percent-box";
      } else if (mdScreen) {
        return "w26-percent-box";
      } else if (lgScreen) {
        return "w20-percent-box";
      }
    }
  };

  const getSellerLabel = (supplier) => {
    return (
      <div
        className="sellers-link-container"
        onClick={() => {
          setOpenSellerPopup(true);
          setSelectedSupplier(supplier);
        }}
      >
        <div className="label">
          {supplier &&
          supplier.selectedInviteSupplier &&
          supplier.selectedInviteSupplier.length > 0
            ? `${supplier.selectedInviteSupplier.length} invited`
            : "All"}
        </div>
        <PlaylistAddIcon className="icon" />
      </div>
    );
  };

  const removeSelectIndex = (index) => {
    const removeIndex = suppliers.splice(index, 1);
    const neItems = [...suppliers];
    setSuppliers(neItems);
  };

  const discardChanges = () => {
    setOpenConfrmationDailog(true);
  };

  useEffect(() => {
    const { auction } = store.getState();
    if (auction?.AuctionForm1?.Suppliers) {
      setSuppliers(auction?.AuctionForm1?.Suppliers);
    }
    getAllItem();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <p className="profile-title"> Create English Auction</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <CloseIcon
            onClick={() => discardChanges()}
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <div className="create-form-container">
        <Grid container justifyContent="center">
          <Grid item md={11} xs={12}>
            <FormSteps steps={stepLabels} currStep={5} className="mt-5 mb-5" />
          </Grid>
        </Grid>
        <Paper elevation={0} square>
          {/* import item Section  */}
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid className={getImportBoxSize("left")}>
              <Autocomplete
                disablePortal
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                labelId="category-input"
                options={dropDownSuppliers}
                onChange={(event, value) => getSelectedSupplier(value)}
                getOptionLabel={(option) =>
                  option ? option.supplier_company_name : ""
                }
                renderInput={(params) => (
                  <Controls.Input
                    name="budget"
                    labelId="budget-input"
                    fullWidth
                    {...params}
                  />
                )}
                popupIcon={<Search />}
              />
            </Grid>
            <Grid className={getImportBoxSize("right")}>
              <Controls.Button
                text="Invite New Supplier"
                disableElevation
                color="default"
                onClick={() => setOpenInviteSupplierPopup(true)}
                startIcon={<PersonAddAltRoundedIcon />}
                className="btn-mui btn-white auto-complete-btn"
              />
            </Grid>
          </Grid>
          {/* import item Section  */}

          <h3 className="b-600 mb-0 mt-3">Added Suppliers</h3>

          <hr style={{ backgroundColor: "#e0e0e0", opacity: "0.5" }} />

          <TableContainer
            className="table-container editable-table"
            style={{ position: "relative" }}
            component={Paper}
          >
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {suppliers.map((supplier, index) => (
                  <TableRow key={supplier?.supplierId} className="editable-row">
                    <TableCell>{supplier?.supplierCompanyName}</TableCell>
                    <TableCell>{supplier?.mainContact}</TableCell>
                    <TableCell>
                      {/* {supplier && supplier.isInvieted
                        ? "-"
                        : getSellerLabel(supplier)} */}
                      {getSellerLabel(supplier)}
                    </TableCell>
                    <TableCell>
                      <Box className="option-cell __active">
                        <DeleteOutlineIcon
                          className="icon __edit clr-ff0000"
                          onClick={() => removeSelectIndex(index)}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal
            open={openSellerPopup}
            aria-label="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="popup-form-container invite-seller-box">
              <div className="title"> Edit Sellers Invited </div>
              <div className="label">Company Name</div>
              <div className="title">
                <div className="icon-cotainer">
                  <img src={LogoIcon} className="icon" />
                </div>
                <div className="text">
                  {selectedSupplier?.supplierCompanyName}
                </div>
              </div>
              <InvitedSellerPopup
                openPopup={openSellerPopup}
                setOpenPopup={setOpenSellerPopup}
                supplier={selectedSupplier}
                suppliers={suppliers}
                setSuppliers={setSuppliers}
              />
            </Box>
          </Modal>

          {/* invite new supplier popup */}
          <Modal
            open={openInviteSupplierPopup}
            aria-label="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="popup-form-container invite-seller-box">
              <div className="title"> Invite New Supplier </div>
              <InviteNewSellerPopup
                openInviteSupplierPopup={openInviteSupplierPopup}
                setOpenInviteSupplierPopup={setOpenInviteSupplierPopup}
                newInvitedSuppliers={newInvitedSuppliers}
                setNewInvitedSuppliers={setNewInvitedSuppliers}
                suppliersTable={suppliersTable}
                setSuppliersTable={setSuppliersTable}
                suppliers={suppliers}
                setSuppliers={setSuppliers}
                isDispatch={true}
              />
            </Box>
          </Modal>
        </Paper>

        <Box
          className="BottomModal"
          display="flex"
          p={1}
          bgcolor="background.paper"
          style={{ marginTop: "20px" }}
        >
          <Grid istem xs={4} className="BottomModal-left">
            <Box p={0} flexGrow={1}>
              <Controls.Button
                text="Back"
                disableElevation
                onClick={() => {
                  pressBack();
                }}
                style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                color="default"
                className="btn-mui fix-w-12em"
              />
            </Box>
          </Grid>
          <Grid item xs={8} className="BottomModal-right">
            <Box p={0}>
              <CommonButton
                text="Save as Draft"
                type="submit"
                disableElevation
                color="default"
                style={{ margin: "0 20px 0 0" }}
                onClick={() => saveAsDraft()}
                className="btn-mui fix-w-12em"
                loader={fullLoder}
              />
            </Box>
            <Box p={0}>
              <CommonButton
                // type="submit"
                disableElevation
                text="Post"
                onClick={() => submitAuction()}
                className="btn-mui fix-w-12em"
                loader={fullLoder}
              />
            </Box>
          </Grid>
        </Box>
      </div>

      <ConfirmationDailog
        openConfrmationDailog={openConfrmationDailog}
        setOpenConfrmationDailog={setOpenConfrmationDailog}
        setDiscardAndClose={setOpenPopupStep5}
        eraseEverything={false}
      />
    </>
  );
}
