import React from "react";
import { Skeleton, Stack } from "@mui/material";
import { Box } from "@material-ui/core";

const ChatSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "80px",
      }}
    >
      <Stack
        spacing={1}
        sx={{
          paddingInline: "1rem",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Skeleton variant="circular" width={60} height={60} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "30%", height: "50px" }}
          />
        </Box>
        <Skeleton variant="rectangular" width={"70%"} height={60} />
        <Skeleton variant="rounded" width={"70%"} height={60} />
      </Stack>
      <Stack
        spacing={1}
        sx={{
          paddingInline: "1rem",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Skeleton variant="circular" width={60} height={60} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "30%", height: "50px" }}
          />
        </Box>
        <Skeleton variant="rectangular" width={"70%"} height={60} />
        <Skeleton variant="rounded" width={"70%"} height={60} />
      </Stack>
      <Stack
        spacing={1}
        sx={{
          paddingInline: "1rem",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Skeleton variant="circular" width={60} height={60} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "30%", height: "50px" }}
          />
        </Box>
        <Skeleton variant="rectangular" width={"70%"} height={60} />
        <Skeleton variant="rounded" width={"70%"} height={60} />
      </Stack>
    </Box>
  );
};

export default ChatSkeleton;
