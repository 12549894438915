import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import { useForm, Form } from "../../../components/useForm";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import "../style/filter-box.css";
import { Api } from "../../../api/Api";
import {
  GET_COMPANY_ITEM_CATEGORY_API,
  GET_ITEM_API,
} from "../../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_INACTIVE_CAP_SMALL,
} from "../../../constants/AppConstants";

const initialFValues = {
  category: "",
  model: "",
  manufacturer: "",
  status: "",
};

export default function ItemFilter(props) {
  const { setItemList, setFullLoder, setShowFilterCategories } = props;
  const [dropdownCategoryList, setDropdownCategoryList] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { auth } = store.getState();
    const companyName = auth?.auth?.user?.company?.company_name;
    const companyId = auth?.auth?.user?.company?._id;

    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      if (i == 0) {
        string += `?${key}=${value}`;
      } else {
        string += `&${key}=${value}`;
      }
      i++;
    }

    localStorage.setItem("itemFilter", JSON.stringify(values));
    const response = await Api(
      `${GET_ITEM_API}/${companyId}${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.item;
        setItemList(allItems);
        setShowFilterCategories(false);
      } else {
        setItemList([]);
        setShowFilterCategories(false);
      }
    } else {
      setShowFilterCategories(false);
      setItemList([]);
    }

    // await axiosInstance
    //   .get(`api/buyer/item/${companyId}${string}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allItems = response.data.item;
    //       setItemList(allItems);
    //       setShowFilterCategories(false);
    //     } else {
    //       setItemList([]);
    //       setShowFilterCategories(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setShowFilterCategories(false);
    //     setItemList([]);
    //   });
  };

  const getAllCategory = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COMPANY_ITEM_CATEGORY_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let CatAndGroups = response.data.itemsCatAndGroups;

          let cateArr = [];
          CatAndGroups.map((group) => {
            if (group.itemType === "category") {
              cateArr.push(group);
            }
          });
          setDropdownCategoryList(cateArr);
        }
      }

      // await axiosInstance
      //   .get(`api/buyer/company/getItemsCatGroups/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let CatAndGroups = response.data.itemsCatAndGroups;

      //       let cateArr = [];
      //       CatAndGroups.map((group) => {
      //         if (group.itemType === "category") {
      //           cateArr.push(group);
      //         }
      //       });
      //       setDropdownCategoryList(cateArr);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const clearFilter = async () => {
    resetForm();
    const { auth } = store.getState();

    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(`${GET_ITEM_API}/${companyId}`, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.item;
        localStorage.removeItem("itemFilter");

        setShowFilterCategories(false);
        const query = setTimeout(() => {
          setItemList(allItems);
        }, 400);
        return () => {
          clearTimeout(query);
        };
      }
    } else {
      setFullLoder(false);
    }

    // await axiosInstance
    //   .get(`api/buyer/item/${companyId}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allItems = response.data.item;
    //       localStorage.removeItem("itemFilter");

    //       setShowFilterCategories(false);
    //       const query = setTimeout(() => {
    //         setItemList(allItems);
    //       }, 400);
    //       return () => {
    //         clearTimeout(query);
    //       };
    //     }
    //   })
    //   .catch((err) => {
    //     setFullLoder(false);
    //   });
  };

  useEffect(() => {
    getAllCategory();
    const filterData = localStorage.getItem("itemFilter");
    if (filterData) {
      const filterObj = JSON.parse(filterData);
      setValues(filterObj);
    }
  }, []);

  return (
    // Block Filter
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item md={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12}>
              <InputLabel>Category</InputLabel>
              <Controls.Select
                name="category"
                value={values.category}
                autoWidth
                onChange={handleInputChange}
                options={dropdownCategoryList}
                className="w-100 b-600 ml-0"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <InputLabel>Model</InputLabel>
              <Controls.Input
                name="model"
                value={values.model}
                onChange={handleInputChange}
                autoWidth
                className="w-100 b-600 ml-0"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <InputLabel>Manufacturer</InputLabel>
              <Controls.Input
                name="manufacturer"
                autoWidth
                value={values.manufacturer}
                onChange={handleInputChange}
                className="w-100 b-600 ml-0"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <InputLabel>Status</InputLabel>
              <Controls.Select
                value={values.status}
                onChange={handleInputChange}
                autoWidth
                name="status"
                options={[
                  { name: "All Status", _id: "" },
                  { name: AUCTION_ACTIVE, _id: AUCTION_ACTIVE },
                  {
                    name: AUCTION_INACTIVE_CAP_SMALL,
                    _id: AUCTION_INACTIVE_CAP_SMALL,
                  },
                ]}
                emptyOption={false}
                className="w-100 b-600 ml-0"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Controls.Button
                text="Clear"
                disableElevation
                color="default"
                className="btn-mui"
                style={{ width: "100%", marginBottom: "0px" }}
                onClick={() => clearFilter()}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controls.Button
                type="submit"
                disableElevation
                text="Apply Filter"
                className="btn-mui"
                //   white-space: nowrap;
                style={{ width: "100%", marginBottom: "0px" }}
                m={0}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}
