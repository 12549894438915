import userImage from "../assets/user.jpg";

export const SupplierAuctionRecords = [
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  }, {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },

  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
  {
    "status": "Published",
    "_id": "61f7e857bbc2ac313465b2a8",
    "projectId": "61c868ba3ebc5e375022d08b",
    "auctionId": "A561851",
    "auction_type": "2",
    "auction_name": "Testing",
    "startDate": "2022-01-31T13:45:58.236Z",
    "endDate": "2022-01-31T13:45:58.236Z",
    "cost_center": "61fbaacec9d102261123e2ae",
    "companyId": "61c04c994423792e8c5423fc",
    "suppliers": [
      {
        "_id": "61f7e857bbc2ac313465b2aa",
        "email": "testuser@yopmail.com"
      }
    ],
    "buyer": {
      "_id": "61f7e857bbc2ac313465b2aa",
      "email": "testuser@yopmail.com",
      "name": "Dnata"
    }
    ,
    "createdDate": "2022-02-08T13:46:28.565Z",
    image: userImage,
    manager_name: "John Doe",
  },
];
