import { CssBaseline, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../components/useWindowDimensions";
import faceLogo from "../../assets/auth/Logo_Oktio_last.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "../Signup/styles/login.css";
import { Link, useParams } from "react-router-dom";
import {
  AUCTION_ACCEPTED_CAPITAL,
  AUCTION_LABEL_DECLINE,
  AUCTION_LABEL_SUCCESS,
  TOAST_AUTO_CLOSE,
} from "../../constants/AppConstants";
import { Api } from "../../api/Api";
import { GET_INVITATION_STATUS } from "../../constants/ApiUrl";
import invitationError from "../../assets/InvitationError.png";
import { Slide, toast } from "react-toastify";
import SupplierInvitationShimmer from "../Auction/components/shimmer/SupplierInvitationShimmer";

const SupplierInvitation = () => {
  const { smScreen } = useWindowDimensions();
  let { supplierId, status } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [statusLabel, setStatusLabel] = useState("");
  useEffect(() => {
    getInvitationStatus();
  }, []);

  const getInvitationStatus = async () => {
    setShowLoader(true);
    const response = await Api(
      `${GET_INVITATION_STATUS}/${supplierId}/${status}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (!response.data.isError) {
        setStatusLabel(response.data.status);
      } else {
        toast.error(response.data.responseDescription, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    } else {
      toast.error(response.data.responseDescription, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
    setShowLoader(false);
  };
  return (
    <div className="auth-box-container login">
      <CssBaseline />
      <div style={{ flexGrow: 1, marginBottom: "25px" }}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          style={{ flexDirection: smScreen ? "column-reverse" : "row" }}
        >
          <Grid item xl={4} lg={5} md={6} sm={8} xs={11}>
            <div className="form-box-container">
              {!showLoader ? (
                <Grid container className="form-banner-container">
                  <Grid item xs={12} className="invitation-data">
                    {statusLabel.length > 0 ? (
                      <div>
                        <div className="invite-icon-status">
                          {statusLabel === AUCTION_ACCEPTED_CAPITAL ? (
                            <CheckCircleIcon
                              className="invited-icon"
                              color="success"
                            />
                          ) : (
                            <CancelOutlinedIcon
                              className="invited-icon"
                              color="error"
                            />
                          )}
                        </div>
                        <div className="main-title">
                          {statusLabel === AUCTION_ACCEPTED_CAPITAL
                            ? AUCTION_LABEL_SUCCESS
                            : AUCTION_LABEL_DECLINE}
                        </div>

                        <div className="sub-title">
                          {statusLabel === AUCTION_ACCEPTED_CAPITAL
                            ? "You have accepted the supplier's invitation successfully!!"
                            : "You have declined the supplier's invitation successfully!!"}
                        </div>
                      </div>
                    ) : (
                      <img src={invitationError} alt="" />
                    )}
                  </Grid>
                  <Grid item xs={12} className="mb-3 mt-3">
                    <div className="text-center mt-5 mb-5 fz-16">
                      <Link to="/signin">Go back to Sign in</Link>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <SupplierInvitationShimmer />
              )}
            </div>
          </Grid>
          <Grid item lg={4} md={3} xs={8}>
            <Grid
              container
              justifyContent="center"
              alignItems="end"
              style={{ flexDirection: "column" }}
            >
              <Grid item sm={12} style={{ textAlign: "-webkit-center" }}>
                <div className="face-logo">
                  <img src={faceLogo} alt="" />
                </div>
              </Grid>
              <Grid item sm={12}>
                <div className="text-logo-box-container">
                  <div className="text-logo-box">
                    <span>oktio</span>
                  </div>
                  {/* <div className="text-logo-img"></div> */}
                  {/* <img src={textLogo} className="text-logo-img" /> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SupplierInvitation;
