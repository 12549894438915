import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import { Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import * as employeeService from "../../../services/employeeService";
import { store } from "../../../store/store";
import { useForm, Form } from "../../../components/useForm";
import { Api } from "../../../api/Api";
import { ONLY_COMAPNY_API } from "../../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_INACTIVE_CAP_SMALL,
} from "../../../constants/AppConstants";

const initialFValues = {
  status: "",
  role: "",
};

const getNewUserRole = [
  { id: "seller", title: "Seller" },
  { id: "buyer", title: "Buyer" },
  { id: "both", title: "Buyer / Seller" },
];

export default function UserFilter(props) {
  const { setUserList, itemList, setShowFilterCategories } = props;
  const [category, setCategory] = React.useState("");
  const [model, setModel] = React.useState("");
  const [manufacturer, setManufacturer] = React.useState("");
  const [status, setStatus] = React.useState("");

  const [allOptions, setAllOptions] = React.useState(
    employeeService.getDepartmentCollection()
  );
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let string = "";
    localStorage.setItem("companyUserFilter", JSON.stringify(values));
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      if (i === 0) {
        string += `?${key}=${value}`;
      } else {
        string += `&${key}=${value}`;
      }
      //
      i++;
    }
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;
    const response = await Api(
      `${ONLY_COMAPNY_API}/${companyId}/members${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.companyTeamMembers;
        setUserList(allItems);
        setShowFilterCategories(false);
      } else {
        setUserList([]);
        setShowFilterCategories(false);
      }
    } else {
    }

    // await axiosInstance
    //   .get(`api/company/${companyId}/members${string}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allItems = response.data.companyTeamMembers;
    //       setUserList(allItems);
    //       setShowFilterCategories(false);
    //     } else {
    //       setUserList([]);
    //       setShowFilterCategories(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setUserList([]);
    //     setShowFilterCategories(false);
    //   });
  };

  React.useEffect(() => {
    const filterData = localStorage.getItem("companyUserFilter");

    if (filterData) {
      const filterObj = JSON.parse(filterData);
      setValues(filterObj);
    }
  }, []);

  const clearFilter = async () => {
    resetForm();
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;
    const response = await Api(
      `${ONLY_COMAPNY_API}/${companyId}/members`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.companyTeamMembers;
        setUserList(allItems);
        localStorage.removeItem("companyUserFilter");
        setShowFilterCategories(false);
      }
    } else {
      setUserList([]);
    }

    // await axiosInstance
    //   .get(`api/company/${companyId}/members`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allItems = response.data.companyTeamMembers;
    //       setUserList(allItems);
    //       localStorage.removeItem("companyUserFilter");
    //       setShowFilterCategories(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setUserList([]);
    //   });
  };

  return (
    // Block Filter
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ alignItems: "flex-end" }}>
        <Grid item lg={2} sm={4} xs={12}>
          <InputLabel className="mui-label" id="model-filter-input">
            Role
          </InputLabel>
          <Controls.Select
            labelId="role-input"
            name="role"
            value={values.role}
            autoWidth
            onChange={handleInputChange}
            options={getNewUserRole}
            error={errors.role}
            className="StepControlInput"
          />
        </Grid>
        <Grid item lg={2} sm={4} xs={12}>
          <InputLabel className="mui-label" id="status-filter-input">
            Status
          </InputLabel>
          <Controls.Select
            labelId="status-filter-input"
            name="status"
            value={values.status}
            autoWidth
            onChange={handleInputChange}
            options={[
              {
                id: AUCTION_ACTIVE,
                name: AUCTION_ACTIVE,
              },
              {
                id: AUCTION_INACTIVE_CAP_SMALL,
                name: AUCTION_INACTIVE_CAP_SMALL,
              },
            ]}
            error={errors.status}
            className="StepControlInput"
          />
        </Grid>
        <Grid item lg={4} sm={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controls.Button
                text="Clear"
                disableElevation
                color="default"
                className="btn-mui w-100 m-0"
                onClick={() => clearFilter()}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Button
                type="submit"
                disableElevation
                text="Apply Filter"
                className="btn-mui w-100 m-0"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}
