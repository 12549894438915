import { Grid } from "@material-ui/core";

const AuctionBannerShimmer = () => {
  return (
    <Grid container alignItems="center" style={{ position: "relative" }}>
      <Grid item md={9} sm={12}>
        <Grid container spacing={2}>
          <Grid
            item
            lg={1}
            sm={2}
            xs={12}
            style={{ margin: "auto 0px" }}
          ></Grid>
          <Grid item xl={2} lg={3} md={4} sm={5} xs={12}>
            <div className="lable cu-shimmer  h15 mb5 br10"></div>
            <div className="cu-shimmer h15 br10"></div>

            <div style={{ display: "flex", marginTop: "1em" }}>
              <div style={{ height: "30px" }} className="cu-shimmer"></div>
            </div>
          </Grid>
          <Grid item lg={4} sm={5} xs={12}>
            <div className="lable cu-shimmer h15 mb5 br10"></div>
            <div className="cu-shimmer h15 br10"></div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className="lable cu-shimmer  h15 mb5 br10"></div>
            <div className="cu-shimmer h15 br10 mb10"></div>

            <div className="lable cu-shimmer h15 mb5 br10"></div>
            <div className="cu-shimmer h15 br10"></div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3} sm={12}>
        <div className="item-center">
          <div className="cu-shimmer w50 h50" />
          <div className="ml-2">
            <div className="lable cu-shimmer w160 h15 mb5 br10"></div>
            <div className="field __view cu-shimmer w160 h15 br10"></div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default AuctionBannerShimmer;
