import { Box, Grid } from "@material-ui/core";
import { InputLabel } from "@mui/material";
import { useEffect } from "react";
import Controls from "../../../../components/controls/Controls";
import { Form, useForm } from "../../../../components/useForm";
import { store } from "../../../../store/store";
import { Api } from "../../../../api/Api";
import {
  ONLY_COMAPNY_API,
  POST_EDIT_ATTACHMENT_API,
} from "../../../../constants/ApiUrl";

const initialFValues = {
  link: "",
  name: "",
  notes: "",
};

export default function CompanyAttachLinkPopup(props) {
  const {
    setOpenPopup,
    getAllDocuments,
    setNotify,
    editLinkData,
    linkIsEdit,
    setEditLinkData,
    setLinkIsEdit,
    isFromSupplier,
    supplier,
    setFullLoder,
  } = props;

  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("link" in fieldValues)
      temp.link = fieldValues.link
        ? !regex.test(fieldValues.link)
          ? "Please enter valid link"
          : ""
        : "This field is required.";
    // temp.link = fieldValues.link ? "" : "This field is required.";

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("notes" in fieldValues)
      temp.notes = fieldValues.notes ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setFullLoder(true);
      const { auth } = store.getState();
      const userId = auth?.auth?.user?._id;
      let companyId = auth?.auth?.user?.company?._id;
      if (isFromSupplier) {
        companyId = supplier?.supplier_company?._id;
      }
      let formData = new FormData();
      formData.append("link", values.link);
      formData.append("name", values.name);
      formData.append("note", values.notes);
      formData.append("userId", userId);
      formData.append("isSeller", isFromSupplier);
      if (!linkIsEdit) {
        const response = await Api(
          `${ONLY_COMAPNY_API}/${companyId}/addCompanyAttachment`,
          formData,
          "postMultipart"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            resetForm();
            setOpenPopup(false);
            getAllDocuments();
          }
          setFullLoder(false);
        } else {
          setFullLoder(false);
        }

        // axiosInstance
        //   .post(`api/company/${companyId}/addCompanyAttachment`, formData)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       resetForm();
        //       setOpenPopup(false);
        //       getAllDocuments();
        //       setNotify({
        //         isOpen: true,
        //         message: "Link created successfully.",
        //         type: "success",
        //       });
        //     } else {
        //       setNotify({
        //         isOpen: true,
        //         message: response?.data?.responseDescription,
        //         type: "error",
        //       });
        //     }
        //     setFullLoder(false);
        //   })
        //   .catch((err) => {
        //     setNotify({
        //       isOpen: true,
        //       message: err?.response?.data?.responseDescription,
        //       type: "error",
        //     });
        //     setFullLoder(false);
        //   });
      } else {
        if (editLinkData && editLinkData?._id) {
          const response = await Api(
            `${POST_EDIT_ATTACHMENT_API}/${editLinkData?._id}`,
            formData,
            "postMultipart"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              resetForm();
              setOpenPopup(false);
              getAllDocuments();
              setEditLinkData({});
              setLinkIsEdit(false);
            }
            setFullLoder(false);
          } else {
            setFullLoder(false);
          }

          // axiosInstance
          //   .post(
          //     `api/company/editCompanyAttachment/${editLinkData?._id}`,
          //     formData
          //   )
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       resetForm();
          //       setOpenPopup(false);
          //       getAllDocuments();
          //       setEditLinkData({});
          //       setLinkIsEdit(false);
          //       setNotify({
          //         isOpen: true,
          //         message: "Link updated successfully.",
          //         type: "success",
          //       });
          //     } else {
          //       setNotify({
          //         isOpen: true,
          //         message: response?.data?.responseDescription,
          //         type: "error",
          //       });
          //     }
          //     setFullLoder(false);
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //     setFullLoder(false);
          //   });
        }
      }
    }
  };

  const pressCancel = () => {
    resetForm();
    setEditLinkData({});
    setLinkIsEdit(false);
    setOpenPopup(false);
  };

  useEffect(() => {
    if (editLinkData && editLinkData?.link) {
      setValues({
        link: editLinkData?.link,
        name: editLinkData?.name,
        notes: editLinkData?.note,
      });
    }
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel className="mui-label">Link</InputLabel>
            <Controls.Input
              className="mui-input"
              fullWidth
              name="link"
              onChange={handleInputChange}
              value={values.link}
              error={errors.link}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="mui-label">Name</InputLabel>
            <Controls.Input
              className="mui-input"
              fullWidth
              name="name"
              onChange={handleInputChange}
              value={values.name}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="mui-label">Notes</InputLabel>
            <Controls.Input
              fullWidth
              name="notes"
              onChange={handleInputChange}
              value={values.notes}
              error={errors.notes}
            />
          </Grid>
        </Grid>
        <Box display="flex" mt={4} bgcolor="background.paper">
          <Grid istem xs={4} className="BottomModal-left">
            <Box p={0} flexGrow={1}>
              <Controls.Button
                text="Cancel"
                disableElevation
                onClick={() => pressCancel()}
                style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                color="default"
                className="btn-mui"
              />
            </Box>
          </Grid>
          <Grid item xs={8} className="BottomModal-right">
            <Box p={0}>
              <Controls.Button
                type="submit"
                disableElevation
                text="Attach"
                className="btn-mui"
                style={{ minWidth: "197px" }}
              />
            </Box>
          </Grid>
        </Box>
      </Form>
    </>
  );
}
