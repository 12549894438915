import {
  AUCTION_COMPLETED,
  AUCTION_RUNNING,
  RFX_SUPPLIER_DECLINED,
} from "../constants/AppConstants";

export const updateUserDetails = (isBuyer, role) => {
  let userData = JSON.parse(localStorage.getItem("userDetails"));
  let updatedUser = { ...userData, user: { ...userData.user, isBuyer, role } };
  localStorage.setItem("userDetails", JSON.stringify(updatedUser));
};
export const updateUserProfileImage = (img, first_name, last_name) => {
  let userData = JSON.parse(localStorage.getItem("userDetails"));
  let updatedUser = {
    ...userData,
    user: { ...userData.user, image_upload: img, first_name, last_name },
  };
  localStorage.setItem("userDetails", JSON.stringify(updatedUser));
};

export const setRoleInLocalStorage = (role) => {
  localStorage.setItem("userRole", JSON.stringify(role));
};

export const getRoleFromLocalStorage = () => {
  const role = JSON.parse(localStorage.getItem("userRole"));
  return role;
};

export const getUserDetails = () => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  return user;
};

export const convertObjToQueryString = (obj) => {
  /*eslint-disable*/
  // ?search=jira
  return "?" + new URLSearchParams(obj).toString();
};

export const removeNullValueForPaylaod = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== 0)
      if (!obj[key]) {
        delete obj[key];
      }
  });

  return obj;
};
export const thousandSepratorHandler = (x) => {
  const min = 0;
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return min;
};
export const thousandRemoverHandler = (x) => {
  const min = 0;
  if (x) return parseInt(x.toString().replace(/,/g, ""));
  else return min;
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const checkHttpInclude = (str) => {
  if (str && str.length > 0) {
    if (str?.includes("https://") || str?.includes("http://")) {
      return str;
    } else {
      return "https://" + str;
    }
  }
};

export function downloadDocumentFile(url, name) {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert("An error sorry"));
}

export function downloadDocumentFile2(fileUrl, linkUrl, fileName) {
  
  if (linkUrl) {
    // If a link URL exists, open it in a new tab
    window.open(linkUrl, "_blank");
  }
  if (fileUrl) {
    // If a file URL exists, download the file
    fetch(fileUrl)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName; // You can set the desired filename here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("An error occurred while downloading the file"));
  }
}

//CHECK VALID DATE
export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getTimeDiffernce = (startDate, endDate) => {
  const formatedStartDate = new Date(startDate);
  const formatedEndDate = new Date(endDate);
  const Difference_In_Time =
    formatedEndDate.getTime() - formatedStartDate.getTime();
  const Days = Difference_In_Time / (1000 * 3600 * 24);

  const Mins = Difference_In_Time / (60 * 1000);
  const Hours = Mins / 60;
  return Math.floor(Days);
};

export const isGlobalRfxDisabled = (isSubmit, rfxStatus, rfxSupplierStatus) => {
  return (
    isSubmit ||
    rfxStatus !== AUCTION_RUNNING ||
    rfxSupplierStatus === RFX_SUPPLIER_DECLINED
  );
};
export const isGlobalRfxShow = (
  isSubmit,
  rfxStatus,
  rfxSupplierStatus,
  sealedBid
) => {
  return (
    isSubmit &&
    rfxSupplierStatus !== RFX_SUPPLIER_DECLINED &&
    (!sealedBid || rfxStatus === AUCTION_COMPLETED)
  );
};

export const convertDateFormat = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${month}/${day}/${year} ${hours}:${minutes}`;
};
