import {
  LIST_PERMISSION_KEY,
  CREATE_PERMISSION_KEY,
  UPDATE_PERMISSION_KEY,
  DELETE_PERMISSION_KEY,
} from "./authorizationConstant";

export const BUYER_PERMISSIONS = {
  // module : { array of permissions }
  auction: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  auction_details: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  company_users: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  dashboard: {
    [LIST_PERMISSION_KEY]: true,
  },
  supplier_dashboard: {
    [LIST_PERMISSION_KEY]: true,
  },
  item: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  item_details: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  project: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  project_details: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  rfx: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  settings: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  supplier: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  supplier_details: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
};
