import { Box, Grid } from "@material-ui/core";
import { InputLabel } from "@mui/material";
import Controls from "../../../components/controls/Controls";
import { useForm, Form } from "../../../components/useForm";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../store/store";
import { SubmitAuction } from "../../../store/actions/auctionAction";
import { Api } from "../../../api/Api";
import { GET_SUPPLIER_EMAIL_API } from "../../../constants/ApiUrl";
import {
  TOAST_AUTO_CLOSE,
  VALID_EMAIL_REGEX,
} from "../../../constants/AppConstants";
import { Slide, toast } from "react-toastify";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { RfxSubmit } from "../../../store/actions/rfxAction";
const initialFValues = {
  supplierCompanyName: "",
  supplierEmail: "",
  supplierName: "",
  supplierId: "",
  selectedInviteSupplier: [],
  supplierCompanyId: "",
};
export default function InvitedNewSellerPopup(props) {
  const {
    setOpenInviteSupplierPopup,
    suppliers,
    setSuppliers,
    isDispatch,
    fromRfx,
    fromEditTab,
  } = props;
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth.auth.user);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("supplierCompanyName" in fieldValues)
      temp.supplierCompanyName = fieldValues.supplierCompanyName
        ? ""
        : "This field is required.";
    if ("supplierEmail" in fieldValues) {
      if (fieldValues.supplierEmail !== "") {
        if (!VALID_EMAIL_REGEX.test(fieldValues.supplierEmail)) {
          temp.supplierEmail = "Invalid Email";
        } else {
          temp.supplierEmail = "";
        }
      } else {
        temp.supplierEmail = "This field is required.";
      }
    }
    if ("supplierName" in fieldValues)
      temp.supplierName = fieldValues.supplierName
        ? ""
        : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const invalidEmail = await supplierValidation(values.supplierEmail);
      if (invalidEmail) return;
      const { auth } = store.getState();
      const loginuserId = auth?.auth?.user?._id;
      const comanyName = auth?.auth?.user?.company?.company_name;
      const comanyId = auth?.auth?.user?.company?._id;
      const sellerData = {
        email: values.supplierEmail,
        company_buyer_name: comanyName,
        companyId: comanyId,
        supplier_company: values.supplierCompanyName,
        userId: loginuserId,
        isInvieted: true,
        supplierCompanyName: values.supplierCompanyName,
        mainContact: values.supplierName,
        supplierId: values.supplierId,
        supplierCompanyId: values.supplierId,
      };
      suppliers.push(sellerData);
      const neItems = [...suppliers];
      setSuppliers(neItems);
      if (!fromEditTab) {
        if (isDispatch) {
          if (fromRfx) {
            dispatch(RfxSubmit({ Suppliers: suppliers }));
          } else {
            dispatch(SubmitAuction({ Suppliers: suppliers }));
          }
        }
      }
      setOpenInviteSupplierPopup(false);
    }
  };

  const getEmail = async (email) => {
    const userEmail = email.target.value;
    if (userEmail) {
      // const invalidEmail = await supplierValidation(userEmail);
      // if (invalidEmail) return;
      const response = await Api(
        `${GET_SUPPLIER_EMAIL_API}/${userEmail}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          const supplierData = response?.data?.supplier;
          setValues({
            supplierCompanyName: supplierData?.company_name?.company_name,
            supplierName:
              supplierData && supplierData.first_name
                ? supplierData?.first_name
                : "" + " " + supplierData && supplierData?.last_name
                ? supplierData?.last_name
                : "",
            supplierEmail: supplierData?.email,
            supplierId: supplierData?.supplier_company?._id,
            supplierCompanyId: supplierData?.supplier_company?._id,
          });
        }
      }

      // await axiosInstance
      //   .get(`/api/buyer/company/suppliersEmail/${userEmail}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       const supplierData = response?.data?.supplier;
      //       setValues({
      //         supplierCompanyName: supplierData?.company_name?.company_name,
      //         supplierName:
      //           supplierData && supplierData.first_name
      //             ? supplierData?.first_name
      //             : "" + " " + supplierData && supplierData?.last_name
      //             ? supplierData?.last_name
      //             : "",
      //         supplierEmail: supplierData?.email,
      //         supplierId: supplierData?.supplier_company?._id,
      //         supplierCompanyId: supplierData?.supplier_company?._id,
      //       });
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const supplierValidation = async (userEmail) => {
    let isError;
    if (userEmail === userDetails.email) {
      toast.error(ValidationMessage.supplierValidation, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      isError = true;
    } else {
      isError = false;
    }
    return isError;
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel className="mui-label">Company Name</InputLabel>
            <Controls.Input
              className="mui-input"
              fullWidth
              name="supplierCompanyName"
              onChange={handleInputChange}
              value={values.supplierCompanyName}
              error={errors.supplierCompanyName}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="mui-label">Email Address</InputLabel>
            <Controls.Input
              className="mui-input"
              fullWidth
              name="supplierEmail"
              onChange={handleInputChange}
              onBlur={getEmail}
              value={values.supplierEmail}
              error={errors.supplierEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="mui-label">Supplier Name</InputLabel>
            <Controls.Input
              className="mui-input"
              fullWidth
              name="supplierName"
              onChange={handleInputChange}
              value={values.supplierName}
              error={errors.supplierName}
            />
          </Grid>
        </Grid>
        <Box display="flex" mt={4} bgcolor="background.paper">
          <Grid istem xs={4} className="BottomModal-left">
            <Box p={0} flexGrow={1}>
              <Controls.Button
                text="Cancel"
                disableElevation
                onClick={() => setOpenInviteSupplierPopup(false)}
                style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                color="default"
                className="btn-mui"
              />
            </Box>
          </Grid>
          <Grid item xs={8} className="BottomModal-right">
            <Box p={0}>
              <Controls.Button
                type="submit"
                disableElevation
                text="Invite"
                className="btn-mui"
                style={{ minWidth: "197px" }}
              />
            </Box>
          </Grid>
        </Box>
      </Form>
    </>
  );
}
