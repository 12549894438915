import { Api } from "../../../api/Api";
import { POST_AUCTION_WINNER_API } from "../../../constants/ApiUrl";

const SelectWinnerBL = async (props) => {
  const { winners, loserReason, auctionId } = props;
  let success = false;
  let message = "Selecting winner done successfully !";

  try {
    const payload = {
      winners,
      loserReason,
      auctionId,
    };
    const response = await Api(POST_AUCTION_WINNER_API, payload, "post");
    if (response.status === 200) {
      success = true;
      message = response.data.responseDescription
        ? response.data.responseDescription
        : message;
    } else {
      message = response.data.responseDescription
        ? response.data.responseDescription
        : "Selecting winner failed !";
    }
    // let response = await axiosInstance.post(`api/seller/supplierWinner`, {
    //   winners,
    //   loserReason,
    //   auctionId,
    // });

    // if (response.status == 200) {
    //   success = true;
    //   message = response.data.responseDescription
    //     ? response.data.responseDescription
    //     : message;
    // } else {
    //   message = response.data.responseDescription
    //     ? response.data.responseDescription
    //     : "Selecting winner failed !";
    // }
  } catch (err) {
    // Handle Error Here
    // if (err.response) {
    //   message = err.response.data.responseDescription
    //     ? err.response.data.responseDescription
    //     : err.message;
    // }
  }

  return {
    success: success,
    message: message,
  };
};

export default SelectWinnerBL;
