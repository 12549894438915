import { Api } from "../../../../api/Api";
import { RFX_DETAILS_API } from "../../../../constants/ApiUrl";

const GetRfxById = async (props) => {
  const { id } = props;
  let rfx = {};
  let message = "details has been fetched successfully !";
  let code = 200;
  let success = true;

  const response = await Api(`${RFX_DETAILS_API}/${id}`, {}, "get");
  if (response.status === 200) {
    if (
      response.data &&
      response.data.responseCode === "00" &&
      response.data.rfx !== undefined
    ) {
      rfx = response.data.rfx;
    }
  } else {
    message = response.data.responseDescription
      ? response.data.responseDescription
      : message;
    success = false;
    code = response.status;
  }

  // let response = await axiosInstance.get(`api/buyer/company/auctions/${id}`);

  return {
    rfx,
    success,
    code,
    message,
  };
};

export default GetRfxById;
