import { UPDATE_LIVE_BIDDING_RANK, UPDATE_LIVE_DATA, UPDATE_LIVE_EVENT_STATUS } from "../../constant/supplierAspect/liveBidConstant";

export function updateLiveData(bidDetails) {
  return {
    type: UPDATE_LIVE_DATA,
    payload: bidDetails,
  };
}

export function updateLiveBiddingRank(rankDetails) {
  return {
    type: UPDATE_LIVE_BIDDING_RANK,
    payload: rankDetails,
  };
}


export function updateLiveEventStatus(liveStatus) {
  return {
    type: UPDATE_LIVE_EVENT_STATUS,
    payload: liveStatus,
  };
}