import { updateEventStack } from "../../store/actions/socketAction";
import {
  INIT_SOCKET_EVENT,
  SEND_NEW_BID_EVENT,
  GOIN_LIVE_BID_GROUP_EVENT,
} from "./constants";
import GoinBidGroupEvent from "./events/goinBidGroupEvent";
import GoinBidGroupResEvent from "./events/goinBidGroupResEvent";
import InitSocketEvent from "./events/initSocketEvent";
import InitSocketResEvent from "./events/initSocketResEvent";
import newBidInSystemEvent from "./events/newBidInSystemEvent";
import RefreshAuctionDataEvent from "./events/refreshAuctionDataEvent";
import SendNewBidEvent from "./events/sendNewBidEvent";
import SendNewBidResEvent from "./events/sendNewBidResEvent";
import ServerSyncClockEvent from "./events/serverSyncClockEvent";
import SetLiveBiddingRankResEvent from "./events/setLiveBiddingRankResEvent";

class SocketHandler {
  static instance = null;
  static authenticted = false;
  static dispatcher = null;
  static user = null;

  static setInstance(socket) {
    SocketHandler.instance = socket;
    return this;
  }

  static setConnectedUser(user) {
    SocketHandler.user = user;
    return this;
  }

  static setDispatcher(dispatcher) {
    SocketHandler.dispatcher = dispatcher;
    return this;
  }

  static isConnected() {
    return SocketHandler.instance && SocketHandler.instance.connected;
  }

  static emit(eventName, data) {
    let send = false;
    if (SocketHandler.instance) {
      if (eventName === INIT_SOCKET_EVENT) {
        send = InitSocketEvent();
      }

      switch (eventName) {
        case SEND_NEW_BID_EVENT:
          send = SendNewBidEvent(data);
          break;

        case GOIN_LIVE_BID_GROUP_EVENT:
          send = GoinBidGroupEvent(data);
          break;
      }

      if (send && SocketHandler.dispatcher !== null) {
        SocketHandler.dispatcher(updateEventStack(eventName));
      }
    }
  }

  static listen() {
    if (SocketHandler.instance) {
      InitSocketResEvent();
      GoinBidGroupResEvent();
      SendNewBidResEvent();
      newBidInSystemEvent();
      ServerSyncClockEvent();
      RefreshAuctionDataEvent();
      SetLiveBiddingRankResEvent();
    }
  }
}
//

export default SocketHandler;
