import { Box, Grid, Paper } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import TablePaginationBox from "../../../../components/TablePaginationBox";
import usePrevious from "../../../../components/usePrevious";
import { shouldRefreshLiveBiddingData } from "../../../../store/selectors/auctionSelector";
import { v1 as uuidv1 } from "uuid";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import Controls from "../../../../components/controls/Controls";
import {
  downloadDocumentFile,
  isGlobalRfxDisabled,
  isGlobalRfxHide,
  isGlobalRfxShow,
  thousandSepratorHandler,
} from "../../../../utility/utils";
import {
  AUCTION_COMPLETED,
  AUCTION_ENDED_CAPITAL,
  RFX_PROGRESS_MAP,
  SHORTLIST_FOR_NEXT_AUCTION,
  SHORTLIST_FOR_NEXT_RFX,
  TOAST_AUTO_CLOSE,
  WON_RFX,
} from "../../../../constants/AppConstants";
import GetAllSupplierListRfx from "../RfxProcessFunction/GetAllSupplierListRfx";
import RfxSupplierModal from "../RfxCompareComponent/RfxSupplierModal";
import { Api } from "../../../../api/Api";
import { POST_WIN_RFX } from "../../../../constants/ApiUrl";
import { Slide, toast } from "react-toastify";
import { ValidationMessage } from "../../../../constants/ValidationMessage";

const columns = [
  {
    label: "Rank",
  },
  {
    label: "Supplier Name",
  },
  {
    label: "Status",
  },
  {
    label: "Open Message",
  },
  {
    label: "Question answered",
  },
  {
    label: "Items priced",
  },
  {
    label: "Attachment",
  },
  {
    label: "Bid",
  },
];

const supplierRfxOverview = [
  { id: WON_RFX, title: "Award" },
  { id: SHORTLIST_FOR_NEXT_RFX, title: "Shortlist for next RFX" },
  { id: SHORTLIST_FOR_NEXT_AUCTION, title: "Shortlist for Auction" },
  // { id: CANCELLED_RFX, title: "Cancel RFX" },
];
const RfxInfoOverviewTable = (props) => {
  const dispatch = useDispatch();
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);
  const { setBestBid, totalQuestion, totalItems } = props;
  const [resendApi, setResendApi] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [numOfPages, setNumOfPages] = useState(0);
  const prevPageValue = usePrevious(page);
  const [supplierList, setSupplierList] = useState([]);
  

  const [limit, setLimit] = useState(0);
  const [awardStatus, setAwardStatus] = useState("");
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const { smScreen } = useWindowDimensions();

  useEffect(() => {
    if (resendApi) {
      GetAllSupplierListRfx({
        currentRfx: rfxDetails,
        page: page,
        rowsPerPage: rowsPerPage,
      }).then((data) => {
        setSupplierList(data.list);
        setLimit(data.limit);
        setBestBid(data.bestBid);
      });
      setResendApi(false);
    }
  }, [resendApi]);

  useEffect(() => {
    if (prevPageValue !== undefined) {
      setResendApi(true);
    }
  }, [page]);

  useEffect(() => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  }, [limit, setSupplierList]);

  useEffect(() => {
    if (rfxDetails && rfxDetails._id) {
      setResendApi(true);
    }
  }, [rfxDetails]);

  const getColumns = () => {
    if (rfxDetails && rfxDetails._id) {
      return columns.map((column) => {
        if (column.id && column.id === "select_winner") {
          if (
            rfxDetails &&
            rfxDetails.status &&
            // rfxDetails.status === "Published"
            rfxDetails.status === AUCTION_ENDED_CAPITAL
          ) {
            return (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            );
          }
        } else {
          return (
            <TableCell key={uuidv1()} align={column.align}>
              {column.label}
            </TableCell>
          );
        }
      });
    }
    return <></>;
  };

  const handleAwardInputChange = (e) => {
    const temp = [...supplierList];
    let filterId = [];
    temp.map((item) => {
      if (item?.rfxStatus === e.target.value) {
        filterId.push(item?.supplierCompanyId?._id);
      }
    });

    setFilteredData([...filterId]);
    if (e.target.value.length > 0) {
      setAwardStatus(e.target.value);
      setShowSupplierModal(true);
    } else {
      setAwardStatus("");
      setShowSupplierModal(false);
    }
  };
  const submitAward = async (finalResponse) => {
    if (finalResponse.length === 0) {
      toast.error(ValidationMessage?.selectSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    const payload = {
      rfxId: rfxDetails?._id,
      status: awardStatus,
      winners: finalResponse,
    };

    const response = await Api(POST_WIN_RFX, payload, "post");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setResendApi(true);
      }
    }
    setAwardStatus("");
    setShowSupplierModal(false);
  };
  return (
    <Paper
      className="rfx-supplier"
      elevation={0}
      square
      style={{ marginTop: "1.5em" }}
    >
      <Box sx={{ padding: "24px" }}>
        <Grid container>
          <Grid
            item
            md={8}
            xs={12}
            style={{ textAlign: smScreen ? "center" : "start" }}
          >
            <h3 style={{ margin: "0" }}>Overview</h3>
          </Grid>
          {rfxDetails?.status === AUCTION_COMPLETED && (
            <Grid item md={4} xs={12} className="w-100">
              <Controls.Select
                name="supplier-rfx"
                labelId="supplier-rfx"
                value={awardStatus}
                // fullWidth
                onChange={handleAwardInputChange}
                options={supplierRfxOverview}
                className="w-100"
                label="Awarding"
                labelClassName="select-label-margin"
              />
            </Grid>
          )}
        </Grid>

        <Box sx={{ padding: "24px" }}>
          <TableContainer className="table-container" component={Paper}>
            <Table>
              <TableHead>
                <TableRow>{getColumns()}</TableRow>
              </TableHead>
              <TableBody>
                {supplierList &&
                  supplierList.length > 0 &&
                  supplierList.map((supplier, index) => (
                    <TableRow key={supplier?._id}>
                      <TableCell style={{ paddingLeft: "8px" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ color: "#2170ff" }}>
                        {supplier?.supplierCompanyId?.company_name}
                      </TableCell>
                      <TableCell>
                        <div className="badge badge-secondary h-30">
                          {RFX_PROGRESS_MAP.get(supplier?.rfxStatus)}
                        </div>
                      </TableCell>
                      <TableCell
                        className={`${
                          supplier?.openMessages > 0 ? "text-warning" : ""
                        }`}
                      >
                        {supplier?.openMessages}
                      </TableCell>
                      <TableCell>
                        {`${
                          isGlobalRfxShow(
                            supplier?.isSubmitted,
                            rfxDetails.status,
                            supplier?.rfxStatus,
                            rfxDetails?.sealed_bid
                          )
                            ? `${supplier?.answeredQuestions} / ${totalQuestion}`
                            : "--"
                        }`}
                      </TableCell>
                      <TableCell>
                        {`${
                          isGlobalRfxShow(
                            supplier?.isSubmitted,
                            rfxDetails?.status,
                            supplier?.rfxStatus,
                            rfxDetails?.sealed_bid
                          )
                            ? `${supplier?.itemsPriced} / ${totalItems}`
                            : "--"
                        }`}
                      </TableCell>
                      <TableCell
                        className="cursor-p file-name library-doc"
                        onClick={() => {
                          if (
                            supplier?.attachment &&
                            supplier?.attachment?.name
                          )
                            downloadDocumentFile(
                              supplier?.attachment?.fileName,
                              supplier?.attachment?.name
                            );
                        }}
                        download={supplier?.attachment?.fileName}
                      >
                        {supplier?.attachment &&
                        supplier?.attachment?.name &&
                        isGlobalRfxShow(
                          supplier?.isSubmitted,
                          rfxDetails?.status,
                          supplier?.rfxStatus,
                          rfxDetails?.sealed_bid
                        )
                          ? supplier?.attachment?.name
                          : "--"}
                      </TableCell>

                      <TableCell>
                        {isGlobalRfxShow(
                          supplier?.isSubmitted,
                          rfxDetails?.status,
                          supplier?.rfxStatus,
                          rfxDetails?.sealed_bid
                        )
                          ? thousandSepratorHandler(supplier?.bid)
                          : "--"}
                      </TableCell>
                      {rfxDetails &&
                      rfxDetails.status &&
                      rfxDetails.status === AUCTION_ENDED_CAPITAL ? (
                        // rfxDetails.status === "Published"
                        <TableCell>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {/* {getWinnerIcon(supplier)} */}
                          </Box>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePaginationBox
              label={limit + " Suppliers Found"}
              numOfPages={numOfPages}
              onChangePage={(event, newPage) => setPage(newPage)}
              page={page}
            />
          </TableContainer>
        </Box>
      </Box>
      {showSupplierModal && (
        <RfxSupplierModal
          showSupplierModal={showSupplierModal}
          setShowSupplierModal={(value) => {
            setAwardStatus("");
            setShowSupplierModal(value);
          }}
          setFilteredData={setFilteredData}
          filteredData={filteredData}
          fromAward={true}
          submitAward={submitAward}
        />
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    refreshAuction: shouldRefreshLiveBiddingData(state),
  };
};

export default withRouter(connect(mapStateToProps)(RfxInfoOverviewTable));
