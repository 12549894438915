/*eslint-disable */
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import faceLogo from "../../assets/auth/Logo_Oktio_last.png";
import { makeStyles } from "@material-ui/core/styles";
import { Link as Links, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import useWindowDimensions from "../../components/useWindowDimensions";
import { connect } from "react-redux";
import { Box, CssBaseline, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Loader from "../../components/Loader/Loader";
import "./styles/auth.css";
import { Tab, Tabs, Typography } from "@mui/material";
import SignUpBuyer from "./SignUpBuyer";
import SignUpSeller from "./SignUpSeller";

const useStyles = makeStyles((theme) => ({
  selectAdornment: {
    "& .MuiButtonBase-root": {
      padding: 0,
      left: "0px",
      top: "calc(50% - 0px)",
    },
  },
  tabs: {
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
      backgroundColor: "#2170ff",
      padding: "0px",
      // margin: "7px",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      textTransform: "capitalize",
    },
    "& .MuiTab-root": {
      fontWeight: 600,
      color: "#000",
      fontSize: "16px",
      textTransform: "capitalize",
      padding: "0px 15px",
      // minHeight: "2.7em",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-label={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Signup = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (location && location?.state) {
      if (location.state === "seller") {
        setValue(1);
      }
    }
  }, [location]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setNotify({
    //   ...notify,
    //   isOpen: false,
    // });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="auth-box-container">
      <CssBaseline />

      <div style={{ flexGrow: 1, marginBottom: "25px" }}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item lg={4} xs={8}>
            <Grid
              container
              justifyContent="center"
              alignItems="end"
              style={{ flexDirection: "column" }}
            >
              <Grid item sm={12} style={{ textAlign: "-webkit-center" }}>
                <div className="face-logo">
                  <img src={faceLogo} />
                </div>
              </Grid>
              <Grid item sm={12}>
                <div className="text-logo-box-container">
                  <div className="text-logo-box">
                    <span>oktio</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={11}>
            <div className="form-box-container">
              {props.showLoading && <Loader />}
              <Grid container className="form-banner-container">
                <Grid item xs={12}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item sm={11}>
                      <Links
                        to="/signin"
                        variant="body1"
                        className="d-flex items-center"
                      >
                        <ArrowBackIosIcon color="primary" className="fz-16" />
                        <span className="ml-2 b-600">Go back</span>
                      </Links>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className="user-tab">
                  <Box
                    sx={{
                      // marginTop: getMargeinTopValue(),
                      bgcolor: "background.paper",
                      borderRadius: "25px !important",
                    }}
                  >
                    <Tabs
                      className={`${classes.tabs} inner-all-tab`}
                      id="project-tab-list"
                      value={value}
                      onChange={handleChange}
                      // className="tab-header"
                    >
                      <Tab label="Buyer" />
                      <Tab label="Seller" />
                    </Tabs>
                  </Box>
                </Grid>

                <Grid item xs={12} className={xlScreen ? "mt-5" : "m-2"}>
                  <Grid container alignItems="center" justifyContent="center">
                    {value === 0 ? (
                      <Grid item sm={11}>
                        <div className="main-title">
                          Manage your tenders and Negotiate with auctions
                        </div>
                        <div className="singup-sub-title ">
                          14 days free trial
                        </div>
                      </Grid>
                    ) : (
                      <Grid item sm={11}>
                        <div className="main-title">
                          Participate to auctions and win projects
                        </div>
                        <div className="singup-sub-title sub-text-none">
                          14 days free trial
                        </div>
                      </Grid>
                    )}
                    {/* <Grid item sm={4}>
                      <div className="sub-title">
                        <div>No credit cards required</div>
                        <Links
                          to="/signup"
                          variant="caption"
                          className={classes.linktxt2}
                        >
                          14 days free trial
                        </Links>
                      </div>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
              <TabPanel className="signup-tab-body" value={value} index={0}>
                <SignUpBuyer notify={notify} setNotify={setNotify} />
              </TabPanel>
              <TabPanel className="signup-tab-body" value={value} index={1}>
                <SignUpSeller notify={notify} setNotify={setNotify} />
              </TabPanel>
              <Box className="mt-5 footer">
                <div>
                  By Clicking the "Create Account" button, I expressly agree to
                  the Oktio &nbsp;
                  <a
                    href="https://oktio.io/terms-conditions"
                    target="_blank"
                    variant="caption"
                  >
                    Terms of Use
                  </a>
                </div>
                <div>
                  and understand that my account information will be used
                  according to the Oktio &nbsp;
                  <a
                    href="https://oktio.io/privacy-policy"
                    target="_blank"
                    variant="caption"
                  >
                    Privacy Policy.
                  </a>
                </div>
              </Box>

              <Snackbar
                className={classes.root}
                // open={notify.isOpen}
                open={false}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
              >
                <Alert severity={notify.type} onClose={handleClose}>
                  {props.successMessage}
                </Alert>
              </Snackbar>
            </div>
          </Grid>
        </Grid>
        {/* </Container> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Signup);
