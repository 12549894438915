export const isAuthenticated = (state) => {
  if (state.auth.auth.token) return true;
  return false;
};

export const currentUser = (state) => {
  if (
    state.auth &&
    state.auth.auth &&
    state.auth.auth.user &&
    state.auth.auth.user.role
  ) {
    return state.auth.auth.user;
  }
  return null;
};
