import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Appbars from "../../../components/Appbar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Copyright from "../../../components/Copyright";
import { useDispatch } from "react-redux";
// import "../../styles/details-index.css";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Api } from "../../../api/Api";
import { GET_SELLER_RFX_DETAILS_API } from "../../../constants/ApiUrl";
import { setRfxDetails } from "../../../store/actions/rfxAction";
import RfxSellerBanner from "./RfxSellerBanner";
import RfxSellerTabContainer from "./RfxSellerTabContainer";

const RfxSellerDetails = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadding, setLoadding] = useState(true);

  const [resendApi, setResendApi] = useState(true);

  useEffect(() => {
    getRfxDetails();
  }, []);

  const getRfxDetails = async () => {
    const response = await Api(
      `${GET_SELLER_RFX_DETAILS_API}/${id}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (
        response.data &&
        response.data.responseCode === "00" &&
        response.data.rfx !== undefined
      ) {
        dispatch(setRfxDetails(response?.data?.rfx));
        setLoadding(false);
      }
    }

    setResendApi(false);
  };

  return (
    <>
      {loadding && <Loader />}
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <Appbars> </Appbars>
        <main className="app-main-section-container">
          <Container maxWidth="lg">
            <RfxSellerBanner getRfxDetails={getRfxDetails} />
            <RfxSellerTabContainer getRfxDetails={getRfxDetails} />
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </>
  );
};

export default RfxSellerDetails;
