import { Box, Grid, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const RfxReadMoreModal = ({
  readMoreModal,
  setReadMoreModal,
  particularData,
}) => {
  return (
    <Modal
      open={readMoreModal}
      onClose={setReadMoreModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="popup-form-container rfx-read-more-modal">
        <Grid container>
          <Grid item xs={9}></Grid>
          <Grid item xs={3} className="text-end">
            <CloseIcon
              onClick={() => {
                setReadMoreModal(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        <div>
          <p className="read-question text-bold text-dark breakLine mb-3">{particularData.question}</p>
          <p className="text-gray breakLine">{particularData.answer}</p>
        </div>
      </Box>
    </Modal>
  );
};

export default RfxReadMoreModal;
