import React from "react";
import { TextField, Box, FormHelperText, InputLabel } from "@mui/material";
const FormInput = ({
  id,
  name,
  type,
  placeholder,
  label,
  rules,
  register,
  spaceClass,
  disabled,
  value,
  maxLength,
  pattern,
  readOnlyType,
  onFocusEvent,
  errors,
  variant,
  fullWidth,
  className,
  errorMsg,
  multiple,
  maxRows,
  minRows,
  inputLabel,
  onChange,
  defaultValue,
}) => {
  const { ref: ref, ...rest } = register(name, rules);

  return (
    <>
      <Box className={className} sx={{ mb: `${spaceClass}px` }}>
        {inputLabel && inputLabel.length > 0 && (
          <InputLabel>{inputLabel}</InputLabel>
        )}

        <TextField
          id={id}
          name={name}
          type={type}
          autoComplete="off"
          label={label}
          placeholder={placeholder}
          {...rest}
          ref={ref}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          pattern={pattern}
          readOnly={readOnlyType}
          onFocus={onFocusEvent}
          error={errors[name] ? true : false}
          variant={variant}
          fullWidth={fullWidth}
          maxRows={maxRows}
          minRows={minRows}
          multiline={multiple}
          defaultValue={defaultValue}
        />

        <FormHelperText
          className="error-msg"
          error={errors[name] ? true : false}
          id={id}
        >
          {errorMsg || errors[name]?.message}
        </FormHelperText>
      </Box>
    </>
  );
};

export default FormInput;

// varient : filled,outlined,standard
