import { useEffect, useState } from "react";
import TableOptionList from "../../../components/TableOptionList";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Box } from "@material-ui/core";
import {
  POST_SELLER_RFX_QUESTION_API,
  POST_SELLER_RFX_SEND_ANSWER_API,
} from "../../../constants/ApiUrl";
import { useSelector } from "react-redux";
import { Api } from "../../../api/Api";
import { store } from "../../../store/store";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Controls from "../../../components/controls/Controls";
import FormInput from "../../../components/FormComponents/FormInput";
import { useForm } from "react-hook-form";
import {
  ACCEPT_DOCUMENT_ATTACHMENTS,
  AUCTION_COMPLETED,
  DOCUMENT_FILE_SIZE,
  FREE_TEXT,
  MULTI_CHOICE,
  SINGLE_CHOICE,
  TOAST_AUTO_CLOSE,
} from "../../../constants/AppConstants";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import attachIcon from "../../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../../assets/icons/CloseX.png";
import { Slide, toast } from "react-toastify";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { isGlobalRfxDisabled } from "../../../utility/utils";

const RfxSellerQuestionTab = () => {
  const rfxSupplierDetails = useSelector((state) => state.supplierRfx.rfx);
  const state = store.getState();
  const companyId = state?.auth?.auth?.user?.company?._id;
  const userId = state?.auth?.auth?.user?._id;

  const {
    register,
    formState: { errors },
  } = useForm({});

  const [questionList, setQuestionList] = useState([]);
  const [editData, setEditData] = useState({});
  const [changeData, setChangeData] = useState([]);
  const [document, setDocument] = useState({});
  const [remainingCount, setRemainingCount] = useState(0);
  const [isAttachmentDeleted, setIsAttachmentDeleted] = useState(false);

  useEffect(() => {
    getQuestionData();
  }, []);

  const getQuestionData = async () => {
    const response = await Api(
      `${POST_SELLER_RFX_QUESTION_API}/${companyId}/${rfxSupplierDetails._id}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setRemainingCount(response?.data?.remainingQuestionCount);
        initalCheckHandler(response?.data?.rfxQuestions);
      }
    }
  };

  const onEditHandler = async (item) => {
    if (item?.questionType === MULTI_CHOICE) {
      const filterData = item.options.filter((obj) => obj?.checked);
      const answer = filterData.map((item) => {
        return { optionId: item?._id, value: item?.option };
      });
      item.answer = [...answer];
    }

    if (
      document &&
      document?.isSaved &&
      document.name &&
      document.name.length > 0
    ) {
      if (!item.answer || item.answer.length === 0) {
        toast.error(ValidationMessage.notOnlyAttachment, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    }

    if (!item.answer || item.answer.length === 0) {
      item.answer = item?.response[0]?.answer;
    }
    const formData = new FormData();
    formData.append("rfxId", rfxSupplierDetails?._id);
    formData.append("rfxQuestionId", item?._id);
    formData.append("answer", JSON.stringify(item.answer));
    formData.append("companyId", companyId);
    formData.append("userId", userId);
    formData.append("answerId", item?.response[0]?._id || null);
    formData.append("isAttachmentDeleted", isAttachmentDeleted);
    if (document && document.name && document.name.length > 0) {
      formData.append("attachment", document);
    }

    const response = await Api(
      POST_SELLER_RFX_SEND_ANSWER_API,
      formData,
      "postMultipart"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setEditData({});
        getQuestionData();
      }
    }
  };

  const disableSubmitHandler = (item) => {
    let isDisabled = false;
    if (item.questionType === FREE_TEXT) {
      if (item?.response[0]?.answer[0]?.value === changeData[0]?.value) {
        isDisabled = true;
      }
    } else if (item.questionType === SINGLE_CHOICE) {
      if (item?.response[0]?.answer[0]?.optionId === changeData[0]?.value) {
        isDisabled = true;
      }
    } else if (item.questionType === MULTI_CHOICE) {
      const temp = [...changeData];
      const responseData = temp.filter((item) => item.checked);
      const final = responseData.map((obj) => {
        return { optionId: obj._id, value: obj?.option };
      });

      if (!item?.response[0]?.answer && final.length === 0) {
        isDisabled = true;
      }
      if (JSON.stringify(item?.response[0]?.answer) === JSON.stringify(final)) {
        isDisabled = true;
      }
    }
    return isDisabled;
  };

  const initalCheckHandler = (data) => {
    let temp = [...data];
    temp.map((item) => {
      if (item?.questionType === MULTI_CHOICE) {
        if (item?.options && item?.options.length > 0) {
          item?.options.map((obj) => {
            if (item?.response && item?.response.length > 0) {
              if (
                item.response[0].answer &&
                item.response[0].answer.length > 0
              ) {
                item.response[0].answer.map((innerItem) => {
                  if (innerItem.optionId === obj?._id) {
                    obj.checked = true;
                  }
                });
              }
            }
          });
        }
      }
    });
    setQuestionList([...temp]);
  };

  const onEditButtonClick = (item) => {
    if (item.questionType !== MULTI_CHOICE) {
      setChangeData([
        {
          value: item?.response[0]?.answer[0]?.value,
        },
      ]);
    } else {
      setChangeData([...item?.options]);
    }
    setDocument({ ...item?.response[0]?.attachment, isSaved: true });
    setEditData({ ...item });
  };

  const handleFileChange = (e) => {
    const selected = e.target.files[0];

    const ALLOWED_TYPES = ACCEPT_DOCUMENT_ATTACHMENTS;
    if (selected && !ALLOWED_TYPES.includes(selected.type)) {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }
    if (selected && Math.round(selected.size / 1024) > DOCUMENT_FILE_SIZE) {
      toast.error(ValidationMessage.documentAttachmentFileSize, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setDocument(selected);
    }
    e.target.value = "";
  };

  const removeDocumentHandler = () => {
    if (document?.fileName && document?.fileName.length > 0) {
      setIsAttachmentDeleted(true);
    }
    setDocument([]);
  };
  return (
    <div className="question-seller-tab">
      <div className="d-flex align-item-center mb-2">
        <p className="question-tab-title">Questions</p>
        <p className="text-dark "> ({remainingCount} remaining)</p>
      </div>
      <Grid container>
        <Grid item xs={12}>
          {questionList && questionList.length > 0 ? (
            questionList.map((item, index) => {
              return (
                <div className="mui-card mb-3" id={item._id}>
                  <Grid container>
                    <Grid item md={2} className="question-order ">
                      <p className="text-primary text-bold">
                        Question {index + 1}
                      </p>
                    </Grid>

                    <Grid item md={7}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <p className="text-dark">{item?.question}</p>
                        </Grid>
                        {editData &&
                          editData?._id &&
                          editData?._id === item?._id && (
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={12}>
                                  {item?.questionType === "FT" && (
                                    <Box className="common-textarea-input w-100">
                                      <TextField
                                        id="free-text-answer"
                                        name="free-text-answer"
                                        type="text"
                                        placeholder="Type a question"
                                        multiple={true}
                                        maxRows={4}
                                        minRows={1}
                                        defaultValue={
                                          item?.response[0]?.answer[0]?.value
                                        }
                                        onChange={(e) => {
                                          item.answer = [
                                            {
                                              value: e.target.value,
                                            },
                                          ];
                                          setChangeData([
                                            { value: e.target.value },
                                          ]);
                                        }}
                                      />
                                    </Box>
                                  )}
                                  {item?.questionType === "SC" && (
                                    <RadioGroup
                                      className="d-block"
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      name="radio-buttons-group"
                                      defaultValue={
                                        item?.response[0]?.answer[0]?.optionId
                                      }
                                    >
                                      {item?.options &&
                                        item?.options.length > 0 &&
                                        item.options.map((obj, i) => (
                                          <Grid item lg={6} sm={12}>
                                            <div className="d-flex align-item-center each-option-card">
                                              <p className="mr-3 text-gray">
                                                {String.fromCharCode(65 + i)}
                                              </p>

                                              <FormControlLabel
                                                control={<Radio />}
                                                label={obj?.option}
                                                value={obj?._id}
                                                onChange={(e) => {
                                                  item.answer = [
                                                    {
                                                      optionId: obj?._id,
                                                      value: obj?.option,
                                                    },
                                                  ];
                                                  setChangeData([
                                                    {
                                                      value: e.target.value,
                                                    },
                                                  ]);
                                                }}
                                              />
                                            </div>
                                          </Grid>
                                        ))}
                                    </RadioGroup>
                                  )}
                                  {item?.questionType === "MC" && (
                                    <div>
                                      {item?.options &&
                                        item?.options.length > 0 &&
                                        item.options.map((obj, i) => (
                                          <Grid item sm={12} className="mb-2">
                                            <div className="d-flex justify-content-between align-item-center each-option-card">
                                              <p className="mr-3 text-gray">
                                                {String.fromCharCode(65 + i)}
                                              </p>
                                              <div className="inner-option-card">
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={obj.checked}
                                                      onChange={(e) => {
                                                        obj.checked =
                                                          e.target.checked;

                                                        setChangeData([
                                                          ...item.options,
                                                        ]);
                                                      }}
                                                    />
                                                  }
                                                  label={obj?.option}
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                        ))}
                                    </div>
                                  )}
                                </Grid>
                                <Grid item xs={4}>
                                  <div className="question-attachment">
                                    {document &&
                                    document.name &&
                                    document.name.length > 0 ? (
                                      <>
                                        <div className="document-list w-100 ">
                                          <Box
                                            component="div"
                                            className="uploaded-files d-flex align-item-center"
                                          >
                                            <img
                                              src={attachIcon}
                                              className="icon icon-chat-attach-list"
                                              alt=""
                                            />

                                            <Box className="name px-2">
                                              {document?.name}
                                            </Box>
                                            <div
                                              className="cursor-p mt-1"
                                              onClick={() => {
                                                removeDocumentHandler();
                                              }}
                                            >
                                              <img src={closeIcon} alt="" />
                                            </div>
                                          </Box>
                                        </div>
                                      </>
                                    ) : (
                                      <label htmlFor="fileUpload">
                                        <div className="common-attachment-btn  ml-0 mt-3">
                                          <AddOutlinedIcon
                                            style={{ fontSize: "20px" }}
                                          />
                                          <span className="ml-2">
                                            Add Attachment
                                          </span>
                                        </div>
                                      </label>
                                    )}

                                    <input
                                      type="file"
                                      id="fileUpload"
                                      hidden
                                      onChange={handleFileChange}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                    <Grid item md={3}>
                      <div className="quetion-action d-flex align-item-center justify-content-end">
                        {editData &&
                        editData?._id &&
                        editData?._id === item?._id ? (
                          <div>
                            <Controls.Button
                              type="submit"
                              text="Submit"
                              className="btn-mui btn-reply mr-2"
                              onClick={() => {
                                onEditHandler(item);
                              }}
                              disabled={
                                disableSubmitHandler(item) && document?.isSaved
                              }
                            />
                          </div>
                        ) : (
                          <>
                            <div>
                              <Controls.Button
                                type="button"
                                disableElevation
                                text={
                                  item?.response && item?.response.length === 0
                                    ? "Reply"
                                    : "Replied"
                                }
                                className={`btn-mui btn-reply mr-2 ${
                                  item?.response && item?.response.length === 0
                                    ? "common-border-button"
                                    : "btn-replied"
                                }`}
                                onClick={() => {
                                  onEditButtonClick(item);
                                }}
                                disabled={
                                  (item?.response &&
                                    item?.response.length > 0) ||
                                  isGlobalRfxDisabled(
                                    rfxSupplierDetails?.supplierRfx
                                      ?.isSubmitted,
                                    rfxSupplierDetails.status,
                                    rfxSupplierDetails?.supplierRfx?.rfxStatus
                                  )
                                }
                              />
                            </div>

                            {!isGlobalRfxDisabled(
                              rfxSupplierDetails?.supplierRfx?.isSubmitted,
                              rfxSupplierDetails.status,
                              rfxSupplierDetails?.supplierRfx?.rfxStatus
                            ) && (
                              <Box>
                                <PopupState
                                  variant="popover"
                                  popupId="demo-popup-menu"
                                >
                                  {(popupState) => (
                                    <div>
                                      <Button
                                        className="action-btn"
                                        variant="contained"
                                        {...bindTrigger(popupState)}
                                      >
                                        <MoreVertIcon />
                                      </Button>
                                      <TableOptionList
                                        {...bindMenu(popupState)}
                                      >
                                        <MenuItem
                                          className="bold-line-600"
                                          onClick={() => {
                                            popupState.close();
                                            onEditButtonClick(item);
                                          }}
                                        >
                                          <div>Edit</div>
                                        </MenuItem>
                                      </TableOptionList>
                                    </div>
                                  )}
                                </PopupState>
                              </Box>
                            )}
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            })
          ) : (
            <div className="mui-card text-center">No records found</div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default RfxSellerQuestionTab;
