import * as React from "react";

import { Divider } from "@mui/material";
import SupplierListDetailsCompanyProp from "./supplierListDetailsCompanyProp";
import SupplierListDetailsContact from "./supplierListDetailsContact";
import "../styles/supplier-list-info-details.css";
import SupplierListDetailsAttachment from "./supplierListDetailsAttachment";

export default function SupplierListDetails(props) {
  const { supplier, setNotify } = props;

  return supplier && supplier.status ? (
    <>
      <SupplierListDetailsCompanyProp supplier={supplier} />
      <Divider className="mt-5 mb-5" />
      <SupplierListDetailsContact supplier={supplier} setNotify={setNotify} />
      <Divider className="mt-5 mb-5" />
      <SupplierListDetailsAttachment supplier={supplier} setNotify={setNotify} />
    </>
  ) : (
    <></>
  );
}
