import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Appbars from "../../../components/Appbar";
import Copyright from "../../../components/Copyright";
import Box from "@material-ui/core/Box";
import SuperAdminCompanyList from "./CompanyList";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
}));

const SuperAdminCompany = () => {
    const classes = useStyles();
    return (
        <div style={{ display: "flex" }}>
            <CssBaseline />
            <Appbars> </Appbars>
            <main className="app-main-section-container">
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <SuperAdminCompanyList />
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
};

export default SuperAdminCompany;
