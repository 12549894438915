import {
  LIST_PERMISSION_KEY,
  CREATE_PERMISSION_KEY,
  UPDATE_PERMISSION_KEY,
  DELETE_PERMISSION_KEY,
} from "./authorizationConstant";

export const SELLER_PERMISSIONS = {
  // dashboard: {
  //   [LIST_PERMISSION_KEY]: true,
  // },
  supplier_company_users: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  supplier_dashboard: {
    [LIST_PERMISSION_KEY]: true,
  },
  supplier_rfx: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  supplier_settings: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
  supplier_auction: {
    [LIST_PERMISSION_KEY]: true,
    [CREATE_PERMISSION_KEY]: true,
    [UPDATE_PERMISSION_KEY]: true,
    [DELETE_PERMISSION_KEY]: true,
  },
};
