import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getHttpCode, getHttpMessage } from "../../store/selectors/ErrorHandlerSelectors";
import "./styles/index.css";

const ErrorPageComponent = (props) => {
  const { httpCode, httpMessage } = props;
  return (
    <div id="error-box-wrapper">
      <div class="box">
        <h1>{httpCode}</h1>
        <p>{httpMessage}</p>
        <p>&#58;&#40;</p>
        <p><a href="#">Please Contact OKTIO Support Team !</a></p>
      </div>
    </div>

    // <div className="clr-ff0000 text-center mt-5">
    //   <h1></h1>
    // </div>
  );
}

const mapStateToProps = (state) => {
  return {
    httpCode: getHttpCode(state),
    httpMessage: getHttpMessage(state),
  };
};

export default withRouter(connect(mapStateToProps)(ErrorPageComponent));
