import { Grid } from "@mui/material";
import { Divider, Paper } from "@material-ui/core";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TableOptionList from "../../../../components/TableOptionList";
import { useEffect, useState } from "react";
import RfxQuestionEditDrawer from "./RfxQuestionEditDrawer";
import { Api } from "../../../../api/Api";
import { DELETE_QUESTION_API, RFX_API } from "../../../../constants/ApiUrl";
import { useSelector } from "react-redux";
import {
  AUCTION_DRAFT,
  QUESTION_TYPE,
} from "../../../../constants/AppConstants";
import Controls from "../../../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import RfxFormStep3 from "../RfxFormStep/RfxFormStep3";

const RfxQuestionsTab = () => {
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const [showEditSidebar, setShowEditSidebar] = useState(false);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    getQuestionData();
  }, []);

  const getQuestionData = async () => {
    const response = await Api(
      `${RFX_API}/${rfxDetails._id}/questions/get`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setQuestionList([...response?.data?.questions]);
      }
    }
  };

  const deleteQuestionHandler = async (question) => {
    const response = await Api(
      `${DELETE_QUESTION_API}/${rfxDetails._id}/${question?._id}`,
      {},
      "delete"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        getQuestionData();
      }
    }
  };
  return (
    <Paper elevation={0} square>
      <section className="question-tab p-0">
        <div className="global-flex-center justify-content-end pr-3 pt-3">
          {rfxDetails?.status === AUCTION_DRAFT ? (
            <Controls.Button
              text="Add Question"
              className="btn btn-primary btn-mui"
              variant="contained"
              startIcon={<AddIcon />}
              style={{
                textTransform: "capitalize",
                border: "1px #2170ff solid",
                padding: "0.6em 1.4em",
                borderRadius: "10px",
                marginLeft: "12px",
                height: "48px",
              }}
              onClick={() => setShowAddQuestionModal(true)}
            />
          ) : (
            <></>
          )}
        </div>
        <Grid container>
          <Grid item xs={12}>
            {questionList && questionList.length > 0 ? (
              questionList.map((item, index) => {
                return (
                  <div className="mui-card mb-3" id={item._id}>
                    <Grid container>
                      <Grid item md={2} className="question-order ">
                        <p className="text-primary text-bold">
                          Question {index + 1}
                        </p>
                      </Grid>
                      <Grid item md={7}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <p className="text-dark">{item?.question}</p>
                          </Grid>
                          {item?.options &&
                            item?.options.length > 0 &&
                            item.options.map((obj, i) => (
                              <Grid item lg={6} sm={12}>
                                <div className="d-flex justify-content-between align-item-center each-option-card">
                                  <p className="mr-3 text-gray">
                                    {String.fromCharCode(65 + i)}
                                  </p>
                                  <div className="inner-option-card">
                                    <p className="text-gray">{obj?.option}</p>
                                  </div>
                                </div>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                      <Grid item md={3}>
                        <div className="quetion-action d-flex align-item-center">
                          <div className="rfx-question-badge mr-3">
                            {QUESTION_TYPE.get(item?.questionType)}
                          </div>
                          {rfxDetails?.status === AUCTION_DRAFT && (
                            <Box>
                              <PopupState
                                variant="popover"
                                popupId="demo-popup-menu"
                              >
                                {(popupState) => (
                                  <div>
                                    <Button
                                      className="action-btn"
                                      variant="contained"
                                      {...bindTrigger(popupState)}
                                    >
                                      <MoreVertIcon />
                                    </Button>
                                    <TableOptionList {...bindMenu(popupState)}>
                                      <MenuItem
                                        className="bold-line-600"
                                        onClick={() => {
                                          popupState.close();
                                          setEditData({ ...item });
                                          setShowEditSidebar(true);
                                        }}
                                      >
                                        <div>Edit</div>
                                      </MenuItem>

                                      <div>
                                        <Divider />
                                        <MenuItem
                                          className="bold-line-600 clr-ff0000"
                                          onClick={() => {
                                            popupState.close();
                                            deleteQuestionHandler(item);
                                          }}
                                        >
                                          <div>Delete</div>
                                        </MenuItem>
                                      </div>
                                    </TableOptionList>
                                  </div>
                                )}
                              </PopupState>
                            </Box>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              })
            ) : (
              <div className="mui-card text-center">No records found</div>
            )}
          </Grid>
        </Grid>

        <RfxQuestionEditDrawer
          showEditSidebar={showEditSidebar}
          setShowEditSidebar={setShowEditSidebar}
          editData={editData}
          rfxId={rfxDetails._id}
          getQuestionData={getQuestionData}
        />

        <RfxFormStep3
          openPopupStep3={showAddQuestionModal}
          setOpenPopupStep3={setShowAddQuestionModal}
          fromEditTab={true}
          rfxDetails={rfxDetails}
          getQuestionData={getQuestionData}
        />
      </section>
    </Paper>
  );
};

export default RfxQuestionsTab;
