import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  downloadDocumentFile,
  isGlobalRfxShow,
} from "../../../../utility/utils";
import { useState } from "react";
import RfxReadMoreModal from "./RfxReadMoreModal";
import { MAX_TEXT_DISPLAY_LENGTH } from "../../../../constants/AppConstants";

const RfxCompareResponseTab = ({
  questionsList,
  companyList,
  removeSupplierHandler,
  rfxDetails,
}) => {
  const [readMoreModal, setReadMoreModal] = useState(false);
  const [particularData, setParticularData] = useState({});
  return (
    <>
      <TableContainer
        className="rfx-compare-tab table-container"
        component={Paper}
      >
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Question Name</TableCell>

              {companyList &&
                companyList.length > 0 &&
                companyList.map((item, index) => {
                  return (
                    <TableCell
                      TableCell
                      style={{ minWidth: "350px", fontWeight: "700" }}
                    >
                      <div className="inner-th d-flex align-item-center justify-content-between w-100">
                        <div className="w-100">
                          <p className="table-word-break w-100">
                            {item?.company_name}
                          </p>
                          <div className="d-flex text-gray">
                            <Tooltip
                              title={` ${item?.user?.first_name} ${item?.user?.last_name}`}
                              placement="bottom-start"
                            >
                              <p className="simple-word-break w-auto">
                                {item?.user?.first_name} {item?.user?.last_name}
                              </p>
                            </Tooltip>
                            <div className="hr-line"></div>
                            <Tooltip
                              title={item?.email}
                              placement="bottom-start"
                            >
                              <p className="table-word-break w-50">
                                {item?.email}
                              </p>
                            </Tooltip>
                          </div>
                        </div>
                        {companyList && companyList.length !== 1 && (
                          <div
                            className="close-icon-body global-flex-center cursor-p"
                            onClick={() => removeSupplierHandler(item)}
                          >
                            <CloseIcon className="close-icon" />
                          </div>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {questionsList &&
              questionsList.length > 0 &&
              questionsList.map((row, index) => (
                <TableRow
                  key={index}
                  className={`${(index + 1) % 2 === 0 ? "bg-skyBlue" : ""} `}
                >
                  <TableCell className="">
                    <p className="breakLine">{row?.question}</p>
                  </TableCell>
                  {companyList &&
                    companyList.length > 0 &&
                    companyList.map((obj, i) => {
                      return (
                        <TableCell>
                          <div className="inner-td global-flex-center justify-content-between">
                            <div
                              className={`response-value ${
                                row?.response[i]?.attachment &&
                                row?.response[i]?.attachment?.name
                                  ? ""
                                  : "w-100"
                              }`}
                            >
                              {row?.response[i]?.answer &&
                              row?.response[i]?.answer.length > 0 &&
                              isGlobalRfxShow(
                                obj?.isSubmitted,
                                rfxDetails?.status,
                                obj?.rfxStatus,
                                rfxDetails?.sealed_bid
                              ) ? (
                                row?.response[i]?.answer.map((obj) => (
                                  <>
                                    {obj?.value ? (
                                      <p className="breakLine">
                                        {obj?.value.length >
                                        MAX_TEXT_DISPLAY_LENGTH
                                          ? obj?.value.slice(
                                              0,
                                              MAX_TEXT_DISPLAY_LENGTH
                                            )
                                          : obj?.value}
                                        {obj.value.length >
                                          MAX_TEXT_DISPLAY_LENGTH && (
                                          <span
                                            onClick={() => {
                                              setReadMoreModal(true);
                                              setParticularData({
                                                question: row?.question,
                                                answer: obj.value,
                                              });
                                            }}
                                            className="cursor-p text-primary pl-2"
                                          >
                                            ...Read more
                                          </span>
                                        )}
                                      </p>
                                    ) : (
                                      <p>--</p>
                                    )}
                                  </>
                                ))
                              ) : (
                                <p>--</p>
                              )}
                            </div>

                            {row?.response[i]?.attachment &&
                              row?.response[i]?.attachment?.name &&
                              isGlobalRfxShow(
                                obj?.isSubmitted,
                                rfxDetails?.status,
                                obj?.rfxStatus,
                                rfxDetails?.sealed_bid
                              ) && (
                                <div
                                  className="bid-doc-display compare-doc d-flex mt-2 cursor-p g-1"
                                  onClick={() =>
                                    downloadDocumentFile(
                                      row?.response[i]?.attachment?.fileName,
                                      row?.response[i]?.attachment?.name
                                    )
                                  }
                                >
                                  <AttachFileIcon className="attach-icon" />
                                  <p className="simple-word-break px-2">
                                    {row?.response[i]?.attachment?.name}
                                  </p>
                                </div>
                              )}
                          </div>
                        </TableCell>
                      );
                    })}
                  {/* {row?.response &&
                    row?.response.length > 0 &&
                    row?.response.map((item) => (
               
                    ))} */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RfxReadMoreModal
        readMoreModal={readMoreModal}
        setReadMoreModal={setReadMoreModal}
        particularData={particularData}
      />
    </>
  );
};

export default RfxCompareResponseTab;
