import { Api } from "../../../api/Api";
import { GET_LIVE_BID_GRAPH_API } from "../../../constants/ApiUrl";

const GetLiveGraphSuppliersBL = async (props) => {
  const { currentAuction } = props;

  let suppliers = [];

  // let response = await axiosInstance.get(
  //   `api/buyer/liveBidGraph/${currentAuction._id}/suppliers`
  // );
  let response = await Api(
    `${GET_LIVE_BID_GRAPH_API}/${currentAuction._id}/suppliers`,
    {},
    "get"
  );

  if (response.status === 200) {
    if (response.data && response.data.suppliers !== undefined) {
      suppliers = response.data.suppliers;
      suppliers.push({
        id: "reverse_price",
        name: "Reverse Price",
      });
    }
  }

  return {
    suppliers: suppliers,
  };
};

export default GetLiveGraphSuppliersBL;
