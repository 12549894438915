import {
  CHANGE_CONNECTION_STATE,
  INIT_SOCKET_IO,
  UPDATE_EVENT_STACK,
} from "../constant/socketConstant";

export function intiSocket(socketIO) {
  return {
    type: INIT_SOCKET_IO,
    payload: socketIO,
  };
}

export function changeConnectionState(status) {
  return {
    type: CHANGE_CONNECTION_STATE,
    payload: status,
  };
}

export function updateEventStack(eventName) {
  return {
    type: UPDATE_EVENT_STACK,
    payload: eventName,
  };
}
