import { Api } from "../../../../api/Api";
import { GET_SELLER_AUCTION_STATUS_API } from "../../../../constants/ApiUrl";

const GetAllAuctionStatusBL = async () => {
  let status = [];

  // const response = await axiosInstance.get(
  //   `api/seller/auctions/supplierAuctionStatus`
  // );
  const response = await Api(GET_SELLER_AUCTION_STATUS_API, {}, "get");

  if (response.status === 200) {
    if (response.data && response.data.status !== undefined) {
      status = response.data.status;
    }
  }

  return {
    status: status,
  };
};

export default GetAllAuctionStatusBL;
