import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Notification from "../../components/Notification";
import "./Styles/contractTable.css";
import "../Auction/styles/auction-table.css";
import TablePaginationBox from "../../components/TablePaginationBox";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import ConfirmDialog from "../../components/ConfirmDialog";
import { store } from "../../store/store";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { currentUser } from "../../store/selectors/AuthSelectors";
import { v1 as uuidv1 } from "uuid";
import { Box } from "@material-ui/core";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Button, MenuItem } from "@mui/material";
import TableOptionList from "../../components/TableOptionList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinkIcon from "@mui/icons-material/Link";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { checkHttpInclude, downloadDocumentFile } from "../../utility/utils";
import { Api } from "../../api/Api";
import { COMAPNY_API } from "../../constants/ApiUrl";
import Menu from "@mui/material/Menu";
import { Grid } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  CONTRACT_ACTIVE,
  CONTRACT_INACTIVE,
  CONTRACT_INACTIVE_CAP_SMALL,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
} from "../../constants/AppConstants";
import { GET_CONTRACT_STATUS_API } from "../../constants/ApiUrl";
var columns = [
  {
    label: "ID",
  },

  {
    label: "Contract Title",
  },
  {
    label: "Supplier Name",
  },
  {
    label: "Start Date",
  },
  {
    label: "End Date",
  },
  {
    label: "Owner",
  },
  {
    label: "Attachment",
  },
  {
    label: "Status",
  },
  {
    label: "Action",
  },
];

const ContractsTable = (props) => {
  const {
    contractList,
    isSeller,
    isBuyer,
    currentUser,
    setResendApi,
    limit,
    page,
    setPage,
    numOfPages,
  } = props;
  const history = useHistory();
  const [deleteContractId, setDeleteContractId] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  // const editContract = (auctionId) => {
  //   history.push("/auction/" + auctionId + "/details");
  // };

  const DeleteContract = (contract) => {
    if (contract._id && isBuyer) {
      setDeleteContractId(contract?._id);
      setConfirmDialog({
        isOpen: true,
        title: contract?.contract,
        subTitle: "Are you sure to delete Contract?",
      });
    }
  };

  const confirmDeleteProject = async () => {
    if (deleteContractId && isBuyer) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      const response = await Api(
        `${COMAPNY_API}/${companyId}/contract/${deleteContractId}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setConfirmDialog({
            isOpen: false,
            title: "",
            subTitle: "",
          });
          if (contractList.length - 1 === 0) {
            setPage((prevPage) => prevPage - 1);
            // setPage(page - 1);
          } else {
            setResendApi(true);
          }
        }
      }
    }
  };

  const updateContractStatus = async (contractData, newStatus) => {
    const contractId = contractData._id;
    if (contractId) {
      try {
        const response = await Api(
          `${GET_CONTRACT_STATUS_API}${contractId}`,
          {
            status: newStatus,
          },
          "post"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            setNotify({
              isOpen: true,
              message: "Status updated successfully",
              type: "success",
            });
            setResendApi(true);
          }
        }
      } catch (error) {
        setNotify({
          isOpen: true,
          message: "Failed to update status",
          type: "error",
        });
      }
    }
  };
  const handleStatusChange = (contractData, newStatus) => {
    setSelectedStatus(newStatus);
    updateContractStatus(contractData, newStatus);
  };
  const getStatusColor = (status) => {
    switch (status) {
      case CONTRACT_ACTIVE:
        return SUCCESS_BACKGROUND;
      case CONTRACT_INACTIVE_CAP_SMALL:
        return SECONDARY_BACKGROUND;
      default:
        return SECONDARY_BACKGROUND;
    }
  };
  return (
    <>
      <Paper elevation={0} square>
        <TableContainer
          className="table-container buyer-aspect"
          component={Paper}
        >
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id ?? uuidv1()}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {contractList.map((contract, index) => (
                <TableRow
                  key={contract._id}
                  className="cursor-p contract-row"
                  // onDoubleClick={() => editContract(contract._id)}
                >
                  <TableCell>{contract.contractId}</TableCell>

                  <TableCell className="auction-title">
                    <h3> {contract?.title} </h3>
                  </TableCell>
                  <TableCell className="contract-supplier-name ">
                    <h3 className="simple-word-break">
                      {contract?.supplierCompanyId?.company_name || "--"}
                    </h3>
                  </TableCell>
                  <TableCell className="auction-datetime">
                    {Moment(contract?.startDate).format("DD/MM/YYYY LT")}
                  </TableCell>
                  <TableCell className="auction-datetime">
                    {Moment(contract?.endDate).format("DD/MM/YYYY LT")}
                  </TableCell>

                  <TableCell>
                    {contract?.owner?.first_name} {contract?.owner?.last_name}
                  </TableCell>
                  {}
                  <TableCell className="text-center">
                    {contract?.attachments &&
                    contract?.attachments.length > 0 ? (
                      <span
                        onClick={() =>
                          downloadDocumentFile(
                            contract?.attachments[0]?.fileName,
                            contract?.attachments[0]?.name
                          )
                        }
                      >
                        <FileOpenIcon />
                      </span>
                    ) : (
                      <>
                        {contract?.links &&
                        contract?.links.length > 0 &&
                        contract?.links[0]?.link &&
                        contract?.links[0]?.link.length > 0 ? (
                          <span>
                            <a
                              href={checkHttpInclude(contract?.links[0]?.link)}
                              target="_blank" rel="noreferrer"
                            >
                              <LinkIcon />
                            </a>
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <Grid item xs={6}>
                      <Box m={2}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <div>
                              <Button
                                className={
                                  "badge badge-" +
                                  getStatusColor(contract.status) +
                                  " status"
                                }
                                variant="contained"
                                {...bindTrigger(popupState)}
                                endIcon={<ArrowDropDownIcon />}
                              >
                                {contract.status}
                              </Button>
                              <Menu
                                {...bindMenu(popupState)}
                                PaperProps={{
                                  sx: {
                                    padding: "3px",
                                  },
                                }}
                              >
                                <div>
                                  <button
                                    className="badge badge-success select-colored-option"
                                    onClick={() => {
                                      handleStatusChange(
                                        contract,
                                        CONTRACT_ACTIVE
                                      );
                                      popupState.close();
                                    }}
                                    disabled={
                                      contract.status === CONTRACT_ACTIVE
                                        ? true
                                        : false
                                    }
                                  >
                                    {CONTRACT_ACTIVE}
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="badge badge-secondary select-colored-option"
                                    onClick={() => {
                                      handleStatusChange(
                                        contract,
                                        CONTRACT_INACTIVE
                                      );
                                      popupState.close();
                                    }}
                                  >
                                    {CONTRACT_INACTIVE}
                                  </button>
                                </div>
                              </Menu>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </Grid>
                  </TableCell>
                  <TableCell className="auction-action-tab">
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <div>
                                <MenuItem
                                  className="bold-line-600 clr-ff0000"
                                  onClick={() => {
                                    DeleteContract(contract);
                                    popupState.close();
                                  }}
                                >
                                  <div>Delete</div>
                                </MenuItem>
                              </div>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {contractList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={limit + " Contracts Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmDeleteProject={confirmDeleteProject}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(ContractsTable));
