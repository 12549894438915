export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const EMAIL_VERIFY = "[Email Verification] Email Verify";
export const SIGNUP_EXIST_ACTION = "[Email Exist ] Email Exist";
export const FORGOT_PASSWORD_CONFIRMED_ACTION =
  "[Forgot Password ] Forgot Password";
export const FORGOT_PASSWORD_FAILED_ACTION =
  "[Forgot Password Faild ] Forgot Password Faild";
export const UPDATE_PROFILE_DETAILS_ACTION =
  "[Profile Update] UPDATE PROFILE DETAILS ACTION";
export const ROLE_UPDATE_ACTION = "ROLE_UPDATE_ACTION";
export const USER_UPDATE_ACTION = "USER_UPDATE_ACTION";
