export const getCurrentAuction = (state) => {
  return state.supplierAuction.auction;
};

export const shouldRefreshLiveBiddingData = (state) => {
  return state.supplierAuction.refreshLiveBiddingData;
};


export const shouldRefreshLiveAuctionSupplier = (state) => {
  return state.supplierAuction.refreshLiveAuction;
};
