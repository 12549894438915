import { Api } from "../../../api/Api";
import { GET_ALL_BID_BY_RANK_API } from "../../../constants/ApiUrl";

const GetAllSupplierRanksBL = async (props) => {
  const { currentAuction, page, rowsPerPage } = props;

  let list = [];
  let limit = 0;
  let winners = [];

  try {
    let auctionId = currentAuction._id;
    const response = await Api(
      `${GET_ALL_BID_BY_RANK_API}/${auctionId}?per_page=${rowsPerPage}&page=${page}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data && response.data.allSupBid !== undefined) {
        list = response.data.allSupBid;
        limit = response.data.totalRecord;
        winners = response.data.supplierWinner;
      }
    }

    // let response = await axiosInstance.get(
    //   `api/buyer/getAllBidByAuctionRank/${auctionId}?per_page=${rowsPerPage}&page=${page}`
    // );
  } catch (err) {}

  return {
    list: list,
    limit: limit,
    winners: winners,
  };
};

export default GetAllSupplierRanksBL;
