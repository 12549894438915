/*eslint-disable */
import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import Controls from "../../components/controls/Controls";
import useWindowDimensions from "../../components/useWindowDimensions";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckIcon from "@mui/icons-material/Check";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link as Links } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
// import faceLogo from "../../assets/auth/octioLogo.svg";
// import faceLogo from "../../assets/auth/Oktio_loko.png";
import faceLogo from "../../assets/auth/Logo_Oktio_last.png";

import textLogo from "../../assets/auth/logotxt.png";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { connect, useDispatch } from "react-redux";
import { Snackbar, TextField } from "@material-ui/core";
import Cookies from "universal-cookie";
import { Alert } from "@material-ui/lab";
import {
  loadingToggleAction,
  loginAction,
  loginFailedAction,
} from "../../store/actions/authActions";
import "./styles/auth.css";
import "./styles/login.css";
import { store } from "../../store/store";
import { TOAST_AUTO_CLOSE, VALID_EMAIL_REGEX } from "../../constants/AppConstants";
import { toast, Slide } from "react-toastify";

const initialFValues = {
  email: "",
  password: "",
};
const cookies = new Cookies();
const Login = (props) => {
  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  // let errorsObj = { email: "", password: { password: "" } };
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initialFValues);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isRememberMe, setIsRememberMe] = useState(false);

  const userDetails = localStorage.getItem("userDetails");
  if (!userDetails) localStorage.setItem("guest", "true");

  const dispatch = useDispatch();

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setPassword({ ...password, [prop]: event.target.value });
  };

  const [password, setPassword] = useState({
    password: "",
    showPassword: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setNotify({
    //   ...notify,
    //   isOpen: false,
    // });
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("password" in fieldValues) {
      if (fieldValues.password && fieldValues.password.length > 0) {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 character";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                if (!/[!@#$*%&_^{}[\]/|\\()?-]/.test(fieldValues.password)) {
                  temp.password = "Password  must be 1 special character";
                } else {
                  temp.password = "";
                }
              }
            }
          }
        }
      } else {
        temp.password = "Password is required.";
      }
    }

    if ("email" in fieldValues) {
      if (fieldValues.email && fieldValues.email.length > 0) {
        if (!VALID_EMAIL_REGEX.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "Email is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  function onLogin(e) {
    e.preventDefault();

    // let error = false;
    // const errorObj = { ...errorsObj };

    // if (!email) {
    //   errorObj.email = "Email is required";
    //   error = true;
    // }

    // if (email !== "") {
    //   error = /$^|.+@.+..+/.test(email) ? false : true;

    //   if (error) errorObj.email = "Invalid Email";
    // }

    // if (!password.password) {
    //   errorObj.password.password = "Password  is required ";
    //   error = true;
    // }

    // setErrors(errorObj);

    // if (error) return;
    if (validate()) {
      dispatch(loadingToggleAction(true));
      dispatch(
        loginAction(values.email, values.password, props.history, isRememberMe)
      );
    }
  }

  useEffect(() => {
    if (props.successMessage !== "") {
      toast.success(props.successMessage, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  }, [props.successMessage]);

  useEffect(() => {
    if (props.errorMessage !== "") {
      toast.error(props.errorMessage, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      dispatch(loginFailedAction(""));
    }
  }, [props.errorMessage]);

  const saveCookieData = () => {
    setIsRememberMe(!isRememberMe);
  };

  useEffect(() => {
    const userEmail = cookies.get("myEmail");
    const userPassword = cookies.get("myPassword");
    if (userEmail !== "" && userPassword !== "") {
      setValues({
        ...values,
        email: userEmail,
        password: userPassword,
      });
      // setEmail(userEmail);
      // setPassword({
      //   password: userPassword,
      //   showPassword: false,
      // });
    }
  }, []);

  return (
    <div className="auth-box-container login">
      <CssBaseline />
      <div style={{ flexGrow: 1, marginBottom: "25px" }}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          style={{ flexDirection: smScreen ? "column-reverse" : "row" }}
        >
          <Grid item xl={4} lg={5} md={6} sm={8} xs={11}>
            <div className="form-box-container">
              {props.showLoading && <Loader />}
              <Grid container className="form-banner-container">
                <Grid item xs={12}>
                  <div className="main-title">Login to your Account</div>
                  <div className="sub-title">
                    Enter to continue and explore within your grasp
                  </div>
                </Grid>
              </Grid>
              <form onSubmit={onLogin} noValidate>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item sm={10} xs={12}>
                    <Controls.Input
                      name="email"
                      autoComplete=""
                      id="email"
                      fullWidth
                      required
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      // value={email}
                      value={values?.email}
                      // onChange={(e) => setEmail(e.target.value)}
                      onChange={handleInputChange}
                      placeholder="Email Address"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon color="primary" fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.email && (
                      <div className="error-msg">{errors.email}</div>
                    )}
                    {/* {errors.email && (
                      <div className="error-msg">{errors.email}</div>
                    )} */}
                  </Grid>
                  <Grid item sm={10} xs={12}>
                    <TextField
                      name="password"
                      autoComplete="current-password"
                      id="password"
                      fullWidth
                      required
                      // value={password.password}
                      value={values?.password}
                      // onChange={handlePasswordChange("password")}
                      onChange={handleInputChange}
                      type={password.showPassword ? "text" : "password"}
                      placeholder="Password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon color="primary" fontSize="small" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {password.showPassword ? (
                                <Visibility color="primary" fontSize="small" />
                              ) : (
                                <VisibilityOff
                                  color="primary"
                                  fontSize="small"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      className="mui-input"
                      variant="outlined"
                    />

                    {errors.password && (
                      <div className="error-msg">{errors.password}</div>
                    )}
                  </Grid>
                  <Grid item sm={10} xs={12} className="mt-3">
                    <div style={{ fontSize: "16px", textAlign: "end" }}>
                      <Links to="/forgotpassword">Forgot Password?</Links>
                    </div>
                  </Grid>
                  <Grid item sm={10} xs={12}>
                    <div className="remember-me-box-container">
                      <div
                        className={
                          "checkbox checkbox-primary " +
                          (rememberMe ? "checked" : "")
                        }
                        onClick={() => {
                          setRememberMe(!rememberMe);
                          saveCookieData();
                        }}
                      >
                        <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                        <CheckIcon aria-label="check-mark" />
                      </div>
                      <span
                        className="cursor-p"
                        onClick={() => {
                          setRememberMe(!rememberMe);
                          saveCookieData();
                        }}
                      >
                        Remember Me
                      </span>
                    </div>
                  </Grid>
                  <Grid item sm={8} xs={12} className="mt-4">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      // disabled={!vcaptcha}
                      className="submit-btn"
                    >
                      Login to Continue
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="text-center mt-4 fz-16">
                      Don't have an account? Sign up as a&nbsp;
                      <Links to={{ pathname: "/signup", state: "buyer" }}>
                        buyer
                      </Links>
                      &nbsp;or&nbsp;
                      <Links to={{ pathname: "/signup", state: "seller" }}>
                        seller
                      </Links>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
          <Grid item lg={4} md={3} xs={8}>
            <Grid
              container
              justifyContent="center"
              alignItems="end"
              style={{ flexDirection: "column" }}
            >
              <Grid item sm={12} style={{ textAlign: "-webkit-center" }}>
                <div className="face-logo">
                  <img src={faceLogo} />
                </div>
              </Grid>
              <Grid item sm={12}>
                <div className="text-logo-box-container">
                  <div className="text-logo-box">
                    <span>oktio</span>
                  </div>
                  {/* <div className="text-logo-img"></div> */}
                  {/* <img src={textLogo} className="text-logo-img" /> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Snackbar
            // open={notify.isOpen}
            open={false}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={handleClose}
          >
            <Alert severity={notify.type} onClose={handleClose}>
              {notify.message}
            </Alert>
          </Snackbar>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Login);
