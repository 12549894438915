import { useRef, useState } from "react";

// interface DownloadFileProps {
//   apiDefinition: () => Promise<AxiosResponse<Blob>>;
//   preDownloading: () => void;
//   postDownloading: () => void;
//   onError: () => void;
//   getFileName: () => string;
// }

// interface DownloadedFileInfo {
//   download: () => Promise<void>;
//   downloadRef: React.MutableRefObject<HTMLAnchorElement | null>;
//   downloadName: string | undefined;
//   downloadUrl: string | undefined;
// }

const useDownloadFile = ({
  apiDefinition,
  preDownloading = null,
  postDownloading = null,
  onError = null,
  getFileName,
}) => {
  // HTMLAnchorElement
  const downloadRef = useRef(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [downloadName, setDownloadName] = useState(null);

  const download = async () => {
    try {
      if (preDownloading) {
        preDownloading();
      }
      const { data } = await apiDefinition();
      const downloadUrl = URL.createObjectURL(new Blob([data]));
      setDownloadUrl(downloadUrl);
      setDownloadName(getFileName());
      downloadRef.current?.click();
      if (postDownloading) {
        postDownloading();
      }
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      if (onError) {
        onError();
      }
    }
  };

  return { download, downloadRef, downloadUrl, downloadName };
};

export default useDownloadFile;
