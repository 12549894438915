import { useEffect, useState } from "react";
import { Grid, Box, Tabs, Tab, Paper, Typography } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import RfxCompareResponseTab from "./RfxCompareResponseTab";
import RfxSupplierModal from "./RfxSupplierModal";
import { store } from "../../../../store/store";
import { useSelector } from "react-redux";
import { POST_SUPPLIER_SAVE_IMPORT_DATA_API } from "../../../../constants/ApiUrl";
import { Api } from "../../../../api/Api";
import RfxComparePriceTab from "./RfxComparePriceTab";
import { downloadDocumentFile } from "../../../../utility/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-label={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const RfxCompareTabContainer = (props) => {
  const { auth } = store.getState();
  const companyId = auth?.auth?.user?.company?._id;
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);
  const [value, setValue] = useState(0);
  const [showSupplierModal, setShowSupplierModal] = useState(false);

  const [questionsList, setQuestionsList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [itemPriceList, setItemPriceList] = useState([]);
  const [itemCompanyList, setItemCompanyList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (rfxDetails && rfxDetails?._id) {
      getQuestionCompareList();
      getPriceCompareList();
    }
  }, [rfxDetails]);

  const getQuestionCompareList = async (isExport = false, comapny) => {
    let queryString = "";
    let path = `${POST_SUPPLIER_SAVE_IMPORT_DATA_API}/${companyId}/rfx/${rfxDetails?._id}/compareResponse`;

    if (comapny && comapny.length > 0) {
      const stringifyCompany = JSON.stringify(comapny);
      queryString = `?suppliers=${stringifyCompany}`;
      path += queryString;
    }
    if (isExport) {
      if (comapny && comapny.length > 0) {
        path += `&exportToExcel=1`;
      } else {
        path += `?exportToExcel=1`;
      }
    }
    const response = await Api(path, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        if (isExport) {
          downloadDocumentFile(
            response?.data?.data?.resourceUrl,
            "Compare Question"
          );
        }
        if (!comapny) {
          let temp = [];
          let data = [...response?.data?.data?.companyList];

          if (data && data.length > 0) {
            data.map((innerItem) => temp.push(innerItem?._id));
            setFilteredData([...temp]);
          }
        }
        if (
          response?.data?.data?.questions &&
          response?.data?.data?.questions.length > 0
        ) {
          let tempQuestion = [...response?.data?.data?.questions];
          let tempCompany = [...response?.data?.data?.companyList];
          tempQuestion.map((item) => {
            let tempResponse = [];
            tempCompany.map((obj) => {
              const t = item.response.find(
                (e) => e.supplierCompanyId === obj._id
              );
              if (t) tempResponse.push(t);
              else {
                tempResponse.push({});
              }
            });

            item.response = [...tempResponse];
          });

          setQuestionsList([...tempQuestion]);
        }
        if (
          response?.data?.data?.companyList &&
          response?.data?.data?.companyList.length > 0
        )
          setCompanyList([...response?.data?.data?.companyList]);
      }
    }
  };
  const getPriceCompareList = async (isExport = false, comapny) => {
    let queryString = "";

    let path = `${POST_SUPPLIER_SAVE_IMPORT_DATA_API}/${companyId}/rfx/${rfxDetails?._id}/comparePrice`;
    if (comapny && comapny.length > 0) {
      const stringifyCompany = JSON.stringify(comapny);
      queryString = `?suppliers=${stringifyCompany}`;
      path += queryString;
    }
    if (isExport) {
      if (comapny && comapny.length > 0) {
        path += `&exportToExcel=1`;
      } else {
        path += `?exportToExcel=1`;
      }
    }
    const response = await Api(path, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        if (isExport) {
          downloadDocumentFile(
            response?.data?.data?.resourceUrl,
            "Compare Price"
          );
        }
        if (
          response?.data?.data?.items &&
          response?.data?.data?.items.length > 0
        ) {
          let tempItem = [...response?.data?.data?.items];
          let tempCompany = [...response?.data?.data?.companyList];
          tempItem.map((item) => {
            let tempResponse = [];
            tempCompany.map((obj) => {
              const t = item.response.find(
                (e) => e.supplierCompanyId === obj._id
              );
              if (t) tempResponse.push(t);
              else {
                tempResponse.push({});
              }
              setItemPriceList([...tempItem]);
            });

            item.response = [...tempResponse];
          });
        }
        if (
          response?.data?.data?.companyList &&
          response?.data?.data?.companyList.length > 0
        )
          setItemCompanyList([...response?.data?.data?.companyList]);
      }
    }
  };

  const removeSupplierHandler = (obj) => {
    const temp = [...filteredData];

    const finalResponse = temp.filter((item) => item !== obj?._id);
    if (finalResponse.length > 0) setFilteredData([...finalResponse]);
    else setFilteredData([]);
    getQuestionCompareList(false, finalResponse);
    getPriceCompareList(false, finalResponse);
  };

  const exportToExcel = () => {
    if (value === 0) {
      getQuestionCompareList(true, filteredData);
    } else {
      getPriceCompareList(true, filteredData);
    }
  };
  return (
    <>
      <Grid
        container
        className="rfx-tab-header tab-header-container"
        style={{ marginTop: "2em" }}
        justifyContent="space-between"
      >
        <Box
          sx={{ borderRadius: "25px !important" }}
          className="rfx-compare-tab-label"
        >
          <Tabs className="tab-header" value={value} onChange={handleChange}>
            <Tab label="Compare Response" />
            <Tab label="Compare Price" />
          </Tabs>
        </Box>
        <div>
          <Controls.Button
            text="Export"
            className="btn btn-success btn-mui"
            startIcon={<SystemUpdateAltIcon />}
            onClick={() => {
              exportToExcel();
            }}
          />
          <Controls.Button
            text="Add Supplier"
            className="btn btn-primary btn-mui"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setShowSupplierModal(true)}
          />
        </div>
      </Grid>
      <Paper className="tab-body-container">
        <TabPanel value={value} index={0} labelAria="no-padding">
          <RfxCompareResponseTab
            questionsList={questionsList}
            companyList={companyList}
            removeSupplierHandler={removeSupplierHandler}
            rfxDetails={rfxDetails}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RfxComparePriceTab
            itemPriceList={itemPriceList}
            itemCompanyList={itemCompanyList}
            removeSupplierHandler={removeSupplierHandler}
            rfxDetails={rfxDetails}
          />
        </TabPanel>
      </Paper>
      {showSupplierModal && (
        <RfxSupplierModal
          showSupplierModal={showSupplierModal}
          setShowSupplierModal={setShowSupplierModal}
          getQuestionCompareList={getQuestionCompareList}
          getPriceCompareList={getPriceCompareList}
          setFilteredData={setFilteredData}
          filteredData={filteredData}
        />
      )}
    </>
  );
};

export default RfxCompareTabContainer;
