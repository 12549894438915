/*eslint-disable */
import * as React from "react";
import { Box, Grid } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Search from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import {
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Modal,
} from "@mui/material";
import Controls from "../../../components/controls/Controls";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import DefImage from "../../../assets/lotus.jpg";
import useWindowDimensions from "../../../components/useWindowDimensions";
import TableHeadOptionList from "../../../components/TableHeadOptionList";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import ItemAttachDocPopup from "./itemAttachDocPopup";
import "../style/item-list-info-details.css";
import ItemAttachLinkPopup from "./itemAttachLinkPopup";
import UserImage from "../../../assets/auth/user_icon_2.png";
import axiosInstance from "../../../services/AxiosInstance";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import usePrevious from "../../../components/usePrevious";
import CloseIcon from "@mui/icons-material/Close";
import AddLibrary from "./addFromLibrary";
import { useSelector } from "react-redux";
import {
  ACCEPT_ONLY_IMAGE,
  AUCTION_ACTIVE,
  AUCTION_INACTIVE_CAP_SMALL,
  ITEM_FILE_SIZE,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  TOAST_AUTO_CLOSE,
} from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { Api } from "../../../api/Api";
import {
  DELETE_UPLOAD_ITEM_API,
  GET_DOCUMENT_LIST_API,
  GET_ITEM_DETAILS_API,
  POST_EDIT_ITEM_API,
  POST_EDIT_ITEM_DRAFT_API,
} from "../../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";

const getStatusColor = (status) => {
  switch (status) {
    case AUCTION_ACTIVE:
      return SUCCESS_BACKGROUND;
    case AUCTION_INACTIVE_CAP_SMALL:
      return SECONDARY_BACKGROUND;
    default:
      return SECONDARY_BACKGROUND;
  }
};

const columns = [
  {
    label: "Attachment",
  },
  {
    label: "Name",
    // minWidth: 100
  },
  {
    label: "Notes",
  },
  {
    label: "Added By",
  },
  {
    label: "",
  },
];

export default function ItemListDetails(props) {
  const { item, setNotify } = props;
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const companyDetails = useSelector(
    (state) => state?.auth?.auth?.user?.company
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [itemImage, setItemImage] = React.useState("");
  const openMenu = Boolean(anchorEl);
  const [list, setList] = React.useState([]);
  const [limit, setLimit] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [documentPopup, setDocumentPopup] = React.useState(false);
  const [linkPopup, setLinkPopup] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState("");
  const [itemDetails, setItemDetails] = React.useState({});
  const [itemStatus, setItemStatus] = React.useState("");
  const [editAttachmentData, setEditAttachmentData] = React.useState({});
  const [isEditAttachment, setIsEditAttachment] = React.useState(false);
  const [editLinkData, setEditLinkData] = React.useState({});
  const [isEditLink, setIsEditLink] = React.useState(false);
  const [alertDailog, setAlertDailog] = React.useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = React.useState({});
  const handleClose = () => setAlertDailog(false);
  const [openPopupLib, setOpenPopupLib] = React.useState(false);

  const numOfPages = Math.ceil(list.length / rowsPerPage);

  const getBodyWidth = () => {
    if (xlScreen) {
      return "translateX(1vw)";
    } else if (xsScreen) {
      return "translateX(0vw)";
    } else if (smScreen) {
      return "translateX(3vw)";
    } else if (mdScreen) {
      return "translateX(4vw)";
    } else if (lgScreen) {
      return "translateX(5vw)";
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const validateImageSize = (file) => {
    const maxSize = ITEM_FILE_SIZE;
    if (file) {
      const fsize = file.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ACCEPT_ONLY_IMAGE;
    if (validateImageSize(selected)) {
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64Image = await getBase64(selected);
        setItemImage(base64Image);
        if (item && item?._id) {
          // const payload = {
          //   image_upload: base64Image,
          // };
          let formData = new FormData();

          formData.append("document", selected);

          const response = await Api(
            `${POST_EDIT_ITEM_API}/${item?._id}`,
            formData,
            "patch"
          );

          // axiosInstance
          //   .patch(`api/buyer/item/editItem/${item?._id}`, payload)
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setNotify({
          //         isOpen: true,
          //         message: "Image updated successfully",
          //         type: "success",
          //       });
          //     } else {
          //       setNotify({
          //         isOpen: true,
          //         message: response?.data?.responseDescription,
          //         type: "error",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //   });
        } else {
          toast.error(ValidationMessage.somethingWrong, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      } else {
        toast.error(ValidationMessage.itemFileType, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    } else {
      toast.error(ValidationMessage.itemFileSize, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  }

  const updateItemStatus = async (item, status) => {
    const itemId = item?._id;
    if (itemId) {
      const payload = {
        status: status,
        companyId: companyDetails?._id,
      };
      const response = await Api(
        `${POST_EDIT_ITEM_DRAFT_API}/${itemId}`,
        payload,
        "patch"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setItemStatus(status);
        }
      }

      // await axiosInstance
      //   .patch(`api/buyer/item/EditsaveItemDraft/${itemId}`, payload)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       setItemStatus(status);
      //       setNotify({
      //         isOpen: true,
      //         message: "Status changed successfully",
      //         type: "success",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: "Something goes wrong",
      //       type: "error",
      //     });
      //   });
    }
  };

  const removeImage = () => {
    setItemImage("");
  };

  const getAllAttachments = async (searchText) => {
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    if (item && item?._id) {
      const response = await Api(
        `${GET_DOCUMENT_LIST_API}/${item?._id}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let AllDocs = response.data.attachments.documents;
          let linkArray = response.data?.attachments?.links;
          let libraryDocs = response.data?.attachments?.library;
          if (AllDocs && AllDocs.length > 0) {
            setList(AllDocs);
          }
          if (linkArray && linkArray.length > 0) {
            setList(linkArray);
          }
          if (libraryDocs && libraryDocs.length > 0) {
            setList(libraryDocs);
          }
          if (linkArray.length > 0 && AllDocs.length > 0) {
            const combine = [...AllDocs, ...linkArray];
            setList(combine);
          }
          if (libraryDocs.length > 0 && AllDocs.length > 0) {
            const combine = [...AllDocs, ...libraryDocs];
            setList(combine);
          }
          if (libraryDocs.length > 0 && linkArray.length > 0) {
            const combine = [...libraryDocs, ...linkArray];
            setList(combine);
          }
          if (
            linkArray.length > 0 &&
            AllDocs.length > 0 &&
            libraryDocs.length > 0
          ) {
            const combine = [...AllDocs, ...linkArray, ...libraryDocs];
            setList(combine);
          }
        } else {
          setList([]);
        }
      } else {
        setList([]);
      }

      // axiosInstance
      //   .get(`api/buyer/item/documentList/${item?._id}${queryString}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let AllDocs = response.data.attachments.documents;
      //       let linkArray = response.data?.attachments?.links;
      //       let libraryDocs = response.data?.attachments?.library;
      //       if (AllDocs && AllDocs.length > 0) {
      //         setList(AllDocs);
      //       }
      //       if (linkArray && linkArray.length > 0) {
      //         setList(linkArray);
      //       }
      //       if (libraryDocs && libraryDocs.length > 0) {
      //         setList(libraryDocs);
      //       }
      //       if (linkArray.length > 0 && AllDocs.length > 0) {
      //         const combine = [...AllDocs, ...linkArray];
      //         setList(combine);
      //       }
      //       if (libraryDocs.length > 0 && AllDocs.length > 0) {
      //         const combine = [...AllDocs, ...libraryDocs];
      //         setList(combine);
      //       }
      //       if (libraryDocs.length > 0 && linkArray.length > 0) {
      //         const combine = [...libraryDocs, ...linkArray];
      //         setList(combine);
      //       }
      //       if (
      //         linkArray.length > 0 &&
      //         AllDocs.length > 0 &&
      //         libraryDocs.length > 0
      //       ) {
      //         const combine = [...AllDocs, ...linkArray, ...libraryDocs];
      //         setList(combine);
      //       }
      //     } else {
      //       setList([]);
      //     }
      //   })
      //   .catch((err) => {
      //     setList([]);
      //   });
    }
  };

  const getItems = async (searchText) => {
    if (item && item?._id) {
      const response = await Api(
        `${GET_ITEM_DETAILS_API}/${item?._id}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let itemDetail = response.data.item;

          setItemImage(itemDetail?.image_upload);
          setItemStatus(itemDetail?.status);
          setItemDetails(itemDetail);
        }
      }

      // axiosInstance
      //   .get(`api/buyer/item/itemDetail/${item?._id}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let itemDetail = response.data.item;

      //       setItemImage(itemDetail?.image_upload);
      //       setItemStatus(itemDetail?.status);
      //       setItemDetails(itemDetail);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const deleteDocument = async (attachment) => {
    if (attachment) {
      setAlertDailog(true);
      setDeleteAttachmentId(attachment);
    }
  };

  const confirmDeleteAttachment = async () => {
    if (deleteAttachmentId) {
      const response = await Api(
        `${DELETE_UPLOAD_ITEM_API}/${item?._id}/${deleteAttachmentId._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllAttachments();
          setAlertDailog(false);
          setDeleteAttachmentId({});
        }
      }

      // await axiosInstance
      //   .delete(
      //     `api/buyer/removeUploadItemAttachment/${item?._id}/${deleteAttachmentId._id}`
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       getAllAttachments();
      //       setAlertDailog(false);
      //       setDeleteAttachmentId({});
      //       setNotify({
      //         isOpen: true,
      //         message: "Attachment removed successfully.",
      //         type: "success",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const EditDocs = (attachment) => {
    if (attachment && attachment.fileName) {
      setEditAttachmentData(attachment);
      setIsEditAttachment(true);
      setDocumentPopup(true);
    } else if (attachment && attachment.link) {
      setEditLinkData(attachment);
      setIsEditLink(true);
      setLinkPopup(true);
    } else {
      toast.error(ValidationMessage.updateDocument, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  React.useEffect(() => {
    getAllAttachments();
    getItems();
  }, [item]);

  const getSearchString = (e) => {
    const searchText = e.target.value;
    getAllAttachments(searchText);
  };

  return (
    <>
      {item && item.status ? (
        <Grid container className="item-list-details-info-container">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                <h3 className="mt-2">{item?.item_name || ""}</h3>
              </Grid>
              <Grid item xs={4}>
                <Grid container className="text-end">
                  <Grid item xs={12}>
                    <h4>{itemDetails?.itemId || ""}</h4>
                  </Grid>
                  <Grid item xs={12}>
                    <Box m={2} className="table-container">
                      <PopupState
                        variant="popover"
                        popupId="item-list-info-details-status"
                      >
                        {(popupState) => (
                          <div>
                            <Button
                              className={
                                "badge badge-" +
                                getStatusColor(itemStatus) +
                                " status"
                              }
                              variant="contained"
                              {...bindTrigger(popupState)}
                              endIcon={<ArrowDropDownIcon />}
                            >
                              {itemStatus}
                            </Button>
                            <Menu
                              {...bindMenu(popupState)}
                              PaperProps={{
                                sx: {
                                  padding: "3px",
                                },
                              }}
                            >
                              <button
                                className="badge badge-success select-colored-option"
                                disabled={
                                  itemStatus === { AUCTION_ACTIVE }
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  updateItemStatus(itemDetails, AUCTION_ACTIVE)
                                }
                              >
                                {AUCTION_ACTIVE}
                              </button>
                              <button
                                className="badge badge-secondary select-colored-option"
                                onClick={() =>
                                  updateItemStatus(
                                    itemDetails,
                                    AUCTION_INACTIVE_CAP_SMALL
                                  )
                                }
                              >
                                {AUCTION_INACTIVE_CAP_SMALL}
                              </button>
                            </Menu>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="image-container">
              <div
                className={"empty-bck " + (itemImage ? "d-none" : "d-block")}
              >
                there is no image
              </div>
              <img src={itemImage} className="image" />
              <div className="middle" style={{ transform: getBodyWidth() }}>
                <div className="hover-box">
                  <label>
                    <Controls.Button
                      component="span"
                      text="Upload new image"
                      disableElevation
                      className="btn-mui btn btn-primary mr-2"
                      style={{ minWidth: "50%" }}
                    />
                    <input
                      accept="image/*"
                      id="outlined-button-file"
                      hidden
                      onChange={handleImageItem}
                      type="file"
                    />
                  </label>

                  <Button
                    className="btn-mui btn btn-primary"
                    style={{
                      minWidth: "20%",
                      backgroundColor: "#F1F0F2",
                      borderColor: "#F1F0F2",
                      color: "#000",
                    }}
                    onClick={() => removeImage()}
                  >
                    Delete
                  </Button>
                  <p style={{ minWidth: "50%", fontSize: "13px" }}>
                    800x600 px recommended <br /> JPG or PNG Max size of 800k
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: "5em" }}>
              <Grid container className="settings-company-section-contaier">
                <Grid item md={4} sm={5} xs={12}>
                  <p className="profile-title" style={{ borderBottom: "none" }}>
                    Attachments
                  </p>
                </Grid>
              </Grid>
              <Grid container className="table-header-container">
                <Grid item md={6} xs={12}>
                  <TextField
                    onChange={getSearchString}
                    placeholder="Search Using Keyword"
                    className=""
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  style={{ textAlign: xsScreen ? "center" : "end" }}
                >
                  <Button
                    aria-controls="item-list-info-details-attach-option-list"
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    className="btn-mui-table-option"
                  >
                    <AddOutlinedIcon style={{ fontSize: "20px" }} />
                    <span>Add Attachment</span>
                  </Button>
                  <TableHeadOptionList
                    id="item-list-info-details-attach-option-list"
                    MenuListProps={{
                      "aria-label": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setDocumentPopup(true);
                      }}
                      disableRipple
                    >
                      Attach Document
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setOpenPopupLib(true);
                      }}
                      disableRipple
                    >
                      Add From Library
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setLinkPopup(true);
                      }}
                      disableRipple
                    >
                      URL Link
                    </MenuItem>
                  </TableHeadOptionList>
                </Grid>
              </Grid>

              <TableContainer className="table-container" component={Paper}>
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontWeight: "700",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? list.slice(
                          (page - 1) * rowsPerPage,
                          (page - 1) * rowsPerPage + rowsPerPage
                        )
                      : list
                    ).map((item, index) => (
                      <TableRow key={item._id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ textAlign: "center" }}
                        >
                          <div>
                            {item.type === "file" ? (
                              <FilePresentOutlinedIcon
                                style={{
                                  color: "#A19B9D",
                                  verticalAlign: "middle",
                                }}
                              />
                            ) : (
                              <LinkOutlinedIcon
                                style={{
                                  color: "#A19B9D",
                                  verticalAlign: "middle",
                                }}
                              />
                            )}
                            <span
                              style={{
                                color: "#2170FF",
                                marginLeft: "0.2em",
                                verticalAlign: "bottom",
                              }}
                            >
                              <a
                                src={item && item.name ? item.name : item.link}
                              >
                                {item && item.name
                                  ? item.name
                                  : item && item.link
                                  ? item.link
                                  : item && item?.doc
                                  ? item?.doc?.document?.name
                                  : "-"}
                              </a>
                            </span>
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.title || "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.notes || "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="text-center"
                        >
                          <img
                            src={item?.createdBy?.image_upload || UserImage}
                            style={{
                              width: "2.7em",
                              height: "2.7em",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Box>
                            <PopupState
                              variant="popover"
                              popupId="item-list-info-details-option-list"
                            >
                              {(popupState) => (
                                <div>
                                  <Button
                                    className="action-btn"
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                  >
                                    <MoreVertIcon />
                                  </Button>
                                  <TableOptionList {...bindMenu(popupState)}>
                                    <MenuItem
                                      className="bold-line-600"
                                      onClick={() => {
                                        EditDocs(item);
                                        popupState.close();
                                      }}
                                    >
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      className="bold-line-600"
                                      style={{ color: "red" }}
                                      onClick={() => {
                                        deleteDocument(item);
                                        popupState.close();
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </TableOptionList>
                                </div>
                              )}
                            </PopupState>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                    {list.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          className="text-center"
                        >
                          No records found <br />
                          {/* <i style={{ color: "var(---2170ff-p-)" }}>
                            Please add some attachemnt
                          </i> */}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
                <TablePaginationBox
                  label={list.length + " Attachments Found"}
                  numOfPages={numOfPages}
                  onChangePage={(event, newPage) => setPage(newPage)}
                  page={page}
                />
              </TableContainer>
              <ItemAttachDocPopup
                openPopup={documentPopup}
                setOpenPopup={setDocumentPopup}
                setNotify={setNotify}
                editAttachmentData={editAttachmentData}
                setEditAttachmentData={setEditAttachmentData}
                isEditAttachment={isEditAttachment}
                setIsEditAttachment={setIsEditAttachment}
                itemDetail={item}
                getAllAttachments={getAllAttachments}
              />
              <ItemAttachLinkPopup
                openPopup={linkPopup}
                setOpenPopup={setLinkPopup}
                editLinkData={editLinkData}
                setEditLinkData={setEditLinkData}
                isEditLink={isEditLink}
                setIsEditLink={setIsEditLink}
                itemDetail={item}
                getAllAttachments={getAllAttachments}
                setNotify={setNotify}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: "20vh" }}></div>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: "1600" }}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete attachment?
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() => setAlertDailog(false)}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={() => confirmDeleteAttachment()}>Confirm</Button>
        </DialogActions>
      </Dialog>

      {/* add from library  */}

      <Modal
        open={openPopupLib}
        aria-label="modal-add-document-from-library"
        aria-describedby="modal-modal-description"
        style={{ zIndex: "1600" }}
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Library</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopupLib(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          {/* <div className="title"> Library </div> */}
          <AddLibrary
            setOpenPopupLib={setOpenPopupLib}
            setNotify={setNotify}
            getAllAttachments={getAllAttachments}
            itemDetail={item}
          />
        </Box>
      </Modal>
      {/* add from library  */}
    </>
  );
}
