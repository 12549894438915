import { updateEventStack } from "../../../store/actions/socketAction";
import { refreshLiveBiddingDataSupplier } from "../../../store/actions/supplierAspect/auctionAction";
import { refreshLiveBiddingDataBuyer } from "../../../store/actions/auctionAction";
import {
  updateLiveData,
  updateLiveEventStatus,
} from "../../../store/actions/supplierAspect/liveBidAction";
import { SEND_NEW_BID_RESPONSE_EVENT } from "../constants";
import SocketHandler from "../SocketHandler";

const SendNewBidResEvent = () => {
  SocketHandler.instance.on(SEND_NEW_BID_RESPONSE_EVENT, (response) => {
    let liveData = {
      best: response.data.bestBid ? response.data.bestBid : {},
      totalCount: response.data.totalCount ? response.data.totalCount : 0,
    };
    // have a new current bid
    //
    if (response.meta.success === true) {
      if (response.data.myCurrentBid && response.data.myCurrentBid.bid) {
        // check if this bid belongs to connected supplier
        if (
          SocketHandler.user &&
          response.data.myCurrentBid.createdBy &&
          String(SocketHandler.user._id) ===
            String(response.data.myCurrentBid.createdBy)
        ) {
          liveData["new"] = response.data.myCurrentBid;
        }
      } else {
        liveData["new"] = { bid: 0, rank: 0 };
      }
      SocketHandler.dispatcher(updateLiveData(liveData));
      SocketHandler.dispatcher(refreshLiveBiddingDataSupplier(true));
      SocketHandler.dispatcher(refreshLiveBiddingDataBuyer(true));
    } else {
      SocketHandler.dispatcher(
        updateLiveEventStatus({
          success: response.meta?.success,
          status: response.meta?.status,
          message: response.meta?.message,
        })
      );
    }
    SocketHandler.dispatcher(updateEventStack(SEND_NEW_BID_RESPONSE_EVENT));
  });
  return true;
};

export default SendNewBidResEvent;
