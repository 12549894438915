import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles, Box, Grid } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import * as employeeService from "../../../../services/employeeService";
import axiosInstance from "../../../../services/AxiosInstance";
import { useForm, Form } from "../../../../components/useForm";
import { store } from "../../../../store/store";
import Moment from "moment";
import "../../styles/filter-box.css";
import { Api } from "../../../../api/Api";
import {
  GET_BUYER_PROJECT_AUCTION_API,
  ONLY_COMAPNY_API,
} from "../../../../constants/ApiUrl";
import { AUCTION_ACTIVE, AUCTION_CANCELLED, AUCTION_DRAFT, AUCTION_PUBLISHED } from "../../../../constants/AppConstants";
const initialValues = {
  date_range: "",
  event_type: "",
  owner: "",
  status: "",
};

const projectType = [
  { id: "1", title: "English Reverse Auction" },
  // { id: "2", title: "type #2" },
  // { id: "3", title: "Type #3" },
];
export default function EventFilter(props) {
  const { setProjectAuctionList, setShowFilterCategories } = props;
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [allOptions, setAllOptions] = React.useState(
    employeeService.getDepartmentCollection()
  );
  const [dropdownOwner, setDropdownOwner] = React.useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const projectId = localStorage.getItem("selectedProjectId");
    let string = "";

    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      if (i == 0) {
        string += `?${key}=${value}`;
      } else {
        string += `&${key}=${value}`;
      }
      i++;
    }
    if (dateRange && dateRange.length !== 0) {
      values.dateRange = dateRange;
      if (string !== "") {
        if (dateRange && dateRange[0] !== null && dateRange[1] !== null) {
          string += `&startDate=${Moment(dateRange[0]).format(
            "YYYY-MM-DD 00:00:00"
          )}&endDate=${Moment(dateRange[1]).format("YYYY-MM-DD 00:00:00")}`;
        }
      }
    }
    localStorage.setItem("projectEventFilter", JSON.stringify(values));
    const response = await Api(
      `${GET_BUYER_PROJECT_AUCTION_API}/${projectId}${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let projectAuc = response.data.auction;
        setProjectAuctionList(projectAuc);
        setShowFilterCategories(false);
      } else {
        setShowFilterCategories(false);
        setProjectAuctionList([]);
      }
    } else {
      setShowFilterCategories(false);
      setProjectAuctionList([]);
    }

    // axiosInstance
    //   .get(`api/buyer/getProjectsAuctionList/${projectId}${string}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let projectAuc = response.data.auction;
    //       setProjectAuctionList(projectAuc);
    //       setShowFilterCategories(false);
    //     } else {
    //       setShowFilterCategories(false);
    //       setProjectAuctionList([]);
    //     }
    //   })
    //   .catch((err) => {
    //     setShowFilterCategories(false);
    //     setProjectAuctionList([]);
    //   });
  };

  const companyUserList = async () => {
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;
    const response = await Api(
      `${ONLY_COMAPNY_API}/${companyId}/companyUserList`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allMembers = response.data.companyTeamMembers;
        setDropdownOwner(
          allMembers.map((owner) => {
            return {
              name: owner?.first_name + " " + owner?.last_name,
              _id: owner._id,
            };
          })
        );
      }
    } else {
      setDropdownOwner([]);
    }

    // await axiosInstance
    //   .get(`/api/company/${companyId}/companyUserList`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allMembers = response.data.companyTeamMembers;
    //       setDropdownOwner(
    //         allMembers.map((owner) => {
    //           return {
    //             name: owner?.first_name + " " + owner?.last_name,
    //             _id: owner._id,
    //           };
    //         })
    //       );
    //     }
    //   })
    //   .catch((err) => {
    //     setDropdownOwner([]);
    //   });
  };

  const filterClear = async () => {
    const projectId = localStorage.getItem("selectedProjectId");
    resetForm();
    const response = await Api(
      `${GET_BUYER_PROJECT_AUCTION_API}/${projectId}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let projectAuc = response.data.auction;
        setProjectAuctionList(projectAuc);
        setShowFilterCategories(false);
        localStorage.removeItem("projectEventFilter");
      }
    }

    // axiosInstance
    //   .get(`api/buyer/getProjectsAuctionList/${projectId}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let projectAuc = response.data.auction;
    //       setProjectAuctionList(projectAuc);
    //       setShowFilterCategories(false);
    //       localStorage.removeItem("projectEventFilter");
    //     }
    //   })
    //   .catch((err) => {});
  };

  useEffect(() => {
    companyUserList();
    const filterData = localStorage.getItem("projectEventFilter");

    if (filterData) {
      const filterObj = JSON.parse(filterData);
      setValues(filterObj);
      if (filterObj && filterObj.dateRange) {
        setDateRange(filterObj.dateRange);
      }
    }
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      // id="auction-list-filter-box-supplier-aspect"
    >
      <Grid container spacing={2} className="filter-field-container">
        <Grid item lg={3} sm={4} xs={12} className="select-field">
          <InputLabel id="demo-simple-select-autowidth-label">
            Start Date - End Date
          </InputLabel>
          <Controls.DateRangePicker
            startProps={{
              name: "date_range",
            }}
            lable="Start Date - End Date"
            onChange={setDateRange}
            value={dateRange}
            className="w-100 ml-0 b-600"
          />
        </Grid>
        <Grid item lg={3} sm={4} xs={12} className="select-field">
          <InputLabel id="demo-simple-select-autowidth-label">
            EventType
          </InputLabel>
          <Controls.Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            name="event_type"
            value={values.event_type}
            onChange={handleInputChange}
            options={projectType}
            autoWidth
            size="small"
            className="w-100 ml-0 b-600"
          />
        </Grid>
        <Grid item lg={3} sm={4} xs={12} className="select-field">
          <Box p={0}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Owner
            </InputLabel>
            <Controls.Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              name="owner"
              value={values.owner}
              onChange={handleInputChange}
              options={dropdownOwner}
              autoWidth
              size="small"
              className="w-100 ml-0 b-600"
            />
          </Box>
        </Grid>
        <Grid item lg={3} sm={4} xs={12} className="select-field">
          <Box p={0}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Status
            </InputLabel>
            <Controls.Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              name="status"
              value={values.status}
              onChange={handleInputChange}
              options={[
                { name: AUCTION_ACTIVE, _id: AUCTION_ACTIVE },
                { name: AUCTION_PUBLISHED, _id: AUCTION_PUBLISHED },
                { name: AUCTION_DRAFT, _id: AUCTION_DRAFT },
                { name: AUCTION_CANCELLED, _id: AUCTION_CANCELLED },
              ]}
              autoWidth
              size="small"
              className="w-100 ml-0 b-600"
            />
          </Box>
        </Grid>
        <Grid item lg={2} sm={4} xs={12} className="select-field">
          <Controls.Button
            text="Clear"
            disableElevation
            color="default"
            className="btn-mui"
            onClick={() => filterClear()}
            style={{ width: "100%", marginBottom: "10px" }}
          />
        </Grid>
        <Grid item lg={2} sm={4} xs={12} className="select-field">
          <Controls.Button
            type="submit"
            disableElevation
            text="Apply Filter"
            className="btn-mui"
            style={{ width: "100%", marginBottom: "10px" }}
            m={0}
          />
        </Grid>
      </Grid>
    </Form>
  );
}
