import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

export default function RadioGroup(props) {
  const { name, label, value, onChange, items, isRowDir = true } = props;

  return (
    <FormControl className="ratio-btn-container">
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup
        row={isRowDir}
        className="ratio-btn"
        name={name}
        value={value}
        onChange={onChange}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            control={<Radio />}
            label={item.title}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
