import { Box, Grid, InputAdornment, Paper, TextField } from "@material-ui/core";
import {
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Modal,
} from "@mui/material";
import LogoIcon from "../../../../assets/Logo_2.png";
import { Search } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import "../../../Auction/styles/details-suplliers.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddSupplierPopup from "../../../Auction/components/AddExistingSupplier";

import {
  AUCTION_ACCEPTED,
  AUCTION_DECLINED,
  AUCTION_DRAFT,
  AUCTION_PUBLISHED,
  DANGER_BACKGROUND,
  PURPLE_BACKGROUND,
  RFX_INVITE_STATUS,
  RFX_PROGRESS_MAP,
  SUCCESS_BACKGROUND,
  SUPPLIER_AUCTION_PENDING,
  TOAST_AUTO_CLOSE,
  WARNING_BACKGROUND,
} from "../../../../constants/AppConstants";
import { toast, Slide } from "react-toastify";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import usePrevious from "../../../../components/usePrevious";
import { store } from "../../../../store/store";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { setHTTPError } from "../../../../store/actions/errorHandlerAction";
import {
  COMAPNY_API,
  GET_COMPANY_SUPPLIER_LIST_API,
  RFX_API,
} from "../../../../constants/ApiUrl";
import TableOptionList from "../../../../components/TableOptionList";
import TablePaginationBox from "../../../../components/TablePaginationBox";
import Notification from "../../../../components/Notification";
import InvitedSellerPopup from "../../../Auction/components/InvitedSellerPopup";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import GetAllSupplierListRfx from "../RfxProcessFunction/GetAllSupplierListRfx";
import SendReminderSupplierRfx from "../RfxProcessFunction/SendReminderSupplierRfx";
import { Api } from "../../../../api/Api";
import { AUCTION_ACTIVE } from "../../../../constants/AppConstants";
import Controls from "../../../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import RfxFormStep6 from "../RfxFormStep/RfxFormStep6";
import { isGlobalRfxShow } from "../../../../utility/utils";

const columns = [
  {
    label: "Rank",
  },
  {
    label: "Supplier Name",
  },
  {
    label: "Email Main Contact",
  },
  {
    label: "Status",
  },
  {
    label: "Open Message",
  },
  {
    label: "Questions Answered",
  },
  {
    label: "Items priced",
  },
  {
    label: "Invited Sellers",
  },
  {
    label: "",
  },
];

const RfxSupplierTab = (props) => {
  const { isEditor, isOwner } = props;
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const [suppliers, setSuppliers] = React.useState([]);
  const [limit, setLimit] = React.useState(0);
  const history = useHistory();
  const UnAuthorizedRoute = useSelector(
    (state) => state.errorHandler.UnAuthorizedRoute
  );

  const [openSellerPopup, setOpenSellerPopup] = React.useState(false);
  const [selectedSupplier, setSelectedSupplier] = React.useState([]);
  const tableRowsRef = useRef([]);
  const { smScreen } = useWindowDimensions();
  const [page, setPage] = React.useState(1);
  const prevPageValue = usePrevious(page);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [resendApi, setResendApi] = React.useState(false);
  const [numOfPages, setNumOfPages] = React.useState(0);
  const [invitedSupplierLabel, setInvitedSupplierLabel] = React.useState("All");
  const [openPopup, setOpenPopup] = React.useState(false);
  const [deleteSupplierId, setDeleteSupplierId] = React.useState({});
  const [addSupplierModal, setAddSupplierModal] = useState(false);
  const dispatch = useDispatch();
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (resendApi === true) {
      GetAllSupplierListRfx({
        currentRfx: rfxDetails,
        page: page,
        rowsPerPage: rowsPerPage,
      }).then((data) => {
        setSuppliers(data.list);
        setLimit(data.limit);
      });
      setResendApi(false);
    }
  }, [resendApi]);

  const resetPaginationSetting = () => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  };

  useEffect(() => {
    resetPaginationSetting();
  }, [limit, suppliers]);

  useEffect(() => {
    if (prevPageValue !== undefined) {
      setResendApi(true);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (rfxDetails && rfxDetails._id) {
      tableRowsRef.current = tableRowsRef.current.slice(0, suppliers.length);
      setResendApi(true);
    }
  }, [rfxDetails]);

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ACCEPTED:
        return SUCCESS_BACKGROUND;
      case SUPPLIER_AUCTION_PENDING:
        return WARNING_BACKGROUND;
      case AUCTION_DECLINED:
        return DANGER_BACKGROUND;
      default:
        return PURPLE_BACKGROUND;
    }
  };

  const getSellerLabel = (supplier) => {
    let tmpLable = "";
    if (supplier && supplier.invitedSellerList) {
      if (Array.isArray(supplier.invitedSellerList)) {
        tmpLable = supplier.invitedSellerList.length;
      } else {
        tmpLable = "--";
      }
    }

    return (
      <div
        className="sellers-link-container"
        onClick={() => {
          setOpenSellerPopup(true);
          setSelectedSupplier({
            ...supplier,
            supplierCompanyId: supplier?.supplierCompanyId?._id,
          });
        }}
      >
        <div className="label">{tmpLable || invitedSupplierLabel}</div>
        <PlaylistAddIcon className="icon" />
      </div>
    );
  };

  const SendReminder = (row) => {
    if (rfxDetails?.status === AUCTION_ACTIVE) {
      // if (row?.rfxStatus === RFX_INVITE_STATUS) {
      const { auth } = store.getState();
      const companyName = auth?.auth?.user?.company?.company_name;
      SendReminderSupplierRfx({
        company_buyer_name: companyName,
        supplier_company: row?.company_name?._id,
        rfxId: rfxDetails?._id,
        email: row?.email,
        supplierId: row?.supplierCompanyId?._id,
      }).then((data) => {});
      // }
    } else {
      toast.error(ValidationMessage.rfxRunningEnded, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const getSearchString = async (e) => {
    const searchText = e.target.value;
    let queryString = "";
    if (searchText) {
      queryString = `&search=${searchText}`;
    }
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const rfxId = rfxDetails?._id;
    if (companyId) {
      const response = await Api(
        `${RFX_API}/${rfxId}/supplier/response/list?per_page=${rowsPerPage}&page=${page}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let suuplierItemList = response?.data?.supplierList;
          setSuppliers(suuplierItemList);
          setLimit(response.data.totalRecord);
        } else {
          setSuppliers([]);
          setLimit(0);
        }
      } else {
        setSuppliers([]);
        setLimit(0);
      }
    }
  };

  const RemoveSupplier = (supplier) => {
    if (rfxDetails?.status === AUCTION_DRAFT) {
      if (supplier?.supplierCompanyId?._id) {
        setDeleteSupplierId(supplier);
        setConfirmDialog({
          isOpen: true,
          title: "SUPPLIER",
          subTitle: "Are you sure to delete supplier?",
        });
      }
    } else {
      toast.error(ValidationMessage.auctionRemoveSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const confirmDeleteSupplier = async () => {
    if (rfxDetails && rfxDetails?._id) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const payload = {
        supplierEmail: deleteSupplierId?.supplierCompanyId?.email,
      };
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      const response = await Api(
        `${COMAPNY_API}/${companyId}/removeRfxSupplier/${rfxDetails?._id}`,
        payload,
        "post"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setResendApi(true);
        }
      } else {
        let message = response?.data?.responseDescription;
        if (response.status === 403) {
          dispatch(
            setHTTPError({
              code: 403,
              message: message,
            })
          );
          history.push(UnAuthorizedRoute);
        } else {
          dispatch(
            setHTTPError({
              code: response.status,
              message: message,
            })
          );
        }
      }
    }
  };

  return (
    <Paper elevation={0} square>
      <Grid container>
        <Grid
          item
          md={6}
          xs={12}
          style={{ textAlign: smScreen ? "center" : "start" }}
        >
          <TextField
            onChange={getSearchString}
            placeholder="Search Using Keyword"
            className="select-box select-box-primary search-box"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
          style={{
            textAlign: smScreen ? "center" : "end",
            marginTop: smScreen ? "10px" : "0px",
          }}
        >
          {/* <Select
            value={rowsPerPage}
            onChange={(event) => setRowsPerPage(event.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            className="items-count-dropdown"
          >
            <MenuItem value="">
              <em>Show 10 entries</em>
            </MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select> */}
          {rfxDetails?.status === AUCTION_DRAFT ? (
            <Controls.Button
              text="Add Supplier"
              className="btn btn-primary btn-mui"
              variant="contained"
              startIcon={<AddIcon />}
              style={{
                textTransform: "capitalize",
                border: "1px #2170ff solid",
                padding: "0.6em 1.4em",
                borderRadius: "10px",
                marginLeft: "12px",
                height: "48px",
              }}
              onClick={() => setAddSupplierModal(true)}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <Paper elevation={0} square>
        <TableContainer
          className="table-container editable-table"
          component={Paper}
        >
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers.map((supplier, index) => {
                return (
                  <TableRow
                    key={supplier._id}
                    ref={(el) => (tableRowsRef.current[index] = el)}
                    className="editable-row"
                  >
                    {/* Number.MAX_SAFE_INTEGER => means infinite  */}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {supplier?.supplierCompanyId?.company_name}
                    </TableCell>
                    <TableCell className="clr-2170ff">
                      {supplier?.supplierCompanyId?.email}
                    </TableCell>
                    <TableCell>
                      <Button
                        className={
                          "badge badge-" +
                          getStatusLabel(supplier?.auctionStatus?.invitation) +
                          " status"
                        }
                        variant="contained"
                      >
                        {RFX_PROGRESS_MAP.get(supplier?.rfxStatus)}
                      </Button>
                    </TableCell>
                    <TableCell
                      className={`${
                        supplier?.openMessages > 0 ? "text-warning" : ""
                      }`}
                    >
                      {supplier?.openMessages}
                    </TableCell>
                    <TableCell>
                      {`${
                        isGlobalRfxShow(
                          supplier?.isSubmitted,
                          rfxDetails?.status,
                          supplier?.rfxStatus,
                          rfxDetails?.sealed_bid
                        )
                          ? `${supplier?.answeredQuestions}/
                      ${rfxDetails?.questionsCounts}`
                          : "--"
                      } `}
                    </TableCell>
                    <TableCell>
                      {`${
                        isGlobalRfxShow(
                          supplier?.isSubmitted,
                          rfxDetails?.status,
                          supplier?.rfxStatus,
                          rfxDetails?.sealed_bid
                        )
                          ? `${supplier?.itemsPriced}/
                      ${rfxDetails?.itemsCounts}`
                          : "--"
                      } `}
                    </TableCell>

                    <TableCell>{getSellerLabel(supplier)}</TableCell>
                    {(rfxDetails?.status === AUCTION_DRAFT ||
                      rfxDetails?.status === AUCTION_ACTIVE) && (
                      <TableCell>
                        <Box className="option-cell __active">
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState) => (
                              <div>
                                <Button
                                  className="action-btn"
                                  variant="contained"
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreVertIcon />
                                </Button>
                                <TableOptionList {...bindMenu(popupState)}>
                                  <div>
                                    <MenuItem
                                      className="bold-line-600"
                                      onClick={() => {
                                        popupState.close();
                                        SendReminder(supplier);
                                      }}
                                    >
                                      <div>Send Reminder</div>
                                    </MenuItem>
                                  </div>
                                  {rfxDetails?.status === AUCTION_DRAFT ? (
                                    <>
                                      <Divider />
                                      <MenuItem
                                        className="bold-line-600 clr-ff0000"
                                        onClick={() => {
                                          popupState.close();
                                          RemoveSupplier(supplier);
                                        }}
                                      >
                                        <div>Remove</div>
                                      </MenuItem>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </TableOptionList>
                              </div>
                            )}
                          </PopupState>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {suppliers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some supplier
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={suppliers.length + " Suppliers Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>
      <Modal
        open={openSellerPopup}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container invite-seller-box">
          <div className="title"> Edit Sellers Invited </div>
          <div className="label">Company Name</div>
          <div className="title">
            <div className="icon-cotainer">
              <img src={LogoIcon} className="icon" alt="" />
            </div>
            <div className="text">{selectedSupplier?.supplierCompanyName}</div>
          </div>
          <InvitedSellerPopup
            openPopup={openSellerPopup}
            setOpenPopup={setOpenSellerPopup}
            supplier={selectedSupplier}
            suppliers={suppliers}
            setSuppliers={setSuppliers}
            setNotify={setNotify}
            setInvitedSupplierLabel={setInvitedSupplierLabel}
            setResendApi={setResendApi}
            rfxDetails={rfxDetails}
          />
        </Box>
      </Modal>

      <AddSupplierPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setNotify={setNotify}
        setResendApi={setResendApi}
        rfxDetails={rfxDetails}
      />

      <RfxFormStep6
        openPopupStep6={addSupplierModal}
        setOpenPopupStep6={setAddSupplierModal}
        setResendApi={setResendApi}
        fromEditTab={true}
        rfxDetails={rfxDetails}
        // fullLoder={fullLoder}
        // setFullLoder={setFullLoder}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmDeleteProject={confirmDeleteSupplier}
      />
    </Paper>
  );
};

export default RfxSupplierTab;
