import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../styles/auction-confirmation-dailog.css";
import Controls from "../../../components/controls/Controls";
import { Box, Grid } from "@material-ui/core";
import { CLEAR_REDUX_ON_SAVED_SUCCESS } from "../../../store/constant/auctionConstant";
import { useDispatch } from "react-redux";
import { CLEAR_REDUX_ON_CLOSE_RFX } from "../../../store/constant/rfxConstant";

const ConrmationDailog = (props) => {
  const {
    fromRfx,
    openConfrmationDailog,
    setOpenConfrmationDailog,
    setDiscardAndClose,
    eraseEverything,
    allModalClose,
  } = props;
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenConfrmationDailog(false);
  };
  const discardAllChanges = () => {
    if (fromRfx) {
      allModalClose();
      dispatch({
        type: CLEAR_REDUX_ON_CLOSE_RFX,
        payload: {},
      });
      setDiscardAndClose(false);
      setOpenConfrmationDailog(false);
      return;
    }
    if (!eraseEverything) {
      setDiscardAndClose(false);
      setOpenConfrmationDailog(false);
    } else {
      dispatch({
        type: CLEAR_REDUX_ON_SAVED_SUCCESS,
        payload: {},
      });

      setDiscardAndClose(false);
      setOpenConfrmationDailog(false);
    }
  };
  return (
    <>
      <Dialog
        open={openConfrmationDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="auction-confirm-dailog-main-div">
          <DialogTitle
            id="alert-dialog-title"
            className="auction-confirm-dailog-title"
          >
            Are you sure you want to exit?
          </DialogTitle>
          <DialogContent className="auctio-dailog-content">
            <DialogContentText id="alert-dialog-description">
              Once You exit, your process cannot be recovered.
            </DialogContentText>
          </DialogContent>
          <Grid container className="mt-20">
            <Grid item container md={3} xs={3}></Grid>
            <Grid item container md={9} xs={9}>
              <Box>
                <Controls.Button
                  text="I'm sure"
                  className="btn btn-primary btn-mui color-red"
                  variant="contained"
                  onClick={discardAllChanges}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item container md={3} xs={3}></Grid>
            <Grid item container md={9} xs={9}>
              <Box>
                <Controls.Button
                  text="Cancel"
                  className="btn btn-primary btn-mui custom-color"
                  variant="contained"
                  onClick={handleClose}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default ConrmationDailog;
