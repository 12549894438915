import React, { useState } from "react";
import Search from "@material-ui/icons/Search";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import {
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_CANCELLED,
  AUCTION_DASH_ON_HOLD,
  AUCTION_ENDED_CAPITAL,
  AUCTION_PUBLISHED,
  AUCTION_RUNNING,
  SECONDARY_BACKGROUND,
  STATUS_INACTIVE,
  STATUS_OPEN,
  SUCCESS_BACKGROUND,
} from "../../../constants/AppConstants";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "100%",
    border: "0px solid #ccc",
    [`& fieldset`]: {
      borderRadius: 6,
    },
  },
  selectAction: {
    "& .MuiInputLabel-root": {
      display: "none",
    },
  },
  toolbars: {
    "& .MuiToolbar-gutters": {
      padding: "12px",
      margin: "0px",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },

  // Forms

  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    "& .MuiDialogTitle-root": {
      padding: "1px",
    },
  },
  dialogTitle: {
    paddingRight: "0px",
    fontWeight: "600px",
  },
  dialog: {
    "& .MuiDialogContent-dividers ": {
      borderTop: "0px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "0px solid rgba(0, 0, 0, 0.12)",
    },
  },

  sectionText: {
    fontWeight: "600",
    fontSize: "24px",
  },
  // Header

  root: {
    backgroundColor: "transparent",
  },
  wrapper: {
    borderRadius: "12px",
    padding: "0 12px",
    backgroundColor: "red",
  },
  pageHeader: {
    padding: theme.spacing(1),
    display: "flex",
    marginBottom: theme.spacing(0),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#2170ff",
  },
  pname: { color: theme.palette.primary.main, fontWeight: "bold" },
  pdesc: { opacity: "0.5" },

  pageTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  warning: { backgroundColor: theme.palette.primary.warning },
  actionbtnRight: {
    position: "relative",
    top: 10,
    right: 10,
  },
  menupos: {
    // position: "relative",
    // right: "-18px",
  },
  filterDiv: {
    width: "100%",
    padding: "1rem",
    margin: ".3rem",
  },
  searchBar: {
    width: "100%",
    margin: "1rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  sort: {
    display: "flex",
  },
}));

const columns = [
  {
    label: "ID",
    align: "center",
    // minWidth: 170
  },
  {
    label: "Subject",
    // minWidth: 100
  },
  {
    label: "Supplier",
    // align: 'right'
  },
  {
    label: "Event",
  },
  {
    label: "Created on",
  },
  {
    label: "Type",
  },
  {
    label: "Status",
  },
  {
    label: "Owner",
  },
  {
    label: "",
  },
];

const itemListDemo = [
  {
    id: "M23762",
    subject: "Message Title",
    supplier: "Demo Supplier",
    event: "Demo RFQ",
    created_on: "02-Apr-23 00:00",
    type: "Clarification",
    status: "Open",
    owner: "Joss Sticks",
  },
  {
    id: "M23762",
    subject: "Message Title",
    supplier: "Demo Supplier",
    event: "Demo RFQ",
    created_on: "02-Apr-23 00:00",
    type: "Clarification",
    status: "Open",
    owner: "Joss Sticks",
  },
];

const ProjectMessage = () => {
  const classes = useStyles();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [itemList, setItemList] = useState([]);
  /// Please be carefull !!!
  /// must start with (1) for pagination issue ....
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // minior chnage to get page without increment by one
  const emptyRows =
    page > 0 ? Math.max(0, page * rowsPerPage - itemList.length) : 0;
  const numOfPages = Math.ceil(itemList.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case STATUS_OPEN:
        return SUCCESS_BACKGROUND;
      case STATUS_INACTIVE:
        return SECONDARY_BACKGROUND;
      default:
        return SECONDARY_BACKGROUND;
    }
  };

  return (
    <>
      <Paper elevation={0} square>
        <Grid item xs={12}>
          <Box>
            <TextField
              placeholder="Search Using Keyword or Tags..."
              className="select-box select-box-primary search-box"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* minior change - start offestting using (page-1) 
               min value of page is one */}
              {(rowsPerPage > 0
                ? itemList.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : itemList
              ).map((item) => (
                <TableRow key={item.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "center" }}
                  >
                    {item.id}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#2170FF" }}
                  >
                    {item.subject}
                  </TableCell>
                  <TableCell style={{ color: "#2170FF" }}>
                    {item.supplier}
                  </TableCell>
                  <TableCell>{item.event}</TableCell>
                  <TableCell>{item.created_on}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <div>
                          <Button
                            className={
                              "badge badge-" +
                              getStatusColor(item.status) +
                              " status"
                            }
                            variant="contained"
                            {...bindTrigger(popupState)}
                            endIcon={<ArrowDropDownIcon />}
                          >
                            {item.status}
                          </Button>
                          <Menu
                            {...bindMenu(popupState)}
                            PaperProps={{
                              sx: {
                                padding: "3px",
                              },
                            }}
                          >
                            <div>
                              <button className="badge badge-success select-colored-option">
                                {AUCTION_PUBLISHED}
                              </button>
                            </div>
                            <div>
                              <button className="badge badge-primary select-colored-option">
                                {AUCTION_RUNNING}
                              </button>
                            </div>
                            <div>
                              <button className="badge badge-warning select-colored-option">
                                {AUCTION_DASH_ON_HOLD}
                              </button>
                            </div>
                            <div>
                              <button className="badge badge-danger-outline select-colored-option">
                                {AUCTION_ENDED_CAPITAL}
                              </button>
                            </div>
                            <div>
                              <button className="badge badge-danger select-colored-option">
                                {AUCTION_CANCELLED}
                              </button>
                            </div>
                            <div>
                              <button className="badge badge-purple select-colored-option">
                                {AUCTION_ARCHIEVED_CAPITAL}
                              </button>
                            </div>
                          </Menu>
                        </div>
                      )}
                    </PopupState>
                  </TableCell>
                  <TableCell>{item.owner}</TableCell>
                  <TableCell>
                    <Box>
                      <PopupState variant="popover">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem className="bold-line-600">
                                Edit Event
                              </MenuItem>
                              <MenuItem className="bold-line-600">
                                Duplicate
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {itemList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some message
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={itemList.length + " Messages Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default ProjectMessage;
