/*eslint-disable */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../../components/Notification";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import GetAllUsersBL from "./BL/GetAllUsersBL";
import usePrevious from "../../../components/usePrevious";
import ToggleUserStatusBL from "./BL/ToggleUserStatusBL";
import MakeUserAsBuyerBL from "./BL/MakeUserAsBuyerBL";
import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";
import moment from "moment";
import { GET_ADMIN_USER_API } from "../../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_ACTIVE_SMALL,
  DANGER_OUTLINE_BACKGROUND,
  STATUS_INACTIVE,
  SUCCESS_BACKGROUND,
  SUPPLIER_AUCTION_PENDING,
  SUPPLIER_AUCTION_PENDING_CAPITAL,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../constants/AppConstants";
import { Api } from "../../../api/Api";

const columns = [
  {
    label: "Company Name",
  },
  {
    label: "Email",
  },
  {
    label: "Name",
    minWidth: 170,
  },
  {
    label: "Is Admin",
  },
  {
    label: "Role",
  },
  {
    label: "Status",
    minWidth: 170,
  },
  {
    label: "Registration date",
    minWidth: 170,
  },
  {
    label: "Start date buyer role",
    minWidth: 170,
  },
  {
    label: "Last login",
    minWidth: 170,
  },
  {
    label: "",
  },
];

const CompanyTable = (props) => {
  const {
    currentUser,
    rowsPerPage,
    setFullLoder,
    searchKey,
    filterValues,
    resendApi,
    setResendApi,
  } = props;

  /// must start with (1) for pagination issue ....
  const [page, setPage] = useState(1);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [numOfPages, setNumOfPages] = useState(0);
  const [list, setList] = useState([]);
  const [limit, setLimit] = useState(10);
  const prevPageValue = usePrevious(page);

  const resetPaginationSetting = () => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  };

  useEffect(() => {
    if (resendApi == true) {
      setFullLoder(true);
      GetAllUsersBL({
        currentUser: currentUser,
        searchKey: searchKey,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        filterValues: filterValues,
      }).then((data) => {
        setList(data.list);
        setLimit(data.limit);
        setFullLoder(false);
        setResendApi(false);
      });
    }
  }, [resendApi]);

  useEffect(() => {
    resetPaginationSetting();
  }, [limit, list]);

  useEffect(() => {
    if (prevPageValue !== undefined) {
      setResendApi(true);
    }
  }, [page]);

  const getIsAdminLabel = (type) => {
    switch (type) {
      case true:
        return SUCCESS_BACKGROUND;
      case false:
        return DANGER_OUTLINE_BACKGROUND;
    }
  };

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ACTIVE || AUCTION_ACTIVE_SMALL:
        return SUCCESS_BACKGROUND;
      case SUPPLIER_AUCTION_PENDING_CAPITAL || SUPPLIER_AUCTION_PENDING:
        return WARNING_OUTLINE_BACKGROUND;
      case STATUS_INACTIVE || STATUS_INACTIVE:
        return DANGER_OUTLINE_BACKGROUND;
    }
  };

  const toggleStatus = async (user) => {
    setFullLoder(true);
    ToggleUserStatusBL({
      user: user,
    }).then((data) => {
      if (data.success) {
        setResendApi(true);
      } else {
        setFullLoder(false);
      }
    });
  };

  const makeItRole = async (user, role) => {
    setFullLoder(true);
    MakeUserAsBuyerBL({
      user: user,
      role,
    }).then((data) => {
      if (data.success) {
        setResendApi(true);
      } else {
        setFullLoder(false);
      }
    });
  };

  const removeRoleHandler = async (user, role) => {
    const path = `${GET_ADMIN_USER_API}/${user._id}/role/remove`;
    const response = await Api(path, { role }, "patch");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setResendApi(true);
      }
    }

    // const res = await axiosInstance.patch(path, { role });
    // if (res.data.responseCode === "00") {
    //   setNotify({
    //     isOpen: true,
    //     message: res.data.responseDescription,
    //     type: "success",
    //   });
    //   setResendApi(true);
    // } else {
    //   setNotify({
    //     isOpen: true,
    //     message: res.data.responseDescription,
    //     type: "error",
    //   });
    // }
  };
  return (
    <>
      <Paper elevation={0} square>
        <TableContainer
          className="table-container supplier-aspect"
          component={Paper}
          aria-label="list-all-user-table-super-admin-aspect"
        >
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user?.company_name?.company_name}</TableCell>
                  <TableCell> {user?.email} </TableCell>
                  <TableCell>
                    {user?.first_name + " " + user?.last_name}
                  </TableCell>
                  <TableCell>
                    <Button
                      className={
                        "badge badge-" +
                        getIsAdminLabel(user?.isAdmin) +
                        " status"
                      }
                      variant="contained"
                    >
                      {user?.isAdmin == true ? "Yes" : "No"}
                    </Button>
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {user.role
                      ? user.role === "both"
                        ? "Buyer & Seller"
                        : user?.role
                      : "--"}
                  </TableCell>
                  <TableCell>
                    <Button
                      className={
                        "badge badge-" +
                        getStatusLabel(user?.status) +
                        " status"
                      }
                      variant="contained"
                    >
                      {user?.status}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {user?.createdDate &&
                      moment(user?.createdDate).format("DD/MM/YYYY LT")}
                  </TableCell>
                  <TableCell>
                    {user?.buyerRoleStartDate &&
                      moment(user?.buyerRoleStartDate).format("DD/MM/YYYY LT")}
                  </TableCell>
                  <TableCell>
                    {user?.lastLogin &&
                      user?.lastLogin &&
                      moment(user?.lastLogin).format("DD/MM/YYYY LT")}
                  </TableCell>
                  <TableCell>
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              {!user.isBuyer ? (
                                <MenuItem
                                  className="bold-line-600"
                                  onClick={() => makeItRole(user, "buyer")}
                                >
                                  Make it Buyer
                                </MenuItem>
                              ) : (
                                <></>
                              )}
                              <MenuItem
                                className="bold-line-600"
                                onClick={() => toggleStatus(user)}
                              >
                                {user.status == "Inactive"
                                  ? "Active"
                                  : "Deactive"}
                              </MenuItem>

                              {user.isBuyer && (
                                <MenuItem
                                  className="bold-line-600"
                                  onClick={() =>
                                    removeRoleHandler(user, "buyer")
                                  }
                                >
                                  Remove As Buyer
                                </MenuItem>
                              )}
                              {!user.isSeller && (
                                <MenuItem
                                  className="bold-line-600"
                                  onClick={() => makeItRole(user, "seller")}
                                >
                                  Make it Seller
                                </MenuItem>
                              )}
                              {user.isSeller && (
                                <MenuItem
                                  className="bold-line-600"
                                  onClick={() =>
                                    removeRoleHandler(user, "seller")
                                  }
                                >
                                  Remove As Seller
                                </MenuItem>
                              )}
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {list.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some user
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={limit + " Users Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(CompanyTable));
