import { Box, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePaginationBox from "../../../../../components/TablePaginationBox";
import useWindowDimensions from "../../../../../components/useWindowDimensions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCurrentAuction } from "../../../../../store/selectors/supplierAspect/auctionSelector";
import GetAllAuctionItemsBL from "../../BL/GetAllAuctionItemsBL";
import defItemImg from "../../../../../assets/Items@2x.png";
import ItemsAllModal from "./ItemsAllModal";
import usePrevious from "../../../../../components/usePrevious";
import "../../styles/details-item.css";
import dummyItem from "../../../../../assets/settings/itemCoverDummy.png";

const columns = [
  {
    label: "ID",
  },
  {
    label: "Image",
  },
  {
    label: "Name",
    minWidth: 100,
  },
  {
    label: "Manufacturer",
  },
  {
    label: "Model",
  },
  {
    label: "Unit",
  },
  {
    label: "Quantity",
  },
];

const Items = (props) => {
  const { currentAuction } = props;
  const [page, setPage] = useState(1);
  const prevPageValue = usePrevious(page);
  const [itemList, setItemList] = useState([]);
  const [limit, setLimit] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [numOfPages, setNumOfPages] = useState(0);
  const [resendApi, setResendApi] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const resetPaginationSetting = () => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  };

  useEffect(() => {
    if (resendApi == true) {
      GetAllAuctionItemsBL({
        currentAuction: currentAuction,
        page: page,
        rowsPerPage: rowsPerPage,
      }).then((data) => {
        setItemList(data.list);
        setLimit(data.limit);
        setResendApi(false);
      });
    }
  }, [resendApi]);

  useEffect(() => {
    resetPaginationSetting();
  }, [limit, itemList]);

  useEffect(() => {
    if (prevPageValue !== undefined) {
      setResendApi(true);
    }
  }, [page]);

  useEffect(() => {
    if (currentAuction._id !== undefined) {
      setResendApi(true);
    }
  }, [currentAuction]);

  return (
    <>
      <div className="mui-card supp-auction-details-items-list-container">
        <Grid container>
          <Grid
            item
            sm={6}
            xs={12}
            style={{ textAlign: smScreen ? "center" : "start" }}
          >
            <div className="header-title">Items</div>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            style={{
              textAlign: smScreen ? "center" : "end",
              marginTop: smScreen ? "10px" : "0px",
            }}
          >
            <div className="header-button">
              <div
                className="btn"
                onClick={() => {
                  setOpenPopup(true);
                }}
              >
                View All
              </div>
            </div>
          </Grid>
        </Grid>

        <Paper elevation={0} square>
          <TableContainer className="table-container" component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "700" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {itemList.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: "center" }}
                    >
                      {item.itemId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.image_upload ? (
                        <img
                          src={item?.image_upload}
                          style={{
                            width: "2.7em",
                            height: "2.7em",
                            objectFit: "cover",
                          }}
                          className="table-item-img"
                          alt=""
                        />
                      ) : (
                        <img
                          src={dummyItem}
                          className="table-item-img"
                          style={{
                            // backgroundColor: "var(---2170ff-p-)",
                            width: "2.7em",
                            height: "2.7em",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      )}
                    </TableCell>
                    <TableCell style={{ color: "#2170FF" }}>
                      {item.item_name}
                    </TableCell>
                    {/* <TableCell>{item.category}</TableCell> */}
                    <TableCell>{item.manufacturer}</TableCell>
                    <TableCell>{item.model}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                  </TableRow>
                ))}
                {itemList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="text-center">
                      No records found <br />
                      {/* <i style={{ color: "var(---2170ff-p-)" }}>
                        Please add some item
                      </i> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
            <TablePaginationBox
              label={limit + " Items Found"}
              numOfPages={numOfPages}
              onChangePage={(event, newPage) => setPage(newPage)}
              page={page}
            />
          </TableContainer>
        </Paper>
      </div>
      <ItemsAllModal openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuction: getCurrentAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(Items));
