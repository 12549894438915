/*eslint-disable */
import { Api } from "../../../../api/Api";
import { GET_SELLER_AUCTION_API } from "../../../../constants/ApiUrl";

const GetAllAuctionBL = async (props) => {
  const { currentUser, page, rowsPerPage, searchKey, filterValues } = props;
  let list = [];
  let limit = 0;

  let path = `${GET_SELLER_AUCTION_API}/${currentUser.company._id}?per_page=${rowsPerPage}&page=${page}`;

  if (searchKey !== "") {
    path += `&searchKey=${searchKey}`;
  }

  if (filterValues.status != "-1") {
    path += `&status=${filterValues.status}`;
  }

  if (filterValues.client != "-1") {
    path += `&client=${filterValues.client}`;
  }

  if (filterValues.manager != "") {
    path += `&manager=${filterValues.manager}`;
  }

  if (filterValues.type != "-1") {
    path += `&type=${filterValues.type}`;
  }

  // if (filterValues.status != "-1") {
  //   path += `&status=${filterValues.status}`;
  // }

  // const response = await axiosInstance.get(path);
  const response = await Api(path, {}, "get");

  if (response.status === 200) {
    if (response.data && response.data.auction !== undefined) {
      list = response.data.auction;
      for (let index = 0; index < list.length; index++) {
        let item = list[index];
        if (
          item.auctionId &&
          item.auctionId.startDate &&
          item.auctionId.endDate
        ) {
          let startDate = new Date(item.auctionId.startDate);
          let endDate = new Date(item.auctionId.endDate);
          let diffHours = ((endDate - startDate) / 1000 / 3600).toFixed(1);

          list[index]["dueTime"] =
            startDate.toLocaleDateString() +
            " " +
            (diffHours > 1
              ? diffHours + "h"
              : Math.round(diffHours * 60) + "m");
        }
      }

      limit = response.data.totalRecord;
    }
  }

  return {
    list: list,
    limit: limit,
  };
};

export default GetAllAuctionBL;
