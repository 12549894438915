import { Paper, Grid, Box, Tooltip } from "@material-ui/core";
import Button from "@mui/material/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { withRouter } from "react-router-dom";
import Notification from "../../../../components/Notification";
import { store } from "../../../../store/store";
import userImage from "../../../../assets/auth/user_icon_2.png";

import "../../../Auction/styles/details-banner.css";
import { currentUser } from "../../../../store/selectors/AuthSelectors";
import {
  COMAPNY_API,
  POST_RFX_STATUS_CHANGE_API,
} from "../../../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_CANCELLED,
  AUCTION_DRAFT,
  AUCTION_ENDED_CAPITAL,
  AUCTION_ONHOLD,
  AUCTION_PUBLISHED,
  AUCTION_RUNNING,
  DANGER_BACKGROUND,
  PURPLE_BACKGROUND,
  RFI_TYPE,
  RFP_TYPE,
  RFQ_TYPE,
  RFX_TYPE_MAP,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  TOAST_AUTO_CLOSE,
  WARNING_BACKGROUND,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../../constants/AppConstants";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";
import { Api } from "../../../../api/Api";
import CommonButton from "../../../../components/controls/CommonButton";

import { HtmlUtilService } from "../../../../services/HtmlUtilService";
import Controls from "../../../../components/controls/Controls";
import AuctionBannerShimmer from "../../../Auction/components/shimmer/AuctionBannerShimmer";
import { Form, useForm } from "../../../../components/useForm";
import DuplicateAuctionDialog from "../../../Auction/components/DuplicateAuctionDialog";
import rfpCover from "../../../../assets/Rfx/RfpCover.png";
import rfiCover from "../../../../assets/Rfx/RfiCover.png";
import rfqCover from "../../../../assets/Rfx/RfqCover.png";
import rfxCover from "../../../../assets/Rfx/RfxCover.png";
import { Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isValidDate } from "../../../../utility/utils";
import moment from "moment";

const getStatusLabel = (type) => {
  switch (type) {
    case AUCTION_ENDED_CAPITAL:
      return WARNING_OUTLINE_BACKGROUND;
    case AUCTION_RUNNING:
      return SUCCESS_BACKGROUND;
    case AUCTION_PUBLISHED:
      return SUCCESS_BACKGROUND;
    case AUCTION_DRAFT:
      return SECONDARY_BACKGROUND;
    case AUCTION_ONHOLD:
      return WARNING_BACKGROUND;
    case AUCTION_CANCELLED:
      return DANGER_BACKGROUND;
    case AUCTION_ARCHIEVED_CAPITAL:
      return PURPLE_BACKGROUND;
    default:
      return PURPLE_BACKGROUND;
  }
};

const RfxBanner = (props) => {
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const dispatch = useDispatch();
  const history = useHistory();
  const UnAuthorizedRoute = useSelector(
    (state) => state.errorHandler.UnAuthorizedRoute
  );
  const { currentUser, getLiveRfxDetails } = props;

  const [editMode, setEditMode] = useState(false);
  const [duplicateAuctionModal, setDuplicateAuctionModal] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(null);

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [saveLoader, setSaveLoader] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const initialFValues = {
    name: rfxDetails?.rfx_name,
    description: rfxDetails?.description,
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const clickToggleBtn = (event) => {
    setEditMode(!editMode);
    const descriptionView = document.querySelector("#description__view");
    const descriptionEdit = document.querySelector("#description__edit");

    if (editMode === true) {
      descriptionView.style.display = "block";
      descriptionEdit.style.display = "none";
    } else {
      descriptionView.style.display = "none";
      descriptionEdit.style.display = "block";
    }
  };

  const GoBackToRfxList = () => {
    history.push("/rfx");
  };

  const isOwner = () => {
    if (rfxDetails && currentUser) {
      return String(rfxDetails?.createdBy?._id) === String(currentUser._id);
    }
    return false;
  };

  const handleSubmit = async (e) => {
    let todayDate = moment().format("MM-DD-YYYY");
    let deliveryDateFormatted = moment(deliveryDate).format("MM-DD-YYYY");
    e.preventDefault();
    if (!editMode && isOwner()) {
      if (validate()) {
        const { auth } = store.getState();
        const companyId = auth?.auth?.user?.company?._id;
        if (rfxDetails !== undefined && rfxDetails._id !== undefined) {
          if (
            rfxDetails.status === AUCTION_PUBLISHED ||
            rfxDetails.status === AUCTION_DRAFT
          ) {
            if (moment(todayDate).isAfter(deliveryDateFormatted)) {
              return toast.error(ValidationMessage.deliveryDate, {
                transition: Slide,
                autoClose: TOAST_AUTO_CLOSE,
              });
            } else {
              if (
                startDate &&
                endDate &&
                isValidDate(new Date(startDate)) &&
                isValidDate(new Date(endDate))
              ) {
                let start = new Date(startDate);
                let end = new Date(endDate);

                if (start.valueOf() > end.valueOf()) {
                  toast.error(ValidationMessage.endDateValidation, {
                    transition: Slide,
                    autoClose: TOAST_AUTO_CLOSE,
                  });
                  setStartDate(rfxDetails?.startDate);
                  setEndDate(rfxDetails?.endDate);
                  return;
                }

                const formatedStartDate = new Date(startDate);
                const formatedEndDate = new Date(endDate);
                values.startDate = formatedStartDate;
                values.endDate = formatedEndDate;
                const formatedDeliveryDate = new Date(deliveryDate);
                if (deliveryDate) {
                  values.deliveryDate = formatedDeliveryDate;
                }
                values.rfx_name = values.name;

                setSaveLoader(true);
                const response = await Api(
                  `${COMAPNY_API}/${companyId}/rfx/${rfxDetails._id}`,
                  values,
                  "patch"
                );
                setSaveLoader(false);
              } else {
                toast.error(ValidationMessage.startEndDate, {
                  transition: Slide,
                  autoClose: TOAST_AUTO_CLOSE,
                });
                setStartDate(rfxDetails?.startDate);
                setEndDate(rfxDetails?.endDate);
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (rfxDetails && rfxDetails._id) {
      // HtmlUtilService.truncate();
      setValues({
        name: rfxDetails?.rfx_name,
        description: rfxDetails?.description,
      });
      setDeliveryDate(rfxDetails?.deliveryDate || null);
      setStartDate(rfxDetails?.startDate);
      setEndDate(rfxDetails?.endDate);
    }
  }, [rfxDetails]);

  useEffect(() => {
    if (values && values.description) {
      HtmlUtilService.truncate();
    }
  }, [values]);

  const changeAuctionStatus = async () => {
    if (rfxDetails && rfxDetails._id) {
      if (rfxDetails.status === AUCTION_DRAFT) {
        const response = await Api(
          `${POST_RFX_STATUS_CHANGE_API}/${rfxDetails._id}`,
          {},
          "post"
        );
        if (response.status === 200) {
          getLiveRfxDetails();
        }
      }
    }
  };

  return (
    <>
      <Form style={{ marginTop: "6em" }} onSubmit={handleSubmit}>
        <div
          className="details-banner buyer-aspect"
          style={{ background: "transparent" }}
        >
          <div className="banner-container">
            {rfxDetails && rfxDetails?.rfxId && (
              <>
                <Grid
                  container
                  style={{
                    position: "relative",
                  }}
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <h1 className="project-title mt-2">
                      <img
                        src={
                          rfxDetails.rfx_type === RFP_TYPE
                            ? rfpCover
                            : rfxDetails.rfx_type === RFQ_TYPE
                            ? rfqCover
                            : rfxDetails.rfx_type === RFI_TYPE
                            ? rfiCover
                            : rfxCover
                        }
                        className="rfx-cover-image"
                        alt=""
                      />
                      <span className="px-2">
                        <span className="main-text" truncate="5">
                          <span
                            className="show__less__text"
                            style={{ display: "inline" }}
                          >
                            {values && values.name ? values.name : "--"}
                          </span>
                          <span
                            className="show__complete__text"
                            style={{ display: "none" }}
                          >
                            {values && values.name ? values.name : "--"}
                          </span>
                          <span className="show__more__link">...</span>
                        </span>

                        {" ( "}
                        {values && values.name
                          ? RFX_TYPE_MAP.get(rfxDetails?.rfx_type)
                          : "--"}
                        {" )"}
                      </span>
                      {/* <ContentCopyIcon
                        className="ml-1 sm-icon cp-icon cusrsor-pointer"
                        onClick={() => setDuplicateAuctionModal(true)}
                      /> */}
                      <div className="table-container">
                        {rfxDetails?.status === AUCTION_DRAFT ? (
                          <PopupState variant="popover" popupId="demoPopover">
                            {(popupState) => (
                              <div>
                                <Button
                                  // className={
                                  //   "badge badge-" +
                                  //   getStatusLabel(rfxDetails?.status) +
                                  //   " status"
                                  // }
                                  className="badge badge-info status"
                                  variant="contained"
                                  {...bindTrigger(popupState)}
                                  endIcon={<ArrowDropDownIcon />}
                                >
                                  {rfxDetails?.status}
                                </Button>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <div className="px-3 py-2">
                                    <div>
                                      <button
                                        className="badge badge-purple select-colored-option"
                                        onClick={changeAuctionStatus}
                                      >
                                        {AUCTION_ACTIVE}
                                      </button>
                                    </div>
                                  </div>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        ) : (
                          <Button
                            className={
                              "badge badge-" +
                              getStatusLabel(rfxDetails?.status) +
                              " status"
                            }
                            variant="contained"
                            onClick={changeAuctionStatus}
                          >
                            {rfxDetails?.status}
                          </Button>
                        )}
                      </div>
                    </h1>
                    <div className="project-title__smal">
                      <span> RFX </span>
                      <span>
                        {" / " +
                          (rfxDetails && rfxDetails.rfx_name
                            ? rfxDetails?.rfx_name
                            : "--")}
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <button
                        className="btn btn-primary go-back-button"
                        onClick={() => GoBackToRfxList()}
                      >
                        <Link to="auction" className="mui-link">
                          <span style={{ color: "var(---2170ff-p-)" }}>
                            <ArrowBackIosNewIcon /> Go Back
                          </span>
                        </Link>
                      </button>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <button
                        className="btn btn-primary go-back-button"
                        style={{ border: "1px solid #2170ff" }}
                        type="button"
                      >
                        <Link
                          to="details/compare"
                          target="_blank"
                          className="mui-link"
                        >
                          <span style={{ color: "#2170ff" }}>
                            {/* <img
                              src={AuctionLiveImg}
                              alt=""
                              className="live-icon"
                            />{" "} */}
                            Go To Compare
                          </span>
                        </Link>
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Paper elevation={1} className="editable-board-container">
              <div className="img-back-grd"></div>

              {rfxDetails && rfxDetails?.rfxId ? (
                <Grid
                  container
                  alignItems="center"
                  style={{ position: "relative" }}
                >
                  <Grid item md={9} sm={12}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={1}
                        sm={2}
                        xs={12}
                        style={{ margin: "auto 0px" }}
                      >
                        <img src={rfxDetails?.image} className="image" alt="" />
                      </Grid>
                      <Grid item xl={2} lg={3} md={4} sm={5} xs={12}>
                        <div className="lable">RFX Name</div>
                        {editMode ? (
                          <textarea
                            className="field __edit"
                            name="name"
                            style={{ fontSize: "1em" }}
                            readOnly={!editMode}
                            spellcheck="false"
                            value={values.name}
                            onChange={handleInputChange}
                            rows="1"
                          >
                            {values.name}
                          </textarea>
                        ) : (
                          <p className="field __view show-wrap-text mw200">
                            {values.name}
                          </p>
                        )}
                        {isOwner() && rfxDetails?.status === AUCTION_DRAFT ? (
                          <div style={{ display: "flex", marginTop: "1em" }}>
                            <CommonButton
                              className={
                                "btn btn-primary go-back-button " +
                                (editMode === true ? "__save" : "__edit")
                              }
                              style={{ height: "30px" }}
                              variant="contained"
                              type="submit"
                              onClick={clickToggleBtn}
                              loader={saveLoader}
                              loaderSize={14}
                              text={editMode ? "Save Changes" : "Edit "}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item lg={4} sm={5} xs={12}>
                        <div className="lable">Description</div>
                        <div
                          id="description__view"
                          className={
                            "field" +
                            " " +
                            (editMode === true
                              ? "__edit"
                              : "__view show-wrap-text")
                          }
                          // truncate="17"
                        >
                          <Tooltip
                            title={values.description}
                            placement="left-start"
                          >
                            <span
                              className="show__less__text "
                              style={{ display: "inline" }}
                            >
                              {values.description}
                            </span>
                          </Tooltip>
                          <span
                            className="show__complete__text"
                            style={{ display: "none" }}
                          >
                            {values.description}
                          </span>
                          {/* <span className="show__more__link">...more</span> */}
                        </div>
                        <textarea
                          id="description__edit"
                          className={
                            "field" +
                            " " +
                            (editMode === true ? "__edit" : "__view")
                          }
                          readOnly={!editMode}
                          spellcheck="false"
                          name="description"
                          value={values.description}
                          onChange={handleInputChange}
                          rows="4"
                          style={{ display: "none" }}
                        >
                          {values.description}
                        </textarea>
                      </Grid>
                      <Grid item lg={2} md={6} sm={6} xs={12}>
                        <div className="lable">Start Date</div>

                        <div
                          className={
                            "datetime-picker-cotainer" +
                            " " +
                            (editMode === true ? "__edit" : "__view")
                          }
                        >
                          <Controls.DateTimePicker
                            name="startDate"
                            readOnly={!editMode}
                            value={startDate}
                            onChange={setStartDate}
                            format="DD-MM-YYYY"
                          />
                        </div>

                        <div className="lable">End Date</div>
                        <div
                          className={
                            "datetime-picker-cotainer" +
                            " " +
                            (editMode === true ? "__edit" : "__view")
                          }
                        >
                          <Controls.DateTimePicker
                            name="endDate"
                            readOnly={!editMode}
                            value={endDate}
                            onChange={setEndDate}
                            minDate={startDate}
                            format="DD-MM-YYYY"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3} sm={12}>
                    <div className="lable">Delivery Date</div>
                    <div
                      className={
                        "datetime-picker-cotainer" +
                        " " +
                        (editMode === true ? "__edit" : "__view")
                      }
                    >
                      <Controls.BasicDatePicker
                        name="deliveryDate"
                        readOnly={!editMode}
                        value={deliveryDate}
                        onChange={setDeliveryDate}
                        format="DD-MM-YYYY"
                        className="mui-date-picker-reverse"
                      />
                    </div>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="overflow-h"
                    >
                      <img
                        src={rfxDetails?.createdBy?.image_upload || userImage}
                        alt=""
                        style={{
                          width: "2.7em",
                          height: "2.7em",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                      <div className="ml-2">
                        <div className="lable">RFX Owner</div>
                        <div
                          className="field __view"
                          style={{ marginBottom: "0px" }}
                        >
                          {rfxDetails?.createdBy?.first_name +
                            " " +
                            rfxDetails?.createdBy?.last_name}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <AuctionBannerShimmer />
              )}
            </Paper>
          </div>
        </div>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <DuplicateAuctionDialog
        duplicateAuctionModal={duplicateAuctionModal}
        setDuplicateAuctionModal={setDuplicateAuctionModal}
        auctionData={rfxDetails}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(RfxBanner));
