import { SEND_NEW_BID_EVENT } from "../constants";
import SocketHandler from "../SocketHandler";
import SocketResponse from "../SocketResponse";

const SendNewBidEvent = (data = {}) => {
  SocketHandler.instance.emit(
    SEND_NEW_BID_EVENT,
    new SocketResponse(data).transform()
  );
  return true;
};

export default SendNewBidEvent;
