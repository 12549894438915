import React, { useEffect, useState } from "react";
import { Grid, Box, Tabs, Tab, Paper, Typography } from "@material-ui/core";
import LiveBIDHistoryTab from "./tabs/LiveBIDHistoryTab";
import LiveSupplierRankTab from "./tabs/LiveSupplierRankTab";
import LiveGraphTab from "./tabs/LiveGraphTab";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentLiveAuction } from "../../../store/selectors/auctionSelector";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-label={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const LiveAuctionTabContainer = (props) => {
  const [value, setValue] = useState(0);
  const { currentLiveAuction, refreshLiveData } = props;
  // const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid
        container
        className="tab-header-container"
        style={{ marginTop: "2em" }}
      >
        <Box sx={{ borderRadius: "25px !important" }}>
          <Tabs className="tab-header" value={value} onChange={handleChange}>
            <Tab label="Live Graph" />
            <Tab label="Bids History" />
            <Tab label="Supplier's Rank" />
          </Tabs>
        </Box>
      </Grid>
      <Paper className="tab-body-container">
        <TabPanel value={value} index={0} labelAria="no-padding">
          <LiveGraphTab
            currentLiveAuction={currentLiveAuction}
            refreshLiveData={refreshLiveData}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LiveBIDHistoryTab refreshLiveData={refreshLiveData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LiveSupplierRankTab
            currentLiveAuction={currentLiveAuction}
            refreshLiveData={refreshLiveData}
          />
        </TabPanel>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(LiveAuctionTabContainer));
