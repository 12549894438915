import { Modal } from "@mui/material";
import { Box, Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import "../styles/import-item-popup.css";
import templateItemCSV from "../../../assets/OKTIO_template_items.csv";
import { Api } from "../../../api/Api";
import { POST_UPLOAD_SUPPLIER_API } from "../../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const ImportItems = (props) => {
  const { importItemsPopup, setImportItemsPopup, items, setItems } = props;

  async function handleImageChange(e) {
    const selected = e.target.files[0];

    const ALLOWED_TYPES = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let formData = new FormData();
      formData.append("csvFile", selected);
      const response = await Api(
        POST_UPLOAD_SUPPLIER_API,
        formData,
        "postMultipart"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          const csvData = response.data.auctionItemsArray;
          let makeItemdata = [];
          for (let i = csvData.length - 1; i >= 0; i--) {
            for (let j = 0; j < items.length; j++) {
              if (
                csvData[i] &&
                csvData[i].itemName === items[j].name &&
                csvData[i].unit === items[j].unit &&
                csvData[i].quantity === items[j].quantity
              ) {
                csvData.splice(i, 1);
              }
            }
          }
          csvData.map((item) => {
            const rendomNum = Math.floor(100000 + Math.random() * 900000);
            if (item && item.itemName) {
              const tmp = {
                category: "",
                itemId: "R" + rendomNum,
                itemName: "[R" + rendomNum + "] " + item?.itemName,
                unit: item?.unit,
                quantity: item?.quantity,
                name: item?.itemName,
              };
              makeItemdata.push(tmp);
            }
          });
          if (items.length === 0) {
            const neItems = [...makeItemdata];
            setItems(neItems);
          } else {
            const concateArray = items.concat(makeItemdata);
            const neItems = [...concateArray];

            setItems(neItems);
          }
          setImportItemsPopup(false);
          e.target.value = "";
        } else {
          e.target.value = "";
          toast.error(ValidationMessage.validFile, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      } else {
        e.target.value = "";
        toast.error(ValidationMessage.validFile, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
      // await axiosInstance
      //   .post(`api/buyer/uploadSupplierCsv`, formData)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       const csvData = response.data.auctionItemsArray;
      //       let makeItemdata = [];
      //       for (let i = csvData.length - 1; i >= 0; i--) {
      //         for (let j = 0; j < items.length; j++) {
      //           if (
      //             csvData[i] &&
      //             csvData[i].itemName === items[j].name &&
      //             csvData[i].unit === items[j].unit &&
      //             csvData[i].quantity === items[j].quantity
      //           ) {
      //             csvData.splice(i, 1);
      //           }
      //         }
      //       }
      //       csvData.map((item) => {
      //         const rendomNum = Math.floor(100000 + Math.random() * 900000);
      //         if (item && item.itemName) {
      //           const tmp = {
      //             category: "",
      //             itemId: "R" + rendomNum,
      //             itemName: "[R" + rendomNum + "] " + item?.itemName,
      //             unit: item?.unit,
      //             quantity: item?.quantity,
      //             name: item?.itemName,
      //           };
      //           makeItemdata.push(tmp);
      //         }
      //       });
      //       if (items.length == 0) {
      //         const neItems = [...makeItemdata];
      //         setItems(neItems);
      //       } else {
      //         const concateArray = items.concat(makeItemdata);
      //         const neItems = [...concateArray];

      //         setItems(neItems);
      //       }
      //       setImportItemsPopup(false);
      //       e.target.value = "";
      //     } else {
      //       e.target.value = "";
      //       setNotify({
      //         isOpen: true,
      //         message: "Please select valid file.",
      //         type: "error",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     e.target.value = "";
      //     setNotify({
      //       isOpen: true,
      //       message: "Please select valid file.",
      //       type: "error",
      //     });
      //   });
    } else {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  }

  return (
    <>
      <Modal
        open={importItemsPopup}
        aria-label="item-list-info-details-attach-docs-popup"
        aria-describedby="item-list-info-details-attach-docs-popup"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Import Items</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setImportItemsPopup(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" className="pt-15">
            <Grid item md={12} xs={12}>
              <Grid container alignItems="center">
                <a
                  href={templateItemCSV}
                  download="OKTIO_template_items.csv"
                  className="btn btn-primary btn-mui import-item-btn w-100"
                >
                  <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
                    <DownloadIcon className="import-download-icn" /> Download
                    Template Spredsheet
                  </span>
                </a>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" className="mt-1 mb-2">
                <Grid item xs={5}>
                  <div className="hr-devider-import-file">
                    <hr className="solid" />
                  </div>
                </Grid>
                <Grid item xs={2} className="text-center">
                  <p className="devider-center-text">and</p>
                </Grid>
                <Grid item xs={5}>
                  <div className="hr-devider-import-file">
                    <hr className="solid" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container alignItems="center">
                <label htmlFor="fileUploadButton" className="w-100">
                  <a download className="btn btn-primary btn-mui w-100">
                    <span style={{ verticalAlign: "sub" }}>Browse File</span>
                  </a>
                  <input
                    type="file"
                    id="fileUploadButton"
                    hidden
                    onChange={(e) => handleImageChange(e)}
                    accept=".csv, application/vnd.ms-excel"
                  />
                </label>
              </Grid>
            </Grid>
            <Grid item xs={10} className="mt-10">
              <p className="import-item-footer-description">
                Once finished, upload the template Spredsheet. <br />
                All .xls file are supported. The maximum file size is 8mb.
              </p>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ImportItems;
