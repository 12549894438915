import { useState, useEffect } from "react";
import { useForm, Form } from "../../components/useForm";
import Controls from "../../components/controls/Controls";
import { makeStyles, Typography, Grid, Box, Paper } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { store } from "../../store/store";
import countries from "../../assets/countries.json";
import Notification from "../../components/Notification";
import { Button, Container, InputLabel } from "@mui/material";
import * as employeeService from "../../services/employeeService";
import useWindowDimensions from "../../components/useWindowDimensions";
import "./settings.css";
import "./styles/company.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import {
  ACCEPT_ONLY_IMAGE,
  ITEM_FILE_SIZE,
  TOAST_AUTO_CLOSE,
  VALID_EMAIL_REGEX,
} from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { phone } from "phone";
import { Api } from "../../api/Api";
import {
  GET_COMPANY_PROFILE_API,
  PATCH_EDIT_COMPANY_API,
} from "../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  errMsg: {
    color: "red",
    fontSize: "14px",
    margin: "3px 0",
  },
  imgPreview: {
    width: "100%",
    height: "460px",
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#ccc",
    padding: "100px",
    border: "1px solid #000",
    background: "#cccccc",
  },

  customFileUpload: {
    color: "#55d6d6",
    fontSize: "22px",
    fontWeight: "500",
    padding: "6px 12px",
    cursor: "pointer",
  },
  files: { padding: "5px" },
  customeFileUpload: {
    position: "absolute",
    padding: "47px 20px",
    cursor: "pointer",
  },
  formPart1: {
    "& .MuiFormControl-root": {
      width: "90%",
    },
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    padding: ".5rem 2rem",
    fontWeight: "700",
  },
  input: {
    display: "none",
  },
  attachmentText: {
    fontWeight: "700",
  },
  attachment: {
    margin: "2rem 0",
  },
  Button: {
    padding: ".4rem 3rem",
    borderRadius: "6px",
    fontWeight: "700",
    fontSize: "14px",
  },
  imageInfo: {
    color: "#A19B9D",
    fontSize: "12px",
    maxWidth: "10.5rem",
  },
  imageInfoTwo: {
    color: "#A19B9D",
    fontSize: "12px",
  },
  modalContent: {
    width: "100%",
  },
  addLink: {
    color: "#1E1D1D",
    margin: "0 0 30px 0",
    fontSize: "14px",
    fontWeight: "700",
  },
  dragAndDrop: {
    fontWeight: "700",
    fontSize: "18px",
  },
  attachmentBoxes: {
    padding: "10px",
  },
}));

const initialFValues = {
  company_name: "",
  country: "",
  city: "",
  address: "",
  mobile: "",
  email: "",
  website: "",
  annual_revenue: "",
  no_of_employees: "",
  company_type: "",
  pobox: "",
  summary: "",
};
const CompanyInfo = (props) => {
  const { setFullLoder, isAdmin, isSeller, isBuyer, currentRole } = props;
  const classes = useStyles();
  const [imgPreview, setImgPreview] = useState(null);
  const [error, setError] = useState();
  const [companyInfoData, setCompanyInfoData] = useState({});
  const [img, setImg] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [additionalActivity, setAdditionalActivity] = useState([]);
  const [tempAdditionalActivity, setTempAdditionalActivity] = useState("");
  const [summyWordCount, setSummyWordCount] = useState(0);
  const { xsScreen } = useWindowDimensions();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [errorPhone, setErrorPhone] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("website" in fieldValues) {
      if (
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
          fieldValues.website
        )
      ) {
        temp.website = "";
      } else {
        temp.website = "Please enter valid website";
      }
    }

    if ("pobox" in fieldValues) {
      if (/^[0-9\s]*$/.test(fieldValues.pobox)) {
        temp.pobox = "";
      } else {
        temp.pobox = "Please enter only numbers";
      }
    }

    // if ("mobile" in fieldValues) {
    //   if (/^[0-9\s]*$/.test(fieldValues.mobile)) {
    //     temp.mobile = "";
    //   } else {
    //     temp.mobile = "Please enter only numbers";
    //   }
    // }

    if ("email" in fieldValues) {
      if (!VALID_EMAIL_REGEX.test(fieldValues.email)) {
        temp.email = "Invalid Email";
      } else {
        temp.email = "";
      }
    }
    if ("summary" in fieldValues) {
      if (fieldValues?.summary?.length > 280) {
        temp.summary = "summary must not exceed 280 characters";
      } else {
        temp.summary = "";
      }
    }
    if ("company_name" in fieldValues) {
      if (fieldValues?.company_name?.length > 0) {
        temp.company_name = "";
      } else {
        temp.company_name = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const removeSelectedImage = () => {
    setImg("");
    setImgPreview(null);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() && !errorPhone) {
      setFullLoder(true);
      let formData = new FormData();
      formData.append("company_name", values.company_name);
      formData.append("website", values.website);
      if (values.country && values.country.length > 0)
        formData.append("country", values.country);
      if (values.city && values.city.length > 0)
        formData.append("city", values.city);
      if (values.address && values.address.length > 0)
        formData.append("address", values.address);
      if (values.email && values.email.length > 0)
        formData.append("email", values.email);
      if (phoneNumber) formData.append("mobile", phoneNumber);
      if (countryCode) formData.append("countryCode", countryCode);
      if (values.no_of_employees && values.no_of_employees.length > 0)
        formData.append("no_of_employees", values.no_of_employees);
      if (values.annual_revenue && values.annual_revenue.length > 0)
        formData.append("annual_revenue", values.annual_revenue);
      if (values.capabilities && values.capabilities.length > 0)
        formData.append("capabilities", values.capabilities);
      if (values.pobox && values.pobox.length > 0)
        formData.append("pobox", values.pobox);
      if (values.summary && values.summary.length > 0)
        formData.append("summary", values.summary);

      if (additionalActivity && additionalActivity.length > 0)
        formData.append(
          "additionalActivity",
          JSON.stringify(additionalActivity)
        );
      if (imgFile && imgFile.type) {
        formData.append("profile", imgFile);
      } else {
        formData.append("image_upload", img || null);
      }

      const response = await Api(
        `${PATCH_EDIT_COMPANY_API}/${companyInfoData?._id}`,
        formData,
        "patch"
      );
      if (response.status === 200) {
        setImgFile(null);
        setFullLoder(false);
        getCompanyProfile();
      } else {
        setImgFile(null);
        setFullLoder(false);
      }

      // axiosInstance
      //   .patch(`api/company/editCompany/${companyInfoData?._id}`, paylod)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       setNotify({
      //         isOpen: true,
      //         message: "Company profile updated successfully.",
      //         type: "success",
      //       });
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: "Submitted Successfully",
      //         type: "success",
      //       });
      //     }
      //     setFullLoder(false);
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: "Failed to save item",
      //       type: "error",
      //     });
      //     setFullLoder(false);
      //   });
    } else {
      setFullLoder(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  async function handleImageChange(e) {
    const selected = e.target.files[0];

    const ALLOWED_TYPES = ACCEPT_ONLY_IMAGE;
    if (selected && !ALLOWED_TYPES.includes(selected.type)) {
      setError(ValidationMessage.itemFileType);

      return;
    }
    if (selected && Math.round(selected.size / 1024) > ITEM_FILE_SIZE) {
      setError(ValidationMessage.itemFileSize);

      return;
    }
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setImgFile(selected);
      const base64Image = await getBase64(selected);

      setImg(base64Image);
      setImgPreview(base64Image);
      setError("");
    }
  }

  const getCompanyProfile = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    setFullLoder(true);
    const response = await Api(
      `${GET_COMPANY_PROFILE_API}/${companyId}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let companyData = response.data.company;
        setCompanyInfoData(companyData);
        setImgPreview(companyData.image_upload || null);
        setImg(companyData.image_upload || "");
        setValues({
          company_name: companyData.company_name,
          country: companyData.country,
          city: companyData.city,
          address: companyData.address,
          mobile: `${companyData?.countryCode}${companyData?.mobile}`,
          email: companyData.email,
          website: companyData?.website,
          no_of_employees: companyData?.no_of_employees,
          annual_revenue: companyData?.annual_revenue,
          capabilities: companyData?.capabilities,
          pobox: companyData?.pobox,
          company_type: companyData?.company_type,
          summary: companyData?.summary,
        });
        setAdditionalActivity(companyData?.additional_activities);
        setSummyWordCount(
          companyData && companyData?.summary ? companyData?.summary.length : 0
        );
      }
      setFullLoder(false);
    } else {
      setFullLoder(false);
    }

    // axiosInstance
    //   .get(`api/company/profile/${companyId}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let companyData = response.data.company;
    //       setCompanyInfoData(companyData);
    //       setImgPreview(companyData.image_upload || null);
    //       setImg(companyData.image_upload || "");
    //       setValues({
    //         company_name: companyData.company_name,
    //         country: companyData.country,
    //         city: companyData.city,
    //         address: companyData.address,
    //         mobile: `${companyData?.countryCode}${companyData?.mobile}`,
    //         email: companyData.email,
    //         website: companyData?.website,
    //         no_of_employees: companyData?.no_of_employees,
    //         annual_revenue: companyData?.annual_revenue,
    //         capabilities: companyData?.capabilities,
    //         pobox: companyData?.pobox,
    //         company_type: companyData?.company_type,
    //         summary: companyData?.summary,
    //       });
    //       setAdditionalActivity(companyData?.additional_activities);
    //       setSummyWordCount(
    //         companyData && companyData?.summary
    //           ? companyData?.summary.length
    //           : 0
    //       );
    //     }
    //     setFullLoder(false);
    //   })
    //   .catch((err) => {
    //     setFullLoder(false);
    //   });
  };

  const addNewAdditionalActivity = (e) => {
    if (tempAdditionalActivity !== "") {
      additionalActivity.push(tempAdditionalActivity);
      const neItems = [...additionalActivity];
      setAdditionalActivity([...neItems]);
      setTempAdditionalActivity("");
    } else {
      toast.error(ValidationMessage.enterText, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const removeSelectActivity = (name, index) => {
    const neItems = [...additionalActivity];
    const fitlerActivity = neItems.filter((obj, i) => i !== index);
    setAdditionalActivity([...fitlerActivity]);
  };

  useEffect(() => {
    getCompanyProfile();
  }, []);

  const phoneNumberValidateHandler = (val) => {
    const PlusData = `+${val}`;
    const FinalVal = phone(PlusData);

    return FinalVal;
  };

  const phoneNumberChangeHandler = (phone) => {
    let init = {
      target: {
        name: "mobile",
        value: phone,
      },
    };
    handleInputChange(init);
    let res = phoneNumberValidateHandler(phone);
    if (!res.isValid) {
      setErrorPhone(true);
    } else {
      setCountryCode(res?.countryCode);
      setPhoneNumber(res?.phoneNumber.replace(res?.countryCode, ""));
      setErrorPhone(false);
    }
  };

  return (
    <Paper square style={{ padding: "24px", borderRadius: "12px" }}>
      <Container className="settings-company-section-contaier">
        <p className="profile-title">Company Info</p>
        <Form onSubmit={handleSubmit}>
          <Grid container className="containerSettings" justifyContent="center">
            <Grid item md={4} xs={12}>
              <div
                className="leftContent mb-4"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                {error && error.length > 0 && (
                  <p
                    className="errMsg"
                    style={{
                      color: "red",
                      fontSize: "11px",
                      margin: "3px 0",
                      paddingLeft: "10px",
                    }}
                  >
                    {error}
                  </p>
                )}
                <div
                  className="imgPreview"
                  style={{
                    background: imgPreview
                      ? `url("${imgPreview}") no-repeat center/cover`
                      : "#e2f2ff",
                    width: "120px",
                    height: "120px",
                    display: "flex",
                    flexDirection: "column",
                    alignItem: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    backgroundColor: "#E2F2FF",
                    borderRadius: "50%",
                    margin: "0 0 30px",
                    color: "#000000",
                  }}
                >
                  {!imgPreview && (
                    <>
                      <p>
                        <AddCircleIcon color="primary" />
                      </p>
                      <label
                        htmlFor="fileUploadButton"
                        className="customeFileUpload"
                        style={{
                          position: "absolute",
                          padding: "47px 57px",
                          cursor: "pointer",
                        }}
                      >
                        &nbsp;
                      </label>
                      <input
                        type="file"
                        id="fileUploadButton"
                        onChange={(e) => handleImageChange(e)}
                        value={values.image}
                        accept={ACCEPT_ONLY_IMAGE}
                      />
                    </>
                  )}
                </div>
                {/* <input
                  accept="image/*"
                  className={classes.input}
                  id="outlined-button-file"
                  multiple
                  type="file"
                /> */}
                <label htmlFor="fileUploadButton">
                  <Controls.Button
                    component="span"
                    text="Change Image"
                    disableElevation
                    style={{
                      borderRadius: "25px",
                      fontWeight: "600",
                      height: "40px",
                    }}
                  />
                  <input
                    type="file"
                    id="fileUploadButton"
                    hidden
                    onChange={handleImageChange}
                    value={values.image}
                  />
                </label>
                {imgPreview && (
                  <Controls.Button
                    text="Delete"
                    color="default"
                    onClick={() => removeSelectedImage()}
                    className="btn draft"
                    style={{
                      width: "150px",
                      height: "40px",
                      borderRadius: "25px",
                      fontWeight: "600",
                    }}
                  />
                )}

                <Typography
                  className={classes.imageInfo}
                  style={{ marginTop: "20px", textAlign: "center" }}
                >
                  540x540 px recommended. JPG or PNG. Max size of 800K
                </Typography>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputLabel className="mui-label">Company Name</InputLabel>
                  <Controls.Input
                    name="company_name"
                    value={values.company_name}
                    onChange={handleInputChange}
                    error={errors.company_name}
                    inputProps={{ maxLength: 30 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled={!isAdmin ? true : false}
                  />
                </Grid>
                {/* <Grid item lg={4} md={6} sm={4} xs={12}>
                  <InputLabel className="mui-label">Company Type</InputLabel>
                  <Controls.Select
                    name="company_type"
                    fullWidth
                    onChange={handleInputChange}
                    value={values.company_type}
                    error={errors.company_type}
                    options={employeeService.companyType()}
                    disabled={!isAdmin ? true : false}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Summary</InputLabel>
                  <Controls.Input
                    name="summary"
                    className="fix-h-8em"
                    onChange={handleInputChange}
                    value={values.summary}
                    error={errors.summary}
                    onKeyUp={(e) => {
                      setSummyWordCount(e.target.value.length);
                    }}
                    disabled={!isAdmin ? true : false}
                  />
                  <Grid
                    item
                    xs={12}
                    className="BottomModal-right summary-word-count"
                  >
                    {summyWordCount}/280
                  </Grid>
                </Grid>
                <Grid item lg={6} md={12} sm={6} xs={12}>
                  <InputLabel className="mui-label">Country </InputLabel>
                  <Controls.Select
                    name="country"
                    value={values.country}
                    fullWidth
                    onChange={handleInputChange}
                    options={countries}
                    error={errors.country}
                    className="StepControlInput"
                    disabled={!isAdmin ? true : false}
                  />
                </Grid>
                <Grid item lg={6} md={12} sm={6} xs={12}>
                  <InputLabel className="mui-label">City </InputLabel>
                  <Controls.Input
                    name="city"
                    value={values.city}
                    onChange={handleInputChange}
                    error={errors.city}
                    inputProps={{ maxLength: 50 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled={!isAdmin ? true : false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Address </InputLabel>
                  <Controls.Input
                    name="address"
                    fullWidth
                    value={values.address}
                    onChange={handleInputChange}
                    error={errors.address}
                    disabled={!isAdmin ? true : false}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <InputLabel className="mui-label">PO Box </InputLabel>
                  <Controls.Input
                    name="pobox"
                    fullWidth
                    value={values.pobox}
                    onChange={handleInputChange}
                    error={errors.pobox}
                    inputProps={{ maxLength: 6 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    className="helper-text-nowrap"
                    disabled={!isAdmin ? true : false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Phone </InputLabel>
                  <PhoneInput
                    country={"us"}
                    value={values.mobile}
                    onChange={(phone) => {
                      phoneNumberChangeHandler(phone);
                    }}
                  />
                  {errorPhone && (
                    <div className="error-msg">
                      Please enter valid contact number
                    </div>
                  )}
                  {/* <Controls.Input
                    name="mobile"
                    fullWidth
                    // disabled
                    value={values.mobile}
                    onChange={handleInputChange}
                    error={errors.mobile}
                    inputProps={{ maxLength: 15 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled={!isAdmin ? true : false}
                  /> */}
                  {/* <Controls.Button text="Change" /> */}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Email </InputLabel>
                  <Controls.Input
                    name="email"
                    fullWidth
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    inputProps={{ maxLength: 60 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled
                  />
                  {/* <Controls.Button text="Change" /> */}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Website </InputLabel>
                  <Controls.Input
                    name="website"
                    fullWidth
                    value={values.website}
                    onChange={handleInputChange}
                    error={errors.website}
                    inputProps={{ maxLength: 200 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled={!isAdmin ? true : false}
                  />
                </Grid>
                <Grid item lg={6} md={12} sm={6} xs={12}>
                  <InputLabel className="mui-label">Annual revenue</InputLabel>
                  <Controls.Select
                    name="annual_revenue"
                    fullWidth
                    onChange={handleInputChange}
                    value={values.annual_revenue}
                    error={errors.annual_revenue}
                    options={employeeService.companyAnnualRevenue()}
                    disabled={!isAdmin ? true : false}
                  />
                </Grid>
                <Grid item lg={6} md={12} sm={6} xs={12}>
                  <InputLabel className="mui-label">Nb of employees</InputLabel>
                  <Controls.Select
                    name="no_of_employees"
                    fullWidth
                    onChange={handleInputChange}
                    value={values.no_of_employees}
                    error={errors.no_of_employees}
                    options={employeeService.companyNoOfEmployee()}
                    disabled={!isAdmin ? true : false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">
                    Additional activities
                  </InputLabel>
                  <div
                    className={
                      "act-container " +
                      (additionalActivity && additionalActivity.length > 0
                        ? "mt-2"
                        : "")
                    }
                  >
                    {additionalActivity && additionalActivity.length > 0
                      ? additionalActivity.map((name, index) => (
                          <>
                            <div className="badge badge-secondary activity max-width100">
                              <span className="simple-word-break ">{name}</span>
                              <CloseIcon
                                onClick={() =>
                                  removeSelectActivity(name, index)
                                }
                              />
                            </div>
                          </>
                        ))
                      : ""}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm={9} xs={12} className="pt-0">
                      <Controls.Input
                        name="new_activty"
                        fullWidth
                        inputProps={{ maxLength: 200 }}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        value={tempAdditionalActivity}
                        onChange={(e) =>
                          setTempAdditionalActivity(e.target.value)
                        }
                        disabled={!isAdmin ? true : false}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12} className="pt-0">
                      <Button
                        variant="contained"
                        className="btn btn-primary-lite btn-mui text-transform-cap w-100"
                        onClick={() => addNewAdditionalActivity()}
                        disabled={!isAdmin ? true : false}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Capabilities</InputLabel>
                  <Controls.Input
                    name="capabilities"
                    className="fix-h-8em"
                    onChange={handleInputChange}
                    value={values.capabilities}
                    error={errors.capabilities}
                    disabled={!isAdmin ? true : false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    {!isAdmin ? (
                      <></>
                    ) : (
                      <Controls.Button
                        type="submit"
                        disableElevation
                        text="Save Changes"
                        className={
                          "btn btn-mui btn-primary m-0 " +
                          (xsScreen ? "w-100" : "")
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            bgcolor="background.paper"
          ></Box>
        </Form>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
  };
};

export default withRouter(connect(mapStateToProps)(CompanyInfo));
