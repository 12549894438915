import { Box, Grid } from "@material-ui/core";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Pagination, PaginationItem } from "@mui/material";

export default function TablePaginationBox(props) {
  const { numOfRec, label, numOfPages, onChangePage, page } = props;
  return (
    <Box className="pagination-box-container">
      <Grid container>
        <Grid item sm={3} xs={12} className="pagination-total-container">
          <h3>{label}</h3>
        </Grid>
        <Grid item sm={9} xs={12} className="pagination-slider-container ">
          <Pagination
            count={numOfPages}
            page={page}
            onChange={(event, newPage) => onChangePage(event, newPage)}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: ArrowBackIosNewIcon,
                  next: ArrowForwardIosIcon,
                }}
                {...item}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
