export const SAVE_AUCTION_STEP_1_REQUEST =
  "[Auction Action] Save form 1 data request";
export const SAVE_AUCTION_STEP_1 = "[Auction Action] Save form 1 data";
export const SAVE_AUCTION_STEP_2_REQUEST =
  "[Auction Action] Save form 2 data request";
export const SAVE_AUCTION_STEP_2 = "[Auction Action] Save form 2 data";
export const SAVE_AUCTION_STEP_3_REQUEST =
  "[Auction Action] Save form 3 data request";
export const SAVE_AUCTION_STEP_3 = "[Auction Action] Save form 3 data";
export const SAVE_AUCTION_STEP_4_REQUEST =
  "[Auction Action] Save form 4 data request";
export const SAVE_AUCTION_STEP_4 = "[Auction Action] Save form 4 data";
export const SAVE_AUCTION_STEP_5_REQUEST =
  "[Auction Action] Save form 5 data request";
export const SAVE_AUCTION_STEP_5 = "[Auction Action] Save form 5 data";
export const SAVE_AUCTION_PROJECT_DATA_REQUEST =
  "[Auction Action] Save form Project details request";
export const SAVE_AUCTION_PROJECT_DATA = "[Auction Action] Save project data";
export const SAVE_AUCTION_PROJECT_LINK_REQUEST =
  "[Auction Action] Save form Project link request";
export const SAVE_AUCTION_PROJECT_LINK = "[Auction Action] Save project link";
export const CLEAR_REDUX_ON_SAVED_SUCCESS =
  "[Auction Action] Remove auction on redux after saved success";
export const SAVE_AUCTION_STATUS = "[Auction Action] Final auction status";
export const SAVE_INVITE_NEW_SUPPLIER_REQUEST =
  "[Auction Action] add new invite supplier";
export const SAVE_INVITE_NEW_SUPPLIER =
  "[Auction Action] Save new invited supplier";
export const SAVE_LIB_DOCUMENT_REQUEST =
  "[Auction Action] Request for add document from library";
export const SAVE_LIB_DOCUMENT =
  "[Auction Action] Save add document from library";
export const TEMP_SAVE_LIB_DOCUMENT_REQUEST =
  "[Auction Action] Temp Request for add document from library";
export const TEMP_SAVE_LIB_DOCUMENT =
  "[Auction Action] Temp Save add document from library";

// for live monitoring
export const GET_LIVE_AUCTION_DETAILS =
  "[Live Auction Get] Get Live Auction Details (Buyer)";
export const REFRESH_LIVE_BIDDING_DATA_BUYER =
  "[Live Bidding Data Refresh] refresh live bidding data (Buyer)";
export const REFRESH_LIVE_AUCTION_BUYER =
  "[Live Auction Refresh] refresh live Auction Props (Buyer)";
