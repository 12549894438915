export const SAVE_RFX_PROJECT_DATA_REQUEST = "SAVE_RFX_PROJECT_DATA_REQUEST ";
export const SAVE_RFX_PROJECT_DATA = "SAVE_RFX_PROJECT_DATA";
export const SAVE_RFX_STEP_1_REQUEST = "SAVE_RFX_STEP_1_REQUEST";
export const SAVE_RFX_STEP_1 = "SAVE_RFX_STEP_1";
export const CLEAR_REDUX_ON_SAVED_SUCCESS = "CLEAR_REDUX_ON_SAVED_SUCCESS";
export const SAVE_RFX_STEP_2_REQUEST = "SAVE_RFX_STEP_2_REQUEST";
export const SAVE_RFX_STEP_2 = "SAVE_RFX_STEP_2";
export const SAVE_RFX_STEP_3_REQUEST = "SAVE_RFX_STEP_3_REQUEST";
export const SAVE_RFX_STEP_3 = "SAVE_RFX_STEP_3";
export const SAVE_RFX_PROJECT_LINK_REQUEST = "SAVE_RFX_PROJECT_LINK_REQUEST";
export const SAVE_RFX_PROJECT_LINK = "SAVE_RFX_PROJECT_LINK";
export const RFX_SAVE_LIB_DOCUMENT_REQUEST = "RFX_SAVE_LIB_DOCUMENT_REQUEST";
export const RFX_SAVE_LIB_DOCUMENT = "RFX_SAVE_LIB_DOCUMENT";
export const RFX_TEMP_SAVE_LIB_DOCUMENT_REQUEST =
  "RFX_TEMP_SAVE_LIB_DOCUMENT_REQUEST";
export const RFX_TEMP_SAVE_LIB_DOCUMENT = "RFX_TEMP_SAVE_LIB_DOCUMENT";
export const SAVE_RFX_STEP_4_REQUEST = "SAVE_RFX_STEP_4_REQUEST";
export const SAVE_RFX_STEP_4 = "SAVE_RFX_STEP_4";
export const SAVE_RFX_STEP_5_REQUEST = "SAVE_RFX_STEP_5_REQUEST";
export const SAVE_RFX_STEP_5 = "SAVE_RFX_STEP_5";
export const SAVE_RFX_STEP_6 = "SAVE_RFX_STEP_6";
export const SAVE_RFX_STEP_6_REQUEST = "SAVE_RFX_STEP_6_REQUEST";
export const SAVE_RFX_STATUS = "SAVE_RFX_STATUS";
export const CLEAR_REDUX_ON_CLOSE_RFX = "CLEAR_REDUX_ON_CLOSE_RFX";
export const GET_LIVE_RFX_DETAILS = "GET_LIVE_RFX_DETAILS";
export const REFRESH_LIVE_RFX_DATA_BUYER = "REFRESH_LIVE_RFX_DATA_BUYER";
export const REFRESH_LIVE_RFX_BUYER = "REFRESH_LIVE_RFX_BUYER";
export const GET_RFX_DETAILS = "GET_RFX_DETAILS";
