import { Link, useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import rfpCover from "../../../../assets/Rfx/RfpCover.png";
import rfiCover from "../../../../assets/Rfx/RfiCover.png";
import rfqCover from "../../../../assets/Rfx/RfqCover.png";
import rfxCover from "../../../../assets/Rfx/RfxCover.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";
import {
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_CANCELLED,
  AUCTION_DRAFT,
  AUCTION_ENDED_CAPITAL,
  AUCTION_ONHOLD,
  AUCTION_PUBLISHED,
  AUCTION_RUNNING,
  DANGER_BACKGROUND,
  PURPLE_BACKGROUND,
  RFI_TYPE,
  RFP_TYPE,
  RFQ_TYPE,
  RFX_TYPE_MAP,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  WARNING_BACKGROUND,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../../constants/AppConstants";
import "../../../Auction/styles/details-banner.css";
import { useSelector } from "react-redux";

const RfxCompareBanner = () => {
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const history = useHistory();

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ENDED_CAPITAL:
        return WARNING_OUTLINE_BACKGROUND;
      case AUCTION_RUNNING:
        return SUCCESS_BACKGROUND;
      case AUCTION_PUBLISHED:
        return SUCCESS_BACKGROUND;
      case AUCTION_DRAFT:
        return SECONDARY_BACKGROUND;
      case AUCTION_ONHOLD:
        return WARNING_BACKGROUND;
      case AUCTION_CANCELLED:
        return DANGER_BACKGROUND;
      case AUCTION_ARCHIEVED_CAPITAL:
        return PURPLE_BACKGROUND;
      default:
        return PURPLE_BACKGROUND;
    }
  };
  const GoBackToRfxList = () => {
    history.push("/rfx/" + rfxDetails?._id + "/details");
  };
  return (
    <div
      className="details-banner rfx-compare-banner buyer-aspect"
      style={{ background: "transparent" }}
    >
      <div className="banner-container">
        {rfxDetails && rfxDetails?.rfxId && (
          <>
            <Grid
              container
              style={{
                position: "relative",
              }}
              spacing={1}
            >
              <Grid item xs={12}>
                <h1 className="project-title mt-2">
                  <img
                    src={
                      rfxDetails.rfx_type === RFP_TYPE
                        ? rfpCover
                        : rfxDetails.rfx_type === RFQ_TYPE
                        ? rfqCover
                        : rfxDetails.rfx_type === RFI_TYPE
                        ? rfiCover
                        : rfxCover
                    }
                    className="rfx-cover-image"
                    alt=""
                  />
                  <span className="px-2">
                    <span className="main-text" truncate="5">
                      <span
                        className="show__less__text"
                        style={{ display: "inline" }}
                      >
                        {rfxDetails && rfxDetails?.rfx_name
                          ? rfxDetails?.rfx_name
                          : "--"}
                      </span>
                      <span
                        className="show__complete__text"
                        style={{ display: "none" }}
                      >
                        {rfxDetails && rfxDetails?.rfx_name
                          ? rfxDetails?.rfx_name
                          : "--"}
                      </span>
                      <span className="show__more__link">...</span>
                    </span>

                    {" ( "}
                    {rfxDetails && rfxDetails?.rfx_name
                      ? RFX_TYPE_MAP.get(rfxDetails?.rfx_type)
                      : "--"}
                    {" )"}
                  </span>
                </h1>
                <div className="project-title__smal">
                  <span> RFX </span>
                  <span>
                    {" / " +
                      (rfxDetails && rfxDetails.rfx_name
                        ? rfxDetails?.rfx_name
                        : "--")}
                  </span>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <button
                    className="btn btn-primary go-back-button"
                    onClick={() => GoBackToRfxList()}
                  >
                    <Link to="auction" className="mui-link">
                      <span style={{ color: "var(---2170ff-p-)" }}>
                        <ArrowBackIosNewIcon /> Go Back
                      </span>
                    </Link>
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};

export default RfxCompareBanner;
