import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Appbars from "../../components/Appbar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Copyright from "../../components/Copyright";
import SuppliersList from "./suppliersList";
import Loader from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const Suppliers = () => {
  const classes = useStyles();
  const [fullLoder, setFullLoder] = useState(false);
  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Appbars> </Appbars>
      <main className="app-main-section-container">
        <div className={classes.appBarSpacer} />
        <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
        <Container maxWidth="lg" className={classes.container}>
          <SuppliersList setFullLoder={setFullLoder} />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default Suppliers;
