import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../components/Notification";
import TableOptionList from "../../components/TableOptionList";
import TablePaginationBox from "../../components/TablePaginationBox";
import countries from "../../assets/countries.json";
import DetailsSlider from "./components/detailsSlider";
import ConfirmDialog from "../../components/ConfirmDialog";
import { Api } from "../../api/Api";
import { DELETE_SUPPLIER_API } from "../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_INACTIVE_CAP_SMALL,
  DANGER_BACKGROUND,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
} from "../../constants/AppConstants";

function ItemTable(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

ItemTable.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const columns = [
  {
    label: "ID",
  },
  {
    label: "Company Name",
    minWidth: 220,
  },
  {
    label: "Country",
    minWidth: 150,
  },
  {
    label: "Main Contact",
    minWidth: 250,
  },
  {
    label: "Email",
  },
  {
    label: "Status",
  },
  {
    label: "Category",
  },
  // {
  //   label: "Tags",
  // },
  {
    label: "",
  },
];

const SuppliersTable = (props) => {
  const { suppliersList, rowsPerPage, getAllSuppliers, setSuppliersList } =
    props;

  const [page, setPage] = React.useState(1);
  const [openDetailsPopup, setOpenDetailsPopup] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [deleteSupplierId, setdeleteSupplierId] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const numOfPages = Math.ceil(suppliersList.length / rowsPerPage);

  const getStatusColor = (status) => {
    switch (status) {
      case AUCTION_ACTIVE:
        return SUCCESS_BACKGROUND;
      case AUCTION_INACTIVE_CAP_SMALL:
        return DANGER_BACKGROUND;
      default:
        return SECONDARY_BACKGROUND;
    }
  };

  const DeleteItem = async (supplier) => {
    if (supplier?._id) {
      setdeleteSupplierId(supplier?._id);
      setConfirmDialog({
        isOpen: true,
        title: "SUPPLIER",
        subTitle: "Are you sure to delete supplier?",
      });
    }
  };

  const confirmDeleteSupplier = async () => {
    if (deleteSupplierId) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const response = await Api(
        `${DELETE_SUPPLIER_API}/${deleteSupplierId}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setdeleteSupplierId(null);
          setConfirmDialog({
            isOpen: false,
            title: "",
            subTitle: "",
          });
          const newSuppliersList = suppliersList.filter(
            (item) => item._id !== deleteSupplierId
          );
          setSuppliersList(newSuppliersList);
          const numOfPages =
            Math.ceil(newSuppliersList.length / rowsPerPage) || 1;
          if (page > numOfPages) {
            setPage(numOfPages);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (suppliersList.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [suppliersList, page]);

  return (
    <>
      <Paper elevation={0} square>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? suppliersList.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : suppliersList
              ).map((supplier) => (
                <TableRow key={supplier._id}>
                  <TableCell>{supplier?.uniqueId || "-"}</TableCell>
                  <TableCell>
                    {supplier?.supplier_company?.company_name}
                  </TableCell>

                  <TableCell>{supplier.country}</TableCell>
                  <TableCell>
                    {supplier?.main_contact?.first_name &&
                    supplier?.main_contact?.last_name
                      ? supplier?.main_contact?.first_name +
                        " " +
                        supplier?.main_contact?.last_name
                      : ""}
                  </TableCell>
                  <TableCell>{supplier.email}</TableCell>
                  <TableCell>
                    <Button
                      className={
                        "badge badge-" +
                        getStatusColor(supplier.status) +
                        " status"
                      }
                      variant="contained"
                    >
                      {supplier.status}
                    </Button>
                  </TableCell>
                  <TableCell>{supplier?.category?.name}</TableCell>
                  <TableCell>
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem
                                className="bold-line-600"
                                onClick={() => {
                                  setOpenDetailsPopup(true);
                                  setSelectedSupplier(supplier);
                                  popupState.close();
                                }}
                              >
                                View Suppliers Details
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                className="bold-line-600 clr-ff0000"
                                onClick={() => {
                                  DeleteItem(supplier);
                                  popupState.close();
                                }}
                              >
                                Delete
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {suppliersList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={suppliersList.length + " Suppliers Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <DetailsSlider
          supplier={selectedSupplier}
          openPopup={openDetailsPopup}
          setOpenPopup={setOpenDetailsPopup}
          getAllItemsByCompanyName={getAllSuppliers}
          setNotify={setNotify}
        />
        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          confirmDeleteProject={confirmDeleteSupplier}
        />
      </Paper>
    </>
  );
};

export default SuppliersTable;
