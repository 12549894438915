import { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Appbars from "../../components/Appbar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Copyright from "../../components/Copyright";
import ProjectBanner from "./components/ProjectBanner";
import ProjectTabContainer from "./projectDetail";
import { useLocation } from "react-router-dom";
import Notification from "../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { setHTTPError } from "../../store/actions/errorHandlerAction";
import { useHistory } from "react-router-dom";
import { Api } from "../../api/Api";
import { GET_COMPANY_PROJECT_API } from "../../constants/ApiUrl";

const ProjectDetails = () => {
  const UnAuthorizedRoute = useSelector(
    (state) => state.errorHandler.UnAuthorizedRoute
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  if (location && location.id) {
    localStorage.setItem("selectedProjectId", location.id);
  }

  const [selectedProject, setSelectedProject] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getProjectDetails = async () => {
    const projectId = localStorage.getItem("selectedProjectId");
    if (projectId) {
      const response = await Api(
        `${GET_COMPANY_PROJECT_API}/${projectId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let auctionData = response.data.project;
          setSelectedProject(auctionData);
        }
      } else {
        let message = response.data.responseDescription
          ? response.data.responseDescription
          : response?.message;
        if (response.status === 403) {
          dispatch(
            setHTTPError({
              code: 403,
              message: message,
            })
          );
          history.push(UnAuthorizedRoute);
        } else {
          dispatch(
            setHTTPError({
              code: response.status,
              message: message,
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Appbars> </Appbars>
      <main className="app-main-section-container">
        <Container maxWidth="lg">
          <ProjectBanner
            selectedProject={selectedProject}
            setNotify={setNotify}
          />
          <ProjectTabContainer
            selectedProject={selectedProject}
            setNotify={setNotify}
          />
          <Notification notify={notify} setNotify={setNotify} />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default ProjectDetails;
