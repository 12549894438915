import { Box, Container, CssBaseline, makeStyles } from '@material-ui/core';
import React from 'react'
import Copyright from '../../../components/Copyright';
import Appbars from "../../../components/Appbar";
import "../Styles/rfx.css"
import RfxSellerList from './RfxSellerList';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
const RfxSellerHome = () => {
  const classes = useStyles();

  return (
      <div style={{ display: "flex" }}>
      <CssBaseline />
      <Appbars> </Appbars>
      <main className="app-main-section-container">
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <RfxSellerList />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  )
}


export default RfxSellerHome