import { Grid } from "@material-ui/core";
import React from "react";
import RfxSellerDetailCard from "./RfxSellerDetailCard";
import RfxSellerItems from "./RfxSellerItems";
import RfxSellerBidCard from "./RfxSellerBidCard";

const RfxSellerGeneralInformationTab = ({getRfxDetails}) => {
  return (
    <Grid container spacing={4} className="supp-auction-details-container">
      <Grid item aria-label="general-info" md={6}>
        <RfxSellerDetailCard />
      </Grid>
      <Grid item aria-label="item-bid-list" md={12}>
        <RfxSellerItems getRfxDetails={getRfxDetails} />
      </Grid>
      <Grid item aria-label="live-bid" md={6}>
        <RfxSellerBidCard getRfxDetails={getRfxDetails} />
      </Grid>
    </Grid>
  );
};

export default RfxSellerGeneralInformationTab;
