import { Api } from "../../../../api/Api";
import { GET_ADMIN_USER_API } from "../../../../constants/ApiUrl";
import {
  SUPER_ADMIN_STATUS_ACTIVE,
  SUPER_ADMIN_STATUS_INACTIVE,
} from "../../../../constants/AppConstants";

const ToggleSuperAdminStatusBL = async (props) => {
  const { user } = props;

  let success = false;
  let message = "";

  try {
    let path = `${GET_ADMIN_USER_API}/toggleStatus/${user._id}`;
    const response = await Api(
      path,
      {
        status:
          user.status === SUPER_ADMIN_STATUS_INACTIVE
            ? SUPER_ADMIN_STATUS_ACTIVE
            : SUPER_ADMIN_STATUS_INACTIVE,
      },
      "post"
    );
    if (response.status === 200) {
      message = response.data.responseDescription;
      success = true;
    } else {
      message = response?.data?.responseDescription
        ? response?.data?.responseDescription
        : response?.message;
    }
  } catch (err) {}

  return {
    success: success,
    message: message,
  };
};

export default ToggleSuperAdminStatusBL;
