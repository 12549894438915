class SocketResponse {
  constructor(data = {}) {
    this.data = data;
    this.token = this.getCurrentToken();
  }

  getCurrentToken() {
    let userDetails = localStorage.getItem("userDetails");
    if (!userDetails) {
      return "";
    }
    userDetails = JSON.parse(userDetails);
    return userDetails?.token;
  }

  transform() {
    return {
      data: this.data,
      token: this.token,
    };
  }
}

export default SocketResponse;
