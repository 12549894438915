export const isAdmin = (state) => {
  if (state.auth && state.auth.auth && state.auth.auth.user && state.auth.auth.user.isAdmin) return true;
  return false;
};

export const isBuyer = (state) => {
  if (state.auth && state.auth.auth && state.auth.auth.user && state.auth.auth.user.isBuyer) return true;
  return false;
};

export const isSeller = (state) => {
  if (state.auth && state.auth.auth && state.auth.auth.user && state.auth.auth.user.isSeller) return true;
  return false;
};

export const isSuperAdmin = (state) => {
  if (state.auth && state.auth.auth && state.auth.auth.user && state.auth.auth.user.isSuperAdmin) return true;
  return false;
};

