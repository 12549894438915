import { Api } from "../../../api/Api";
import { GET_COMPANY_SUPPLIER_LIST_API } from "../../../constants/ApiUrl";

const GetAuctionSuppliersBL = async (props) => {
  const { currentAuction, page, rowsPerPage } = props;

  let list = [];
  let limit = 0;

  let auctionId = currentAuction._id;
  // let response = await axiosInstance.get(
  //   `api/buyer/company/auctionCompanySupplierList/${auctionId}?per_page=${rowsPerPage}&page=${page}`
  // );
  const response = await Api(
    `${GET_COMPANY_SUPPLIER_LIST_API}/${auctionId}?per_page=${rowsPerPage}&page=${page}`,
    {},
    "get"
  );
  if (response.status === 200) {
    if (response.data && response.data.suuplierItemList !== undefined) {
      list = response.data.suuplierItemList;
      limit = response.data.totalRecord ? response.data.totalRecord : 0;
    }
  }

  return {
    list: list,
    limit: limit,
  };
};

export default GetAuctionSuppliersBL;
