import {
  EMAIL_VERIFY,
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  SIGNUP_EXIST_ACTION,
  FORGOT_PASSWORD_CONFIRMED_ACTION,
  FORGOT_PASSWORD_FAILED_ACTION,
  UPDATE_PROFILE_DETAILS_ACTION,
  ROLE_UPDATE_ACTION,
  USER_UPDATE_ACTION,
} from "../constant/authConstant";

const initialState = {
  auth: {
    email: "",
    idToken: "",
    localId: "",
    expiresIn: "",
    refreshToken: "",
    user: {
      role: "",
      isAdmin: false,
      isSeller: false,
      isBuyer: false,
    },
  },
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_DETAILS_ACTION:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            image_upload: action.payload.image_upload,
          },
        },
        errorMessage: "",
        successCode: action.payload.responseCode,
        successMessage: "",
        showLoading: false,
      };

    case SIGNUP_CONFIRMED_ACTION:
      return {
        ...state,
        auth: action.payload,
        errorMessage: "",
        successCode: action.payload.responseCode,
        successMessage: "",
        showLoading: false,
      };

    case SIGNUP_EXIST_ACTION:
      return {
        ...state,
        auth: action.payload,
        errorMessage: "",
        successCode: action.payload.responseCode,
        successMessage: "",
        showLoading: false,
      };

    case LOGIN_CONFIRMED_ACTION:
      return {
        ...state,
        auth: action.payload,
        errorMessage: "",
        successMessage: "",
        showLoading: false,
      };

    case LOGIN_FAILED_ACTION:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
        showLoading: false,
      };

    case SIGNUP_FAILED_ACTION:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
        showLoading: false,
      };

    case LOGOUT_ACTION:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
        auth: {
          email: "",
          idToken: "",
          localId: "",
          expiresIn: "",
          refreshToken: "",
          user: {
            role: "",
            isAdmin: false,
            isSeller: false,
            isBuyer: false,
          },
        },
      };

    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };

    case EMAIL_VERIFY:
      return {
        ...state,
        emaiVerify: action.payload,
      };

    case FORGOT_PASSWORD_CONFIRMED_ACTION:
      return {
        ...state,
        auth: action.payload,
        errorMessage: "",
        successMessage: "",
        showLoading: false,
      };

    case FORGOT_PASSWORD_FAILED_ACTION:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
        showLoading: false,
      };
    case ROLE_UPDATE_ACTION:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            role: action.payload.role,
            isSeller: action.payload.isSeller,
            isBuyer: action.payload.isBuyer,
          },
        },

        errorMessage: "",
        successMessage: "",
        showLoading: false,
      };
    case USER_UPDATE_ACTION:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            image_upload: action?.payload?.image_upload,
          },
        },

        errorMessage: "",
        successMessage: "",
        showLoading: false,
      };
    default:
      return state;
  }
};
