import React, { useState, useEffect } from "react";
import { useForm, Form } from "../../../components/useForm";
import Controls from "../../../components/controls/Controls";
import {
   makeStyles,
   Grid,
   Box,
} from "@material-ui/core";
import { store } from "../../../store/store"
import axiosInstance from "../../../services/AxiosInstance";
const initialFValues = {
   email: "",
};
const useStyles = makeStyles((theme) => ({
   errMsg: {
      color: "red",
      fontSize: "14px",
      margin: "3px 0",
   },
   button: {
      margin: theme.spacing(1),
      borderRadius: "5em",
      padding: ".5rem 2rem",
      fontWeight: "700",
   },
   input: {
      display: "none",
   },
   Button: {
      padding: ".4rem 3rem",
      borderRadius: "6px",
      fontWeight: "700",
      fontSize: "14px",
   },

   modalContent: {
      width: "100%",
   }
}));

const ChangeEmail = (props) => {
   const { changeEmailPopup, setChangeEmailPopup, setNotify } = props;
   const classes = useStyles();
   const validate = (fieldValues = values) => {
      let temp = { ...errors };

      if ("company_name" in fieldValues)
         temp.company_name = fieldValues.company_name
            ? ""
            : "This field is required.";

      setErrors({
         ...temp,
      });

      if (fieldValues === values)
         return Object.values(temp).every((x) => x === "");
   };
   const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
      useForm(initialFValues, true, validate);
   const handleSubmit = (e) => {
      e.preventDefault();
      const { auth } = store.getState();
      const userId = auth?.auth?.user?._id;
      const paload = {
         "new_password": values.new_password
      }
      //   axiosInstance
      //   .patch(`api/users/changePassword/${userId}`, paload)
      //   .then((response) => {
      //      if (response.data.responseCode === "00") {
      //         setChangePasswordPopup(false)
      //         setNotify({
      //            isOpen: true,
      //            message: "Password changes successfully",
      //            type: "success",
      //         });
      //      } else {
      //         setNotify({
      //            isOpen: true,
      //            message: "Something goes wrong.",
      //            type: "success",
      //         });
      //      }
      //   }).catch((err) => {
      //      setNotify({
      //         isOpen: true,
      //         message: "Failed to update password",
      //         type: "error",
      //      });
      //   });
   }

   return (
      <>
         <Form onSubmit={handleSubmit}>
            <Grid container>
               <Grid item xs={12}>
                  <Controls.Input
                     name="email"
                     label="New Email"
                     fullWidth
                     value={values.email}
                     onChange={handleInputChange}
                     error={errors.email}
                     className="StepControlInput"
                  />
               </Grid>
            </Grid>
            <Box
               className="BottomModal"
               display="flex"
               p={1}
               bgcolor="background.paper"
            >
               <Grid istem xs={4} className="BottomModal-left">
                  <Box p={0} flexGrow={1}>
                     <Controls.Button
                        text="Cancel"
                        disableElevation
                        onClick={() => {
                           setChangeEmailPopup(false);
                        }}
                        style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                        color="default"
                        className={classes.Button}
                     />
                  </Box>
               </Grid>
               <Grid item xs={8} className="BottomModal-right">
                  <Box p={0}>
                     <Controls.Button
                        type="submit"
                        disableElevation
                        text="Save Change"
                        className={classes.Button}
                     />
                  </Box>
               </Grid>
            </Box>
         </Form>
      </>
   )

}

export default ChangeEmail;