import { Box, Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { InputLabel } from "@mui/material";
import { Form, useForm } from "../../../../components/useForm";
import { useDispatch } from "react-redux";
import Controls from "../../../../components/controls/Controls";
import { rfxDetails } from "../../../../store/actions/rfxAction";
import { useEffect } from "react";

const dropdownRfxOption = [
  { id: "RFI", title: "RFI (Request For Information)" },
  { id: "RFP", title: "RFP (Request For Proposal)" },
  { id: "RFQ", title: "RFQ (Request For Quotation)" },
];
const initialFValues = {
  project_id: "",
  rfx_type: "",
};

const InitialRfxCreateModal = ({
  openPopup,
  setOpenPopup,
  isBuyer,
  addFirstStepRFx,
  dropdownProject,
}) => {
  const dispatch = useDispatch();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("project_id" in fieldValues)
      temp.project_id = fieldValues.project_id ? "" : "Project is required.";

    if ("rfx_type" in fieldValues)
      temp.rfx_type = fieldValues.rfx_type ? "" : "Type is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate() && isBuyer) {
      dispatch(rfxDetails(values));
      addFirstStepRFx();
    }
  };
  useEffect(() => {
    resetForm();
  }, [openPopup]);
  return (
    <Modal
      open={openPopup}
      aria-label="create-auction-zero-step-buyer-aspect"
      aria-describedby="create-auction-zero-step-buyer-aspect-desc"
    >
      <Box className="popup-form-container">
        <Grid container>
          <Grid item xs={9}>
            <p className="profile-title"> Add RFX</p>
          </Grid>
          <Grid item xs={3} className="text-end">
            <CloseIcon
              onClick={() => {
                setOpenPopup(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        <Form onSubmit={handleSubmit} className="mt-5">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Project</InputLabel>
              <Controls.Select
                name="project_id"
                value={values.project_id}
                fullWidth
                onChange={handleInputChange}
                options={dropdownProject}
                error={errors.project_id}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>RFX Type</InputLabel>
              <Controls.Select
                name="rfx_type"
                value={values.rfx_type}
                fullWidth
                onChange={handleInputChange}
                options={dropdownRfxOption}
                error={errors.rfx_type}
              />
            </Grid>
          </Grid>
          <Box
            className="BottomModal"
            display="flex"
            justifyContent="center"
            p={1}
            bgcolor="background.paper"
          >
            <Grid item xs={6}>
              <Controls.Button
                type="submit"
                disableElevation
                text="Add RFX"
                className="btn-mui w-100"
              />
            </Grid>
          </Box>
        </Form>
      </Box>
    </Modal>
  );
};

export default InitialRfxCreateModal;
