import { Box, Grid, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CommonButton from "../../../components/controls/CommonButton";

const RfxSellerSubmitModal = ({
  showSubmitModal,
  setShowSubmitModal,
  finalBidSumitHandler,
}) => {
  return (
    <Modal open={showSubmitModal}>
      <Box className="popup-form-container rfx-submit-bid-modal">
        <Grid container justifyContent="end">
          <Grid item xs={3} className="text-end">
            <CloseIcon
              onClick={() => {
                setShowSubmitModal(false);
              }}
              className="cursor-p"
            />
          </Grid>
        </Grid>
        <div className="submit-bid-body">
          <div className="rfx-submit-icon">
            <UploadFileIcon className="rfx-submit-icon text-primary" />
          </div>
          <p className="submit-main-text text-bold text-black mt-3 text-center">
            "Are you sure you want to submit your proposal ?"
          </p>
          <p className="text-gray mt-3 mb-3">
            Once submitted, this cannot be undone
          </p>
          <div>
            <CommonButton
              text="Submit"
              onClick={() => finalBidSumitHandler()}
              className="btn-mui mb-2"
            />
          </div>
          <div>
            <CommonButton
              text="Cancel"
              onClick={() => setShowSubmitModal(false)}
              className="btn-mui submit-cancel-btn"
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RfxSellerSubmitModal;
