import { Api } from "../../../api/Api";
import { GET_ALL_BID_API } from "../../../constants/ApiUrl";

const GetAllAuctionBidsBL = async (props) => {
  const { currentAuction, page, rowsPerPage, supplierId = -1 } = props;

  let list = [];
  let limit = 0;


    let auctionId = currentAuction._id;

    const response = await Api(
      `${GET_ALL_BID_API}/${auctionId}?per_page=${rowsPerPage}&page=${page}&supplier_id=${supplierId}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data && response.data.allBid !== undefined) {
        list = response.data.allBid;
        limit = response.data.totalRecord;
      }
    }

    // let response = await axiosInstance.get(
    //   `api/buyer/getAllBidByAuction/${auctionId}?per_page=${rowsPerPage}&page=${page}&supplier_id=${supplierId}`
    // );

  return {
    list: list,
    limit: limit,
  };
};

export default GetAllAuctionBidsBL;
