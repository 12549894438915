export const getCurrentRole = (state) => {
  if (state.authorization) {
   
    if (state.authorization.selectedRole === "") {
      const tokenDetailsString = localStorage.getItem("userDetails");
      let tokenDetails = "";
      if (!tokenDetailsString) {
        return "";
      }

      tokenDetails = JSON.parse(tokenDetailsString);
      if (!tokenDetails.user) {
        state.authorization.selectedRole = "guest";
        return "guest";
      }

      const canToggle =
        !tokenDetails.user.isSuperAdmin &&
        tokenDetails.user.isSeller &&
        tokenDetails.user.isBuyer;
      let defRole = canToggle
        ? "buyer"
        : tokenDetails.user && tokenDetails.user.isSuperAdmin === false
        ? tokenDetails.user.isSeller
          ? "supplier"
          : "buyer"
        : "super_admin";

      state.authorization.selectedRole = defRole;
      return defRole;
    }
    if (state.authorization.selectedRole === "buyer") {
      return state.authorization.selectedRole;
    }
    return state.authorization.selectedRole;
  }

  return "";
};
