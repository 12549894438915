/*eslint-disable*/
import { Box, Button, Grid, Paper } from "@mui/material";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { getCurrentRole } from "../../../store/selectors/AuthorSelector";
import {
  isAdmin,
  isBuyer,
  isSeller,
} from "../../../store/selectors/RoleSelector";
import { useEffect } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import AuctionsBgImg from "../../../assets/Auctions@2x.png";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import GetAllProjectsBL from "../BL/GetAllProjectsBL";
import moment from "moment";

const columns = [
  {
    label: "Name", // project_name
  },
  {
    label: "Manager", // project_manager
  },
  {
    label: "Owner", // ownerName.first_name , ownerName.last_name
    minWidth: 170,
  },
  {
    label: "Start Date", // startDate
    minWidth: 170,
  },
  {
    label: "End Date", // endDate
  },
];

const RecentProjects = (props) => {
  const { resendApi, setNotify, currentUser } = props;
  const [projects, setProjects] = useState([]);
  const [numOfPages, setNumOfPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const history = useHistory();

  useEffect(() => {
    if (resendApi == true) {
      GetAllProjectsBL({
        companyId: currentUser.company._id,
        rowsPerPage: rowsPerPage,
      })
        .then((projects) => {
          setProjects(projects);
          // setNumOfPages(Math.ceil(projects.length / rowsPerPage));
          // setLimit(projects.length);
        })
        .catch((error) => {});
    }
  }, [resendApi]);

  const navigateToProjectDetails = (project) => {
    if (project) {
      history.push({
        pathname: "/project/details",
        id: project._id,
      });
    }
  };

  return (
    <>
      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Paper elevation={0} square>
            <Grid container style={{ marginTop: "1em" }}>
              <Grid item container xs={12} style={{ alignItems: "center" }}>
                <Grid item xs={12}>
                  <h1 className="item-container">Top recent projects</h1>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer
              className="table-container supplier-aspect"
              component={Paper}
              aria-label="list-all-project-table-supplier-aspect"
            >
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: "700" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects.map((project) => (
                    // <TableRow key={project._id}>
                    <TableRow
                      key={project._id}
                      className="cursor-p"
                      onDoubleClick={() => navigateToProjectDetails(project)}
                    >
                      <TableCell>{project?.project_name}</TableCell>
                      <TableCell>{project?.project_manager}</TableCell>
                      <TableCell>
                        {project?.ownerName?.first_name +
                          " " +
                          project?.ownerName?.last_name}
                      </TableCell>
                      <TableCell>
                        {moment(project?.startDate).format("MMM Do YY")}
                      </TableCell>
                      <TableCell>
                        {moment(project?.endDate).format("MMM Do YY")}
                      </TableCell>
                    </TableRow>
                  ))}
                  {projects.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="text-center"
                      >
                        No records found <br />
                        {/* <i style={{ color: "var(---2170ff-p-)" }}>
                          Please add some project
                        </i> */}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
              {/* <TablePaginationBox
                label={limit + " Auctions Found"}
                numOfPages={numOfPages}
                onChangePage={(event, newPage) => setPage(newPage)}
                page={page}
              /> */}
            </TableContainer>
          </Paper>
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(RecentProjects));
