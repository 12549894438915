import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import { useForm, Form } from "../../../components/useForm";
import { useEffect } from "react";
import { store } from "../../../store/store";
import * as employeeService from "../../../services/employeeService";
import Moment from "moment";
import "../../Auction/styles/filter-box.css";
import { Api } from "../../../api/Api";
import { ONLY_COMAPNY_API, PROJECT_LIST_API } from "../../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_CANCELLED,
  AUCTION_COMPLETED,
  AUCTION_DRAFT,
  AUCTION_ENDED_CAPITAL,
  AUCTION_PUBLISHED,
  AUCTION_RUNNING,
} from "../../../constants/AppConstants";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const initialValues = {
  date_range: "",
  status: "",
  project: "",
  owner: "",
  bider: "",
  currency: "",
};

export default function RfxFilter(props) {
  const {
    setAuctionList,
    setShowFilterCategories,
    setResendApi,
    setPage,
    setFilters,
  } = props;

  const [dateRange, setDateRange] = React.useState([null, null]);
  const [dropdownProject, setDropdownProject] = React.useState([]);
  const [dropdownOwner, setDropdownOwner] = React.useState([]);
  const [currencyList, setCurrencyList] = React.useState([]);
  const [messageLabel, setMessageLabel] = React.useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (dateRange && dateRange.length !== 0) {
      if (dateRange && dateRange[0] && dateRange[1]) {
        values.dateRange = [
          Moment(dateRange[0]).format("YYYY-MM-DD 00:00:00"),
          Moment(dateRange[1]).format("YYYY-MM-DD 00:00:00"),
        ];
      }
    }

    values.openMessage = messageLabel;
    localStorage.setItem("rfxFilter", JSON.stringify(values));
    setShowFilterCategories(false);
    setFilters(values);
    setPage(1);
    setResendApi(true);
  };

  const filterClear = async () => {
    resetForm();
    setFilters(null);
    setDateRange([null, null]);
    localStorage.removeItem("rfxFilter");
    setPage(1);
    setResendApi(true);
    setShowFilterCategories(false);
    setMessageLabel(false);
  };

  const getProjectList = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(`${PROJECT_LIST_API}/${companyId}`, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allProject = response.data.projectData;
        setDropdownProject(allProject);
      } else {
        setDropdownProject([]);
      }
    } else {
      setDropdownProject([]);
    }
  };

  const companyUserList = async () => {
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;
    const response = await Api(
      `${ONLY_COMAPNY_API}/${companyId}/companyUserList`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allMembers = response.data.companyTeamMembers;
        setDropdownOwner(
          allMembers.map((owner) => {
            return {
              name: owner?.first_name + " " + owner?.last_name,
              _id: owner._id,
            };
          })
        );
      }
    } else {
      setDropdownOwner([]);
    }
  };

  const initCurrencyList = () => {
    setCurrencyList(
      employeeService.currencyList().map((currency) => {
        return {
          name: currency.name,
          _id: currency.code,
        };
      })
    );
  };

  useEffect(() => {
    getProjectList();
    companyUserList();
    initCurrencyList();
    const filterData = localStorage.getItem("rfxFilter");

    if (filterData) {
      const filterObj = JSON.parse(filterData);
      setValues(filterObj);
      if (filterObj && filterObj.dateRange) {
        setDateRange(filterObj.dateRange);
      }
    }
  }, []);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        id="auction-list-filter-box-supplier-aspect"
      >
        <Grid container spacing={2} className="filter-field-container">
          <Grid item md={4} sm={6} xs={12} className="date-range-container">
            <InputLabel>Start Date - End Date</InputLabel>
            <Controls.DateRangePicker
              startProps={{
                name: "date_range",
              }}
              lable="Start Date - End Date"
              onChange={setDateRange}
              value={dateRange}
              inpClassName="ml-0 mr-0"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel>Project</InputLabel>
            <Controls.Select
              name="project"
              value={values.project}
              onChange={handleInputChange}
              options={dropdownProject}
              fullWidth
              className="w-100 ml-0 b-600"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel>Owner</InputLabel>
            <Controls.Select
              name="owner"
              value={values.owner}
              onChange={handleInputChange}
              options={dropdownOwner}
              fullWidth
              className="w-100 ml-0 b-600"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel id="bider-input">Best Bidder</InputLabel>
            <Controls.Input
              name="bider"
              id="bider-input-box"
              fullWidth
              value={values.bider}
              onChange={handleInputChange}
              error={errors.name}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              className="w-100 ml-0 b-600"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel>Status</InputLabel>
            <Controls.Select
              name="status"
              value={values.status}
              onChange={handleInputChange}
              options={[
                { name: AUCTION_DRAFT, _id: AUCTION_DRAFT },
                { name: AUCTION_ACTIVE, _id: AUCTION_ACTIVE },
                // { name: "Active", _id: "Active" },
                { name: AUCTION_RUNNING, _id: AUCTION_RUNNING },
                { name: AUCTION_COMPLETED, _id: AUCTION_COMPLETED },
                {
                  name: AUCTION_ARCHIEVED_CAPITAL,
                  _id: AUCTION_ARCHIEVED_CAPITAL,
                },
                { name: AUCTION_CANCELLED, _id: AUCTION_CANCELLED },
              ]}
              fullWidth
              className="w-100 ml-0 b-600"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel id="currency-input">Currency</InputLabel>
            <Controls.Select
              name="currency"
              value={values.currency}
              onChange={handleInputChange}
              options={currencyList}
              fullWidth
              className="w-100 ml-0 b-600"
            />
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={4} sm={6} xs={12}>
              <div className="ml-2">
                <Box display="flex">
                  <div
                    className={
                      "checkbox checkbox-primary-outline " +
                      (messageLabel ? "checked" : "")
                    }
                    onClick={() => {
                      setMessageLabel(!messageLabel);
                    }}
                  >
                    <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                    <CheckIcon aria-label="check-mark" />
                  </div>
                  <div style={{ paddingTop: "3px" }}>
                    <span className="label">Open Message</span>
                  </div>
                </Box>
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item sm={6} xs={12}>
                  <Controls.Button
                    type="submit"
                    disableElevation
                    text="Apply Filter"
                    style={{ width: "100%", margin: "auto" }}
                    className="btn-mui"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controls.Button
                    text="Clear"
                    disableElevation
                    color="default"
                    onClick={() => filterClear()}
                    style={{ width: "100%", margin: "auto" }}
                    className="btn btn-mui draft"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}
