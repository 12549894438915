import { Paper } from "@material-ui/core";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentAuction } from "../../../../../store/selectors/supplierAspect/auctionSelector";
import "../../styles/details-general-info.css";
import ReadMoreText from "../../../../../components/readmoretext/ReadMoreText";

const GeneralInfo = (props) => {
  const { currentAuction } = props;
  

  const formatStartDate = () => {
    return currentAuction && currentAuction.auctionId
      ? new Date(currentAuction?.auctionId?.startDate).toUTCString()
      : "---";
  };

  const formatDeliveryDate = () => {
    const date = new Date(
      currentAuction?.auctionId?.deliveryDate ||
        currentAuction?.auctionId?.deliveryDateTS
    );
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");

    return `${month}/${day}/${year}`;
  };

  return (
    <Paper>
      <div className="details-form-container mui-card supp-auction-details-general-info-container">
        <h3 className="mt-0 mb-4" style={{ fontSize: "1.3em" }}>
          Auction Details
        </h3>
        <div className="mui-label"> Auction ID</div>
        <h3 className="mt-0 mb-3">{currentAuction?.auctionId?.auctionId}</h3>
        <div className="mui-label"> Start On</div>
        <h3 className="mt-0 mb-3">{formatStartDate()}</h3>

        {(currentAuction?.auctionId?.deliveryDate ||
          currentAuction?.auctionId?.deliveryDateTS) && (
          <>
            <div className="mui-label">Delivery Date</div>
            <h3 className="mt-0 mb-2">{formatDeliveryDate()}</h3>
          </>
        )}

        <div className="mui-label">Customer</div>
        <h3 className="mt-0 mb-2">{currentAuction?.company_buyer_name}</h3>
        <div className="mui-label">Award commitment</div>
        <h3 className="mt-0 mb-2">
          {currentAuction?.auctionId?.awarding_commitment ? "Yes" : "No"}
        </h3>
        <div className="mui-label">Buyer</div>
        <h3 className="mt-0 mb-2">{currentAuction?.company_buyer_name}</h3>
        <div className="mui-label">Attachments</div>
        <div className="file-name-container">
          {currentAuction?.auctionId?.documents &&
            currentAuction?.auctionId?.documents.map((document) => (
              <div key={document?._id} className="d-flex">
                <div className="icon">
                  <FilePresentOutlinedIcon />
                </div>
                <div className="name">
                  <a
                    href={document?.fileName}
                    target="_blank"
                    style={{ color: "var(---2170ff-p-)" }}
                  >
                    {document?.name}
                  </a>
                </div>
              </div>
            ))}
        </div>
        <div className="file-name-container">
          {currentAuction?.auctionId?.links &&
            currentAuction?.auctionId?.links.map((link) => (
              <div key={link?._id} className="d-flex">
                <div className="icon">
                  <LinkOutlinedIcon />
                </div>
                <div className="name">
                  <a
                    href={link?.link}
                    target="_blank"
                    style={{ color: "var(---2170ff-p-)" }}
                  >
                    {link?.title}
                  </a>
                </div>
              </div>
            ))}
        </div>
        <div className="mui-label mt-5">Description</div>
        <h3
          className="mt-0 show-wrap-text mw600 eight-line"
          style={{ fontSize: "1em" }}
        >
          <ReadMoreText text={currentAuction?.auctionId?.description} />
        </h3>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuction: getCurrentAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(GeneralInfo));
