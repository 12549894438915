import * as React from "react";
import { Box, Grid } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { Button, InputLabel, Menu } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import AuctionBlueImage from "../../../assets/icons/Auctions-Blue@2x.png";
import { HtmlUtilService } from "../../../services/HtmlUtilService";
import userImage from "../../../assets/auth/user_icon_2.png";
import axiosInstance from "../../../services/AxiosInstance";
import countries from "../../../assets/countries.json";
import "../styles/supplier-list-info-details.css";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../components/useWindowDimensions";
import * as employeeService from "../../../services/employeeService";
import { Api } from "../../../api/Api";
import { GET_SELLER_SUPPLIER_DETAIL_API } from "../../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_INACTIVE_CAP_SMALL,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
} from "../../../constants/AppConstants";

const getStatusColor = (status) => {
  switch (status) {
    case AUCTION_ACTIVE:
      return SUCCESS_BACKGROUND;
    case AUCTION_INACTIVE_CAP_SMALL:
      return SECONDARY_BACKGROUND;
    default:
      return SECONDARY_BACKGROUND;
  }
};

export default function SupplierListDetailsCompanyProp(props) {
  const { supplier } = props;
  const { smScreen, xsScreen } = useWindowDimensions();
  const [supplierDetail, setSupplierDetail] = React.useState({});

  const getSupplierDetails = async () => {
    if (supplier && supplier?._id) {
      const response = await Api(
        `${GET_SELLER_SUPPLIER_DETAIL_API}/${supplier?._id}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let details = response.data.company;
          setSupplierDetail(details);
        } else {
          setSupplierDetail({});
        }
      } else {
        setSupplierDetail({});
      }

      // await axiosInstance
      //   .get(`api/seller/supplierDetails/${supplier?._id}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let details = response.data.company;
      //       setSupplierDetail(details);
      //     } else {
      //       setSupplierDetail({});
      //     }
      //   })
      //   .catch((err) => {
      //     setSupplierDetail({});
      //   });
    }
  };

  const getCountryName = (code) => {
    const countryName = countries.find((element) => element.code === code);
    return countryName ? countryName.name : "-";
  };

  const getCompanyType = (id) => {
    const companyType = employeeService
      .companyType()
      .find((element) => element.id === id);
    return companyType ? companyType.name : "-";
  };

  const getCompanyEmployee = (id) => {
    const companyEmployee = employeeService
      .companyNoOfEmployee()
      .find((element) => element.id === id);
    return companyEmployee ? companyEmployee.name : "-";
  };

  const getCompanyAnnualRevenue = (id) => {
    const companyRevenue = employeeService
      .companyAnnualRevenue()
      .find((element) => element.id === id);
    return companyRevenue ? companyRevenue.name : "-";
  };

  React.useEffect(() => {}, []);
  React.useEffect(() => {
    getSupplierDetails();
    HtmlUtilService.truncate();
  }, [supplier]);

  return supplier && supplier.status ? (
    <Grid container className="supplier-list-details-info-container">
      <Grid item xs={12}>
        <Grid container>
          <Grid item md={8} xs={12}>
            <Grid container spacing={2} alignItems="center">
              {supplierDetail?.supplier_company?.image_upload &&
                supplierDetail?.supplier_company?.image_upload.length > 0 && (
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    className={!smScreen ? "" : !xsScreen ? "text-end" : ""}
                  >
                    <img
                      src={supplierDetail?.supplier_company?.image_upload}
                      style={{
                        width: "74px",
                        height: "74px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </Grid>
                )}
              <Grid
                item
                sm={6}
                xs={12}
                className={`show-wrap-text lineheight28 ${!smScreen ? "text-center" : ""}`}
              >
                <h3>{supplier?.supplier_company?.company_name}</h3>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <Grid container className="text-end">
              <Grid item xs={12}>
                <h4>{supplier?.uniqueId || "-"}</h4>
              </Grid>
              <Grid item xs={12}>
                <Box m={2} className="table-container">
                  <PopupState
                    variant="popover"
                    popupId="supplier-list-info-details-status"
                  >
                    {(popupState) => (
                      <div>
                        <Button
                          className={
                            "badge badge-" +
                            getStatusColor(supplier.status) +
                            " status"
                          }
                          variant="contained"
                          {...bindTrigger(popupState)}
                        >
                          {supplier.status}
                        </Button>
                      </div>
                    )}
                  </PopupState>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className="mt-5">
          <Grid item md={4} xs={12}>
            <Box display="flex" alignItems="center" className="contact">
              <LanguageIcon className="icon" />
              <Link
                color="primary"
                to={{ pathname: "#" }}
                className="link b-600"
              >
                {supplierDetail?.supplier_company?.website || "-"}
              </Link>
            </Box>
            <Box display="flex" alignItems="center" className="contact">
              <MailOutlineIcon className="icon" />
              <Link
                color="primary"
                to={{ pathname: "#" }}
                className="link b-600"
              >
                {supplierDetail?.supplier_company?.email || "-"}
              </Link>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <h4>About us</h4>

            <div truncate="25">
              <span className="show-wrap-text mw550 b-600">
                {supplierDetail?.supplier_company?.summary || "-"}
              </span>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className="mt-5" spacing={2}>
          <Grid item sm={4} xs={12}>
            <InputLabel>Location</InputLabel>
            <p className="font-13 b-600 show-wrap-text">
              {supplierDetail?.supplier_company?.address || "-"}
              {/* <br /> PO Box{" "}
              {supplierDetail?.supplier_company?.pobox || "-"} */}
            </p>
            <p className="font-13 b-600 show-wrap-text">
              PO Box {supplierDetail?.supplier_company?.pobox || "-"}
            </p>
            <br />
            <p className="font-13 b-600 show-wrap-text">
              {getCountryName(supplierDetail?.supplier_company?.country)}
            </p>
          </Grid>
          <Grid item sm={8} xs={12}>
            <Grid container justifyContent="space-between" spacing={2}>
              {/* <Grid item md={4} xs={12}>
                <InputLabel>Company type</InputLabel>
                <p className="font-13 b-600 show-wrap-text">
                  {getCompanyType(
                    supplierDetail?.supplier_company?.company_type
                  )}
                </p>
              </Grid> */}
              <Grid item md={6} xs={12}>
                <InputLabel>Employees</InputLabel>
                <p className="font-13 b-600 show-wrap-text">
                  {getCompanyEmployee(
                    supplierDetail?.supplier_company?.no_of_employees
                  )}
                </p>
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel>Annual revenue</InputLabel>
                <p className="font-13 b-600 show-wrap-text">
                  {getCompanyAnnualRevenue(
                    supplierDetail?.supplier_company?.annual_revenue
                  )}
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              className="mt-2"
            >
              <Grid item md={6} xs={12}>
                <InputLabel>Capabilities</InputLabel>
                <p className="font-13 b-600 mt-1 show-wrap-text">
                  {supplierDetail?.supplier_company?.capabilities || "-"}
                </p>
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel>Additional Activities</InputLabel>
                <p className="font-13 b-600 mt-1 show-wrap-text">
                  {supplierDetail?.supplier_company &&
                  supplierDetail?.supplier_company?.additional_activities
                    .length > 0
                    ? supplierDetail?.supplier_company?.additional_activities.map(
                        (item) => (
                          <span>
                            * {item} <br />
                          </span>
                        )
                      )
                    : "-"}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}
