import "./Loader.css";

export default function Loader(props) {
  return (
    <>
      <div className="loading-progress-spinner-container">
        <div className="overlay"></div>
        <div class="loadingio-spinner-spinner-box absolute">
          <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
