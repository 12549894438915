import {
  CONFIRMED_GET_PROJECTS,
  ADD_FIRST_FORM,
  PROJECTS_ADD_REQUEST,
  GET_COMPANY_ITEM,
  REQUEST_FORM_TWO,
  REQUEST_FORM_TWO_DATA_SAVE,
  ADD_PROJECT_DRAFT_SAVE_STATUS,
  CLEAR_ITEM_ON_SAVE,
  CLEAR_ITEM_ON_SAVE_DOCS,
} from "../constant/itemConstants";
import { Api } from "../../api/Api";
import { POST_ITEM_DRAFT_API } from "../../constants/ApiUrl";

export const addFirstForm = (values, draftStatus) => {
  return async (dispatch, getState) => {
    let formData = new FormData();
    const {
      auth: { auth },
      itemss: { ItemsFirstForm },
    } = getState();
    let finalRes;

    if (draftStatus.isSaveAsDraft) {
      formData.append("item_name", values.item_name);
      formData.append(
        "itemId",
        "R" + Math.floor(100000 + Math.random() * 900000)
      );
      if (values?.manufacturer && values?.manufacturer.length > 0)
        formData.append("manufacturer", values.manufacturer);
      if (values?.notes && values?.notes.length > 0)
        formData.append("notes", values.notes);
      formData.append("unit", values.unit);
      if (values?.category?.length > 0)
        formData.append("category", values.category);
      if (values?.model && values?.model.length > 0)
        formData.append("model", values.model);
      if (values?.group?.length > 0) formData.append("group", values.group);
      if (values?.tags?.length > 0) formData.append("tagss", values.tags);
      formData.append("status", "Inactive");
      formData.append("company_name", auth?.user?.company?.company_name);
      formData.append("companyId", auth?.user?.company?._id);
      formData.append("userId", auth.user._id);
      const response = await Api(
        POST_ITEM_DRAFT_API,
        formData,
        "postMultipart"
      );
      finalRes = response;
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          //  let addedProjects = response.data;
          // dispatch({
          //   type: PROJECTS_ADD_REQUEST,
          // });
          // dispatch({
          //   type: ADD_FIRST_FORM,
          //   payload: response.data,
          // });
          dispatch({
            type: ADD_PROJECT_DRAFT_SAVE_STATUS,
            payload: true,
          });
          dispatch({
            type: CLEAR_ITEM_ON_SAVE,
            payload: {},
          });
          dispatch({
            type: CLEAR_ITEM_ON_SAVE_DOCS,
            payload: {},
          });
        } else {
          dispatch({
            type: ADD_PROJECT_DRAFT_SAVE_STATUS,
            payload: false,
          });
        }
      } else {
        dispatch({
          type: ADD_PROJECT_DRAFT_SAVE_STATUS,
          payload: false,
        });
      }

      // await axiosInstance
      //   .post(`api/buyer/item/saveItemAsDraft`, formData)
      //   .then((response) => {
      //     finalRes = response;
      //     if (response.data.responseCode === "00") {
      //       //  let addedProjects = response.data;
      //       // dispatch({
      //       //   type: PROJECTS_ADD_REQUEST,
      //       // });
      //       // dispatch({
      //       //   type: ADD_FIRST_FORM,
      //       //   payload: response.data,
      //       // });
      //       dispatch({
      //         type: ADD_PROJECT_DRAFT_SAVE_STATUS,
      //         payload: true,
      //       });
      //       dispatch({
      //         type: CLEAR_ITEM_ON_SAVE,
      //         payload: {},
      //       });
      //       dispatch({
      //         type: CLEAR_ITEM_ON_SAVE_DOCS,
      //         payload: {},
      //       });
      //     } else {
      //       dispatch({
      //         type: ADD_PROJECT_DRAFT_SAVE_STATUS,
      //         payload: false,
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     dispatch({
      //       type: ADD_PROJECT_DRAFT_SAVE_STATUS,
      //       payload: false,
      //     });
      //   });
    } else {
      let formFirstData = values;
      if (ItemsFirstForm && ItemsFirstForm.tempForm2) {
        formFirstData = Object.assign(ItemsFirstForm.tempForm2, values);
      }
      dispatch({
        type: PROJECTS_ADD_REQUEST,
      });
      dispatch({
        type: ADD_FIRST_FORM,
        payload: formFirstData,
      });
    }

    return finalRes;
  };
};
export const saveSecondFormDataTemp = (values) => (dispatch, getState) => {
  const {
    itemss: { ItemsFirstForm },
  } = getState();
  const projectFormsValues = Object.assign(ItemsFirstForm, values);

  dispatch({
    type: REQUEST_FORM_TWO,
  });
  dispatch({
    type: REQUEST_FORM_TWO_DATA_SAVE,
    payload: projectFormsValues,
  });
};

// export const addSecondForm = (values) => (dispatch, getState) => {
//   const {
//     Items: { ItemsFirstForm },
//     auth: { auth },
//   } = getState();

//   const ItemFormsValues = Object.assign(ItemsFirstForm, values);
//   ItemFormsValues.userId = auth.user._id;
//   ItemFormsValues.unit = "unit";
//   // projectFormsValues.image = image;

//   axiosInstance
//     .post(`api/buyer/addProject`, ItemFormsValues)
//     .then((response) => {
//       if (response.data.responseCode === "00") {
//         //  let addedItems = response.data;
//         dispatch({
//           type: ADD_SECOND_FORM,
//           payload: ItemFormsValues,
//         });
//       }
//     })
//   // .catch((err) => {
//   //   dispatch({ type: GET_ItemS_FAIL });
//   // });

//   //  dispatch({
//   //   type: ADD_SECOND_FORM,
//   //   payload: ItemFormsValues,
//   // });
// };

export function confirmedGetItemAction(allItems) {
  return {
    type: CONFIRMED_GET_PROJECTS,
    payload: allItems,
  };
}

export function setAllItemListByCompanyName(items) {
  return {
    type: GET_COMPANY_ITEM,
    payload: items,
  };
}
