import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
import DesktopDateRangePicker from "@mui/lab/DesktopDateRangePicker";
import { InputAdornment } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
export default function BasicDateRangePicker(props) {
  const { label, inputProps, startProps, value, onChange, ...other } = props;
  const [InpClassName, setInpClassName] = React.useState("mui-input ");

  React.useEffect(() => {
    if (other && other.inpClassName) {
      setInpClassName(InpClassName + other.inpClassName);
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDateRangePicker
          calendars={1}
          startText=""
          endText=""
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          PopperProps={{
            placement: "bottom",
          }}
          renderInput={({ inputProps, ...startProps }, endProps) => {
            const startValue = inputProps.value;
            delete inputProps.value;
            return (
              <TextField
                className={InpClassName}
                style={{ width: "100%" }}
                {...startProps}
                inputProps={inputProps}
                value={`${startValue} - ${endProps.inputProps.value}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday style={{ color: "#2170ff" }} />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
          {...other}
        />
      </Stack>
    </LocalizationProvider>
  );
}
