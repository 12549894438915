import { Box, Button, Grid, Paper } from "@mui/material";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { getCurrentRole } from "../../../store/selectors/AuthorSelector";
import {
  isAdmin,
  isBuyer,
  isSeller,
} from "../../../store/selectors/RoleSelector";
import { useEffect } from "react";
import { useState } from "react";
import TablePaginationBox from "../../../components/TablePaginationBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import AuctionsBgImg from "../../../assets/Auctions@2x.png";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import Moment from "moment";
import "../styles/auction-table.css";
import GetAllAuctionBL from "../../Auction/BL/GetAllAuctionBL";
import { thousandSepratorHandler } from "../../../utility/utils";
import {
  AUCTION_ACCEPTED,
  AUCTION_ACTIVE,
  AUCTION_DECLINED,
  AUCTION_ENDED,
  AUCTION_INVITED,
  AUCTION_LOST,
  AUCTION_ON_CANCELLED,
  AUCTION_ON_HOLD,
  AUCTION_PUBLISHED,
  AUCTION_RUNNING,
  AUCTION_WON,
  BLUE_BACKGROUND,
  BLUE_HEX_COLOUR_CODE,
  DANGER_BACKGROUND,
  DANGER_OUTLINE_BACKGROUND,
  DISABELED_BACKGROUND,
  GREEN_BACKGROUND,
  GREEN_HEX_COLOUR_CODE,
  PRIMARY_BACKGROUND,
  PRIMARY_OUTLINE_BACKGROUND,
  PURPLE_BACKGROUND,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  TYPE_ONE,
  TYPE_TWO,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../constants/AppConstants";

const columns = [
  {
    label: "ID",
  },
  {
    label: "Type",
  },
  {
    label: "Auction Name",
  },
  {
    label: "Start Date",
  },
  {
    label: "Duration",
  },
  {
    label: "Best Bid AED",
  },
  {
    label: "Best Bidder",
  },
  {
    label: "Confirmed/Invited",
  },
  {
    label: "Status",
  },
  {
    label: "Project Buyer",
  },
  // {
  //   label: "",
  // },
];

const LiveAuction = (props) => {
  const { resendApi, setNotify, currentUser } = props;
  const [auctions, setAuctions] = useState([]);
  const [numOfPages, setNumOfPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const history = useHistory();

  useEffect(() => {
    if (resendApi === true) {
      GetAllAuctionBL({
        companyId: currentUser?.company._id,
        filters: {
          status: AUCTION_RUNNING,
        },
      })
        .then((data) => {
          setAuctions(data.list);
          setNumOfPages(Math.ceil(data.list.length / rowsPerPage));
          setLimit(data.list.length);
        })
        .catch((error) => {});
    }
  }, [resendApi]);

  const navigateToAuctionDetails = async (auction) => {
    history.push("/auction/" + auction._id + "/details");
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case TYPE_ONE:
        return BLUE_BACKGROUND;
      case TYPE_TWO:
        return GREEN_BACKGROUND;
      default:
        return DISABELED_BACKGROUND;
    }
  };

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ENDED:
        return SECONDARY_BACKGROUND;
      case AUCTION_ACCEPTED:
        return SUCCESS_BACKGROUND;
      case AUCTION_WON:
        return SUCCESS_BACKGROUND;
      case AUCTION_PUBLISHED:
        return PRIMARY_BACKGROUND;
      case AUCTION_DECLINED:
        return WARNING_OUTLINE_BACKGROUND;
      case AUCTION_ON_HOLD:
        return PRIMARY_OUTLINE_BACKGROUND;
      case AUCTION_ON_CANCELLED:
        return DANGER_BACKGROUND;
      case AUCTION_LOST:
        return DANGER_OUTLINE_BACKGROUND;
      case AUCTION_INVITED:
        return PURPLE_BACKGROUND;
      default:
        return SECONDARY_BACKGROUND;
    }
  };

  const getColor = (status) => {
    if (status === AUCTION_ACTIVE) {
      return BLUE_HEX_COLOUR_CODE;
    } else {
      return GREEN_HEX_COLOUR_CODE;
    }
  };

  return (
    <>
      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Paper elevation={0} square>
            <Grid container style={{ marginTop: "1em" }}>
              <Grid item container xs={12} style={{ alignItems: "center" }}>
                <Grid item xs={12}>
                  <h1 className="item-container">Live events</h1>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer
              className="table-container supplier-aspect"
              component={Paper}
              aria-label="list-all-auction-table-supplier-aspect"
            >
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: "700" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? auctions.slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage
                      )
                    : auctions
                  ).map((auction) => (
                    <TableRow
                      key={auction._id}
                      className="cursor-p"
                      onDoubleClick={() => navigateToAuctionDetails(auction)}
                    >
                      <TableCell>{auction.auctionId}</TableCell>
                      <TableCell>
                        <div
                          className={
                            "aution-img-container " +
                            getTypeLabel(auction.auction_type)
                          }
                        >
                          <img src={AuctionsBgImg} alt="" />
                        </div>
                      </TableCell>
                      <TableCell className="auction-title">
                        <h3> {auction.auction_name} </h3>
                        <p> {auction?.projectId?.project_name} </p>
                      </TableCell>
                      <TableCell className="auction-datetime">
                        {/* {auction?.startDate} */}
                        {Moment(auction?.startDate).format("DD/MM/YYYY LT")}
                      </TableCell>
                      <TableCell>{auction?.timeRemaining}</TableCell>
                      <TableCell style={{ color: "#E0A800" }}>
                        {auction?.bestBid?.bid
                          ? thousandSepratorHandler(auction?.bestBid?.bid)
                          : "-"}
                        {auction?.bestBid?.bid ? auction?.currency : ""}
                      </TableCell>
                      <TableCell style={{ color: getColor(auction?.status) }}>
                        {auction?.bestBid?.supplierId?.company_name || "-"}
                      </TableCell>
                      {/* <TableCell>{auction.confirmedInv || "-"}</TableCell> */}
                      <TableCell>
                        {auction?.totalAccepted}/{auction?.totalInvited}
                      </TableCell>
                      <TableCell>
                        <Button
                          className={
                            "badge badge-" +
                            getStatusLabel(auction.status) +
                            " status"
                          }
                          variant="contained"
                        >
                          {auction.status}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {auction?.projectId?.createdBy?.first_name}{" "}
                        {auction?.projectId?.createdBy?.last_name}
                      </TableCell>
                    </TableRow>
                  ))}
                  {auctions.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="text-center"
                      >
                        No records found <br />
                        {/* <i style={{ color: "var(---2170ff-p-)" }}>
                          Please add some auction
                        </i> */}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
              <TablePaginationBox
                label={limit + " Auctions Found"}
                numOfPages={numOfPages}
                onChangePage={(event, newPage) => setPage(newPage)}
                page={page}
              />
            </TableContainer>
          </Paper>
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(LiveAuction));
