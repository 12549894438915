import React, { useEffect } from "react";
import { Grid, Paper, Box } from "@material-ui/core";
import Slider, { SliderThumb } from "@mui/material/Slider";
import Notification from "../../../../components/Notification";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Legend,
  ScatterSeries,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { ArgumentScale, Animation } from "@devexpress/dx-react-chart";
import { EventTracker } from "@devexpress/dx-react-chart";
import { curveCatmullRom, line, symbol, symbolCircle } from "d3-shape";
import { scalePoint } from "d3-scale";
import { Button, MenuItem } from "@mui/material";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import TableHeadOptionList from "../../../../components/TableHeadOptionList";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCurrentLiveAuction,
  shouldRefreshLiveBiddingData,
} from "../../../../store/selectors/auctionSelector";
import GetLiveGraphBidBL from "../../BL/GetLiveGraphBidBL";
import GetLiveGraphSuppliersBL from "../../BL/GetLiveGraphSuppliersBL";
import Loader from "../../../../components/Loader/Loader";
import "../../styles/live-graph-range-bar-container.css";
import { getServerLiveTimestamp } from "../../../../store/selectors/ServerClockSelector";
import { thousandSepratorHandler } from "../../../../utility/utils";

const Point = (type, pointStyles) => (props) => {
  const { arg, val, color } = props;
  return (
    <path
      fill={color}
      transform={`translate(${arg} ${val})`}
      d={symbol().size([30]).type(type)()}
      style={pointStyles}
      aria-label="bot-vertex"
    />
  );
};

const DotPoint = Point(symbolCircle, {});

const LineWithDotPoint = (props) => (
  <React.Fragment>
    <LineSeries.Path
      {...props}
      path={line()
        .x(({ arg }) => arg)
        .y(({ val }) => val)}
    />
    <ScatterSeries.Path {...props} pointComponent={DotPoint} />
  </React.Fragment>
);

const TooltIpContent = (props) => {
  return (
    <div>
      <span style={{ fontWeight: "600" }}>{props.targetItem.series} : </span>
      <span style={{ textDecoration: "underline" }}>
        {thousandSepratorHandler(props.text)}
      </span>
    </div>
  );
};

const scaleLabels = [
  {
    id: "30_sec",
    name: " 30 seconds",
    shift: 30 * 1000,
  },
  {
    id: "1_min",
    name: "1 minute",
    shift: 60 * 1000,
  },
  {
    id: "2_min",
    name: "2 minutes",
    shift: 2 * 60 * 1000,
  },
  {
    id: "5_min",
    name: "5 minutes",
    shift: 5 * 60 * 1000,
  },
  {
    id: "15_min",
    name: "15 minutes",
    shift: 15 * 60 * 1000,
  },
  {
    id: "1_hour",
    name: "1 hour",
    shift: 60 * 60 * 1000,
  },
  {
    id: "2_hour",
    name: "2 hour",
    shift: 2 * 60 * 60 * 1000,
  },
  {
    id: "10_hour",
    name: "10 hour",
    shift: 10 * 60 * 60 * 1000,
  },
  {
    id: "1_day",
    name: "1 day",
    shift: 24 * 60 * 60 * 1000,
  },
];

const LiveGraphTab = (props) => {
  const { currentLiveAuction, refreshLiveData, serverLiveTS } = props;
  const [scale, setScale] = React.useState(scaleLabels[5]);
  const [timeSlot, setTimeSlot] = React.useState(new Date());
  const [resendApi, setResendApi] = React.useState(false);
  const [suppliers, setSuppliers] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [chartData, setChartData] = React.useState([]);
  const [loadding, setLoadding] = React.useState(false);
  const [seletedVertex, setSeletedVertex] = React.useState(undefined);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { xsScreen } = useWindowDimensions();

  const getLineSeries = () => {
    let lines = new Array();
    suppliers.forEach((supplier) => {
      lines.push(
        <LineSeries
          name={supplier.name}
          valueField={"supplier_" + supplier.id}
          argumentField="label"
          seriesComponent={LineWithDotPoint}
        />
      );
    });
    return lines;
  };

  const ChooseScale = (target) => {
    const scaleTr = scaleLabels.find((item) => item.id == target);
    setTimeSlot(scaleTr.shift);
    setScale(scaleTr);
    setAnchorEl(null);
    setResendApi(true);
  };

  const initChartStyle = () => {
    let selector =
      ".live-graph-container .live-graph-container #bottom-container .MuiListItem-root svg";
    let Dots = document.querySelectorAll(selector);

    for (let index = 0; index < Dots.length; index++) {
      const Dot = Dots[index];
      Dot.style.height = "15px";
      Dot.style.width = "30px";
      Dot.style.borderRadius = "4px";
      Dot.style.backgroundColor = Dot.getAttribute("fill");
    }

    // number of total vertices equal to number of suppliers * number of labels
    // means = suppliers.length * chartData.length
    // eache supplier will hold the chartData.length vertices respectively

    selector = '.live-graph-container [aria-label="bot-vertex"]';
    let Verteces = document.querySelectorAll(selector);

    for (let index = 0; index < Verteces.length; index++) {
      const Vertex = Verteces[index];
      Vertex.style.stroke = Vertex.getAttribute("fill");
    }

    // suppliers.forEach((supplier, outerIX) => {
    //   chartData.forEach((datum, innerIX) => {
    //     const Vertex = Verteces[outerIX * chartData.length + innerIX];
    //     if (datum.placer_id !== supplier.id) {
    //       // is not real vertix (supplier does not place a bid in this Time Slot)
    //       Vertex.style.stroke = "#ffffff00";
    //       Vertex.style.d = 'path("M 1 0 A 1 1 0 1 1 -1 0 A 1 1 0 1 1 1 0")'
    //     }
    //   });
    // });
  };

  useEffect(() => {
    if (Array.isArray(chartData) && chartData.length > 0) {
      initChartStyle();
    }
  }, [chartData]);

  useEffect(() => {
    if (resendApi == true) {
      setLoadding(true);
      if (suppliers.length > 0) {
        GetLiveGraphBidBL({
          currentAuction: currentLiveAuction,
          timeSlot: timeSlot,
          suppliers: suppliers,
        })
          .then((data) => {
            setChartData(data.bidsData);
            setLoadding(false);
          })
          .catch((error) => {
            setLoadding(false);
          });
        setResendApi(false);
      }
    }
  }, [resendApi]);

  useEffect(async () => {
    if (currentLiveAuction && currentLiveAuction._id) {
      setLoadding(true);

      const response = await GetLiveGraphSuppliersBL({
        currentAuction: currentLiveAuction,
      });
      setSuppliers(response.suppliers);
      setTimeSlot(scaleLabels[5].shift);
      setResendApi(true);
    }
  }, [currentLiveAuction]);

  useEffect(() => {
    if (refreshLiveData == true) {
      setResendApi(true);
    }
  }, [refreshLiveData]);

  return (
    <>
      {loadding && <Loader />}
      <Paper elevation={0} square>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <h3 style={{ marginTop: "0px" }}>Live Graph</h3>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            style={{
              textAlign: xsScreen ? "center" : "end",
              marginTop: xsScreen ? "10px" : "0px",
            }}
          >
            <Button
              aria-controls="demo-customized-menu"
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={(event) => setAnchorEl(event.currentTarget)}
              className="btn-mui-table-option outline"
              endIcon={<KeyboardArrowDownIcon />}
            >
              <span>Last {scale.name}</span>
            </Button>
            <TableHeadOptionList
              id="demo-customized-menu"
              MenuListProps={{
                "aria-label": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={() => setAnchorEl(null)}
            >
              {scaleLabels.map((scale) => {
                return (
                  <MenuItem onClick={() => ChooseScale(scale.id)} disableRipple>
                    {scale.name}
                  </MenuItem>
                );
              })}
            </TableHeadOptionList>
          </Grid>
        </Grid>
        <Paper className="live-graph-container" elevation={0} square>
          <Box className="live-graph-container">
            {suppliers.length > 0 && chartData.length > 0 ? (
              <Chart data={chartData}>
                <ArgumentScale factory={scalePoint} />
                <ArgumentAxis />
                <ValueAxis />
                {getLineSeries().map((item) => {
                  return item;
                })}
                <EventTracker />
                <Tooltip
                  contentComponent={(props, extra) => (
                    <TooltIpContent {...props} />
                  )}
                  targetItem={seletedVertex}
                  onTargetItemChange={(targetItem) =>
                    setSeletedVertex(targetItem)
                  }
                />
                <Legend position="bottom" />
                {/* <Animation /> */}
              </Chart>
            ) : (
              <h3 className="text-center">
                No records found <br />
                {/* <i style={{ color: "var(---2170ff-p-)" }}>
                  there is at least one bid in selected period
                </i> */}
              </h3>
            )}
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
        </Paper>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
    serverLiveTS: getServerLiveTimestamp(state),
  };
};

export default withRouter(connect(mapStateToProps)(LiveGraphTab));
