import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../../components/Notification";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import axiosInstance from "../../../services/AxiosInstance";
import { store } from "../../../store/store";

import "./company.css";
import { Api } from "../../../api/Api";
import { ONLY_COMAPNY_API } from "../../../constants/ApiUrl";

function ItemTable(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

ItemTable.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const columns = [
  {
    label: "Name",
  },
  {
    label: "Email",
  },
  {
    label: "Role",
  },
  {
    label: "Position",
  },
  {
    label: "Status",
  },
  {
    label: "",
  },
];

const UserTable = (props) => {
  const {
    setValues,
    setOpenPopup,
    setIsEditRecord,
    setEditUserId,
    userList,
    rowsPerPage,
    setRowsPerPage,
    getAllUser,
  } = props;
  /// Please be carefull !!!
  /// must start with (1) for pagination issue ....
  const [page, setPage] = React.useState(1);
  // const [rowsPerPage, setRowsPerPage] = React.useState(tablePagination);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [alertDailog, setAlertDailog] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const handleClose = () => setAlertDailog(false);
  const numOfPages = Math.ceil(userList.length / rowsPerPage);

  const editUser = (user) => {
    if (user) {
      setIsEditRecord(true);
      setOpenPopup(true);
      setValues(user);
      setEditUserId(user._id);
    }
  };

  const deleteDocument = async (user) => {
    if (user) {
      setAlertDailog(true);
      setDeleteUserData(user);
    }
  };

  const confirmDeleteUser = async () => {
    if (deleteUserData && deleteUserData._id) {
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      const response = await Api(
        `${ONLY_COMAPNY_API}/${companyId}/deleteMember/${deleteUserData._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllUser();
          setAlertDailog(false);
          setDeleteUserData({});
        }
      }

      // await axiosInstance
      //   .delete(`api/company/${companyId}/deleteMember/${deleteUserData._id}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       getAllUser();
      //       setAlertDailog(false);
      //       setDeleteUserData({});
      //       setNotify({
      //         isOpen: true,
      //         message: "User removed successfully.",
      //         type: "success",
      //       });
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: response?.data?.responseDescription,
      //         type: "error",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  return (
    <>
      <Paper elevation={0} square>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? userList.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : userList
              ).map((user) => (
                <TableRow key={user._id}>
                  <TableCell style={{ color: "#2170FF" }}>
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        style={{
                          width: "2em",
                          height: "2em",
                          borderRadius: "50%",
                          border: "2px #fff solid",
                        }}
                        src={user.image_upload}
                      />
                    </div>
                    <span
                      style={{ marginLeft: "10px" }}
                    >{`${user.first_name} ${user.last_name}`}</span>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {user?.role === "both" ? "Buyer & Seller" : user?.role}
                  </TableCell>
                  <TableCell>{user.position}</TableCell>
                  <TableCell>
                    <Button
                      className="badge badge-success status"
                      variant="contained"
                    >
                      {user.status}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem onClick={() => editUser(user)}>
                                Edit
                              </MenuItem>
                              <MenuItem
                                style={{ color: "red" }}
                                onClick={() => deleteDocument(user)}
                              >
                                Delete
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {userList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some user
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={userList.length + " Users Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>

      <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this user
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setAlertDailog(false)}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={() => confirmDeleteUser()}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserTable;
