import React, { useState } from "react";
import { Grid, Box, Tabs, Tab, Paper, Typography } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentLiveAuction } from "../../../../store/selectors/auctionSelector";
import Notification from "../../../../components/Notification";
import { currentUser } from "../../../../store/selectors/AuthSelectors";

import { AUCTION_DRAFT } from "../../../../constants/AppConstants";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import Controls from "../../../../components/controls/Controls";
import RfxGeneralInfoTab from "./RfxGeneralInfoTab";
import { POST_SUPPLIER_SAVE_IMPORT_DATA_API } from "../../../../constants/ApiUrl";
import { Api } from "../../../../api/Api";
import RfxSupplierTab from "./RfxSupplierTab";
import RfxItemTab from "./RfxItemTab";
import RfxAttachmentTab from "./RfxAttachmentTab";
import RfxCommentTab from "./RfxCommentTab";
import RfxQuestionsTab from "./RfxQuestionsTab";
import RfxMessagesTab from "./RfxMessagesTab";

const TabWithCount = ({ children, count }) => {
  return (
    <Box className="rfx-tab">
      <Typography className="tab-title" component="div">
        {children}
      </Typography>
      {count >= 0 ? (
        <Typography
          component="div"
          variant="body2"
          className="message-count"
          sx={{ marginLeft: "0.5rem" }}
        >
          {count}
        </Typography>
      ) : null}
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-label={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const RfxTabContainer = (props) => {
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const { refreshLiveAuction, currentUser, getLiveRfxDetails } = props;
  const [value, setValue] = useState(0);
  const GNInfoTabIX = 0;
  const QuestionTABIX = 2;
  const { mdScreen, smScreen, xsScreen } = useWindowDimensions();
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cancelRfx = async () => {
    if (rfxDetails && rfxDetails.projectId && currentUser) {
      const response = await Api(
        `${POST_SUPPLIER_SAVE_IMPORT_DATA_API}/rfx/${rfxDetails._id}/cancel`,
        {},
        "patch"
      );
    }
  };

  const getMargeinTopValue = () => {
    if (xsScreen) {
      return "calc(30em + 4vw)";
    } else if (smScreen) {
      return "calc(12em + 5vw)";
    } else if (mdScreen) {
      return "calc(9em + 1vw)";
    }
  };

  const isOwner = () => {
    if (rfxDetails && currentUser) {
      return String(rfxDetails?.createdBy?._id) === String(currentUser._id);
    }
    return false;
  };

  const isEditor = () => {
    if (rfxDetails && rfxDetails.projectId && currentUser) {
      if (Array.isArray(rfxDetails.projectId.editorIds)) {
        return (
          rfxDetails.projectId.editorIds.findIndex(
            (Id) => String(Id) === String(currentUser._id)
          ) >= 0
        );
      }
    }

    return false;
  };

  return (
    <>
      <Grid container className="tab-header-container rfx-tab-body">
        <Box
          sx={{
            borderRadius: "25px !important",
            marginTop: getMargeinTopValue(),
          }}
        >
          <Tabs
            className="tab-header"
            id="auction-tab-list rfx-tab-list"
            value={value}
            onChange={handleChange}
          >
            <Tab label="General Information" />
            <Tab label="Suppliers" />
            <Tab label="Questions" />
            <Tab label="Items" />
            <Tab
              label={
                <TabWithCount count={rfxDetails?.openMessagesCount || 0}>
                  Messages
                </TabWithCount>
              }
            />
            <Tab label="Attachments" />
            <Tab label="Comments" />
          </Tabs>
        </Box>
        <Box
          className="cancel-rfx"
          style={{ marginTop: mdScreen ? "2em" : "0em" }}
        >
          {/* <Controls.Button
            style={{
              backgroundColor: "#424143",
              borderRadius: "12px",
            }}
            text="Put on Hold"
          /> */}
          {rfxDetails?.status === AUCTION_DRAFT ? (
            <Controls.Button
              text={`Cancel ${rfxDetails?.rfx_type}`}
              style={{ backgroundColor: "#dc3545", borderRadius: "12px" }}
              onClick={cancelRfx}
            />
          ) : (
            <></>
          )}
        </Box>
      </Grid>
      <Paper
        className={
          "tab-body-container " +
          (value === GNInfoTabIX || value === QuestionTABIX
            ? "__clear-page"
            : "")
        }
      >
        <TabPanel value={value} index={0} labelAria="no-padding">
          <RfxGeneralInfoTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
            refreshLiveAuction={refreshLiveAuction}
            getLiveRfxDetails={getLiveRfxDetails}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RfxSupplierTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
          />
        </TabPanel>
        <TabPanel value={value} index={2} labelAria="no-padding">
          <RfxQuestionsTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <RfxItemTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <RfxMessagesTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <RfxAttachmentTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
          />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <RfxCommentTab
            isEditor={isEditor}
            isOwner={isOwner}
            setNotify={setNotify}
          />
        </TabPanel>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rfxDetails: getCurrentLiveAuction(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(RfxTabContainer));
