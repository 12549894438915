import { CHANGE_CURRENT_SELECTED_ROLE } from "../constant/authorizationConstant";

const initialState = {
  selectedRole: "",
};

export const AuthorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CURRENT_SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
      };

    default:
      return state;
  }
};
