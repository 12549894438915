import { Api } from "../../api/Api";
import { GET_USER_API } from "../../constants/ApiUrl";
import { setRoleInLocalStorage, updateUserDetails } from "../../utility/utils";
import { CHANGE_CURRENT_SELECTED_ROLE } from "../constant/authorizationConstant";
import { roleUpdateAction } from "./authActions";

export function changeCurrentSelectedRole(role) {
  return {
    type: CHANGE_CURRENT_SELECTED_ROLE,
    payload: role,
  };
}
export function buyerRolePermission(
  id,
  confirmDialog,
  setConfirmDialog,
  history
) {
  return async (dispatch) => {
    const postData = {
      role: confirmDialog.role,
    };
    const response = await Api(
      `${GET_USER_API}/${id}/role/update`,
      postData,
      "patch"
    );
    if (response.status === 200) {
      setConfirmDialog({ ...confirmDialog, isOpen: false });

      // let userDetail = getUserDetails();

      updateUserDetails(
        response?.data?.updatedData?.isBuyer,
        response?.data?.updatedData?.role
      );
      setRoleInLocalStorage("buyer");
      dispatch(changeCurrentSelectedRole(response?.data?.updatedData?.role));
      // dispatch(changeCurrentSelectedRole("buyer"));
      dispatch(
        roleUpdateAction({
          role: response?.data?.updatedData?.role,
          isSeller: response?.data?.updatedData?.isSeller,
          isBuyer: response?.data?.updatedData?.isBuyer,
        })
      );
      history.push("/dashboard");
    }

    // axiosInstance
    //   .patch(
    //     process.env.REACT_APP_BACKEND_API_URL + `/api/users/${id}/role/update`,
    //     postData
    //   )
    //   .then((response) => {
    //     setConfirmDialog({ ...confirmDialog, isOpen: false });

    //     // let userDetail = getUserDetails();

    //     updateUserDetails(
    //       response?.data?.updatedData?.isBuyer,
    //       response?.data?.updatedData?.role
    //     );
    //     setRoleInLocalStorage("buyer");
    //     dispatch(changeCurrentSelectedRole(response?.data?.updatedData?.role));
    //     // dispatch(changeCurrentSelectedRole("buyer"));
    //     dispatch(
    //       roleUpdateAction({
    //         role: response?.data?.updatedData?.role,
    //         isSeller: response?.data?.updatedData?.isSeller,
    //         isBuyer: response?.data?.updatedData?.isBuyer,
    //       })
    //     );
    //     history.push("/dashboard");
    //   })
    //   .catch((error) => {});
  };
}
