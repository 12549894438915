import React from "react";
import { TextField } from "@material-ui/core";

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    multiline,
    maxRows = 0,
    onChange,
    type,
    minRows,
    autoComplete,
    ...other
  } = props;

  const [ClassName, setClassName] = React.useState("mui-input ");
  React.useEffect(() => {
    if (other && other.className) {
      setClassName(ClassName + other.className);
    }
  }, []);

  return (
    <TextField
      variant="outlined"
      size="small"
      label={label}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
      multiline
      minRows={minRows}
      maxRows={maxRows}
      className={ClassName}
      autoComplete={autoComplete}
    />
  );
}
