import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function DatePicker(props) {
  const { name, label, value, onChange, ...other } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const [ClassName, setClassName] = React.useState("mui-input ");
  React.useEffect(() => {
    if (other && other.className) {
      setClassName(ClassName + other.className);
    }
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        {...other}
        label={label}
        format={other.format ?? "MMM/dd/yyyy"}
        name={name}
        value={value}
        disablePast
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
        className={ClassName}
      />
    </MuiPickersUtilsProvider>
  );
}
