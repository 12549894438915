import react from "react"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
const Loder = (props) => {
    const {fullLoder, setFullLoder} = props;

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={fullLoder}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Loder