import { Box, Grid, Paper } from "@material-ui/core";
import { InputLabel, Modal } from "@mui/material";
import React, { useEffect } from "react";
import Controls from "../../../components/controls/Controls";
import { Form, useForm } from "../../../components/useForm";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import { Api } from "../../../api/Api";
import {
  POST_ITEM_EDIT_UPLOAD_DOCUMENT_API,
  POST_ITEM_UPLOAD_DOCUMENT_API,
} from "../../../constants/ApiUrl";

const initialFValues = {
  link: "",
  name: "",
  notes: "",
};

export default function ItemAttachLinkPopup(props) {
  const {
    openPopup,
    setOpenPopup,
    editLinkData,
    setEditLinkData,
    isEditLink,
    setIsEditLink,
    itemDetail,
    getAllAttachments,
    setNotify,
  } = props;

  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("link" in fieldValues)
      temp.link = fieldValues.link
        ? !regex.test(fieldValues.link)
          ? "Please enter valid link"
          : ""
        : "This field is required.";
    // temp.link = fieldValues.link ? "" : "This field is required.";

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("notes" in fieldValues)
      temp.notes = fieldValues.notes ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const { auth } = store.getState();
      const userId = auth?.auth?.user?._id;
      let formData = new FormData();
      formData.append("id", itemDetail?._id);
      formData.append("title", values.name);
      formData.append("notes", values.notes);
      formData.append("link", values.link);
      formData.append("createdBy", userId);
      formData.append("type", "link");
      if (!isEditLink) {
        const response = await Api(
          POST_ITEM_UPLOAD_DOCUMENT_API,
          formData,
          "postMultipart"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            resetForm();
            setOpenPopup(false);
            getAllAttachments();
          }
        }

        // await axiosInstance
        //   .post(`api/buyer/item/document/uploadDocument`, formData)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       resetForm();
        //       setOpenPopup(false);
        //       getAllAttachments();
        //       setNotify({
        //         isOpen: true,
        //         message: "Attachted successfully.",
        //         type: "success",
        //       });
        //     } else {
        //       setNotify({
        //         isOpen: true,
        //         message: response?.data?.responseDescription,
        //         type: "error",
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     setNotify({
        //       isOpen: true,
        //       message: err?.response?.data?.responseDescription,
        //       type: "error",
        //     });
        //   });
      } else {
        formData.append("attachmentId", editLinkData?._id);
        const response = await Api(
          POST_ITEM_EDIT_UPLOAD_DOCUMENT_API,
          formData,
          "postMultipart"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            resetForm();
            setOpenPopup(false);
            getAllAttachments();
            setEditLinkData({});
            setIsEditLink(false);
          }
        }

        // await axiosInstance
        //   .post(`api/buyer/item/document/editUploadDocument`, formData)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       resetForm();
        //       setOpenPopup(false);
        //       getAllAttachments();
        //       setEditLinkData({});
        //       setIsEditLink(false);
        //       setNotify({
        //         isOpen: true,
        //         message: "Attachment updated successfully.",
        //         type: "success",
        //       });
        //     } else {
        //       setNotify({
        //         isOpen: true,
        //         message: response?.data?.responseDescription,
        //         type: "error",
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     setNotify({
        //       isOpen: true,
        //       message: err?.response?.data?.responseDescription,
        //       type: "error",
        //     });
        //   });
      }
    }
  };

  const pressCancel = () => {
    resetForm();
    setEditLinkData({});
    setIsEditLink(false);
    setOpenPopup(false);
  };

  useEffect(() => {
    if (editLinkData && editLinkData?.link) {
      setValues({
        link: editLinkData?.link,
        name: editLinkData?.title,
        notes: editLinkData?.notes,
      });
    }
  }, [editLinkData]);

  return (
    <Modal
      open={openPopup}
      aria-label="item-list-info-details-attach-link-popup"
      aria-describedby="item-list-info-details-attach-link-popup"
    >
      <Box className="popup-form-container">
        <div className="title">Add Link</div>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className="mui-label">Link</InputLabel>
              <Controls.Input
                className="mui-input"
                fullWidth
                name="link"
                onChange={handleInputChange}
                value={values.link}
                error={errors.link}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mui-label">Name</InputLabel>
              <Controls.Input
                className="mui-input"
                fullWidth
                name="name"
                onChange={handleInputChange}
                value={values.name}
                error={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mui-label">Notes</InputLabel>
              <Controls.Input
                fullWidth
                name="notes"
                onChange={handleInputChange}
                value={values.notes}
                error={errors.notes}
              />
            </Grid>
          </Grid>
          <Box display="flex" mt={4} bgcolor="background.paper">
            <Grid istem xs={4} className="BottomModal-left">
              <Box p={0} flexGrow={1}>
                <Controls.Button
                  text="Cancel"
                  disableElevation
                  onClick={() => pressCancel()}
                  style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                  color="default"
                  className="btn-mui"
                />
              </Box>
            </Grid>
            <Grid item xs={8} className="BottomModal-right">
              <Box p={0}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text="Attach"
                  className="btn-mui"
                  style={{ minWidth: "197px" }}
                />
              </Box>
            </Grid>
          </Box>
        </Form>
      </Box>
    </Modal>
  );
}
