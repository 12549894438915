import { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { setHTTPError } from "../../store/actions/errorHandlerAction";
import Loader from "../../components/Loader/Loader";
import Copyright from "../../components/Copyright";
import Appbars from "../../components/Appbar";

import {
  getCurrentLiveAuction,
  shouldRefreshLiveAuctionBuyer,
  shouldRefreshLiveBiddingData,
} from "../../store/selectors/auctionSelector";
import RfxBanner from "./Components/RfxDetailsComponent/RfxBanner";
import RfxTabContainer from "./Components/RfxDetailsComponent/RfxTabContainer";
import {
  refreshLiveRfxBuyer,
  setLiveRfxDetails,
} from "../../store/actions/rfxAction";
import GetRfxById from "./Components/RfxProcessFunction/GetRfxById";

const RfxDetails = (props) => {
  const { refreshLiveBidding, refreshLiveAuction } = props;
  const { id } = useParams();
  const UnAuthorizedRoute = useSelector(
    (state) => state.errorHandler.UnAuthorizedRoute
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadding, setLoadding] = useState(false);
  const [resendApi, setResendApi] = useState(true);

  useEffect(() => {
    if (resendApi === true) {
      GetRfxById({ id: id }).then((result) => {
        setResendApi(false);
        setLoadding(false);
        if (result.success) {
          dispatch(setLiveRfxDetails(result.rfx));
        } else {
          if (result.code === 403) {
            dispatch(
              setHTTPError({
                code: 403,
                message: result.message,
              })
            );
            history.push(UnAuthorizedRoute);
          } else {
            dispatch(
              setHTTPError({
                code: result.code,
                message: result.message,
              })
            );
          }
        }
      });
    }
  }, [resendApi]);

  useEffect(() => {
    if (refreshLiveAuction === true) {
      setLoadding(true);
      setResendApi(true);
      dispatch(refreshLiveRfxBuyer(false));
    }
  }, [refreshLiveAuction]);

  useEffect(() => {}, [loadding]);

  const getLiveRfxDetails = () => {
    GetRfxById({ id: id }).then((result) => {
      setLoadding(false);
      if (result.success) {
        dispatch(setLiveRfxDetails(result.rfx));
      } else {
        if (result.code === 403) {
          dispatch(
            setHTTPError({
              code: 403,
              message: result.message,
            })
          );
          history.push(UnAuthorizedRoute);
        } else {
          dispatch(
            setHTTPError({
              code: result.code,
              message: result.message,
            })
          );
        }
      }
    });
  };
  return (
    <>
      {loadding && <Loader />}
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <Appbars> </Appbars>
        <main className="app-main-section-container">
          <Container maxWidth="lg">
            <RfxBanner
              refreshLiveBidding={refreshLiveBidding}
              getLiveRfxDetails={getLiveRfxDetails}
            />
            <RfxTabContainer
              refreshLiveBidding={refreshLiveBidding}
              refreshLiveAuction={refreshLiveAuction}
              getLiveRfxDetails={getLiveRfxDetails}
            />
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
    refreshLiveBidding: shouldRefreshLiveBiddingData(state),
    refreshLiveAuction: shouldRefreshLiveAuctionBuyer(state),
  };
};

export default withRouter(connect(mapStateToProps)(RfxDetails));
