import {
  AUCTION_ACTIVE,
  AUCTION_ACTIVE_SMALL,
  AUCTION_ARCHIEVED,
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_COMPLETED,
  AUCTION_COMPLETED_SMALL,
  AUCTION_DRAFT,
  AUCTION_DRAFT_SMALL,
  AUCTION_INACTIVE,
  AUCTION_INACTIVE_SMALL,
} from "../constants/AppConstants";

const KEYS = {
  employees: "employees",
  employeeId: "employeeId",
};

export const getDepartmentCollection = () => [
  { id: "1", title: "India" },
  { id: "2", title: "Nigeria" },
  { id: "3", title: "UAE" },
  { id: "4", title: "UK" },
];

export const companyType = () => [
  { id: "1", name: "LLC" },
  { id: "2", name: "Pvt. Ltd." },
];

export const companyAnnualRevenue = () => [
  { id: "1", name: "0-250,000 USD" },
  { id: "2", name: "250-1,000,000 USD" },
  { id: "3", name: "1-5,000,000 USD" },
  { id: "4", name: "5-20,000,000 USD" },
  { id: "5", name: "+20,000,000 USD" },
];
export const languageOption = () => [{ id: "1", title: "English" }];
export const companyNoOfEmployee = () => [
  { id: "1", name: "0-50" },
  { id: "2", name: "50-200" },
  { id: "3", name: "200-1000" },
  { id: "4", name: "+1000" },
];

export const getItemStatus = () => [
  { id: AUCTION_ACTIVE_SMALL, title: AUCTION_ACTIVE },
  { id: AUCTION_INACTIVE_SMALL, title: AUCTION_INACTIVE },
];

export const projectStatus = () => [
  { id: AUCTION_ACTIVE_SMALL, title: AUCTION_ACTIVE },
  { id: AUCTION_ARCHIEVED, title: AUCTION_ARCHIEVED_CAPITAL },
  { id: AUCTION_COMPLETED_SMALL, title: AUCTION_COMPLETED },
  { id: AUCTION_DRAFT_SMALL, title: AUCTION_DRAFT },
];

export const currencyList = () => [
  {
    code: "USD",
    name: "United States Dollar (USD)",
  },
  {
    code: "EUR",
    name: "Euro (EUR)",
  },
  {
    code: "GBP",
    name: "United Kingdom Pounds (GBP)",
  },
  {
    code: "INR",
    name: "India Rupees (INR)",
  },
  {
    code: "AUD",
    name: "Australia Dollars (AUD)",
  },
  {
    code: "CAD",
    name: "Canada Dollars (CAD)",
  },
  {
    code: "ZAR",
    name: "South Africa Rand (ZAR)",
  },
  {
    code: "NZD",
    name: "New Zealand Dollars (NZD)",
  },
  {
    code: "JPY",
    name: "Japan Yen (JPY)",
  },
  {
    code: "SGD",
    name: "Singapore Dollars (SGD)",
  },
  {
    code: "CNY",
    name: "China Yuan Renminbi (CNY)",
  },
  {
    code: "CHF",
    name: "Switzerland Francs (CHF)",
  },
  {
    code: "MYR",
    name: "Malaysia Ringgits (MYR)",
  },
  {
    code: "HUF",
    name: "Hungary Forint (HUF)",
  },
  {
    code: "HKD",
    name: "Hong Kong Dollars (HKD)",
  },
  {
    code: "AED",
    name: "United Arab Emirates Dirhams (AED)",
  },
  {
    code: "THB",
    name: "Thailand Baht (THB)",
  },
  {
    code: "KRW",
    name: "Korea (South) Won (KRW)",
  },
  {
    code: "SEK",
    name: "Sweden Kronor (SEK)",
  },
  {
    code: "PHP",
    name: "Philippines Pesos (PHP)",
  },
  {
    code: "ILS",
    name: "Israel New Shekels (ILS)",
  },
  {
    code: "TRY",
    name: "Turkey Lira (TRY)",
  },
  {
    code: "PLN",
    name: "Poland Zlotych (PLN)",
  },
  {
    code: "MXN",
    name: "Mexico Pesos (MXN)",
  },
  {
    code: "NOK",
    name: "Norway Kroner (NOK)",
  },
  {
    code: "DKK",
    name: "Denmark Kroner (DKK)",
  },
  {
    code: "KWD",
    name: "Kuwait Dinars (KWD)",
  },
  {
    code: "BRL",
    name: "Brazil Reals (BRL)",
  },
  {
    code: "IDR",
    name: "Indonesia Rupiahs (IDR)",
  },
  {
    code: "TWD",
    name: "Taiwan New Dollars (TWD)",
  },
  {
    code: "SAR",
    name: "Saudi Arabia Riyals (SAR)",
  },
  {
    code: "PKR",
    name: "Pakistan Rupees (PKR)",
  },
  {
    code: "CZK",
    name: "Czech Republic Koruny (CZK)",
  },
  {
    code: "IQD",
    name: "Iraq Dinars (IQD)",
  },
  {
    code: "RUB",
    name: "Russia Rubles (RUB)",
  },
  {
    code: "KES",
    name: "Kenya Shillings (KES)",
  },
  {
    code: "JOD",
    name: "Jordan Dinars (JOD)",
  },
  {
    code: "CLP",
    name: "Chile Pesos (CLP)",
  },
  {
    code: "ARS",
    name: "Argentina Pesos (ARS)",
  },
  {
    code: "EGP",
    name: "Egypt Pounds (EGP)",
  },
  {
    code: "VND",
    name: "Vietnam Dong (VND)",
  },
  {
    code: "QAR",
    name: "Qatar Riyals (QAR)",
  },
  {
    code: "XAU",
    name: "Gold Ounces (XAU)",
  },
  {
    code: "RON",
    name: "Romania New Lei (RON)",
  },
  {
    code: "COP",
    name: "Colombia Pesos (COP)",
  },
  {
    code: "OMR",
    name: "Oman Rials (OMR)",
  },
  {
    code: "BGN",
    name: "Bulgaria Leva (BGN)",
  },
  {
    code: "XAG",
    name: "Silver Ounces (XAG)",
  },
  {
    code: "TND",
    name: "Tunisia Dinars (TND)",
  },
  {
    code: "MAD",
    name: "Morocco Dirhams (MAD)",
  },
  {
    code: "BHD",
    name: "Bahrain Dinars (BHD)",
  },
  {
    code: "ISK",
    name: "Iceland Kronur (ISK)",
  },
  {
    code: "LKR",
    name: "Sri Lanka Rupees (LKR)",
  },
  {
    code: "HRK",
    name: "Croatia Kuna (HRK)",
  },
  {
    code: "GEL",
    name: "Georgia Lari (GEL)",
  },
  {
    code: "UAH",
    name: "Ukraine Hryvnia (UAH)",
  },
  {
    code: "IRR",
    name: "Iran Rials (IRR)",
  },
  {
    code: "NGN",
    name: "Nigeria Nairas (NGN)",
  },
  {
    code: "XAF",
    name: "Coopération financière en Afrique centrale BEAC (XAF)",
  },
  // {
  //   code: "SKK",
  //   name: "Slovakia Koruny (obsolete) (SKK)",
  // },
  // {
  //   code: "LVL",
  //   name: "Latvia Lati (LVL)",
  // },
  // {
  //   code: "LTL",
  //   name: "Lithuania Litai (LTL)",
  // },
  {
    code: "BDT",
    name: "Bangladesh Taka (BDT)",
  },
  {
    code: "MUR",
    name: "Mauritius Rupees (MUR)",
  },
  // {
  //   code: "CYP",
  //   name: "Cyprus Pounds (obsolete) (CYP)",
  // },
  {
    code: "ALL",
    name: "Albania Leke (ALL)",
  },
  {
    code: "FJD",
    name: "Fiji Dollars (FJD)",
  },
  // {
  //   code: "MTL",
  //   name: "Malta Liri (obsolete) (MTL)",
  // },
  {
    code: "PEN",
    name: "Peru Nuevos Soles (PEN)",
  },
  {
    code: "XOF",
    name: "Communauté Africaine Financière Francs BEAC (XOF)",
  },
  {
    code: "BOB",
    name: "Bolivia Bolivianos (BOB)",
  },
  // {
  //   code: "BYR",
  //   name: "Belarus Rubles (BYR)",
  // },
  {
    code: "UYU",
    name: "Uruguay Pesos (UYU)",
  },
  {
    code: "BAM",
    name: "Bosnia and Herzegovena Convertible Marka (BAM)",
  },
  {
    code: "UZS",
    name: "Uzbekistan Sums (UZS)",
  },
  {
    code: "KZT",
    name: "Kazakhstan Tenge (KZT)",
  },
  {
    code: "MKD",
    name: "Macedonia Denars (MKD)",
  },
  {
    code: "MDL",
    name: "Moldova Lei (MDL)",
  },
  {
    code: "AMD",
    name: "Armenia Drams (AMD)",
  },
  {
    code: "KGS",
    name: "Kyrgyzstan Sums (KGS)",
  },
  {
    code: "TJS",
    name: "Tajikistan Somoni (TJS)",
  },
  {
    code: "TMT",
    name: "Turkmenistan New Manats (TMT)",
  },
  {
    code: "VEF",
    name: "Venezuela Bolivares (VEF)",
  },
  {
    code: "DOP",
    name: "Dominican Republic Pesos (DOP)",
  },
  {
    code: "JMD",
    name: "Jamaica Dollars (JMD)",
  },
  {
    code: "DZD",
    name: "Algeria Dinars (DZD)",
  },
  {
    code: "XPF",
    name: "Comptoirs Français du Pacifique Francs (XPF)",
  },
  {
    code: "UGX",
    name: "Uganda Shillings (UGX)",
  },
  {
    code: "CRC",
    name: "Costa Rica Colones (CRC)",
  },
  // {
  //   code: "ZMK",
  //   name: "Zambia Kwacha (ZMK)",
  // },
  {
    code: "BND",
    name: "Brunei Dollars (BND)",
  },
  {
    code: "LYD",
    name: "Libya Dinars (LYD)",
  },
  {
    code: "XCD",
    name: "Eastern Caribbean Dollars (XCD)",
  },
  // {
  //   code: "TRL",
  //   name: "Turkey Lira (obsolete) (TRL)",
  // },
  {
    code: "BBD",
    name: "Barbados Dollars (BBD)",
  },
  {
    code: "TTD",
    name: "Trinidad and Tobago Dollars (TTD)",
  },
  {
    code: "LBP",
    name: "Lebanon Pounds (LBP)",
  },
  {
    code: "SYP",
    name: "Syria Pounds (SYP)",
  },
  {
    code: "KHR",
    name: "Cambodia Riels (KHR)",
  },
  {
    code: "NPR",
    name: "Nepal Rupees (NPR)",
  },
];

export function insertEmployee(data) {
  let employees = getAllEmployees();
  data["id"] = generateEmployeeId();
  employees.push(data);
  localStorage.setItem(KEYS.employees, JSON.stringify(employees));
}

export function updateEmployee(data) {
  let employees = getAllEmployees();
  let recordIndex = employees.findIndex((x) => x.id === data.id);
  employees[recordIndex] = { ...data };
  localStorage.setItem(KEYS.employees, JSON.stringify(employees));
}

export function deleteEmployee(id) {
  let employees = getAllEmployees();
  employees = employees.filter((x) => x.id !== id);
  localStorage.setItem(KEYS.employees, JSON.stringify(employees));
}

export function generateEmployeeId() {
  if (localStorage.getItem(KEYS.employeeId) === null)
    localStorage.setItem(KEYS.employeeId, "0");
  var id = parseInt(localStorage.getItem(KEYS.employeeId));
  localStorage.setItem(KEYS.employeeId, (++id).toString());
  return id;
}

export function getAllEmployees() {
  if (localStorage.getItem(KEYS.employees) === null)
    localStorage.setItem(KEYS.employees, JSON.stringify([]));
  let employees = JSON.parse(localStorage.getItem(KEYS.employees));
  //map departmentID to department title
  let departments = getDepartmentCollection();
  return employees.map((x) => ({
    ...x,
    department: departments[x.location - 1].title,
  }));
}
