
const SetupLiveMonitoringBL = (props) => {
  const { currentAuction, serverLiveTS } = props;

  let timeRemaining = {
    canCount: false,
    dueTime: -1,
  };

  if (currentAuction.auctionId) {
    let startDate = new Date(currentAuction.auctionId.startDate);
    let endDate = new Date(currentAuction.auctionId.endDate);
    let serverTime = new Date(serverLiveTS);

    timeRemaining = getTimeRemainingAttr(
      startDate,
      endDate,
      serverTime,
    );
  }

  return {
    timeRemaining: timeRemaining,
  };
};

const getTimeRemainingAttr = (startDate, endDate, serverTime) => {
  if (!((serverTime - startDate) >= 0)) {
    return {
      canCount: false,
      dueTime: -1,
      revTime: -1,
    };
  } else if ((endDate - serverTime) <= 0) {
    return {
      canCount: false,
      dueTime: 1,
      revTime: 1,
    };
  } else {
    return {
      canCount: true,
      dueTime: Math.round((endDate - serverTime) / 1000),
      revTime: Math.round((serverTime - startDate) / 1000),
    };
  }
};

export default SetupLiveMonitoringBL;
