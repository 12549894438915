/*eslint-disable */
import { useEffect, useState, useRef, createRef } from "react";
import { makeStyles, Box, Grid } from "@material-ui/core";
import { Button, Divider, Stack } from "@mui/material";
import { store } from "../../../store/store";
import { useForm, Form } from "../../../components/useForm";
import Controls from "../../../components/controls/Controls";
import TextField from "@mui/material/TextField";
import "../styles/comment.css";
import Moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import userImage from "../../../assets/auth/user_icon_2.png";
import { Api } from "../../../api/Api";
import { GET_BUYER_PROJECT_API } from "../../../constants/ApiUrl";

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTab-root.Mui-selected": {
      color: "#1976d2",
      color: "#fff",
      backgroundColor: "blue",
      padding: "0px",
      margin: "7px",
      borderRadius: "25px",
      textTransform: "capitalize",
      "& .comment-number": {
        backgroundColor: "#fff",
        color: "#00f",
      },
    },
  },
}));

const initialFValues = {
  comment: "",
  replaycomment: "",
};

const ProjectComments = (props) => {
  const { selectedProject, currentUser } = props;
  const classes = useStyles();
  const [profileImg, setProfileImg] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [replayCommentText, setReplayCommentText] = useState("");
  const [replayToId, setReplayToId] = useState(null);
  const textRef = useRef([]);
  textRef.current = commentList.map(
    (ref, index) => (textRef.current[index] = createRef())
  );
  const getAllIComments = async () => {
    const state = store.getState();
    const companyName = state?.auth?.auth?.user?.company?.company_name;
    const response = await Api(
      `${GET_BUYER_PROJECT_API}/${selectedProject._id}/comments`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allComments = response.data.comments;
        setCommentList(allComments);
      }
    }

    // await axiosInstance
    //   .get(`api/buyer/project/${selectedProject._id}/comments`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allComments = response.data.comments;
    //       setCommentList(allComments);
    //     }
    //   })
    //   .catch((err) => {});
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("names" in fieldValues)
      temp.names = fieldValues.names ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const state = store.getState();
      const userId = state?.auth?.auth?.user?._id;
      const payload = {
        // "replyingTo": "",
        content: values.comment,
        userId: userId,
      };
      const response = await Api(
        `${GET_BUYER_PROJECT_API}/${selectedProject._id}/comment`,
        payload,
        "post"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllIComments();
          resetForm();
        }
      }

      // await axiosInstance
      //   .post(`api/buyer/project/${selectedProject._id}/comment`, payload)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       getAllIComments();
      //       resetForm();
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const addReplay = async (data, index) => {
    if (data && data._id) {
      setReplayToId(data._id);
      const state = store.getState();
      const userId = state?.auth?.auth?.user?._id;
      const payload = {
        replyingTo: data._id,
        content: replayCommentText,
        userId: userId,
      };

      const response = await Api(
        `${GET_BUYER_PROJECT_API}/${selectedProject._id}/comment`,
        payload,
        "post"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllIComments();
          setReplayCommentText("");
          setReplayToId(null);
          resetForm();
          textRef.current[index].current.value = "";
        }
      }

      // await axiosInstance
      //   .post(`api/buyer/project/${selectedProject._id}/comment`, payload)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       getAllIComments();
      //       setReplayCommentText("");
      //       setReplayToId(null);
      //       resetForm();
      //       textRef.current[index].current.value = "";
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const ClearCommentText = (index) => {
    textRef.current[index].current.value = "";
    setReplayCommentText("");
    setReplayToId(null);
  };

  const getReplayText = (e) => {
    const texts = e.target.value;
    if (texts) {
      setReplayCommentText(texts);
    }
  };

  const isEditor = () => {
    if (selectedProject && currentUser) {
      if (Array.isArray(selectedProject.editorIds)) {
        return (
          selectedProject.editorIds.findIndex(
            (Id) => String(Id) === String(currentUser._id)
          ) >= 0
        );
      }
    }
    return false;
  };

  const isViewer = () => {
    if (selectedProject && currentUser) {
      if (Array.isArray(selectedProject.viewerIds)) {
        return (
          selectedProject.viewerIds.findIndex(
            (Id) => String(Id) === String(currentUser._id)
          ) >= 0
        );
      }
    }
    return false;
  };

  const isOwner = () => {
    if (selectedProject && currentUser) {
      return String(selectedProject.createdBy) === String(currentUser._id);
    }
    return false;
  };

  useEffect(() => {
    if (currentUser) {
      getAllIComments();
    }
  }, [currentUser]);

  return (
    <>
      <Grid container className="prodject-comment-container">
        {isEditor() ? (
          <Stack
            direction="row"
            spacing={1}
            style={{
              width: "100%",
            }}
          >
            <Box>
              {/* <div className="new-comment-img-container"> */}
              <img
                style={{
                  marginTop: "6px",
                  width: "2.7em",
                  height: "2.7em",
                  objectFit: "cover",
                }}
                src={
                  currentUser.image_upload
                    ? currentUser.image_upload
                    : userImage
                  // : "https://www.bootdey.com/img/Content/avatar/avatar2.png"
                }
              />
              {/* </div> */}
            </Box>
            <Box style={{ flexGrow: 1, marginLeft: "1.7em" }}>
              <Form
                onSubmit={handleSubmit}
                className="new-comment-form-container"
              >
                <Grid item xs={12}>
                  <Controls.Input
                    name="comment"
                    value={values.comment}
                    onChange={handleInputChange}
                    error={errors.comment}
                    className="comment-text-box fix-h-5em"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ float: "right", marginTop: "1em" }}>
                    <Controls.Button
                      type="submit"
                      disableElevation
                      text="Add Comment"
                      className="action-btn"
                    />
                  </div>
                </Grid>
              </Form>
            </Box>
          </Stack>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <p style={{ marginTop: "0" }}>All Comments</p>
          <Divider
            style={{
              color: "#f2f2f2",
              fontSize: "1em",
              opacity: 0.4,
              height: "2px",
            }}
          />
        </Grid>
        {commentList.map((comment, ind) => (
          <Grid item xs={12} style={{ marginTop: "1em" }}>
            <div className="comment-container">
              <div>
                <img
                  src={
                    comment?.user?.image_upload
                      ? comment?.user?.image_upload
                      : userImage
                    // : "https://www.bootdey.com/img/Content/avatar/avatar2.png"
                  }
                  style={{
                    width: "2.7em",
                    height: "2.7em",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div>
                <span className="comment-container-title">
                  {comment?.user?.first_name} {comment?.user?.last_name}
                </span>
                <span className="comment-container-time">
                  {Moment(comment?.createdDate).format("MMM DD YYYY LT")}
                </span>
                <h5 className="lineBreak" style={{ marginTop: "0.6em" }}>
                  {comment?.content}
                </h5>
              </div>
            </div>
            <div className="replay-container">
              <div className="replay-container-label">Reply</div>
              {comment.replies.length > 0
                ? comment.replies.map((replay) => (
                    <div className="comment-container">
                      <div>
                        <img
                          src={
                            replay?.user?.image_upload
                              ? replay?.user?.image_upload
                              : userImage
                            // : "https://www.bootdey.com/img/Content/avatar/avatar2.png"
                          }
                          style={{
                            width: "2.7em",
                            height: "2.7em",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div>
                        <span className="comment-container-title">
                          {replay?.user?.first_name} {replay?.user?.last_name}
                        </span>
                        <span className="comment-container-time">
                          {Moment(replay?.createdDate).format("MMM DD YYYY LT")}
                        </span>
                        <h5
                          className="lineBreak"
                          style={{ marginTop: "0.6em" }}
                        >
                          {replay.content}
                        </h5>
                      </div>
                    </div>
                  ))
                : ""}
              {isEditor() ? (
                <Stack
                  direction="row"
                  spacing={1}
                  className="w-100 mt-4"
                  style={{
                    width: "100%",
                  }}
                >
                  <Box>
                    <img
                      style={{
                        marginTop: "6px",
                        width: "2.7em",
                        height: "2.7em",
                        objectFit: "cover",
                      }}
                      src={
                        currentUser.image_upload
                          ? currentUser.image_upload
                          : userImage
                        // : "https://www.bootdey.com/img/Content/avatar/avatar2.png"
                      }
                    />
                  </Box>
                  <Box style={{ flexGrow: 1 }}>
                    <Form className="new-comment-form-container">
                      <Grid item xs={12}>
                        <TextField
                          name="replaycomment"
                          onChange={getReplayText}
                          inputRef={textRef.current[ind]}
                          className="comment-text-box fix-h-8em repaly"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ float: "right", marginTop: "1em" }}>
                          <Button
                            variant="contained"
                            className="action-btn cancel"
                            style={{ marginRight: "0.5em" }}
                            onClick={() => ClearCommentText(ind)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            className="action-btn add"
                            onClick={() => addReplay(comment, ind)}
                          >
                            Add Reply
                          </Button>
                        </div>
                      </Grid>
                    </Form>
                  </Box>
                </Stack>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(ProjectComments));
