import { Box, Grid, Paper } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import TablePaginationBox from "../../../../components/TablePaginationBox";
import usePrevious from "../../../../components/usePrevious";
import {
  getCurrentLiveAuction,
  shouldRefreshLiveBiddingData,
} from "../../../../store/selectors/auctionSelector";
import GetAllSupplierRanksBL from "../../BL/GetAllSupplierRanksBL";
import CupBlackImg from "../../../../assets/auction/cup-black.png";
import CupColoredImg from "../../../../assets/auction/cup-colored.png";
import SendIcon from "@mui/icons-material/Send";
import { v1 as uuidv1 } from "uuid";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import Controls from "../../../../components/controls/Controls";
import SelectWinnerBL from "../../BL/SelectWinnerBL";
import ConfirmSendingWinnerEmails from "../confirmSendingWinnerEmails";
import { refreshLiveBiddingDataBuyer } from "../../../../store/actions/auctionAction";
import { thousandSepratorHandler } from "../../../../utility/utils";
import { AUCTION_ENDED_CAPITAL, TOAST_AUTO_CLOSE } from "../../../../constants/AppConstants";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const columns = [
  {
    label: "Rank",
    align: "right",
  },
  {
    label: "Supplier Name",
  },
  {
    label: "Current Bid",
  },
  {
    label: "Weighted Bid",
    minWidth: 270,
  },
  {
    label: "Number Of Bid",
  },
  {
    label: "Select Winner",
    id: "select_winner",
  },
];
const GeneralInfoRankTable = (props) => {
  const dispatch = useDispatch();
  const { currentLiveAuction, isOwner, setNotify, refreshLiveAuction } = props;
  const [resendApi, setResendApi] = React.useState(false);
  const [loserReasonPopup, setLoserReasonPopup] = React.useState(false);
  const [loserReasonVal, setLoserReasonVal] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [numOfPages, setNumOfPages] = React.useState(0);
  const prevPageValue = usePrevious(page);
  const [supplierList, setSupplierList] = React.useState([]);
  const [limit, setLimit] = React.useState(0);
  const [isWinnerManual, setIsWinnerManual] = React.useState(false);
  const [winners, setWinners] = React.useState([]);
  const { smScreen } = useWindowDimensions();
  const [sendEmailConfirmed, SetSendEmailConfirmed] = React.useState(false);

  useEffect(() => {
    if (
      currentLiveAuction &&
      currentLiveAuction._id &&
      currentLiveAuction.status === AUCTION_ENDED_CAPITAL &&
      // currentLiveAuction.status === "Published" &&
      !currentLiveAuction.awarding_commitment
    ) {
      setIsWinnerManual(true);
    }
    if (resendApi) {
      GetAllSupplierRanksBL({
        currentAuction: currentLiveAuction,
        page: page,
        rowsPerPage: rowsPerPage,
      }).then((data) => {
        setSupplierList(data.list);
        setLimit(data.limit);
        setWinners(data.winners);
      });
      setResendApi(false);
    }
  }, [resendApi]);

  useEffect(() => {
    if (prevPageValue !== undefined) {
      setResendApi(true);
    }
  }, [page]);

  useEffect(() => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  }, [limit, setSupplierList]);

  useEffect(() => {
    if (currentLiveAuction && currentLiveAuction._id) {
      setResendApi(true);
    }
  }, [currentLiveAuction]);

  useEffect(() => {
    if (refreshLiveAuction) {
      dispatch(refreshLiveBiddingDataBuyer(false));

      setResendApi(true);
    }
  }, [refreshLiveAuction]);

  const getColumns = () => {
    if (currentLiveAuction && currentLiveAuction._id) {
      return columns.map((column) => {
        if (column.id && column.id === "select_winner") {
          if (
            currentLiveAuction &&
            currentLiveAuction.status &&
            // currentLiveAuction.status === "Published"
            currentLiveAuction.status === AUCTION_ENDED_CAPITAL
          ) {
            return (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            );
          }
        } else {
          return (
            <TableCell key={uuidv1()} align={column.align}>
              {column.label}
            </TableCell>
          );
        }
      });
    }
    return <></>;
  };

  const checkEmailForWinner = () => {
    if (isWinnerManual) {
      if (winners.length === 0) {
        setLoserReasonPopup(true);
      } else {
        sendEmailForWinner();
      }
    }
  };

  const sendEmailForWinner = () => {
    let suppToSend = winners.map((winner) => {
      return {
        auctionId: winner?.auctionId?._id,
        supplierId: winner?.supplierId?._id,
        supplierBidId: winner?._id,
      };
    });
    SelectWinnerBL({
      winners: suppToSend,
      loserReason: loserReasonVal,
      auctionId: currentLiveAuction._id,
    }).then((data) => {
      if (data.success) {
        setResendApi(true);
      }
      setWinners([]);
      setLoserReasonVal("");
    });
  };

  useEffect(() => {
    if (sendEmailConfirmed === true) {
      SetSendEmailConfirmed(false);
      sendEmailForWinner();
    }
  }, [sendEmailConfirmed]);

  const selectWinner = (supplier) => {
    if (isWinnerManual) {
      let exist = false;
      let checkedWinner = [];
      for (let index = 0; index < winners.length; index++) {
        const winner = winners[index];
        if (winner.supplierId._id === supplier?.supplierId?._id) {
          exist = true;
        } else {
          checkedWinner.push(winner);
        }
      }
      if (exist == false) {
        checkedWinner.push(supplier);
      }
      setWinners(checkedWinner);
    } else {
      toast.error(ValidationMessage.auctionWinner, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const getWinnerIcon = (supplier) => {
    if (winners && winners.length > 0) {
      for (let index = 0; index < winners.length; index++) {
        const winner = winners[index];
        if (winner.supplierId._id === supplier?.supplierId?._id) {
          return (
            <>
              <img
                src={CupColoredImg}
                alt=""
                style={{ width: "26px", cursor: "pointer" }}
                onClick={() => selectWinner(supplier)}
              />
              <span style={{ fontSize: "12px" }}>WINNER</span>
            </>
          );
        }
      }
    }
    return (
      <img
        src={CupBlackImg}
        style={{
          width: "26px",
          cursor: "pointer",
          opacity: "0.1",
        }}
        onClick={() => selectWinner(supplier)}
      />
    );
  };

  return (
    <Paper elevation={0} square style={{ marginTop: "1.5em" }}>
      <Box sx={{ padding: "24px" }}>
        <Grid container>
          <Grid
            item
            md={4}
            xs={12}
            style={{ textAlign: smScreen ? "center" : "start" }}
          >
            {" "}
            <h3 style={{ margin: "0" }}>Rank</h3>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
            style={{
              textAlign: smScreen ? "center" : "end",
              marginTop: smScreen ? "10px" : "0px",
            }}
          >
            {isWinnerManual && isOwner() ? (
              <Controls.Button
                text="Confirm Winner"
                className="btn btn-mui"
                color="warning"
                startIcon={<SendIcon />}
                onClick={() => checkEmailForWinner()}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <Box sx={{ padding: "24px" }}>
          <TableContainer className="table-container" component={Paper}>
            <Table>
              <TableHead>
                <TableRow>{getColumns()}</TableRow>
              </TableHead>
              <TableBody>
                {supplierList.map((supplier) => (
                  <TableRow key={supplier?._id}>
                    <TableCell style={{ paddingLeft: "8px" }}>
                      {supplier?.rank}
                    </TableCell>
                    <TableCell style={{ color: "#2170ff" }}>
                      {supplier?.supplierId?.company_name}
                    </TableCell>
                    <TableCell>
                      {thousandSepratorHandler(supplier?.currentBid)}
                    </TableCell>
                    <TableCell>
                      {thousandSepratorHandler(supplier?.weightedBid)}
                    </TableCell>
                    <TableCell>{supplier?.noOfBid}</TableCell>
                    {currentLiveAuction &&
                    currentLiveAuction.status &&
                    currentLiveAuction.status === AUCTION_ENDED_CAPITAL ? (
                      // currentLiveAuction.status === "Published"
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          {getWinnerIcon(supplier)}
                        </Box>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
                {supplierList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="text-center">
                      No records found <br />
                      {/* <i style={{ color: "var(---2170ff-p-)" }}>
                        Please wait for new bid
                      </i> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
            <TablePaginationBox
              label={limit + " Suppliers Found"}
              numOfPages={numOfPages}
              onChangePage={(event, newPage) => setPage(newPage)}
              page={page}
            />
          </TableContainer>
        </Box>
      </Box>
      <ConfirmSendingWinnerEmails
        openConfrmationDailog={loserReasonPopup}
        setOpenConfrmationDailog={setLoserReasonPopup}
        isConfirmed={SetSendEmailConfirmed}
        setReason={setLoserReasonVal}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
    refreshAuction: shouldRefreshLiveBiddingData(state),
  };
};

export default withRouter(connect(mapStateToProps)(GeneralInfoRankTable));
