import React, { useEffect, useRef, useState } from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import "../../Auction/styles/create-auction-step4.css";
import "../../../styles/table.css";
import dummyItem from "../../../assets/settings/itemCoverDummy.png";
import {
  GET_SELLER_ITEM_API,
  SEND_BID_ITEM_API,
} from "../../../constants/ApiUrl";
import { Api } from "../../../api/Api";
import { useSelector } from "react-redux";
import TablePaginationBox from "../../../components/TablePaginationBox";
import { store } from "../../../store/store";
import Controls from "../../../components/controls/Controls";
import { AUCTION_COMPLETED } from "../../../constants/AppConstants";
import { isGlobalRfxDisabled } from "../../../utility/utils";
const initalColumns = [
  {
    label: "ID",
  },
  {
    label: "Image",
  },
  {
    label: "Name",
  },
  {
    label: "Manufacturer",
  },
  {
    label: "Model",
  },
  {
    label: "Unit",
  },
  {
    label: "Quantity",
  },
  {
    label: "Price",
  },
  {
    label: "Action",
  },
];

const RfxSellerItems = ({ getRfxDetails }) => {
  const rfxSupplierDetails = useSelector((state) => state.supplierRfx.rfx);
  const state = store.getState();
  const companyId = state?.auth?.auth?.user?.company?._id;
  const userId = state?.auth?.auth?.user?._id;

  const tableRowsRef = useRef([]);
  const [columns, setColumns] = useState([...initalColumns]);

  const [itemList, setItemList] = useState([]);
  const [currentRowId, setCurrentRowId] = useState("");
  const [currentRowValue, setCurrentRowValue] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const numOfPages = Math.ceil(itemList.length / rowsPerPage);

  useEffect(() => {
    if (rfxSupplierDetails.supplier_to_provide === "2") {
      const filterData = columns.filter(
        (item) => item.label !== "Price" && item.label !== "Action"
      );
      if (filterData && filterData.length > 0) setColumns([...filterData]);
    }
  }, [rfxSupplierDetails]);
  const sendEditableVaule = async (item) => {
    const values = {
      itemId: item?._id,
      editId: item?.response[0]?._id || null,
    };
    const payload = {
      rfxId: rfxSupplierDetails?._id,
      rfxItemId: values?.itemId,
      bid: parseInt(currentRowValue),
      companyId: companyId,
      userId: userId,
      bidId: values?.editId,
    };
    const response = await Api(`${SEND_BID_ITEM_API}`, payload, "post");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setCurrentRowValue("");
        setCurrentRowId("");
        getRfxDetails();
        getItemList();
      }
    }
  };

  const getItemList = async (searchText) => {
    if (rfxSupplierDetails._id !== undefined) {
      const response = await Api(
        `${GET_SELLER_ITEM_API}/${companyId}/${rfxSupplierDetails._id}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setItemList(response.data.rfxItems);
        } else {
          setItemList([]);
        }
      } else {
        setItemList([]);
      }
    }
  };

  useEffect(() => {
    tableRowsRef.current = tableRowsRef.current.slice(0, itemList.length);

    getItemList();
  }, [rfxSupplierDetails]);

  return (
    <>
      <Paper elevation={0} square className="item-seller-table p-4">
        <Paper elevation={0} square>
          <TableContainer
            className="table-container editable-table"
            component={Paper}
          >
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "700" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {itemList.map((item, index) => (
                  <TableRow
                    key={index}
                    ref={(el) => (tableRowsRef.current[index] = el)}
                    className="editable-row"
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: "center" }}
                    >
                      {item?.itemId?.itemId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <img
                        style={{
                          width: "2.7em",
                          height: "2.7em",
                          objectFit: "cover",
                        }}
                        src={item?.itemId?.image_upload || dummyItem}
                        className="table-item-img"
                        alt=""
                      />
                    </TableCell>
                    <TableCell style={{ color: "#2170FF" }}>
                      {item?.itemId?.item_name}
                    </TableCell>
                    <TableCell>{item?.itemId?.manufacturer}</TableCell>
                    <TableCell>{item?.itemId?.model}</TableCell>
                    <TableCell>{item?.itemId?.unit}</TableCell>
                    <TableCell>{item?.quantity}</TableCell>

                    {rfxSupplierDetails.supplier_to_provide === "1" && (
                      <>
                        <TableCell>
                          <Box
                            className={`editable-field ${
                              currentRowId === item._id ? "" : "__active"
                            }`}
                          >
                            {item?.response[0]?.bid
                              ? item?.response[0]?.bid / item?.quantity
                              : "--"}
                          </Box>

                          <Box
                            className={`editable-field ${
                              currentRowId === item._id ? "__active" : ""
                            }`}
                          >
                            <TextField
                              id={`price${item?._id}`}
                              name={`price${item?._id}`}
                              variant="outlined"
                              // defaultValue={item?.response[0]?.bid}
                              value={currentRowValue}
                              onChange={(e) =>
                                setCurrentRowValue(e.target.value)
                              }
                              fullWidth
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <>
                            <Box
                              className={`option-cell
                        ${currentRowId === item._id ? "" : "__active"}
                        `}
                            >
                              <Controls.Button
                                type="button"
                                className={`badge item-add-action ${
                                  item?.response[0]?.bid
                                    ? "item-edit-action"
                                    : "badge-primary"
                                } ${
                                  isGlobalRfxDisabled(
                                    rfxSupplierDetails?.supplierRfx
                                      ?.isSubmitted,
                                    rfxSupplierDetails.status,
                                    rfxSupplierDetails?.supplierRfx?.rfxStatus
                                  )
                                    ? "button-disabeled"
                                    : ""
                                }`}
                                text={`${
                                  item?.response[0]?.bid ? "Edit" : "Add"
                                } Price`}
                                onClick={() => {
                                  setCurrentRowId(item?._id);
                                  setCurrentRowValue(
                                    item?.response[0]?.bid / item?.quantity ||
                                      ""
                                  );
                                }}
                                disabled={isGlobalRfxDisabled(
                                  rfxSupplierDetails?.supplierRfx?.isSubmitted,
                                  rfxSupplierDetails.status,
                                  rfxSupplierDetails?.supplierRfx?.rfxStatus
                                )}
                              />
                            </Box>
                            <Box
                              className={`option-cell
                        ${currentRowId === item._id ? "__active" : ""}
                        `}
                            >
                              <CheckIcon
                                className="icon __check"
                                onClick={() => {
                                  sendEditableVaule(item);
                                }}
                              />
                              <CloseIcon
                                className="icon __close"
                                onClick={() => {
                                  setCurrentRowId("");
                                  setCurrentRowValue("");
                                }}
                              />
                            </Box>
                          </>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
                {itemList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="text-center">
                      No records found <br />
                      {/* <i style={{ color: "var(---2170ff-p-)" }}>
                        Please add some items
                      </i> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
            <TablePaginationBox
              label={itemList.length + " Items Found"}
              numOfPages={numOfPages}
              onChangePage={(event, newPage) => setPage(newPage)}
              page={page}
            />
          </TableContainer>
        </Paper>
      </Paper>
    </>
  );
};

export default RfxSellerItems;
