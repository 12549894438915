import { updateEventStack } from "../../../store/actions/socketAction";
import { refreshLiveBiddingDataSupplier } from "../../../store/actions/supplierAspect/auctionAction";
import { NEW_BIDIN_SYSTEM_EVENT } from "../constants";
import SocketHandler from "../SocketHandler";

const newBidInSystemEvent = () => {
  SocketHandler.instance.on(NEW_BIDIN_SYSTEM_EVENT, (response) => {
    SocketHandler.dispatcher(refreshLiveBiddingDataSupplier(true));
    // SocketHandler.dispatcher(
    //   updateLiveData({
    //     best: response?.data?.bestBid,
    //     new: response?.data?.myCurrentBid,
    //   })
    // );
    SocketHandler.dispatcher(updateEventStack(NEW_BIDIN_SYSTEM_EVENT));
  });
  return true;
};

export default newBidInSystemEvent;
