/*eslint-disable */
import { Box, Grid, makeStyles } from "@material-ui/core";
import { v1 as uuidv1 } from "uuid";
import doneIcon from "../assets/icons/Check-Stepper@2x.png";
export default function Formsteps(props) {
  const { currStep, steps, ...other } = props;

  const getSteps = () => {
    let UISteps = [];

    for (let stepNum = 1; stepNum <= steps.length; stepNum++) {
      const stepLabel = steps[stepNum - 1];
      if (stepNum < currStep) {
        UISteps.push(
          <div className="stepitem">
            <Box className="step-num __active">
              <img
                // src="/assets/icons/Check-Stepper@2x.png"
                src={doneIcon}
                className="StepControlIcon"
              />
            </Box>
            <Box>
              <div className="step-text __active">{stepLabel}</div>
            </Box>
          </div>
        );
      } else if (stepNum == currStep) {
        UISteps.push(
          <div className="stepitem">
            <Box className="step-num __active">
              <div>{stepNum}</div>
            </Box>
            <Box>
              <div className="step-text __active">{stepLabel}</div>
            </Box>
          </div>
        );
      } else {
        UISteps.push(
          <div className="stepitem">
            <Box className="step-num">
              <div>{stepNum}</div>
            </Box>
            <Box>
              <div className="step-text">{stepLabel}</div>
            </Box>
          </div>
        );
      }
    }

    return UISteps;
  };

  return (
    <Grid container spacing={1} {...other}>
      <Grid item xs={12} className="form-stepper-label-container">
        {getSteps().map((step, index) => {
          let item = [step];
          if (index !== steps.length - 1) {
            item.push(
              <Grid item className="divider-container" key={uuidv1()}>
                <div className="line"></div>
              </Grid>
            );
          }
          return item;
        })}
      </Grid>
    </Grid>
  );
}
