import { CircularProgress, Grid } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import "../../Auction/styles/import-item-popup.css";
import templateSupplierCSV from "../../../assets/csvDataFile/OKTIO_template_supplier.csv";
import { Api } from "../../../api/Api";
import {
  POST_SUPPLIER_FILE_API,
  POST_SUPPLIER_SAVE_IMPORT_DATA_API,
} from "../../../constants/ApiUrl";
import { useState } from "react";
import {
  ACEPT_IMPORT_ATTACHMENTS,
  SUPPLIER_ATTACHMENTS_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../../constants/AppConstants";
import { Slide, toast } from "react-toastify";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { store } from "../../../store/store";

const ImportSupplierList = ({ getAllSuppliers, setOpenPopup }) => {
  const [showLoading, setShowLoading] = useState(false);
  const state = store.getState();
  const companyDetails = state?.auth?.auth?.user?.company_name;

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (ACEPT_IMPORT_ATTACHMENTS.includes(file.type)) {
      if (Math.round(file.size / 1024) > SUPPLIER_ATTACHMENTS_SIZE) {
        toast.error(ValidationMessage.supplierImportFileSize, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
    } else {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }

    setShowLoading(true);
    const formdata = new FormData();
    formdata.append("csvFile", file);
    const response = await Api(
      POST_SUPPLIER_FILE_API,
      formdata,
      "postMultipart"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        const payload = {
          company_name: companyDetails.company_name,
          suppliers: [...response.data.suppliersArray],
        };
        const innerResponse = await Api(
          `${POST_SUPPLIER_SAVE_IMPORT_DATA_API}/${companyDetails._id}/suppliers/addMultipleSuppliers`,
          payload,
          "post"
        );

        if (innerResponse.status === 200) {
          if (innerResponse.data.responseCode === "00") {
            setShowLoading(false);
            setOpenPopup(false);
            getAllSuppliers();
          } else {
            setShowLoading(false);
            setOpenPopup(false);
          }
        }
      } else {
        setShowLoading(false);
        setOpenPopup(false);
      }
    } else {
      setShowLoading(false);
      setOpenPopup(false);
    }
    e.target.value = "";
  };

  return (
    <>
      <Grid container justifyContent="center" className="pt-15 supplier-import">
        {!showLoading ? (
          <>
            <Grid item md={12} xs={12}>
              <Grid container alignItems="center">
                <a
                  href={templateSupplierCSV}
                  download="OKTIO_template_items.csv"
                  className="btn btn-primary btn-mui import-item-btn w-100"
                >
                  <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
                    <DownloadIcon className="import-download-icn" /> Download
                    Template Spredsheet
                  </span>
                </a>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" className="mt-1 mb-2">
                <Grid item xs={5}>
                  <div className="hr-devider-import-file">
                    <hr className="solid" />
                  </div>
                </Grid>
                <Grid item xs={2} className="text-center">
                  <p className="devider-center-text">and</p>
                </Grid>
                <Grid item xs={5}>
                  <div className="hr-devider-import-file">
                    <hr className="solid" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container alignItems="center">
                <label
                  for="fileUploadButton"
                  className="w-100 text-center browse-file-supplier"
                >
                  Browse File
                </label>
                <input
                  type="file"
                  id="fileUploadButton"
                  hidden
                  onChange={(e) => handleImageChange(e)}
                  accept={ACEPT_IMPORT_ATTACHMENTS}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-10">
              <p className="import-item-footer-description">
                Once finished, upload the template Spredsheet. <br />
                All .xls file are supported. The maximum file size is 8mb.
              </p>
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={12} xs={12} className="global-flex-center">
              <CircularProgress color="primary" size={54} />
            </Grid>
            <Grid item md={12} xs={12} className="global-flex-center">
              <h3 className="mt-3 mb-5 b-600">Processing...</h3>
            </Grid>
          </>
        )}

        {/* <CommonButton
          text="Okay"
          className="btn-mui mt-4"
          variant="contained"
          onClick={() => {}}
          loader={!showLoading}
         
        /> */}
      </Grid>
    </>
  );
};

export default ImportSupplierList;
