import { INIT_SOCKET_EVENT } from "../constants";
import SocketHandler from "../SocketHandler";
import SocketResponse from "../SocketResponse";

const InitSocketEvent = (data = {}) => {
  SocketHandler.instance.emit(
    INIT_SOCKET_EVENT,
    new SocketResponse(data).transform()
  );
  return true;
};

export default InitSocketEvent;
