import { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import { InputAdornment, TextField } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import Search from "@material-ui/icons/Search";
import Moment from "moment";
import { store } from "../../../../store/store";
import "../../../Auction/styles/details-suplliers.css";
import { Api } from "../../../../api/Api";
import { COMAPNY_API, POST_ADD_LIBRARY_RFX_API } from "../../../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../../../constants/AppConstants";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";
import { useSelector } from "react-redux";

export default function AddFromLibraryPopup(props) {
  const {
    setOpenPopupLib,
    docsList,
    setDocsList,
    getDocumets,
  } = props;
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);

  const [checked, setChecked] = useState([]);

  const [checkedAll, setCheckedAll] = useState(false);

  const checkallBoxes = (e) => {
    if (!e.target.value) {
      setCheckedAll(false);
      const checked = [];
      const docLi = [...checked];
      setChecked(docLi);
    } else {
      setCheckedAll(true);
      let neItems = [];
      docsList.filter((i) => {
        checked.push(i._id);
      });
      const docLi = [...checked];
      setChecked(docLi);
    }
  };

  const columns = [
    {
      label: "Template Document Name",
    },
    {
      label: "Date Added",
    },
    {
      label: (
        <div className="checkbox checkbox-primary">
          <Controls.Checkbox
            name="checkall"
            color="primary"
            value={checkedAll}
            checked={checkedAll}
            onChange={checkallBoxes}
          />
        </div>
      ),
    },
  ];

  const checkBoxHandle = (e) => {
    const { name, value } = e.target;
    if (value) {
      const index = checked.findIndex((x) => x === name);
      if (!checked || index == -1) {
        checked.push(name);
        const neItems = [...checked];
        setChecked(neItems);
      } else {
        setChecked(checked.filter((item) => item !== name));
      }
    } else {
      const index = checked.findIndex((x) => x === name);
      if (index > -1) {
        checked.splice(index, 1);
        const neItems = [...checked];
        setChecked(neItems);
      }
    }
  };

  const attachSelectedDocs = async () => {
    if (checked && checked.length > 0) {
      if (rfxDetails !== undefined && rfxDetails._id !== undefined) {
        const payload = {
          docs: checked,
        };
        const response = await Api(
          `${POST_ADD_LIBRARY_RFX_API}/${rfxDetails._id}`,
          payload,
          "post"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            setOpenPopupLib(false);
            getDocumets();
          }
        }
      } else {
        toast.error(ValidationMessage.somethingWrong, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    }
  };

  const requestSearch = async (searchedVal) => {
    const textVal = searchedVal.target.value;
    if (textVal) {
      const filteredRows = docsList.filter((row) => {
        return row?.document?.fileName
          .toLowerCase()
          ?.includes(textVal.toLowerCase());
      });
      setDocsList(filteredRows);
    } else {
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      if (companyId) {
        const response = await Api(
          `${COMAPNY_API}/${companyId}/library`,
          {},
          "get"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            let allDocs = response.data.library;
            setDocsList(allDocs);
          } else {
            setDocsList([]);
          }
        } else {
          setDocsList([]);
        }
      }
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      {/* <Grid container spacing={2}> */}
      <Grid container spacing={2}>
        <Box sx={{ display: "flex", p: 0 }} className="SearchBox">
          <Box sx={{ p: 1, flexGrow: 1 }} style={{ padding: "0" }}>
            <Box style={{ position: "relative" }}>
              <TextField
                onChange={requestSearch}
                placeholder="Search Using Keyword"
                className="SearchInputList"
                style={{
                  backgroundImage: "url(/assets/icons/search-1@2x.png)",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
        <TableContainer component={Paper} className="T3columns">
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {docsList.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>
                    <AssignmentOutlinedIcon />
                    <span
                      style={{ color: "#2170FF" }}
                      className="TextAfterImage"
                    >
                      {item && item.document ? item?.document?.name : ""}
                    </span>
                  </TableCell>
                  <TableCell>
                    {Moment(item.createdDate).format("DD-MM-YYYY LT")}
                  </TableCell>

                  <TableCell>
                    <div className="checkbox checkbox-primary">
                      <Controls.Checkbox
                        name={item?._id}
                        color="primary"
                        value={checked.includes(item?._id) ? item?._id : ""}
                        checked={checked.includes(item?._id) ? item?._id : ""}
                        onChange={checkBoxHandle}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <p className="table-record-count-libarary">
          {docsList.length} Documents Found
        </p>
      </Grid>

      <Box
        className="BottomModal"
        display="flex"
        p={1}
        bgcolor="background.paper"
      >
        <Grid istem xs={4} className="BottomModal-left"></Grid>
        <Grid item xs={8} className="BottomModal-right">
          <Box p={0}>
            <Controls.Button
              text="Cancel"
              disableElevation
              onClick={() => {
                setOpenPopupLib(false);
              }}
              style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
              color="default"
              className="btn-mui"
            />
          </Box>
          <Box p={0}>
            <Controls.Button
              disableElevation
              text="Attach"
              className="btn-mui"
              onClick={() => attachSelectedDocs()}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
}
