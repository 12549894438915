import { UPDATE_SERVER_LIVE_CLOCK } from "../constant/serverConstant";



export function setLiveServerClock(data) {
  return {
    type: UPDATE_SERVER_LIVE_CLOCK,
    payload: data,
  };
}
