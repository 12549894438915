/*eslint-disable */
import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { InputLabel, Button } from "@mui/material";
import useWindowDimensions from "../../../components/useWindowDimensions";
import Controls from "../../../components/controls/Controls";
import { useForm, Form } from "../../../components/useForm";
import "../style/item-edit-board-details.css";
import { store } from "../../../store/store";
import Moment from "moment";
import { Api } from "../../../api/Api";
import {
  COMAPNY_API,
  GET_ITEM_DETAILS_API,
  POST_EDIT_ITEM_API,
} from "../../../constants/ApiUrl";
import UserImage from "../../../assets/auth/user_icon_2.png";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { TOAST_AUTO_CLOSE } from "../../../constants/AppConstants";
import { toast, Slide } from "react-toastify";
import CommonButton from "../../../components/controls/CommonButton";

const initialFValues = {
  item_name: "",
  manufacturer: "",
  notes: "",
  unit: "",
  category: "",
  model: "",
  group: "",
  tags: "",
};

export default function ItemEditboardDetails(props) {
  const { item, setNotify } = props;
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const [editMode, setEditMode] = React.useState(false);
  const [dropdownCategoryList, setDropdownCategoryList] = React.useState([]);
  const [dropdownGroupList, setDropdownGroupList] = React.useState([]);
  const [itemDetail, setItemDetail] = React.useState({});
  const [tags, setTags] = React.useState([]);
  const [tempTag, setTempTag] = React.useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("item_name" in fieldValues)
      temp.item_name = fieldValues.item_name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editMode == true) {
      if (validate()) {
        if (item && item?._id) {
          values.item_name = values.item_name.trim();
          values.tags = tags;
          setSaveLoader(true);
          const response = await Api(
            `${POST_EDIT_ITEM_API}/${item?._id}`,
            values,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setEditMode(!editMode);
            }
          }
          setSaveLoader(false);

          // axiosInstance
          //   .patch(`api/buyer/item/editItem/${item?._id}`, values)
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setEditMode(!editMode);
          //       setNotify({
          //         isOpen: true,
          //         message: "Item updated successfully",
          //         type: "success",
          //       });
          //     } else {
          //       setNotify({
          //         isOpen: true,
          //         message: response?.data?.responseDescription,
          //         type: "error",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //   });
        }
      } else {
        toast.error(ValidationMessage.somethingWrong, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    } else {
      setEditMode(!editMode);
    }
  };

  const getCategoryName = (cateId) => {
    const CateItem = dropdownCategoryList.find(
      (element) => element._id === cateId
    );
    return CateItem ? CateItem.name : "";
  };
  const getGroupName = (cateId) => {
    const CateItem = dropdownGroupList.find(
      (element) => element._id === cateId
    );
    return CateItem ? CateItem.name : "";
  };

  const getAllCategory = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${COMAPNY_API}/getItemsCatGroups/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let CatAndGroups = response.data.itemsCatAndGroups;
          let groupArr = [];
          let cateArr = [];
          CatAndGroups.map((group) => {
            if (group.itemType === "group") {
              groupArr.push(group);
            } else if (group.itemType === "category") {
              cateArr.push(group);
            }
          });
          setDropdownCategoryList(cateArr);
          setDropdownGroupList(groupArr);
        }
      }

      // await axiosInstance
      //   .get(`api/buyer/company/getItemsCatGroups/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let CatAndGroups = response.data.itemsCatAndGroups;
      //       let groupArr = [];
      //       let cateArr = [];
      //       CatAndGroups.map((group) => {
      //         if (group.itemType === "group") {
      //           groupArr.push(group);
      //         } else if (group.itemType === "category") {
      //           cateArr.push(group);
      //         }
      //       });
      //       setDropdownCategoryList(cateArr);
      //       setDropdownGroupList(groupArr);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const addNewAdditionalActivity = (e) => {
    if (tempTag !== "") {
      tags.push(tempTag);
      const neItems = [...tags];
      setTags(neItems);
      setTempTag("");
    } else {
      toast.error(ValidationMessage.enterText, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const removeSelectTag = (index) => {
    const removeIndex = tags.splice(index, 1);
    const neItems = [...tags];
    setTags(neItems);
  };

  React.useEffect(() => {
    getAllCategory();
    getItemdetails();
  }, [item]);
  const getItemdetails = async () => {
    if (item && item?._id) {
      const response = await Api(
        `${GET_ITEM_DETAILS_API}/${item?._id}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let itemDetail = response.data.item;
          setValues({
            item_name: itemDetail?.item_name,
            manufacturer: itemDetail?.manufacturer,
            notes: itemDetail?.notes,
            unit: itemDetail?.unit,
            category: itemDetail?.category,
            model: itemDetail?.model,
            group: itemDetail?.group,
            tags: itemDetail?.tags,
          });
          setItemDetail(itemDetail);
          setTags(itemDetail?.tags);
        }
      }

      // axiosInstance
      //   .get(`api/buyer/item/itemDetail/${item?._id}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let itemDetail = response.data.item;
      //       setValues({
      //         item_name: itemDetail?.item_name,
      //         manufacturer: itemDetail?.manufacturer,
      //         notes: itemDetail?.notes,
      //         unit: itemDetail?.unit,
      //         category: itemDetail?.category,
      //         model: itemDetail?.model,
      //         group: itemDetail?.group,
      //         tags: itemDetail?.tags,
      //       });
      //       setItemDetail(itemDetail);
      //       setTags(itemDetail?.tags);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };
  return item && item.status ? (
    <Grid container className="item-edit-board-details-container">
      <Form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <h3
              style={{ opacity: editMode ? "1" : "0" }}
              className="title-header"
            >
              Edit Info Item
            </h3>
            <CommonButton
              className="btn-primary-lite edit-btn"
              text={editMode ? "save" : "Edit"}
              type="submit"
              loader={saveLoader}
              loaderSize={14}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={editMode ? "d-none" : "d-block"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Category</InputLabel>
              <p>{getCategoryName(values.category) || "-"}</p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Group</InputLabel>
              <p>{getGroupName(values.group) || "-"}</p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Manufacturer</InputLabel>
              <p>{values.manufacturer || "-"}</p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Extermal ID</InputLabel>
              <p>{values.itemId}</p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Model</InputLabel>
              <p>{values.model}</p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Unit</InputLabel>
              <p>{values.unit}</p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Internal Notes</InputLabel>
              <p>{values.notes}</p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mb-2">Tags</InputLabel>
              <Box display="flex" flexWrap="wrap" spacing={2}>
                {tags && tags.length > 0 ? (
                  tags.map((tag) => (
                    <div className="badge badge-primary-outline tag">
                      <span>{tag}</span>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: "20vh" }}></div>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <img
                  src={itemDetail?.userId?.image_upload || UserImage}
                  style={{
                    width: "2.7em",
                    height: "2.7em",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
                <div className="ml-2">
                  <InputLabel>Created</InputLabel>
                  <div>
                    {Moment(itemDetail?.createdDate).format("MMMM DD, YYYY")}{" "}
                    {itemDetail?.userId?.first_name}{" "}
                    {itemDetail?.userId?.last_name}
                  </div>
                </div>
              </Box>
            </Grid>
            {itemDetail && itemDetail?.updatedDate ? (
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <img
                    src={itemDetail?.userId?.image_upload || UserImage}
                    style={{
                      width: "2.7em",
                      height: "2.7em",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="ml-2">
                    <InputLabel>Last update</InputLabel>
                    <div>
                      {Moment(itemDetail?.updatedDate).format("MMMM DD, YYYY")}{" "}
                      {itemDetail?.userId?.first_name}{" "}
                      {itemDetail?.userId?.last_name}
                    </div>
                  </div>
                </Box>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={!editMode ? "d-none" : "d-block"}>
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel>Item Name</InputLabel>
                <Controls.Input
                  name="item_name"
                  fullWidth
                  value={values.item_name}
                  onChange={handleInputChange}
                  error={errors.item_name}
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="category-input">Category</InputLabel>
                <Controls.Select
                  name="category"
                  className="w-100"
                  labelId="category-input"
                  fullWidth
                  options={dropdownCategoryList}
                  value={values.category}
                  onChange={handleInputChange}
                  error={errors.category}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="group-input">Group</InputLabel>
                <Controls.Select
                  name="group"
                  labelId="group-input"
                  value={values.group}
                  onChange={handleInputChange}
                  options={dropdownGroupList}
                  error={errors.group}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Manufacturer</InputLabel>
                <Controls.Input
                  name="manufacturer"
                  fullWidth
                  value={values.manufacturer}
                  onChange={handleInputChange}
                  error={errors.manufacturer}
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>External ID</InputLabel>
                <Controls.Input
                  name="external_id"
                  fullWidth
                  value="EQ12788021"
                  readonly
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Model</InputLabel>
                <Controls.Input
                  name="model"
                  fullWidth
                  value={values.model}
                  onChange={handleInputChange}
                  error={errors.model}
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Unit</InputLabel>
                <Controls.Input
                  name="unit"
                  fullWidth
                  value={values.unit}
                  onChange={handleInputChange}
                  error={errors.unit}
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="down-period-input">Internal Notes </InputLabel>
                <Controls.Input
                  name="notes"
                  className="fix-h-12em"
                  inputProps={{ maxLength: 499 }}
                  value={values.notes}
                  onChange={handleInputChange}
                  error={errors.notes}
                  multiline="multiline"
                  Rows={4}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <InputLabel style={{ float: "right" }}>0/499</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="mb-2">Tags</InputLabel>
                <Box display="flex" flexWrap="wrap" spacing={2}>
                  {tags && tags.length > 0 ? (
                    tags.map((tag, index) => (
                      <div className="badge badge-primary-outline tag">
                        <span>{tag}</span>
                        <CloseIcon onClick={() => removeSelectTag(index)} />
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item sm={8} xs={12}>
                    <Controls.Input
                      name="new_activty"
                      fullWidth
                      inputProps={{ maxLength: 200 }}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      value={tempTag}
                      onChange={(e) => setTempTag(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Button
                      variant="contained"
                      className="btn btn-light-blue btn-mui text-transform-cap w-100"
                      onClick={() => addNewAdditionalActivity()}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Form>
    </Grid>
  ) : (
    <></>
  );
}
