export const GET_PROJECTS_REQUEST = "[Projects Action] Get Projects Request";
export const GET_PROJECTS = "[Projects Action] Get Projects";
export const GET_PROJECTS_FAIL = "[Projects Action] Get Projects Fail";
export const GET_PROJECTS_SUCCESS = "[Projects Action] Get Projects Success";
export const CONFIRMED_GET_PROJECTS =
  "[Projects Action] Confirmed Get Projects";
export const ADD_FIRST_FORM = "[Projects Action] Add First Form";
export const PROJECTS_ADD_REQUEST = "[Projects Action] Add First Form Request";
export const ADD_SECOND_FORM = "[Projects Action] Add Second Form Request";
export const EDIT_PROJECT_FORM = "[Projects Action] Editing a Project";
export const CLEAR_PROJECT_AFTER_SUBMIT = "[Projects Action] Clear project data";
export const SAVE_SECOND_FORM_DATA_TEMP = "[Projects Action] save form 2 data as temp";

