import { Api } from "../../api/Api";
import {
  AUCTION_SAVE_API,
  POST_AUCTION_DRAFT_API,
} from "../../constants/ApiUrl";
import {
  SAVE_AUCTION_STEP_1_REQUEST,
  SAVE_AUCTION_STEP_1,
  SAVE_AUCTION_STEP_2_REQUEST,
  SAVE_AUCTION_STEP_2,
  SAVE_AUCTION_STEP_3_REQUEST,
  SAVE_AUCTION_STEP_3,
  SAVE_AUCTION_STEP_4_REQUEST,
  SAVE_AUCTION_STEP_4,
  SAVE_AUCTION_STEP_5_REQUEST,
  SAVE_AUCTION_STEP_5,
  SAVE_AUCTION_PROJECT_DATA_REQUEST,
  SAVE_AUCTION_PROJECT_DATA,
  SAVE_AUCTION_PROJECT_LINK_REQUEST,
  SAVE_AUCTION_PROJECT_LINK,
  CLEAR_REDUX_ON_SAVED_SUCCESS,
  SAVE_AUCTION_STATUS,
  SAVE_INVITE_NEW_SUPPLIER_REQUEST,
  SAVE_INVITE_NEW_SUPPLIER,
  SAVE_LIB_DOCUMENT_REQUEST,
  SAVE_LIB_DOCUMENT,
  TEMP_SAVE_LIB_DOCUMENT_REQUEST,
  TEMP_SAVE_LIB_DOCUMENT,
  GET_LIVE_AUCTION_DETAILS,
  REFRESH_LIVE_BIDDING_DATA_BUYER,
  REFRESH_LIVE_AUCTION_BUYER,
} from "../constant/auctionConstant";

export const projectDetails = (values) => (dispatch, getState) => {
  dispatch({
    type: SAVE_AUCTION_PROJECT_DATA_REQUEST,
  });
  dispatch({
    type: SAVE_AUCTION_PROJECT_DATA,
    payload: values,
  });
};
export const FormStep1Add = (values) => (dispatch, getState) => {
  const { auction } = getState();
  const projectData = Object.assign(auction.AuctionForm1, values);
  dispatch({
    type: SAVE_AUCTION_STEP_1_REQUEST,
  });
  dispatch({
    type: SAVE_AUCTION_STEP_1,
    payload: projectData,
  });
};

export const FormStep2Add = (values) => (dispatch, getState) => {
  const { auction } = getState();
  const form1Data = Object.assign(auction.AuctionForm1, values);
  
  dispatch({
    type: SAVE_AUCTION_STEP_2_REQUEST,
  });
  dispatch({
    type: SAVE_AUCTION_STEP_2,
    payload: form1Data,
  });
};

export const FormStep3Add = (values) => (dispatch, getState) => {
  const { auction } = getState();
  const form1And2Data = Object.assign(auction.AuctionForm1, values);
  dispatch({
    type: SAVE_AUCTION_STEP_3_REQUEST,
  });
  dispatch({
    type: SAVE_AUCTION_STEP_3,
    payload: form1And2Data,
  });
};

export const FormStep4Add = (values) => (dispatch, getState) => {
  const { auction } = getState();
  const form1And2Data = Object.assign(auction.AuctionForm1, values);
  dispatch({
    type: SAVE_AUCTION_STEP_4_REQUEST,
  });
  dispatch({
    type: SAVE_AUCTION_STEP_4,
    payload: form1And2Data,
  });
};

export const AddLinkData = (values) => (dispatch, getState) => {
  const { auction } = getState();
  const form1And2Data = Object.assign(auction.AuctionForm1, values);
  dispatch({
    type: SAVE_AUCTION_PROJECT_LINK_REQUEST,
  });
  dispatch({
    type: SAVE_AUCTION_PROJECT_LINK,
    payload: form1And2Data,
  });
};

export const AddLibraryDocs = (values) => (dispatch, getState) => {
  const { auction } = getState();
  const form1And2Data = Object.assign(auction.AuctionForm1, values);
  dispatch({
    type: SAVE_LIB_DOCUMENT_REQUEST,
  });
  dispatch({
    type: SAVE_LIB_DOCUMENT,
    payload: form1And2Data,
  });
};

export const AddLibraryDocsTemp = (values) => (dispatch, getState) => {
  const { auction } = getState();
  const form1And2Data = Object.assign(auction.AuctionForm1, values);
  dispatch({
    type: TEMP_SAVE_LIB_DOCUMENT_REQUEST,
  });
  dispatch({
    type: TEMP_SAVE_LIB_DOCUMENT,
    payload: form1And2Data,
  });
};

export const AddNewInviteSupplierInArray = (values) => (dispatch, getState) => {
  const { auction } = getState();
  const form1And2Data = Object.assign(auction.AuctionForm1, values);
  dispatch({
    type: SAVE_INVITE_NEW_SUPPLIER_REQUEST,
  });
  dispatch({
    type: SAVE_INVITE_NEW_SUPPLIER,
    payload: form1And2Data,
  });
};

export const SubmitAuction = (values) => (dispatch, getState) => {
  const { auction } = getState();
  const form1And2Data = Object.assign(auction.AuctionForm1, values);
  dispatch({
    type: SAVE_AUCTION_STEP_5_REQUEST,
  });
  dispatch({
    type: SAVE_AUCTION_STEP_5,
    payload: form1And2Data,
  });
};

export const AddSupplier = (value) => {
  return async (dispatch, getState) => {
    const {
      auction,
      auth: { auth },
    } = getState();
    const link = [
      {
        link: "",
        title: "",
        notes: "",
      },
    ];
    const form1And2Data = auction.AuctionForm1;
    let formData = new FormData();

    let OldSupplierArray = [];
    let newSupplierArray = [];
    if (form1And2Data && form1And2Data.Suppliers) {
      const supplierArray = form1And2Data.Suppliers;
      supplierArray.forEach((element) => {
        if (element && element.isInvieted) {
          newSupplierArray.push(element);
        } else {
          OldSupplierArray.push(element);
        }
      });
    }
    formData.append("projectId", form1And2Data.project_id);
    formData.append("auction_type", form1And2Data.auction_type);
    formData.append("auction_name", form1And2Data.name);
    formData.append("description", form1And2Data.description);
    formData.append("startDate", form1And2Data.startDate);
    formData.append("endDate", form1And2Data.endDate);
    formData.append("starting_price", form1And2Data.starting_price);
    formData.append("minimum_step", form1And2Data.min_step);
    formData.append("currency", form1And2Data.currency);
    if (form1And2Data?.const_center && form1And2Data?.const_center.length > 0)
      formData.append("cost_center", form1And2Data.const_center);
    if (form1And2Data?.budget && form1And2Data?.budget.length > 0)
      formData.append("budget", form1And2Data.budget);
    formData.append("cool_down", form1And2Data.cool_down_period);
    formData.append(
      "awarding_commitment",
      form1And2Data.awarding_commitment || false
    );
    if (form1And2Data?.deliveryDate) {
      formData.append("deliveryDate", form1And2Data.deliveryDate);
    }
    if (form1And2Data && form1And2Data.show_to_supplier !== "") {
      formData.append("show_to_supplier", form1And2Data?.show_to_supplier);
    } else {
      formData.append("show_to_supplier", "0");
    }
    formData.append("reserve_price", form1And2Data?.reserve_price || 0);
    formData.append(
      "number_of_participants",
      form1And2Data?.no_of_participants || 0
    );
    formData.append(
      "disclose_suppliers_bid",
      form1And2Data?.supplier_bid || false
    );
    formData.append(
      "disclose_suppliers_name",
      form1And2Data?.supplier_name || false
    );
    formData.append(
      "disclose_starting_price",
      form1And2Data?.supplier_stating_price_event || false
    );
    formData.append("items", JSON.stringify(form1And2Data.Items));
    formData.append("suppliers", JSON.stringify(OldSupplierArray));
    if (
      form1And2Data &&
      form1And2Data.files &&
      form1And2Data.files.length > 0
    ) {
      for (let i = 0; i < form1And2Data.files.length; i++) {
        formData.append(`documents[${i}]`, form1And2Data.files[i]);
      }
    }
    if (form1And2Data && form1And2Data.links) {
      formData.append("links", JSON.stringify(form1And2Data.links));
    } else {
      formData.append("links", JSON.stringify(link));
    }
    formData.append("companyId", auth.user?.company?._id);
    formData.append("createdBy", auth.user?._id);
    formData.append(
      "auctionId",
      "A" + Math.floor(100000 + Math.random() * 900000)
    );
    if (newSupplierArray.length > 0) {
      formData.append(
        "selectedInviteNewSupplier",
        JSON.stringify(newSupplierArray)
      );
    }
    if (
      form1And2Data &&
      form1And2Data.libDocs &&
      form1And2Data.libDocs.length > 0
    ) {
      formData.append("docs", JSON.stringify(form1And2Data.libDocs));
    }
    let returnRes;
    const response = await Api(AUCTION_SAVE_API, formData, "postMultipart");

    // await axiosInstance
    //   .post(`api/buyer/company/addAuction`, formData)
    //   .then((response) => {
    returnRes = response;
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        //  let addedProjects = response.data;
        dispatch({
          type: CLEAR_REDUX_ON_SAVED_SUCCESS,
          payload: {},
        });
        dispatch({
          type: SAVE_AUCTION_STATUS,
          payload: true,
          code: 200,
          message: "Auction has been created successfully !",
          success: true,
        });
      } else {
        dispatch({
          type: SAVE_AUCTION_STATUS,
          payload: false,
          code: response.status,
          message: response.data.responseDescription
            ? response.data.responseDescription
            : "creating auction has been failed",
          success: false,
        });
      }
    } else {
      // })
      // .catch((err) => {
      // returnRes = response;

      dispatch({
        type: SAVE_AUCTION_STATUS,
        payload: false,
        code: response.status,
        message: response?.data?.responseDescription,
        success: false,
      });
      // });
    }
    return returnRes;
  };
};

export const auctionSaveAsDraft = (values) => {
  return async (dispatch, getState) => {
    const {
      auction,
      auth: { auth },
    } = getState();

    const form1And2Data = auction.AuctionForm1;
    let formData = new FormData();

    let OldSupplierArray = [];
    let newSupplierArray = [];
    if (form1And2Data && form1And2Data.Suppliers) {
      const supplierArray = form1And2Data.Suppliers;
      supplierArray.forEach((element) => {
        if (element && element.isInvieted) {
          newSupplierArray.push(element);
        } else {
          OldSupplierArray.push(element);
        }
      });
    }
    formData.append("projectId", form1And2Data.project_id);
    formData.append("auction_type", form1And2Data.auction_type);
    formData.append("auction_name", form1And2Data.name);
    formData.append("description", form1And2Data.description);
    formData.append("startDate", form1And2Data.startDate);
    formData.append("endDate", form1And2Data.endDate);
    formData.append("starting_price", form1And2Data.starting_price);
    formData.append("minimum_step", form1And2Data.min_step);
    formData.append("currency", form1And2Data.currency);
    if (form1And2Data?.deliveryDate) {
      formData.append("deliveryDate", form1And2Data.deliveryDate);
    }
    if (form1And2Data?.const_center && form1And2Data?.const_center.length > 0)
      formData.append("cost_center", form1And2Data.const_center);
    if (form1And2Data?.budget && form1And2Data?.budget.length > 0)
      formData.append("budget", form1And2Data.budget);

    formData.append("cool_down", form1And2Data.cool_down_period);
    formData.append("awarding_commitment", form1And2Data.awarding_commitment);
    formData.append("show_to_supplier", form1And2Data?.show_to_supplier || "0");
    formData.append("reserve_price", form1And2Data?.reserve_price || 0);
    formData.append(
      "number_of_participants",
      form1And2Data?.no_of_participants || 0
    );
    formData.append(
      "disclose_suppliers_bid",
      form1And2Data?.supplier_bid || false
    );
    formData.append(
      "disclose_suppliers_name",
      form1And2Data?.supplier_name || false
    );
    formData.append(
      "disclose_starting_price",
      form1And2Data?.supplier_stating_price_event || false
    );
    if (form1And2Data && form1And2Data.Items) {
      formData.append("items", JSON.stringify(form1And2Data.Items));
    }
    if (OldSupplierArray && OldSupplierArray.length > 0) {
      formData.append("suppliers", JSON.stringify(OldSupplierArray));
    }
    if (
      form1And2Data &&
      form1And2Data.files &&
      form1And2Data.files.length > 0
    ) {
      for (let i = 0; i < form1And2Data.files.length; i++) {
        formData.append(`documents[${i}]`, form1And2Data.files[i]);
      }
    }
    if (form1And2Data && form1And2Data.links) {
      formData.append("links", JSON.stringify(form1And2Data.links));
    }
    formData.append("companyId", auth.user?.company?._id);
    formData.append("createdBy", auth.user?._id);
    formData.append(
      "auctionId",
      "A" + Math.floor(100000 + Math.random() * 900000)
    );
    if (newSupplierArray.length > 0) {
      formData.append(
        "selectedInviteNewSupplier",
        JSON.stringify(newSupplierArray)
      );
    }
    if (
      form1And2Data &&
      form1And2Data.libDocs &&
      form1And2Data.libDocs.length > 0
    ) {
      formData.append("docs", JSON.stringify(form1And2Data.libDocs));
    }
    let returnRes;
    const response = await Api(
      POST_AUCTION_DRAFT_API,
      formData,
      "postMultipart"
    );
    if (response.status === 200) {
      returnRes = response;
      if (response.data.responseCode === "00") {
        //  let addedProjects = response.data;
        dispatch({
          type: CLEAR_REDUX_ON_SAVED_SUCCESS,
          payload: {},
        });
        dispatch({
          type: SAVE_AUCTION_STATUS,
          payload: true,
          code: 200,
          message: "Auction has been created successfully !",
          success: true,
        });
      } else {
        dispatch({
          type: SAVE_AUCTION_STATUS,
          payload: false,
        });
      }
    } else {
      dispatch({
        type: SAVE_AUCTION_STATUS,
        payload: false,
        code: response?.status,
        message: response?.data?.responseDescription
          ? response?.data?.responseDescription
          : response?.message,
        success: false,
      });
    }

    // await axiosInstance
    //   .post(`api/buyer/suppier/saveAuctionAsDraft`, formData)
    //   .then((response) => {
    //     returnRes = response;
    //     if (response.data.responseCode === "00") {
    //       //  let addedProjects = response.data;
    //       dispatch({
    //         type: CLEAR_REDUX_ON_SAVED_SUCCESS,
    //         payload: {},
    //       });
    //       dispatch({
    //         type: SAVE_AUCTION_STATUS,
    //         payload: true,
    //         code: 200,
    //         message: "Auction has been created successfully !",
    //         success: true,
    //       });
    //     } else {
    //       dispatch({
    //         type: SAVE_AUCTION_STATUS,
    //         payload: false,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     dispatch({
    //       type: SAVE_AUCTION_STATUS,
    //       payload: false,
    //       code: err.response.status,
    //       message: err.response.data.responseDescription
    //         ? err.response.data.responseDescription
    //         : err.message,
    //       success: false,
    //     });
    //   });
    return returnRes;
  };
};

// # live monitoring
export function setLiveAuctionDetails(auction) {
  return {
    type: GET_LIVE_AUCTION_DETAILS,
    payload: auction,
  };
}

export function refreshLiveBiddingDataBuyer(auction) {
  return {
    type: REFRESH_LIVE_BIDDING_DATA_BUYER,
    payload: auction,
  };
}

export function refreshLiveAuctionBuyer(auction) {
  return {
    type: REFRESH_LIVE_AUCTION_BUYER,
    payload: auction,
  };
}
// # live monitoring
