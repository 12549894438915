import { Box, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DragIcon from "@mui/icons-material/DragIndicator";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../../../../components/useForm";
import Controls from "../../../../components/controls/Controls";
import Formsteps from "../../../../components/Formsteps";
import FormInput from "../../../../components/FormComponents/FormInput";
import { useForm, useFieldArray } from "react-hook-form";

import NestedAnswer from "../NestedAnswer";
import SelectInput from "../../../../components/FormComponents/SelectInput";
import {
  RFXFormStep3Add,
  rfxSaveAsDraft,
} from "../../../../store/actions/rfxAction";
import { store } from "../../../../store/store";
import Modal from "@mui/material/Modal";
import {
  QUESTION_RULES,
  QUESTION_TYPE_RULES,
} from "../../../../constants/Rules";
import CommonButton from "../../../../components/controls/CommonButton";
import { Api } from "../../../../api/Api";
import { POST_RFX_ADD_QUESTION_API } from "../../../../constants/ApiUrl";

const answerOption = [
  { value: "FT", label: "Free Text" },
  { value: "SC", label: "Single Choice" },
  { value: "MC", label: "Multi Choice" },
];
const RfxFormStep3 = ({
  openPopupStep3,
  setOpenPopupStep2,
  setOpenPopupStep3,
  setOpenPopupStep4,
  discardChanges,
  stepLabels,
  setResendApi,
  fromEditTab,
  rfxDetails,
  getQuestionData,
}) => {
  const rfxData = useSelector((state) => state.rfx);
  const { auth } = store.getState();
  const companyId = auth?.auth?.user?.company?._id;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      question: [{}],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    keyName: "key",
    name: "question",
  });

  const [auctionSaveDraft, setauctionSaveDraft] = useState(false);

  const questionList = watch("question");

  const onSubmit = async (values) => {
    if (values && values.question) {
      let temp = values;
      let responseQue = temp.question.map((obj, index) => {
        if (
          obj &&
          obj.options &&
          Array.isArray(obj.options) &&
          obj.options.length === 1 &&
          obj.options[0] &&
          obj.options[0].option
        ) {
          obj.sequence = index;
          obj.options = [];
        } else {
          obj.sequence = index;
          if (obj.options && Array.isArray(obj.options)) {
            obj.options = obj.options.map((item, i) => {
              if (item && item.option) {
                item.sequence = i;
              }
              return item;
            });
          }
        }
        return obj;
      });
      if (!fromEditTab) {
        if (!auctionSaveDraft) {
          dispatch(RFXFormStep3Add({ question: [...responseQue] }));

          setOpenPopupStep4(true);
          setOpenPopupStep3(false);
        } else {
          await dispatch(RFXFormStep3Add({ question: [...responseQue] }));
          let res = await dispatch(
            rfxSaveAsDraft({ question: [...responseQue] })
          );
          if (res?.data?.responseCode === "00") {
            setResendApi(true);
            setOpenPopupStep3(false);
          }
        }
        setauctionSaveDraft(false);
      } else {
        const payload = { questions: [...responseQue] };

        let res = await Api(
          `${POST_RFX_ADD_QUESTION_API}/${rfxDetails?._id}/${companyId}`,
          payload,
          "post"
        );
        if (res.status === 200) {
          if (res?.data?.responseCode === "00") {
          }
        }
        getQuestionData();
        setOpenPopupStep3(false);
      }
    }
  };

  const PressBack = () => {
    setOpenPopupStep3(false);
    setOpenPopupStep2(true);
  };

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.style.backgroundColor = "#e0e0e0";
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.style.backgroundColor = "transparent";
  };

  const handleDrop = (event, currentIndex) => {
    event.preventDefault();
    event.currentTarget.style.backgroundColor = "transparent";
    const draggedIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedFields = [...questionList];
    const draggedField = updatedFields.splice(draggedIndex, 1)[0];
    updatedFields.splice(currentIndex, 0, draggedField);
    setValue("question", updatedFields, { shouldValidate: true });
  };

  useEffect(() => {
    if (!rfxData?.RfxForm1?.question || fromEditTab) reset();
  }, [openPopupStep3]);

  useEffect(() => {
    const { rfx } = store.getState();
    if (!fromEditTab) setValue("question", rfx?.RfxForm1?.question);
  }, []);
  return (
    <Modal
      open={openPopupStep3}
      aria-label="create-auction-second-step-buyer-aspect"
      aria-describedby="modal-modal-description"
      className="auction-form-container"
    >
      <Box className="popup-form-container rfx-question-step">
        <Grid container>
          <Grid item xs={9}>
            {!fromEditTab ? (
              <p className="profile-title">
                Create {rfxData?.RfxForm1?.rfx_type}
              </p>
            ) : (
              <p className="profile-title">Add Question</p>
            )}
          </Grid>
          <Grid item xs={3} className="text-end">
            <CloseIcon
              onClick={() => {
                if (!fromEditTab) discardChanges();
                else setOpenPopupStep3(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        {!fromEditTab && (
          <Grid container justifyContent="center">
            <Grid item md={11} xs={12}>
              <Formsteps
                steps={stepLabels}
                currStep={3}
                className="mt-5 mb-5"
              />
            </Grid>
          </Grid>
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, index) => {
            return (
              <div className="mb-4" key={field.key}>
                <Box
                  className="cursor-p question-box"
                  draggable
                  onDragStart={(event) => handleDragStart(event, index)}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(event) => handleDrop(event, index)}
                >
                  <Grid container>
                    <Grid className="question-title" item xs={12}>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className="global-flex-center item-start">
                            <DragIcon />
                            <span className="bold-line-600 clr-2170ff">
                              Question{index + 1}
                            </span>
                          </div>
                        </Grid>
                        {index !== 0 && (
                          <Grid item xs={1}>
                            <div
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <DeleteOutlineIcon className="cursor-p float-right icon __edit clr-ff0000" />
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid className="lower-box" container>
                      <Grid className="answer-area" item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <FormInput
                              className="common-textarea-input"
                              id={`question.${index}.question`}
                              name={`question.${index}.question`}
                              type="text"
                              register={register}
                              errors={errors}
                              placeholder="Type a question"
                              //rules={QUESTION_RULES}
                              // errorMsg={
                              //   errors["question"]?.[index]?.["question"]
                              //     ?.message
                              // }
                              multiple={true}
                              maxRows={4}
                              minRows={1}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <SelectInput
                                id={`question.${index}.questionType`}
                                name={`question.${index}.questionType`}
                                options={answerOption}
                                register={register}
                                errors={errors}
                                //rules={QUESTION_TYPE_RULES}
                                placeholder="Type a answer"
                                control={control}
                                watch={watch}
                                // errorMsg={
                                //   errors["question"]?.[index]?.["questionType"]
                                //     ?.message
                                // }
                              />
                            </Box>
                          </Grid>
                          <NestedAnswer
                            nestIndex={index}
                            questionList={questionList}
                            {...{
                              control,
                              register,
                              errors,
                              watch,
                              setValue,
                              isValid,
                            }}
                          />
                        </Grid>
                      </Grid>
                      {fields.length - 1 === index && (
                        <Grid item xs={12}>
                          <Controls.Button
                            type="button"
                            disableElevation
                            text="Add New Question"
                            className="btn-mui fix-w-12em float-right mt-2"
                            disabled={!isValid}
                            onClick={() => append()}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </div>
            );
          })}
          {!fromEditTab ? (
            <Box
              className="BottomModal"
              display="flex"
              p={1}
              bgcolor="background.paper"
              style={{ marginTop: "20px" }}
            >
              <Grid item xs={4} className="BottomModal-left">
                <Box p={0} flexGrow={1}>
                  <Controls.Button
                    text="Back"
                    disableElevation
                    onClick={() => {
                      PressBack();
                    }}
                    style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                    color="default"
                    className="btn-mui fix-w-12em"
                  />
                </Box>
              </Grid>

              <Grid item xs={8} className="BottomModal-right">
                <Box p={0}>
                  <Controls.Button
                    text="Save as Draft"
                    type="submit"
                    disableElevation
                    color="default"
                    style={{ margin: "0 20px 0 0" }}
                    onClick={() => {
                      if (!isValid) return;
                      setauctionSaveDraft(true);
                    }}
                    className="btn draft btn-mui fix-w-12em"
                  />
                </Box>
                <Box p={0}>
                  <Controls.Button
                    type="submit"
                    disableElevation
                    text="Next"
                    className="btn-mui fix-w-12em"
                  />
                </Box>
              </Grid>
            </Box>
          ) : (
            <Box p={0} className="global-flex-center">
              <CommonButton
                disableElevation
                type="submit"
                text="Save"
                className="btn-mui fix-w-12em "
              />
            </Box>
          )}
        </Form>
      </Box>
    </Modal>
  );
};

export default RfxFormStep3;
