import { useForm, Form } from "../../components/useForm";
import { makeStyles } from "@material-ui/styles";
import { Grid, Modal } from "@mui/material";
import Controls from "../../components/controls/Controls";
import "../../pages/Items/style/form.css";
import { Box } from "@mui/system";
import uploadIcon from "../../assets/icons/Upload@2x.png";
import attachIcon from "../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../assets/icons/CloseX.png";
import CloseIcon from "@mui/icons-material/Close";
import {
  ACCEPT_DOCUMENT_ATTACHMENTS,
  DOCUMENT_FILE_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { useEffect } from "react";
import { toast, Slide } from "react-toastify";

const initialFValues = {
  name: "",
  notes: "",
};

const useStyles = makeStyles((theme) => ({
  errMsg: {
    color: "red",
    fontSize: "14px",
    margin: "3px 0",
  },
  imgPreview: {
    width: "100%",
    height: "460px",
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#ccc",
    padding: "100px",
    border: "1px solid #000",
    background: "#cccccc",
  },

  customFileUpload: {
    color: "#55d6d6",
    fontSize: "22px",
    fontWeight: "500",
    padding: "6px 12px",
    cursor: "pointer",
  },
  files: { padding: "5px" },
  customeFileUpload: {
    position: "absolute",
    padding: "47px 20px",
    cursor: "pointer",
  },
  formPart1: {
    "& .MuiFormControl-root": {
      width: "90%",
    },
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    padding: ".5rem 2rem",
    fontWeight: "700",
    marginLeft: "0px",
  },
  input: {
    display: "none",
  },
  attachmentText: {
    fontWeight: "700",
    fontSize: "1.3em",
  },
  attachment: {
    margin: "2rem 0",
  },
  Button: {
    padding: ".4rem 3rem",
    borderRadius: "6px",
    fontWeight: "700",
    fontSize: "14px",
  },
  imageInfo: {
    color: "#A19B9D",
    fontSize: "12px",
    maxWidth: "10.5rem",
  },
  imageInfoTwo: {
    color: "#A19B9D",
    fontSize: "12px",
  },
  modalContent: {
    width: "100%",
  },
  addLink: {
    color: "#1E1D1D",
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "1.5em",
  },
  dragAndDrop: {
    fontWeight: "700",
    fontSize: "18px",
  },
  attachmentBoxes: {
    padding: "10px",
  },
}));

const CommonDocumentModal = (props) => {
  const classes = useStyles();
  const {
    open,
    handleClose,
    documentFile,
    setDocumentFile,
    finalAttachmentDocs,
    setFinalAttachmentDocs,
    setNotify,
    documentDataForList,
    setDocumentDataForList,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    setDocumentFile({});
    resetForm();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (documentDataForList && documentDataForList.length > 0) {
        toast.error(ValidationMessage.onlyOneAttachment, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
      if (documentFile && documentFile.name) {
        const tempDocs = {
          title: values.Title,
          notes: values.notes,
          document: documentFile,
        };
        finalAttachmentDocs.push(tempDocs);
        documentDataForList.push(tempDocs);
        setFinalAttachmentDocs(finalAttachmentDocs);
        setDocumentDataForList(documentDataForList);
        handleClose();
      } else {
        toast.error(ValidationMessage.selectDocument, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const selected = e.dataTransfer.files[0];
    handleImageChange(selected);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  async function handleImageChange(selected) {
    // const selected = e.target.files[0];

    const ALLOWED_TYPES = ACCEPT_DOCUMENT_ATTACHMENTS;
    if (selected && !ALLOWED_TYPES.includes(selected.type)) {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }
    if (selected && Math.round(selected.size / 1024) > DOCUMENT_FILE_SIZE) {
      toast.error(ValidationMessage.documentAttachmentFileSize, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setDocumentFile(selected);
    }
  }

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const selectedDocRemove = () => {
    setDocumentFile({});
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-label="create-item-second-step-buyer-aspect-upload-docs-file"
    >
      <Box className="popup-form-container">
        <Grid container>
          <Grid item xs={9}>
            <p className="profile-title"> Add Document</p>
          </Grid>
          <Grid item xs={3} className="text-end">
            <CloseIcon onClick={() => handleClose()} className="cursor-p" />
          </Grid>
        </Grid>
        <Form onSubmit={handleSubmit}>
          <div className="container mb-4">
            <div
              className="image-uploader-container"
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
            >
              {documentFile && (
                <>
                  <div className="uploader-header">
                    <img src={uploadIcon} className="upload-icon" alt="" />
                    <p className="upload-title">Drag or drop files here</p>
                    <p className="upload-description">Max. file size is 20mb</p>
                    <p className="upload-description">
                      and files must be .pdf, doc, zip
                    </p>
                    <label htmlFor="fileUploadButton">
                      <Controls.Button
                        component="span"
                        text="Choose File"
                        disableElevation
                        htmlFor="fileUploadButton"
                        className="mui-btn upload-btn"
                      />
                    </label>
                    <input
                      type="file"
                      id="fileUploadButton"
                      hidden
                      onChange={(e) => handleImageChange(e.target.files[0])}
                    />
                  </div>
                  <label
                    htmlFor="fileUploadButton"
                    className="customeFileUpload"
                    style={{
                      position: "absolute",
                      padding: "47px 57px",
                      cursor: "pointer",
                    }}
                  >
                    &nbsp;
                  </label>
                  <input
                    type="file"
                    id="fileUploadButton"
                    hidden
                    onChange={(e) => handleImageChange(e.target.files[0])}
                    value={values.image}
                  />
                  {/* <span>( jpg, jpeg or png)</span> */}
                </>
              )}
            </div>
          </div>

          {documentFile && (documentFile.name || documentFile.fileName) ? (
            <Grid item xs={12} style={{ alignItems: "flex-end" }}>
              <Box component="div" className="uploaded-files">
                <Grid container>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <img src={attachIcon} alt="" className="icon" />
                  </Grid>
                  <Grid item xs={9} className="details">
                    <Grid container>
                      <Grid item xs={12}>
                        <Box className="name">
                          {documentFile?.name || documentFile?.fileName}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <box className="size">
                          {bytesToSize(documentFile?.size || 20000)}
                        </box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ textAlign: "end", margin: "auto" }}
                    onClick={() => selectedDocRemove()}
                  >
                    <img src={closeIcon} alt="" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ) : (
            ""
          )}

          {/* <Box className="create-form-container mt-3 q">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel id="title-input">Title</InputLabel>
                <Controls.Input
                  // className="mui-input"
                  fullWidth
                  labelId="title-input"
                  name="Title"
                  value={values.tilte}
                  onChange={handleInputChange}
                  error={errors.title}
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="notes-input">Notes</InputLabel>
                <Controls.Input
                  className="mui-input fix-h-8em"
                  labelId="notes-input"
                  name="notes"
                  fullWidth
                  value={values.notes}
                  onChange={handleInputChange}
                  error={errors.notes}
                  inputProps={{ maxLength: 499 }}
                />
              </Grid>
            </Grid>
          </Box> */}

          <div className="bottom-button-box-container">
            <div className="left-side">
              <Controls.Button
                variant="contained"
                disableElevation
                className="btn-mui w-100"
                onClick={() => handleClose()}
                text="Cancel"
                style={{
                  backgroundColor: "#E2F2FF",
                  color: "#2170FF",
                  marginRight: "2.5rem",
                }}
              />
            </div>
            <div className="right-side">
              <div>
                <Controls.Button
                  variant="contained"
                  disableElevation
                  className="btn-mui w-100"
                  type="submit"
                  text="Attach"
                />
              </div>
            </div>
          </div>
        </Form>
      </Box>
    </Modal>
  );
};

export default CommonDocumentModal;
