/*eslint-disable */
import * as React from "react";
import { Box, Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { InputLabel, Button } from "@mui/material";
import useWindowDimensions from "../../../components/useWindowDimensions";
import { Form, useForm } from "../../../components/useForm";
import Controls from "../../../components/controls/Controls";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import "../styles/supplier-edit-board-details.css";
import Moment from "moment";
import { useSelector } from "react-redux";
import UserImage from "../../../assets/auth/user_icon_2.png";
import {
  getUserDetails,
  removeNullValueForPaylaod,
} from "../../../utility/utils";
import { Api } from "../../../api/Api";
import {
  GET_SELLER_SUPPLIER_DETAIL_API,
  GET_SUPPLIER_CATEGORY_API,
  PATCH_EDIT_SUPPLIER_API,
} from "../../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const initialFValues = {
  category: "",
  scorecard_number: "",
  internal_notes: "",
  external_id: "",
};

export default function SupplierEditboardDetails(props) {
  const { supplier, setNotify } = props;
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const userProfileImage = useSelector(
    (state) => state?.auth?.auth?.user?.image_upload
  );
  const [editMode, setEditMode] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [tempTag, setTempTag] = React.useState("");
  const [userData, setUserData] = React.useState("");
  const [dropdownSupplierCategory, setDropdownSupplierCategory] =
    React.useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // if ("email" in fieldValues)
    //   temp.email = fieldValues.email ? "" : "Email is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (editMode == true) {
        if (supplier && supplier?._id) {
          const payload = {
            scorecard: values.scorecard_number,
            category: values.category,
            notes: values.internal_notes,
            tags: tags,
            externalId: values.external_id,
          };
          const finalPayload = removeNullValueForPaylaod(payload);
          const response = await Api(
            `${PATCH_EDIT_SUPPLIER_API}/${supplier?._id}`,
            finalPayload,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setEditMode(false);
              resetForm();
              getSupplierDetails();
            }
          }

          // axiosInstance
          //   .patch(`api/seller/supplierEdit/${supplier?._id}`, payload)
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setEditMode(false);
          //       resetForm();
          //       getSupplierDetails();
          //       setNotify({
          //         isOpen: true,
          //         message: "Supplier updated successfully",
          //         type: "success",
          //       });
          //     } else {
          //       setNotify({
          //         isOpen: true,
          //         message: response?.data?.responseDescription,
          //         type: "error",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //   });
        } else {
          toast.error(ValidationMessage.somethingWrong, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      } else {
        setEditMode(!editMode);
      }
    }
  };

  const getAllSuppCategory = async () => {
    const state = store.getState();
    const companyId = state?.auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_SUPPLIER_CATEGORY_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let suppliersList = response.data.supplierCategories;
          setDropdownSupplierCategory(suppliersList);
        }
      }

      // await axiosInstance
      //   .get(`api/buyer/company/getSupplierCategories/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let suppliersList = response.data.supplierCategories;
      //       setDropdownSupplierCategory(suppliersList);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const addNewAdditionalActivity = (e) => {
    if (tempTag !== "") {
      tags.push(tempTag);
      const neItems = [...tags];
      setTags(neItems);
      setTempTag("");
    } else {
      toast.error(ValidationMessage.enterText, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const removeSelectTag = (index) => {
    const removeIndex = tags.splice(index, 1);
    const neItems = [...tags];
    setTags(neItems);
  };

  const getCategoryname = (id) => {
    const categoryName = dropdownSupplierCategory.find(
      (element) => element._id === id
    );
    return categoryName ? categoryName.name : "-";
  };

  const getSupplierDetails = async () => {
    if (supplier && supplier?._id) {
      const response = await Api(
        `${GET_SELLER_SUPPLIER_DETAIL_API}/${supplier?._id}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let details = response.data.company;
          setValues({
            category: details?.category?._id,
            scorecard_number: details?.scorecard,
            internal_notes: details?.notes,
            external_id: details?.externalId,
          });
          setTags(details?.tags || []);
        }
      }

      // await axiosInstance
      //   .get(`api/seller/supplierDetails/${supplier?._id}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let details = response.data.company;
      //       setValues({
      //         category: details?.category?._id,
      //         scorecard_number: details?.scorecard,
      //         internal_notes: details?.notes,
      //         external_id: details?.externalId,
      //       });
      //       setTags(details?.tags || []);
      //     } else {
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  React.useEffect(() => {
    getAllSuppCategory();
    getSupplierDetails();
  }, [supplier]);

  React.useEffect(() => {
    const user = getUserDetails();
    setUserData({ ...user });
  }, []);
  return supplier && supplier.status ? (
    <Grid container className="supplier-edit-board-details-container">
      <Form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <h3
              style={{ opacity: editMode ? "1" : "0" }}
              className="title-header"
            >
              Edit Supplier Infos
            </h3>
            <Controls.Button
              className="btn-primary-lite edit-btn"
              text={editMode ? "save" : "Edit"}
              type="submit"
              // onClick={(event) => editInformationTggle(event)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={editMode ? "d-none" : "d-block"}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="text-center">
              <Box className="scorecard-container mt-4 mb-4">
                <CircularProgress
                  variant="determinate"
                  value={values.scorecard_number || supplier?.scorecard}
                  color="primary"
                />
                <CircularProgress
                  variant="determinate"
                  value={100}
                  color="inherit"
                />
                <Box className="score-progress">
                  <div className="text-center">
                    <b className="score-progress-number">{`${Math.round(
                      values.scorecard_number
                        ? values.scorecard_number
                        : supplier?.scorecard
                        ? supplier?.scorecard
                        : 0
                    )}`}</b>{" "}
                    <br />
                    <b> Scorecard </b>
                  </div>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Internal Notes</InputLabel>
              <p>{values.internal_notes || supplier?.notes}</p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Extermal ID</InputLabel>
              <p>{values.external_id || supplier?.externalId}</p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Category</InputLabel>
              <p>
                {values && values.category
                  ? getCategoryname(values.category)
                  : supplier?.category?.name}
              </p>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mb-2">Tags</InputLabel>
              <Box display="flex" flexWrap="wrap" spacing={2}>
                {tags && tags.length > 0 ? (
                  tags.map((tag) => (
                    <div className="badge badge-primary-outline tag">
                      <span>{tag}</span>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: "20vh" }}></div>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <img
                  src={supplier?.createdBy?.image_upload || UserImage}
                  style={{
                    width: "2.7em",
                    height: "2.7em",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
                <div className="ml-2">
                  <InputLabel>Added By</InputLabel>
                  <div>
                    {userData?.user?.first_name} {userData?.user?.last_name}{" "}
                  </div>
                  <div>
                    {Moment(supplier?.createdDate).format("MMMM DD, YYYY")}
                  </div>
                </div>
              </Box>
            </Grid>
            {supplier && supplier?.updatedDate ? (
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <img
                    // src="https://www.bootdey.com/img/Content/avatar/avatar2.png"
                    src={supplier?.main_contact?.image_upload || UserImage}
                    style={{
                      width: "2.7em",
                      height: "2.7em",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="ml-2">
                    <InputLabel>Last update</InputLabel>
                    <div>
                      {Moment(supplier?.updatedDate).format("MMMM DD, YYYY")}{" "}
                      {supplier?.main_contact?.first_name}{" "}
                      {supplier?.main_contact?.last_name}
                    </div>
                  </div>
                </Box>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={!editMode ? "d-none" : "d-block"}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputLabel>Category</InputLabel>
              <Controls.Select
                name="category"
                className="w-100"
                fullWidth
                value={values.category}
                onChange={handleInputChange}
                options={dropdownSupplierCategory}
                error={errors.category}
              />
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <InputLabel>External ID</InputLabel>
                <Controls.Input
                  name="external_id"
                  fullWidth
                  value={values.external_id}
                  onChange={handleInputChange}
                  error={errors.external_id}
                  inputProps={{ maxLength: 20 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>Scorecard</InputLabel>
                <Controls.Input
                  name="scorecard_number"
                  fullWidth
                  value={values.scorecard_number}
                  onChange={handleInputChange}
                  error={errors.scorecard_number}
                  inputProps={{ maxLength: 3 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="down-period-input">Internal Notes</InputLabel>
              <Controls.Input
                name="internal_notes"
                className="fix-h-12em"
                inputProps={{ maxLength: 499 }}
                value={values.internal_notes}
                onChange={handleInputChange}
                error={errors.internal_notes}
              />
              <InputLabel style={{ float: "right" }}>0/499</InputLabel>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mb-2">Tags</InputLabel>
              <Box display="flex" flexWrap="wrap" spacing={2}>
                {tags && tags.length > 0 ? (
                  tags.map((tag, index) => (
                    <div className="badge badge-primary-outline tag">
                      <span>{tag}</span>
                      <CloseIcon onClick={() => removeSelectTag(index)} />
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item sm={8} xs={12}>
                  <Controls.Input
                    name="new_activty"
                    fullWidth
                    inputProps={{ maxLength: 200 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    value={tempTag}
                    onChange={(e) => setTempTag(e.target.value)}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Button
                    variant="contained"
                    className="btn btn-light-blue btn-mui text-transform-cap w-100"
                    onClick={() => addNewAdditionalActivity()}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Grid>
  ) : (
    <></>
  );
}
