import { Api } from "../../../../api/Api";
import { ONLY_SUPER_ADMIN_API } from "../../../../constants/ApiUrl";

const GetAllSuperAdminBL = async (props) => {
  const { page, rowsPerPage, searchKey } = props;

  let superAdminList = [];
  let limit = 0;

  let path = `${ONLY_SUPER_ADMIN_API}/getAll?per_page=${rowsPerPage}&page=${page}`;

  if (searchKey !== "") {
    path += `&searchKey=${searchKey}`;
  }

  const response = await Api(path, {}, "get");
  if (response.status === 200) {
    if (response.data && response.data.admins !== undefined) {
      superAdminList = response.data.admins;
      limit = response.data.totalRecords;
    }
  }
  return {
    superAdminList: superAdminList,
    limit: limit,
  };
};

export default GetAllSuperAdminBL;
