import { SET_HTTP_ERROR_SETTING } from "../constant/errorHandlerConstant";

const initialState = {
  httpCode: 500,
  httpMessage: "Some Thing Goes Wrong In Sytem !",
  httpSuccess: false,
  UnAuthorizedRoute: "/error/403",
};

export const ErrorHandlerReducer = (state = initialState, error) => {
  switch (error.type) {
    case SET_HTTP_ERROR_SETTING:
      return {
        ...state,
        httpCode: error.payload.code ? error.payload.code : state.httpCode,
        httpMessage: error.payload.message
          ? error.payload.message
          : state.httpMessage,
        httpSuccess: error.payload.success
          ? error.payload.success
          : state.httpSuccess,
      };

    default:
      return state;
  }
};
