export const INIT_SOCKET_EVENT = "init-socket";
export const INIT_SOCKET_RESPONSE_EVENT = "init-socket-response";
export const SEND_NEW_BID_EVENT = "send-bid";
export const SEND_NEW_BID_RESPONSE_EVENT = "send-bid-response";
export const GOIN_LIVE_BID_GROUP_EVENT = "join-group";
export const GOIN_LIVE_BID_GROUP_RESPONSE_EVENT = "join-group-response";
export const NEW_BIDIN_SYSTEM_EVENT = "new-bid-in-system";
export const SERVER_SYNC_CLOCK = "server-sync-clock";
export const REFRESH_LIVE_AUCTION_PROPS = "refresh-live-auction-props";
export const SET_MY_LIVE_BIDDING_RANK_EVENT = "set-my-live-bidding-rank";
export const SET_MY_LIVE_BIDDING_RANK_RESPONSE_EVENT =
  "set-my-live-bidding-rank-response";
export const RFP_TYPE = "RFP";
export const RFQ_TYPE = "RFQ";
export const RFI_TYPE = "RFI";
export const RFX_TYPE = "RFX";
