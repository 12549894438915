import { Fragment, useEffect } from "react";
import CollapsedPage from "../../../../components/collapsedPage";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import { Box } from "@material-ui/core";
import FormInput from "../../../../components/FormComponents/FormInput";
import { useFieldArray, useForm } from "react-hook-form";
import { Form } from "../../../../components/useForm";
import { QUESTION_RULES } from "../../../../constants/Rules";
import Controls from "../../../../components/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Api } from "../../../../api/Api";
import { PATCH_EDIT_QUESTION_API } from "../../../../constants/ApiUrl";

const RfxQuestionEditDrawer = ({
  showEditSidebar,
  setShowEditSidebar,
  editData,
  rfxId,
  getQuestionData,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      options: [],
    },
    mode: "onChange",
  });
  const opt = watch("options");
  const { fields, remove, append } = useFieldArray({
    control,
    keyName: "key",
    name: "options",
    mode: { onchange },
  });

  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const getBodyWidth = () => {
    if (xlScreen) {
      return 40;
    } else if (xsScreen) {
      return 80;
    } else if (smScreen) {
      return 80;
    } else if (mdScreen) {
      return 70;
    } else if (lgScreen) {
      return 50;
    }
  };

  useEffect(() => {
    if (editData && editData?._id) {
      reset({
        question: editData?.question,
        options: editData?.options,
      });
    }
  }, [editData]);
  const formSubmitHandler = async (values) => {
    const payload = {
      ...values,
      questionType: editData?.questionType,
    };
    const response = await Api(
      `${PATCH_EDIT_QUESTION_API}/${rfxId}/${editData?._id}`,
      payload,
      "patch"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setShowEditSidebar(false);
        getQuestionData();
      }
    }
  };
  return (
    <>
      <CollapsedPage
        openPopup={showEditSidebar}
        setOpenPopup={setShowEditSidebar}
        bodyWidth={getBodyWidth()}
        fromRfx={true}
      >
        <Box container className="common-slider">
          <p className="edit-title">Question</p>
          <div className="divider-title mt-3"></div>
          <div className="edit-question-answer w-100">
            <Form onSubmit={handleSubmit(formSubmitHandler)}>
              <div className="edit-question w-100 mb-3">
                <FormInput
                  className="common-textarea-input edit-question-textarea w-100"
                  id="question"
                  name="question"
                  type="text"
                  register={register}
                  errors={errors}
                  placeholder="Type a question"
                  rules={QUESTION_RULES}
                  multiple={true}
                  maxRows={4}
                  minRows={1}
                />
              </div>
              {fields.map((field, index) => (
                <Fragment key={field.key}>
                  <div className="edit-answer w-100 mb-3 d-flex justify-content-between align-item-center">
                    <FormInput
                      className="edit-question-textarea w-100"
                      id={`options.${index}.option`}
                      name={`options.${index}.option`}
                      type="text"
                      register={register}
                      errors={errors}
                      placeholder="Type a Answer"
                      rules={QUESTION_RULES}
                      errorMsg={errors["options"]?.[index]?.["option"]?.message}
                    />

                    {fields.length - 1 === index && (
                      <AddIcon
                        className={`ml-3  common-rfx-icon add-rfx-icon ${
                          !isValid ? "item-disabeled" : ""
                        }`}
                        onClick={() => {
                          if (!isValid) return;
                          append({ option: "" });
                        }}
                      />
                    )}
                    {index !== 0 && (
                      <RemoveIcon
                        className="ml-2 common-rfx-icon remove-rfx-icon"
                        onClick={() => remove(index)}
                      />
                    )}
                  </div>
                </Fragment>
              ))}

              <div className="divider-title mt-3"></div>
              <div className="float-right mt-3">
                <Controls.Button
                  text="Discard"
                  disableElevation
                  onClick={() => {
                    setShowEditSidebar(false);
                  }}
                  color="default"
                  className="btn-mui common-light-button "
                />
                <Controls.Button
                  type="submit"
                  disableElevation
                  text="Save"
                  className="btn-mui"
                />
              </div>
            </Form>
          </div>
        </Box>
      </CollapsedPage>
    </>
  );
};

export default RfxQuestionEditDrawer;
