/*eslint-disable */
import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from "@material-ui/icons/Person";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import EmailIcon from "@material-ui/icons/Email";
import BusinessIcon from "@material-ui/icons/Business";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from "@material-ui/icons/Lock";
import { useDispatch, connect } from "react-redux";
import { makeStyles, TextField, Tooltip } from "@material-ui/core";
import countries from "../../assets/countries.json";
import {
  signupAction,
  loadingToggleAction,
} from "../../store/actions/authActions";
import ReCAPTCHA from "react-google-recaptcha";
import LanguageIcon from "@material-ui/icons/Language";
import Controls from "../../components/controls/Controls";
import "./styles/auth.css";
import { useHistory } from "react-router-dom";
import { VALID_EMAIL_REGEX } from "../../constants/AppConstants";

const initialFValues = {
  company_name: "",
  country: "",
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  role: "",
  cpassword: "",
};
const useStyles = makeStyles((theme) => ({
  selectAdornment: {
    "& .MuiButtonBase-root": {
      padding: 0,
      left: "0px",
      top: "calc(50% - 0px)",
    },
  },
}));
const CommonSignup = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [countryList, setCountryList] = useState([]);

  const hint =
    "Send email request to OKTIO managemant team to grant you 'BUYER' role ";

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initialFValues);
  const [requestToBeBuyerCheckBox, setRequestToBeBuyerCheckBox] =
    useState(false);

  const [password, setPassword] = useState({
    password: "",
    showPassword: false,
  });

  const [cpassword, setCpassword] = useState({
    cpassword: "",
    showCPassword: false,
  });

  const [vcaptcha, setVaptcha] = useState(false);
  const [pass, setPass] = useState("");
  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleClickShowCPassword = () => {
    setCpassword({ ...cpassword, showCPassword: !cpassword.showCPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onChange = () => {
    setVaptcha(true);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("first_name" in fieldValues) {
      if (fieldValues.first_name !== "") {
        if (fieldValues.first_name.length < 3) {
          temp.first_name = "Minimun 3 character required";
        } else {
          temp.first_name = "";
        }
      } else {
        temp.first_name = "This field is required.";
      }
    }

    if ("last_name" in fieldValues) {
      if (fieldValues.last_name !== "") {
        if (fieldValues.last_name.length < 3) {
          temp.last_name = "Minimun 3 character required";
        } else {
          temp.last_name = "";
        }
      } else {
        temp.last_name = "This field is required.";
      }
    }

    if ("role" in fieldValues)
      temp.role = fieldValues.role ? "" : "This field is required.";

    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : "This field is required.";

    if ("password" in fieldValues) {
      if (fieldValues.password !== "") {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 character";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                if (!/[!@#$*%&_^{}[\]/|\\()?-]/.test(fieldValues.password)) {
                  temp.password = "Password  must be 1 special character";
                } else {
                  setPass(fieldValues.password);
                  temp.password = "";
                }
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }

    if ("cpassword" in fieldValues) {
      if (fieldValues.cpassword !== "") {
        if (String(fieldValues.cpassword) !== String(pass)) {
          temp.cpassword = "Password not matched.";
        } else {
          temp.cpassword = "";
        }
      } else {
        temp.cpassword = "This field is required.";
      }
    }

    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (!VALID_EMAIL_REGEX.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if ("company_name" in fieldValues)
      temp.company_name = fieldValues.company_name
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      // setPass("");
      dispatch(loadingToggleAction(true));
      dispatch(
        signupAction(
          values.first_name,
          values.last_name,
          values.role,
          values.email,
          values.company_name,
          values.country,
          values.password,
          requestToBeBuyerCheckBox,
          props.fromBuyer,
          history,
          props.setNotify
        )
      );
    }
  };

  useEffect(() => {
    setCountryList(
      countries.map((row) => {
        return {
          id: row.name,
          name: row.name,
        };
      })
    );
  }, []);
  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controls.Input
              name="first_name"
              autoComplete="fname"
              id="first_name"
              fullWidth
              value={values.first_name}
              onChange={handleInputChange}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder="First Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.first_name && (
              <div className="error-msg">{errors.first_name}</div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Input
              id="last_name"
              name="last_name"
              autoComplete="lname"
              fullWidth
              value={values.last_name}
              onChange={handleInputChange}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              error={errors.link}
              placeholder="Last Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.last_name && (
              <div className="error-msg">{errors.last_name}</div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Input
              id="role"
              name="role"
              autoComplete="role"
              fullWidth
              value={values.role}
              onChange={handleInputChange}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder="Position"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CardTravelIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.role && <div className="error-msg">{errors.role}</div>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Input
              id="email"
              name="email"
              autoComplete="email"
              fullWidth
              value={values.email}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder="Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.email && <div className="error-msg">{errors.email}</div>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Input
              id="companyname"
              name="company_name"
              autoComplete="company_name"
              fullWidth
              value={values.company_name}
              onChange={handleInputChange}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder="Company Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.company_name && (
              <div className="error-msg">{errors.company_name}</div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {}
            <Controls.Select
              name="country"
              value={values.country}
              fullWidth
              displayEmpty
              onChange={handleInputChange}
              options={countryList}
              // error={errors.country}
              emptyOption={false}
              startAdornment={
                <InputAdornment
                  position="start"
                  className={classes.selectAdornment}
                >
                  <IconButton>
                    <Tooltip title="tooltip text">
                      <LanguageIcon color="primary" fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.country && (
              <div className="error-msg">{errors.country}</div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className="mui-input"
              variant="outlined"
              id="password"
              name="password"
              autoComplete="current-password"
              fullWidth
              value={values.password}
              onChange={handleInputChange}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder="Password"
              type={password.showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password.showPassword ? (
                        <Visibility color="primary" fontSize="small" />
                      ) : (
                        <VisibilityOff color="primary" fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <div className="error-msg">{errors.password}</div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="cpassword"
              name="cpassword"
              variant="outlined"
              autoComplete="current-password"
              className="mui-input"
              fullWidth
              value={values.cpassword}
              onChange={handleInputChange}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder="Confirm Password"
              type={cpassword.showCPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowCPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {cpassword.showCPassword ? (
                        <Visibility color="primary" fontSize="small" />
                      ) : (
                        <VisibilityOff color="primary" fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.cpassword && (
              <div className="error-msg">{errors.cpassword}</div>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <div className="checkbox-container">
              <div className="d-flex items-center">
                <div
                  className={
                    "checkbox checkbox-primary " +
                    (requestToBeBuyerCheckBox ? "checked" : "")
                  }
                  onClick={() => {
                    setRequestToBeBuyerCheckBox(!requestToBeBuyerCheckBox);
                  }}
                >
                  <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                  <CheckIcon aria-label="check-mark" />
                </div>
                <b style={{ color: "var(---a19b9d)" }}>
                  Request to be buyer also !
                </b>
                <Tooltip
                  title={hint}
                  classes={{
                    popper: "mui-tooltip mui-tooltip-primary",
                  }}
                  placement="top-start"
                  arrow
                >
                  <HelpIcon className="hint-icon" />
                </Tooltip>
              </div>
            </div>
          </Grid> */}
          <Grid item xs={12}>
            <Grid
              container
              className="mt-4"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!vcaptcha}
                  className="submit-btn"
                >
                  Create Account
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(CommonSignup);
