/*eslint-disable */
import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  ClickAwayListener,
  Button,
  CardActionArea,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TableOptionList from "../../../components/TableOptionList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import Controls from "../../../components/controls/Controls";
import { onEditSubmit } from "../../../store/actions/ProjectActions";
import { useDispatch } from "react-redux";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import EditProjectForm from "../EditProjectForm";
import EditProjectFormStepTwo from "../EditProjectFormStepTwo";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { InputLabel, Menu } from "@mui/material";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../services/AxiosInstance";
import { store } from "../../../store/store";
import "../styles/project-card.css";
import dummyCoverImg from "../../../assets/dummyProject.jpeg";
import { Api } from "../../../api/Api";
import {
  DELETE_PROJECT_API,
  POST_EDIT_PROJECT_API,
} from "../../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_ACTIVE_SMALL,
  AUCTION_ARCHIEVED,
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_COMPLETE,
  AUCTION_COMPLETED_SMALL,
  AUCTION_DRAFT,
  AUCTION_DRAFT_SMALL,
  PRIMARY_OUTLINE_BACKGROUND,
  PURPLE_BACKGROUND,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
} from "../../../constants/AppConstants";

const stepLabels = ["Initiation", "Details"];

export default function ProjectCard(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setSelectedProjectDetails, getAllProjectsAction } = props;
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const showdetails = () => {
    setShow(true);
  };

  const hidedetails = () => {
    setShow(false);
  };

  const onFormEdit = (firstValues) => {
    dispatch(onEditSubmit(firstValues));
    setOpenPopup2(false);
  };

  //   menu Start
  const [openmenu, setOpenmenu] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpenmenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
      return;
    }
    setOpenmenu(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openmenu);
  React.useEffect(() => {
    if (prevOpen.current === true && openmenu === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = openmenu;
  }, [openmenu]);
  // menu End

  const showProjectDetails = (projectDetail) => {
    setSelectedProjectDetails(projectDetail);
    // setProjectDetailShow(true);
    if (projectDetail) {
      history.push({
        pathname: "/project/details",
        id: projectDetail._id,
      });
    }
  };

  const getStatusChange = async (status, projectId) => {
    const payload = {
      project_status: status,
    };
    if (projectId) {
      const { auth } = store.getState();
      const userId = auth?.auth?.user?._id;
      const response = await Api(
        `${POST_EDIT_PROJECT_API}/${projectId}/${userId}`,
        payload,
        "patch"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllProjectsAction();
        }
      }

      // await axiosInstance
      //   .patch(`api/buyer/editProject/${projectId}/${userId}`, payload)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       getAllProjectsAction();
      //       // handleClose()
      //       setNotify({
      //         isOpen: true,
      //         message: "Status changed successfully.",
      //         type: "success",
      //       });
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: "Status changed failed.",
      //         type: "success",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const deleteProject = async (projectId) => {
    if (projectId) {
      setDeleteProjectId(projectId);
      setConfirmDialog({
        isOpen: true,
        title: "Project",
        subTitle: "Are you sure to delete project",
      });
    }
  };

  const confirmDeleteProject = async () => {
    if (deleteProjectId) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const response = await Api(
        `${DELETE_PROJECT_API}/${deleteProjectId}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllProjectsAction();
        }
      }

      // await axiosInstance
      //   .delete(`/api/buyer/deleteProject/${deleteProjectId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       setNotify({
      //         isOpen: true,
      //         message: "Project removed successfully",
      //         type: "success",
      //       });
      //       setConfirmDialog({
      //         isOpen: false,
      //         title: "",
      //         subTitle: "",
      //       });
      //       getAllProjectsAction();
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: "Status changed failed.",
      //         type: "success",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case AUCTION_ACTIVE_SMALL:
        return SUCCESS_BACKGROUND;
      case AUCTION_COMPLETED_SMALL:
        return PRIMARY_OUTLINE_BACKGROUND;
      case AUCTION_ARCHIEVED:
        return PURPLE_BACKGROUND;
      case AUCTION_DRAFT_SMALL:
        return SECONDARY_BACKGROUND;
      default:
        return SECONDARY_BACKGROUND;
    }
  };

  const moveToDetails = (projectDetail) => {
    if (projectDetail) {
      history.push({
        pathname: "/project/details",
        id: projectDetail._id,
      });
    }
  };
  return (
    <div className="projet-card-buyer-aspect">
      <Card
        className={"card-container " + (show ? "__active" : "")}
        onMouseOver={showdetails}
        onMouseOut={hidedetails}
      >
        {/* <CardActionArea> */}
        {/* {props.project.image && props?.project?.image.trim().length > 0 && ( */}
        <CardMedia
          component="img"
          image={
            props?.project?.image && props?.project?.image.trim().length > 0
              ? props?.project?.image
              : dummyCoverImg
          }
          alt={props.project.project_name}
          className={"card-image " + (show ? "__hide" : "")}
        />
        {/* )} */}
        <Box className="status-crud-list">
          <PopupState variant="popover" popupId="staus-popup-menu">
            {(popupState) => (
              <div>
                <Button
                  className={
                    "badge badge-" +
                    getStatusColor(props.project.project_status) +
                    " status project-status"
                  }
                  variant="contained"
                  {...bindTrigger(popupState)}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {props.project.project_status}
                </Button>
                <Menu
                  {...bindMenu(popupState)}
                  PaperProps={{
                    sx: {
                      padding: "3px",
                    },
                  }}
                >
                  <div>
                    <button
                      className="badge badge-success select-colored-option"
                      onClick={() =>
                        getStatusChange(AUCTION_ACTIVE_SMALL, props.project._id)
                      }
                    >
                      {AUCTION_ACTIVE}
                    </button>
                  </div>
                  <div>
                    <button
                      className="badge badge-primary-outline select-colored-option"
                      onClick={() =>
                        getStatusChange(
                          AUCTION_COMPLETED_SMALL,
                          props.project._id
                        )
                      }
                    >
                      {AUCTION_COMPLETE}
                    </button>
                  </div>
                  <div>
                    <button
                      className="badge badge-purple select-colored-option"
                      onClick={() =>
                        getStatusChange(AUCTION_ARCHIEVED, props.project._id)
                      }
                    >
                      {AUCTION_ARCHIEVED_CAPITAL}
                    </button>
                  </div>
                  <div>
                    <button
                      className="badge badge-secondary select-colored-option"
                      onClick={() =>
                        getStatusChange(AUCTION_DRAFT_SMALL, props.project._id)
                      }
                    >
                      {AUCTION_DRAFT}
                    </button>
                  </div>
                </Menu>
              </div>
            )}
          </PopupState>
        </Box>

        <Box className="option-crud-list">
          <PopupState
            variant="popover"
            popupId="options-popup-menu"
            style={{ zIndex: 1 }}
            open={openmenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {(popupState) => (
              <div>
                <Button
                  className="action-list"
                  variant="contained"
                  {...bindTrigger(popupState)}
                >
                  <MoreVertIcon
                    ref={anchorRef}
                    aria-controls={openmenu ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    alt="Remy Sharp"
                    size="small"
                  />
                </Button>
                <ClickAwayListener onClickAway={handleClose}>
                  <TableOptionList {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={() => {
                        setOpenmenu(false);
                        showProjectDetails(props.project);
                      }}
                      className="bold-line-600"
                    >
                      View Project
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() =>
                        getStatusChange("archived", props.project._id)
                      }
                      className="bold-line-600"
                    >
                      Archive
                    </MenuItem> */}
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleToggle();
                        popupState.close();
                        deleteProject(props.project._id);
                      }}
                      className="bold-line-600 clr-ff0000"
                    >
                      Delete
                    </MenuItem>
                  </TableOptionList>
                </ClickAwayListener>
              </div>
            )}
          </PopupState>
        </Box>

        <CardContent
          className={"card-content-container " + (show ? "__show" : "")}
          onClick={() => moveToDetails(props.project)}
        >
          <div className="title show-wrap-table-text">
            {props.project.project_name}
          </div>
          <div className="description">
            <div truncate="25">
              <span className="show-wrap-text">
                {props.project.description}
              </span>
              {/* <span className="show__more__link" style={{ display: "inline" }}>
                more
              </span> */}
            </div>
          </div>
          <div className="details">
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item sm={6} xs={12}>
                <InputLabel className="mui-label">Start Date</InputLabel>
                <div className="field">
                  {moment(props.project.startDate).format("MMM Do YY")}
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputLabel className="mui-label">End Date</InputLabel>
                <div className="field">
                  {moment(props.project.endDate).format("MMM Do YY")}
                </div>
              </Grid>
            </Grid>
            <Collapse in={show}>
              <Grid
                container
                className="mt-2"
                spacing={1}
                justifyContent="space-between"
              >
                <Grid item xs={12}>
                  <InputLabel className="mui-label">Location</InputLabel>
                  <div className="field show-wrap-table-text">
                    {props.project.location}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel className="mui-label">Project Manager</InputLabel>
                  <div className="field show-wrap-table-text">
                    {props.project.project_manager}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel className="mui-label">Project Buyer</InputLabel>
                  <div className="field show-wrap-table-text">
                    {props?.project?.ownerName?.first_name}{" "}
                    {props?.project?.ownerName?.last_name}
                  </div>
                </Grid>
              </Grid>
            </Collapse>
          </div>
        </CardContent>
        {/* </CardActionArea> */}
      </Card>

      {/* Form 1 */}
      <Dialog open={openPopup} maxWidth="md">
        <DialogTitle>
          <div style={{ display: "flex" }}>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              Edit Project
            </Typography>
            <Controls.ActionButton
              color="secondary"
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              <CloseIcon />
            </Controls.ActionButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <EditProjectForm
            recordForEdit={recordForEdit}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            openPopup2={openPopup2}
            setOpenPopup2={setOpenPopup2}
            stepLabels={stepLabels}
          />
        </DialogContent>
      </Dialog>

      {/* Form 2 */}
      <Dialog open={openPopup2} maxWidth="md">
        <DialogTitle>
          <div style={{ display: "flex" }}>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              Edit Project
            </Typography>

            <Controls.ActionButton
              color="secondary"
              onClick={() => {
                setOpenPopup2(false);
              }}
            >
              <CloseIcon />
            </Controls.ActionButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {/* {children} */}
          <EditProjectFormStepTwo
            // openPopup={openPopup}
            // setOpenPopup={setOpenPopup}
            openPopup2={openPopup2}
            setOpenPopup2={setOpenPopup2}
            recordForEdit={recordForEdit}
            onFormEdit={onFormEdit}
            stepLabels={stepLabels}
          />
        </DialogContent>
      </Dialog>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmDeleteProject={confirmDeleteProject}
      />
    </div>
  );
}
