import React, { useEffect } from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Search from "@material-ui/icons/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import InvitedSellerPopup from "../../../Auction/components/InvitedSellerPopup";
import InviteNewSellerPopup from "../../../Auction/components/InviteNewSupplier";
import FormSteps from "../../../../components/Formsteps";
import { store } from "../../../../store/store";
import { auctionSaveAsDraft } from "../../../../store/actions/auctionAction";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import "../../../Auction/styles/details-suplliers.css";
import "../../../Auction/styles/create-auction-step5.css";
import { toast, Slide } from "react-toastify";
import Controls from "../../../../components/controls/Controls";
import CommonButton from "../../../../components/controls/CommonButton";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import {
  COMAPNY_VERSION_API,
  POST_RFX_ADD_SUPPLIER_API,
} from "../../../../constants/ApiUrl";
import { Api } from "../../../../api/Api";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { TOAST_AUTO_CLOSE } from "../../../../constants/AppConstants";
import LogoIcon from "../../../../assets/Logo_2.png";
import {
  AddSupplierInRfx,
  RfxSubmit,
  rfxSaveAsDraft,
} from "../../../../store/actions/rfxAction";

const columns = [
  {
    label: "Company Name",
    minWidth: 250,
  },
  {
    label: "Main Contact Name",
    minWidth: 400,
  },
  {
    label: "Invited Seller",
  },
  {
    label: "",
  },
];
const RfxFormStep6 = ({
  openPopupStep6,
  setOpenPopupStep5,
  setOpenPopupStep6,
  discardChanges,
  stepLabels,
  setResendApi,
  fullLoder,
  setFullLoder,
  fromEditTab,
  rfxDetails,
}) => {
  const rfxData = useSelector((state) => state.rfx);
  const { auth } = store.getState();
  const companyId = auth?.auth?.user?.company?._id;
  const dispatch = useDispatch();
  const [suppliers, setSuppliers] = React.useState([]);
  const [newInvitedSuppliers, setNewInvitedSuppliers] = React.useState([]);
  const [suppliersTable, setSuppliersTable] = React.useState([]);
  const [dropDownSuppliers, setdropDownSuppliers] = React.useState([]);
  const [openSellerPopup, setOpenSellerPopup] = React.useState(false);
  const [openInviteSupplierPopup, setOpenInviteSupplierPopup] =
    React.useState(false);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  React.useState(null);
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  /*V1 */
  const getAllItem = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(
      `${COMAPNY_VERSION_API}/${companyId}/suppliersActive`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.suppliers;

        let companyData = Object.values(allItems);
        let final = [];
        companyData.map((item) => final.push(item[item.length - 1]));
        final.map((item) => {
          item.supplier_company_name = item?.supplier_company?.company_name;
        });
        setdropDownSuppliers(final);
      }
    }
  };

  const getSelectedSupplier = (supplier) => {
    if (supplier) {
      const { auth } = store.getState();
      let oldSuppliers = [...suppliers];
      const companyName = auth?.auth?.user?.company?.company_name;
      const companyId = auth?.auth?.user?.company?._id;
      const MainContactName =
        supplier?.main_contact?.first_name +
        " " +
        supplier?.main_contact?.last_name;
      const supplierEmail = {
        email: supplier.email,
        supplierId: supplier?.supplier_company?._id,
        supplierCompanyName: supplier?.supplier_company?.company_name,
        mainContact: MainContactName,
        userId: supplier?.main_contact?._id,
        companyId: companyId,
        company_buyer_name: companyName,
        supplierCompanyId: supplier?.supplier_company?._id,
        selectedInviteSupplier: [],
      };

      let anyObj = oldSuppliers.find(
        (item) => item.userId === supplierEmail.userId
      );

      if (anyObj && anyObj.userId) {
        return;
      }
      suppliers.push(supplierEmail);
      const neItems = [...suppliers];
      setSuppliers(neItems);
      if (!fromEditTab) dispatch(RfxSubmit({ Suppliers: suppliers }));
    }
  };

  const submitAuction = async () => {
    if (fromEditTab) {
      submitSupplierForEdit();
      return;
    }
    if (suppliers && suppliers.length > 0) {
      setFullLoder(true);

      let res = await dispatch(AddSupplierInRfx(setFullLoder));

      if (res?.data?.responseCode === "00") {
        setFullLoder(false);
        if (setResendApi) {
          setResendApi(true);
        }
        setOpenPopupStep6(false);
      } else {
        setFullLoder(false);
        setOpenPopupStep6(false);
      }
    } else {
      toast.error(ValidationMessage.selectSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };
  const submitSupplierForEdit = async () => {
    let OldSupplierArray = [];
    let newSupplierArray = [];
    if (suppliers && suppliers.length > 0) {
      const supplierArray = suppliers;
      supplierArray.forEach((element) => {
        if (element && element.isInvieted) {
          newSupplierArray.push(element);
        } else {
          OldSupplierArray.push(element);
        }
      });
      let payload = {
        companyId,
        suppliers: OldSupplierArray,
      };
      if (newSupplierArray.length > 0) {
        payload = { ...payload, selectedInviteNewSupplier: newSupplierArray };
      }

      const response = await Api(
        `${POST_RFX_ADD_SUPPLIER_API}/${rfxDetails?._id}`,
        payload,
        "post"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setResendApi(true);
        }
      }
      setOpenPopupStep6(false);
    } else {
      toast.error(ValidationMessage.selectSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };
  const saveAsDraft = async () => {
    if (suppliers && suppliers.length > 0) {
      setFullLoder(true);

      let res = await dispatch(rfxSaveAsDraft());
      if (res?.data?.responseCode === "00") {
        setFullLoder(false);
        setOpenPopupStep6(false);
      } else {
        setFullLoder(false);
        setOpenPopupStep6(false);
      }
    } else {
      toast.error(ValidationMessage.selectSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const pressBack = () => {
    dispatch(RfxSubmit({ Suppliers: suppliers }));
    setOpenPopupStep6(false);
    setOpenPopupStep5(true);
  };

  const getImportBoxSize = (dir = "left") => {
    if (dir === "left") {
      if (xlScreen) {
        return "w80-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w60-percent-box";
      } else if (mdScreen) {
        return "w73-percent-box";
      } else if (lgScreen) {
        return "w79-percent-box";
      }
    } else {
      if (xlScreen) {
        return "w19-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w40-percent-box";
      } else if (mdScreen) {
        return "w26-percent-box";
      } else if (lgScreen) {
        return "w20-percent-box";
      }
    }
  };

  const getSellerLabel = (supplier) => {
    return (
      <div
        className="sellers-link-container"
        onClick={() => {
          setOpenSellerPopup(true);
          setSelectedSupplier(supplier);
        }}
      >
        <div className="label">
          {supplier &&
          supplier.selectedInviteSupplier &&
          supplier.selectedInviteSupplier.length > 0
            ? `${supplier.selectedInviteSupplier.length} invited`
            : "All"}
        </div>
        <PlaylistAddIcon className="icon" />
      </div>
    );
  };

  const removeSelectIndex = (index) => {
    const removeIndex = suppliers.splice(index, 1);
    const neItems = [...suppliers];
    setSuppliers(neItems);
  };

  useEffect(() => {
    const { rfx } = store.getState();
    if (!fromEditTab) {
      if (rfx?.RfxForm1?.Suppliers) {
        setSuppliers(rfx?.RfxForm1?.Suppliers);
      }
    }
    getAllItem();
  }, []);

  useEffect(() => {
    if (!rfxData?.RfxForm1?.Suppliers) {
      setSuppliers([]);
    }
  }, [openPopupStep6]);

  return (
    <Modal
      open={openPopupStep6}
      aria-label="create-auction-fifth-step-buyer-aspect"
      aria-describedby="modal-modal-description"
      className="auction-form-container"
    >
      <Box className="popup-form-container">
        <>
          <Grid container>
            <Grid item xs={9}>
              {!fromEditTab ? (
                <p className="profile-title">
                  Create {rfxData?.RfxForm1?.rfx_type}
                </p>
              ) : (
                <p className="profile-title">Add Supplier!</p>
              )}
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  if (!fromEditTab) discardChanges();
                  else setOpenPopupStep6(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <div className="create-form-container">
            {!fromEditTab && (
              <Grid container justifyContent="center">
                <Grid item md={11} xs={12}>
                  <FormSteps
                    steps={stepLabels}
                    currStep={6}
                    className="mt-5 mb-5"
                  />
                </Grid>
              </Grid>
            )}
            <Paper elevation={0} square>
              {/* import item Section  */}
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid className={getImportBoxSize("left")}>
                  <Autocomplete
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    labelId="category-input"
                    options={dropDownSuppliers}
                    onChange={(event, value) => getSelectedSupplier(value)}
                    getOptionLabel={(option) =>
                      option ? option.supplier_company_name : ""
                    }
                    renderInput={(params) => (
                      <Controls.Input
                        name="budget"
                        labelId="budget-input"
                        fullWidth
                        {...params}
                      />
                    )}
                    popupIcon={<Search />}
                  />
                </Grid>
                <Grid className={getImportBoxSize("right")}>
                  <Controls.Button
                    text="Invite New Supplier"
                    disableElevation
                    color="default"
                    onClick={() => setOpenInviteSupplierPopup(true)}
                    startIcon={<PersonAddAltRoundedIcon />}
                    className="btn-mui btn-white auto-complete-btn"
                  />
                </Grid>
              </Grid>
              {/* import item Section  */}

              <h3 className="b-600 mb-0 mt-3">Added Suppliers</h3>

              <hr style={{ backgroundColor: "#e0e0e0", opacity: "0.5" }} />

              <TableContainer
                className="table-container editable-table"
                style={{ position: "relative" }}
                component={Paper}
              >
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {suppliers.map((supplier, index) => (
                      <TableRow
                        key={supplier?.supplierId}
                        className="editable-row"
                      >
                        <TableCell>{supplier?.supplierCompanyName}</TableCell>
                        <TableCell>{supplier?.mainContact}</TableCell>
                        <TableCell>
                          {/* {supplier && supplier.isInvieted
                        ? "-"
                        : getSellerLabel(supplier)} */}
                          {getSellerLabel(supplier)}
                        </TableCell>
                        <TableCell>
                          <Box className="option-cell __active">
                            <DeleteOutlineIcon
                              className="icon __edit clr-ff0000"
                              onClick={() => removeSelectIndex(index)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Modal
                open={openSellerPopup}
                aria-label="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="popup-form-container invite-seller-box">
                  <div className="title"> Edit Sellers Invited </div>
                  <div className="label">Company Name</div>
                  <div className="title">
                    <div className="icon-cotainer">
                      <img src={LogoIcon} alt="" className="icon" />
                    </div>
                    <div className="text">
                      {selectedSupplier?.supplierCompanyName}
                    </div>
                  </div>
                  <InvitedSellerPopup
                    openPopup={openSellerPopup}
                    setOpenPopup={setOpenSellerPopup}
                    supplier={selectedSupplier}
                    suppliers={suppliers}
                    setSuppliers={setSuppliers}
                  />
                </Box>
              </Modal>

              {/* invite new supplier popup */}
              <Modal
                open={openInviteSupplierPopup}
                aria-label="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="popup-form-container invite-seller-box">
                  <div className="title"> Invite New Supplier </div>
                  <InviteNewSellerPopup
                    openInviteSupplierPopup={openInviteSupplierPopup}
                    setOpenInviteSupplierPopup={setOpenInviteSupplierPopup}
                    newInvitedSuppliers={newInvitedSuppliers}
                    setNewInvitedSuppliers={setNewInvitedSuppliers}
                    suppliersTable={suppliersTable}
                    setSuppliersTable={setSuppliersTable}
                    suppliers={suppliers}
                    setSuppliers={setSuppliers}
                    isDispatch={true}
                    fromRfx={true}
                    fromEditTab={fromEditTab}
                  />
                </Box>
              </Modal>
            </Paper>

            {!fromEditTab ? (
              <Box
                Box
                className="BottomModal"
                display="flex"
                p={1}
                bgcolor="background.paper"
                style={{ marginTop: "20px" }}
              >
                <Grid istem xs={4} className="BottomModal-left">
                  <Box p={0} flexGrow={1}>
                    <Controls.Button
                      text="Back"
                      disableElevation
                      onClick={() => {
                        pressBack();
                      }}
                      style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                      color="default"
                      className="btn-mui fix-w-12em"
                    />
                  </Box>
                </Grid>

                <Grid item xs={8} className="BottomModal-right">
                  <Box p={0}>
                    <CommonButton
                      text="Save as Draft"
                      type="submit"
                      disableElevation
                      color="default"
                      style={{ margin: "0 20px 0 0" }}
                      onClick={() => saveAsDraft()}
                      className="btn-mui fix-w-12em"
                      loader={fullLoder}
                    />
                  </Box>

                  <Box p={0}>
                    <CommonButton
                      // type="submit"
                      disableElevation
                      text="Post"
                      onClick={() => submitAuction()}
                      className="btn-mui fix-w-12em"
                      loader={fullLoder}
                    />
                  </Box>
                </Grid>
              </Box>
            ) : (
              <Box p={0} className="global-flex-center">
                <CommonButton
                  // type="submit"
                  disableElevation
                  text="Save"
                  onClick={() => submitAuction()}
                  className="btn-mui fix-w-12em "
                  loader={fullLoder}
                />
              </Box>
            )}
          </div>
        </>
      </Box>
    </Modal>
  );
};

export default RfxFormStep6;
