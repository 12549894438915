import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

export default function BasicDatePicker(props) {
  const { label, value, name, onChange, error = null, ...other } = props;
  const [ClassName, setClassName] = React.useState("mui-input ");
  React.useEffect(() => {
    if (other && other.className) {
      setClassName(ClassName + other.className);
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        renderInput={(props) => (
          <TextField
            name={name}
            {...props}
            {...(error && { error: true, helperText: error })}
          />
        )}
        label={label}
        value={value}
        ampm={false}
        disablePast
        onChange={(newValue) => {
          //
          // let dateval = new Date(newValue);
          // must wrap value and name in object then pass it ti 'onChange' function
          onChange(newValue);
        }}
        {...other}
        className={ClassName}
      />
    </LocalizationProvider>
  );
}
