import {
  SAVE_AUCTION_STEP_1_REQUEST,
  SAVE_AUCTION_STEP_1,
  SAVE_AUCTION_STEP_2_REQUEST,
  SAVE_AUCTION_STEP_2,
  SAVE_AUCTION_STEP_3_REQUEST,
  SAVE_AUCTION_STEP_3,
  SAVE_AUCTION_STEP_4_REQUEST,
  SAVE_AUCTION_STEP_4,
  SAVE_AUCTION_STEP_5_REQUEST,
  SAVE_AUCTION_STEP_5,
  SAVE_AUCTION_PROJECT_DATA_REQUEST,
  SAVE_AUCTION_PROJECT_DATA,
  SAVE_AUCTION_PROJECT_LINK_REQUEST,
  SAVE_AUCTION_PROJECT_LINK,
  CLEAR_REDUX_ON_SAVED_SUCCESS,
  SAVE_AUCTION_STATUS,
  SAVE_INVITE_NEW_SUPPLIER_REQUEST,
  SAVE_INVITE_NEW_SUPPLIER,
  SAVE_LIB_DOCUMENT_REQUEST,
  SAVE_LIB_DOCUMENT,
  TEMP_SAVE_LIB_DOCUMENT_REQUEST,
  TEMP_SAVE_LIB_DOCUMENT,
  GET_LIVE_AUCTION_DETAILS,
  REFRESH_LIVE_BIDDING_DATA_BUYER,
  REFRESH_LIVE_AUCTION_BUYER,
} from "../constant/auctionConstant";

const initialState = {
  Auction: [],
  // this for live monitoring ...
  liveAuction: {},
  refreshLiveBidingData: false,
  refreshLiveAuction: false,
  httpCode: 200,
  httpMessage: "Process Done Successfully !",
  httpSuccess: true,
};

export default function AuctionReducer(
  state = initialState,
  actions,
  loading = false
) {
  switch (actions.type) {
    // live monitoring
    case GET_LIVE_AUCTION_DETAILS:
      return {
        ...state,
        liveAuction: actions.payload,
      };

    case REFRESH_LIVE_BIDDING_DATA_BUYER:
      return {
        ...state,
        refreshLiveBidingData: actions.payload,
      };

    case REFRESH_LIVE_AUCTION_BUYER:
      return {
        ...state,
        refreshLiveAuction: actions.payload,
      };
    // live monitoring
    case SAVE_AUCTION_PROJECT_DATA_REQUEST:
      return { loading: true };

    case SAVE_AUCTION_PROJECT_DATA:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case SAVE_AUCTION_STEP_1_REQUEST:
      return { loading: true };

    case SAVE_AUCTION_STEP_1:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case SAVE_AUCTION_STEP_2_REQUEST:
      return { loading: true };

    case SAVE_AUCTION_STEP_2:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case SAVE_AUCTION_STEP_3_REQUEST:
      return { loading: true };

    case SAVE_AUCTION_STEP_3:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case SAVE_AUCTION_STEP_4_REQUEST:
      return { loading: true };

    case SAVE_AUCTION_STEP_4:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case SAVE_AUCTION_STEP_5_REQUEST:
      return { loading: true };

    case SAVE_AUCTION_STEP_5:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case SAVE_AUCTION_PROJECT_LINK_REQUEST:
      return { loading: true };

    case SAVE_AUCTION_PROJECT_LINK:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case CLEAR_REDUX_ON_SAVED_SUCCESS:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case SAVE_AUCTION_STATUS:
      return {
        ...state,
        SaveSuccess: actions.payload,
        loading: false,
        httpCode: actions.code ? actions.code : state.httpCode,
        httpMessage: actions.message ? actions.message : state.httpMessage,
        httpSuccess: actions.success ? actions.success : state.httpSuccess,
      };
    case SAVE_INVITE_NEW_SUPPLIER_REQUEST:
      return { loading: true };

    case SAVE_INVITE_NEW_SUPPLIER:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case SAVE_LIB_DOCUMENT_REQUEST:
      return { loading: true };

    case SAVE_LIB_DOCUMENT:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    case TEMP_SAVE_LIB_DOCUMENT_REQUEST:
      return { loading: true };

    case TEMP_SAVE_LIB_DOCUMENT:
      return {
        ...state,
        AuctionForm1: actions.payload,
        loading: false,
      };
    default:
      return state;
  }
}
