import { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import { InputAdornment, TextField } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import Moment from "moment";
import { store } from "../../../store/store";

import "../styles/lib-attach-modal.css";
import useWindowDimensions from "../../../components/useWindowDimensions";
import { Api } from "../../../api/Api";
import { COMAPNY_API, ONLY_COMAPNY_API } from "../../../constants/ApiUrl";

export default function AddFromLibraryPopup(props) {
  const {
    setOpenPopupLib,
    getAllAttachments,
    setNotify,
    isFromSupplier,
    supplier,
    setFullLoder,
  } = props;
  const { xsScreen } = useWindowDimensions();
  const [checked, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [docsList, setDocsList] = useState([]);

  const checkallBoxes = (e) => {
    if (!e.target.value) {
      setCheckedAll(false);
      const checked = [];
      const docLi = [...checked];
      setChecked(docLi);
    } else {
      setCheckedAll(true);
      let neItems = [];
      docsList.filter((i) => {
        checked.push(i._id);
      });
      const docLi = [...checked];
      setChecked(docLi);
    }
  };

  const addDocumentFromLib = async (searchText) => {
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      if (!isFromSupplier) setFullLoder(true);
      const response = await Api(
        `${COMAPNY_API}/${companyId}/library${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allDocs = response.data.library;
          setDocsList(allDocs);
        } else {
          setDocsList([]);
        }
        if (!isFromSupplier) setFullLoder(false);
      } else {
        setDocsList([]);
        if (!isFromSupplier) setFullLoder(false);
      }
    }
  };

  const columns = [
    {
      label: "Template Document Name",
    },
    {
      label: "Date Added",
    },
    {
      label: (
        <div className="checkbox header checkbox-primary">
          <Controls.Checkbox
            name="checkall"
            color="primary"
            value={checkedAll}
            checked={checkedAll}
            onChange={checkallBoxes}
          />
        </div>
      ),
    },
  ];

  const checkBoxHandle = (e) => {
    const { name, value } = e.target;
    if (value) {
      const index = checked.findIndex((x) => x === name);
      if (!checked || index == -1) {
        checked.push(name);
        const neItems = [...checked];
        setChecked(neItems);
      } else {
        setChecked(checked.filter((item) => item !== name));
      }
    } else {
      const index = checked.findIndex((x) => x === name);
      if (index > -1) {
        checked.splice(index, 1);
        const neItems = [...checked];
        setChecked(neItems);
      }
    }
  };

  const attachSelectedDocs = async () => {
    if (checked && checked.length > 0) {
      const { auth } = store.getState();
      const userId = auth?.auth?.user?._id;
      let companyId = auth?.auth?.user?.company?._id;
      if (isFromSupplier) {
        companyId = supplier?.supplier_company?._id;
      }
      let formData = new FormData();
      formData.append("userId", userId);
      formData.append("doc", JSON.stringify(checked));
      formData.append("isSeller", isFromSupplier);
      if (!isFromSupplier) setFullLoder(true);
      const response = await Api(
        `${ONLY_COMAPNY_API}/${companyId}/addCompanyAttachment`,
        formData,
        "postMultipart"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setOpenPopupLib(false);
          getAllAttachments();
        }
        if (!isFromSupplier) setFullLoder(false);
      } else {
        if (!isFromSupplier) setFullLoder(false);
      }

    }
  };

  const requestSearch = (e) => {
    const searchText = e.target.value;
    addDocumentFromLib(searchText);
  };

  useEffect(() => {
    addDocumentFromLib();
  }, []);

  return (
    <>
      <Grid container>
        <Grid
          item
          md={4}
          sm={7}
          xs={12}
          style={{ textAlign: xsScreen ? "center" : "start" }}
        >
          <TextField
            onChange={requestSearch}
            placeholder="Search Using Keyword or Tags..."
            className="select-box select-box-primary search-box"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <img src={SearchIcon} style={{ width: '20px' }} /> */}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer
        className="table-container editable-table"
        style={{ position: "relative" }}
        component={Paper}
      >
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {docsList.map((item) => (
              <TableRow key={item._id}>
                <TableCell>
                  <AssignmentOutlinedIcon />
                  <span style={{ color: "#2170FF" }} className="TextAfterImage">
                    {item && item.document ? item?.document?.name : ""}
                  </span>
                </TableCell>
                <TableCell>
                  {Moment(item.createdDate).format("DD-MM-YYYY LT")}
                </TableCell>
                <TableCell>
                  <div className="checkbox checkbox-primary">
                    <Controls.Checkbox
                      name={item?._id}
                      color="primary"
                      value={checked.includes(item?._id) ? item?._id : ""}
                      checked={checked.includes(item?._id) ? item?._id : ""}
                      onChange={checkBoxHandle}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p className="table-record-count-libarary">
        {docsList.length} Documents Found
      </p>
      <Box
        className="BottomModal"
        display="flex"
        p={1}
        bgcolor="background.paper"
      >
        <Grid istem xs={4} className="BottomModal-left"></Grid>
        <Grid item xs={8} className="BottomModal-right">
          <Box p={0}>
            <Controls.Button
              text="Cancel"
              disableElevation
              onClick={() => {
                setOpenPopupLib(false);
              }}
              color="default"
              className="btn btn-mui draft"
            />
          </Box>
          <Box p={0}>
            <Controls.Button
              disableElevation
              text="Attach"
              className="btn-mui"
              onClick={() => attachSelectedDocs()}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
}
