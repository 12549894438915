import React, { Fragment } from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

// import { Select } from "@material-ui/core";
import { v1 as uuidv1 } from "uuid";

const SelectInput = ({
  label,
  name,
  options,
  control,
  rules,
  errors,
  placeHolder,
  errorMsg,
}) => {
  return (
    <FormControl fullWidth className="core-select">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Fragment>
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              name={name}
              value={field.value || ""}
              onChange={field.onChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem key={uuidv1()} value="">
                None
              </MenuItem>
              {/* <div
                key={uuidv1()}
                className={placeHolder ? "mui-select-placeholder" : "d-none"}
              >
                {placeHolder}
              </div> */}
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {(errorMsg || errors[name]?.message) && (
              <FormHelperText
                className="error-msg ml-0"
                error={errors[name] ? true : false}
              >
                {errorMsg || errors[name]?.message}
              </FormHelperText>
            )}
          </Fragment>
        )}
      />
    </FormControl>
  );
};

export default SelectInput;
