import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { store } from "../../store/store";
import { Grid } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../components/Notification";
import Loader from "../../components/Loader";
import "./item.css";
import { Pagination, PaginationItem } from "@material-ui/lab";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ConfirmDialog from "../../components/ConfirmDialog";
import DetailsSlider from "./components/detailsSlider";
import { useSelector } from "react-redux";
import { Api } from "../../api/Api";
import {
  DELETE_ITEM_API,
  GET_COMPANY_ITEM_CATEGORY_API,
  GET_ITEM_API,
  POST_EDIT_ITEM_DRAFT_API,
} from "../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_INACTIVE_CAP_SMALL,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
} from "../../constants/AppConstants";

const columns = [
  {
    label: "ID",
    align: "center",
    // minWidth: 170
  },
  {
    label: "Image",
    // minWidth: 100
  },
  {
    label: "Name",
    minWidth: 100,
  },
  {
    label: "Category",
    minWidth: 100,
  },
  {
    label: "Manufacturer",
  },
  {
    label: "Model",
  },
  {
    label: "Unit",
  },
  {
    label: "Status",
  },
  {
    label: "",
  },
];

export default function CustomPaginationActionsTable(props) {
  const { itemList, setItemList, rowsPerPage } = props;
  const companyDetails = useSelector(
    (state) => state?.auth?.auth?.user?.company
  );
  /// Please be carefull !!!
  /// must start with (1) for pagination issue ....
  const [page, setPage] = React.useState(1);
  const [categoryList, setCategoryList] = useState([]);

  const [fullLoder, setFullLoder] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [openDetailsPopup, setOpenDetailsPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const numOfPages = Math.ceil(itemList.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getAllItemsByCompanyName = async () => {
    const state = store.getState();

    const companyId = state?.auth?.auth?.user?.company?._id;

    if (companyId) {
      setFullLoder(true);
      const response = await Api(`${GET_ITEM_API}/${companyId}`, {}, "get");
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allItems = response.data.item;

          const query = setTimeout(() => {
            setItemList(allItems);
          }, 400);
          const loader = setTimeout(() => {
            setFullLoder(false);
          }, 500);

          return () => {
            clearTimeout(query);
            clearTimeout(loader);
          };
        }
      } else {
        setFullLoder(false);
      }
    }
  };

  const itemClickOnActive = async (itemData) => {
    const itemId = itemData?._id;
    if (itemId) {
      delete itemData._id;
      itemData.status = AUCTION_ACTIVE;
      itemData.companyId = companyDetails?._id;
      const response = await Api(
        `${POST_EDIT_ITEM_DRAFT_API}/${itemId}`,
        itemData,
        "patch"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllItemsByCompanyName();
        }
      }
    }
  };

  const itemClickOnInactive = async (itemData) => {
    const itemId = itemData?._id;
    if (itemId) {
      delete itemData._id;
      itemData.status = "Inactive";
      itemData.companyId = companyDetails?._id;
      const response = await Api(
        `${POST_EDIT_ITEM_DRAFT_API}/${itemId}`,
        itemData,
        "patch"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllItemsByCompanyName();
        }
      }
    }
  };

  const DeleteItem = async (itemData) => {
    if (itemData?._id) {
      setDeleteItemId(itemData?._id);
      setConfirmDialog({
        isOpen: true,
        title: "ITEM",
        subTitle: "Are you sure to delete Item",
      });
    }
  };

  const confirmDeleteProject = async () => {
    if (deleteItemId) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const payload = {
        id: `${deleteItemId}`,
      };
      const response = await Api(`${DELETE_ITEM_API}`, payload, "delete");
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setConfirmDialog({
            isOpen: false,
            title: "",
            subTitle: "",
          });

          const newItemList = itemList.filter(
            (item) => item._id !== deleteItemId
          );
          setItemList(newItemList);
          const numOfPages = Math.ceil(newItemList.length / rowsPerPage);
          if (page > numOfPages) {
            setPage(numOfPages);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (itemList.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [itemList, page]);

  const getAllCategory = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COMPANY_ITEM_CATEGORY_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let CatAndGroups = response.data.itemsCatAndGroups;
          let cateArr = [];
          CatAndGroups.map((group) => {
            if (group.itemType === "category") {
              cateArr.push(group);
            }
          });
          setCategoryList(cateArr);
        }
      }
    }
  };

  const getCategoryName = (cateId) => {
    const CateItem = categoryList.find((element) => element._id === cateId);
    return CateItem ? CateItem.name : "";
  };

  useEffect(() => {
    getAllItemsByCompanyName();
    getAllCategory();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case AUCTION_ACTIVE:
        return SUCCESS_BACKGROUND;
      case AUCTION_INACTIVE_CAP_SMALL:
        return SECONDARY_BACKGROUND;
      default:
        return SECONDARY_BACKGROUND;
    }
  };

  const viewItemDetails = (item) => {
    setOpenDetailsPopup(true);
    setSelectedItem(item);
  };

  return (
    <>
      <Paper elevation={0} square>
        <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? itemList.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : itemList
              ).map((item) => (
                <TableRow key={item._id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "center" }}
                  >
                    {item.itemId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <img src={item.image_upload} className="table-item-img" />
                  </TableCell>
                  <TableCell style={{ color: "#2170FF" }}>
                    {item.item_name}
                  </TableCell>
                  <TableCell>{item.category?.name}</TableCell>
                  <TableCell>{item.manufacturer}</TableCell>
                  <TableCell>{item.model}</TableCell>
                  <TableCell>{item.unit}</TableCell>
                  <TableCell>
                    <Grid item xs={6}>
                      <Box m={2}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <div>
                              <Button
                                className={
                                  "badge badge-" +
                                  getStatusColor(item.status) +
                                  " status"
                                }
                                variant="contained"
                                {...bindTrigger(popupState)}
                                endIcon={<ArrowDropDownIcon />}
                              >
                                {item.status}
                              </Button>
                              <Menu
                                {...bindMenu(popupState)}
                                PaperProps={{
                                  sx: {
                                    padding: "3px",
                                  },
                                }}
                              >
                                <div>
                                  <button
                                    className="badge badge-success select-colored-option"
                                    onClick={() => itemClickOnActive(item)}
                                    disabled={
                                      // item.status === { AUCTION_ACTIVE }
                                      item.status === AUCTION_ACTIVE
                                        ? true
                                        : false
                                    }
                                  >
                                    {AUCTION_ACTIVE}
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="badge badge-secondary select-colored-option"
                                    onClick={() => itemClickOnInactive(item)}
                                  >
                                    {AUCTION_INACTIVE_CAP_SMALL}
                                  </button>
                                </div>
                              </Menu>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <Menu
                              {...bindMenu(popupState)}
                              PaperProps={{
                                sx: {
                                  marginTop: "13px",
                                  minWidth: "10em",
                                },
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  viewItemDetails(item);
                                  popupState.close();
                                }}
                              >
                                View Item Details
                              </MenuItem>
                              <MenuItem
                                style={{ color: "red" }}
                                onClick={() => {
                                  DeleteItem(item);
                                  popupState.close();
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {itemList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some item
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <Box className="pagination-box-container">
            <Grid container>
              <Grid item xs={8} className="pagination-total-container">
                <h3> {itemList.length} Items Found </h3>
              </Grid>
              <Grid item xs={4} className="pagination-slider-container">
                <Pagination
                  count={numOfPages}
                  page={page}
                  onChange={handleChangePage}
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        previous: ArrowBackIosNewIcon,
                        next: ArrowForwardIosIcon,
                      }}
                      {...item}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>

      <DetailsSlider
        item={selectedItem}
        openPopup={openDetailsPopup}
        setOpenPopup={setOpenDetailsPopup}
        setNotify={setNotify}
        getAllItemsByCompanyName={getAllItemsByCompanyName}
      />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmDeleteProject={confirmDeleteProject}
      />
    </>
  );
}
