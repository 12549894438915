/*eslint-disable */
import React, { useEffect, useState } from "react";
import Controls from "../../../components/controls/Controls";
import Search from "@material-ui/icons/Search";
import {
  Paper,
  makeStyles,
  FormControl,
  FormHelperText,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Select,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import useWindowDimensions from "../../../components/useWindowDimensions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Modal from "@mui/material/Modal";
import { useForm, Form } from "../../../components/useForm";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { InputLabel } from "@mui/material";
import UserImage from "../../../assets/user.jpg";

import "../styles/user.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import {
  COMAPNY_API,
  DELETE_PROJECT_USER_API,
  ONLY_COMAPNY_API,
  POST_ADD_BUYER_PROJECT_API,
  POST_EDIT_PROJECT_USER_API,
} from "../../../constants/ApiUrl";
import { Api } from "../../../api/Api";
import { TOAST_AUTO_CLOSE } from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  ButtonAddUser: {
    border: "1px #2170ff solid",
    padding: "0.4em 1.4em",
    borderRadius: "10px",
  },
}));

var columns = [
  {
    label: "User",
  },
  {
    label: "Role",
  },
  // {
  //   label: "",
  // },
];

const initialFValues = {
  user: "",
  role: "",
};

const roles = [
  {
    id: "Project Editor",
    title: "Project Editor",
  },
  {
    id: "Project Viewer",
    title: "Project Viewer",
  },
];

const ProjectUsers = (props) => {
  const { selectedProject, currentUser } = props;
  const classes = useStyles();
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [dropdownUserList, setDropdownUserList] = useState([]);
  const [fullLoder, setFullLoder] = useState(false);
  const [editUserId, setEditUserId] = useState(false);
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState(null);
  const handleCloseConfirmation = () => setConfirmationBox(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const numOfPages = Math.ceil(userList.length / rowsPerPage);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("names" in fieldValues)
      temp.names = fieldValues.names ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const projectId = selectedProject._id;
      if (!isEditMode) {
        const paylod = {
          userId: values.user,
          role: values.role,
        };
        if (projectId) {
          const response = await Api(
            `${POST_ADD_BUYER_PROJECT_API}/${projectId}`,
            paylod,
            "post"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setOpen(false);
              getAllProjectUser();
            }
          }

          // await axiosInstance
          //   .post(`/api/buyer/addProjectUser/${projectId}`, paylod)
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setNotify({
          //         isOpen: true,
          //         message: "User assign successfully.",
          //         type: "success",
          //       });
          //       setOpen(false);
          //       getAllProjectUser();
          //     } else {
          //       setNotify({
          //         isOpen: true,
          //         message: response?.data?.responseDescription,
          //         type: "error",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: "Something goes wrong.",
          //       type: "error",
          //     });
          //   });
        } else {
          toast.error(ValidationMessage.somethingWrong, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      } else {
        if (projectId) {
          const editPaylod = {
            newRole: values.role,
          };
          const response = await Api(
            `${POST_EDIT_PROJECT_USER_API}/${projectId}/${editUserId}`,
            editPaylod,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setOpen(false);
              getAllProjectUser();
              setEditUserId(null);
              setIsEditMode(false);
            }
          }

          // await axiosInstance
          //   .patch(
          //     `/api/buyer/editProjectUser/${projectId}/${editUserId}`,
          //     editPaylod
          //   )
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setNotify({
          //         isOpen: true,
          //         message: "User assign successfully.",
          //         type: "success",
          //       });
          //       setOpen(false);
          //       getAllProjectUser();
          //       setEditUserId(null);
          //       setIsEditMode(false);
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: "Something goes wrong.",
          //       type: "error",
          //     });
          //   });
        } else {
          toast.error(ValidationMessage.somethingWrong, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      }
    }
  };

  const getUserImage = (user) => {
    if (user.image == null) {
      const twoLetters =
        user?.first_name.substring(0, 1) + user?.last_name.substring(0, 1);
      return <span className="letter-user-list"> {twoLetters} </span>;
    } else {
      return <img className="imge-user-list" src={user.image} />;
    }
  };

  const addEditUser = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      resetForm();
      setFullLoder(true);
      const response = await Api(
        `${ONLY_COMAPNY_API}/${companyId}/${selectedProject?.createdBy}/membersLists`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let users = response.data.companyTeamMembers;
          // -------------------------Attemtion Please---------------------------------
          // TODO : user's image must be return with user attrs
          users.forEach((user, index) => {
            if (index < 3) {
              user["image"] = UserImage;
            } else {
              user["image"] = null;
            }
          });

          // -------------------------Attemtion Please---------------------------------
          setDropdownUserList(users);
          setTimeout(() => {
            setFullLoder(false);
            setOpen(true);
          }, 700);
        } else {
          setFullLoder(false);
        }
      } else {
        setFullLoder(false);
      }

      // await axiosInstance
      //   .get(
      //     `api/company/${companyId}/${selectedProject?.createdBy}/membersLists`
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let users = response.data.companyTeamMembers;
      //       // -------------------------Attemtion Please---------------------------------
      //       // TODO : user's image must be return with user attrs
      //       users.forEach((user, index) => {
      //         if (index < 3) {
      //           user["image"] = UserImage;
      //         } else {
      //           user["image"] = null;
      //         }
      //       });

      //       // -------------------------Attemtion Please---------------------------------
      //       setDropdownUserList(users);
      //       setTimeout(() => {
      //         setFullLoder(false);
      //         setOpen(true);
      //       }, 700);
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: response?.data?.responseDescription,
      //         type: "error",
      //       });
      //       setFullLoder(false);
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //     setFullLoder(false);
      //   });
    }
  };

  const getAllProjectUser = async (searchText) => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const projectId = selectedProject?._id;
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    const response = await Api(
      `${COMAPNY_API}/${projectId}/projectUserList${queryString}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allUser = response.data.userData;
        setUserList(allUser);
      }
    }

    // axiosInstance
    //   .get(`api/buyer/company/${projectId}/projectUserList${queryString}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allUser = response.data.userData;
    //       setUserList(allUser);
    //     }
    //   })
    //   .catch((err) => {});
  };

  const editUser = (user) => {
    addEditUser();
    if (user) {
      setValues({
        user: user?.user._id,
        role: user?.role,
      });
      setIsEditMode(true);
      setEditUserId(user.user._id);
      setOpen(true);
    }
  };

  const deleteUser = (user) => {
    setDeleteUserData(user);
    setConfirmationBox(true);
  };

  const confirmDelete = async () => {
    if (deleteUserData) {
      const response = await Api(
        `${DELETE_PROJECT_USER_API}/${selectedProject?._id}/${deleteUserData?.user?._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        setConfirmationBox(false);
        if (response.data.responseCode === "00") {
          getAllProjectUser();
        }
      } else {
        let message = response?.data?.responseDescription
          ? response?.data?.responseDescription
          : response?.message;
        setConfirmationBox(false);
      }

      // await axiosInstance
      //   .delete(
      //     `api/buyer/deleteProjectUser/${selectedProject?._id}/${deleteUserData?.user?._id}`
      //   )
      //   .then((response) => {
      //     setConfirmationBox(false);
      //     if (response.data.responseCode === "00") {
      //       setNotify({
      //         isOpen: true,
      //         message: "User Removed successfully",
      //         type: "success",
      //       });
      //       getAllProjectUser();
      //     }
      //   })
      //   .catch((err) => {
      //     let message = err.response.data.responseDescription
      //       ? err.response.data.responseDescription
      //       : err.message;
      //     setConfirmationBox(false);
      //     setNotify({
      //       isOpen: true,
      //       message: message,
      //       type: "error",
      //     });
      //   });
    }
  };

  const confirmCancel = () => {
    setDeleteUserData(null);
    setConfirmationBox(false);
  };

  const requestSearch = (e) => {
    const searchText = e.target.value;
    getAllProjectUser(searchText);
  };

  const isOwner = () => {
    if (selectedProject && currentUser) {
      return String(selectedProject.createdBy) === String(currentUser._id);
    }
    return false;
  };

  useEffect(() => {
    if (isOwner() && columns.length < 3) {
      columns.push({
        label: "",
      });
    }
    getAllProjectUser();
  }, []);

  return (
    <>
      <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
      <Paper elevation={0} square>
        <Grid
          container
          className="filter-table-header"
          style={{ marginTop: "0px" }}
        >
          <Grid
            item
            sm={6}
            xs={12}
            style={{ textAlign: xsScreen ? "center" : "start" }}
          >
            <TextField
              onChange={requestSearch}
              placeholder="Search Using Keyword"
              className="select-box select-box-primary search-box"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {!selectedProject ||
          (selectedProject && selectedProject.createdBy != currentUser._id) ? (
            <></>
          ) : (
            <Grid
              item
              sm={6}
              xs={12}
              style={{ textAlign: xsScreen ? "center" : "end" }}
            >
              <Controls.Button
                text="Add User"
                className={classes.ButtonAddUser}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  addEditUser();
                }}
              />
            </Grid>
          )}
        </Grid>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? userList.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : userList
              ).map((item) => (
                <TableRow key={item._id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#2170FF" }}
                  >
                    {/* must be replaced with real data above  */}
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        style={{
                          width: "2em",
                          height: "2em",
                          objectFit: "cover",
                          borderRadius: "50%",
                          border: "2px #fff solid",
                        }}
                        src={
                          item.user.image_upload
                            ? item.user.image_upload
                            : "https://www.bootdey.com/img/Content/avatar/avatar2.png"
                        }
                      />
                    </div>
                    <span style={{ marginLeft: "10px" }}>
                      {item?.user?.first_name} {item?.user?.last_name}
                    </span>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.role}
                  </TableCell>
                  {/* {!selectedProject ||
                  item.creator ||
                  (selectedProject &&
                    selectedProject.createdBy != currentUser._id) ? (
                    <></>
                  ) : ( */}
                  <TableCell style={{ textAlign: "end" }}>
                    <Box>
                      <PopupState variant="popover" popupId="option-list-menu">
                        {(popupState) => (
                          <>
                            {!selectedProject ||
                            item.creator ||
                            (selectedProject &&
                              selectedProject.createdBy != currentUser._id) ? (
                              <></>
                            ) : (
                              <div>
                                <Button
                                  className="action-btn"
                                  variant="contained"
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreVertIcon />
                                </Button>
                                <TableOptionList {...bindMenu(popupState)}>
                                  <MenuItem
                                    className="bold-line-600"
                                    onClick={() => editUser(item)}
                                  >
                                    Edit User
                                  </MenuItem>
                                  <MenuItem
                                    className="bold-line-600 clr-ff0000"
                                    onClick={() => deleteUser(item)}
                                  >
                                    Delete
                                  </MenuItem>
                                </TableOptionList>
                              </div>
                            )}
                          </>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                  {/* )} */}
                </TableRow>
              ))}
              {userList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some user
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={userList.length + " Users Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
      </Paper>

      {/* add / edit user popup  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-label="project-details-user-popup"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <Grid container className="mb-5">
            <Grid item xs={9}>
              <p className="profile-title" style={{ borderBottom: "none" }}>
                {!isEditMode ? "Add" : "Edit"} User
              </p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel id="user-input">Select User</InputLabel>
                <FormControl
                  variant="outlined"
                  {...(errors.user && { errors: true })}
                >
                  <Select
                    name="user"
                    value={values.user}
                    onChange={handleInputChange}
                    disabled={isEditMode}
                    className="mui-select"
                  >
                    <MenuItem value="" className="inner-li">
                      None
                    </MenuItem>
                    {dropdownUserList.map((item) => (
                      <MenuItem
                        key={item._id}
                        value={item._id}
                        className="inner-li"
                      >
                        <div>
                          <div className="user-dropdown-data">
                            {getUserImage(item)}
                            <span className="user-title">{`${item.first_name} ${item.last_name}`}</span>
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.user && (
                    <FormHelperText>{errors.user}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginBottom: "9em" }} id="user-role-container">
                  <Controls.RadioGroup
                    isRowDir={false}
                    name="role"
                    value={values.role}
                    onChange={handleInputChange}
                    items={roles}
                    id="ratio-btn-primary"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent="center">
              <Grid item sm={6} xs={12}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text={!isEditMode ? "Done" : "Save"}
                  className="btn btn-mui w-100"
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Modal>
      {/* add / edit user popup  */}

      <Dialog
        open={confirmationBox}
        onClose={handleCloseConfirmation}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box style={{ padding: "20px 50px" }}>
          <DialogTitle id="alert-dialog-title" className="text-center">
            Are you sure you want delete?
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="text-center pb-5"
            >
              <Grid container justifyContent="center">
                <Grid item sm={8} xs={12}>
                  <Button
                    onClick={() => confirmDelete()}
                    variant="contained"
                    style={{ backgroundColor: "red" }}
                    className="btn btn-danger btn-mui w-100"
                  >
                    I'am Sure
                  </Button>
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Button
                    className="btn btn-mui w-100 mt-4"
                    onClick={() => confirmCancel()}
                    variant="contained"
                    style={{
                      marginRight: "0.5em",
                      backgroundColor: "#e2f2ff",
                      color: "#1976d2",
                      fontWeight: "600",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(ProjectUsers));
