import React, { useState, useEffect } from "react";
import { Divider, Modal, useMediaQuery } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { makeStyles } from "@material-ui/styles";
import Formsteps from "../../components/Formsteps";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { connect, useDispatch } from "react-redux";
import Notification from "../../components/Notification";
import { store } from "../../store/store";
import LinkPopup from "./itemLink";
import DocumentPopup from "./itemDocument";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../../components/useWindowDimensions";
import "./style/form.css";
import {
  CLEAR_ITEM_ON_SAVE,
  CLEAR_ITEM_ON_SAVE_DOCS,
} from "../../store/constant/itemConstants";
import { saveSecondFormDataTemp } from "../../store/actions/ItemActions";
import "./style/create-item-step2.css";
import attachIcon from "../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../assets/icons/CloseX.png";
import {
  ACCEPT_ONLY_IMAGE,
  AUCTION_ACTIVE,
  AUCTION_INACTIVE_CAP_SMALL,
  ITEM_FILE_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { Api } from "../../api/Api";
import {
  GET_ITEM_API,
  POST_ADD_ITEM_API,
  POST_EDIT_ITEM_DRAFT_API,
} from "../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";
import CommonButton from "../../components/controls/CommonButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  errMsg: {
    color: "red",
    fontSize: "14px",
    margin: "3px 0",
  },
  imgPreview: {
    width: "100%",
    height: "460px",
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#ccc",
    padding: "100px",
    border: "1px solid #000",
    background: "#cccccc",
  },

  customFileUpload: {
    color: "#55d6d6",
    fontSize: "22px",
    fontWeight: "500",
    padding: "6px 12px",
    cursor: "pointer",
  },
  files: { padding: "5px" },
  customeFileUpload: {
    position: "absolute",
    padding: "47px 20px",
    cursor: "pointer",
  },
  formPart1: {
    "& .MuiFormControl-root": {
      width: "90%",
    },
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    padding: ".5rem 2rem",
    fontWeight: "700",
    marginLeft: "0px",
    marginTop: "0px",
  },
  input: {
    display: "none",
  },
  attachmentText: {
    fontWeight: "700",
    fontSize: "1.3em",
  },
  attachment: {
    margin: "2rem 0",
  },
  Button: {
    padding: ".4rem 3rem",
    borderRadius: "6px",
    fontWeight: "700",
    fontSize: "14px",
  },
  imageInfo: {
    color: "#A19B9D",
    fontSize: "12px",
    maxWidth: "10.5rem",
  },
  imageInfoTwo: {
    color: "#A19B9D",
    fontSize: "12px",
  },
  modalContent: {
    width: "100%",
  },
  addLink: {
    color: "#1E1D1D",
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "1.5em",
  },
  dragAndDrop: {
    fontWeight: "700",
    fontSize: "18px",
  },
  attachmentBoxes: {
    padding: "10px",
  },
}));

const ItemForm2 = (props) => {
  const classes = useStyles();
  const {
    recordForEdit,
    openPopup,
    setOpenPopup,
    openPopup2,
    setOpenPopup2,
    projects,
    setItemList,
    stepLabels,
    fullLoder,
    setFullLoder,
  } = props;
  const dispatch = useDispatch();
  const [imgPreview, setImgPreview] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openAddDocument, setOpenAddDocument] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenDocument = () => setOpenAddDocument(true);
  const handleClose = () => setOpen(false);
  const handleCloseDocument = () => setOpenAddDocument(false);
  const [img, setImg] = useState("");
  const [imgFile, setImgFile] = useState(null);
  const [documentFile, setDocumentFile] = useState({});
  const [selectNewDocs, setselectNewDocs] = useState(null);
  const [editOnDynmicId, setEditOnDynmicId] = useState(null);
  const [finalAttachmentDocs, setFinalAttachmentDocs] = useState([]);
  const [linkData, setLinkData] = useState([]);
  const [linkDataArray, setLinkDataArray] = useState([]);
  const [error, setError] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [saveItemAsDraft, setSaveItemAsDraft] = React.useState(false);
  const [isSaveDraft, setIsSaveDraft] = useState(false);
  const [isEditSaveItem, setIsEditSaveItem] = useState(false);
  const [itemId, setItemId] = useState("");
  const [attachedDocument, setAttachedDocument] = useState([]);
  const [documentDataForList, setDocumentDataForList] = useState([]);
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const BlueText = withStyles({
    root: {
      color: "#2170FF;",
      fontWeight: "600",
      cursor: "pointer",
    },
  })(Typography);
  const state = store.getState();

  const AddItemAsDraft = async () => {
    setFullLoder(true);
    const firstFormData = state?.itemss?.ItemsFirstForm;

    if (Object.keys(firstFormData).length > 1) {
      let formData = new FormData();
      formData.append("status", "Inactive");
      formData.append(
        "company_name",
        state.auth.auth.user.company.company_name
      );

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      formData.append("item_name", firstFormData.item_name);
      formData.append(
        "itemId",
        "R" + Math.floor(100000 + Math.random() * 900000)
      );

      formData.append("manufacturer", firstFormData.manufacturer);
      formData.append("notes", firstFormData.notes);
      formData.append("unit", firstFormData.unit);
      formData.append("model", firstFormData.model);

      if (linkDataArray && linkDataArray.length > 0) {
        formData.append("links", JSON.stringify(linkDataArray));
      }
      if (firstFormData?.category && firstFormData?.category.length > 0) {
        formData.append("category", firstFormData.category);
      }
      if (firstFormData?.group && firstFormData?.group.length > 0) {
        formData.append("group", firstFormData.group);
      }
      if (firstFormData?.tags && firstFormData?.tags.length > 0) {
        formData.append("tags", firstFormData.tags);
      }
      if (imgFile && imgFile.type) {
        formData.append("image", imgFile);
      }
      formData.append("userId", state.auth.auth.user._id);

      if (finalAttachmentDocs) {
        for (let i = 0; i < finalAttachmentDocs.length; i++) {
          formData.append(
            "documents[" + i + "]",
            finalAttachmentDocs[i].document
          );
          formData.append(
            "documents[" + i + "][title]",
            finalAttachmentDocs[i].title
          );
          formData.append(
            "documents[" + i + "][notes]",
            finalAttachmentDocs[i].notes
          );
        }
      }
      formData.append("companyId", state.auth.auth.user.company?._id);
      const response = await Api(POST_ADD_ITEM_API, formData, "postMultipart");
      if (response.status === 200) {
        setImgFile(null);
        if (response.data.responseCode === "00") {
          //  let addedProjects = response.data;

          dispatch({
            type: CLEAR_ITEM_ON_SAVE,
            payload: {},
          });
          dispatch({
            type: CLEAR_ITEM_ON_SAVE_DOCS,
            payload: {},
          });
          setFullLoder(false);
          setOpenPopup2(false);
          setSaveItemAsDraft(false);
          getAllItemsByCompanyName();
        } else {
          setOpenPopup2(false);
          setFullLoder(false);
        }
      } else {
        setImgFile(null);
        setFullLoder(false);
        setOpenPopup2(false);
      }

      // axiosInstance
      //   .post(`api/buyer/item/addItem`, formData, config)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       //  let addedProjects = response.data;
      //       setNotify({
      //         isOpen: true,
      //         message: "Submitted Successfully",
      //         type: "success",
      //       });
      //       dispatch({
      //         type: CLEAR_ITEM_ON_SAVE,
      //         payload: {},
      //       });
      //       dispatch({
      //         type: CLEAR_ITEM_ON_SAVE_DOCS,
      //         payload: {},
      //       });
      //       setFullLoder(false);
      //       setOpenPopup2(false);
      //       setSaveItemAsDraft(false);
      //       getAllItemsByCompanyName();
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: "Submitted Successfully",
      //         type: "success",
      //       });
      //       setOpenPopup2(false);
      //       setFullLoder(false);
      //     }
      //   })
      //   .catch((err) => {
      //     setFullLoder(false);
      //     setNotify({
      //       isOpen: true,
      //       message: "Something goes wrong",
      //       type: "error",
      //     });
      //   });
    }
  };

  const submitItem = async () => {
    setFullLoder(true);
    const state = store.getState();
    const firstFormData = state?.itemss?.ItemsFirstForm;

    if (Object.keys(firstFormData).length > 1) {
      let formData = new FormData();

      let IStatus = "";

      if (isSaveDraft) {
        // Save item as draft and status is Inactive
        IStatus = AUCTION_INACTIVE_CAP_SMALL;
        // IStatus = "Active";
        // formData.append("status", "Inactive");
      } else {
        IStatus = AUCTION_ACTIVE;
        // IStatus = "Inactive";
      }
      formData.append("status", AUCTION_ACTIVE);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      formData.append(
        "company_name",
        state.auth.auth.user.company.company_name
      );
      if (isEditSaveItem === true) {
        let editPaylod = {
          itemId: editOnDynmicId,
          item_name: firstFormData.item_name.trim(),
          manufacturer: firstFormData.manufacturer,
          notes: firstFormData.notes,
          unit: firstFormData.unit,
          model: firstFormData.model,
          links: JSON.stringify(linkDataArray),
          status: IStatus,
          company_name: state?.auth?.auth?.user?.company?.company_name,
          image_upload: img,
        };

        let formDataEdit = new FormData();
        formDataEdit.append("itemId", editPaylod.editOnDynmicId);
        formDataEdit.append("item_name", editPaylod.item_name);
        formDataEdit.append("manufacturer", editPaylod.manufacturer);
        formDataEdit.append("notes", editPaylod.notes);
        formDataEdit.append("unit", editPaylod.unit);
        formDataEdit.append("model", editPaylod.model);
        formDataEdit.append("links", JSON.stringify(linkDataArray));
        formDataEdit.append("status", editPaylod.IStatus);
        formDataEdit.append(
          "company_name",
          state?.auth?.auth?.user?.company?.company_name
        );
        formDataEdit.append("companyId", state?.auth?.auth?.user?.company?._id);
        if (firstFormData?.category && firstFormData?.category.length > 0) {
          formDataEdit.append("category", firstFormData.category);
        }
        if (firstFormData?.group && firstFormData?.group.length > 0) {
          formDataEdit.append("group", firstFormData.group);
        }
        if (firstFormData?.tags && firstFormData?.tags.length > 0) {
          formDataEdit.append("tags", firstFormData.tags);
        }
        if (imgFile && imgFile.type) {
          formDataEdit.append("documents", imgFile);
        } else {
          formDataEdit.append("image_upload", img);
        }
        const response = await Api(
          `${POST_EDIT_ITEM_DRAFT_API}/${itemId}`,
          formDataEdit,
          "patch"
        );
        if (response.status === 200) {
          setImgFile(null);
          if (response.data.responseCode === "00") {
            //  let addedProjects = response.data;
            getAllItemsByCompanyName();
            setEditOnDynmicId(null);

            dispatch({
              type: CLEAR_ITEM_ON_SAVE,
              payload: {},
            });
            dispatch({
              type: CLEAR_ITEM_ON_SAVE_DOCS,
              payload: {},
            });
            setFullLoder(false);
            setOpenPopup2(false);
          } else {
            setFullLoder(false);
            setOpenPopup2(false);
          }
        } else {
          setImgFile(null);
          setFullLoder(false);
        }

        // axiosInstance
        //   .patch(`api/buyer/item/EditsaveItemDraft/${itemId}`, editPaylod)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       //  let addedProjects = response.data;
        //       getAllItemsByCompanyName();
        //       setEditOnDynmicId(null);
        //       setNotify({
        //         isOpen: true,
        //         message: response?.data?.responseDescription,
        //         type: "success",
        //       });
        //       dispatch({
        //         type: CLEAR_ITEM_ON_SAVE,
        //         payload: {},
        //       });
        //       dispatch({
        //         type: CLEAR_ITEM_ON_SAVE_DOCS,
        //         payload: {},
        //       });
        //       setFullLoder(false);
        //       setOpenPopup2(false);
        //     } else {
        //       setNotify({
        //         isOpen: true,
        //         message: response?.data?.responseDescription,
        //         type: "success",
        //       });
        //       setFullLoder(false);
        //       setOpenPopup2(false);
        //     }
        //   })
        //   .catch((err) => {
        //     setFullLoder(false);
        //     setNotify({
        //       isOpen: true,
        //       message: "Failed to save item",
        //       type: "error",
        //     });
        //   });
      } else {
        formData.append("item_name", firstFormData.item_name.trim());
        formData.append(
          "itemId",
          "R" + Math.floor(100000 + Math.random() * 900000)
        );
        if (
          firstFormData?.manufacturer &&
          firstFormData?.manufacturer.length > 0
        ) {
          formData.append("manufacturer", firstFormData.manufacturer);
        }
        if (firstFormData?.notes && firstFormData?.notes.length > 0) {
          formData.append("notes", firstFormData.notes);
        }
        formData.append("unit", firstFormData.unit);
        if (firstFormData?.model && firstFormData?.model.length > 0) {
          formData.append("model", firstFormData.model);
        }
        if (linkDataArray && linkDataArray.length > 0) {
          formData.append("links", JSON.stringify(linkDataArray));
        }

        formData.append("userId", state.auth.auth.user._id);
        if (firstFormData?.category && firstFormData?.category.length > 0) {
          formData.append("category", firstFormData.category);
        }
        if (firstFormData?.group && firstFormData?.group.length > 0) {
          formData.append("group", firstFormData.group);
        }
        if (firstFormData?.tags && firstFormData?.tags.length > 0) {
          formData.append("tags", firstFormData.tags);
        }
        if (finalAttachmentDocs) {
          for (let i = 0; i < finalAttachmentDocs.length; i++) {
            formData.append(
              "documents[" + i + "]",
              finalAttachmentDocs[i].document
            );
            formData.append(
              "documents[" + i + "][title]",
              finalAttachmentDocs[i].title
            );
            formData.append(
              "documents[" + i + "][notes]",
              finalAttachmentDocs[i].notes
            );
          }
        }
        formData.append("companyId", state.auth.auth.user.company?._id);
        if (imgFile && imgFile.type) {
          formData.append("image", imgFile);
        }
        const response = await Api(
          POST_ADD_ITEM_API,
          formData,
          "postMultipart"
        );
        if (response.status === 200) {
          setImgFile(null);
          if (response.data.responseCode === "00") {
            //  let addedProjects = response.data;
            dispatch({
              type: CLEAR_ITEM_ON_SAVE,
              payload: {},
            });
            dispatch({
              type: CLEAR_ITEM_ON_SAVE_DOCS,
              payload: {},
            });
            setFullLoder(false);
            setOpenPopup2(false);
            setSaveItemAsDraft(false);
            getAllItemsByCompanyName();
          } else {
            setFullLoder(false);
            setOpenPopup2(false);
          }
        } else {
          setImgFile(null);
          setFullLoder(false);
          setOpenPopup2(false);
        }

        // axiosInstance
        //   .post(`api/buyer/item/addItem`, formData, config)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       //  let addedProjects = response.data;
        //       setNotify({
        //         isOpen: true,
        //         message: response?.data?.responseDescription,
        //         type: "success",
        //       });
        //       dispatch({
        //         type: CLEAR_ITEM_ON_SAVE,
        //         payload: {},
        //       });
        //       dispatch({
        //         type: CLEAR_ITEM_ON_SAVE_DOCS,
        //         payload: {},
        //       });
        //       setFullLoder(false);
        //       setOpenPopup2(false);
        //       setSaveItemAsDraft(false);
        //       getAllItemsByCompanyName();
        //     } else {
        //       setNotify({
        //         isOpen: true,
        //         message: response?.data?.responseDescription,
        //         type: "success",
        //       });
        //       setFullLoder(false);
        //       setOpenPopup2(false);
        //     }
        //   })
        //   .catch((err) => {
        //     setFullLoder(false);
        //     setNotify({
        //       isOpen: true,
        //       message: "Please add link",
        //       type: "error",
        //     });
        //   });
      }
    } else {
      toast.error(ValidationMessage.somethingWrong, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      setFullLoder(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ACCEPT_ONLY_IMAGE;
    if (selected && !ALLOWED_TYPES.includes(selected.type)) {
      setError(ValidationMessage.itemFileType);
      return;
    }
    if (selected && Math.round(selected.size / 1024) > ITEM_FILE_SIZE) {
      setError(ValidationMessage.itemFileSize);
      return;
    }
    if (
      selected &&
      ALLOWED_TYPES.includes(selected.type) &&
      Math.round(selected.size / 1024) < ITEM_FILE_SIZE
    ) {
      setImgFile(selected);
      const base64Image = await getBase64(selected);
      setImg(base64Image);
      setImgPreview(base64Image);
      setError("");
    }
  }

  const removeSelectedImage = () => {
    setImg(null);
    setImgPreview(null);
  };

  const removeAttachedDocument = (docsData, index) => {
    if (docsData && docsData.link) {
      if (docsData && docsData.title) {
        const newData = linkDataArray.filter((o) => {
          return o.tempId !== docsData.tempId;
        });
        setLinkDataArray([...newData]);
      }
    } else if (docsData && docsData.title) {
      if (docsData && docsData.document.lastModified) {
        if (docsData && docsData.title) {
          const newData = finalAttachmentDocs.filter((o) => {
            return o.document.tempId !== docsData.document.tempId;
          });
          setDocumentFile({});
          setFinalAttachmentDocs([...newData]);
        }
      }
    }

    documentDataForList.splice(index, 1);
    setDocumentDataForList(documentDataForList);
    if (docsData && docsData.link) {
      toast.success(ValidationMessage.removeLink, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    } else {
      toast.success(ValidationMessage.removeDocument, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const pressBack = () => {
    const form2Data = {
      document: finalAttachmentDocs,
      links: linkDataArray,
      itemImage: img,
      listlinkanddoc: documentDataForList,
    };
    dispatch(saveSecondFormDataTemp({ tempForm2: form2Data }));
    setOpenPopup2(false);
    setOpenPopup(true);
  };

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const getAllItemsByCompanyName = async () => {
    setFullLoder(true);
    const state = store.getState();

    const companyId = state?.auth?.auth?.user?.company?._id;

    if (companyId) {
      const response = await Api(`${GET_ITEM_API}/${companyId}`, {}, "get");
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allItems = response.data.item;
          setItemList(allItems);
          setFullLoder(false);
        } else {
          setItemList([]);
          setFullLoder(false);
        }
      }

      // await axiosInstance
      //   .get(`api/buyer/item/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let allItems = response.data.item;
      //       setItemList(allItems);
      //       setFullLoder(false);
      //     } else {
      //       setItemList([]);
      //       setFullLoder(false);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  useEffect(() => {
    if (recordForEdit && recordForEdit.document) {
      setAttachedDocument(recordForEdit.document);
    }

    if (recordForEdit && Object.keys(recordForEdit).length > 1) {
      setImgPreview(recordForEdit.image_upload);
      setImg(recordForEdit.image_upload);
    }

    if (recordForEdit && recordForEdit._id) {
      setItemId(recordForEdit._id);
      setIsEditSaveItem(true);
    }

    setEditOnDynmicId(
      recordForEdit && recordForEdit.itemId ? recordForEdit.itemId : null
    );

    const { itemss } = store.getState();

    if (itemss && itemss.ItemsFirstForm && itemss.ItemsFirstForm.tempForm2) {
      const tempData = itemss.ItemsFirstForm.tempForm2;
      if (tempData && tempData.document) {
        setFinalAttachmentDocs(tempData.document);
      }
      if (tempData && tempData.links) {
        setLinkDataArray(tempData.links);
      }
      if (tempData && tempData.itemImage) {
        setImg(tempData.itemImage);
        setImgPreview(tempData.itemImage);
      }
      if (tempData && tempData.listlinkanddoc) {
        setDocumentDataForList(tempData.listlinkanddoc);
      }
    }
  }, [recordForEdit, finalAttachmentDocs]);

  const isXlargScreen = useMediaQuery("(min-width:1536px)");

  return (
    <>
      {/* create a new item step 2  */}
      {/* <Form onSubmit={handleSubmit} className="create-form-container"> */}
      <Grid container justifyContent="center">
        <Grid item xl={5} lg={6} md={7} sm={8} xs={12}>
          <Formsteps
            steps={stepLabels}
            currStep={2}
            className="mt-5 mb-5"
          ></Formsteps>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} className={classes.formPart1}></Grid>
      </Grid>
      <div style={{ marginBottom: "8px", color: "#a19b9d" }}>Image Upload</div>
      <Box display="flex">
        <Box>
          <div className="container">
            {error && error.length > 0 && (
              <p
                className="errMsg"
                style={{
                  color: "red",
                  fontSize: "11px",
                  margin: "3px 0",
                  paddingLeft: "10px",
                }}
              >
                {error}
              </p>
            )}
            <div
              className="imgPreview attach_box"
              style={{
                background: imgPreview
                  ? `url("${imgPreview}") no-repeat center/cover`
                  : "#e2f2ff",
              }}
            >
              {!imgPreview && (
                <>
                  <p>
                    <AddCircleIcon color="primary" />
                  </p>
                  <label
                    htmlFor="fileUploadItem"
                    className="customeFileUpload"
                    style={{
                      position: "absolute",
                      padding: "47px 57px",
                      cursor: "pointer",
                    }}
                  >
                    &nbsp;
                  </label>
                  <input
                    type="file"
                    id="fileUploadItem"
                    hidden
                    onChange={handleImageItem}
                  />
                  {/* <span>( jpg, jpeg or png)</span> */}
                </>
              )}
            </div>
          </div>
        </Box>
        <Box className="ml-4" style={{ minWidth: "13em" }}>
          <label>
            <Controls.Button
              component="span"
              text="Choose File"
              disableElevation
              style={{
                width: "150px",
                borderRadius: "25px",
                fontWeight: "600",
                height: "40px",
              }}
            />
            <input
              accept={ACCEPT_ONLY_IMAGE}
              className={classes.input}
              id="outlined-button-file"
              // multiple
              onChange={handleImageItem}
              type="file"
            />
          </label>
          <Controls.Button
            text="Delete"
            color="default"
            disableElevation
            onClick={() => removeSelectedImage()}
            className="btn draft"
            style={{
              width: "97px",
              height: "40px",
              borderRadius: "25px",
              fontWeight: "600",
            }}
          />
          <Typography className={classes.imageInfo}>
            540x540 px recommended. JPG or PNG. Max size of 800K
          </Typography>
        </Box>
      </Box>

      <div className={`${classes.attachment} ContainerAttachment0`}>
        <Typography variant="body" className={classes.attachmentText}>
          Attachments
        </Typography>
        <Divider variant="middle" />

        {documentDataForList && documentDataForList.length > 0 ? (
          <Grid container style={{ alignItems: "flex-end" }} spacing={2}>
            {documentDataForList.map((docs, index) => (
              <Grid item md={4} sm={6} xs={12}>
                {}
                <Box component="div" className="uploaded-files">
                  <Grid container>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      <img src={attachIcon} alt="" className="icon" />
                    </Grid>
                    <Grid item xs={9} className="details">
                      <Grid container>
                        <Grid item xs={12}>
                          <box className="name">
                            {docs?.document?.name || docs?.link}
                          </box>
                        </Grid>
                        {!docs?.link && (
                          <Grid item xs={12}>
                            <box className="size">
                              {bytesToSize(docs?.document?.size || 0)}
                            </box>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className="cursor-p"
                      style={{ textAlign: "end", margin: "auto" }}
                      onClick={() => removeAttachedDocument(docs, index)}
                    >
                      <img src={closeIcon} alt="" />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <div></div>
        )}
        <Box display="flex">
          <BlueText
            variant="sub"
            style={{ fontSize: "1.1em" }}
            onClick={handleOpen}
          >
            + Add Link
          </BlueText>
          <BlueText
            variant="sub"
            style={{ fontSize: "1.1em" }}
            className="ml-4"
            onClick={handleOpenDocument}
          >
            + Add Document
          </BlueText>
        </Box>
      </div>

      <div id="bottom-button-box-container">
        <div id="left-side">
          <Controls.Button
            text="Back"
            disableElevation
            className="btn-mui fix-w-12em  w-100"
            onClick={() => pressBack()}
            style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
          />
        </div>
        <div id="right-side">
          <div>
            <CommonButton
              text="Save as Draft"
              color="default"
              type="submit"
              disableElevation
              onClick={() => AddItemAsDraft()}
              className="btn-mui fix-w-12em btn draft  w-100"
              loader={fullLoder}
            />
          </div>
          <div>
            <CommonButton
              // type="submit"
              text="Add"
              disableElevation
              className="btn-mui fix-w-12em w-100"
              onClick={() => submitItem()}
              loader={fullLoder}
            />
          </div>
        </div>
      </div>

      {/* </Form> */}
      {/* create a new item step 2  */}

      {/* new link popup  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-label="create-item-second-step-buyer-aspect-upload-link-file"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Add Link</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <LinkPopup
            setOpen={setOpen}
            setLinkData={setLinkData}
            linkData={linkData}
            setDocumentFile={setDocumentFile}
            documentDataForList={documentDataForList}
            setDocumentDataForList={setDocumentDataForList}
            linkDataArray={linkDataArray}
            setLinkDataArray={setLinkDataArray}
          />
        </Box>
      </Modal>
      {/* new link popup  */}

      {/* new document popup  */}
      <Modal
        open={openAddDocument}
        onClose={handleCloseDocument}
        aria-label="create-item-second-step-buyer-aspect-upload-docs-file"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Add Document</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => setOpenAddDocument(false)}
                className="cursor-p"
              />
            </Grid>
          </Grid>
          <DocumentPopup
            documentFile={documentFile}
            setDocumentFile={setDocumentFile}
            finalAttachmentDocs={finalAttachmentDocs}
            setFinalAttachmentDocs={setFinalAttachmentDocs}
            setOpenAddDocument={setOpenAddDocument}
            setNotify={setNotify}
            documentDataForList={documentDataForList}
            setDocumentDataForList={setDocumentDataForList}
          />
        </Box>
      </Modal>
      {/* new document popup  */}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    projects: state.projects.projectsFirstForm,
  };
};

export default connect(mapStateToProps)(ItemForm2);
