import React, { useEffect, useState } from "react";
import Controls from "../../../../components/controls/Controls";
import { Box, Grid, InputLabel } from "@material-ui/core";
import { Form, useForm } from "../../../../components/useForm";
import CloseIcon from "@mui/icons-material/Close";
import Formsteps from "../../../../components/Formsteps";
import { Slide, toast } from "react-toastify";
import { TOAST_AUTO_CLOSE } from "../../../../constants/AppConstants";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { useDispatch, useSelector } from "react-redux";
import { RfxFormStep1Add } from "../../../../store/actions/rfxAction";
import Modal from "@mui/material/Modal";
import { isValidDate } from "../../../../utility/utils";

const initialValues = {
  name: "",
  description: "",
};
const RfxFormStep1 = ({
  stepLabels,
  openPopupStep1,
  setOpenPopupStep1,
  discardChanges,
  setOpenPopupStep2,
  setOpenConfrmationDailog,
}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() + 1 * 60000)
  );
  const rfxData = useSelector((state) => state.rfx);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("startDate" in fieldValues)
      temp.startDate = fieldValues.startDate ? "" : "This field is required.";
    if ("endtDate" in fieldValues)
      temp.endtDate = fieldValues.endtDate ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (
        startDate &&
        endDate &&
        isValidDate(startDate) &&
        isValidDate(endDate)
      ) {
        if (startDate.valueOf() > endDate.valueOf()) {
          toast.error(ValidationMessage.endDateValidation, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
          return;
        }
        values.name = values.name.trim();
        values.startDate = startDate;
        values.endDate = endDate;
        dispatch(RfxFormStep1Add(values));
        setOpenPopupStep1(false);
        setOpenPopupStep2(true);
      } else {
        toast.error(ValidationMessage.startEndDate, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    }
  };
  useEffect(() => {
    if (!rfxData?.RfxForm1?.name) resetForm();
  }, [openPopupStep1]);

  return (
    <Modal
      open={openPopupStep1}
      aria-label="create-auction-first-step-buyer-aspect"
      aria-describedby="modal-modal-description"
    >
      <Box className="popup-form-container">
        <>
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title">
                Create {rfxData?.RfxForm1?.rfx_type}
              </p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => discardChanges()}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Form onSubmit={handleSubmit} className="create-form-container">
            <Grid container justifyContent="center">
              <Grid item md={12} xs={12}>
                <Formsteps
                  steps={stepLabels}
                  currStep={1}
                  className="mt-5 mb-5"
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} className="mt-3">
              <Grid item xs={12}>
                <InputLabel id="name-input">Name</InputLabel>
                <Controls.Input
                  name="name"
                  fullWidth
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  inputProps={{ maxLength: 60 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="desc-input">Description</InputLabel>
                <Controls.Input
                  // label="desc-input"
                  name="description"
                  value={values.description}
                  onChange={handleInputChange}
                  className="fix-h-20em"
                  inputProps={{ maxLength: 499 }}
                />
              </Grid>
              <Grid item xs={6} className="datetime-picker-cotainer">
                <InputLabel id="name-input">Start Date & Time</InputLabel>
                <Controls.DateTimePicker
                  name="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e);
                    setEndDate(new Date(e?.getTime() + 30 * 60 * 1000));
                  }}
                  format="DD-MM-YYYY"
                  error={errors.startDate}
                />
              </Grid>
              <Grid item xs={6} className="datetime-picker-cotainer">
                <InputLabel id="name-input">End Date & Time</InputLabel>
                <Controls.DateTimePicker
                  name="endDate"
                  // value={values.endDate}
                  value={endDate}
                  // onChange={handleInputChange}
                  onChange={setEndDate}
                  minDate={startDate}
                  format="DD-MM-YYYY"
                  error={errors.endDate}
                />
              </Grid>
            </Grid>
            <Box
              className="BottomModal"
              display="flex"
              p={1}
              bgcolor="background.paper"
              style={{ marginTop: "20px" }}
            >
              <Grid container>
                <Grid item sm={4} className="BottomModal-left">
                  <Controls.Button
                    text="Cancel"
                    disableElevation
                    onClick={() => {
                      setOpenPopupStep1(false);
                    }}
                    style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                    color="default"
                    className="btn-mui fix-w-12em"
                  />
                </Grid>
                <Grid item xs={8} className="BottomModal-right">
                  <Controls.Button
                    type="submit"
                    disableElevation
                    text="Next"
                    className="btn-mui fix-w-12em"
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        </>
      </Box>
    </Modal>
  );
};

export default RfxFormStep1;
