import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { v1 as uuidv1 } from "uuid";

export default function Select(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    labelClassName,
    disabled,
    emptyOption = true,
    placeHolder = null,
    labelId = uuidv1(),
    ...other
  } = props;

  const [ClassName, setClassName] = React.useState("mui-select ");
  React.useEffect(() => {
    if (other && other.className) {
      setClassName(ClassName + other.className);
    }
  }, []);

  return (
    <FormControl
      className={ClassName}
      variant="outlined"
      {...(error && { error: true })}
      disabled={other.disabled ?? false}
    >
      <InputLabel className={labelClassName}>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        labelId={labelId}
        {...other}
      >
        <MenuItem
          key={uuidv1()}
          className={emptyOption ? "d-block" : "d-none"}
          value=""
        >
          None
        </MenuItem>
        <div
          key={uuidv1()}
          className={placeHolder ? "mui-select-placeholder" : "d-none"}
        >
          {placeHolder}
        </div>
        {options.map((item) => (
          <MenuItem key={uuidv1()} value={item.id || item.code || item._id}>
            {item.title || item.name || `${item.first_name}  ${item.last_name}`}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
