import { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import { Form, useForm } from "../../../components/useForm";
import axiosInstance from "../../../services/AxiosInstance";
import {
  GET_SUPPLIER_LIST_API,
  POST_AUCTION_EDIT_SUPPLIER_API,
} from "../../../constants/ApiUrl";
import { Api } from "../../../api/Api";
import { AUCTION_DRAFT } from "../../../constants/AppConstants";

const initialValues = {};
export default function InvitedSellerPopup(props) {
  const {
    openPopup,
    setOpenPopup,
    supplier,
    setSuppliers,
    suppliers,
    setNotify,
    setInvitedSupplierLabel,
    setResendApi,
    rfxDetails,
    currentLiveAuction,
  } = props;
  const [supplierList, setSupplierList] = useState([]);
  const [checked, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [isFromAuctionDetails, setIsFromAuctionDetails] = useState(false);
  const clickClosePopup = () => {
    setOpenPopup(false);
  };
  const getCompanySuppliers = async () => {
    if (supplier && supplier.supplierCompanyId) {
      const response = await Api(
        `${GET_SUPPLIER_LIST_API}/${supplier?.supplierCompanyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let supplierList = response.data.suppliers;
          setSupplierList(supplierList);
          if (
            suppliers &&
            supplier.selectedInviteSupplier &&
            supplier.selectedInviteSupplier.length > 0
          ) {
            setChecked(supplier.selectedInviteSupplier);
            setCheckedAll(false);
            if (
              supplierList &&
              supplierList.length === supplier.selectedInviteSupplier.length
            ) {
              setCheckedAll(true);
            }
          } else if (supplier && supplier.invitedSellerList) {
            setIsFromAuctionDetails(true);
            if (Array.isArray(supplier.invitedSellerList)) {
              if (supplier.invitedSellerList.length === supplierList.length) {
                setChecked(supplierList.map((li) => li._id));
                setCheckedAll(true);
              } else {
                let selectedSellers = [];
                for (let index = 0; index < supplierList.length; index++) {
                  const compSupplier = supplierList[index];
                  for (
                    let inIndex = 0;
                    inIndex < supplier.invitedSellerList.length;
                    inIndex++
                  ) {
                    const invitedSeller = supplier.invitedSellerList[inIndex];
                    if (
                      String(compSupplier.main_contact._id) ===
                      String(invitedSeller._id)
                    ) {
                      selectedSellers.push(compSupplier);
                    }
                  }
                }
                setChecked(selectedSellers.map((li) => li._id));
                setCheckedAll(false);
              }
            } else {
              setChecked([]);
              setCheckedAll(false);
            }
          } else {
            setCheckedAll(true);
            setChecked(supplierList.map((li) => li._id));
          }
        }
      }

      // await axiosInstance
      //   .get(`api/company/suppliersList/${supplier?.supplierCompanyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let supplierList = response.data.suppliers;
      //       setSupplierList(supplierList);
      //       if (
      //         suppliers &&
      //         supplier.selectedInviteSupplier &&
      //         supplier.selectedInviteSupplier.length > 0
      //       ) {
      //         setChecked(supplier.selectedInviteSupplier);
      //         setCheckedAll(false);
      //         if (
      //           supplierList &&
      //           supplierList.length === supplier.selectedInviteSupplier.length
      //         ) {
      //           setCheckedAll(true);
      //         }
      //       } else if (supplier && supplier.invitedSellerList) {
      //         setIsFromAuctionDetails(true);
      //         if (Array.isArray(supplier.invitedSellerList)) {
      //           if (supplier.invitedSellerList.length === supplierList.length) {
      //             setChecked(supplierList.map((li) => li._id));
      //             setCheckedAll(true);
      //           } else {
      //             let selectedSellers = [];
      //             for (let index = 0; index < supplierList.length; index++) {
      //               const compSupplier = supplierList[index];
      //               for (
      //                 let inIndex = 0;
      //                 inIndex < supplier.invitedSellerList.length;
      //                 inIndex++
      //               ) {
      //                 const invitedSeller = supplier.invitedSellerList[inIndex];
      //                 if (
      //                   String(compSupplier.main_contact._id) ===
      //                   String(invitedSeller._id)
      //                 ) {
      //                   selectedSellers.push(compSupplier);
      //                 }
      //               }
      //             }
      //             setChecked(selectedSellers.map((li) => li._id));
      //             setCheckedAll(false);
      //           }
      //         } else {
      //           setChecked([]);
      //           setCheckedAll(false);
      //         }
      //       } else {
      //         setCheckedAll(true);
      //         setChecked(supplierList.map((li) => li._id));
      //       }
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    suppliers.map((sup) => {
      if (!isFromAuctionDetails) {
        if (sup.supplierCompanyId === supplier?.supplierCompanyId) {
          sup.selectedInviteSupplier = checked;
        }
      }
    });
    if (!isFromAuctionDetails) {
      setSuppliers(suppliers);
      setOpenPopup(false);
    } else {
      const payload = {
        selectedInviteNewSupplier: JSON.stringify(checked),
        auctionId: supplier?.auctionUId,
      };
      const response = await Api(
        `${POST_AUCTION_EDIT_SUPPLIER_API}/${supplier?.supplierCompanyId}`,
        payload,
        "post"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          const totalSelected = checked.length;

          // if (supplier && supplier?.invitedSellerList) {
          //   if (supplier?.invitedSellerList?.selectedInviteSupplier !== "") {
          //     const supplierArrayData = JSON.parse(supplier?.invitedSellerList?.selectedInviteSupplier);
          //     if (totalSelected == 0) {
          //       setInvitedSupplierLabel("0 invited");
          //     } else if (totalSelected !== supplierArrayData) {
          //       setInvitedSupplierLabel(`${totalSelected} invited`);
          //     }
          //   }
          // }
          setResendApi(true);
          setOpenPopup(false);
        }
      }

      // await axiosInstance
      //   .post(
      //     `api/buyer/company/auctionEditSupplierInvite/${supplier?.supplierCompanyId}`,
      //     payload
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       const totalSelected = checked.length;

      //       // if (supplier && supplier?.invitedSellerList) {
      //       //   if (supplier?.invitedSellerList?.selectedInviteSupplier !== "") {
      //       //     const supplierArrayData = JSON.parse(supplier?.invitedSellerList?.selectedInviteSupplier);
      //       //     if (totalSelected == 0) {
      //       //       setInvitedSupplierLabel("0 invited");
      //       //     } else if (totalSelected !== supplierArrayData) {
      //       //       setInvitedSupplierLabel(`${totalSelected} invited`);
      //       //     }
      //       //   }
      //       // }
      //       setResendApi(true);
      //       setOpenPopup(false);
      //       setNotify({
      //         isOpen: true,
      //         message: response.data.responseDescription,
      //         type: "success",
      //       });
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: "Failed to edit invite supplier",
      //         type: "error",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  useEffect(() => {
    getCompanySuppliers();
  }, []);

  const getSellerCheckListOption = () => {
    const checkallBoxes = (e) => {
      if (!e.target.value) {
        setCheckedAll(false);
        setChecked([]);
      } else {
        setCheckedAll(true);
        setChecked(supplierList.map((li) => li._id));
      }
    };

    const testhandle = (e) => {
      const { name, value } = e.target;

      const checkedArrLen = checked.length + 1;

      if (supplierList.length === checkedArrLen && value) {
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }

      //setChecked([...checked, name]);
      if (value) {
        const index = checked.findIndex((x) => x === name);
        if (!checked || index == -1) {
          setChecked([...checked, name]);
        } else {
          setChecked(checked.filter((item) => item !== name));
        }
      } else {
        const index = checked.findIndex((x) => x === name);

        if (index > -1) {
          checked.splice(index, 1);
          // delete checked[index]

          setChecked([...checked]);
        }

        //setChecked(checked);
      }
    };

    if (supplierList.length > 0) {
      let options = supplierList.map((seller, index) => (
        <Grid item xs={12} className="seller-check-box-container">
          <Grid conrainer>
            <Grid item xs={12} className="seller-check-box">
              <div className="name-container">
                <span className="icon"></span>
                <span className="name">
                  {seller?.main_contact?.first_name}{" "}
                  {seller?.main_contact?.last_name}
                </span>
              </div>
              <div className="checkbox checkbox-primary">
                <Controls.Checkbox
                  name={seller?._id}
                  color="primary"
                  value={checked.includes(seller?._id) ? seller?._id : ""}
                  checked={checked.includes(seller?._id) ? seller?._id : ""}
                  onChange={testhandle}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      ));

      options.unshift(
        <Grid item xs={12} className="seller-check-box-container">
          <Grid conrainer>
            <Grid item xs={12} className="seller-check-box">
              <div className="name-container">
                <span className="name">Select All </span>
              </div>
              <div className="checkbox checkbox-primary">
                <Controls.Checkbox
                  name="checkall"
                  color="primary"
                  value={checkedAll}
                  checked={checkedAll}
                  onChange={checkallBoxes}
                />
              </div>
              {/* <div
                className={
                  "checkbox checkbox-primary " +
                  (isCheckedSeller ? "checked" : "")
                }
                onClick={() => {
                  setIsCheckedSeller(!isCheckedSeller);
                }}
              >
                <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                <CheckIcon aria-label="check-mark" />
              </div> */}
            </Grid>
          </Grid>
        </Grid>
      );
      return options;
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {getSellerCheckListOption()}
        </Grid>
        <Box
          className="BottomModal"
          display="flex"
          p={1}
          bgcolor="background.paper"
        >
          <Grid istem xs={4} className="BottomModal-left">
            <Box p={0} flexGrow={1}>
              <Controls.Button
                text="Cancel"
                disableElevation
                onClick={() => {
                  clickClosePopup();
                }}
                style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                color="default"
                className="btn-mui"
              />
            </Box>
          </Grid>
          {(currentLiveAuction?.status === AUCTION_DRAFT ||
            rfxDetails?.status === AUCTION_DRAFT) && (
            <Grid item xs={8} className="BottomModal-right">
              <Box p={0}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text="Save Changes"
                  className="btn-mui"
                />
              </Box>
            </Grid>
          )}
        </Box>
      </Form>
    </>
  );
}
