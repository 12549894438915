import { Api } from "../../../api/Api";
import { GET_LIVE_BID_GRAPH_API } from "../../../constants/ApiUrl";

const GetLiveGraphBidBL = async (props) => {
  const { currentAuction, timeSlot, suppliers } = props;

  let bidsData = [];

  // let response = await axiosInstance.get(
  //   `api/buyer/liveBidGraph/${currentAuction._id}?time_slot=${timeSlot}`
  // );
  const response = await Api(
    `${GET_LIVE_BID_GRAPH_API}/${currentAuction._id}?time_slot=${timeSlot}`,
    {},
    "get"
  );

  if (response.status === 200) {
    let stPriceMine = 0;

    if (response.data && response.data.bids !== undefined) {
      const bids = response.data.bids;
      const reservePrice = response.data.reservePrice
        ? response.data.reservePrice
        : 0;
      const startPrice = response.data.startPrice
        ? response.data.startPrice
        : 0;
      const startPrices = response.data.startPrices
        ? response.data.startPrices
        : {};

      // const suppliers = response.data.suppliers ? response.data.suppliers
      // : {};

      bids.forEach((bid) => {
        let label = formatLabel(new Date(bid.createdDate));
        let index = bidsData.findIndex((bid) => bid.label === label);
        if (index >= 0) {
          bidsData[index][`supplier_${bid.supplierId._id}`] = Number.parseInt(
            bid.weightedBid
          );
        } else {
          let obj = {
            label: label,
            placer_id: bid.supplierId._id,
          };
          obj[`supplier_${bid.supplierId._id}`] = Number.parseInt(
            bid.weightedBid
          );
          bidsData.push(obj);
          index = bidsData.length - 1;
        }
        suppliers.forEach((supplier) => {
          if (bid.supplierId._id !== supplier.id) {
            if (index - 1 >= 0) {
              bidsData[index][`supplier_${supplier.id}`] = Number.parseInt(
                bidsData[index - 1][`supplier_${supplier.id}`]
              );
            } else {
              stPriceMine = startPrices[supplier.id]
                ? Number.parseInt(startPrices[supplier.id])
                : Number.parseInt(startPrice);
              bidsData[index][`supplier_${supplier.id}`] = stPriceMine;
            }
          }
        });
        bidsData[index][`supplier_reverse_price`] =
          Number.parseInt(reservePrice);
      });
    }
  }

  return {
    bidsData: bidsData,
  };
};

function formatLabel(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  let strTime = hours + ":" + minutes + ":" + seconds;
  // let strTime = hours + ":" + minutes;
  return strTime;
}

export default GetLiveGraphBidBL;
