import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import { Grid } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { getCurrentAuction } from "../../../../store/selectors/supplierAspect/auctionSelector";
import ParticipateInAuctionBL from "../BL/ParticipateInAuctionBL";
import DeclineAuctionBL from "../BL/DeclineAuctionBL";
import "../styles/details-banner.css";
import { currentUser } from "../../../../store/selectors/AuthSelectors";
import Notification from "../../../../components/Notification";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GetAuctionByIdBL from "../BL/GetAuctionByIdBL";
import { setAuctionDetails } from "../../../../store/actions/supplierAspect/auctionAction";
import {
  AUCTION_ACCEPTED_CAPITAL,
  AUCTION_CANCELLED,
  AUCTION_DASH_CAPITAL_ON_HOLD,
  AUCTION_DASH_WIN_LOST,
  AUCTION_DECLINED_CAPITAL,
  AUCTION_INVITED,
  AUCTION_INVITED_CAPITAL,
  AUCTION_RUNNING,
  DANGER_BACKGROUND,
  PURPLE_BACKGROUND,
  SUCCESS_BACKGROUND,
  SUPPLIER_AUCTION_PENDING,
  WARNING_BACKGROUND,
} from "../../../../constants/AppConstants";

const AuctionBanner = (props) => {
  const { currentAuction, currentUser, setNotify } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_INVITED_CAPITAL:
        return WARNING_BACKGROUND;
      case AUCTION_ACCEPTED_CAPITAL:
        return SUCCESS_BACKGROUND;
      case AUCTION_DASH_WIN_LOST:
        return SUCCESS_BACKGROUND;
      case AUCTION_CANCELLED:
        return WARNING_BACKGROUND;
      case AUCTION_DECLINED_CAPITAL:
        return DANGER_BACKGROUND;
      case AUCTION_RUNNING:
        return DANGER_BACKGROUND;
      case AUCTION_DASH_CAPITAL_ON_HOLD:
        return PURPLE_BACKGROUND;
      default:
        return SUCCESS_BACKGROUND;
    }
  };

  const participateAuction = () => {
    ParticipateInAuctionBL({
      auctionId: currentAuction?.auctionId?._id,
    }).then((data) => {
      if (data.success) {
        // must be take it from response of API
        // no need to send API to refresh the auction
        const message = data.message;
        GetAuctionByIdBL({
          id: currentAuction._id,
        }).then((data) => {
          dispatch(setAuctionDetails(data.auction));
        });
      }
    });
  };

  const declineAuction = () => {
    DeclineAuctionBL({
      auctionId: currentAuction?.auctionId?._id,
    }).then((data) => {
      if (data.success) {
        history.push("/supplier/auction/");
      }
    });
  };

  return (
    <div style={{ marginTop: "6em" }}>
      <div
        className="details-banner supplier-aspect"
        style={{ background: "transparent" }}
      >
        <div className="banner-container">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h1 className="project-title">
                <span style={{ verticalAlign: "middle", marginRight: "0.5em" }}>
                  {currentAuction?.auctionId?.auction_name}
                </span>
                <div
                  className="table-container"
                  style={{ display: "inline", verticalAlign: "sub" }}
                >
                  <Button
                    className={
                      "badge badge-" +
                      getStatusLabel(currentAuction?.auctionStatus) +
                      " status"
                    }
                    variant="contained"
                  >
                    {currentAuction?.auctionStatus}
                  </Button>
                </div>
              </h1>
              <div className="project-title__smal">
                <span> Auctions </span>
                <span>{" / " + currentAuction?.auctionId?.auction_name}</span>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <button className="btn btn-primary go-back-button">
                  <Link to="/supplier/auction" className="mui-link">
                    <span style={{ color: "var(---2170ff-p-)" }}>
                      <ArrowBackIosNewIcon /> Go Back
                    </span>
                  </Link>
                </button>
              </Grid>
              {currentAuction.supplierStatus &&
              (currentAuction?.supplierStatus === AUCTION_INVITED ||
                currentAuction?.supplierStatus === SUPPLIER_AUCTION_PENDING) ? (
                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <button
                    className="btn btn-success btn-mui"
                    onClick={() => participateAuction()}
                  >
                    <span style={{ color: "#fff" }}>Participate</span>
                  </button>

                  <button
                    className="btn btn-danger btn-mui"
                    onClick={() => declineAuction()}
                  >
                    <span style={{ color: "#fff" }}>Decline</span>
                  </button>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuction: getCurrentAuction(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(AuctionBanner));
