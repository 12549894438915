import React, { useEffect, useState } from "react";
import ProjectForm from "./ProjectForm";
import { Paper, Box, Grid, TextField, InputAdornment } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import { connect, useDispatch } from "react-redux";
import { addSecondForm } from "../../store/actions/ProjectActions";
import {
  CLEAR_PROJECT_AFTER_SUBMIT,
  GET_PROJECTS_FAIL,
} from "../../store/constant/projectConstants";
import ProjectFormstep2 from "./ProjectFormstep2";
import ProjectCard from "./components/ProjectCard";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import ProjectFilter from "./components/filters/ProjectFilter";
import { store } from "../../store/store";
import "./styles/list.css";
import { MenuItem, Modal, Select } from "@mui/material";
import useWindowDimensions from "../../components/useWindowDimensions";
import Loader from "../../components/Loader";
import { HtmlUtilService } from "../../services/HtmlUtilService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Moment from "moment";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { currentUser } from "../../store/selectors/AuthSelectors";
import usePrevious from "../../components/usePrevious";
import GetAllProjectBL from "./BL/GetAllProjectBL";
import { Api } from "../../api/Api";
import { BUYER_PROJECT_LIST_API } from "../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { toast, Slide } from "react-toastify";
import { downloadDocumentFile } from "../../utility/utils";

const stepLabels = ["Initiation", "Details"];

const ProjectList = React.forwardRef((props, ref) => {
  const { currentUser, editData, errorMessage, successMessage } = props;
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  const [showFilterCategories, setShowFilterCategories] = useState(false);

  const { smScreen } = useWindowDimensions();

  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [projectDetailShow, setProjectDetailShow] = useState(false);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState({});
  const [listLimit, setListLimit] = React.useState(8);
  const [saveProjectDraft, setSaveProjectDraft] = useState(false);
  const [fullLoder, setFullLoder] = useState(false);
  const [shortingDropdown, setShortingDropdown] = useState("-1");
  const [totalProjectPage, setTotalProjectPage] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [resendApi, setResendApi] = useState(false);
  const prevPageValue = usePrevious(CurrentPage);
  const [searchText, setSearchText] = useState(null);
  const [filters, setFilters] = useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  React.useImperativeHandle(ref, () => ({
    foo() {
      alert("Hello world");
    },
  }));

  useEffect(() => {
    //  re-init filter
    localStorage.removeItem("projectFilter");
  }, []);

  useEffect(() => {
    if (currentUser) {
      setResendApi(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      currentUser &&
      prevPageValue !== undefined &&
      prevPageValue !== CurrentPage
    ) {
      setResendApi(true);
    }
  }, [CurrentPage]);

  useEffect(() => {
    if (resendApi) {
      // getAllProjectsAction();
      setFullLoder(true);
      setResendApi(false);
      GetAllProjectBL({
        companyId: currentUser.company._id,
        rowsPerPage: listLimit,
        page: CurrentPage,
        search: searchText,
        filters: filters,
        sort: shortingDropdown,
      })
        .then((data) => {
          setFullLoder(false);
          let list = [];
          for (let index = 0; index < (CurrentPage - 1) * listLimit; index++) {
            list.push(records[index]);
          }
          list = [...list, ...data.list];
          setRecords(list);
          setTotalProjectPage(Math.ceil(data.limit / listLimit));
          setTotalProjects(data.limit);
        })
        .catch((error) => {
          setFullLoder(false);
        });
    }
  }, [resendApi]);

  useEffect(() => {
    HtmlUtilService.truncate();
  }, [records]);

  const dispatch = useDispatch();

  const getAllProjectsAction = async (searchText, shortValue, limits, page) => {
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    let shortString = "";
    // if(shortValue){
    if (queryString === "") {
      shortString = `?sorting=${shortValue || "-1"}&page=${
        page || CurrentPage
      }&size=${limits || listLimit}&status=&location=&manager=&owner=`;
    } else {
      shortString = `&sorting=${shortValue}&page=${page || CurrentPage}&size=${
        limits || listLimit
      }&status=&location=&manager=&owner=`;
    }

    // }
    setFullLoder(true);
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${BUYER_PROJECT_LIST_API}/${companyId}${queryString}${shortString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allProjects = response.data.project;
          setRecords(allProjects);
          setTotalProjectPage(response?.data?.totalPage);
          setTotalProjects(response?.data?.totalProject);
          const loader = setTimeout(() => {
            setFullLoder(false);
            HtmlUtilService.truncate();
          }, 300);

          return () => {
            clearTimeout(loader);
          };
        } else {
          setTotalProjectPage(0);
          setTotalProjects(0);
        }
      } else {
        setFullLoder(false);
        setRecords([]);
        setTotalProjectPage(0);
        setTotalProjects(0);
        dispatch({ type: GET_PROJECTS_FAIL });
      }

      // axiosInstance
      //   .get(
      //     `api/buyer/getAllProjects/${companyId}${queryString}${shortString}`
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let allProjects = response.data.project;
      //       setRecords(allProjects);
      //       setTotalProjectPage(response?.data?.totalPage);
      //       setTotalProjects(response?.data?.totalProject);
      //       const loader = setTimeout(() => {
      //         setFullLoder(false);
      //         HtmlUtilService.truncate();
      //       }, 300);

      //       return () => {
      //         clearTimeout(loader);
      //       };
      //     } else {
      //       setTotalProjectPage(0);
      //       setTotalProjects(0);
      //     }
      //   })
      //   .catch((err) => {
      //     setFullLoder(false);
      //     setRecords([]);
      //     setTotalProjectPage(0);
      //     setTotalProjects(0);
      //     dispatch({ type: GET_PROJECTS_FAIL });
      //   });
    } else {
      setFullLoder(false);
      setRecords([]);
    }
  };

  useEffect(() => {
    setResendApi(true);
  }, [editData]);

  useEffect(() => {
    if (successMessage && successMessage !== "") {
      toast.success(successMessage, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      setResendApi(true);
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage && errorMessage !== "") {
      toast.error(errorMessage, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  }, [errorMessage]);

  const addOrEdit = async (firstValues, resetForm) => {
    const res = await dispatch(addSecondForm(firstValues, saveProjectDraft));

    if (res) {
      resetForm();
      setRecordForEdit(null);
      setOpenPopup2(false);
      setResendApi(true);
    }
  };

  const handleChange = (event) => {
    setListLimit(event.target.value);
    setCurrentPage(1);
    setResendApi(true);
  };

  const shotingHandle = (e) => {
    const shortingValue = e.target.value;
    setCurrentPage(1);
    setShortingDropdown(shortingValue === "" ? "-1" : shortingValue);
    setResendApi(true);
  };

  const getSearchString = (e) => {
    const searchText = e.target.value;
    getAllProjectsAction(searchText, "");
  };

  const nextPage = () => {
    setCurrentPage(CurrentPage + 1);
    setResendApi(true);
  };

  const ExportToExcel = async () => {
    setFullLoder(true);
    let projectFilter = JSON.parse(localStorage.getItem("projectFilter"));
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    let string = "?sorting=-1&exportToExcel=1";
    const values = projectFilter;
    if (values) {
      for (let [key, value] of Object.entries(values)) {
        string += `&${key}=${value}`;
      }
    }
    if (
      values &&
      values.dateRange &&
      values.dateRange[0] !== null &&
      values.dateRange[1] !== null
    ) {
      string += `&startDate=${Moment(values.dateRange[0]).format(
        "YYYY-MM-DD 00:00:00"
      )}&endDate=${Moment(values.dateRange[1]).format("YYYY-MM-DD 00:00:00")}`;
    }
    const response = await Api(
      `${BUYER_PROJECT_LIST_API}/${companyId}${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        if (!response.data.data) {
          setFullLoder(false);
          return;
        }
        downloadDocumentFile(response?.data?.data?.resourceUrl, "Projects");
      }
      setFullLoder(false);
    } else {
      setFullLoder(false);
    }

    // axiosInstance
    //   .get(`api/buyer/getAllProjects/${companyId}${string}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       if (!response.data.data) {
    //         setNotify({
    //           isOpen: true,
    //           message: response?.data?.responseDescription,
    //           type: "error",
    //         });
    //         setFullLoder(false);

    //         return;
    //       }
    //       setNotify({
    //         isOpen: true,
    //         message: response.data.responseDescription,
    //         type: "success",
    //       });
    //       const link = document.createElement("a");
    //       link.href = response.data.data.resourceUrl;
    //       link.target = "_blank";
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     } else {
    //       setNotify({
    //         isOpen: true,
    //         message: response.data.responseDescription,
    //         type: "error",
    //       });
    //     }
    //     setFullLoder(false);
    //   })
    //   .catch((err) => {
    //     setFullLoder(false);
    //     setNotify({
    //       isOpen: true,
    //       message: err.response.data.responseDescription,
    //       type: "error",
    //     });
    //   });
  };

  const clearFormDataOnModalClose = () => {
    dispatch({
      type: CLEAR_PROJECT_AFTER_SUBMIT,
      payload: {},
    });
  };
  return (
    <>
      <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
      {/* {!projectDetailShow ? ( */}
      <>
        {/* ---------- Header (Buttons and Filter) ---------- */}
        <div id="project-table-list-buyer-aspect">
          <Grid container className="filter-table-header">
            <Grid item md={4} sm={5} xs={12}>
              <div className="title">Projects</div>
            </Grid>
            <Grid item md={8} sm={7} xs={12} className="buttons-box">
              <Controls.Button
                variant="contained"
                className={
                  "btn btn-white btn-mui" +
                  (showFilterCategories ? " active" : "")
                }
                text="Filter"
                onClick={toggleFilterCategory}
                startIcon={<FilterListSharpIcon style={{ color: "#2170FF" }} />}
              />
              <Controls.Button
                text="Export"
                className="btn btn-success btn-mui"
                startIcon={<SystemUpdateAltIcon />}
                onClick={() => {
                  ExportToExcel();
                }}
              />
              <Controls.Button
                text="Add Project"
                className="btn-mui"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className="filter-box-container position-rel">
            {/* <CloseIcon
              onClick={() => {
                setOpenPopup(false);
              }}
              style={{ cursor: "pointer" }}
            /> */}
            {showFilterCategories && (
              <Paper elevation={1} className="filter-box">
                <ProjectFilter
                  setRecords={setRecords}
                  setFullLoder={setFullLoder}
                  setTotalProjectPage={setTotalProjectPage}
                  setTotalProjects={setTotalProjects}
                  setShowFilterBox={setShowFilterCategories}
                  setFilters={setFilters}
                  setPage={setCurrentPage}
                  setResendApi={setResendApi}
                />
              </Paper>
            )}
          </Grid>
          {/* </Paper> */}
        </div>

        {/* ---------- List ---------- */}
        <Paper elevation={0} square>
          <Box sx={{ padding: "24px", paddingTop: "30px" }}>
            <Grid container className="table-header-container">
              <Grid
                item
                md={6}
                xs={12}
                style={{ textAlign: smScreen ? "center" : "start" }}
              >
                <TextField
                  onChange={getSearchString}
                  placeholder="Search Using Keyword or Tags..."
                  className="select-box select-box-primary search-box"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                style={{
                  textAlign: smScreen ? "center" : "end",
                  marginTop: smScreen ? "10px" : "0px",
                }}
              >
                <Select
                  value={listLimit}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="items-count-dropdown"
                >
                  <MenuItem value={8}>Show 8 entries</MenuItem>
                  <MenuItem value={16}>Show 16 entries</MenuItem>
                  <MenuItem value={32}>Show 32 entries</MenuItem>
                  <MenuItem value={64}>Show 64 entries</MenuItem>
                </Select>
                <Select
                  value={shortingDropdown}
                  onChange={shotingHandle}
                  displayEmpty
                  style={{ marginLeft: "10px" }}
                  className="items-count-dropdown"
                >
                  <MenuItem value="-1" selected>
                    Newest
                  </MenuItem>
                  <MenuItem value="1">Oldest</MenuItem>
                  <MenuItem value="atoz">A to Z</MenuItem>
                  <MenuItem value="ztoa">Z to A</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Box className="table-container mt-5">
              <Grid container justifyContent="left" spacing={2}>
                {records.map((item) => (
                  <Grid key={item} item lg={3} md={6} xs={12}>
                    <ProjectCard
                      project={item}
                      projectDetailShow={projectDetailShow}
                      setProjectDetailShow={setProjectDetailShow}
                      setSelectedProjectDetails={setSelectedProjectDetails}
                      getAllProjectsAction={getAllProjectsAction}
                      aria-label="projet-card-buyer-aspect"
                    />
                  </Grid>
                ))}
                {records.length === 0 ? (
                  <div className="no-record-msg">no records founds</div>
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
            <Grid
              container
              className="project-footer-pagination"
              alignItems="center"
            >
              <Grid item md={6} xs={12}>
                {totalProjectPage > 1 && CurrentPage < totalProjectPage ? (
                  <Controls.Button
                    text="Load more projects"
                    variant="text"
                    endIcon={<ExpandMoreIcon />}
                    onClick={() => nextPage()}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                className="pagination-total-container text-end"
              >
                {totalProjects} Project Found
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </>

      {/* Form 1 */}
      <Modal
        open={openPopup}
        aria-label="create-project-first-step-buyer-aspect"
        aria-describedby="create-project-first-step-buyer-aspect-desc"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> New Project</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopup(false);
                  clearFormDataOnModalClose();
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <ProjectForm
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}
            setSaveProjectDraft={setSaveProjectDraft}
            saveProjectDraft={saveProjectDraft}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            openPopup2={openPopup2}
            setOpenPopup2={setOpenPopup2}
            stepLabels={stepLabels}
            clearFormDataOnModalClose={clearFormDataOnModalClose}
          />
        </Box>
      </Modal>

      {/* Form 2 */}
      <Modal
        open={openPopup2}
        aria-label="create-project-second-step-buyer-aspect"
        aria-describedby="create-project-second-step-buyer-aspect-desc"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Create Project</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopup2(false);
                  clearFormDataOnModalClose();
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <ProjectFormstep2
            openPopup2={openPopup2}
            setOpenPopup2={setOpenPopup2}
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}
            setOpenPopup={setOpenPopup}
            setSaveProjectDraft={setSaveProjectDraft}
            stepLabels={stepLabels}
            setNotify={setNotify}
          />
        </Box>
      </Modal>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    editData: state.projects.editData,
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
    projectAddLoading: state.projects.projectAddLoading,
    errorMessage: state.projects.errorMessage,
    successMessage: state.projects.successMessage,
  };
};
export default connect(mapStateToProps)(ProjectList);
