import {
  CONFIRMED_GET_PROJECTS,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_FAIL,
  ADD_FIRST_FORM,
  PROJECTS_ADD_REQUEST,
  ADD_SECOND_FORM,
  EDIT_PROJECT_FORM,
  CLEAR_PROJECT_AFTER_SUBMIT,
  SAVE_SECOND_FORM_DATA_TEMP,
  GET_PROJECTS_SUCCESS,
} from "../constant/projectConstants";
import axiosInstance from "../../services/AxiosInstance";
import { Api } from "../../api/Api";
import {
  GET_BUYER_API,
  POST_ADD_PROJECT_API,
  POST_EDIT_PROJECT_DRAFT_API,
  POST_SAVE_PROJECT_DRAFT_API,
} from "../../constants/ApiUrl";
import { AUCTION_DRAFT_SMALL } from "../../constants/AppConstants";
export const getAllProjectsAction = () => async (dispatch, getState) => {
  const {
    auth: { auth },
  } = getState();
  dispatch({
    type: GET_PROJECTS_REQUEST,
  });

  return await new Promise(async (resolve, reject) => {
    const response = await Api(
      `${GET_BUYER_API}/${auth.auth?.user?.company_name?.company_name}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allProjects = response.data.project;
        dispatch(confirmedGetProjectAction(allProjects));

        resolve(response);
      }
      reject(response);
    } else {
      reject(response);
      dispatch({ type: GET_PROJECTS_FAIL });
    }

    // axiosInstance
    //   .get(`api/buyer/${auth.auth?.user?.company_name?.company_name}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allProjects = response.data.project;
    //       dispatch(confirmedGetProjectAction(allProjects));

    //       resolve(response);
    //     }
    //     reject(response);
    //   })
    //   .catch((err) => {
    //     reject(err);
    //     dispatch({ type: GET_PROJECTS_FAIL });
    //   });
  });
};

export const addFirstForm = (values) => (dispatch, getState) => {
  const {
    projects: { projectsFirstForm },
  } = getState();
  let firstFormVales = values;
  if (projectsFirstForm && projectsFirstForm.form2project) {
    firstFormVales = Object.assign(projectsFirstForm.form2project, values);
  }
  dispatch({
    type: PROJECTS_ADD_REQUEST,
  });
  dispatch({
    type: ADD_FIRST_FORM,
    payload: firstFormVales,
  });
};

export const saveSecondFormValue = (values) => (dispatch, getState) => {
  const {
    projects: { projectsFirstForm },
  } = getState();
  let projectFormsValues;
  if (projectsFirstForm && projectsFirstForm.form2project) {
    projectsFirstForm.form2project = values;
    projectFormsValues = projectsFirstForm;
  } else {
    projectFormsValues = Object.assign(projectsFirstForm, values);
  }

  dispatch({
    type: SAVE_SECOND_FORM_DATA_TEMP,
    payload: projectFormsValues,
  });
};

export const addSecondForm = (values, saveProjectDraft) => {
  return async (dispatch, getState) => {
    const {
      projects: { projectsFirstForm },
      auth: { auth },
    } = getState();
    let formData = new FormData();
    let projectFormsValues = values;
    if (projectsFirstForm) {
      projectFormsValues = Object.assign(projectsFirstForm, values);
    }

    projectFormsValues.companyId = auth.user.company._id;
    projectFormsValues.createdBy = auth.user._id;
    projectFormsValues.unit = "unit";
    delete projectFormsValues.selectedImage;
    formData.append("project_name", projectFormsValues.project_name);
    formData.append("description", projectFormsValues.description);
    formData.append("location", projectFormsValues.location);
    formData.append("startDate", projectFormsValues.startDate);
    formData.append("endDate", projectFormsValues.endDate);
    if (values.business_unit && values.business_unit.length > 0)
      formData.append("business_unit", values.business_unit);
    if (values.department && values.department.length > 0)
      formData.append("department", values.department);
    if (
      values.project_reference_number &&
      values.project_reference_number.length > 0
    )
      formData.append(
        "project_reference_number",
        values.project_reference_number
      );
    if (values.project_manager && values.project_manager.length > 0)
      formData.append("project_manager", values.project_manager);
    formData.append("currency", values.currency);
    formData.append("companyId", auth.user.company._id);
    formData.append("createdBy", auth.user._id);
    formData.append("unit", "unit");
    if (saveProjectDraft) {
      // projectFormsValues.project_status = "draft";
      formData.append("project_status", AUCTION_DRAFT_SMALL);
    } else {
      formData.append("project_status", values.project_status);
    }
    if (projectFormsValues.document && projectFormsValues.document.type) {
      formData.append("document", projectFormsValues.document);
    } else {
      formData.append("image", projectFormsValues.image);
    }

    let finalRes;

    const response = await Api(POST_ADD_PROJECT_API, formData, "postMultipart");
    if (response.status === 200) {
      finalRes = response.data;
      if (response.data.responseCode === "00") {
        dispatch({
          type: ADD_SECOND_FORM,
          payload: projectFormsValues,
        });

        dispatch({
          type: CLEAR_PROJECT_AFTER_SUBMIT,
          payload: {},
        });
      }
    } else {
      finalRes = response.data;

      dispatch({
        type: CLEAR_PROJECT_AFTER_SUBMIT,
        payload: {},
      });
    }

    // await axiosInstance
    //   .post(`api/buyer/addProject/`, projectFormsValues)
    //   .then((response) => {
    //     finalRes = response.data;
    //     if (response.data.responseCode === "00") {
    //       dispatch({
    //         type: ADD_SECOND_FORM,
    //         payload: projectFormsValues,
    //       });
    //       dispatch({
    //         type: GET_PROJECTS_SUCCESS,
    //         payload: response.data.responseDescription,
    //       });
    //       dispatch({
    //         type: CLEAR_PROJECT_AFTER_SUBMIT,
    //         payload: {},
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     finalRes = err.response.data;

    //     let message = err.response.data.responseDescription
    //       ? err.response.data.responseDescription
    //       : err.message;

    //     dispatch({
    //       type: GET_PROJECTS_FAIL,
    //       payload: message,
    //     });

    //     dispatch({
    //       type: CLEAR_PROJECT_AFTER_SUBMIT,
    //       payload: {},
    //     });
    //   });
    return finalRes;
  };
};

export const onEditSubmit = (values) => async (dispatch, getState) => {
  const {
    projects: { projectsFirstForm },
    auth: { auth },
  } = getState();

  const projectFormsValues = Object.assign(projectsFirstForm, values);
  projectFormsValues.userId = auth.user._id;
  projectFormsValues.unit = "unit";
  // projectFormsValues.image = image;

  const response = await Api(
    `${POST_EDIT_PROJECT_DRAFT_API}/${projectFormsValues._id}`,
    projectFormsValues,
    "patch"
  );
  if (response.status === 200) {
    if (response.data.responseCode === "00") {
      //  let addedProjects = response.data;
      dispatch({
        type: EDIT_PROJECT_FORM,
        payload: response.data.project,
      });
    }
  }

  // axiosInstance
  //   .patch(
  //     `/api/buyer/EditsaveProjectDraft/${projectFormsValues._id}`,
  //     projectFormsValues
  //   )
  //   .then((response) => {
  //     if (response.data.responseCode === "00") {
  //       //  let addedProjects = response.data;
  //       dispatch({
  //         type: EDIT_PROJECT_FORM,
  //         payload: response.data.project,
  //       });
  //     }
  //   })
  //   .catch((err) => {});

  //  dispatch({
  //   type: ADD_SECOND_FORM,
  //   payload: projectFormsValues,
  // });
};

export const saveAsDraft = (values) => async (dispatch, getState) => {
  const {
    projects: { projectsFirstForm },
    auth: { auth },
  } = getState();

  const projectFormsValues = Object.assign(projectsFirstForm, values);
  projectFormsValues.userId = auth.user._id;
  projectFormsValues.unit = "unit";
  projectFormsValues.project_status = "draft";
  // projectFormsValues.image = image;

  const response = await Api(
    `${POST_SAVE_PROJECT_DRAFT_API}${projectFormsValues._id}`,
    projectFormsValues,
    "patch"
  );
  if (response.status === 200) {
    if (response.data.responseCode === "00") {
      //  let addedProjects = response.data;
      dispatch({
        type: CLEAR_PROJECT_AFTER_SUBMIT,
        payload: {},
      });
    }
  }

  // axiosInstance
  //   .patch(
  //     `/api/buyer/saveProjectAsDraft${projectFormsValues._id}`,
  //     projectFormsValues
  //   )
  //   .then((response) => {
  //     if (response.data.responseCode === "00") {
  //       //  let addedProjects = response.data;
  //       dispatch({
  //         type: CLEAR_PROJECT_AFTER_SUBMIT,
  //         payload: {},
  //       });
  //     }
  //   })
  //   .catch((err) => {});

  //  dispatch({
  //   type: ADD_SECOND_FORM,
  //   payload: projectFormsValues,
  // });
};

export function confirmedGetProjectAction(allProjects) {
  return {
    type: CONFIRMED_GET_PROJECTS,
    payload: allProjects,
  };
}
