import { Paper } from "@material-ui/core";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  AUCTION_COMPLETED,
  DANGER_BACKGROUND,
  PURPLE_BACKGROUND,
  QUESTION_ACTIVE_TYPE,
  QUESTION_CLOSED_TYPE,
  QUESTION_OPEN_TYPE,
  RFX_MESSAGE_CLOSE,
  RFX_MESSAGE_OPEN,
  SUCCESS_BACKGROUND,
} from "../../../../constants/AppConstants";
import moment from "moment";
import { Api } from "../../../../api/Api";
import {
  POST_RFX_MESSAGE_CHANGE_API,
  RFX_API,
  SELLER_RFX_API,
} from "../../../../constants/ApiUrl";
import { useSelector } from "react-redux";
import RfxMessageDrawer from "./RfxMessageDrawer";
import Controls from "../../../../components/controls/Controls";
import CreateMessageModal from "../CreateMessageModal";
import { store } from "../../../../store/store";
import { Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isGlobalRfxDisabled } from "../../../../utility/utils";

const initalColumns = [
  {
    label: "Message Id",
  },
  {
    label: "Supplier",
  },
  {
    label: "Subject",
  },
  {
    label: "Created on",
  },
  {
    label: "Status",
  },
  {
    label: "Owner",
  },
];

const RfxMessagesTab = ({ fromSeller }) => {
  const rfxDetails = useSelector((state) => state.rfx.liveRfx);
  const rfxSupplierDetails = useSelector((state) => state.supplierRfx.rfx);

  const { auth } = store.getState();
  const companyId = auth?.auth?.user?.company?._id;

  const [columns, setColumns] = useState([...initalColumns]);
  const [messageList, setmessageList] = useState([]);
  const [showMessageSidebar, setShowMessageSidebar] = useState(false);
  const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);
  const [messageId, setMessageId] = useState("");
  const [messageData, setMessageData] = useState(null);

  useEffect(() => {
    if (!fromSeller) {
      const filterData = columns.filter((item) => item.label !== "Owner");
      if (filterData && filterData.length > 0) setColumns([...filterData]);
    }
  }, []);

  const getStatusLabel = (type) => {
    switch (type) {
      case RFX_MESSAGE_OPEN:
        return SUCCESS_BACKGROUND;
      case RFX_MESSAGE_CLOSE:
        return DANGER_BACKGROUND;
      default:
        return PURPLE_BACKGROUND;
    }
  };

  useEffect(() => {
    getMessageList();
  }, []);

  const getMessageList = async () => {
    let path = fromSeller
      ? `${SELLER_RFX_API}/${rfxSupplierDetails._id}/${companyId}/messages/get`
      : `${RFX_API}/${rfxDetails._id}/messages/get`;
    const response = await Api(path, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setmessageList([...response?.data?.messages]);
      }
    }
  };

  const getStatusChange = async (status, item) => {
    const payload = {
      rfxId: rfxDetails?._id,
      messageId: item?._id,
      status: status, //Open , Closed
      companyId,
    };
    if (item?._id) {
      const response = await Api(POST_RFX_MESSAGE_CHANGE_API, payload, "post");
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getMessageList();
        }
      }
    }
  };

  return (
    <Paper elevation={0} square className="messages-tab">
      <div className="d-flex justify-content-end">
        {fromSeller &&
          !isGlobalRfxDisabled(
            rfxSupplierDetails?.supplierRfx?.isSubmitted,
            rfxSupplierDetails.status,
            rfxSupplierDetails?.supplierRfx?.rfxStatus
          ) && (
            <Controls.Button
              type="Button"
              disableElevation
              text="Create Message"
              className="btn-mui "
              onClick={() => setShowCreateMessageModal(true)}
            />
          )}
      </div>
      <TableContainer
        className="table-container editable-table"
        component={Paper}
      >
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            {/* {fromSeller ? ( */}
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "700" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {messageList.map((item, index) => {
              return (
                <TableRow
                  key={item._id}
                  className="cursor-p"
                  onDoubleClick={() => {
                    setShowMessageSidebar(true);
                    setMessageData(item);
                    setMessageId(item?._id);
                  }}
                >
                  <TableCell>{item?.messageId}</TableCell>
                  <TableCell>{item?.supplierCompanyId?.company_name}</TableCell>
                  <TableCell>{item?.subject}</TableCell>
                  <TableCell>
                    <p>{moment(item?.createdDate).format("DD/MM/YYYY")}</p>
                    <p>{moment(item?.createdDate).format("LT")}</p>
                  </TableCell>
                  {item?.status === RFX_MESSAGE_OPEN && !fromSeller ? (
                    <TableCell className="rfx-status">
                      <PopupState variant="popover" popupId="demoPopover">
                        {(popupState) => (
                          <div>
                            <Button
                              className={
                                "badge badge-" +
                                getStatusLabel(item?.status) +
                                " status"
                              }
                              variant="contained"
                              {...bindTrigger(popupState)}
                              endIcon={<ArrowDropDownIcon />}
                            >
                              {item?.status}
                            </Button>

                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <div className="px-3 py-2 ">
                                <div>
                                  <button
                                    className="badge badge-success select-colored-option"
                                    onClick={() => {
                                      popupState.close();
                                      getStatusChange(RFX_MESSAGE_OPEN, item);
                                    }}
                                  >
                                    {RFX_MESSAGE_OPEN}
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="badge badge-danger select-colored-option"
                                    onClick={() => {
                                      popupState.close();
                                      getStatusChange(RFX_MESSAGE_CLOSE, item);
                                    }}
                                  >
                                    {RFX_MESSAGE_CLOSE}
                                  </button>
                                </div>
                              </div>
                            </Popover>
                          </div>
                        )}
                      </PopupState>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Button
                        className={
                          "badge badge-" +
                          getStatusLabel(item?.status) +
                          " status"
                        }
                        variant="contained"
                      >
                        {item?.status}
                      </Button>
                    </TableCell>
                  )}
                  {fromSeller && (
                    <TableCell>
                      {item?.createdBy?.first_name +
                        " " +
                        item?.createdBy?.last_name}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {messageList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                  No records found <br />
                  {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some supplier
                    </i> */}
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showMessageSidebar && (
        <RfxMessageDrawer
          showMessageSidebar={showMessageSidebar}
          setShowMessageSidebar={setShowMessageSidebar}
          getMessageList={getMessageList}
          fromSeller={fromSeller}
          messageId={messageId}
          messageData={messageData}
          setMessageData={setMessageData}
        />
      )}
      {showCreateMessageModal && (
        <CreateMessageModal
          open={showCreateMessageModal}
          handleClose={() => setShowCreateMessageModal(!showCreateMessageModal)}
          getMessageList={getMessageList}
          setShowMessageSidebar={setShowMessageSidebar}
          setMessageId={setMessageId}
        />
      )}
    </Paper>
  );
};

export default RfxMessagesTab;
