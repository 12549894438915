import { Box, Grid, InputAdornment, Paper, TextField } from "@material-ui/core";
import {
  Select,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Modal,
} from "@mui/material";
import LogoIcon from "../../../../assets/Logo_2.png";
import { Search } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Notification from "../../../../components/Notification";
import "../../styles/details-suplliers.css";
import TableOptionList from "../../../../components/TableOptionList";
import TablePaginationBox from "../../../../components/TablePaginationBox";
import InvitedSellerPopup from "../InvitedSellerPopup";
import EditIcon from "@mui/icons-material/Edit";
import Controls from "../../../../components/controls/Controls";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentLiveAuction } from "../../../../store/selectors/auctionSelector";
import GetAuctionSuppliersBL from "../../BL/GetAuctionSuppliersBL";
import usePrevious from "../../../../components/usePrevious";
import EditBidBL from "../../BL/EditBidBL";
import { store } from "../../../../store/store";
import SendReminderSupplierBL from "../../BL/SendReminderSupplier";
import AddIcon from "@material-ui/icons/Add";
import AddSupplierPopup from "../AddExistingSupplier";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import ConfirmUpdatedBiddingSettings from "../confirmUpdatedBiddingSettings";
import { refreshLiveAuctionBuyer } from "../../../../store/actions/auctionAction";
import { setHTTPError } from "../../../../store/actions/errorHandlerAction";
import { useHistory } from "react-router-dom";
import {
  thousandRemoverHandler,
  thousandSepratorHandler,
} from "../../../../utility/utils";
import NumericSeparatorInput from "../../../../components/NumericSeparatorInput";
import { Api } from "../../../../api/Api";
import {
  COMAPNY_API,
  GET_COMPANY_SUPPLIER_LIST_API,
} from "../../../../constants/ApiUrl";
import {
  AUCTION_ACCEPTED,
  AUCTION_DECLINED,
  AUCTION_DRAFT,
  AUCTION_INVITED,
  AUCTION_PUBLISHED,
  AUCTION_RUNNING,
  DANGER_BACKGROUND,
  PURPLE_BACKGROUND,
  SUCCESS_BACKGROUND,
  SUPPLIER_AUCTION_PENDING,
  TOAST_AUTO_CLOSE,
  WARNING_BACKGROUND,
} from "../../../../constants/AppConstants";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const columns = [
  {
    label: "Rank",
  },
  {
    label: "Supplier Name",
  },
  {
    label: "Email Main Contact",
  },
  {
    label: "Status",
  },
  {
    label: "Number of Bids",
  },
  {
    label: "Starting Price",
  },
  {
    label: "Bonus-Malus %",
  },
  {
    label: "Bonus-Malus Amount",
  },
  {
    label: "Final Bid",
  },
  {
    label: "Weighted bid",
  },
  {
    label: "Invited Sellers",
  },
  {
    label: "",
  },
];

const initialValues = {
  starting_price: "",
  bonus_malus_perc: "",
  bonus_malus_amount: "",
};

const SupplierTab = (props) => {
  const { currentLiveAuction, isEditor, isOwner } = props;
  const [suppliers, setSuppliers] = React.useState([]);
  const [limit, setLimit] = React.useState(0);
  const history = useHistory();
  const UnAuthorizedRoute = useSelector(
    (state) => state.errorHandler.UnAuthorizedRoute
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSellerPopup, setOpenSellerPopup] = React.useState(false);
  const [openBidSettingAlarm, setOpenBidSettingAlarm] = React.useState(false);
  const [changeBidSettingConfirmed, setChangeBidSettingConfirmed] =
    React.useState(false);
  const [bidSettings, setBidSettings] = React.useState({});
  const [selectedSupplier, setSelectedSupplier] = React.useState([]);
  const tableRowsRef = useRef([]);
  const { smScreen } = useWindowDimensions();
  const [page, setPage] = React.useState(1);
  const prevPageValue = usePrevious(page);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [resendApi, setResendApi] = React.useState(false);
  const [numOfPages, setNumOfPages] = React.useState(0);
  const [invitedSupplierLabel, setInvitedSupplierLabel] = React.useState("All");
  const [openPopup, setOpenPopup] = React.useState(false);
  const [deleteSupplierId, setDeleteSupplierId] = React.useState({});
  const [startingPrice, setStartingPrice] = useState("");
  const [bonusMalusPer, setBonusMalusPer] = useState("");
  const [bonusMalusAmount, setBonusMalusAmount] = useState("");

  const dispatch = useDispatch();
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (resendApi === true) {
      GetAuctionSuppliersBL({
        currentAuction: currentLiveAuction,
        page: page,
        rowsPerPage: rowsPerPage,
      }).then((data) => {
        setSuppliers(data.list);
        setLimit(data.limit);
      });
      setResendApi(false);
    }
  }, [resendApi]);

  const resetPaginationSetting = () => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  };

  useEffect(() => {
    resetPaginationSetting();
  }, [limit, suppliers]);

  const editableValues = [
    {
      name: "starting_price",
      match: "starting_price",
    },
    {
      name: "bonus_malus_perc",
      match: "bonusMalus",
    },
    {
      name: "bonus_malus_amount",
      match: "bonusMalusAmount",
    },
  ];

  useEffect(() => {
    if (prevPageValue !== undefined) {
      setResendApi(true);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (currentLiveAuction && currentLiveAuction._id) {
      tableRowsRef.current = tableRowsRef.current.slice(0, suppliers.length);
      setResendApi(true);
    }
  }, [currentLiveAuction]);

  useEffect(() => {
    if (changeBidSettingConfirmed === true) {
      setChangeBidSettingConfirmed(false);
      sendEditableVaule(bidSettings);
    }
  }, [changeBidSettingConfirmed]);

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ACCEPTED:
        return SUCCESS_BACKGROUND;
      case SUPPLIER_AUCTION_PENDING:
        return WARNING_BACKGROUND;
      case AUCTION_DECLINED:
        return DANGER_BACKGROUND;
      default:
        return PURPLE_BACKGROUND;
    }
  };

  const getSellerLabel = (supplier) => {
    let tmpLable = "";
    if (supplier && supplier.invitedSellerList) {
      if (Array.isArray(supplier.invitedSellerList)) {
        tmpLable = supplier.invitedSellerList.length;
      } else {
        tmpLable = "--";
      }
    }
    return (
      <div
        className="sellers-link-container"
        onClick={() => {
          setOpenSellerPopup(true);
          setSelectedSupplier(supplier);
        }}
      >
        <div className="label">{tmpLable || invitedSupplierLabel}</div>
        <PlaylistAddIcon className="icon" />
      </div>
    );
  };

  const getValueFromEditableCell = (field) => {
    let values = {};
    editableValues.map((item) => {
      let fieldToUpdate = field.querySelector(`[name='${item.name}']`);
      if (fieldToUpdate !== null) {
        values[fieldToUpdate.name] = fieldToUpdate.value;
      }
    });
    return values;
  };

  const setValueForEditableCell = (field, supplier) => {
    editableValues.map((item) => {
      let fieldToUpdate = field.querySelector(`[name='${item.name}']`);
      if (fieldToUpdate !== null) {
        fieldToUpdate.value = supplier[`${item.match}`]
          ? supplier[`${item.match}`]
          : "";
      }
    });
  };

  const toggleEditableRowMode = (ref, supplier, editMode = false) => {
    ref.classList.toggle("__select");
    const optionBoxes = ref.querySelectorAll(".option-cell");
    for (let index = 0; index < optionBoxes.length; index++) {
      const box = optionBoxes[index];
      box.classList.toggle("__active");
    }
    const fieldBoxes = ref.querySelectorAll(".editable-field");
    let values = {};
    for (let index = 0; index < fieldBoxes.length; index++) {
      const field = fieldBoxes[index];
      field.classList.toggle("__active");
      if (editMode) {
        values = Object.assign(values, getValueFromEditableCell(field));
      } else {
        setStartingPrice(supplier?.starting_price);
        setBonusMalusPer(supplier?.bonusMalus);
        setBonusMalusAmount(supplier?.bonusMalusAmount);
        setValueForEditableCell(field, supplier);
      }
    }

    if (editMode) {
      values = Object.assign(values, {
        suplierBidId: supplier?._id,
        supplierCompanyId: supplier?.supplierCompanyId,
      });
      setOpenBidSettingAlarm(true);
      setBidSettings(values);
    }
  };

  const SendReminder = (row) => {
    if (currentLiveAuction?.status === AUCTION_PUBLISHED) {
      if (row?.auctionStatus?.invitation === AUCTION_INVITED) {
        const { auth } = store.getState();
        const companyName = auth?.auth?.user?.company?.company_name;
        SendReminderSupplierBL({
          company_buyer_name: companyName,
          supplier_company: row?.company_name?._id,
          auctionId: currentLiveAuction?._id,
          email: row?.email,
          supplierId: row?._id,
        }).then((data) => {
          // if (data.success) {
          //   setNotify({
          //     isOpen: true,
          //     message: data?.message,
          //     type: "success",
          //   });
          // } else {
          //   setNotify({
          //     isOpen: true,
          //     message: data?.message,
          //     type: "error",
          //   });
          // }
        });
      } else {
        // setNotify({
        //   isOpen: true,
        //   message: "This supplier has already accept or declined invitation",
        //   type: "error",
        // });
      }
    } else {
      toast.error(ValidationMessage.auctionRunningEnded, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const sendEditableVaule = (values) => {
    const { auth } = store.getState();
    const userId = auth?.auth?.user?._id;
    EditBidBL({
      startingPrice: thousandRemoverHandler(values.starting_price).toString(),
      bonusMalus: values.bonus_malus_perc,
      bonusMalusAmount: values.bonus_malus_amount,
      supplierCompanyId: values.supplierCompanyId,
      auctionId: currentLiveAuction._id,
      createdBy: userId,
    }).then((data) => {
      if (data.success) {
        setResendApi(true);
        dispatch(refreshLiveAuctionBuyer(true));
      } else {
        if (data.code === 403) {
          dispatch(
            setHTTPError({
              code: 403,
              message: data.message,
            })
          );
          history.push(UnAuthorizedRoute);
        } else {
          dispatch(
            setHTTPError({
              code: data.code,
              message: data.message,
            })
          );
        }
      }
    });
  };

  const getSearchString = async (e) => {
    const searchText = e.target.value;
    let queryString = "";
    if (searchText) {
      queryString = `&search=${searchText}`;
    }
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const auctionId = currentLiveAuction?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COMPANY_SUPPLIER_LIST_API}/${auctionId}?per_page=${rowsPerPage}&page=${page}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let suuplierItemList = response.data.suuplierItemList;
          setSuppliers(suuplierItemList);
          setLimit(response.data.totalRecord);
        } else {
          setSuppliers([]);
          setLimit(0);
        }
      } else {
        setSuppliers([]);
        setLimit(0);
      }

      // axiosInstance
      //   .get(
      //     `api/buyer/company/auctionCompanySupplierList/${auctionId}?per_page=${rowsPerPage}&page=${page}${queryString}`
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let suuplierItemList = response.data.suuplierItemList;
      //       setSuppliers(suuplierItemList);
      //       setLimit(response.data.totalRecord);
      //     } else {
      //       setSuppliers([]);
      //       setLimit(0);
      //     }
      //   })
      //   .catch((err) => {
      //     setSuppliers([]);
      //     setLimit(0);
      //   });
    }
  };

  const addNewSupplier = () => {
    if (
      currentLiveAuction?.status === AUCTION_PUBLISHED ||
      currentLiveAuction?.status === AUCTION_DRAFT
    ) {
      setOpenPopup(true);
    } else {
      toast.error(ValidationMessage.auctionAddSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const RemoveSupplier = (supplier) => {
    if (currentLiveAuction?.status === AUCTION_DRAFT) {
      if (supplier?._id) {
        setDeleteSupplierId(supplier);
        setConfirmDialog({
          isOpen: true,
          title: "SUPPLIER",
          subTitle: "Are you sure to delete supplier?",
        });
      }
    } else {
      toast.error(ValidationMessage.auctionRemoveSupplier, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const confirmDeleteSupplier = async () => {
    if (currentLiveAuction && currentLiveAuction?._id) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const payload = {
        supplierEmail: deleteSupplierId.email,
      };
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      const response = await Api(
        `${COMAPNY_API}/${companyId}/removeAuctionsSupplier/${currentLiveAuction?._id}`,
        payload,
        "post"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setResendApi(true);
        }
      } else {
        let message = response?.data?.responseDescription;
        if (response.status === 403) {
          dispatch(
            setHTTPError({
              code: 403,
              message: message,
            })
          );
          history.push(UnAuthorizedRoute);
        } else {
          dispatch(
            setHTTPError({
              code: response.status,
              message: message,
            })
          );
        }
      }

      // await axiosInstance
      //   .post(
      //     `api/buyer/company/${companyId}/removeAuctionsSupplier/${currentLiveAuction?._id}`,
      //     payload
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       setResendApi(true);
      //       setNotify({
      //         isOpen: true,
      //         message: "Supplier removed successfully",
      //         type: "success",
      //       });
      //       setConfirmDialog({
      //         isOpen: false,
      //         title: "",
      //         subTitle: "",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     let message = err?.response?.data?.responseDescription;
      //     if (err.response.status === 403) {
      //       dispatch(
      //         setHTTPError({
      //           code: 403,
      //           message: message,
      //         })
      //       );
      //       history.push(UnAuthorizedRoute);
      //     } else {
      //       dispatch(
      //         setHTTPError({
      //           code: err.response.status,
      //           message: message,
      //         })
      //       );
      //     }
      //     setNotify({
      //       isOpen: true,
      //       message: message,
      //       type: "error",
      //     });
      //   });
    }
  };

  const editableFileHandle = (e) => {
    const { name, value } = e.target;
    if (name === "starting_price") {
      setStartingPrice(value);
    } else if (name === "bonus_malus_perc") {
      setBonusMalusPer(value);
    } else if (name === "bonus_malus_amount") {
      setBonusMalusAmount(value);
    }
  };

  return (
    <Paper elevation={0} square>
      <Grid container>
        <Grid
          item
          md={6}
          xs={12}
          style={{ textAlign: smScreen ? "center" : "start" }}
        >
          <TextField
            onChange={getSearchString}
            placeholder="Search Using Keyword"
            className="select-box select-box-primary search-box"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          style={{
            textAlign: smScreen ? "center" : "end",
            marginTop: smScreen ? "10px" : "0px",
          }}
        >
          <Select
            value={rowsPerPage}
            onChange={(event) => setRowsPerPage(event.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            className="items-count-dropdown"
          >
            <MenuItem value="">
              <em>Show 10 entries</em>
            </MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
          {isEditor() || isOwner() ? (
            <Controls.Button
              text="Add Supplier"
              className="btn btn-primary btn-mui"
              variant="contained"
              startIcon={<AddIcon />}
              style={{
                textTransform: "capitalize",
                border: "1px #2170ff solid",
                padding: "0.6em 1.4em",
                borderRadius: "10px",
                marginLeft: "12px",
                height: "48px",
              }}
              onClick={() => addNewSupplier()}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <Paper elevation={0} square>
        <TableContainer
          className="table-container editable-table"
          component={Paper}
        >
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers.map((supplier, index) => {
                return (
                  <TableRow
                    key={supplier._id}
                    ref={(el) => (tableRowsRef.current[index] = el)}
                    className="editable-row"
                  >
                    {/* Number.MAX_SAFE_INTEGER => means infinite  */}
                    <TableCell>
                      {supplier.rank &&
                      supplier.rank < Number.MAX_SAFE_INTEGER - 1
                        ? supplier.rank
                        : "--"}
                    </TableCell>
                    <TableCell>{supplier?.company_name}</TableCell>
                    <TableCell className="clr-2170ff">
                      {supplier?.email}
                    </TableCell>
                    <TableCell>
                      <Button
                        className={
                          "badge badge-" +
                          getStatusLabel(supplier?.auctionStatus?.invitation) +
                          " status"
                        }
                        variant="contained"
                      >
                        {supplier?.auctionStatus?.invitation}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {supplier.noOfBid ? supplier.noOfBid : "--"}
                    </TableCell>
                    <TableCell className="minW200">
                      <Box className="editable-field __active mw200 simple-word-break">
                        {thousandSepratorHandler(supplier?.starting_price)}
                      </Box>

                      <Box className="editable-field">
                        <TextField
                          name="starting_price"
                          variant="outlined"
                          defaultValue={supplier?.starting_price}
                          fullWidth
                          onChange={editableFileHandle}
                          value={startingPrice}
                          InputProps={{
                            inputComponent: NumericSeparatorInput,
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className="minW200">
                      <Box className="editable-field __active mw-200 simple-word-break">
                        {supplier?.bonusMalus !== ""
                          ? parseFloat(supplier?.bonusMalus).toFixed(2) + "%"
                          : "--"}
                      </Box>

                      <Box className="editable-field">
                        <TextField
                          name="bonus_malus_perc"
                          variant="outlined"
                          defaultValue={supplier?.bonusMalus}
                          fullWidth
                          onChange={editableFileHandle}
                          value={bonusMalusPer}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className="minW200">
                      <Box className="editable-field __active mw200 simple-word-break">
                        {supplier?.bonusMalusAmount !== ""
                          ? parseFloat(supplier?.bonusMalusAmount).toFixed(2)
                          : "--"}
                      </Box>

                      <Box className="editable-field">
                        <TextField
                          name="bonus_malus_amount"
                          variant="outlined"
                          defaultValue={supplier?.bonusMalusAmount}
                          fullWidth
                          onChange={editableFileHandle}
                          value={bonusMalusAmount}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className="minW200 simple-word-break">
                      {thousandSepratorHandler(supplier?.lastBid) || "--"}
                    </TableCell>
                    <TableCell className="minW200 simple-word-break">
                      {thousandSepratorHandler(supplier?.weightedBid) || "--"}
                    </TableCell>
                    <TableCell>{getSellerLabel(supplier)}</TableCell>
                    <TableCell>
                      <Box className="option-cell __active">
                        {isEditor() || isOwner() ? (
                          <EditIcon
                            className="icon __edit"
                            onClick={() => {
                              toggleEditableRowMode(
                                tableRowsRef.current[index],
                                supplier
                              );
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {/* <Box> */}
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <div>
                              <Button
                                className="action-btn"
                                variant="contained"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </Button>
                              <TableOptionList {...bindMenu(popupState)}>
                                {isEditor() || isOwner() ? (
                                  <div>
                                    <MenuItem
                                      className="bold-line-600"
                                      onClick={() => {
                                        toggleEditableRowMode(
                                          tableRowsRef.current[index],
                                          supplier
                                        );
                                      }}
                                    >
                                      Edit Supplier
                                    </MenuItem>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {currentLiveAuction?.status ===
                                  AUCTION_PUBLISHED && (
                                  <div>
                                    <MenuItem
                                      className="bold-line-600"
                                      onClick={() => SendReminder(supplier)}
                                    >
                                      <div>Send Reminder</div>
                                    </MenuItem>
                                  </div>
                                )}
                                {isEditor() || isOwner() ? (
                                  <>
                                    <Divider />
                                    <MenuItem
                                      className="bold-line-600 clr-ff0000"
                                      onClick={() => RemoveSupplier(supplier)}
                                    >
                                      <div>Remove</div>
                                    </MenuItem>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </TableOptionList>
                            </div>
                          )}
                        </PopupState>
                        {/* </Box> */}
                      </Box>
                      <Box className="option-cell">
                        <CheckIcon
                          className="icon __check"
                          onClick={() => {
                            toggleEditableRowMode(
                              tableRowsRef.current[index],
                              supplier,
                              true
                            );
                          }}
                        />
                        <CloseIcon
                          className="icon __close"
                          onClick={() => {
                            toggleEditableRowMode(
                              tableRowsRef.current[index],
                              supplier
                            );
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {suppliers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some supplier
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={suppliers.length + " Suppliers Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>
      <Modal
        open={openSellerPopup}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container invite-seller-box">
          <div className="title"> Edit Sellers Invited </div>
          <div className="label">Company Name</div>
          <div className="title">
            <div className="icon-cotainer">
              <img src={LogoIcon} className="icon" />
            </div>
            <div className="text">{selectedSupplier?.supplierCompanyName}</div>
          </div>
          <InvitedSellerPopup
            openPopup={openSellerPopup}
            setOpenPopup={setOpenSellerPopup}
            supplier={selectedSupplier}
            suppliers={suppliers}
            setSuppliers={setSuppliers}
            setNotify={setNotify}
            setInvitedSupplierLabel={setInvitedSupplierLabel}
            setResendApi={setResendApi}
            currentLiveAuction={currentLiveAuction}
          />
        </Box>
      </Modal>

      <AddSupplierPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setNotify={setNotify}
        setResendApi={setResendApi}
        currentLiveAuction={currentLiveAuction}
      />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmDeleteProject={confirmDeleteSupplier}
      />

      <ConfirmUpdatedBiddingSettings
        openConfrmationDailog={openBidSettingAlarm}
        setOpenConfrmationDailog={setOpenBidSettingAlarm}
        isConfirmed={setChangeBidSettingConfirmed}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(SupplierTab));
