/*eslint-disable */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmDialog from "../../../components/ConfirmDialog";
import {
  DANGER_OUTLINE_BACKGROUND,
  STATUS_INACTIVE,
  SUCCESS_BACKGROUND,
  SUPER_ADMIN_STATUS_ACTIVE,
  SUPER_ADMIN_STATUS_INACTIVE,
  SUPER_ADMIN_STATUS_PENDING,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../constants/AppConstants";

import { Api } from "../../../api/Api";
import Notification from "../../../components/Notification";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import usePrevious from "../../../components/usePrevious";
import GetAllSuperAdminBL from "./SuperAdminBL/GetAllSuperAdmins";
import { GET_ADMIN_USER_API } from "../../../constants/ApiUrl";
import ToggleSuperAdminStatusBL from "./SuperAdminBL/ToggleSuperAdminStatusBL";
const columns = [
  {
    label: "Email",
  },
  {
    label: "Name",
    minWidth: 170,
  },

  {
    label: "Role",
  },
  {
    label: "Status",
    minWidth: 170,
  },
];

const SuperAdminTable = (props) => {
  const {
    currentUser,
    rowsPerPage,
    setFullLoder,
    searchKey,
    resendApi,
    setResendApi,
  } = props;
  const [page, setPage] = useState(1);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [superAdminList, setSuperAdminList] = useState([]);
  const [numOfPages, setNumOfPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [deleteUserData, setDeleteUserData] = useState({});
  const prevPageValue = usePrevious(page);

  const resetPaginationSetting = () => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (resendApi == true) {
      setFullLoder(true);
      GetAllSuperAdminBL({
        currentUser: currentUser,
        searchKey: searchKey,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
      }).then((data) => {
        setSuperAdminList(data.superAdminList);
        setLimit(data.limit);
        setFullLoder(false);
        setResendApi(false);
      });
    }
  }, [resendApi]);

  useEffect(() => {
    resetPaginationSetting();
  }, [limit, superAdminList]);

  useEffect(() => {
    if (prevPageValue !== undefined) {
      setResendApi(true);
    }
  }, [page]);

  const getStatusLabel = (type) => {
    switch (type) {
      case SUPER_ADMIN_STATUS_ACTIVE:
        return SUCCESS_BACKGROUND;
      case SUPER_ADMIN_STATUS_PENDING:
        return WARNING_OUTLINE_BACKGROUND;
      case SUPER_ADMIN_STATUS_INACTIVE || STATUS_INACTIVE:
        return DANGER_OUTLINE_BACKGROUND;
    }
  };

  const toggleStatus = async (user) => {
    setFullLoder(true);
    ToggleSuperAdminStatusBL({
      user: user,
    }).then((data) => {
      if (data.success) {
        setFullLoder(false);
        setResendApi(true);
      } else {
      }
    });
  };

  const deleteDocument = async (user) => {
    if (user) {
      const fullName = `${user.first_name} ${user.last_name}`;
      setConfirmDialog({
        isOpen: true,
        title: fullName,
        subTitle: "Are you sure to delete Super Admin?",
      });
      setDeleteUserData(user);
    }
  };

  const confirmDeleteUser = async () => {
    if (deleteUserData && deleteUserData._id) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const response = await Api(
        `${GET_ADMIN_USER_API}/${deleteUserData._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setDeleteUserData({});
          const newUserList = superAdminList.filter(
            (item) => item._id !== deleteUserData._id
          );
          setSuperAdminList(newUserList);

          if (superAdminList.length - 1 === 0) {
            setPage((prevPage) => prevPage - 1);
          } else {
            setResendApi(true);
          }
        }
      }
    }
  };

  return (
    <>
      <Paper elevation={0} square>
        <TableContainer
          className="table-container supplier-aspect"
          component={Paper}
          aria-label="list-all-user-table-super-admin-aspect"
        >
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {superAdminList && superAdminList.length > 0 ? (
                superAdminList.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell> {user?.email} </TableCell>
                    <TableCell>
                      {user?.first_name + " " + user?.last_name}
                    </TableCell>

                    <TableCell>
                      <Button
                        className={
                          "badge badge-" +
                          getStatusLabel(user?.status) +
                          " status"
                        }
                        variant="contained"
                      >
                        {user?.status}
                      </Button>
                    </TableCell>

                    <TableCell>
                      <Box>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <div>
                              <Button
                                className="action-btn"
                                variant="contained"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </Button>
                              <TableOptionList {...bindMenu(popupState)}>
                                <MenuItem
                                  className="bold-line-600"
                                  onClick={() => {
                                    toggleStatus(user);
                                    popupState.close();
                                  }}
                                >
                                  {user.status === SUPER_ADMIN_STATUS_INACTIVE
                                    ? SUPER_ADMIN_STATUS_ACTIVE
                                    : SUPER_ADMIN_STATUS_INACTIVE}
                                </MenuItem>
                                <MenuItem
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    deleteDocument(user);
                                    popupState.close();
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </TableOptionList>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={limit + " Users Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmDeleteProject={confirmDeleteUser}
      />
    </>
  );
};

export default SuperAdminTable;
