import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../../components/Notification";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AuctionsBgImg from "../../../assets/Auctions@2x.png";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import GetAllAuctionBL from "./BL/GetAllAuctionBL";
import {
  AUCTION_ACCEPTED,
  AUCTION_DECLINED,
  AUCTION_ENDED,
  AUCTION_INVITED,
  AUCTION_LOST,
  AUCTION_ON_CANCELLED,
  AUCTION_ON_HOLD,
  AUCTION_PUBLISHED,
  AUCTION_WON,
  BLUE_BACKGROUND,
  DANGER_BACKGROUND,
  DANGER_OUTLINE_BACKGROUND,
  DISABELED_BACKGROUND,
  GREY_BACKGROUND,
  PRIMARY_BACKGROUND,
  PRIMARY_OUTLINE_BACKGROUND,
  PURPLE_BACKGROUND,
  SUCCESS_BACKGROUND,
  TOAST_AUTO_CLOSE,
  TYPE_ONE,
  TYPE_TWO,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";
import { convertDateFormat } from "../../../utility/utils";

const columns = [
  {
    label: "ID",
  },
  {
    label: "Auction Type",
  },
  {
    label: "Client Name",
    minWidth: 170,
  },
  {
    label: "Auction Name",
    minWidth: 170,
  },
  {
    label: "Status",
  },
  {
    label: "Start Date",
  },
  {
    label: "Buyer",
    minWidth: 170,
  },
  {
    label: "Actions",
  },
];

const AuctionTable = (props) => {
  const {
    currentUser,
    rowsPerPage,
    setFullLoder,
    searchKey,
    filterValues,
    resendApi,
    setResendApi,
    page,
    setPage,
    list,
    setList,
    limit,
    setLimit,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  /// must start with (1) for pagination issue ....
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [numOfPages, setNumOfPages] = useState(0);

  const resetPaginationSetting = () => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  };

  useEffect(() => {
    setResendApi(true);
  }, []);

  useEffect(() => {
    if (resendApi) {
      setFullLoder(true);

      GetAllAuctionBL({
        currentUser: currentUser,
        searchKey: searchKey,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        filterValues: filterValues,
      }).then((data) => {
        setList(data.list);
        setLimit(data.limit);
        setFullLoder(false);
        setResendApi(false);
      });
    }
  }, [resendApi]);

  useEffect(() => {
    resetPaginationSetting();
  }, [limit, list]);

  useEffect(() => {
    setResendApi(true);
  }, [page]);

  const getTypeLabel = (type) => {
    switch (type) {
      case TYPE_ONE:
        return BLUE_BACKGROUND;
      case TYPE_TWO:
        return GREY_BACKGROUND;
      default:
        return DISABELED_BACKGROUND;
    }
  };

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ENDED:
        return "secondary";
      case AUCTION_ACCEPTED:
        return "success";
      case AUCTION_WON:
        return SUCCESS_BACKGROUND;
      case AUCTION_PUBLISHED:
        return PRIMARY_BACKGROUND;
      case AUCTION_DECLINED:
        return WARNING_OUTLINE_BACKGROUND;
      case AUCTION_ON_HOLD:
        return PRIMARY_OUTLINE_BACKGROUND;
      case AUCTION_ON_CANCELLED:
        return DANGER_BACKGROUND;
      case AUCTION_LOST:
        return DANGER_OUTLINE_BACKGROUND;
      case AUCTION_INVITED:
        return PURPLE_BACKGROUND;
      default:
        return SUCCESS_BACKGROUND;
    }
  };

  const navigateToAuctionDetails = async (auction) => {
    if (auction.supplierstatus && auction.supplierstatus !== AUCTION_DECLINED) {
      history.push("/supplier/auction/" + auction._id + "/details");
    } else {
      toast.error(ValidationMessage.notAccessAuction, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  return (
    <>
      <Paper elevation={0} square>
        <TableContainer
          className="table-container supplier-aspect"
          component={Paper}
          aria-label="list-all-auction-table-supplier-aspect"
        >
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((auction, i) => (
                // <TableRow key={auction._id}>
                <TableRow
                  key={auction._id}
                  className="cursor-p"
                  onDoubleClick={() => navigateToAuctionDetails(auction)}
                >
                  <TableCell>{auction?.auctionId?.auctionId}</TableCell>
                  <TableCell>
                    <div
                      className="items-center d-flex"
                      style={{
                        opacity:
                          getTypeLabel(auction.auctionId.auction_type) ===
                          "disabled"
                            ? "0"
                            : "1",
                      }}
                    >
                      <div
                        className={
                          "aution-img-container " +
                          getTypeLabel(auction.auctionId.auction_type)
                        }
                      >
                        <img src={AuctionsBgImg} alt="" />
                      </div>
                      <div> English Auction </div>
                    </div>
                  </TableCell>
                  <TableCell>{auction?.company_buyer_name}</TableCell>
                  <TableCell>{auction?.auctionId?.auction_name}</TableCell>
                  <TableCell>
                    <Button
                      className={
                        "badge badge-" +
                        getStatusLabel(auction?.auctionStatus) +
                        " status"
                      }
                      variant="contained"
                    >
                      {auction?.auctionStatus}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {convertDateFormat(auction?.auctionId?.startDateTS)}
                  </TableCell>
                  <TableCell>
                    <div className="items-center d-flex">
                      {/* <img
                        style={{ width: "26px", borderRadius: "50%" }}
                        src={
                          auction?.manager?.supplierId?.main_contact
                            ?.image_upload
                        }
                      /> */}
                      <div>
                        {auction.manager && auction.manager.length > 0
                          ? auction.manager
                          : "---"}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              {/* <MenuItem className="bold-line-600">
                                <div>View events list</div>
                              </MenuItem> */}
                              <MenuItem
                                className="bold-line-600"
                                onClick={() =>
                                  navigateToAuctionDetails(auction)
                                }
                              >
                                <div>View</div>
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {list.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add some auction
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={limit + " Auctions Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(AuctionTable));
