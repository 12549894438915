import React, { useState } from "react";
import "./ReadMore.css";
const ReadMoreText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="read-more-paragraph">
        <p className={`paragraph-text ${isExpanded ? "expanded" : ""}`}>
          {isExpanded && text
            ? text
            : `${text?.slice(0, 360)} ${
                text && text.length < 498 ? "" : "..."
              }`}

          {text && text.length > 498 && (
            <button
              className={`paragraph-text ${
                isExpanded
                  ? "expanded read-more-button p-0 ml-2"
                  : "ml-2 expanded read-more-button p-0"
              }`}
              onClick={toggleExpand}
            >
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
        </p>
      </div>
    </>
  );
};

export default ReadMoreText;
