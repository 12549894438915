import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Search from "@material-ui/icons/Search";
import { useForm } from "../../components/useForm";
import Loader from "../../components/Loader";
import { store } from "../../store/store";
import "../../styles/filter-box.css";
import { connect, useDispatch } from "react-redux";
import ConfirmationDailog from "../Auction/components/confirmationDailog";
// import "./styles/create-auction-step0.css";
import { withRouter } from "react-router-dom";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import { currentUser } from "../../store/selectors/AuthSelectors";

import usePrevious from "../../components/usePrevious";
import { PROJECT_LIST_API } from "../../constants/ApiUrl";
import { Api } from "../../api/Api";
import RfxTable from "./RfxTable";
import RfxFilter from "./Components/RfxFilter";
import InitialRfxCreateModal from "./Components/RfxFormStep/InitialRfxCreateModal";
import RfxFormStep1 from "./Components/RfxFormStep/RfxFormStep1";
import RfxFormStep2 from "./Components/RfxFormStep/RfxFormStep2";
import RfxFormStep3 from "./Components/RfxFormStep/RfxFormStep3";
import RfxFormStep4 from "./Components/RfxFormStep/RfxFormStep4";
import RfxFormStep5 from "./Components/RfxFormStep/RfxFormStep5";
import RfxFormStep6 from "./Components/RfxFormStep/RfxFormStep6";
import GetAllRfx from "./Components/RfxProcessFunction/GetAllRfx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
}));

const initialFValues = {
  project_id: "",
  rfx_id: "",
  auction_type: "1",
};

const stepLabels = [
  "General Information",
  "Setting",
  "Questions",
  "Attachment",
  "Item",
  "Suppliers",
];

const RfxList = (props) => {
  const { isAdmin, isSeller, isBuyer, currentUser } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupStep1, setOpenPopupStep1] = useState(false);
  const [openPopupStep2, setOpenPopupStep2] = useState(false);
  const [openPopupStep3, setOpenPopupStep3] = useState(false);
  const [openPopupStep4, setOpenPopupStep4] = useState(false);
  const [openPopupStep5, setOpenPopupStep5] = useState(false);
  const [openPopupStep6, setOpenPopupStep6] = useState(false);
  const [isEditRecord, setIsEditRecord] = useState(false);
  const [editUserId, setEditUserId] = useState(undefined);
  const [rfxList, setAuctionList] = useState([]);
  const [fullLoder, setFullLoder] = useState(false);
  const [dropdownProject, setDropdownProject] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataOrder, setDataOrder] = React.useState("desc");
  const [openConfrmationDailog, setOpenConfrmationDailog] = useState(false);
  const [resendApi, setResendApi] = useState(false);
  const [numOfPages, setNumOfPages] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const prevPageValue = usePrevious(page);
  const [searchText, setSearchText] = useState(null);
  const [filters, setFilters] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("project_id" in fieldValues)
      temp.project_id = fieldValues.project_id ? "" : "Project is required.";

    if ("auction_type" in fieldValues)
      temp.auction_type = fieldValues.auction_type ? "" : "Type is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getProjectListForDropdown = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(`${PROJECT_LIST_API}/${companyId}`, {}, "get");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.projectData;
        setDropdownProject(allItems);
      }
    }

    setOpenPopup(true);
    setFullLoder(false);
  };

  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
    setResendApi(true);
  };

  const clickClosePopup = () => {
    setOpenConfrmationDailog(true);
  };

  useEffect(() => {
    if (resendApi) {
      setFullLoder(true);
      setResendApi(false);
      getAllAuctionList();
    }
  }, [resendApi]);

  useEffect(() => {
    if (currentUser) {
      setResendApi(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && prevPageValue !== undefined && prevPageValue !== page) {
      setResendApi(true);
    }
  }, [page]);

  const openPPAddAuction = async () => {
    setFullLoder(true);
    resetForm();
    getProjectListForDropdown();
  };

  const addFirstStepRFx = () => {
    setOpenPopup(false);
    setOpenPopupStep1(true);
  };

  const getSearchString = (e) => {
    setSearchText(e.target.value);
    setResendApi(true);
  };

  const getAllAuctionList = () => {
    GetAllRfx({
      companyId: currentUser.company._id,
      rowsPerPage: rowsPerPage,
      page: page,
      search: searchText,
      filters: filters,
      dataOrder: dataOrder,
    })
      .then((data) => {
        setFullLoder(false);
        setAuctionList(data.list);
        setLimit(data.limit);
        setNumOfPages(Math.ceil(data.limit / rowsPerPage));
      })
      .catch((error) => {
        setFullLoder(false);
      });
  };

  const discardChanges = () => {
    setOpenPopup(false);

    setOpenConfrmationDailog(true);
  };
  const allModalClose = () => {
    setOpenPopupStep1(false);
    setOpenPopupStep2(false);
    setOpenPopupStep3(false);
    setOpenPopupStep4(false);
    setOpenPopupStep5(false);
    setOpenPopupStep6(false);
  };
  return (
    <>
      <Paper elevation={0} square className={classes.root}>
        <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
        {/* <Loader fullLoder={false} /> */}
        <Grid container style={{ marginTop: "1em" }}>
          <Grid item container xs={12} style={{ alignItems: "center" }}>
            <Grid item xs={3}>
              <h1 className="item-container">RFX</h1>
            </Grid>
            <Grid item xs={9}>
              <Box style={{ float: "right" }}>
                <Controls.Button
                  variant="contained"
                  className={
                    "btn btn-white btn-mui" +
                    (showFilterCategories ? " active" : "")
                  }
                  text="Filter"
                  onClick={toggleFilterCategory}
                  startIcon={
                    <FilterListSharpIcon style={{ color: "#2170FF" }} />
                  }
                />

                {!isBuyer ? (
                  <></>
                ) : (
                  <>
                    <Controls.Button
                      text="Add RFX"
                      className="btn btn-primary btn-mui"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        openPPAddAuction();
                      }}
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container className="filter-box-container">
            {showFilterCategories && (
              <Paper elevation={1} className="filter-box">
                <RfxFilter
                  setRfxList={setAuctionList}
                  setShowFilterCategories={setShowFilterCategories}
                  setResendApi={setResendApi}
                  setPage={setPage}
                  setFilters={setFilters}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Grid item xs={12}>
            <Box sx={{ display: "inline" }}>
              <TextField
                onChange={getSearchString}
                placeholder="Search Using Keyword"
                className="select-box select-box-primary search-box"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box style={{ float: "right" }}>
              <Select
                value={rowsPerPage}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="items-count-dropdown mr-3"
              >
                <MenuItem value={10}>Show 10 entries</MenuItem>
                <MenuItem value={20}>Show 20 entries</MenuItem>
                <MenuItem value={30}>Show 30 entries</MenuItem>
              </Select>
              <Select
                value={dataOrder}
                onChange={(e) => {
                  setDataOrder(e.target.value);
                  setResendApi(true);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="items-count-dropdown"
              >
                <MenuItem value={"desc"}>Sort by Latest</MenuItem>
                <MenuItem value={"asc"}>Sort by Oldest</MenuItem>
              </Select>
            </Box>
          </Grid>
          <RfxTable
            setIsEditRecord={setIsEditRecord}
            setOpenPopup={setOpenPopup}
            setValues={setValues}
            setEditUserId={setEditUserId}
            rfxList={rfxList}
            setRowsPerPage={setRowsPerPage}
            setResendApi={setResendApi}
            page={page}
            setPage={setPage}
            limit={limit}
            numOfPages={numOfPages}
            getAllAuctionList={getAllAuctionList}
          />
        </Box>
      </Paper>

      {openPopup && (
        <InitialRfxCreateModal
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          isBuyer={isBuyer}
          addFirstStepRFx={addFirstStepRFx}
          dropdownProject={dropdownProject}
        />
      )}
      <RfxFormStep1
        stepLabels={stepLabels}
        openPopupStep1={openPopupStep1}
        setOpenPopupStep1={setOpenPopupStep1}
        setOpenPopupStep2={setOpenPopupStep2}
        discardChanges={discardChanges}
      />

      <RfxFormStep2
        openPopupStep2={openPopupStep2}
        setOpenPopupStep1={setOpenPopupStep1}
        setOpenPopupStep2={setOpenPopupStep2}
        setOpenPopupStep3={setOpenPopupStep3}
        setResendApi={setResendApi}
        stepLabels={stepLabels}
        projects={dropdownProject}
        discardChanges={discardChanges}
      />

      {openPopupStep3 && (
        <RfxFormStep3
          openPopupStep3={openPopupStep3}
          setOpenPopupStep4={setOpenPopupStep4}
          setOpenPopupStep2={setOpenPopupStep2}
          setOpenPopupStep3={setOpenPopupStep3}
          discardChanges={discardChanges}
          stepLabels={stepLabels}
          setResendApi={setResendApi}
        />
      )}
      {openPopupStep4 && (
        <RfxFormStep4
          openPopupStep4={openPopupStep4}
          setOpenPopupStep5={setOpenPopupStep5}
          setOpenPopupStep3={setOpenPopupStep3}
          setOpenPopupStep4={setOpenPopupStep4}
          discardChanges={discardChanges}
          stepLabels={stepLabels}
          setResendApi={setResendApi}
        />
      )}
      {openPopupStep5 && (
        <RfxFormStep5
          openPopupStep5={openPopupStep5}
          setOpenPopupStep5={setOpenPopupStep5}
          setOpenPopupStep6={setOpenPopupStep6}
          setOpenPopupStep4={setOpenPopupStep4}
          discardChanges={discardChanges}
          stepLabels={stepLabels}
          setResendApi={setResendApi}
        />
      )}
      {openPopupStep6 && (
        <RfxFormStep6
          openPopupStep6={openPopupStep6}
          setOpenPopupStep5={setOpenPopupStep5}
          setOpenPopupStep6={setOpenPopupStep6}
          discardChanges={discardChanges}
          stepLabels={stepLabels}
          setResendApi={setResendApi}
          fullLoder={fullLoder}
          setFullLoder={setFullLoder}
        />
      )}

      <ConfirmationDailog
        openConfrmationDailog={openConfrmationDailog}
        setOpenConfrmationDailog={setOpenConfrmationDailog}
        setDiscardAndClose={discardChanges}
        eraseEverything={false}
        fromRfx={true}
        allModalClose={allModalClose}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(RfxList));
