import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Chart,
  Legend,
  PieSeries,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker } from "@devexpress/dx-react-chart";
import { Animation } from "@devexpress/dx-react-chart";
import {
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Controls from "../../../components/controls/Controls";
import Search from "@material-ui/icons/Search";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@material-ui/icons/Add";
import TableHead from "@mui/material/TableHead";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Modal from "@mui/material/Modal";
import { useForm, Form } from "../../../components/useForm";
import useWindowDimensions from "../../../components/useWindowDimensions";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import MonyBagIcon from "../../../assets/statistics/money-bag.png";
import DollarIcon from "../../../assets/statistics/dollar.png";
import FinanceIcon from "../../../assets/statistics/finance.png";
import "../styles/budget.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { InputLabel } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import { thousandSepratorHandler } from "../../../utility/utils";
import NumericSeparatorInput from "../../../components/NumericSeparatorInput";
import { Api } from "../../../api/Api";
import {
  COMAPNY_API,
  DELETE_BUDGET_API,
  GET_COST_CENTER_API,
  POST_EDIT_BUDGET_API,
} from "../../../constants/ApiUrl";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { BUDGET_MAX_VALUE } from "../../../constants/AppConstants";
import CommonButton from "../../../components/controls/CommonButton";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "100%",
    border: "0px solid #ccc",
    [`& fieldset`]: {
      borderRadius: 6,
    },
  },
  selectAction: {
    "& .MuiInputLabel-root": {
      display: "none",
    },
  },
  toolbars: {
    "& .MuiToolbar-gutters": {
      padding: "12px",
      margin: "0px",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  // Forms
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    "& .MuiDialogTitle-root": {
      padding: "1px",
    },
  },
  dialogTitle: {
    paddingRight: "0px",
    fontWeight: "600px",
  },
  dialog: {
    "& .MuiDialogContent-dividers ": {
      borderTop: "0px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "0px solid rgba(0, 0, 0, 0.12)",
    },
  },
  ButtonFilter: {
    border: "1px #2170ff solid",
    padding: "0.4em 1.4em",
    backgroundColor: "#fff",
    color: "#2170ff",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  ButtonAddEvent: {
    border: "1px #2170ff solid",
    padding: "0.4em 1.4em",
    borderRadius: "10px",
  },
  sectionText: {
    fontWeight: "600",
    fontSize: "24px",
  },
  // Header
  root: {
    backgroundColor: "transparent",
  },
  wrapper: {
    borderRadius: "12px",
    padding: "0 12px",
    backgroundColor: "red",
  },
  // .MuiPaper-rounded
  pageHeader: {
    padding: theme.spacing(1),
    display: "flex",
    marginBottom: theme.spacing(0),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#2170ff",
  },
  pname: { color: theme.palette.primary.main, fontWeight: "bold" },
  pdesc: { opacity: "0.5" },
  pageTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  warning: { backgroundColor: theme.palette.primary.warning },
  actionbtnRight: {
    position: "relative",
    top: 10,
    right: 10,
  },
  menupos: {
    // position: "relative",
    // right: "-18px",
  },
  searchBar: {
    width: "100%",
    margin: "1rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  sort: {
    display: "flex",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "38em",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initialFValues = {
  cost_center: "",
  budget: "",
};

var columns = [
  {
    label: "Cost Center",
    align: "center",
  },
  {
    label: "Budget",
    // minWidth: 100
  },
];

const ProjectBudget = (props) => {
  const { selectedProject, currentUser } = props;
  const classes = useStyles();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [spentBudgetList, setSpentBudgetList] = useState([]);
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  /// Please be carefull !!!
  /// must start with (1) for pagination issue ....
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [costCenterList, setCostCenterList] = useState(false);
  const [fullLoder, setFullLoder] = useState(false);
  const [editBudgetId, setEditBudgetId] = useState(null);
  const [firstChartData, setFirstChartData] = useState([]);
  const [secondChartData, setSecondChartData] = useState([]);
  const [thiredChartData, setThiredChartData] = useState([]);
  const [sumOfTotalBudget, setSumOfTotalBudget] = useState(0);
  const [sumOfTotalSpentBudget, setSumOfTotalSpentBudget] = useState(0);
  const [sumOfTotalBalanceBudget, setSumOfTotalBalanceBudget] = useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [alertDailog, setAlertDailog] = useState(false);
  const [deleteBudgetData, setDeleteBudgetData] = useState({});
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  const numOfPages = Math.ceil(budgetList.length / rowsPerPage);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("cost_center" in fieldValues)
      temp.cost_center = fieldValues.cost_center
        ? ""
        : "This field is required.";
    if ("budget" in fieldValues) {
      if (fieldValues.budget) {
        if (fieldValues.budget > BUDGET_MAX_VALUE) {
          temp.budget = ValidationMessage.budgetLength;
        } else {
          temp.budget = "";
        }
      } else {
        temp.budget = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setShowSaveLoader(true);
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      const projectId = selectedProject._id;
      const payload = {
        budget: parseInt(values.budget),
        companyId: companyId,
        userId: auth?.auth?.user?._id,
        costCenter: values.cost_center,
      };
      if (!isEditMode) {
        const response = await Api(
          `${COMAPNY_API}/${projectId}/addBudget`,
          payload,
          "post"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            setOpen(false);
            getAllBudget();
            setIsEditMode(false);
            resetForm();
          }
        }
        setShowSaveLoader(false);

        // await axiosInstance
        //   .post(`/api/buyer/company/${projectId}/addBudget`, payload)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       setNotify({
        //         isOpen: true,
        //         message: "Budget created successfully",
        //         type: "success",
        //       });
        //       setOpen(false);
        //       getAllBudget();
        //       setIsEditMode(false);
        //       resetForm();
        //     }
        //   })
        //   .catch((err) => {
        //     setNotify({
        //       isOpen: true,
        //       message: err?.response?.data?.responseDescription,
        //       type: "error",
        //     });
        //   });
      } else {
        if (editBudgetId) {
          payload["projectId"] = projectId;
          const response = await Api(
            `${POST_EDIT_BUDGET_API}/${editBudgetId}`,
            payload,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setOpen(false);
              getAllBudget();
              setIsEditMode(false);
              resetForm();
            }
          }
          setShowSaveLoader(false);

          // await axiosInstance
          //   .patch(`api/buyer/company/editBudget/${editBudgetId}`, payload)
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setNotify({
          //         isOpen: true,
          //         message: "Budget updated successfully",
          //         type: "success",
          //       });
          //       setOpen(false);
          //       getAllBudget();
          //       setIsEditMode(false);
          //       resetForm();
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //   });
        }
      }
    }
  };

  const addOrEditBudget = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COST_CENTER_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let costCenterList = response.data.costCenter;
          setCostCenterList(costCenterList);
          const loader = setTimeout(() => {
            setFullLoder(false);
            setOpen(true);
          }, 500);

          return () => {
            clearTimeout(loader);
          };
        }
      }

      // await axiosInstance
      //   .get(`/api/buyer/company/getCostCenter/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let costCenterList = response.data.costCenter;
      //       setCostCenterList(costCenterList);
      //       const loader = setTimeout(() => {
      //         setFullLoder(false);
      //         setOpen(true);
      //       }, 500);

      //       return () => {
      //         clearTimeout(loader);
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const clickOnCancel = () => {
    setOpen(false);
    setIsEditMode(false);
    resetForm();
  };

  const getAllBudget = async () => {
    const projectId = selectedProject._id;
    if (projectId) {
      const response = await Api(
        `${COMAPNY_API}/${projectId}/budgets`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let bugetsLists = response.data.budgets;
          let spntBugetsList = response.data.spentBudgets;
          let balanceBugetsList = {};
          let dataForFirstChart = [];
          let dataForSecondChart = [];
          let dataForThirdChart = [];
          setBudgetList(bugetsLists);
          setSpentBudgetList(spntBugetsList);
          let totalBudget = 0;
          let totalSpentBudget = 0;
          let totalBalancBudget = 0;
          bugetsLists.map((ch) => {
            const tmp = {
              argument: ch?.costCenter?.name,
              value: ch.budget,
            };
            balanceBugetsList[ch?.costCenter?.name] = ch.budget;
            totalBudget = totalBudget + ch.budget;
            dataForFirstChart.push(tmp);
          });
          spntBugetsList.map((ch) => {
            const tmp = {
              argument: ch?.costCenter?.name,
              value: ch.budget,
            };
            totalSpentBudget = totalSpentBudget + ch.budget;
            dataForSecondChart.push(tmp);

            if (balanceBugetsList[ch?.costCenter?.name]) {
              balanceBugetsList[ch?.costCenter?.name] -= ch.budget;
            } else {
              balanceBugetsList[ch?.costCenter?.name] = -1 * ch.budget;
            }
          });
          for (const budgetName in balanceBugetsList) {
            totalBalancBudget += balanceBugetsList[budgetName];
            dataForThirdChart.push({
              argument: budgetName,
              value: balanceBugetsList[budgetName],
            });
          }
          setSumOfTotalBudget(totalBudget);
          setSumOfTotalSpentBudget(totalSpentBudget);
          setSumOfTotalBalanceBudget(totalBalancBudget);
          setFirstChartData(dataForFirstChart);
          setSecondChartData(dataForSecondChart);
          setThiredChartData(dataForThirdChart);
          const loader = setTimeout(() => {
            setFullLoder(false);
          }, 500);
          return () => {
            clearTimeout(loader);
          };
        }
      }

      // await axiosInstance
      //   .get(`/api/buyer/company/${projectId}/budgets`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let bugetsLists = response.data.budgets;
      //       let spntBugetsList = response.data.spentBudgets;
      //       let balanceBugetsList = {};
      //       let dataForFirstChart = [];
      //       let dataForSecondChart = [];
      //       let dataForThirdChart = [];
      //       setBudgetList(bugetsLists);
      //       setSpentBudgetList(spntBugetsList);
      //       let totalBudget = 0;
      //       let totalSpentBudget = 0;
      //       let totalBalancBudget = 0;
      //       bugetsLists.map((ch) => {
      //         const tmp = {
      //           argument: ch?.costCenter?.name,
      //           value: ch.budget,
      //         };
      //         balanceBugetsList[ch?.costCenter?.name] = ch.budget;
      //         totalBudget = totalBudget + ch.budget;
      //         dataForFirstChart.push(tmp);
      //       });
      //       spntBugetsList.map((ch) => {
      //         const tmp = {
      //           argument: ch?.costCenter?.name,
      //           value: ch.budget,
      //         };
      //         totalSpentBudget = totalSpentBudget + ch.budget;
      //         dataForSecondChart.push(tmp);

      //         if (balanceBugetsList[ch?.costCenter?.name]) {
      //           balanceBugetsList[ch?.costCenter?.name] -= ch.budget;
      //         } else {
      //           balanceBugetsList[ch?.costCenter?.name] = -1 * ch.budget;
      //         }
      //       });
      //       for (const budgetName in balanceBugetsList) {
      //         totalBalancBudget += balanceBugetsList[budgetName];
      //         dataForThirdChart.push({
      //           argument: budgetName,
      //           value: balanceBugetsList[budgetName],
      //         });
      //       }
      //       setSumOfTotalBudget(totalBudget);
      //       setSumOfTotalSpentBudget(totalSpentBudget);
      //       setSumOfTotalBalanceBudget(totalBalancBudget);
      //       setFirstChartData(dataForFirstChart);
      //       setSecondChartData(dataForSecondChart);
      //       setThiredChartData(dataForThirdChart);
      //       const loader = setTimeout(() => {
      //         setFullLoder(false);
      //       }, 500);
      //       return () => {
      //         clearTimeout(loader);
      //       };
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: "Please add cost centers on the settings",
      //         type: "error",
      //       });
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const editBudget = (budget) => {
    if (budget) {
      setIsEditMode(true);
      setEditBudgetId(budget._id);
      setValues({
        cost_center: budget?.costCenter?._id,
        budget: budget?.budget,
      });
      addOrEditBudget();
    }
  };

  const deleteBudget = async (budget) => {
    if (budget) {
      setAlertDailog(true);
      setDeleteBudgetData(budget);
    }
  };

  const confirmDeleteBudget = async () => {
    if (deleteBudgetData) {
      const response = await Api(
        `${DELETE_BUDGET_API}/${selectedProject._id}/${deleteBudgetData._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllBudget();
          setAlertDailog(false);
        }
      }

      // await axiosInstance
      //   .delete(
      //     `api/buyer/deleteBudget/${selectedProject._id}/${deleteBudgetData._id}`
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       getAllBudget();
      //       setAlertDailog(false);
      //       setNotify({
      //         isOpen: true,
      //         message: "Budget removed successfully.",
      //         type: "success",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const requestSearch = (searchedVal) => {
    // budgetList, setBudgetList
    const textVal = searchedVal.target.value;
    if (textVal) {
      const filteredRows = budgetList.filter((row) => {
        return (
          row?.costCenter?.name?.indexOf(textVal) > -1 || row?.budget == textVal
        );
      });
      setBudgetList(filteredRows);
    } else {
      getAllBudget();
    }
  };

  const isEditor = () => {
    if (selectedProject && currentUser) {
      if (Array.isArray(selectedProject.editorIds)) {
        return (
          selectedProject.editorIds.findIndex(
            (Id) => String(Id) === String(currentUser._id)
          ) >= 0
        );
      }
    }
    return false;
  };

  const isViewer = () => {
    if (selectedProject && currentUser) {
      if (Array.isArray(selectedProject.viewerIds)) {
        return (
          selectedProject.viewerIds.findIndex(
            (Id) => String(Id) === String(currentUser._id)
          ) >= 0
        );
      }
    }
    return false;
  };

  const isOwner = () => {
    if (selectedProject && currentUser) {
      return String(selectedProject.createdBy) === String(currentUser._id);
    }
    return false;
  };

  useEffect(() => {
    if (isEditor() && columns.length < 3) {
      columns.push({
        label: "",
      });
    }
    getAllBudget();
  }, []);

  return (
    <>
      <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
      {/* statistics section */}
      <Grid container spacing={3} className="buget-chart-container">
        <Grid item lg={4} md={6} sm={12}>
          <Card className="budget-statistics-card first">
            <CardHeader
              action={
                <IconButton aria-label="settings" className="chart-icon-label">
                  <img src={MonyBagIcon} />
                </IconButton>
              }
              title={
                <div className="buget-chart-title">
                  <span> Total Project Budget </span>
                  <span>
                    {thousandSepratorHandler(sumOfTotalBudget)}{" "}
                    {selectedProject?.currency}
                  </span>
                </div>
              }
            />
            <CardContent>
              <Chart
                ariaLabel="chart-number-1"
                data={firstChartData}
                width="285"
                heigth="285"
                style={{ margin: "auto" }}
              >
                <PieSeries valueField="value" argumentField="argument" />
                <Legend position="bottom" />
                <Animation />
                <EventTracker />
                <Tooltip />
              </Chart>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <Card className="budget-statistics-card second">
            <CardHeader
              action={
                <IconButton aria-label="settings" className="chart-icon-label">
                  <img src={FinanceIcon} />
                </IconButton>
              }
              title={
                <div className="buget-chart-title">
                  <span> Total Spent </span>
                  <span>
                    {thousandSepratorHandler(sumOfTotalSpentBudget)}{" "}
                    {selectedProject?.currency}
                  </span>
                </div>
              }
            />
            <CardContent>
              <Chart
                data={secondChartData}
                width="285"
                heigth="285"
                style={{ margin: "auto" }}
                ariaLabel="chart-number-2"
              >
                <PieSeries valueField="value" argumentField="argument" />
                <Legend position="bottom" />
                <Animation />
                <EventTracker />
                <Tooltip />
              </Chart>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <Card className="budget-statistics-card third">
            <CardHeader
              action={
                <IconButton aria-label="settings" className="chart-icon-label">
                  <img src={DollarIcon} />
                </IconButton>
              }
              title={
                <div className="buget-chart-title">
                  <span> Balance </span>
                  <span>
                    {thousandSepratorHandler(sumOfTotalBalanceBudget)}{" "}
                    {selectedProject?.currency}
                  </span>
                </div>
              }
            />
            <CardContent>
              <Chart
                data={thiredChartData}
                width="285"
                heigth="285"
                style={{ margin: "auto" }}
                ariaLabel="chart-number-3"
              >
                <Legend position="bottom" />
                <PieSeries valueField="value" argumentField="argument" />
                <Animation />
                <EventTracker />
                <Tooltip />
              </Chart>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* statistics section */}

      <Paper
        elevation={0}
        square
        style={{ marginTop: "2em", padding: "1.5em 2em" }}
      >
        <Grid container>
          <Grid
            item
            sm={6}
            xs={12}
            style={{ textAlign: xsScreen ? "center" : "start" }}
          >
            <TextField
              onChange={requestSearch}
              placeholder="Search Using Keyword"
              className="select-box select-box-primary search-box"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {isEditor() ? (
            <Grid
              item
              sm={6}
              xs={12}
              style={{ textAlign: xsScreen ? "center" : "end" }}
            >
              <Controls.Button
                text="Add Budget"
                className={classes.ButtonAddEvent}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  addOrEditBudget();
                }}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* minior change - start offestting using (page-1) 
               min value of page is one */}
              {(rowsPerPage > 0
                ? budgetList.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : budgetList
              ).map((item) => (
                <TableRow key={item?._id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "center" }}
                  >
                    {item?.costCenter?.name}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#2170FF" }}
                  >
                    {thousandSepratorHandler(item.budget)}
                  </TableCell>
                  {isEditor() ? (
                    <TableCell>
                      <Box>
                        <PopupState
                          variant="popover"
                          popupId="option-list-menu"
                        >
                          {(popupState) => (
                            <div>
                              <Button
                                className="action-btn"
                                variant="contained"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </Button>
                              <TableOptionList {...bindMenu(popupState)}>
                                <MenuItem
                                  className="bold-line-600"
                                  onClick={() => {
                                    popupState.close();
                                    editBudget(item);
                                  }}
                                >
                                  Edit Budget
                                </MenuItem>
                                <MenuItem
                                  className="bold-line-600"
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    popupState.close();
                                    deleteBudget(item);
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </TableOptionList>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              ))}
              {budgetList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                    {/* <i style={{ color: "var(---2170ff-p-)" }}>
                      Please add cost centers on the settings
                    </i> */}
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={budgetList.length + " Bugets Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
      </Paper>

      <Modal
        open={open}
        onClose={handleClose}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="SettingsModal"
      >
        {/* //////////////////////////////////////////////////////// */}
        <Box sx={style} className="budget-form-container">
          <p className="profile-title">{!isEditMode ? "Add" : "Edit"} Budget</p>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel id="cost-center-label">Cost Center</InputLabel>
                <Controls.Select
                  name="cost_center"
                  labelId="cost-center-label"
                  // label="Cost Center"
                  value={values.cost_center}
                  onChange={handleInputChange}
                  options={costCenterList}
                  error={errors.cost_center}
                  disabled={isEditMode}
                />
              </Grid>
              <br />
              <Grid item xs={12} style={{ marginTop: "1em" }}>
                <InputLabel id="budget-label">Budget</InputLabel>
                <Controls.Input
                  name="budget"
                  id="budget-label"
                  // label="Budget"
                  value={values.budget}
                  onChange={handleInputChange}
                  error={errors.budget}
                  InputProps={{
                    inputComponent: NumericSeparatorInput,
                  }}
                />
              </Grid>
            </Grid>
            <Box
              className="BottomModal"
              display="flex"
              p={1}
              bgcolor="background.paper"
            >
              <Grid item xs={12} className="BottomModal-right">
                <Box p={0}>
                  <Controls.Button
                    text="Cancel"
                    type="submit"
                    disableElevation
                    color="default"
                    style={{ margin: "0 20px 0 0" }}
                    onClick={() => clickOnCancel()}
                    className="action-btn cancel"
                  />
                </Box>
                <Box p={0}>
                  <CommonButton
                    type="submit"
                    disableElevation
                    text={!isEditMode ? "Add" : "Save"}
                    className="action-btn"
                    loader={showSaveLoader}
                  />
                </Box>
              </Grid>
            </Box>
          </Form>
        </Box>
      </Modal>

      <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete Budget?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setAlertDailog(false)}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={() => confirmDeleteBudget()}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(ProjectBudget));
