/*eslint-disable */
import { Paper, Grid, Box, Tooltip, InputLabel } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Form, useForm } from "../../../components/useForm";
import { connect, useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { HtmlUtilService } from "../../../services/HtmlUtilService";
import { Link, useHistory } from "react-router-dom";
import Controls from "../../../components/controls/Controls";
import useWindowDimensions from "../../../components/useWindowDimensions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AuctionLiveImg from "../../../assets/icons/Auctions-Blue.png";
import { getCurrentLiveAuction } from "../../../store/selectors/auctionSelector";
import { withRouter } from "react-router-dom";
import Notification from "../../../components/Notification";
import { store } from "../../../store/store";
import axiosInstance from "../../../services/AxiosInstance";
import PublishDraftAuctionBL from "../BL/PublishDraftAuctionBL";
import GetAuctionByIdBL from "../BL/GetAuctionByIdBL";
import { setLiveAuctionDetails } from "../../../store/actions/auctionAction";
import { setHTTPError } from "../../../store/actions/errorHandlerAction";
import userImage from "../../../assets/auth/user_icon_2.png";

import "../styles/details-banner.css";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import AuctionBannerShimmer from "./shimmer/AuctionBannerShimmer";
import { COMAPNY_API } from "../../../constants/ApiUrl";
import {
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_CANCELLED,
  AUCTION_DRAFT,
  AUCTION_ENDED_CAPITAL,
  AUCTION_ONHOLD,
  AUCTION_PUBLISHED,
  AUCTION_RUNNING,
  DANGER_BACKGROUND,
  PURPLE_BACKGROUND,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  TOAST_AUTO_CLOSE,
  WARNING_BACKGROUND,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";
import { Api } from "../../../api/Api";
import CommonButton from "../../../components/controls/CommonButton";
import DuplicateAuctionDialog from "./DuplicateAuctionDialog";
import moment from "moment";

const getStatusLabel = (type) => {
  switch (type) {
    case AUCTION_ENDED_CAPITAL:
      return WARNING_OUTLINE_BACKGROUND;
    case AUCTION_RUNNING:
      return SUCCESS_BACKGROUND;
    case AUCTION_PUBLISHED:
      return SUCCESS_BACKGROUND;
    case AUCTION_DRAFT:
      return SECONDARY_BACKGROUND;
    case AUCTION_ONHOLD:
      return WARNING_BACKGROUND;
    case AUCTION_CANCELLED:
      return DANGER_BACKGROUND;
    case AUCTION_ARCHIEVED_CAPITAL:
      return PURPLE_BACKGROUND;
  }
};

const AuctionBanner = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const UnAuthorizedRoute = useSelector(
    (state) => state.errorHandler.UnAuthorizedRoute
  );
  const { currentLiveAuction, currentUser } = props;

  const [editMode, setEditMode] = useState(false);
  const { mdScreen } = useWindowDimensions();
  const [duplicateAuctionModal, setDuplicateAuctionModal] = useState(false);

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [saveLoader, setSaveLoader] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const initialFValues = {
    name: currentLiveAuction?.auction_name,
    description: currentLiveAuction?.description,
    // startDate: currentLiveAuction?.startDate,
    // endDate: currentLiveAuction?.endDate,
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const clickToggleBtn = (event) => {
    setEditMode(!editMode);
    const descriptionView = document.querySelector("#description__view");
    const descriptionEdit = document.querySelector("#description__edit");

    if (editMode == true) {
      descriptionView.style.display = "block";
      descriptionEdit.style.display = "none";
    } else {
      descriptionView.style.display = "none";
      descriptionEdit.style.display = "block";
    }
  };

  const GoBackToAuctionList = () => {
    history.push("/auction");
  };

  const isOwner = () => {
    if (currentLiveAuction && currentUser) {
      return String(currentLiveAuction.createdBy) === String(currentUser._id);
    }
    return false;
  };

  const handleSubmit = async (e) => {
    let todayDate = moment().format("MM-DD-YYYY");
    let deliveryDateFormatted = moment(deliveryDate).format("MM-DD-YYYY");

    e.preventDefault();
    if (!editMode && isOwner()) {
      if (validate()) {
        const { auth } = store.getState();
        const companyId = auth?.auth?.user?.company?._id;
        if (
          currentLiveAuction !== undefined &&
          currentLiveAuction._id !== undefined
        ) {
          if (
            currentLiveAuction.status === AUCTION_PUBLISHED ||
            currentLiveAuction.status === AUCTION_DRAFT
          ) {
            const formatedStartDate = new Date(startDate);
            const formatedEndDate = new Date(endDate);
            const formatedDeliveryDate = new Date(deliveryDate);
            if (deliveryDate) {
              values.deliveryDate = formatedDeliveryDate;
            }
            values.startDate = formatedStartDate;
            values.endDate = formatedEndDate;
            values.auction_name = values.name;
            const Difference_In_Time =
              formatedEndDate.getTime() - formatedStartDate.getTime();
            const Days = Difference_In_Time / (1000 * 3600 * 24);
            const Mins = Difference_In_Time / (60 * 1000);
            const Hours = Mins / 60;

            if (moment(todayDate).isAfter(deliveryDateFormatted)) {
              toast.error(ValidationMessage.deliveryDate, {
                transition: Slide,
                autoClose: TOAST_AUTO_CLOSE,
              });
            } else {
              if (
                Math.round(Mins) >= 1 &&
                Math.round(Hours) <= 24 &&
                Math.round(Days) <= 1
              ) {
                setSaveLoader(true);
                const response = await Api(
                  `${COMAPNY_API}/${companyId}/auctions/${currentLiveAuction._id}`,
                  values,
                  "patch"
                );
                setSaveLoader(false);
              } else {
                toast.error(ValidationMessage.auctionTimeLimit, {
                  transition: Slide,
                  autoClose: TOAST_AUTO_CLOSE,
                });
              }
            }
          } else {
            toast.error(ValidationMessage.notUpdateAuction, {
              transition: Slide,
              autoClose: TOAST_AUTO_CLOSE,
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (currentLiveAuction && currentLiveAuction._id) {
      // HtmlUtilService.truncate();
      setValues({
        name: currentLiveAuction?.auction_name,
        description: currentLiveAuction?.description,
      });
      setStartDate(currentLiveAuction?.startDate);
      setDeliveryDate(currentLiveAuction?.deliveryDate || null);
      setEndDate(currentLiveAuction?.endDate);
    }
  }, [currentLiveAuction]);

  useEffect(() => {
    if (values && values.description) {
      HtmlUtilService.truncate();
    }
  }, [values]);

  const changeAuctionStatus = () => {
    if (currentLiveAuction && currentLiveAuction._id) {
      if (currentLiveAuction.status === AUCTION_DRAFT) {
        PublishDraftAuctionBL({
          auctionId: currentLiveAuction._id,
        }).then((data) => {
          if (data.success) {
            // must be take it from response of API
            // no need to send API to refresh the auction
            const message = data.message;
            GetAuctionByIdBL({
              id: currentLiveAuction._id,
            }).then((result) => {
              if (result.success) {
                dispatch(setLiveAuctionDetails(result.auction));
              } else {
                if (result.code === 403) {
                  dispatch(
                    setHTTPError({
                      code: 403,
                      message: result.message,
                    })
                  );
                  history.push(UnAuthorizedRoute);
                } else {
                  dispatch(
                    setHTTPError({
                      code: result.code,
                      message: result.message,
                    })
                  );
                }
              }
            });
          }
        });
      }
    }
  };

  return (
    <>
      <Form style={{ marginTop: "6em" }} onSubmit={handleSubmit}>
        <div
          className="details-banner buyer-aspect"
          style={{ background: "transparent" }}
        >
          <div className="banner-container">
            <Grid
              container
              style={{
                position: "relative",
              }}
              spacing={1}
            >
              <Grid item xs={12}>
                <h1 className="project-title mt-2">
                  <img src={AuctionLiveImg} className="sm-icon" />
                  <span className="ml-1">
                    <span className="main-text" truncate="5">
                      <span
                        className="show__less__text"
                        style={{ display: "inline" }}
                      >
                        {values && values.name ? values.name : "--"}
                      </span>
                      <span
                        className="show__complete__text"
                        style={{ display: "none" }}
                      >
                        {values && values.name ? values.name : "--"}
                      </span>
                      <span className="show__more__link">...</span>
                    </span>
                    {values && values.name
                      ? " ( English Reverse Auction )"
                      : ""}
                  </span>
                  <ContentCopyIcon
                    className="ml-1 sm-icon cp-icon cusrsor-pointer"
                    onClick={() => setDuplicateAuctionModal(true)}
                  />
                  <div className="table-container">
                    <Button
                      className={
                        "badge badge-" +
                        getStatusLabel(currentLiveAuction?.status) +
                        " status"
                      }
                      variant="contained"
                      onClick={changeAuctionStatus}
                    >
                      {currentLiveAuction?.status}
                    </Button>
                  </div>
                </h1>
                <div className="project-title__smal">
                  <span> Auctions </span>
                  <span>
                    {" / " +
                      (currentLiveAuction && currentLiveAuction.auction_name
                        ? currentLiveAuction?.auction_name
                        : "--")}
                  </span>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <button
                    className="btn btn-primary go-back-button"
                    onClick={() => GoBackToAuctionList()}
                  >
                    <Link to="auction" className="mui-link">
                      <span style={{ color: "var(---2170ff-p-)" }}>
                        <ArrowBackIosNewIcon /> Go Back
                      </span>
                    </Link>
                  </button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <button
                    className="btn btn-primary go-back-button"
                    style={{ border: "1px solid #2170ff" }}
                    type="button"
                  >
                    <Link
                      to="details/live"
                      target="_blank"
                      className="mui-link"
                    >
                      <span style={{ color: "#2170ff" }}>
                        <img src={AuctionLiveImg} className="live-icon" /> Go To
                        Live Auction
                      </span>
                    </Link>
                  </button>
                </Grid>
              </Grid>
            </Grid>
            <Paper elevation={1} className="editable-board-container">
              <div className="img-back-grd"></div>

              {currentLiveAuction && currentLiveAuction?.auctionId ? (
                <Grid
                  container
                  alignItems="center"
                  style={{ position: "relative" }}
                >
                  <Grid item md={9} sm={12}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={1}
                        sm={2}
                        xs={12}
                        style={{ margin: "auto 0px" }}
                      >
                        <img
                          src={currentLiveAuction?.image}
                          className="image"
                        />
                      </Grid>
                      <Grid item xl={2} lg={3} md={4} sm={5} xs={12}>
                        <div className="lable">Auction Name</div>
                        {editMode ? (
                          <textarea
                            className="field __edit"
                            name="name"
                            style={{ fontSize: "1em" }}
                            readOnly={!editMode}
                            spellcheck="false"
                            value={values.name}
                            onChange={handleInputChange}
                            rows="1"
                          >
                            {values.name}
                          </textarea>
                        ) : (
                          <p className="field __view show-wrap-text mw200">
                            {values.name}
                          </p>
                        )}
                        {isOwner() &&
                        currentLiveAuction?.status === AUCTION_DRAFT ? (
                          <div style={{ display: "flex", marginTop: "1em" }}>
                            <CommonButton
                              className={
                                "btn btn-primary go-back-button " +
                                (editMode === true ? "__save" : "__edit")
                              }
                              style={{ height: "30px" }}
                              variant="contained"
                              type="submit"
                              onClick={clickToggleBtn}
                              loader={saveLoader}
                              loaderSize={14}
                              text={editMode ? "Save Changes" : "Edit "}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item lg={4} sm={5} xs={12}>
                        <div className="lable">Description</div>
                        <div
                          id="description__view"
                          className={
                            "field" +
                            " " +
                            (editMode === true
                              ? "__edit"
                              : "__view show-wrap-text")
                          }
                          // truncate="17"
                        >
                          <Tooltip
                            title={values.description}
                            placement="left-start"
                          >
                            <span
                              className="show__less__text "
                              style={{ display: "inline" }}
                            >
                              {values.description}
                            </span>
                          </Tooltip>
                          <span
                            className="show__complete__text"
                            style={{ display: "none" }}
                          >
                            {values.description}
                          </span>
                          {/* <span className="show__more__link">...more</span> */}
                        </div>
                        <textarea
                          id="description__edit"
                          className={
                            "field" +
                            " " +
                            (editMode === true ? "__edit" : "__view")
                          }
                          readOnly={!editMode}
                          spellcheck="false"
                          name="description"
                          value={values.description}
                          onChange={handleInputChange}
                          rows="4"
                          style={{ display: "none" }}
                        >
                          {values.description}
                        </textarea>
                      </Grid>
                      <Grid item lg={2} md={6} sm={6} xs={12}>
                        <div className="lable">Start Date</div>
                        <div
                          className={
                            "datetime-picker-cotainer" +
                            " " +
                            (editMode === true ? "__edit" : "__view")
                          }
                        >
                          <Controls.DateTimePicker
                            name="startDate"
                            readOnly={!editMode}
                            value={startDate}
                            onChange={setStartDate}
                            format="DD-MM-YYYY"
                          />
                        </div>
                        <div className="lable">End Date</div>
                        <div
                          className={
                            "datetime-picker-cotainer" +
                            " " +
                            (editMode === true ? "__edit" : "__view")
                          }
                        >
                          <Controls.DateTimePicker
                            name="endDate"
                            readOnly={!editMode}
                            value={endDate}
                            onChange={setEndDate}
                            minDate={startDate}
                            format="DD-MM-YYYY"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3} sm={12}>
                    <div className="lable">Delivery Date</div>
                    <div
                      className={
                        "datetime-picker-cotainer" +
                        " " +
                        (editMode === true ? "__edit" : "__view")
                      }
                    >
                      <Controls.BasicDatePicker
                        name="deliveryDate"
                        readOnly={!editMode}
                        value={deliveryDate}
                        onChange={setDeliveryDate}
                        format="DD-MM-YYYY"
                        className="mui-date-picker-reverse"
                      />
                    </div>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="overflow-h"
                    >
                      <img
                        src={
                          currentLiveAuction?.createAuction?.image_upload ||
                          userImage
                        }
                        style={{
                          width: "2.7em",
                          height: "2.7em",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                      <div className="ml-2">
                        <div className="lable">Auction Owner</div>
                        <div
                          className="field __view"
                          style={{ marginBottom: "0px" }}
                        >
                          {currentLiveAuction?.createAuction?.first_name +
                            " " +
                            currentLiveAuction?.createAuction?.last_name}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                // <div className=" cu-shimmer w-100 mh170 br10 h20"></div>
                <AuctionBannerShimmer />
              )}
            </Paper>
          </div>
        </div>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <DuplicateAuctionDialog
        duplicateAuctionModal={duplicateAuctionModal}
        setDuplicateAuctionModal={setDuplicateAuctionModal}
        auctionData={currentLiveAuction}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(AuctionBanner));
