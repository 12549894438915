import { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import { InputAdornment, TextField } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import Search from "@material-ui/icons/Search";
import Moment from "moment";
import { store } from "../../../store/store";
import { Api } from "../../../api/Api";
import {
  COMAPNY_API,
  POST_ITEM_UPLOAD_DOCUMENT_API,
} from "../../../constants/ApiUrl";
// import "../../styles/details-suplliers.css"

export default function AddFromLibraryPopup(props) {
  const { setOpenPopupLib, getAllAttachments, setNotify, itemDetail } = props;

  const [checked, setChecked] = useState([]);

  const [checkedAll, setCheckedAll] = useState(false);
  const [docsList, setDocsList] = useState([]);

  const checkallBoxes = (e) => {
    if (!e.target.value) {
      setCheckedAll(false);
      const checked = [];
      const docLi = [...checked];
      setChecked(docLi);
    } else {
      setCheckedAll(true);
      let neItems = [];
      docsList.filter((i) => {
        checked.push(i._id);
      });
      const docLi = [...checked];
      setChecked(docLi);
    }
  };

  const addDocumentFromLib = async (searchText) => {
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${COMAPNY_API}/${companyId}/library${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allDocs = response.data.library;
          setDocsList(allDocs);
        } else {
          setDocsList([]);
        }
      } else {
        setDocsList([]);
      }

      // await axiosInstance
      //   .get(`api/buyer/company/${companyId}/library${queryString}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let allDocs = response.data.library;
      //       setDocsList(allDocs);
      //     } else {
      //       setDocsList([]);
      //       setNotify({
      //         isOpen: true,
      //         message: "Please add document on settings.",
      //         type: "error",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setDocsList([]);
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const columns = [
    {
      label: "Template Document Name",
    },
    {
      label: "Date Added",
    },
    {
      label: (
        <div className="checkbox checkbox-primary">
          <Controls.Checkbox
            name="checkall"
            color="primary"
            value={checkedAll}
            checked={checkedAll}
            onChange={checkallBoxes}
          />
        </div>
      ),
    },
  ];

  const checkBoxHandle = (e) => {
    const { name, value } = e.target;
    if (value) {
      const index = checked.findIndex((x) => x === name);
      if (!checked || index == -1) {
        checked.push(name);
        const neItems = [...checked];
        setChecked(neItems);
      } else {
        setChecked(checked.filter((item) => item !== name));
      }
    } else {
      const index = checked.findIndex((x) => x === name);
      if (index > -1) {
        checked.splice(index, 1);
        const neItems = [...checked];
        setChecked(neItems);
      }
    }
  };

  const attachSelectedDocs = async () => {
    if (checked && checked.length > 0) {
      const { auth } = store.getState();
      const userId = auth?.auth?.user?._id;
      let formData = new FormData();
      formData.append("id", itemDetail?._id);
      formData.append("library", JSON.stringify(checked));
      formData.append("createdBy", userId);
      formData.append("type", "library");
      const response = await Api(
        POST_ITEM_UPLOAD_DOCUMENT_API,
        formData,
        "postMultipart"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setOpenPopupLib(false);
          getAllAttachments();
        }
      }

      // await axiosInstance
      //   .post(`api/buyer/item/document/uploadDocument`, formData)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       setOpenPopupLib(false);
      //       getAllAttachments();
      //       setNotify({
      //         isOpen: true,
      //         message: "Attachted successfully.",
      //         type: "success",
      //       });
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: response?.data?.responseDescription,
      //         type: "error",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const requestSearch = (e) => {
    const searchText = e.target.value;
    addDocumentFromLib(searchText);
  };

  useEffect(() => {
    addDocumentFromLib();
  }, []);
  return (
    <>
      {/* <Grid container spacing={2}> */}
      <Grid container spacing={2}>
        <Box sx={{ display: "flex", p: 0 }} className="SearchBox">
          <Box sx={{ p: 1, flexGrow: 1 }} style={{ padding: "0" }}>
            <Box style={{ position: "relative" }}>
              <TextField
                onChange={requestSearch}
                placeholder="Search Using Keyword"
                className="SearchInputList"
                style={{
                  backgroundImage: "url(/assets/icons/search-1@2x.png)",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
        <TableContainer component={Paper} className="T3columns">
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {docsList.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>
                    <AssignmentOutlinedIcon />
                    <span
                      style={{ color: "#2170FF" }}
                      className="TextAfterImage"
                    >
                      {item && item.document ? item?.document?.name : ""}
                    </span>
                  </TableCell>
                  <TableCell>
                    {Moment(item.createdDate).format("DD-MM-YYYY LT")}
                  </TableCell>

                  <TableCell>
                    <div className="checkbox checkbox-primary">
                      <Controls.Checkbox
                        name={item?._id}
                        color="primary"
                        value={checked.includes(item?._id) ? item?._id : ""}
                        checked={checked.includes(item?._id) ? item?._id : ""}
                        onChange={checkBoxHandle}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <p className="table-record-count-libarary">
          {docsList.length} Documents Found
        </p>
      </Grid>

      <Box
        className="BottomModal"
        display="flex"
        p={1}
        bgcolor="background.paper"
      >
        <Grid istem xs={4} className="BottomModal-left"></Grid>
        <Grid item xs={8} className="BottomModal-right">
          <Box p={0}>
            <Controls.Button
              text="Cancel"
              disableElevation
              onClick={() => {
                setOpenPopupLib(false);
              }}
              style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
              color="default"
              className="btn-mui"
            />
          </Box>
          <Box p={0}>
            <Controls.Button
              disableElevation
              text="Attach"
              className="btn-mui"
              onClick={() => attachSelectedDocs()}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
}
