import React, { useState, useEffect } from "react";
import { useForm, Form } from "../../components/useForm";
import { makeStyles } from "@material-ui/styles";
import Notification from "../../components/Notification";
import {
  Stack,
  InputLabel,
  TextField,
  useMediaQuery,
  Grid,
} from "@mui/material";
import Controls from "../../components/controls/Controls";
import "./style/form.css";
import { Box } from "@mui/system";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { toast, Slide } from "react-toastify";

const initialFValues = {
  link: "",
  title: "",
  notes: "",
};

const useStyles = makeStyles((theme) => ({
  errMsg: {
    color: "red",
    fontSize: "14px",
    margin: "3px 0",
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    padding: ".5rem 2rem",
    fontWeight: "700",
  },
  input: {
    display: "none",
  },
}));

const Links = (props) => {
  const {
    setOpen,
    setLinkData,
    linkData,
    documentDataForList,
    setDocumentDataForList,
    linkDataArray,
    setLinkDataArray,
  } = props;
  const classes = useStyles();
  const handleClose = () => setOpen(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("link" in fieldValues)
      temp.link = fieldValues.link
        ? !regex.test(fieldValues.link)
          ? "Please enter valid link"
          : ""
        : "This field is required.";
    // temp.link = fieldValues.link ? "" : "This field is required.";

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";

    if ("notes" in fieldValues)
      temp.notes = fieldValues.notes ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (regex.test(values.link)) {
        linkDataArray.push({
          ...values,
          tempId: Math.floor(Date.now() / 1000),
        });
        const newDataLinks = [...linkDataArray];
        setLinkDataArray(newDataLinks);
        documentDataForList.push(values);
        const neItems = [...documentDataForList];
        setDocumentDataForList(neItems);
        handleClose();
      } else {
        toast.error(ValidationMessage.validLink, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    } else {
      toast.error(ValidationMessage.validLink, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const isXlargScreen = useMediaQuery("(min-width:1536px)");

  const formContainer = {
    width: isXlargScreen ? "25vw" : "30vw",
  };

  useEffect(() => {
    // setValues(linkData);
  }, []);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        style={formContainer}
        className="create-form-container"
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel id="link-name-input">Link</InputLabel>
              <TextField
                fullWidth
                labelId="link-name-input"
                name="link"
                value={values.link}
                onChange={handleInputChange}
                error={errors.link}
                inputProps={{ maxLength: 40 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="title-name-input">Title</InputLabel>
              <TextField
                className="mui-input"
                fullWidth
                labelId="title-name-input"
                name="title"
                value={values.title}
                onChange={handleInputChange}
                error={errors.title}
                inputProps={{ maxLength: 40 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="notes-input">Notes</InputLabel>
              <Controls.Input
                className="mui-input fix-h-8em"
                fullWidth
                labelId="notes-input"
                name="notes"
                value={values.notes}
                onChange={handleInputChange}
                error={errors.notes}
                inputProps={{ maxLength: 499 }}
              />
            </Grid>
          </Grid>
        </Box>

        <Stack
          direction="row"
          className="BottomModal"
          style={{ justifyContent: "space-between", display: "flex" }}
        >
          <Controls.Button
            variant="contained"
            disableElevation
            className="btn-mui fix-8em"
            onClick={handleClose}
            text="Cancel"
            style={{
              backgroundColor: "#E2F2FF",
              color: "#2170FF",
              marginRight: "2.5rem",
            }}
          />
          <Controls.Button
            variant="contained"
            disableElevation
            className="btn-mui fix-w-12em"
            // onClick={handleClose}
            type="submit"
            text="Save"
          />
        </Stack>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Links;
