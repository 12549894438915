/*eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, InputAdornment, Paper, TextField } from "@material-ui/core";
import {
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Search } from "@material-ui/icons";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Notification from "../../../../components/Notification";
import TablePaginationBox from "../../../../components/TablePaginationBox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import "../../styles/create-auction-step4.css";
import "../../../../styles/table.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentLiveAuction } from "../../../../store/selectors/auctionSelector";
import dummyItem from "../../../../assets/settings/itemCoverDummy.png";
import {
  DELETE_AUCTION_ITEM_API,
  GET_ITEM_TAB_API,
  POST_AUCTION_EDIT_ITEM_API,
} from "../../../../constants/ApiUrl";
import { Api } from "../../../../api/Api";
const columns = [
  {
    label: "ID",
  },
  {
    label: "Image",
  },
  {
    label: "Name",
  },
  {
    label: "Group",
  },
  {
    label: "Category",
  },
  {
    label: "Manufacturer",
  },
  {
    label: "Model",
  },
  {
    label: "Unit",
  },
  {
    label: "Quantity",
  },
  {
    label: "Budget",
  },
  {
    label: "",
  },
];

const ItemTab = (props) => {
  const { currentLiveAuction, isEditor, isOwner } = props;
  const tableRowsRef = useRef([]);
  const { smScreen } = useWindowDimensions();
  const [itemList, setItemList] = useState([]);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const emptyRows =
    page > 0 ? Math.max(0, page * rowsPerPage - itemList.length) : 0;

  const numOfPages = Math.ceil(itemList.length / rowsPerPage);

  const editableValues = [
    {
      name: "quantity",
      match: "quantity",
    },
    {
      name: "budget",
      match: "budget",
    },
  ];

  const setValueForEditableCell = (field, items) => {
    editableValues.map((item) => {
      let fieldToUpdate = field.querySelector(`[name='${item.name}']`);
      if (fieldToUpdate !== null) {
        fieldToUpdate.value = items[`${item.match}`]
          ? items[`${item.match}`]
          : "";
      }
    });
  };

  const toggleEditableRowMode = (ref, item, editMode = false) => {
    ref.classList.toggle("__select");
    // toggle option list
    const optionBoxes = ref.querySelectorAll(".option-cell");
    for (let index = 0; index < optionBoxes.length; index++) {
      const box = optionBoxes[index];
      box.classList.toggle("__active");
    }
    // toggle editable field
    const fieldBoxes = ref.querySelectorAll(".editable-field");
    let values = {};
    for (let index = 0; index < fieldBoxes.length; index++) {
      const field = fieldBoxes[index];
      field.classList.toggle("__active");
      if (editMode) {
        values = Object.assign(values, getValueFromEditableCell(field));
      } else {
        setValueForEditableCell(field, item);
      }
    }

    if (editMode) {
      values = Object.assign(values, { itemId: item?._id });
      sendEditableVaule(values);
    }
  };

  const sendEditableVaule = async (values) => {
    const payload = {
      quantity: values.quantity,
      budget: values.budget,
    };
    if (isOwner() || isEditor()) {
      const response = await Api(
        `${POST_AUCTION_EDIT_ITEM_API}/${currentLiveAuction?._id}/${values?.itemId}`,
        payload,
        "patch"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getItemList();
        }
      }

      // axiosInstance
      //   .patch(
      //     `api/buyer/company/auctions/editItem/${currentLiveAuction?._id}/${values?.itemId}`,
      //     payload
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       setNotify({
      //         isOpen: true,
      //         message: "Updated successfully.",
      //         type: "success",
      //       });
      //       getItemList();
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: response?.data?.responseDescription,
      //         type: "error",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //   });
    }
  };

  const getValueFromEditableCell = (field) => {
    let values = {};
    editableValues.map((item) => {
      let fieldToUpdate = field.querySelector(`[name='${item.name}']`);
      if (fieldToUpdate !== null) {
        values[fieldToUpdate.name] = fieldToUpdate.value;
      }
    });
    return values;
  };

  const getItemList = async (searchText) => {
    if (currentLiveAuction._id !== undefined) {
      let queryString = "";
      if (searchText) {
        queryString = `?search=${searchText}`;
      }
      const response = await Api(
        `${GET_ITEM_TAB_API}/${currentLiveAuction._id}?per_page=${rowsPerPage}&page=${page}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let auctionItemData = response.data.itemAllData;
          setItemList(auctionItemData);
        } else {
          setItemList([]);
        }
      } else {
        setItemList([]);
      }

      // axiosInstance
      //   .get(
      //     `api/buyer/auctions/AuctionItems/${currentLiveAuction._id}?per_page=${rowsPerPage}&page=${page}${queryString}`
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let auctionItemData = response.data.itemAllData;
      //       setItemList(auctionItemData);
      //     } else {
      //       setItemList([]);
      //     }
      //   })
      //   .catch((err) => {
      //     setItemList([]);
      //   });
    }
  };

  const requestSearch = (e) => {
    const searchText = e.target.value;
    getItemList(searchText);
  };

  useEffect(() => {
    tableRowsRef.current = tableRowsRef.current.slice(0, itemList.length);

    getItemList();
  }, [currentLiveAuction]);

  const deleteItemHandler = async (id) => {
    const response = await Api(
      `${DELETE_AUCTION_ITEM_API}/${currentLiveAuction._id}/${id}`,
      {},
      "delete"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        getItemList();
      }
    }
  };

  return (
    <>
      <Paper elevation={0} square>
        <Grid container>
          <Grid
            item
            md={6}
            xs={12}
            style={{ textAlign: smScreen ? "center" : "start" }}
          >
            <TextField
              onChange={requestSearch}
              placeholder="Search Using Keyword or Tags..."
              className="select-box select-box-primary search-box"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            style={{
              textAlign: smScreen ? "center" : "end",
              marginTop: smScreen ? "10px" : "0px",
            }}
          >
            <Select
              value={rowsPerPage}
              onChange={(event) => setRowsPerPage(event.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className="items-count-dropdown"
            >
              <MenuItem value={10}>Show 10 entries</MenuItem>
              <MenuItem value={20}>Show 20 entries</MenuItem>
              <MenuItem value={30}>Show 30 entries</MenuItem>
              <MenuItem value={40}>Show 40 entries</MenuItem>
              <MenuItem value={50}>Show 50 entries</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Paper elevation={0} square>
          <TableContainer
            className="table-container editable-table"
            component={Paper}
          >
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "700" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? itemList.slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                  : itemList
                ).map((item, index) => (
                  <TableRow
                    key={item._id}
                    ref={(el) => (tableRowsRef.current[index] = el)}
                    className="editable-row"
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: "center" }}
                    >
                      {item?.itemId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <img
                        style={{
                          width: "2.7em",
                          height: "2.7em",
                          objectFit: "cover",
                        }}
                        src={item?.image_upload || dummyItem}
                        className="table-item-img"
                      />
                    </TableCell>
                    <TableCell style={{ color: "#2170FF" }}>
                      {item?.item_name}
                    </TableCell>
                    <TableCell>{item?.group}</TableCell>
                    <TableCell>{item?.category}</TableCell>
                    <TableCell>{item?.manufacturer}</TableCell>
                    <TableCell>{item?.model}</TableCell>
                    <TableCell>{item?.unit}</TableCell>
                    <TableCell>
                      <Box className="editable-field __active">
                        {item?.quantity}
                      </Box>
                      <Box className="editable-field">
                        <TextField
                          name="quantity"
                          variant="outlined"
                          defaultValue={item.quantity}
                          fullWidth
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="editable-field __active">
                        {item?.budget}
                      </Box>
                      <Box className="editable-field">
                        <TextField
                          name="budget"
                          variant="outlined"
                          defaultValue={item.budget}
                          fullWidth
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      {isOwner() || isEditor() ? (
                        <>
                          <Box className="option-cell __active">
                            <EditIcon
                              className="icon __edit"
                              onClick={() =>
                                toggleEditableRowMode(
                                  tableRowsRef.current[index],
                                  item
                                )
                              }
                            />
                            <DeleteOutlineIcon
                              className="icon __edit clr-ff0000"
                              onClick={() => deleteItemHandler(item._id)}
                            />
                          </Box>
                          <Box className="option-cell">
                            <CheckIcon
                              className="icon __check"
                              onClick={() =>
                                toggleEditableRowMode(
                                  tableRowsRef.current[index],
                                  item,
                                  true
                                )
                              }
                            />
                            <CloseIcon
                              className="icon __close"
                              onClick={() =>
                                toggleEditableRowMode(
                                  tableRowsRef.current[index],
                                  item
                                )
                              }
                            />
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {itemList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="text-center">
                      No records found <br />
                      {/* <i style={{ color: "var(---2170ff-p-)" }}>
                        Please add some items
                      </i> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
            <TablePaginationBox
              label={itemList.length + " Items Found"}
              numOfPages={numOfPages}
              onChangePage={(event, newPage) => setPage(newPage)}
              page={page}
            />
          </TableContainer>
          <Notification notify={notify} setNotify={setNotify} />
        </Paper>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(ItemTab));
