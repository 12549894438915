import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProjectEvents from "./components/projectEvents";
import ProjectMessage from "./components/projectMessage";
import ProjectUsers from "./components/projectUsers";
import ProjectAttachment from "./components/projectAttachment";
import ProjectComments from "./components/projectComments";
import ProjectBudget from "./components/projectBudget";
// import ProjectBanner from "./components/ProjectBanner";
import { Paper, Typography, Box, Grid, makeStyles } from "@material-ui/core";
import useWindowDimensions from "../../components/useWindowDimensions";
import "./styles/budget.css";

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .comment-number": {
      marginLeft: "6px",
      padding: "6px",
      borderRadius: "100%",
      fontSize: "14px",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#1976d2",
      color: "#fff",
      backgroundColor: "blue",
      margin: "7px",
      borderRadius: "25px",
      textTransform: "capitalize",
      "& .comment-number": {
        backgroundColor: "#fff",
        color: "#00f",
      },
    },
    "& .MuiTab-root": {
      fontWeight: 600,
      color: "#000",
      textTransform: "capitalize",
      padding: "0px 15px",
      minHeight: "2.7em",
      "& .comment-number": {
        backgroundColor: "#00f",
        color: "#fff",
        display: "block",
        width: "2em",
        height: "2em",
      },
    },
  },
  budgetTabCont: {
    // "& .MuiPaper-root": {
    //    backgroundColor: "#e5e5e5"
    // }
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-label={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProjectDetails = (props) => {
  const classes = useStyles();
  const { selectedProject, setNotify } = props;
  const [value, setValue] = useState(0);
  const BudgetTabIX = 3;
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const [projectDetails, setProjectDetails] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getMargeinTopValue = () => {
    if (xsScreen) {
      return "37em";
    } else if (mdScreen || smScreen) {
      return "6em";
    } else if (lgScreen) {
      return "0em";
    } else if (xlScreen) {
      return "0em";
    }
  };

  return (
    <>
      <Grid container className="tab-header-container">
        <Box
          sx={{
            marginTop: getMargeinTopValue(),
            bgcolor: "background.paper",
            borderRadius: "25px !important",
          }}
        >
          <Tabs
            className={classes.tabs}
            id="project-tab-list"
            value={value}
            onChange={handleChange}
            // className="tab-header"
          >
            <Tab label="Auctions" />
            {/* <Tab label="Messages" /> */}
            <Tab label="Users" />
            <Tab label="Budget" />
            <Tab label="Attachment" />
            <Tab
              label={
                <div style={{ margin: "0px 8px", display: "inline-flex" }}>
                  <span style={{ margin: "auto" }}>Comments</span>
                  <span className="comment-number">
                    {selectedProject?.commentCount}
                  </span>
                </div>
              }
            />
          </Tabs>
        </Box>
      </Grid>

      <Paper
        className={
          "tab-body-container " +
          (value === BudgetTabIX
            ? "page-chart-container__hide"
            : "page-chart-container__view")
        }
      >
        <TabPanel value={value} index={0}>
          <ProjectEvents
            selectedProject={selectedProject}
            setNotify={setNotify}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <ProjectMessage />
        </TabPanel> */}
        <TabPanel value={value} index={1}>
          <ProjectUsers selectedProject={selectedProject} />
        </TabPanel>
        <TabPanel value={value} index={2} className="project-buget-container">
          <ProjectBudget selectedProject={selectedProject} />
        </TabPanel>
        <TabPanel value={value} index={3} className="project-attachment-tab">
          <ProjectAttachment selectedProject={selectedProject} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ProjectComments selectedProject={selectedProject} />
        </TabPanel>
      </Paper>
    </>
  );
};

export default ProjectDetails;
