import React, { useEffect, useState } from "react";
import { useForm, Form } from "../../components/useForm";
import FormSteps from "../../components/Formsteps";
import { Box, Grid } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import Controls from "../../components/controls/Controls";
import { useDispatch } from "react-redux";
import { FormStep1Add } from "../../store/actions/auctionAction";
import { store } from "../../store/store";
import Notification from "../../components/Notification";
import "./styles/create-auction-step1.css";
import ConfirmationDailog from "./components/confirmationDailog";
import CloseIcon from "@mui/icons-material/Close";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { toast, Slide } from "react-toastify";
import { isValidDate } from "../../utility/utils";

const initialValues = {
  name: "",
  description: "",
  // startDate: Date.now(),
  // endDate: Date.now(),
};

const EnglishAuctionPopup = (props) => {
  const { setOpenPopupEnglishAuction, setOpenPopupStep2, stepLabels } = props;
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() + 1 * 60000)
  );
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openConfrmationDailog, setOpenConfrmationDailog] = useState(false);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : "This field is required.";
    if ("startDate" in fieldValues)
      temp.startDate = fieldValues.startDate ? "" : "This field is required.";
    if ("endtDate" in fieldValues)
      temp.endtDate = fieldValues.endtDate ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (
        startDate &&
        endDate &&
        isValidDate(startDate) &&
        isValidDate(endDate)
      ) {
        values.name = values.name.trim();
        values.startDate = startDate;
        values.endDate = endDate;
        const Difference_In_Time = endDate.getTime() - startDate.getTime();
        const Days = Difference_In_Time / (1000 * 3600 * 24);
        const Mins = Difference_In_Time / (60 * 1000);
        const Hours = Mins / 60;

        if (
          Math.ceil(Mins) >= 1 &&
          // Math.round(Hours) <= 24 &&
          Math.ceil(Days) <= 1
        ) {
          dispatch(FormStep1Add(values));
          setOpenPopupEnglishAuction(false);
          setOpenPopupStep2(true);
        } else {
          toast.error(ValidationMessage.auctionTimeLimit, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      } else {
        toast.error(ValidationMessage.startEndDate, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    }
  };

  const discardChanges = () => {
    setOpenConfrmationDailog(true);
  };

  useEffect(() => {
    const { auction } = store.getState();
    if (auction && auction.AuctionForm1.name) {
      const Obj = {
        name: auction.AuctionForm1.name,
        description: auction.AuctionForm1.description,
      };
      if (auction && auction.AuctionForm1.startDate) {
        setStartDate(auction.AuctionForm1.startDate);
      }
      if (auction && auction.AuctionForm1.endDate) {
        setEndDate(auction.AuctionForm1.endDate);
      }
      setValues(Obj);
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <p className="profile-title">Create English Auction</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <CloseIcon
            onClick={() => discardChanges()}
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <Form onSubmit={handleSubmit} className="create-form-container">
        <Grid container justifyContent="center">
          <Grid item md={11} xs={12}>
            <FormSteps steps={stepLabels} currStep={1} className="mt-5 mb-5" />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="mt-3">
          <Grid item xs={12}>
            <InputLabel id="name-input">Name</InputLabel>
            <Controls.Input
              name="name"
              // label="name-input"
              fullWidth
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              inputProps={{ maxLength: 60 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="desc-input">Description</InputLabel>
            <Controls.Input
              // label="desc-input"
              name="description"
              value={values.description}
              onChange={handleInputChange}
              error={errors.description}
              className="fix-h-20em"
              inputProps={{ maxLength: 499 }}
            />
          </Grid>
          <Grid item xs={6} className="datetime-picker-cotainer">
            <InputLabel id="name-input">Start Date & Time</InputLabel>
            <Controls.DateTimePicker
              name="startDate"
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
                setEndDate(new Date(e?.getTime() + 30 * 60 * 1000));
              }}
              format="DD-MM-YYYY"
              error={errors.startDate}
            />
          </Grid>
          <Grid item xs={6} className="datetime-picker-cotainer">
            <InputLabel id="name-input">End Date & Time</InputLabel>
            <Controls.DateTimePicker
              name="endDate"
              // value={values.endDate}
              value={endDate}
              // onChange={handleInputChange}
              onChange={setEndDate}
              minDate={startDate}
              format="DD-MM-YYYY"
              error={errors.endDate}
            />
          </Grid>
        </Grid>
        <Box
          className="BottomModal"
          display="flex"
          p={1}
          bgcolor="background.paper"
          style={{ marginTop: "20px" }}
        >
          <Grid container>
            <Grid item sm={4} className="BottomModal-left">
              <Controls.Button
                text="Cancel"
                disableElevation
                onClick={() => {
                  setOpenPopupEnglishAuction(false);
                }}
                style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                color="default"
                className="btn-mui fix-w-12em"
              />
            </Grid>
            <Grid item xs={8} className="BottomModal-right">
              <Controls.Button
                type="submit"
                disableElevation
                text="Next"
                className="btn-mui fix-w-12em"
              />
            </Grid>
          </Grid>
        </Box>
      </Form>

      <ConfirmationDailog
        openConfrmationDailog={openConfrmationDailog}
        setOpenConfrmationDailog={setOpenConfrmationDailog}
        setDiscardAndClose={setOpenPopupEnglishAuction}
        eraseEverything={false}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default EnglishAuctionPopup;
