import * as React from "react";
import { Grid } from "@material-ui/core";
import CollapsedPage from "../../../components/collapsedPage";
import SupplierListDetails from "./supplierListDetails";
import useWindowDimensions from "../../../components/useWindowDimensions";
import SupplierEditboardDetails from "./supplierEditboardDetails";
import "../styles/supplier-list-details.css";

export default function DetailsSlider(props) {
  const {
    openPopup,
    setOpenPopup,
    supplier,
    getAllItemsByCompanyName,
    setNotify,
  } = props;
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const getBodyWidth = () => {
    if (xlScreen) {
      return 60;
    } else if (xsScreen) {
      return 90;
    } else if (smScreen) {
      return 90;
    } else if (mdScreen) {
      return 80;
    } else if (lgScreen) {
      return 75;
    }
  };

  return (
    <CollapsedPage
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      bodyWidth={getBodyWidth()}
      getAllItemsByCompanyName={getAllItemsByCompanyName}
    >
      <Grid
        container
        className="supplier-list-details-container"
        style={{ backgroundColor: "#fff", minHeight: "100vh" }}
      >
        <Grid
          item
          lg={9}
          sm={8}
          xs={12}
          className={"p-5 " + (!xsScreen ? "scroll-page" : "")}
        >
          <SupplierListDetails supplier={supplier} setNotify={setNotify} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={4}
          xs={12}
          style={{ backgroundColor: "#F8FCFF" }}
          className={"p-5 " + (!xsScreen ? "scroll-page" : "")}
        >
          <SupplierEditboardDetails supplier={supplier} setNotify={setNotify} />
        </Grid>
      </Grid>
    </CollapsedPage>
  );
}
