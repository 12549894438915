import {
  GET_AUCTION_DETAILS,
  REFRESH_LIVE_AUCTION_SUPPLIER,
  REFRESH_LIVE_BIDDING_DATA_SUPPLIER,
} from "../../constant/supplierAspect/auctionConstant";

const initialState = {
  auction: {},
  refreshLiveBiddingData: false,
  refreshLiveAuction: false,
};

export default function AuctionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AUCTION_DETAILS:
      return {
        ...state,
        auction: action.payload,
      };

    case REFRESH_LIVE_BIDDING_DATA_SUPPLIER:
      return {
        ...state,
        refreshLiveBiddingData: action.payload,
      };

    case REFRESH_LIVE_AUCTION_SUPPLIER:
      return {
        ...state,
        refreshLiveAuction: action.payload,
      };


    default:
      return state;
  }
}
