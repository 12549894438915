import { Box, Grid, Typography } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import CloseIcon from "@mui/icons-material/Close";

import { Form, useForm } from "../../components/useForm";
import Modal from "@mui/material/Modal";
import "../../styles/filter-box.css";

import { Divider, InputLabel, TextField } from "@mui/material";
// import "./styles/create-auction-step0.css";
import "../Auction/styles/create-auction-step0.css";
import { useEffect, useState } from "react";
import { store } from "../../store/store";
import { ValidationMessage } from "../../constants/ValidationMessage";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { withStyles } from "@material-ui/styles";
import { makeStyles } from "@material-ui/styles";
import CommonLinkPopup from "../../components/Modals/CommonLinkPopup";
import { bytesToSize } from "../../utility/utils";
import attachIcon from "../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../assets/icons/CloseX.png";
import CommonDocumentModal from "../../components/Modals/CommonDocumentModal";
import Notification from "../../components/Notification";
import { useSelector } from "react-redux";
import { Api } from "../../api/Api";
import { COMAPNY_VERSION_API, POST_ADD_CONTRACT_API } from "../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { toast, Slide } from "react-toastify";

const BlueText = withStyles({
  root: {
    color: "#2170FF;",
    fontWeight: "600",
    cursor: "pointer",
  },
})(Typography);
const useStyles = makeStyles((theme) => ({
  errMsg: {
    color: "red",
    fontSize: "14px",
    margin: "3px 0",
  },
  imgPreview: {
    width: "100%",
    height: "460px",
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#ccc",
    padding: "100px",
    border: "1px solid #000",
    background: "#cccccc",
  },

  customFileUpload: {
    color: "#55d6d6",
    fontSize: "22px",
    fontWeight: "500",
    padding: "6px 12px",
    cursor: "pointer",
  },
  files: { padding: "5px" },
  customeFileUpload: {
    position: "absolute",
    padding: "47px 20px",
    cursor: "pointer",
  },
  formPart1: {
    "& .MuiFormControl-root": {
      width: "90%",
    },
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    padding: ".5rem 2rem",
    fontWeight: "700",
    marginLeft: "0px",
    marginTop: "0px",
  },
  input: {
    display: "none",
  },
  attachmentText: {
    fontWeight: "700",
    fontSize: "1.3em",
  },
  attachment: {
    margin: "2rem 0",
  },
  Button: {
    padding: ".4rem 3rem",
    borderRadius: "6px",
    fontWeight: "700",
    fontSize: "14px",
  },
  imageInfo: {
    color: "#A19B9D",
    fontSize: "12px",
    maxWidth: "10.5rem",
  },
  imageInfoTwo: {
    color: "#A19B9D",
    fontSize: "12px",
  },
  modalContent: {
    width: "100%",
  },
  addLink: {
    color: "#1E1D1D",
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "1.5em",
  },
  dragAndDrop: {
    fontWeight: "700",
    fontSize: "18px",
  },
  attachmentBoxes: {
    padding: "10px",
  },
}));

const AddContractModal = ({
  openPopup,
  clickClosePopup,
  setFullLoder,
  getAllContractList,
}) => {
  const classes = useStyles();
  const user = useSelector((state) => state?.auth?.auth?.user);

  const initialFValues = {
    title: "",
    supplierId: "",
    startDate: "",
    endDate: "",
    companyId: user?.company?._id
      ? {
          id: user?.company?._id,
          name: user?.first_name + " " + user?.last_name,
        }
      : "",
  };

  const [dropdownSupplierList, setDropdownSupplierList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateError, setDateError] = useState("");
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [linkDataArray, setLinkDataArray] = useState([]);
  const [documentDataForList, setDocumentDataForList] = useState([]);
  
  const [finalAttachmentDocs, setFinalAttachmentDocs] = useState([]);
  
  const [documentFile, setDocumentFile] = useState({});
  

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues) {
      if (fieldValues.title && fieldValues.title.length > 0) {
        if (fieldValues.title.length < 3) {
          temp.title = "Minimun 3 character required";
        } else {
          temp.title = "";
        }
      } else {
        temp.title = "This field is required.";
      }
    }

    if ("supplierId" in fieldValues)
      temp.supplierId = fieldValues.supplierId ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    getAllSupplierList();
    resetForm();
  }, []);

  // const getAllSuppliers = async () => {
  //   const state = store.getState();
  //   const companyId = state?.auth?.auth?.user?.company?._id;
  //   const response = await Api(
  //     `${COMAPNY_API}/${companyId}/suppliers?status=${AUCTION_ACTIVE}`,
  //     {},
  //     "get"
  //   );
  //   if (response.status === 200) {
  //     if (response.data.responseCode === "00") {
  //       let suppliersList = response.data.suppliers;
  //       let optionDropdown = [];
  //       suppliersList.map((obj) =>
  //         optionDropdown.push({
  //           name:
  //             obj?.main_contact?.first_name +
  //             " " +
  //             obj?.main_contact?.last_name,
  //           id: obj?._id,
  //         })
  //       );
  //       setDropdownSupplierList([...optionDropdown]);
  //     } else {
  //       setDropdownSupplierList([]);
  //     }
  //   } else {
  //     setDropdownSupplierList([]);
  //   }

  //   // await axiosInstance
  //   //   .get(`api/buyer/company/${companyId}/suppliers?status=Active`)
  //   //   .then((response) => {
  //   //     if (response.data.responseCode === "00") {
  //   //       let suppliersList = response.data.suppliers;
  //   //       let optionDropdown = [];
  //   //       suppliersList.map((obj) =>
  //   //         optionDropdown.push({
  //   //           name:
  //   //             obj?.main_contact?.first_name +
  //   //             " " +
  //   //             obj?.main_contact?.last_name,
  //   //           id: obj?._id,
  //   //         })
  //   //       );
  //   //       setDropdownSupplierList([...optionDropdown]);
  //   //     } else {
  //   //       setDropdownSupplierList([]);
  //   //     }
  //   //   })
  //   //   .catch((err) => {
  //   //     setDropdownSupplierList([]);
  //   //   });
  // };

  const getAllSupplierList = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(
      `${COMAPNY_VERSION_API}/${companyId}/suppliersActive`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.suppliers;

        let companyData = Object.values(allItems);
        let final = [];
        companyData.map((item) => final.push(item[item.length - 1]));
        final.map((item) => {
          item.name = item?.supplier_company?.company_name;
          item.id = item?.supplier_company?._id;
        });
        setDropdownSupplierList(final);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate() && dateValidationHandler()) {
      const link = [];
      if (linkDataArray && linkDataArray.length > 0)
        link.push(linkDataArray[0]?.link);

      let formData = new FormData();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      formData.append("title", values.title);
      formData.append("supplierCompanyId", values.supplierId);
      formData.append("companyId", values.companyId?.id);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      if (link && link.length > 0)
        formData.append("links", JSON.stringify(link));
      if (documentFile && documentFile.name)
        formData.append("attachment", documentFile);
      setFullLoder(true);
      const response = await Api(
        POST_ADD_CONTRACT_API,
        formData,
        "postMultipart"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          clickClosePopup();
          getAllContractList();
        } else {
          clickClosePopup();
        }
        setFullLoder(false);
      } else {
        setFullLoder(false);
      }

      // axiosInstance
      //   .post(`api/buyer/company/addContract`, formData, config)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       setNotify({
      //         isOpen: true,
      //         message: response?.data?.responseDescription,
      //         type: "success",
      //       });

      //       clickClosePopup();
      //       getAllContractList();
      //     } else {
      //       setNotify({
      //         isOpen: true,
      //         message: response?.data?.responseDescription,
      //         type: "success",
      //       });

      //       clickClosePopup();
      //     }
      //     setFullLoder(false);
      //   })
      //   .catch((err) => {
      //     setFullLoder(false);
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription
      //         ? err?.response?.data?.responseDescription
      //         : ValidationMessage.somethingWrong,
      //       type: "error",
      //     });
      //   });
      clickClosePopup();
    }
  };

  const dateValidationHandler = (start, end) => {
    if (start && end) {
      if (
        start.getDate() > end.getDate() ||
        start.getMonth() > end.getMonth() ||
        start.getFullYear() > end.getFullYear()
      ) {
        setDateError(ValidationMessage.endDateError);
        return false;
      } else {
        setDateError("");
        return true;
      }
    } else {
      setDateError("");
      return true;
    }
  };

  // const removeAttachedDocument = (docsData, index) => {
  //   
  //   if (docsData && docsData.link) {
  //     if (docsData && docsData.title) {
  //       const getIndex = linkDataArray.findIndex((o) => {
  //         return o.title == docsData.title;
  //       });
  //       if (getIndex > -1) {
  //         linkDataArray.splice(getIndex, 1);
  //         setLinkDataArray(linkDataArray);
  //       }
  //     }
  //   } else if (docsData && docsData.title) {
  //     if (docsData && docsData.document.lastModified) {
  //       const getIndex = finalAttachmentDocs.findIndex((o) => {
  //         return o.document.lastModified === docsData.document.lastModified;
  //       });
  //       if (getIndex > -1) {
  //         finalAttachmentDocs.splice(getIndex, 1);
  //         setFinalAttachmentDocs(documentDataForList);
  //       }
  //     }
  //   }
  //   documentDataForList.splice(index, 1);
  //   setDocumentDataForList(documentDataForList);
  //   toast.error(ValidationMessage.removeAttachment, {
  //     transition: Slide,
  //     autoClose: TOAST_AUTO_CLOSE,
  //   });
  // };

  const removeAttachedDocument = (index) => {
    const updatedDocumentDataForList = [...documentDataForList];
    
    updatedDocumentDataForList.splice(index, 1);
    setDocumentDataForList(updatedDocumentDataForList);
    // setFinalAttachmentDocs(updatedDocumentDataForList);
    toast.error("Attachment removed.", {
      transition: Slide,
      autoClose: TOAST_AUTO_CLOSE,
    });
  };

  return (
    <>
      <Modal
        open={openPopup}
        aria-label="create-auction-second-step-buyer-aspect"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Add Contract</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  clickClosePopup();
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Form onSubmit={handleSubmit} className="mt-5">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <InputLabel id="name-input">Contract Title</InputLabel>
                    <Controls.Input
                      name="title"
                      labelId="title-input"
                      fullWidth
                      value={values.title}
                      onChange={handleInputChange}
                      error={errors.title}
                      className="StepControlInput"
                      // inputProps={{ maxLength: 40 }}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel id="category-input">Supplier Name</InputLabel>
                    <Controls.Select
                      name="supplierId"
                      labelId="supplierId-input"
                      // value={values.supplier_company_name}
                      fullWidth
                      onChange={handleInputChange}
                      options={dropdownSupplierList}
                      // getOptionLabel={(option) =>
                      //   option ? option.supplier_company_name : ""
                      // }
                      error={errors.supplierId}
                      className="StepControlInput"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>
                      Start Date<span className="mandatorySign">*</span>
                    </InputLabel>
                    {/* <Controls.DatePicker
                      name="startDate"
                      value={values.startDate}
                      onChange={handleInputChange}
                      format="DD-MM-YYYY"
                      className="mui-date-picker-reverse"
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        variant="inline"
                        inputVariant="outlined"
                        value={startDate}
                        // disablePast
                        className="mui-input mui-date-picker-reverse"
                        format="MMM/dd/yyyy"
                        onChange={(newValue) => {
                          setStartDate(newValue);

                          if (newValue > endDate) {
                            setEndDate(newValue);
                          }
                          dateValidationHandler(newValue, newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>
                      End Date<span className="mandatorySign">*</span>
                    </InputLabel>
                    {/* <Controls.DatePicker
                      name="endDate"
                      value={values.endDate}
                      onChange={handleInputChange}
                      minDate={values.startDate}
                      format="DD-MM-YYYY"
                      className="mui-date-picker-reverse"
                      /> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        variant="inline"
                        inputVariant="outlined"
                        value={endDate}
                        minDate={startDate}
                        disablePast
                        className="mui-input mui-date-picker-reverse"
                        format="MMM/dd/yyyy"
                        onChange={(newValue) => {
                          setEndDate(newValue);
                          dateValidationHandler(startDate, newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {dateError && dateError.length > 0 && (
                    <p className="textfield-error-style contract-module">
                      {dateError}
                    </p>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <div className={`${classes.attachment} ContainerAttachment0`}>
              <Typography variant="body" className={classes.attachmentText}>
                Attachments
              </Typography>
              <Divider variant="middle" />
              {}
              {documentDataForList && documentDataForList.length > 0 ? (
                <Grid container style={{ alignItems: "flex-end" }} spacing={2}>
                  {documentDataForList.map((docs, index) => (
                    <Grid item md={4} sm={6} xs={12}>
                      <Box component="div" className="uploaded-files">
                        <Grid container>
                          <Grid item xs={2} style={{ textAlign: "center" }}>
                            <img src={attachIcon} alt="" className="icon" />
                          </Grid>
                          <Grid item xs={9} className="details">
                            <Grid container>
                              <Grid item xs={12}>
                                <box className="name">
                                  {docs?.document?.name || docs?.link}
                                </box>
                              </Grid>
                              <Grid item xs={12}>
                                {!docs?.link && (
                                  <box className="size">
                                    {bytesToSize(docs?.document?.size || 0)}
                                  </box>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className="cursor-p"
                            style={{ textAlign: "end", margin: "auto" }}
                            // onClick={(docs) =>
                            //   removeAttachedDocument(docs, index)
                            // }
                            onClick={() => removeAttachedDocument(index)}
                          >
                            <img src={closeIcon} alt="" />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div></div>
              )}
              <Box display="flex">
                <BlueText
                  variant="sub"
                  style={{ fontSize: "1.1em" }}
                  onClick={() => setShowLinkModal(true)}
                >
                  + Add Link
                </BlueText>

                <BlueText
                  variant="sub"
                  style={{ fontSize: "1.1em" }}
                  className="ml-4"
                  onClick={() => setShowDocumentModal(true)}
                >
                  + Add Document
                </BlueText>
              </Box>
            </div>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <InputLabel id="name-input">Owner</InputLabel>
                <Controls.Input
                  name="companyId"
                  labelId="companyId-input"
                  fullWidth
                  value={values.companyId.name}
                  onChange={handleInputChange}
                  error={errors.companyId}
                  className="StepControlInput"
                  // inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  disabled
                />
              </Grid>
            </Grid>
            <Box
              className="BottomModal"
              display="flex"
              justifyContent="center"
              p={1}
              bgcolor="background.paper"
            >
              <Grid item xs={6}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text="Add Contract"
                  className="btn-mui w-100"
                />
              </Grid>
            </Box>
          </Form>
        </Box>
      </Modal>
      <Notification notify={notify} setNotify={setNotify} />
      {showLinkModal && (
        <CommonLinkPopup
          open={showLinkModal}
          handleClose={() => setShowLinkModal(!showLinkModal)}
          linkDataArray={linkDataArray}
          setLinkDataArray={setLinkDataArray}
          documentDataForList={documentDataForList}
          setDocumentDataForList={setDocumentDataForList}
        />
      )}
      {showDocumentModal && (
        <CommonDocumentModal
          open={showDocumentModal}
          setNotify={setNotify}
          handleClose={() => setShowDocumentModal(!showDocumentModal)}
          documentFile={documentFile}
          setDocumentFile={setDocumentFile}
          finalAttachmentDocs={finalAttachmentDocs}
          setFinalAttachmentDocs={setFinalAttachmentDocs}
          documentDataForList={documentDataForList}
          setDocumentDataForList={setDocumentDataForList}
        />
      )}
    </>
  );
};

export default AddContractModal;
