// imports from axios
import axios from "axios";
import { store } from "../store/store";
import { CashService } from "../services/CashService";
import { TOAST_AUTO_CLOSE } from "../constants/AppConstants";
import { toast, Slide } from "react-toastify";

export const Api = async (endpoint, data, type, showToast = true) => {
  const lang = "en";
  let response;
  const state = store.getState();
  const token = state.auth.auth.token;

  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,

    // Authorization: `Bearer ${token}`,
    lang: lang,
    "Accept-language": lang,
  };

  try {
    switch (type) {
      case "post":
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_URL}${endpoint}`,
          { ...data },
          {
            headers,
          }
        );
        break;

      case "postMultipart":
        headers["Content-Type"] = "multipart/form-data";
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_URL}${endpoint}`,
          data,
          {
            headers,
          }
        );
        break;
      case "get":
        response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_URL}${endpoint}`,
          {
            headers,
          }
        );
        break;
      case "put":
        response = await axios.put(
          `${process.env.REACT_APP_BACKEND_API_URL}${endpoint}`,
          data,
          {
            headers,
          }
        );
        break;
      case "patch":
        response = await axios.patch(
          `${process.env.REACT_APP_BACKEND_API_URL}${endpoint}`,
          data,
          {
            headers,
          }
        );
        break;
      case "delete":
        response = await axios.delete(
          `${process.env.REACT_APP_BACKEND_API_URL}${endpoint}`,
          {
            data,
            headers,
          }
        );
        break;
      default:
        return true;
    }
  } catch (error) {
    response = error?.response;
    if (type !== "get") {
      toast.error(response.data.responseDescription, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.removeItem("userDetails");
      localStorage.removeItem("userRole");
      CashService.clear();
      window.location.replace(`${process.env.REACT_APP_BASENAME}`);
    }
  }

  if (response.status === 200 && type !== "get") {
    if (
      response?.data?.responseCode !== "00" &&
      response?.data?.ResponseCode !== "00"
    ) {
      toast.error(response.data.responseDescription, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    } else {
      if (showToast)
        toast.success(response.data.responseDescription, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
    }
  }
  return response;
};
