/*eslint-disable */
import { Form, useForm } from "../../../../components/useForm";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import { Box, Grid, Tooltip } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import InputLabel from "@mui/material/InputLabel";
import "../../styles/details-form.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import TimeClockImage from "../../../../assets/Time-Remaining@2x.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Button } from "@mui/material";
import { store } from "../../../../store/store";
import axiosInstance from "../../../../services/AxiosInstance";
import * as employeeService from "../../../../services/employeeService";
import { withRouter } from "react-router-dom";
import {
  getCurrentLiveAuction,
  shouldRefreshLiveBiddingData,
} from "../../../../store/selectors/auctionSelector";
import GeneralInfoRankTable from "./GeneralInfoRankTable";
import SetupLiveMonitoringBL from "../../../SupplierAspect/Auction/BL/SetupLiveMonitoringBL";
import { getServerLiveTimestamp } from "../../../../store/selectors/ServerClockSelector";
import { currentSocketStatus } from "../../../../store/selectors/SocketSelector";
import { refreshLiveAuctionBuyer } from "../../../../store/actions/auctionAction";
import { currentUser } from "../../../../store/selectors/AuthSelectors";
import moment from "moment";
import { GOIN_LIVE_BID_GROUP_EVENT } from "../../../../services/socket/constants";
import SocketHandler from "../../../../services/socket/SocketHandler";
import {
  thousandRemoverHandler,
  thousandSepratorHandler,
} from "../../../../utility/utils";
import NumericSeparatorInput from "../../../../components/NumericSeparatorInput";
import NumberFormatTextarea from "../../../../components/NumberFormatTextarea";
import {
  AUCTION_PUBLISHED,
  AUCTION_DRAFT,
  AUCTION_RUNNING,
  TOAST_AUTO_CLOSE,
} from "../../../../constants/AppConstants";
import { Api } from "../../../../api/Api";
import {
  COMAPNY_API,
  GET_COMPANY_AUCTION_API,
  GET_COST_BUDGET_API,
  GET_COST_CENTER_API,
} from "../../../../constants/ApiUrl";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const initialFValues = {
  cost_center: "",
  currency: "",
  budget: "",
  down_period: "",
  show_to_supplier: "",
  reserve_price: "",
  no_of_participants: "",
  min_step: "",
};

const hint =
  "To get started. add the name of request choose when it starts, what it ends, a minimum step, cool-down period and describe what you want to buy.";

function GeneralInfoTab(props) {
  const {
    currentLiveAuction,
    setNotify,
    serverLiveTS,
    isConnected,
    isOwner,
    refreshAuction,
    getLiveAuctionDetails,
  } = props;
  const bestLiveBid = useSelector((state) => state.liveBid.best);

  const [statEditMode, setEditMode] = useState(false);
  const { smScreen } = useWindowDimensions();
  const [awardLabel, setAwardLabel] = useState();
  const [supplierNameCheckBox, setSupplierNameCheckBox] = useState(false);
  const [supplierBidsCheckBox, setSupplierBidsCheckBox] = useState(false);
  const [supplierPriceEventCheckBox, setSupplierPriceEventCheckBox] =
    useState(false);
  const [showMore, setShowMore] = useState(false);
  const [dropdownCostCenter, setDropdownCostCenter] = useState([]);
  const [defVals, setDefVals] = useState();
  const [minimumSteps, setMinimumSteps] = useState(0);
  const [startingPrice, setStartingPrice] = useState(0);
  const [reservePrice, setReservePrice] = useState(0);
  const [currentCurrency, setCurrentCurrency] = useState("");
  const [genralBtnState, setGenralBtnState] = useState(false);
  const [timeRemLabel, setTimeRemLabel] = useState("--:--:--");
  const [timeRevLabel, setTimeRevLabel] = useState("0h 0m 0s");
  const [auctionBudget, setAuctionBudget] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isConnected === true &&
      currentLiveAuction != undefined &&
      currentLiveAuction._id !== undefined
    ) {
      SocketHandler.emit(GOIN_LIVE_BID_GROUP_EVENT, {
        groupId: `auction_${currentLiveAuction?._id}_group`,
        supplierAuctionId: currentLiveAuction?._id,
        auctionId: currentLiveAuction?._id,
        type: "buyer",
      });
    }
  }, [isConnected, currentLiveAuction]);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });
    // if ("cost_center" in fieldValues)
    //   temp.cost_center = fieldValues.cost_center
    //     ? ""
    //     : "This field is required.";
    if ("currency" in fieldValues)
      temp.currency = fieldValues.currency ? "" : "This field is required.";
    // if ("budget" in fieldValues)
    //   temp.budget = fieldValues.budget ? "" : "This field is required.";
    // if ("min_step" in fieldValues)
    //   temp.min_step = fieldValues.min_step ? "" : "This field is required.";

    if ("down_period" in fieldValues) {
      if (fieldValues.down_period !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.down_period)) {
          temp.down_period = "";
          if (fieldValues.down_period > 1440) {
            temp.down_period =
              "Cool down period must be less than 1440 minutes";
          } else {
            temp.down_period = "";
          }
        } else {
          temp.down_period = "Only Number allow";
        }
      } else {
        temp.down_period = "This field is required.";
      }
    }
    if ("min_step" in fieldValues) {
      if (fieldValues.min_step !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.min_step)) {
          temp.min_step = "";
        } else {
          temp.min_step = "Only Number allow";
        }
      } else {
        temp.min_step = "This field is required.";
      }
    }
    // if ("down_period" in fieldValues)
    //   temp.down_period = fieldValues.down_period
    //     ? ""
    //     : "This field is required.";
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const editInformationTggle = () => {
    if (
      (currentLiveAuction &&
        currentLiveAuction?.status === AUCTION_PUBLISHED) ||
      (currentLiveAuction && currentLiveAuction?.status === AUCTION_DRAFT)
    ) {
      setGenralBtnState(!genralBtnState);
    } else {
      setGenralBtnState(false);
      toast.error(ValidationMessage.notUpdateAuction, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (genralBtnState) return;
    if (validate()) {
      if (
        (!genralBtnState &&
          currentLiveAuction &&
          currentLiveAuction?.status === AUCTION_PUBLISHED) ||
        (currentLiveAuction && currentLiveAuction?.status === AUCTION_DRAFT)
      ) {
        const { auth } = store.getState();
        const companyId = auth?.auth?.user?.company?._id;
        if (
          currentLiveAuction !== undefined &&
          currentLiveAuction._id !== undefined
        ) {
          values.awarding_commitment = awardLabel;
          values.disclose_starting_price = supplierPriceEventCheckBox;
          values.disclose_suppliers_bid = supplierBidsCheckBox;
          values.disclose_suppliers_name = supplierNameCheckBox;
          if (values?.cost_center.length === 0) values.cost_center = null;

          const response = await Api(
            `${COMAPNY_API}/${companyId}/auctions/${currentLiveAuction._id}`,
            values,
            "patch"
          );
          getCurrentLiveAuction();
          // await axiosInstance
          //   .patch(
          //     `api/buyer/company/${companyId}/auctions/${currentLiveAuction._id}`,
          //     values
          //   )
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setNotify({
          //         isOpen: true,
          //         message: "Updated successfully.",
          //         type: "success",
          //       });
          //     } else {
          //       setNotify({
          //         isOpen: true,
          //         message: "Auction update failed",
          //         type: "error",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     setNotify({
          //       isOpen: true,
          //       message: "Something goes wrong.",
          //       type: "error",
          //     });
          //   });
        }
      } else {
      }
    } else {
    }
  };

  const getAllCostCenter = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COST_CENTER_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let costCenterList = response.data.costCenter;
          setDropdownCostCenter(costCenterList);
        }
      }

      // await axiosInstance
      //   .get(`/api/buyer/company/getCostCenter/${companyId}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let costCenterList = response.data.costCenter;
      //       setDropdownCostCenter(costCenterList);
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const calculateTimeLeft = async (dueTime) => {
    if (dueTime >= 0) {
      const hours = Math.floor((dueTime / 3600) % 24);
      const minutes = Math.floor((dueTime / 60) % 60);
      const seconds = Math.floor(dueTime % 60);
      setTimeRemLabel(
        ("0" + hours).substr(-2) +
          ":" +
          ("0" + minutes).substr(-2) +
          ":" +
          ("0" + seconds).substr(-2)
      );
    }
  };

  const calculateRevTimeLeft = async (leftTime) => {
    if (leftTime >= 0) {
      const hours = Math.floor((leftTime / 3600) % 24);
      const minutes = Math.floor((leftTime / 60) % 60);
      const seconds = Math.floor(leftTime % 60);
      setTimeRevLabel(
        ("0" + hours).substr(-2) +
          ":" +
          ("0" + minutes).substr(-2) +
          ":" +
          ("0" + seconds).substr(-2)
      );
    }
  };

  const setupTimer = (timerSetting) => {
    if (!timerSetting.canCount) {
      setTimeRemLabel(timerSetting.dueTime > 0 ? "00:00:00" : "--:--:--");
      setTimeRevLabel(
        timerSetting.revTime < 0
          ? "0h 0m 0s"
          : moment
              .utc(currentLiveAuction.totalTimeSec * 1000)
              .format("HH:mm:ss")
      );

      if (
        timerSetting.dueTime > 0 &&
        currentLiveAuction.status === AUCTION_RUNNING
      )
        getLiveAuctionDetails(true);
    } else {
      if (currentLiveAuction.status === AUCTION_PUBLISHED) {
        getLiveAuctionDetails(true);
      }
      calculateTimeLeft(timerSetting.dueTime);
      calculateRevTimeLeft(timerSetting.revTime);
    }
  };

  const getBudgetForAuction = async (e) => {
    const constCenterId = e.target.value;
    if (constCenterId) {
      const projectId = currentLiveAuction?.projectId?._id;
      if (projectId) {
        const response = await Api(
          `${GET_COST_BUDGET_API}/${projectId}/${constCenterId}`,
          {},
          "get"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            const allBudget = response?.data?.project;
            const result = allBudget.reduce(
              (total, currentValue) => (total = total + currentValue.budget),
              0
            );
            setAuctionBudget(parseInt(result));
            setValues({
              ...values,
              budget: result,
              cost_center: constCenterId,
            });
          } else {
            setValues({
              ...values,
              cost_center: constCenterId,
            });
          }
        } else {
          setValues({
            ...values,
            cost_center: constCenterId,
          });
        }

        // await axiosInstance
        //   .get(`/api/buyer/auctionCostBudget/${projectId}/${constCenterId}`)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       const allBudget = response?.data?.project;
        //       const result = allBudget.reduce(
        //         (total, currentValue) => (total = total + currentValue.budget),
        //         0
        //       );
        //       setAuctionBudget(parseInt(result));
        //       setValues({
        //         ...values,
        //         budget: result,
        //         cost_center: constCenterId,
        //       });
        //     } else {
        //       setValues({
        //         ...values,
        //         cost_center: constCenterId,
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     setValues({
        //       ...values,
        //       cost_center: constCenterId,
        //     });
        //   });
      }
    } else {
      setValues({
        ...values,
        cost_center: constCenterId,
      });
    }
  };

  useEffect(() => {
    getCurrentLiveAuction();
  }, [currentLiveAuction]);

  useEffect(() => {
    if (isConnected === true && currentLiveAuction && currentLiveAuction._id) {
      let res = SetupLiveMonitoringBL({
        currentAuction: currentLiveAuction,
        serverLiveTS: serverLiveTS,
      });
      setupTimer(res.timeRemaining);
    }
  }, [serverLiveTS]);

  useEffect(() => {
    if (isConnected === true && currentLiveAuction && currentLiveAuction._id) {
      if (currentLiveAuction.status === AUCTION_RUNNING) {
        dispatch(refreshLiveAuctionBuyer(true));
      }
    }
  }, []);

  const getCurrentLiveAuction = async () => {
    if (
      currentLiveAuction !== undefined &&
      currentLiveAuction._id !== undefined
    ) {
      const formValue = {
        cost_center: currentLiveAuction.cost_center,
        currency: currentLiveAuction.currency,
        budget: currentLiveAuction.budget,
        down_period: currentLiveAuction.cool_down,
        show_to_supplier: currentLiveAuction.show_to_supplier,
        reserve_price: currentLiveAuction.reserve_price,
        no_of_participants: currentLiveAuction.number_of_participants,
        min_step: currentLiveAuction.minimum_step,
      };
      if (currentLiveAuction?.disclose_suppliers_bid) {
        setSupplierBidsCheckBox(true);
      }
      if (currentLiveAuction?.disclose_suppliers_name) {
        setSupplierNameCheckBox(true);
      }
      if (currentLiveAuction?.disclose_starting_price) {
        setSupplierPriceEventCheckBox(true);
      }

      setValues(formValue);
      setCurrentCurrency(currentLiveAuction?.currency);
      setMinimumSteps(currentLiveAuction?.minimum_step);
      setStartingPrice(currentLiveAuction?.starting_price);
      setReservePrice(currentLiveAuction?.reserve_price);
      if (
        currentLiveAuction !== undefined &&
        currentLiveAuction._id !== undefined
      ) {
        const response = await Api(
          `${GET_COMPANY_AUCTION_API}/${currentLiveAuction._id}`,
          {},
          "get"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            let auctionData = response.data.auction;
            setDefVals(auctionData);
            // setDataToForm();
            setValues({
              cost_center: auctionData.cost_center,
              currency: auctionData.currency,
              budget: auctionData.budget,
              down_period: auctionData.cool_down,
              show_to_supplier: auctionData.show_to_supplier,
              reserve_price: auctionData.reserve_price,
              no_of_participants: auctionData.number_of_participants,
              min_step: auctionData.minimum_step,
            });
            if (auctionData?.awarding_commitment) {
              setAwardLabel(auctionData?.awarding_commitment);
            }
            if (auctionData?.disclose_suppliers_bid) {
              setSupplierBidsCheckBox(true);
            }
            if (auctionData?.disclose_suppliers_name) {
              setSupplierNameCheckBox(true);
            }
            if (auctionData?.disclose_starting_price) {
              setSupplierPriceEventCheckBox(true);
            }

            setCurrentCurrency(auctionData?.currency);
            setMinimumSteps(auctionData?.minimum_step);
            setStartingPrice(auctionData?.starting_price);
            setReservePrice(auctionData?.reserve_price);
          }
        }

        // axiosInstance
        //   .get(`api/buyer/company/auctions/${currentLiveAuction._id}`)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       let auctionData = response.data.auction;
        //       setDefVals(auctionData);
        //       // setDataToForm();
        //       setValues({
        //         cost_center: auctionData.cost_center,
        //         currency: auctionData.currency,
        //         budget: auctionData.budget,
        //         down_period: auctionData.cool_down,
        //         show_to_supplier: auctionData.show_to_supplier,
        //         reserve_price: auctionData.reserve_price,
        //         no_of_participants: auctionData.number_of_participants,
        //         min_step: auctionData.minimum_step,
        //       });
        //       if (auctionData?.awarding_commitment) {
        //         setAwardLabel(auctionData?.awarding_commitment);
        //       }
        //       if (auctionData?.disclose_suppliers_bid) {
        //         setSupplierBidsCheckBox(true);
        //       }
        //       if (auctionData?.disclose_suppliers_name) {
        //         setSupplierNameCheckBox(true);
        //       }
        //       if (auctionData?.disclose_starting_price) {
        //         setSupplierPriceEventCheckBox(true);
        //       }

        //       setCurrentCurrency(auctionData?.currency);
        //       setMinimumSteps(auctionData?.minimum_step);
        //       setStartingPrice(auctionData?.starting_price);
        //       setReservePrice(auctionData?.reserve_price);
        //     }
        //   })
        //   .catch((err) => {
        //     // it is impossible non auctherized buyer access here (this tab)
        //   });
      }

      getAllCostCenter();
      currentLiveAuction.auctionId = {
        auctionId: currentLiveAuction.auctionId,
        startDate: currentLiveAuction.startDate,
        endDate: currentLiveAuction.endDate,
      };
    }
  };
  const editValuesIP = async () => {
    if (
      (currentLiveAuction && currentLiveAuction.status === AUCTION_PUBLISHED) ||
      (currentLiveAuction && currentLiveAuction.status === AUCTION_DRAFT)
    ) {
      setEditMode(!statEditMode);
      if (statEditMode) {
        if (
          currentLiveAuction !== undefined &&
          currentLiveAuction._id !== undefined
        ) {
          const payload = {
            minimum_step: thousandRemoverHandler(minimumSteps),
            starting_price: thousandRemoverHandler(startingPrice),
            reserve_price: thousandRemoverHandler(reservePrice),
          };
          const { auth } = store.getState();
          const companyId = auth?.auth?.user?.company?._id;
          const response = await Api(
            `${COMAPNY_API}/${companyId}/auctions/${currentLiveAuction._id}`,
            payload,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
            } else {
              resetPrice();
            }
          } else {
            resetPrice();
          }

          // axiosInstance
          //   .patch(
          //     `api/buyer/company/${companyId}/auctions/${currentLiveAuction._id}`,
          //     payload
          //   )
          //   .then((response) => {
          //     if (response.data.responseCode === "00") {
          //       setNotify({
          //         isOpen: true,
          //         message: "Updated successfully.",
          //         type: "success",
          //       });
          //     } else {
          //       resetPrice();
          //       setNotify({
          //         isOpen: true,
          //         message: "Auction update failed",
          //         type: "error",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     resetPrice();
          //     setNotify({
          //       isOpen: true,
          //       message: err?.response?.data?.responseDescription,
          //       type: "error",
          //     });
          //   });
        } else {
          resetPrice();
          toast.error(ValidationMessage.somethingWrong, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      }
    } else {
      toast.error(ValidationMessage.notUpdateAuction, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const resetPrice = () => {
    setMinimumSteps(defVals?.minimum_step);
    setStartingPrice(defVals?.starting_price);
    setReservePrice(defVals?.reserve_price);
  };
  return (
    <Grid
      container
      spacing={3}
      style={{ flexDirection: smScreen ? "column-reverse" : "row" }}
    >
      <Grid item md={4}>
        <Form
          onSubmit={handleSubmit}
          className="details-form-container mui-card"
        >
          <Box className="title-container">
            <h3 style={{ marginTop: "0" }}>Auction Details</h3>
            {isOwner() && currentLiveAuction?.status === AUCTION_DRAFT ? (
              <Controls.Button
                className={
                  (genralBtnState ? "btn-primary" : "btn-primary-lite") +
                  " edit-btn"
                }
                text={genralBtnState ? "save" : "Edit"}
                type="submit"
                onClick={() => editInformationTggle()}
              />
            ) : (
              <></>
            )}
          </Box>
          <Grid
            container
            spacing={2}
            className="body-container filter-box-container"
          >
            <Grid item xs={12} className="select-field">
              <InputLabel className="mui-label" id="const-center-input">
                Cost Center
              </InputLabel>
              <Controls.Select
                name="cost_center"
                labelId="const-center-input"
                value={values.cost_center}
                fullWidth
                disabled={!genralBtnState}
                options={dropdownCostCenter}
                error={errors.cost_center}
                onChange={getBudgetForAuction}
              />
            </Grid>
            <Grid item xs={12} className="select-field">
              <InputLabel className="mui-label" id="currency-input">
                Currency
              </InputLabel>
              <Controls.Select
                name="currency"
                labelId="currency-input"
                value={values.currency}
                fullWidth
                disabled={true}
                onChange={handleInputChange}
                options={employeeService.currencyList()}
                error={errors.currency}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mui-label" id="budget-input">
                Budget
              </InputLabel>
              <Controls.Input
                name="budget"
                labelId="budget-input"
                fullWidth
                disabled={!genralBtnState}
                value={values.budget}
                onChange={handleInputChange}
                error={errors.budget}
                InputProps={{
                  inputComponent: NumericSeparatorInput,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mui-label" id="down-period-input">
                Cool-down Period (min)
              </InputLabel>
              <Controls.Input
                name="down_period"
                labelId="down-period-input"
                fullWidth
                disabled={!genralBtnState}
                value={values.down_period}
                onChange={handleInputChange}
                error={errors.down_period}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="awarding-check-alert">
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <div
                  className={
                    "checkbox checkbox-primary-outline " +
                    (awardLabel ? "checked" : "")
                  }
                  onClick={() => {
                    setAwardLabel(!awardLabel);
                  }}
                >
                  <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                  <CheckIcon aria-label="check-mark" />
                </div>
              </Grid>
              <Grid item xs={10} style={{ marginLeft: "5px" }}>
                <span className="label">Awarding Commitment</span>
                {/* <div className="text">
                  orem ipsum is a pseudo-Latin text used in web design,
                  typography, layout, and printing in place of English to
                  emphasise design elements over content.
                </div> */}
              </Grid>
            </Grid>
          </Grid>

          <div
            className={
              "mt-4 mb-2 animate-max-height " + (showMore ? "__feed" : "__hide")
            }
          >
            <Box className="title-container">
              <h3>Advance Settings</h3>
            </Box>
            <Grid
              container
              spacing={2}
              className="body-container filter-box-container"
            >
              <Grid item xs={12} className="select-field">
                <InputLabel className="mui-label" id="supplier-input">
                  Show to supplier
                </InputLabel>
                <Controls.Select
                  name="show_to_supplier"
                  labelId="supplier-input"
                  value={values.show_to_supplier}
                  fullWidth
                  disabled={
                    !genralBtnState || currentLiveAuction.weightedBidIsSettedUp
                  }
                  onChange={handleInputChange}
                  options={[
                    { id: "1", name: "Best Bid" },
                    { id: "2", name: "Rank" },
                    { id: "3", name: "Best Bid + Rank" },
                  ]}
                  error={errors.supplier}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="mui-label" id="num-participants-input">
                  Minimun number of participants
                </InputLabel>
                <Controls.Input
                  name="no_of_participants"
                  labelId="no_of_participants-input"
                  fullWidth
                  disabled={!genralBtnState}
                  value={values.no_of_participants}
                  onChange={handleInputChange}
                  error={errors.no_of_participants}
                  className="StepControlInput"
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="title-container">
                  <h3>What to disclose ?</h3>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid conrainer>
                  <Grid item xs={12} className="disclose-option">
                    <div
                      className={
                        "checkbox checkbox-primary " +
                        (supplierBidsCheckBox ? "checked" : "")
                      }
                      // onClick={() => {
                      //   setSupplierBidsCheckBox(!supplierBidsCheckBox);
                      // }}
                    >
                      <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                      <CheckIcon aria-label="check-mark" />
                    </div>
                    <span className="label">Supplier's Bid</span>
                    <Tooltip
                      title={hint}
                      classes={{ popper: "mui-tooltip mui-tooltip-primary" }}
                      placement="top-start"
                      arrow
                    >
                      <HelpIcon className="icon" />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid conrainer>
                  <Grid item xs={12} className="disclose-option">
                    <div
                      className={
                        "checkbox checkbox-primary " +
                        (supplierNameCheckBox ? "checked" : "")
                      }
                      // onClick={() => {
                      //   setSupplierNameCheckBox(!supplierNameCheckBox);
                      // }}
                    >
                      <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                      <CheckIcon aria-label="check-mark" />
                    </div>
                    <span className="label">Supplier's Names</span>
                    <Tooltip
                      title={hint}
                      classes={{ popper: "mui-tooltip mui-tooltip-primary" }}
                      placement="top-start"
                      arrow
                    >
                      <HelpIcon className="icon" />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid conrainer>
                  <Grid item xs={12} className="disclose-option">
                    <div
                      className={
                        "checkbox checkbox-primary " +
                        (supplierPriceEventCheckBox ? "checked" : "")
                      }
                      // onClick={() => {
                      //   setSupplierPriceEventCheckBox(
                      //     !supplierPriceEventCheckBox
                      //   );
                      // }}
                    >
                      <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                      <CheckIcon aria-label="check-mark" />
                    </div>
                    <span className="label">Starting Price of the event</span>
                    <Tooltip
                      title={hint}
                      classes={{ popper: "mui-tooltip mui-tooltip-primary" }}
                      placement="top-start"
                      arrow
                    >
                      <HelpIcon className="icon" />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: "2em" }}>
            <Controls.Button
              className="btn-mui btn-white active"
              text={
                !showMore ? "Show Advanced Settings" : "Hide Advanced Settings"
              }
              onClick={() => setShowMore(!showMore)}
            />
          </Grid>
        </Form>
      </Grid>

      <Grid item md={8}>
        <div style={{ flexGrow: 1 }} className="statistics-card-container">
          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <div className="mui-card" id="statistics-box-first-container">
                <Grid container className="line">
                  <Grid item xs={9}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} container className="line">
                        <Grid item sm={6} className="label">
                          Minimum Step
                        </Grid>
                        <Grid item sm={6}>
                          {statEditMode ? (
                            <NumberFormatTextarea
                              name="name"
                              customClass="field p-0 __edit"
                              spellcheck="false"
                              readOnly={!statEditMode}
                              rows="1"
                              value={minimumSteps}
                              onChange={(e) => {
                                setMinimumSteps(e.target.value);
                              }}
                            />
                          ) : (
                            <p className="field p-0 __view">
                              {thousandSepratorHandler(minimumSteps)}
                            </p>
                          )}
                          {/* <textarea
                            className={
                              "field" +
                              " " +
                              (statEditMode ? "__edit" : "__view")
                            }
                            name="name"
                            readOnly={!statEditMode}
                            spellcheck="false"
                            onChange={(e) => setMinimumSteps(e.target.value)}
                            rows="1"
                            value={minimumSteps}
                          ></textarea> */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container className="line">
                        <Grid item sm={6} className="label">
                          Starting Price
                        </Grid>
                        <Grid item sm={6}>
                          {statEditMode ? (
                            <NumberFormatTextarea
                              name="name"
                              customClass="field p-0 __edit"
                              spellcheck="false"
                              readOnly={!statEditMode}
                              rows="1"
                              value={startingPrice}
                              onChange={(e) => {
                                setStartingPrice(e.target.value);
                              }}
                            />
                          ) : (
                            <p className="field p-0 __view">
                              {thousandSepratorHandler(startingPrice)}{" "}
                              {currentCurrency}
                            </p>
                          )}
                          {/* <textarea
                            className={
                              "field" +
                              " " +
                              (statEditMode ? "__edit" : "__view")
                            }
                            name="name"
                            readOnly={!statEditMode}
                            spellcheck="false"
                            onChange={(e) => setStartingPrice(e.target.value)}
                            rows="1"
                            value={
                              statEditMode
                                ? startingPrice
                                : `${thousandSepratorHandler(
                                    startingPrice
                                  )} ${currentCurrency}`
                            }
                           
                          ></textarea> */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container className="line">
                        <Grid item sm={6} className="label">
                          Reserve Price
                        </Grid>
                        <Grid item sm={6}>
                          {statEditMode ? (
                            <NumberFormatTextarea
                              name="name"
                              customClass="field p-0 __edit"
                              spellcheck="false"
                              readOnly={!statEditMode}
                              rows="1"
                              value={reservePrice}
                              onChange={(e) => {
                                setReservePrice(e.target.value);
                              }}
                            />
                          ) : (
                            <p className="field p-0 __view">
                              {thousandSepratorHandler(reservePrice)}{" "}
                              {currentCurrency}
                            </p>
                          )}
                          {/* <textarea
                            className={
                              "field" +
                              " " +
                              (statEditMode ? "__edit" : "__view")
                            }
                            name="name"
                            readOnly={!statEditMode}
                            spellcheck="false"
                            onChange={(e) => setReservePrice(e.target.value)}
                            rows="1"
                            value={
                              statEditMode
                                ? reservePrice
                                : `${reservePrice} ${currentCurrency}`
                            }
                          ></textarea> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {isOwner() && currentLiveAuction?.status === AUCTION_DRAFT ? (
                    <Grid item xs={3} style={{ textAlign: "end" }}>
                      <Button
                        className={
                          "btn btn-primary go-back-button " +
                          (statEditMode === true ? "__save" : "__edit")
                        }
                        style={{ height: "30px" }}
                        variant="contained"
                        onClick={() => editValuesIP(!statEditMode)}
                      >
                        {statEditMode ? "Save" : "Edit"}
                      </Button>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <div className="mui-card" id="statistics-box-second-container">
                <Grid container style={{ marginTop: "10%" }}>
                  <Grid item sm={4}>
                    <img src={TimeClockImage} className="icon" />
                  </Grid>
                  <Grid item sm={6}>
                    <Grid container>
                      <Grid item sm={12}>
                        <p style={{ margin: "0" }}>Time Remaining</p>
                      </Grid>
                      <Grid item sm={12}>
                        <h3 style={{ margin: "0" }}> {timeRemLabel} </h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <div className="mui-card" id="statistics-box-third-container">
                <Grid container spacing={1}>
                  <Grid item sm={12} className="label">
                    The best bid
                  </Grid>
                  {/* <Grid item sm={12} className="number">
                    {bestLiveBid && bestLiveBid.weightedBid
                      ? thousandSepratorHandler(bestLiveBid?.weightedBid) +
                        " " +
                        currentCurrency
                      : thousandSepratorHandler(currentLiveAuction?.totalBid)}
                  </Grid> */}
                  <Grid item sm={12} className="number">
                    {bestLiveBid && bestLiveBid.bid
                      ? thousandSepratorHandler(bestLiveBid?.bid) +
                        " " +
                        currentCurrency
                      : thousandSepratorHandler(currentLiveAuction?.totalBid)}
                    {/* {currentLiveAuction?.totalBid} {currentCurrency} */}
                  </Grid>
                  <Grid item sm={12} className="time">
                    <Grid container spacing={2}>
                      <Grid item sm={2}>
                        <AccessTimeIcon />
                      </Grid>
                      <Grid item sm={10}>
                        Duration: {timeRevLabel}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* table  */}
        <GeneralInfoRankTable
          setNotify={setNotify}
          isOwner={isOwner}
          refreshLiveAuction={refreshAuction}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
    serverLiveTS: getServerLiveTimestamp(state),
    isConnected: currentSocketStatus(state),
    currentUser: currentUser(state),
    refreshAuction: shouldRefreshLiveBiddingData(state),
  };
};

export default withRouter(connect(mapStateToProps)(GeneralInfoTab));
