import { Api } from "../../../../api/Api";
import { SEND_REMINDER_RFX } from "../../../../constants/ApiUrl";

const SendReminderSupplierRfx = async (props) => {
  const { company_buyer_name, supplier_company, rfxId, email, supplierId } =
    props;
  let success = false;
  let message = "Reminder send successfully.";
  const payload = {
    company_buyer_name: company_buyer_name,
    supplier_company: supplier_company,
    rfxId: rfxId,
    email: email,
  };

  const response = await Api(
    `${SEND_REMINDER_RFX}/${supplierId}`,
    payload,
    "post"
  );
  if (response.status === 200) {
    if (response.data.responseCode === "00") {
      success = true;
      message = response.data.responseDescription
        ? response.data.responseDescription
        : message;
    } else {
      message = response.data.responseDescription
        ? response?.data?.responseDescription
        : response?.message;
    }
  }

  return {
    success: success,
    message: message,
  };
};

export default SendReminderSupplierRfx;
