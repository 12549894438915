import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Grid } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import { useForm, Form } from "../../../../components/useForm";
import { store } from "../../../../store/store";
import axiosInstance from "../../../../services/AxiosInstance";
import Moment from "moment";
import "../../styles/filter-box.css";
import {
  AUCTION_ACTIVE,
  AUCTION_ACTIVE_SMALL,
  AUCTION_ARCHIEVED,
  AUCTION_ARCHIEVED_CAPITAL,
  AUCTION_COMPLETED,
  AUCTION_COMPLETED_SMALL,
  AUCTION_DRAFT,
  AUCTION_DRAFT_SMALL,
} from "../../../../constants/AppConstants";

const initialFValues = {
  status: "",
  location: "",
  manager: "",
  owner: "",
};
export default function ProjectFilter(props) {
  const {
    setRecords,
    setFullLoder,
    setShowFilterBox,
    setFilters,
    setPage,
    setResendApi,
  } = props;

  const [dateRange, setDateRange] = React.useState([null, null]);

  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const statusArr = [
    { name: "All Status", _id: "" },
    { name: AUCTION_ACTIVE, _id: AUCTION_ACTIVE_SMALL },
    { name: AUCTION_COMPLETED, _id: AUCTION_COMPLETED_SMALL },
    { name: AUCTION_ARCHIEVED_CAPITAL, _id: AUCTION_ARCHIEVED },
    { name: AUCTION_DRAFT, _id: AUCTION_DRAFT_SMALL },
  ];

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dateRange && dateRange.length !== 0) {
      if (dateRange && dateRange[0] && dateRange[1]) {
        values.dateRange = [
          Moment(dateRange[0]).format("YYYY-MM-DD 00:00:00"),
          Moment(dateRange[1]).format("YYYY-MM-DD 00:00:00"),
        ];
      } else {
        values.dateRange = [null, null];
      }
    }

    localStorage.setItem("projectFilter", JSON.stringify(values));
    setShowFilterBox(false);
    setFilters(values);
    setPage(1);
    setResendApi(true);
  };

  const clearFilter = () => {
    resetForm();
    setFilters(null);
    setDateRange([null, null]);
    localStorage.removeItem("projectFilter");
    setPage(1);
    setResendApi(true);
    setShowFilterBox(false);
  };

  const getFitGridSize = () => {
    if (xlScreen) {
      return "w15-percent-box pt-1 pb-1";
    } else if (xsScreen) {
      return "w100-percent-box pt-1 pb-1";
    } else if (smScreen) {
      return "w33-percent-box pt-1 pb-1";
    } else if (mdScreen) {
      return "w25-percent-box pt-1 pb-1";
    } else if (lgScreen) {
      return "w15-percent-box pt-1 pb-1";
    }
  };

  const getFitGridBtnSize = () => {
    if (xlScreen) {
      return "w12p5-percent-box pt-1 pb-1";
    } else if (xsScreen) {
      return "w100-percent-box pt-1 pb-1";
    } else if (smScreen) {
      return "w50-percent-box pt-1 pb-1";
    } else if (mdScreen) {
      return "w37-percent-box pt-1 pb-1";
    } else if (lgScreen) {
      return "w12p5-percent-box pt-1 pb-1";
    }
  };

  React.useEffect(() => {
    const filterData = localStorage.getItem("projectFilter");
    //

    if (filterData) {
      const filterObj = JSON.parse(filterData);
      setValues(filterObj);
      if (filterObj && filterObj.dateRange) {
        setDateRange(filterObj.dateRange);
      }
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item className={getFitGridSize()}>
          <InputLabel className="mui-label mb-0">
            Start Date - End Date
          </InputLabel>
          <Controls.DateRangePicker
            startProps={{
              name: "date_range",
            }}
            onChange={setDateRange}
            value={dateRange}
            inpClassName="w-100 b-600 "
            aria-label="datetime-picker"
          />
        </Grid>
        <Grid
          item
          className={getFitGridSize()}
          style={{ marginBottom: "-3px" }}
        >
          <InputLabel className="mui-label mb-0">Status</InputLabel>
          <Controls.Select
            value={values.status}
            onChange={handleInputChange}
            autoWidth
            name="status"
            options={statusArr}
            emptyOption={false}
            className="w-100 b-600 ml-0"
          />
        </Grid>
        <Grid item className={getFitGridSize()}>
          <InputLabel className="mui-label mb-0">Location</InputLabel>
          <Controls.Input
            name="location"
            value={values.location}
            onChange={handleInputChange}
            multiline
            className="w-100 b-600 ml-0"
          />
        </Grid>
        <Grid item className={getFitGridSize()}>
          <InputLabel className="mui-label mb-0">Project Manager</InputLabel>

          <Controls.Input
            name="manager"
            value={values.manager}
            onChange={handleInputChange}
            multiline
            className="w-100 b-600 ml-0"
          />
        </Grid>
        <Grid item className={getFitGridSize()}>
          <InputLabel className="mui-label mb-0">Project Buyer</InputLabel>
          <Controls.Input
            name="owner"
            value={values.owner}
            onChange={handleInputChange}
            multiline
            className="w-100 b-600 ml-0"
          />
        </Grid>
        <Grid item className={getFitGridBtnSize()}>
          <Controls.Button
            text="Clear"
            disableElevation
            color="default"
            className="btn draft btn-mui w-100"
            onClick={() => clearFilter()}
          />
        </Grid>
        <Grid item className={getFitGridBtnSize()}>
          <Controls.Button
            type="submit"
            disableElevation
            text="Apply Filter"
            className="btn-mui w-100"
          />
        </Grid>
      </Grid>
    </Form>
  );
}
