import React from "react";
import CommonSignup from "./CommonSignup";

const SignUpBuyer = ({ notify, setNotify }) => {
  return (
    <CommonSignup fromBuyer={true} notify={notify} setNotify={setNotify} />
  );
};

export default SignUpBuyer;
