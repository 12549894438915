import { useEffect, useState } from "react";
import CollapsedPage from "../collapsedPage";
import useWindowDimensions from "../useWindowDimensions";
import { Box, Grid, InputLabel } from "@mui/material";
import "../../styles/supportDrawer.css";
import { useForm } from "react-hook-form";
import FormInput from "../FormComponents/FormInput";
import { Form } from "../useForm";
import SelectInput from "../FormComponents/SelectInput";
import attachIcon from "../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../assets/icons/CloseX.png";
import { Slide, toast } from "react-toastify";
import { ValidationMessage } from "../../constants/ValidationMessage";
import {
  ACCEPT_ITEM_ATTACHMENTS,
  BOTH_ROLE,
  ITEM_ATTACHMENTS_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../constants/AppConstants";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CommonButton from "../controls/CommonButton";
import {
  SUPPORT_AREA_REQUIRED,
  SUPPORT_CATEGORY_REQUIRED,
  SUPPORT_MESSAGE_REQUIRED,
  SUPPORT_ROLE_REQUIRED,
  SUPPORT_SUBJECT_REQUIRED,
} from "../../constants/Rules";
import { Api } from "../../api/Api";
import { POST_CREATE_SUPPORT_API } from "../../constants/ApiUrl";
import { store } from "../../store/store";

const roleOption = [
  { value: "seller", label: "Supplier" },
  { value: "Buyer", label: "Buyer" },
];
const categoryOption = [
  { value: "4", label: "Report a Bug" },
  { value: "3", label: "Request Assistance" },
  { value: "2", label: "Submit Feedback" },
  { value: "1", label: "Other" },
];
const areaOption = [
  { value: "Dashboard", label: "Dashboard" },
  { value: "Projects", label: "Projects" },
  { value: "Auctions", label: "Auctions" },
  { value: "RFX", label: "RFX" },
  { value: "Suppliers", label: "Suppliers" },
  { value: "Contracts", label: "Contracts" },
  { value: "Items", label: "Items" },
  { value: "CompanyUsers", label: "Company Users" },
  { value: "Settings", label: "Settings" },
];
const SupportFormDrawer = ({ showSupportDrawer, setShowSupportDrawer }) => {
  const state = store.getState();

  const companyId = state?.auth?.auth?.user?.company?._id;
  const userId = state?.auth?.auth?.user?._id;
  const currentRole = state?.auth?.auth?.user?.role;
  const [document, setDocument] = useState([]);
  const [roleFilterOption, setRoleFilterOption] = useState([...roleOption]);
  const [showLoading, setShowLoading] = useState(false);

  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm();
  const getBodyWidth = () => {
    if (xlScreen) {
      return 40;
    } else if (xsScreen) {
      return 80;
    } else if (smScreen) {
      return 80;
    } else if (mdScreen) {
      return 70;
    } else if (lgScreen) {
      return 50;
    }
  };

  useEffect(() => {
    if (showSupportDrawer)
      reset({
        role: "",
        message: "",
        category: "",
        area: "",
        subject: "",
      });
  }, [showSupportDrawer]);
  useEffect(() => {
    let temp = [...roleFilterOption];
    if (currentRole !== BOTH_ROLE) {
      const filterData = temp.filter(
        (item) => item.value.toLowerCase() === currentRole.toLowerCase()
      );
      setRoleFilterOption([...filterData]);
    }
  }, [currentRole]);
  const handleFileChange = (e) => {
    const ALLOWED_TYPES = ACCEPT_ITEM_ATTACHMENTS;
    let allFile = [...document];

    for (let i = 0; i < e.target.files.length; ++i) {
      if (
        e.target.files[i] &&
        !ALLOWED_TYPES.includes(e.target.files[i].type)
      ) {
        toast.error(ValidationMessage.itemFileType, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });

        return;
      }
      if (
        e.target.files[i] &&
        Math.round(e.target.files[i].size / 1024) > ITEM_ATTACHMENTS_SIZE
      ) {
        toast.error(ValidationMessage.itemAttachmentFileSize, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });

        return;
      }

      if (ALLOWED_TYPES.includes(e.target.files[i].type)) {
        let tempData = e.target.files[i];
        tempData.tempId = Math.floor(Date.now() / 1000) + i;
        allFile.push(tempData);
      }
      setDocument([...allFile]);
    }
    e.target.value = "";
  };

  const removeDocumentHandler = (docsData, index) => {
    if (docsData && docsData.name) {
      if (docsData && docsData.tempId) {
        const resData = document.filter(
          (obj) => obj.tempId !== docsData.tempId
        );
        if (resData && resData.length > 0) setDocument([...resData]);
        else setDocument([]);
      }
    }
  };

  const supportFormSubmitHandler = async (values) => {
    const formData = new FormData();
    formData.append("companyId", companyId);
    formData.append("userId", userId);
    formData.append("role", values.role);
    formData.append("category", values.category);
    formData.append("area", values.area);
    formData.append("subject", values.subject);
    formData.append("message", values.message);
    if (document && document.length > 0) {
      for (let i = 0; i < document.length; i++) {
        formData.append(`attachments[${i}]`, document[i]);
      }
    }

    setShowLoading(true);
    const response = await Api(
      POST_CREATE_SUPPORT_API,
      formData,
      "postMultipart"
    );

    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        reset({
          role: "",
          message: "",
          category: "",
          area: "",
          subject: "",
        });
        setDocument([]);
        setShowSupportDrawer(false);
      }
    }
    setShowLoading(false);
  };
  return (
    <>
      <CollapsedPage
        openPopup={showSupportDrawer}
        setOpenPopup={setShowSupportDrawer}
        bodyWidth={getBodyWidth()}
        fromRfx={true}
      >
        <Box container className="common-slider support-drawer">
          <div className="support-header">
            <div className="support-header-body">
              <p className="common-title simple-word-break">Contact Us</p>
            </div>
            <div className="divider-title mt-3"></div>
          </div>
          <div className="support-body w-100">
            <Form onSubmit={handleSubmit(supportFormSubmitHandler)}>
              <div className="mb-3">
                <InputLabel className="text-bold text-dark">Role</InputLabel>
                <SelectInput
                  className="w-100"
                  id="role"
                  name="role"
                  options={roleFilterOption}
                  register={register}
                  errors={errors}
                  rules={SUPPORT_ROLE_REQUIRED}
                  control={control}
                />
              </div>
              <div className="mb-3">
                <InputLabel className="text-bold text-dark">
                  Category
                </InputLabel>
                <SelectInput
                  className="w-100"
                  id="category"
                  name="category"
                  options={categoryOption}
                  register={register}
                  errors={errors}
                  rules={SUPPORT_CATEGORY_REQUIRED}
                  control={control}
                />
              </div>
              <div className="mb-3">
                <InputLabel className="text-bold text-dark">Area</InputLabel>
                <SelectInput
                  className="w-100"
                  id="area"
                  name="area"
                  options={areaOption}
                  register={register}
                  errors={errors}
                  rules={SUPPORT_AREA_REQUIRED}
                  control={control}
                />
              </div>
              <div className="mb-3">
                <FormInput
                  className="subject-input w-100"
                  id="subject"
                  name="subject"
                  inputLabel="Subject"
                  type="text"
                  register={register}
                  errors={errors}
                  placeholder="Type a subject"
                  rules={SUPPORT_SUBJECT_REQUIRED}
                />
              </div>
              <div className="mb-3">
                <FormInput
                  className="common-textarea-input w-100"
                  id="message"
                  name="message"
                  inputLabel="Message"
                  type="text"
                  register={register}
                  errors={errors}
                  placeholder="Type a message"
                  rules={SUPPORT_MESSAGE_REQUIRED}
                  multiple={true}
                  maxRows={4}
                  minRows={1}
                />
              </div>

              <div className="mb-3">
                <Grid container className="support-document-list" spacing={1}>
                  {document &&
                    document.length > 0 &&
                    document.map((file, index) => (
                      <Grid item lg={4} md={6} xs={12} className="">
                        <Box
                          component="div"
                          className="uploaded-files d-flex align-item-center"
                        >
                          <img
                            src={attachIcon}
                            className="icon icon-chat-attach-list"
                            alt=""
                          />

                          <Box className="name px-2 simple-word-break">
                            {file?.name}
                          </Box>
                          <div
                            className="cursor-p mt-1"
                            onClick={() => {
                              removeDocumentHandler(file, index);
                            }}
                          >
                            <img src={closeIcon} alt="" />
                          </div>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
                <label htmlFor="fileUpload">
                  <div className="common-attachment-btn outline ml-0 mt-3">
                    <AddOutlinedIcon style={{ fontSize: "20px" }} />
                    <span className="ml-2">Add Attachment</span>
                  </div>
                </label>
                <input
                  type="file"
                  id="fileUpload"
                  hidden
                  onChange={handleFileChange}
                  multiple
                />
              </div>
              <div>
                <CommonButton
                  type="submit"
                  text="Submit"
                  className="btn-mui common-border-button m-0"
                  loader={showLoading}
                />
              </div>
            </Form>
          </div>
        </Box>
      </CollapsedPage>
    </>
  );
};

export default SupportFormDrawer;
