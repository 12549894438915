import { loginConfirmedAction, logout } from "../store/actions/authActions";
import { Api } from "../api/Api";
import { toast, Slide } from "react-toastify";
import {
  GET_USER_API,
  LOGIN_API,
  VERIFY_EMAIL_API,
  EMAIL_VERIFY_TOST_API,
  SUPER_ADMIN_PASSWORD_CHECK_INVITE,
} from "../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../constants/AppConstants";
//
export async function signUp(
  first_name,
  last_name,
  role,
  email,
  company_name,
  country,
  password,
  request_to_be_buyer,
  fromBuyer
) {
  let postData = {
    first_name,
    last_name,
    role,
    email,
    company_name,
    country,
    password,
    request_to_be_buyer,
  };

  if (fromBuyer) {
    postData = { ...postData, isBuyer: fromBuyer };
  }
  if (!fromBuyer) {
    postData = { ...postData, isSeller: !fromBuyer };
  }
  const response = await Api(GET_USER_API, postData, "post");

  return response;
  // return axios.post(
  //   process.env.REACT_APP_BACKEND_API_URL + `/api/users`,
  //   postData
  //   // {
  //   //   headers: headers,
  //   // }
  // );
}

export async function login(email, password) {
  const postData = {
    email,
    password,
  };
  const response = await Api(LOGIN_API, postData, "post");
  return response;

  // return axios.post(
  //   process.env.REACT_APP_BACKEND_API_URL + `/api/auth`,
  //   postData
  // );
}
export async function VerifyEmail(token) {
  const response = await Api(`${VERIFY_EMAIL_API}/${token}`, {}, "get");
  return response;
  // return axios.get(
  //   process.env.REACT_APP_BACKEND_API_URL + `/verifyEmail/${token}`
  // );
}

export async function EmailVerificationToast(token) {
  const response = await Api(`${EMAIL_VERIFY_TOST_API}/${token}`, {}, "get");

  return response;
}

export async function SuperAdminPasswordConfirm(token, _id) {
  const response = await Api(
    `${SUPER_ADMIN_PASSWORD_CHECK_INVITE}/${_id}/${token}`,
    {},
    "get"
  );
  if (response.status === 200) {
    toast.success(response.data.responseDescription, {
      transition: Slide,
      autoClose: TOAST_AUTO_CLOSE,
    });
  } else {
    toast.error(response.data.responseDescription, {
      transition: Slide,
      autoClose: TOAST_AUTO_CLOSE,
    });
  }
  return response;
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "responseDescription":
      return "Email already exists";

    case "EMAIL_EXISTS":
      return "Email already exists";

    case "EMAIL_NOT_FOUND":
      return "Email not found";
    case "INVALID_PASSWORD":
      return "Invalid Password";
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  const pathName = history?.location?.pathname;

  if (
    !pathName.includes("resetpassword") &&
    !pathName.includes("supplierInvitation") &&
    !pathName.includes("verifyToast") &&
    !pathName.includes("setPassword")
  ) {
    if (!tokenDetailsString) {
      dispatch(logout(history));
      return;
    }
    tokenDetails = JSON.parse(tokenDetailsString);
    dispatch(loginConfirmedAction(tokenDetails));
  }
}
