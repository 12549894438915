import * as React from "react";
import { Box, createStyles, Grid, makeStyles } from "@material-ui/core";
import {
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Modal,
} from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import useWindowDimensions from "../../../components/useWindowDimensions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import UserImage from "../../../assets/auth/user_icon_2.png";
import { Search } from "@mui/icons-material";
import "../styles/supplier-list-info-details.css";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import TableHeadOptionList from "../../../components/TableHeadOptionList";
import { HtmlUtilService } from "../../../services/HtmlUtilService";
import CompanyAttachDocPopup from "../../Settings/components/companyAttachDocPopup";
import CompanyAttachLinkPopup from "../../Settings/components/companyAttachLinkPopup";
import AddFromLibrary from "../../Settings/components/addFromLibrary";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Api } from "../../../api/Api";
import {
  DELETE_ATTACHMENT_API,
  GET_COMPANY_ATTACHMENT_API,
} from "../../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const columns = [
  {
    label: "Attachment",
  },
  {
    label: "Name",
    // minWidth: 100
  },
  {
    label: "Notes",
  },
  {
    label: "Added By",
  },
  {
    label: "",
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    attachmentCol: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("xs")]: {
        width: "100px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "150px",
      },
    },
    notesCol: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("xs")]: {
        width: "150px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "250px",
      },
    },
  })
);

export default function SupplierListDetailsAttachment(props) {
  const { supplier, setNotify } = props;
  const classes = useStyles();
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [limit, setLimit] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [numOfPages, setNumOfPages] = React.useState(0);
  const [documentPopup, setDocumentPopup] = React.useState(false);
  const [linkPopup, setLinkPopup] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState("");
  const [list, setList] = React.useState([]);

  const [editAttachmentData, setEditAttachmentData] = React.useState({});
  const [attachmentIsEdit, setAttachmentIsEdit] = React.useState(false);
  const [editLinkData, setEditLinkData] = React.useState({});
  const [linkIsEdit, setLinkIsEdit] = React.useState(false);
  const [openPopupLib, setOpenPopupLib] = React.useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = React.useState({});
  const [alertDailog, setAlertDailog] = React.useState(false);
  const handleClose = () => setAlertDailog(false);

  const getAllDocuments = async (searchText) => {
    const supplierCompanyId = supplier?.supplier_company?._id;
    let queryString = "";
    if (searchText) {
      queryString = `&search=${searchText}`;
    }
    if (supplierCompanyId) {
      const response = await Api(
        `${GET_COMPANY_ATTACHMENT_API}/${supplierCompanyId}?per_page=${rowsPerPage}&page=${page}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let companyAttachment = response?.data?.attachmentsList;
          setList(companyAttachment);
          setLimit(response?.data?.totalRecord);
        } else {
          setList([]);
          setLimit(0);
        }
      } else {
        setList([]);
        setLimit(0);
      }
    } else {
      setList([]);
    }
  };

  const editRecord = (row) => {
    if (row && row?.attachment) {
      setEditAttachmentData(row);
      setAttachmentIsEdit(true);
      setDocumentPopup(true);
      setAnchorEl(null);
    } else if (row && row?.link) {
      setEditLinkData(row);
      setLinkIsEdit(true);
      setLinkPopup(true);
      setAnchorEl(null);
    } else {
      toast.error(ValidationMessage.updateDocument, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const deleteDocument = async (attachment) => {
    if (attachment) {
      setAlertDailog(true);
      setDeleteAttachmentId(attachment);
    }
  };

  const confirmDeleteAttachment = async () => {
    if (deleteAttachmentId) {
      const response = await Api(
        `${DELETE_ATTACHMENT_API}/${deleteAttachmentId._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          // getAllDocuments();
          setAlertDailog(false);
          setDeleteAttachmentId({});
        }
        const numOfPages = Math.ceil(list.length / rowsPerPage) || 1;
        if (list.length - 1 === 0) {
          if (page > numOfPages) {
            setPage(numOfPages);
          } else {
            getAllDocuments();
          }
        } else {
          getAllDocuments();
        }
      }
    }
  };

  const getSearchString = (e) => {
    const searchText = e.target.value;
    getAllDocuments(searchText);
  };

  const getAttachFile = (item) => {
    return item && item.attachment
      ? item.attachment?.name
      : item && item.link
      ? item.link
      : item && item.doc
      ? item.doc?.document?.name
      : "";
  };

  React.useEffect(() => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  }, [limit, list]);

  React.useEffect(() => {
    HtmlUtilService.truncate();
    getAllDocuments();
  }, [supplier, page]);

  return supplier && supplier.status ? (
    <Grid container className="supplier-list-details-info-container">
      <Grid container>
        <Grid item md={4} sm={5} xs={12}>
          <p className="profile-title" style={{ borderBottom: "none" }}>
            Attachments
          </p>
        </Grid>
      </Grid>
      <Grid container className="table-header-container">
        <Grid
          item
          lg={6}
          xs={12}
          style={{ textAlign: mdScreen ? "center" : "start" }}
        >
          <TextField
            onChange={getSearchString}
            placeholder="Search Using Keyword"
            className=""
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          style={{
            textAlign: mdScreen ? "center" : "end",
            marginTop: mdScreen ? "10px" : "0px",
          }}
        >
          <Button
            aria-controls="supplier-list-info-details-attach-option-list"
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className="btn-mui-table-option"
          >
            <AddOutlinedIcon style={{ fontSize: "20px" }} />
            <span>Add Attachment</span>
          </Button>
          <TableHeadOptionList
            id="supplier-list-info-details-attach-option-list"
            MenuListProps={{
              "aria-label": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setDocumentPopup(true);
              }}
              disableRipple
            >
              Attach Document
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenPopupLib(true);
              }}
              disableRipple
            >
              Add From Library
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setLinkPopup(true);
              }}
              disableRipple
            >
              URL Link
            </MenuItem>
          </TableHeadOptionList>
        </Grid>
      </Grid>
      <TableContainer className="table-container" component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "700" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item) => (
              <TableRow key={item._id}>
                <TableCell>
                  <div className={classes.attachmentCol}>
                    {item?.attachment?.name || item?.doc?.document?.name ? (
                      <FilePresentOutlinedIcon
                        style={{
                          color: "#A19B9D",
                          verticalAlign: "middle",
                        }}
                      />
                    ) : (
                      <LinkOutlinedIcon
                        style={{
                          color: "#A19B9D",
                          verticalAlign: "middle",
                        }}
                      />
                    )}
                    <span
                      style={{
                        color: "#2170FF",
                        marginLeft: "0.2em",
                        verticalAlign: "bottom",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {getAttachFile(item).length > 22
                        ? getAttachFile(item).substring(0, 22) + ".."
                        : getAttachFile(item)}
                    </span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  {(item?.name || "-").length > 22
                    ? (item?.name || "-").substring(0, 22) + ".."
                    : item?.name || "-"}
                </TableCell>
                <TableCell>
                  <span className={classes.notesCol}>
                    <p style={{ whiteSpace: "break-spaces" }}>
                      {(item?.note || "-").length > 47
                        ? (item?.note || "-").substring(0, 47) + "..."
                        : item?.note || "-"}
                    </p>
                  </span>
                </TableCell>
                <TableCell component="th" scope="row" className="text-center">
                  <img
                    src={item?.createdBy?.image_upload || UserImage}
                    style={{
                      width: "2.7em",
                      height: "2.7em",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </TableCell>
                <TableCell>
                  {!item?.isSeller ? (
                    <Box>
                      <PopupState
                        variant="popover"
                        popupId="supplier-list-info-details-option-list"
                      >
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem
                                className="bold-line-600"
                                onClick={() => {
                                  editRecord(item);
                                  popupState.close();
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                className="bold-line-600"
                                style={{ color: "red" }}
                                onClick={() => {
                                  deleteDocument(item);
                                  popupState.close();
                                }}
                              >
                                Delete
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {list.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                  No records found <br />
                  {/* <i style={{ color: "var(---2170ff-p-)" }}>
                    Please add some attachemnt
                  </i> */}
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePaginationBox
          label={limit + " Attachments Found"}
          numOfPages={numOfPages}
          onChangePage={(event, newPage) => setPage(newPage)}
          page={page}
        />
      </TableContainer>
      {/* <SupplierAttachDocPopup
        openPopup={documentPopup}
        setOpenPopup={setDocumentPopup}
        setNotify={setNotify}
        editAttachmentData={editAttachmentData}
        setEditAttachmentData={setEditAttachmentData}
        isEditAttachment={isEditAttachment}
        setIsEditAttachment={setIsEditAttachment}
      /> */}

      <Modal
        open={documentPopup}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: "1600" }}
      >
        <Box className="popup-form-container">
          <div className="title">Attach Document</div>
          <CompanyAttachDocPopup
            openPopup={documentPopup}
            setOpenPopup={setDocumentPopup}
            getAllDocuments={getAllDocuments}
            attachmentIsEdit={attachmentIsEdit}
            editAttachmentData={editAttachmentData}
            setEditAttachmentData={setEditAttachmentData}
            setAttachmentIsEdit={setAttachmentIsEdit}
            setNotify={setNotify}
            isFromSupplier={true}
            supplier={supplier}
          />
        </Box>
      </Modal>

      {/* <SupplierAttachLinkPopup
        openPopup={linkPopup}
        setOpenPopup={setLinkPopup}
        setNotify={setNotify}
        editLinkData={editLinkData}
        setEditLinkData={setEditLinkData}
        isEditLink={isEditLink}
        setIsEditLink={setIsEditLink}
      /> */}

      <Modal
        open={linkPopup}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: "1600" }}
      >
        <Box className="popup-form-container">
          <div className="title">Add Link</div>
          <CompanyAttachLinkPopup
            openPopup={linkPopup}
            setOpenPopup={setLinkPopup}
            getAllDocuments={getAllDocuments}
            setNotify={setNotify}
            editLinkData={editLinkData}
            linkIsEdit={linkIsEdit}
            setEditLinkData={setEditLinkData}
            setLinkIsEdit={setLinkIsEdit}
            isFromSupplier={true}
            supplier={supplier}
          />
        </Box>
      </Modal>

      {/* add from library  */}

      <Modal
        open={openPopupLib}
        aria-label="modal-add-document-from-library"
        aria-describedby="modal-modal-description"
        style={{ zIndex: "1600" }}
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Library</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopupLib(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          {/* <div className="title"> Library </div> */}
          <AddFromLibrary
            setOpenPopupLib={setOpenPopupLib}
            setNotify={setNotify}
            getAllAttachments={getAllDocuments}
            isFromSupplier={true}
            supplier={supplier}
          />
        </Box>
      </Modal>
      {/* add from library  */}

      <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: "1600" }}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete attachment?
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ padding : "10px" }}>
            Are you sure to delete attachment?
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button
            onClick={() => setAlertDailog(false)}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={() => confirmDeleteAttachment()}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  ) : (
    <></>
  );
}
