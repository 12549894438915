import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Controls from "../../../components/controls/Controls";
import { Api } from "../../../api/Api";
import { DUPLICATE_AUCTION_API } from "../../../constants/ApiUrl";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(4),
    position: "absolute",
    top: theme.spacing(5),
  },
  closeIcon: {
    cursor: "pointer",
    marginLeft: "auto",
  },

  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

const DuplicateAuctionDialog = (props) => {
  const {
    duplicateAuctionModal,
    setDuplicateAuctionModal,
    auctionData,
    setResendApi,
    fromTable,
  } = props;
  const classes = useStyles();
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingText, setShowLoadingText] = useState("");

  const confirmDuplicateProject = async () => {
    setShowLoading(true);
    setShowLoadingText("progress");
    const payload = {
      id: auctionData?._id,
      projectId: auctionData?.projectId?._id,
    };
    // return;
    const response = await Api(DUPLICATE_AUCTION_API, payload, "post");

    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        setShowLoading(false);
        if (fromTable) setResendApi(true);
      } else {
        setShowLoading(false);
      }
    } else {
      setShowLoading(false);
    }
    setDuplicateAuctionModal();
  };
  return (
    <Dialog
      disableEnforceFocus
      open={duplicateAuctionModal}
      classes={{ paper: classes.dialog }}
    >
      <CloseIcon
        onClick={() => {
          setDuplicateAuctionModal(false);
        }}
        className={classes.closeIcon}
      />
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <NotListedLocationIcon />
        </IconButton>
      </DialogTitle>

      {!showLoading ? (
        <>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="h6" className="show-wrap-text mw600 two-line">
              {auctionData?.auction_name}
            </Typography>
            <Typography variant="subtitle2">
              Are you sure to duplicate Auction?
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Controls.Button
              text="No"
              color="default"
              onClick={() => setDuplicateAuctionModal(false)}
            />
            <Controls.Button
              text="Yes"
              color="primary"
              onClick={() => confirmDuplicateProject()}
            />
          </DialogActions>{" "}
        </>
      ) : (
        <>
          <DialogContent className={classes.dialogContent}>
            <CircularProgress color="primary" size={52} />
            <h3>Processing...</h3>
          </DialogContent>

          {/* <DialogContent className={classes.dialogContent}>
              <CheckCircleIcon
                className="auction-duplicate-success-icon"
                color="success"
              />
              <h3>Process done</h3>
            </DialogContent> */}
        </>
      )}
    </Dialog>
  );
};

export default DuplicateAuctionDialog;
