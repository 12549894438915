import { Api } from "../../../../api/Api";
import { GET_SELLER_AUCTION_ITEM_LIST_API } from "../../../../constants/ApiUrl";
import axiosInstance from "../../../../services/AxiosInstance";

const GetAllAuctionItemsBL = async (props) => {
  const { currentAuction, page, rowsPerPage } = props;

  let list = [];
  let limit = 0;

  // const response = await axiosInstance.get(
  //   `api/seller/auctions/supplierAuctionItems/${currentAuction._id}?per_page=${rowsPerPage}&page=${page}`
  // );
  const response = await Api(
    `${GET_SELLER_AUCTION_ITEM_LIST_API}/${currentAuction._id}?per_page=${rowsPerPage}&page=${page}`,
    {},
    "get"
  );
  if (response.status === 200) {
    if (response.data && response.data.itemAllData !== undefined) {
      list = response.data.itemAllData;
      limit = response.data.totalRecord;
    }
  }

  return {
    list: list,
    limit: limit,
  };
};

export default GetAllAuctionItemsBL;
