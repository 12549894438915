/*eslint-disable*/

import { Box, Grid, Paper } from "@mui/material";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ProjectsBLImg from "../../../assets/blue projects @2X.png";
import AuctionsBLImg from "../../../assets/Blue auction @2x.png";
import SuppliersBLImg from "../../../assets/Blue suppliers@2x.png";
import ProjectsImg from "../../../assets/blue projects @2X.png";
import RfxIconImg from "../../../assets/bule_rfx_icon.png";
import { getCurrentRole } from "../../../store/selectors/AuthorSelector";
import {
  isAdmin,
  isBuyer,
  isSeller,
} from "../../../store/selectors/RoleSelector";
import "../styles/statistics.css";
import GetDashboardStatisticsBL from "../BL/GetDashboardStatisticsBL";
import { useEffect } from "react";
import { useState } from "react";

const StatisticsBoxes = (props) => {
  const { resendApi } = props;
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalAuctions, setTotalAuctions] = useState(0);
  const [totalSuppliers, setTotalSuppliers] = useState(0);
  const [totalRFX, setTotalRFX] = useState(0);

  const [totalActiveProjects, setTotalActiveProjects] = useState(0);

  useEffect(() => {
    if (resendApi == true) {
      GetDashboardStatisticsBL()
        .then((statistics) => {
          setTotalProjects(statistics.totalProjects);
          setTotalAuctions(statistics.totalAuctions);
          setTotalSuppliers(statistics.totalSuppliers);
          setTotalActiveProjects(statistics.totalActiveProjects);
          setTotalRFX(statistics.totalRFX);
        })
        .catch((error) => {});
    }
  }, [resendApi]);

  return (
    <Grid container spacing={2} className="dashboard-statistics-container">
      <Grid item md={3} sm={6} xs={12}>
        <Paper elevation={0} square>
          <Box
            className="dashboard-statistics-box"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={ProjectsBLImg} className="icon" />
            <div className="details">
              <div className="label">
                <Link
                  to={{
                    pathname: "/projects",
                  }}
                >
                  Total Projects
                </Link>
              </div>
              <div className="value">{totalProjects}</div>
            </div>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Paper elevation={0} square>
          <Box
            className="dashboard-statistics-box"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={SuppliersBLImg} className="icon" />
            <div className="details">
              <div className="label">
                <Link
                  to={{
                    pathname: "/suppliers",
                  }}
                >
                  Total Suppliers
                </Link>
              </div>
              <div className="value">{totalSuppliers}</div>
            </div>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Paper elevation={0} square>
          <Box
            className="dashboard-statistics-box"
            display="flex"
            justifyContent="center"
            alignItems="center"
            // style={{ backgroundColor: "#28a745" }}
          >
            <img src={RfxIconImg} className="icon" />
            <div className="details">
              <div
                className="label"
                // style={{ color: "#fff" }}
              >
                <Link
                  to={{
                    pathname: "/rfx",
                  }}
                >
                  Total RFX
                </Link>
              </div>
              <div
                className="value"
                // style={{ color: "#fff" }}
              >
                {totalRFX}
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Paper elevation={0} square>
          <Box
            className="dashboard-statistics-box"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={AuctionsBLImg} className="icon" />
            <div className="details">
              <div className="label">
                <Link
                  to={{
                    pathname: "/auction",
                  }}
                >
                  Total Auctions
                </Link>
              </div>
              <div className="value">{totalAuctions}</div>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
  };
};

export default withRouter(connect(mapStateToProps)(StatisticsBoxes));
