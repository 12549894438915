import { updateEventStack } from "../../../store/actions/socketAction";
import { refreshLiveAuctionSupplier } from "../../../store/actions/supplierAspect/auctionAction";
import { refreshLiveAuctionBuyer } from "../../../store/actions/auctionAction";
import { REFRESH_LIVE_AUCTION_PROPS } from "../constants";
import SocketHandler from "../SocketHandler";

const RefreshAuctionDataEvent = () => {
  SocketHandler.instance.on(REFRESH_LIVE_AUCTION_PROPS, (response) => {
    SocketHandler.dispatcher(refreshLiveAuctionSupplier(true));
    SocketHandler.dispatcher(refreshLiveAuctionBuyer(true));
    SocketHandler.dispatcher(updateEventStack(REFRESH_LIVE_AUCTION_PROPS));
  });
  return true;
};

export default RefreshAuctionDataEvent;
