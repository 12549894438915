import { Api } from "../../../api/Api";
import { POST_BUYER_REMINDER_API } from "../../../constants/ApiUrl";
import axiosInstance from "../../../services/AxiosInstance";

const SendReminderSupplierBL = async (props) => {
  const { company_buyer_name, supplier_company, auctionId, email, supplierId } =
    props;
  let success = false;
  let message = "Reminder send successfully.";
  const payload = {
    company_buyer_name: company_buyer_name,
    supplier_company: supplier_company,
    auctionId: auctionId,
    email: email,
  };

  const response = await Api(
    `${POST_BUYER_REMINDER_API}/${supplierId}`,
    payload,
    "post"
  );
  if (response.status === 200) {
    if (response.data.responseCode === "00") {
      success = true;
      message = response.data.responseDescription
        ? response.data.responseDescription
        : message;
    } else {
      message = response.data.responseDescription
        ? response?.data?.responseDescription
        : response?.message;
    }
    // let response = await axiosInstance.post(
    //   `api/buyer/sendReminder/${supplierId}`,
    //   payload
    // );

    // if (response.status == 200) {
    //   success = true;
    //   message = response.data.responseDescription
    //     ? response.data.responseDescription
    //     : message;
    // }
  }

  return {
    success: success,
    message: message,
  };
};

export default SendReminderSupplierBL;
