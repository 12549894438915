import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../../components/Notification";
import "../../Auction/styles/auction-table.css";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { store } from "../../../store/store";
import { v1 as uuidv1 } from "uuid";
import { convertDateFormat } from "../../../utility/utils";
import { Api } from "../../../api/Api";
import {
  COMAPNY_API,
  POST_SUPPLIER_SAVE_IMPORT_DATA_API,
} from "../../../constants/ApiUrl";
import {
  AUCTION_ACTIVE,
  AUCTION_ARCHIEVED,
  AUCTION_DRAFT,
  AUCTION_ENDED,
  AUCTION_ON_CANCELLED,
  AUCTION_ON_HOLD,
  AUCTION_SMALL_PUBLISHED,
  BLUE_HEX_COLOUR_CODE,
  DANGER_BACKGROUND,
  GREEN_HEX_COLOUR_CODE,
  PRIMARY_BACKGROUND,
  PURPLE_BACKGROUND,
  RFX_DECLINED,
  SECONDARY_BACKGROUND,
  SUCCESS_BACKGROUND,
  SUPPLIER_AUCTION_RUNNING,
  WARNING_BACKGROUND,
  WARNING_OUTLINE_BACKGROUND,
} from "../../../constants/AppConstants";
import rfpCover from "../../../assets/Rfx/RfpCover.png";
import rfiCover from "../../../assets/Rfx/RfiCover.png";
import rfqCover from "../../../assets/Rfx/RfqCover.png";
import rfxCover from "../../../assets/Rfx/RfxCover.png";
import {
  RFI_TYPE,
  RFP_TYPE,
  RFQ_TYPE,
} from "../../../services/socket/constants";
import { RFX_PROGRESS_MAP } from "../../../constants/AppConstants";
import { Slide, toast } from "react-toastify";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { TOAST_AUTO_CLOSE } from "../../../constants/AppConstants";

var columns = [
  {
    label: "ID",
  },
  {
    label: "Type",
  },
  {
    label: "Client Name",
  },
  {
    label: "RFX Name",
  },
  {
    label: "Status",
  },
  {
    label: "Start Date",
  },
  {
    label: "Buyer",
  },
  // {
  //   label: "Actions",
  // },
];

const RfxSellerTable = (props) => {
  const { rfxList, isBuyer, setResendApi, limit, page, setPage, numOfPages } =
    props;
  console.log("rfxList: ", rfxList);
  const history = useHistory();
  const [deleteAuctionId, setDeleteAuctionId] = React.useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const editAuction = (auctionId) => {
    history.push("/rfx/" + auctionId + "/details");
  };

  const DeleteRfx = (rfx) => {
    if (rfx._id && isBuyer) {
      setDeleteAuctionId(rfx?._id);
      setConfirmDialog({
        isOpen: true,
        title: rfx?.rfx_name,
        subTitle: "Are you sure to delete RFX?",
      });
    }
  };

  const confirmDeleteProject = async () => {
    if (deleteAuctionId && isBuyer) {
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;
      return;
      const response = await Api(
        `${COMAPNY_API}/${companyId}/auctions/${deleteAuctionId}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setResendApi(true);
        }
      }
    }
  };

  const getStatusLabel = (type) => {
    switch (type) {
      case AUCTION_ENDED:
        return WARNING_OUTLINE_BACKGROUND;
      case AUCTION_SMALL_PUBLISHED:
        return SUCCESS_BACKGROUND;
      case AUCTION_DRAFT:
        return SECONDARY_BACKGROUND;
      case SUPPLIER_AUCTION_RUNNING:
        return PRIMARY_BACKGROUND;
      case AUCTION_ON_HOLD:
        return WARNING_BACKGROUND;
      case AUCTION_ON_CANCELLED:
        return DANGER_BACKGROUND;
      case AUCTION_ARCHIEVED:
        return PURPLE_BACKGROUND;
      default:
        return PRIMARY_BACKGROUND;
    }
  };

  const getColor = (status) => {
    if (status === AUCTION_ACTIVE) {
      return BLUE_HEX_COLOUR_CODE;
    } else {
      return GREEN_HEX_COLOUR_CODE;
    }
  };

  const getStatusChange = async (status, rfxId) => {
    const payload = {
      status: status,
    };
    if (rfxId) {
      const { auth } = store.getState();
      const companyId = auth?.auth?.user?.company?._id;

      const response = await Api(
        `${POST_SUPPLIER_SAVE_IMPORT_DATA_API}/${companyId}/rfx/${rfxId}`,
        payload,
        "patch"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setResendApi(true);
        }
      }
    }
  };

  const navigateToRfxDetails = async (rfx) => {
    if (rfx.rfxStatus && rfx.rfxStatus !== RFX_DECLINED) {
      history.push("/supplier/rfx/" + rfx?.rfxId?._id + "/details");
    } else {
      toast.error(ValidationMessage.notAccessAuction, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };
  return (
    <>
      <Paper elevation={0} square>
        <TableContainer
          className="table-container buyer-aspect rfx-seller-table"
          component={Paper}
        >
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id ?? uuidv1()}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rfxList.map((rfx) => (
                <TableRow
                  key={rfx._id}
                  className="cursor-p"
                  onDoubleClick={() => navigateToRfxDetails(rfx)}
                >
                  <TableCell>{rfx?.rfxId?.rfxId}</TableCell>
                  <TableCell>
                    <div>
                      <img
                        className="rfx-cover-image"
                        src={
                          rfx?.rfxId?.rfx_type === RFP_TYPE
                            ? rfpCover
                            : rfx?.rfxId?.rfx_type === RFQ_TYPE
                            ? rfqCover
                            : rfx?.rfxId?.rfx_type === RFI_TYPE
                            ? rfiCover
                            : rfxCover
                        }
                        alt=""
                      />
                    </div>
                  </TableCell>
                  <TableCell>{rfx?.company_buyer_name}</TableCell>
                  <TableCell className="rfx-title">
                    <h3 className="show-wrap-table-text">
                      {rfx?.rfxId?.rfx_name}{" "}
                    </h3>
                  </TableCell>
                  <TableCell className="rfx-seller-status">
                    <Button
                      className={
                        "badge badge-" +
                        getStatusLabel(rfx?.rfxStatus) +
                        " status"
                      }
                      variant="contained"
                    >
                      {RFX_PROGRESS_MAP.get(rfx?.rfxStatus)}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {convertDateFormat(rfx?.rfxId?.startDateTS)}
                  </TableCell>
                  <TableCell>
                    {`${rfx?.rfxId?.createdBy?.first_name} ${rfx?.rfxId?.createdBy?.last_name}`}
                  </TableCell>
                  <TableCell className="rfx-action-tab">
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem
                                className="bold-line-600"
                                onClick={() => navigateToRfxDetails(rfx)}
                              >
                                <div>View</div>
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {rfxList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center">
                    No records found <br />
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <TablePaginationBox
            label={limit + " RFX Found"}
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => setPage(newPage)}
            page={page}
          />
        </TableContainer>
        <Notification notify={notify} setNotify={setNotify} />
      </Paper>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmDeleteProject={confirmDeleteProject}
      />
    </>
  );
};

export default RfxSellerTable;
