import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Formsteps from "../../components/Formsteps";
import { useDispatch } from "react-redux";
import { addFirstForm } from "../../store/actions/ProjectActions";
import { store } from "../../store/store";
import "./styles/create-project-step1.css";
import { InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { ValidationMessage } from "../../constants/ValidationMessage";
import moment from "moment";

const initialFValues = {
  project_name: "",
  description: "",
  location: "",
};

export default function ProjectForm(props) {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateError, setDateError] = useState("");
  const {
    recordForEdit,
    setOpenPopup,
    setOpenPopup2,
    stepLabels,
    addOrEdit,
    setSaveProjectDraft,
    clearFormDataOnModalClose,
  } = props;

  const [saveAsDraftStep1, setSaveAsDraftStep1] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("project_name" in fieldValues) {
      if (fieldValues.project_name && fieldValues.project_name.length > 0) {
        if (fieldValues.project_name.length > 25) {
          temp.project_name = "Project name must not exceed 25 characters";
        } else {
          temp.project_name = "";
        }
      } else {
        temp.project_name = "Project Name is required.";
      }
    }
    if ("description" in fieldValues) {
      if (fieldValues.description && fieldValues.description.length > 0) {
        if (fieldValues.description.length > 80) {
          temp.description = "Description must not exceed 80 characters";
        } else {
          temp.description = "";
        }
      } else {
        temp.description = "Description is required.";
      }
    }

    if ("location" in fieldValues)
      temp.location =
        fieldValues.location.length !== 0 ? "" : "Location is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    values.startDate = startDate;
    values.endDate = endDate;

    if (startDate && endDate) {
      let dateValid = dateValidationHandler(startDate, endDate);
      if (!dateValid) return;
      if (validate()) {
        if (!saveAsDraftStep1) {
          dispatch(addFirstForm(values));
          setOpenPopup2(true);
          resetForm();
          setOpenPopup(false);
        } else {
          addOrEdit(values, resetForm);
          setOpenPopup(false);
        }
      }
    }
  };

  useEffect(() => {
    const { projects } = store.getState();
    if (recordForEdit !== null) {
      setValues({
        ...recordForEdit,
      });
    } else {
      if (projects?.projectsFirstForm?.project_name) {
        setValues({
          project_name: projects?.projectsFirstForm?.project_name,
          description: projects?.projectsFirstForm?.description,
          location: projects?.projectsFirstForm?.location,
        });
        setStartDate(projects?.projectsFirstForm?.startDate);
        setEndDate(projects?.projectsFirstForm?.endDate);
      }
    }
  }, [recordForEdit]);

  const dateValidationHandler = (start, end) => {
    const tempStartDate = moment(start).startOf("day").valueOf();
    const tempEndDate = moment(end).endOf("day").valueOf();

    if (start && end) {
      if (tempStartDate > tempEndDate) {
        setDateError(ValidationMessage.projectStartDateError);
        return false;
      } else {
        setDateError("");
        return true;
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container justifyContent="center">
          <Grid item xl={5} lg={6} md={7} sm={8} xs={12}>
            <Formsteps
              steps={stepLabels}
              currStep={1}
              className="mt-5 mb-5"
            ></Formsteps>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mt-3">
          <Grid item xs={12}>
            <InputLabel>
              Project Name<span className="mandatorySign">*</span>
            </InputLabel>
            <Controls.Input
              name="project_name"
              value={values.project_name}
              onChange={handleInputChange}
              error={errors.project_name}
              inputProps={{ maxLength: 60 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              Description<span className="mandatorySign">*</span>
            </InputLabel>
            <Controls.Input
              name="description"
              value={values.description}
              onChange={handleInputChange}
              error={errors.description}
              multiline="multiline"
              maxRows={4}
              className="fix-h-12em"
              inputProps={{ maxLength: 499 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>
                      Start Date<span className="mandatorySign">*</span>
                    </InputLabel>
                    {/* <Controls.DatePicker
                      name="startDate"
                      value={values.startDate}
                      onChange={handleInputChange}
                      format="DD-MM-YYYY"
                      className="mui-date-picker-reverse"
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        id="startDate"
                        variant="inline"
                        inputVariant="outlined"
                        value={startDate}
                        // disablePast
                        className="mui-input mui-date-picker-reverse"
                        format="MMM/dd/yyyy"
                        onChange={(newValue) => {
                          setStartDate(newValue);
                          // if (newValue > endDate) {
                          //   setEndDate(newValue);
                          // }
                          dateValidationHandler(newValue, newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>
                      End Date<span className="mandatorySign">*</span>
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        id="endDate"
                        variant="inline"
                        inputVariant="outlined"
                        value={endDate}
                        // minDate={startDate}
                        // disablePast
                        className="mui-input mui-date-picker-reverse"
                        format="MMM/dd/yyyy"
                        onChange={(newValue) => {
                          setEndDate(newValue);
                          dateValidationHandler(startDate, newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel>
                  Location<span className="mandatorySign">*</span>
                </InputLabel>
                <Controls.Input
                  name="location"
                  value={values.location}
                  onChange={handleInputChange}
                  error={errors.location}
                  inputProps={{ maxLength: 40 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
            </Grid>
            {dateError && dateError.length > 0 && (
              <p className="textfield-error-style">{dateError}</p>
            )}
          </Grid>
        </Grid>
        <div id="bottom-button-box-container">
          <div id="right-side">
            <Controls.Button
              text="Cancel"
              onClick={() => {
                clearFormDataOnModalClose();
                setOpenPopup(false);
              }}
              disableElevation
              className="m-0 btn btn-mui w-100"
              style={{
                backgroundColor: "#E2F2FF",
                color: "#2170FF",
              }}
            />
          </div>
          <div id="left-side">
            {/* <div>
              <Controls.Button
                text="Save as Draft"
                disableElevation
                type="submit"
                color="default"
                onClick={() => {
                  setSaveProjectDraft(true);
                  setSaveAsDraftStep1(true);
                }}
                className="m-0 btn btn-mui draft w-100"
              />
            </div> */}
            <div>
              <Controls.Button
                disableElevation
                type="submit"
                text="Next"
                className="m-0 btn btn-mui w-100"
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
