/*eslint-disable */
import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import useWindowDimensions from "../../components/useWindowDimensions";
import Appbars from "../../components/Appbar";
import { Paper, makeStyles, Box, Grid, Container } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CompanyBlackIcon from "../../assets/settings/CompantUsersBlack.png";
import CompanyWhiteIcon from "../../assets/settings/CompantUsersWhite.png";
import ItemsBlackIcon from "../../assets/settings/ItemsBlack.png";
import ItemsWhiteIcon from "../../assets/settings/ItemsWhite.png";
import SuppliersWhiteIcon from "../../assets/settings/SuppliersWhite.png";
import SuppliersBlackIcon from "../../assets/settings/SuppliersBlack.png";
import CostCentersBlackIcon from "../../assets/settings/CostCentersBlack.png";
import CostCentersWhiteIcon from "../../assets/settings/CostCentersWhite.png";
import BUBlackIcon from "../../assets/settings/BUBlack.png";
import BUWhiteIcon from "../../assets/settings/BUWhite.png";
import TagsBlackIcon from "../../assets/settings/TagsBlack.png";
import TagsWhiteIcon from "../../assets/settings/TagsWhite.png";
import LibBlackIcon from "../../assets/settings/LibBlack.png";
import LibWhiteIcon from "../../assets/settings/LibWhite.png";
import ProfileBlackIcon from "../../assets/settings/ProfileBlack.png";
import ProfileWhiteIcon from "../../assets/settings/ProfileWhite.png";
import Typography from "@mui/material/Typography";
import Copyright from "../../components/Copyright";
import MyProfile from "./settingProfile";
import CompanyInfo from "./settingCompanyInfo";
import ItemTable from "./settingItems";
import Suppliers from "./settingSuppliers";
import CostCenters from "./settingCostCenters";
import BusinessUnit from "./settingBusinessUnit";
import Tags from "./settingTags";
import Library from "./settingLibrary";
import CompanyAttachments from "./components/companyAttachments";
import Loader from "../../components/Loader/Loader";
import {
  isAdmin,
  isBuyer,
  isSeller,
  isSuperAdmin,
} from "../../store/selectors/RoleSelector";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import "./styles/index.css";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-label={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: index != 1 ? 3 : 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Setting = (props) => {
  const { isAdmin, isSeller, isBuyer, currentRole, isSuperAdmin } = props;
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [fullLoder, setFullLoder] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { smScreen } = useWindowDimensions();
  const BudgetTabIX = 1;

  useEffect(() => {
    if (location?.state?.showProfile) {
      setValue(0);
    }
  }, [location]);
  const mainTabsList = (value) => {
    if (isSuperAdmin) {
      return [
        <Tab
          icon={<img src={value == 0 ? ProfileWhiteIcon : ProfileBlackIcon} />}
          label="My Profile"
          className="TabMenu"
        />,
      ];
    } else {
      return [
        <Tab
          icon={<img src={value == 0 ? ProfileWhiteIcon : ProfileBlackIcon} />}
          label="My Profile"
          className="TabMenu"
        />,
        <Tab
          icon={<img src={value == 1 ? CompanyWhiteIcon : CompanyBlackIcon} />}
          label="Company Info"
          className="TabMenu"
        />,
        <Tab
          icon={<img src={value == 2 ? ItemsWhiteIcon : ItemsBlackIcon} />}
          label="Items"
          className="TabMenu"
        />,
        <Tab
          icon={
            <img src={value == 3 ? SuppliersWhiteIcon : SuppliersBlackIcon} />
          }
          label="Suppliers"
          className="TabMenu"
        />,
        <Tab
          icon={
            <img
              src={value == 4 ? CostCentersWhiteIcon : CostCentersBlackIcon}
            />
          }
          label="Cost Centers"
          className="TabMenu"
        />,
        <Tab
          icon={<img src={value == 5 ? BUWhiteIcon : BUBlackIcon} />}
          label="Business Unit"
          className="TabMenu"
        />,
        <Tab
          icon={<img src={value == 6 ? TagsWhiteIcon : TagsBlackIcon} />}
          label="Tags"
          className="TabMenu"
        />,
        <Tab
          icon={<img src={value == 7 ? LibWhiteIcon : LibBlackIcon} />}
          label="Library"
          className="TabMenu"
        />,
      ];
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Appbars></Appbars>
      <main className="app-main-section-container">
        {fullLoder && <Loader />}
        <div className={classes.appBarSpacer} />
        <Container>
          <div style={{ marginTop: "2rem" }}>
            <h2 className="fz-32 mt-4 mb-4" style={{ color: "var(---424143)" }}>
              Settings
            </h2>
          </div>
          <Grid
            container
            style={{ justifyContent: smScreen ? "center" : "space-between" }}
            className="settings-index-section-contaier"
          >
            <Grid item className="settings-index-section-tab-labels">
              <Paper elevation={0} className="BlocTabMenuContainer">
                <Tabs
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  aria-label="settings-index-section-tab-labels-block"
                >
                  {mainTabsList(value).map((item) => {
                    return item;
                  })}
                </Tabs>
              </Paper>
            </Grid>
            <Grid
              item
              className={
                "BlocInfos settings-index-section-tab-content  " +
                (smScreen ? "mt-5" : "")
              }
            >
              <Paper
                className={
                  value === BudgetTabIX
                    ? "page-chart-container__hide"
                    : "page-chart-container__view"
                }
              >
                <TabPanel value={value} index={0}>
                  <MyProfile setFullLoder={setFullLoder} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <CompanyInfo setFullLoder={setFullLoder} />
                  {!isAdmin ? (
                    <></>
                  ) : (
                    <CompanyAttachments setFullLoder={setFullLoder} />
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ItemTable setFullLoder={setFullLoder} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Suppliers setFullLoder={setFullLoder} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <CostCenters setFullLoder={setFullLoder} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <BusinessUnit setFullLoder={setFullLoder} />
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <Tags setFullLoder={setFullLoder} />
                </TabPanel>
                <TabPanel value={value} index={7}>
                  <Library setFullLoder={setFullLoder} />
                </TabPanel>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg" className={classes.container}>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    isSuperAdmin: isSuperAdmin(state),
    currentRole: getCurrentRole(state),
  };
};

export default withRouter(connect(mapStateToProps)(Setting));
