import { Api } from "../../../../api/Api";
import { GET_ADMIN_USER_API } from "../../../../constants/ApiUrl";

const GetAllUsersBL = async (props) => {
  const { currentUser, page, rowsPerPage, searchKey, filterValues } = props;

  let list = [];
  let limit = 0;

  let path = `${GET_ADMIN_USER_API}?per_page=${rowsPerPage}&page=${page}`;

  if (searchKey !== "") {
    path += `&searchKey=${searchKey}`;
  }

  // if (filterValues.client != "-1") {
  //   path += `&client=${filterValues.client}`;
  // }

  // if (filterValues.status != "-1") {
  //   path += `&status=${filterValues.status}`;
  // }

  const response = await Api(path, {}, "get");
  if (response.status === 200) {
    if (response.data && response.data.users !== undefined) {
      list = response.data.users;
      limit = response.data.totalRecords;
    }
  }

  // const response = await axiosInstance.get(path);

  return {
    list: list,
    limit: limit,
  };
};

export default GetAllUsersBL;
