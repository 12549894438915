export const getSocketInstance = (state) => {
  if (state.socketIO) {
    return state.socketIO.instance;
  }
  return {};
};

export const currentSocketStatus = (state) => {
  if (state.socketIO) {
    return state.socketIO.connected;
  }
  return {};
};

export const topSocketEvent = (state) => {
  if (state.socketIO) {
    return state.socketIO.lastEvent;
  }
  return "";
};
