import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./CommonButton";
import ActionButton from "./ActionButton";
import DateRangePicker from "./BasicDateRangePicker";
import DateTimePicker from "./BasicDateTimePicker";
import BasicDatePicker from "./BasicDatePicker";

const Controls = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  DatePicker,
  Button,
  ActionButton,
  DateRangePicker,
  DateTimePicker,
  BasicDatePicker,
};

export default Controls;
