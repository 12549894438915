export const getBestLiveBid = (state) => {
  return state.liveBid.best;
};

export const getNewestLiveBid = (state) => {
  return state.liveBid.new;
};

export const isLiveSentEventSuccess = (state) => {
  return state.liveBid.success;
};


export const getLiveEventStatus = (state) => {
  return {
    success: state.liveBid.success,
    message: state.liveBid.message,
    status: state.liveBid.status,
  };
};