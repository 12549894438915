/*eslint-disable */
import * as React from "react";
import { Box, createStyles, Grid, makeStyles } from "@material-ui/core";
import {
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import useWindowDimensions from "../../../components/useWindowDimensions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import UserImage from "../../../assets/auth/user_icon_2.png";
import { Search } from "@mui/icons-material";
import TableOptionList from "../../../components/TableOptionList";
import TablePaginationBox from "../../../components/TablePaginationBox";
import SupplierNewContactPopup from "./supplierNewContactPopup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import "../styles/supplier-list-info-details.css";
import { useSelector } from "react-redux";
import { Api } from "../../../api/Api";
import {
  DELETE_CONTACT_SUPPLIER_API,
  GET_CONTACT_SUPPLIER_LIST_API,
} from "../../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";
import { get } from "react-hook-form";

const columns = [
  {
    label: "Name",
  },
  {
    label: "Email",
    // minWidth: 100
  },
  {
    label: "Phone",
  },
  // {
  //   label: "Added By",
  // },
  {
    label: "",
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    contactNameCol: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("xs")]: {
        width: "100px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "210px",
      },
    },
  })
);

export default function SupplierListDetailsContact(props) {
  const { supplier, setNotify } = props;
  const classes = useStyles();
  const userProfileImage = useSelector(
    (state) => state?.auth?.auth?.user?.image_upload
  );
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const [limit, setLimit] = React.useState(0);
  const [page, setPage] = React.useState(1);
  
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [numOfPages, setNumOfPages] = React.useState(0);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [contactEditData, setContactEditData] = React.useState({});
  const [isContactEdit, setIsContactEdit] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [alertDailog, setAlertDailog] = React.useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = React.useState({});
  const handleClose = () => setAlertDailog(false);

  const getAllContact = async (searchText) => {
    let queryString = "";
    if (searchText) {
      queryString = `&search=${searchText}`;
    }
    if (supplier && supplier?._id) {
      const response = await Api(
        `${GET_CONTACT_SUPPLIER_LIST_API}/${supplier?._id}/${supplier?.supplier_company?._id}?per_page=${rowsPerPage}&page=${page}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let ContectsuppliersList = response.data.contactSupplierList;
          setList(ContectsuppliersList);
          setLimit(response?.data?.totalRecord);
        } else {
          setList([]);
          setLimit(0);
        }
      } else {
        setList([]);
        setLimit(0);
      }
    } else {
      setList([]);
    }
  };

  const editContact = (row) => {
    if (row?.type === "contact") {
      setContactEditData(row);
      setIsContactEdit(true);
      setOpenPopup(true);
    } else {
      toast.error(ValidationMessage.notEditUser, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  React.useEffect(() => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  }, [limit, list]);

  React.useEffect(() => {
    getAllContact();
  }, [supplier, page]);

  const getSearchString = (e) => {
    const searchText = e.target.value;
    getAllContact(searchText);
  };

  const deleteDocument = async (attachment) => {
    if (attachment) {
      setAlertDailog(true);
      setDeleteAttachmentId(attachment);
    }
  };

  const confirmDeleteContact = async () => {
    if (deleteAttachmentId) {
      const response = await Api(
        `${DELETE_CONTACT_SUPPLIER_API}/${deleteAttachmentId._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          setAlertDailog(false);
          setDeleteAttachmentId({});
          // getAllContact();
        }
        const numOfPages = Math.ceil(list.length / rowsPerPage) || 1;
        if (list.length - 1 === 0) {
          if (page > numOfPages) {
            setPage(numOfPages);
          } else {
            getAllContact();
          }
        } else {
          getAllContact();
        }
      }
    }
  };

  return (
    <>
      {supplier && supplier.status ? (
        <Grid container className="supplier-list-details-info-container">
          <Grid item xs={12}>
            <div>
              <Grid container>
                <Grid item md={4} sm={5} xs={12}>
                  <p className="profile-title" style={{ borderBottom: "none" }}>
                    Contacts
                  </p>
                </Grid>
              </Grid>
              <Grid container className="table-header-container">
                <Grid
                  item
                  lg={6}
                  xs={12}
                  style={{ textAlign: mdScreen ? "center" : "start" }}
                >
                  <TextField
                    onChange={getSearchString}
                    placeholder="Search Using Keyword "
                    className=""
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  style={{
                    marginTop: mdScreen ? "10px" : "0px",
                    textAlign: mdScreen ? "center" : "end",
                  }}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={(event) => setOpenPopup(true)}
                    className="btn-mui-table-option"
                  >
                    <AddOutlinedIcon style={{ fontSize: "20px" }} />
                    <span>Add Contact</span>
                  </Button>
                </Grid>
              </Grid>
              <TableContainer className="table-container" component={Paper}>
                <Table
                  sx={{ minWidth: 300 }}
                  aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontWeight: "700",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((contact) => (
                      <TableRow key={contact._id}>
                        <TableCell className="supplier-detail-contact-name">
                          <span className={classes.contactNameCol}>
                            <img
                              src={contact?.image_upload || UserImage}
                              style={{
                                width: "2.7em",
                                height: "2.7em",
                                borderRadius: "50%",
                                objectFit: "cover",
                                marginRight: "10px",
                              }}
                            />
                            <p className="simple-word-break mw100">
                              {contact?.firstName || contact?.first_name}{" "}
                              {contact?.lastName || contact?.last_name}
                            </p>
                          </span>
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {contact.email}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {contact.phone}
                        </TableCell>
                        {/* <TableCell
                          component="th"
                          scope="row"
                          className="text-center"
                        >
                          <img
                            src={contact?.createdBy?.image_upload || UserImage}
                            style={{
                              width: "2.7em",
                              height: "2.7em",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        </TableCell> */}
                        <TableCell>
                          <Box>
                            <PopupState
                              variant="popover"
                              popupId="supplier-list-info-details-option-list"
                            >
                              {(popupState) => (
                                <div>
                                  <Button
                                    className="action-btn"
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                  >
                                    <MoreVertIcon />
                                  </Button>
                                  <TableOptionList {...bindMenu(popupState)}>
                                    <MenuItem
                                      className="bold-line-600"
                                      onClick={() => {
                                        editContact(contact);
                                        popupState.close();
                                      }}
                                    >
                                      Edit Contact
                                    </MenuItem>
                                    <MenuItem
                                      className="bold-line-600"
                                      style={{ color: "red" }}
                                      onClick={() => {
                                        deleteDocument(contact);
                                        popupState.close();
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </TableOptionList>
                                </div>
                              )}
                            </PopupState>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                    {list.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          className="text-center"
                        >
                          No records found <br />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
                <TablePaginationBox
                  label={limit + " Contacts Found"}
                  numOfPages={numOfPages}
                  onChangePage={(event, newPage) => setPage(newPage)}
                  page={page}
                />
              </TableContainer>
              <SupplierNewContactPopup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                getAllContact={getAllContact}
                setNotify={setNotify}
                supplier={supplier}
                contactEditData={contactEditData}
                setContactEditData={setContactEditData}
                isContactEdit={isContactEdit}
                setIsContactEdit={setIsContactEdit}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: "1600" }}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete contact?
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() => setAlertDailog(false)}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={() => confirmDeleteContact()}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
