import React, { useEffect, useState, useReducer } from "react";
import ProjectForm from "./ItemForm";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import Search from "@material-ui/icons/Search";
import Stack from "@mui/material/Stack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@mui/icons-material/Close";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import { connect, useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../services/AxiosInstance";
import ProjectFormstep2 from "./ItemFormstep2";
import ItemTable from "./itemTable";
import Modal from "@mui/material/Modal";
import ItemDetails from "./itemDetails";
import { store } from "../../store/store";
import Loader from "../../components/Loader";
import "./item.css";
import "../../styles/button.css";
import "../../styles/input.css";
import ItemFilter from "./filter/itemFilter";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Moment from "moment";
import { Api } from "../../api/Api";
import { GET_ITEM_API } from "../../constants/ApiUrl";
import { downloadDocumentFile } from "../../utility/utils";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "100%",
    border: "0px solid #ccc",
    [`& fieldset`]: {
      borderRadius: 6,
    },
  },
  selectAction: {
    "& .MuiInputLabel-root": {
      display: "none",
    },
  },
  toolbars: {
    "& .MuiToolbar-gutters": {
      padding: "12px",
      margin: "0px",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },

  // Forms
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    "& .MuiDialogTitle-root": {
      padding: "1px",
    },
  },
  dialogTitle: {
    paddingRight: "0px",
    fontWeight: "600px",
  },
  dialog: {
    "& .MuiDialogContent-dividers ": {
      borderTop: "0px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "0px solid rgba(0, 0, 0, 0.12)",
    },
  },
  sectionText: {
    fontWeight: "600",
    fontSize: "24px",
  },
  // Header
  root: {
    backgroundColor: "transparent",
  },
  wrapper: {
    borderRadius: "12px",
    padding: "0 12px",
    backgroundColor: "red",
  },
  pageHeader: {
    padding: theme.spacing(1),
    display: "flex",
    marginBottom: theme.spacing(0),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#2170ff",
  },
  pname: { color: theme.palette.primary.main, fontWeight: "bold" },
  pdesc: { opacity: "0.5" },

  pageTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  warning: { backgroundColor: theme.palette.primary.warning },
  actionbtnRight: {
    position: "relative",
    top: 10,
    right: 10,
  },
  menupos: {
    // position: "relative",
    // right: "-18px",
  },
  filterDiv: {
    width: "100%",
    padding: "1rem",
    margin: ".3rem",
  },
  searchBar: {
    width: "100%",
    margin: "1rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  sort: {
    display: "flex",
  },
}));

const stepLabels = ["Initiation", "Details"];

const ItemList = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [isShowItemDetails, setIsShowItemDetails] = useState(false);
  const [fullLoder, setFullLoder] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  React.useImperativeHandle(ref, () => ({
    foo() {
      alert("Hello world");
    },
  }));

  const getAllItemsByCompanyName = async (searchText) => {
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    const state = store.getState();

    const companyId = state?.auth?.auth?.user?.company?._id;

    if (companyId) {
      setFullLoder(true);
      const response = await Api(
        `${GET_ITEM_API}/${companyId}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allItems = response.data.item;

          const query = setTimeout(() => {
            setItemList(allItems);
          }, 400);
          const loader = setTimeout(() => {
            setFullLoder(false);
          }, 500);

          return () => {
            clearTimeout(query);
            clearTimeout(loader);
          };
        }
      } else {
        setItemList([]);
        setFullLoder(false);
      }

      // await axiosInstance
      //   .get(`api/buyer/item/${companyId}${queryString}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let allItems = response.data.item;

      //       const query = setTimeout(() => {
      //         setItemList(allItems);
      //       }, 400);
      //       const loader = setTimeout(() => {
      //         setFullLoder(false);
      //       }, 500);

      //       return () => {
      //         clearTimeout(query);
      //         clearTimeout(loader);
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     setItemList([]);

      //     setFullLoder(false);
      //   });
    }
  };

  const requestSearch = (searchedVal) => {
    const textVal = searchedVal.target.value;

    if (textVal) {
      const filteredRows = itemList.filter((row) => {
        return (
          row?.itemId?.includes(textVal) ||
          row?.item_name.toLowerCase()?.includes(textVal.toLowerCase()) ||
          row?.manufacturer.toLowerCase()?.includes(textVal.toLowerCase()) ||
          row?.model.toLowerCase()?.includes(textVal.toLowerCase()) ||
          row?.unit.toLowerCase()?.includes(textVal.toLowerCase())
        );
      });
      setItemList(filteredRows);
    } else {
      getAllItemsByCompanyName("");
    }
  };

  const ExportToExcel = async () => {
    setFullLoder(true);
    let itemFilter = JSON.parse(localStorage.getItem("itemFilter"));
    const { auth } = store.getState();

    const companyId = auth?.auth?.user?.company?._id;

    let string = "?exportToExcel=1";
    const values = itemFilter;

    if (values) {
      for (let [key, value] of Object.entries(values)) {
        string += `&${key}=${value}`;
      }
    }
    const response = await Api(
      `${GET_ITEM_API}/${companyId}${string}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        if (response?.data?.data?.resourceUrl.length > 0) {
          downloadDocumentFile(response?.data?.data?.resourceUrl, "Items");
        }
      }
      setFullLoder(false);
    } else {
      setFullLoder(false);
    }

    // axiosInstance
    //   .get(`api/buyer/item/${companyId}${string}`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       if (response?.data?.data?.resourceUrl.length > 0) {
    //         setNotify({
    //           isOpen: true,
    //           message: response.data.responseDescription,
    //           type: "success",
    //         });
    //         const link = document.createElement("a");
    //         link.href = response?.data?.data?.resourceUrl;
    //         link.target = "_blank";
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //       } else {
    //         setNotify({
    //           isOpen: true,
    //           message: response.data.responseDescription,
    //           type: "error",
    //         });
    //       }
    //     } else {
    //       setNotify({
    //         isOpen: true,
    //         message: response.data.responseDescription,
    //         type: "error",
    //       });
    //     }
    //     setFullLoder(false);
    //   })
    //   .catch((err) => {
    //     setFullLoder(false);
    //     setNotify({
    //       isOpen: true,
    //       message: err?.response?.data?.responseDescription,
    //       type: "error",
    //     });
    //   });
  };

  return (
    <>
      {/* <Container maxWidth={false}> */}
      <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
      {!isShowItemDetails ? (
        <div id="item-table-list-buyer-aspect">
          <Paper elevation={0} square className={classes.root}>
            <Grid container className="filter-table-header">
              <Grid item md={4} sm={5} xs={12}>
                <div className="title"> Items</div>
              </Grid>
              <Grid item md={8} sm={7} xs={12} className="buttons-box">
                <Controls.Button
                  variant="contained"
                  className={
                    "btn btn-white btn-mui" +
                    (showFilterCategories ? " active" : "")
                  }
                  text="Filter"
                  onClick={toggleFilterCategory}
                  startIcon={
                    <FilterListSharpIcon style={{ color: "#2170FF" }} />
                  }
                />
                <Controls.Button
                  text="Export"
                  className="btn btn-success btn-mui"
                  startIcon={<SystemUpdateAltIcon />}
                  onClick={() => {
                    ExportToExcel();
                  }}
                />
                <Controls.Button
                  text="Add Item"
                  className="btn-mui"
                  variant="contained"
                  startIcon={<AddIcon />}
                  // endIcon={<ArrowDropDownIcon />}
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className="filter-box-container">
              {showFilterCategories && (
                <Paper elevation={1} className="filter-box">
                  <ItemFilter
                    setItemList={setItemList}
                    setShowFilterCategories={setShowFilterCategories}
                  />
                </Paper>
              )}
            </Grid>
          </Paper>
          <Paper elevation={0} square>
            <Box sx={{ padding: "24px" }}>
              <Grid xs={12}>
                <Box sx={{ display: "inline" }}>
                  <TextField
                    // onChange={getSearchString}
                    // onChange={(searchVal) => requestSearch(searchVal)}
                    onChange={requestSearch}
                    placeholder="Search Using Keyword"
                    className="select-box select-box-primary search-box"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={{ float: "right" }}>
                  <Select
                    value={rowsPerPage}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="items-count-dropdown"
                  >
                    {/* <MenuItem value="">
                      <em>Show 10 entries</em>
                    </MenuItem> */}
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <ItemTable
                recordForEdit={recordForEdit}
                // openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                openPopup2={openPopup2}
                setOpenPopup2={setOpenPopup2}
                setRecordForEdit={setRecordForEdit}
                setItemList={setItemList}
                itemList={itemList}
                setIsShowItemDetails={setIsShowItemDetails}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Box>
          </Paper>
        </div>
      ) : (
        <ItemDetails
          setIsShowItemDetails={setIsShowItemDetails}
          setRecordForEdit={setRecordForEdit}
        />
      )}

      {/* Form 1 */}
      <Modal
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        aria-label="create-item-first-step-buyer-aspect"
        aria-describedby="create-item-first-step-buyer-aspect-description"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> New Item</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopup(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <ProjectForm
            recordForEdit={recordForEdit}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            openPopup2={openPopup2}
            setOpenPopup2={setOpenPopup2}
            stepLabels={stepLabels}
            setNotify={setNotify}
            getAllItemsByCompanyName={getAllItemsByCompanyName}
          />
        </Box>
      </Modal>

      {/* Form 2 */}
      <Modal
        open={openPopup2}
        aria-label="create-item-second-step-buyer-aspect"
        aria-describedby="create-item-second-step-buyer-aspect-description"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title">Add Item</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopup2(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <ProjectFormstep2
            // openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            openPopup2={openPopup2}
            setOpenPopup2={setOpenPopup2}
            recordForEdit={recordForEdit}
            setUpdateList={setUpdateList}
            setItemList={setItemList}
            stepLabels={stepLabels}
            setFullLoder={setFullLoder}
            fullLoder={fullLoder}
          />
        </Box>
      </Modal>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    editData: state.projects.editData,
  };
};
export default connect(mapStateToProps)(ItemList);
