import { useState, useEffect } from "react";
import { useForm, Form } from "../../components/useForm";
// import FormSteps from "../../components/FormStepAuction";
import FormSteps from "../../components/Formsteps";
import { Box, Grid, Divider, Tooltip } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import Collapse from "@mui/material/Collapse";
import Controls from "../../components/controls/Controls";
import * as employeeService from "../../services/employeeService";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import { useDispatch } from "react-redux";
import {
  FormStep2Add,
  auctionSaveAsDraft,
} from "../../store/actions/auctionAction";
import { store } from "../../store/store";
import ConfirmationDailog from "./components/confirmationDailog";
import CloseIcon from "@mui/icons-material/Close";
import "./styles/create-auction-step2.css";
import NumericSeparatorInput from "../../components/NumericSeparatorInput";
import { removeNullValueForPaylaod } from "../../utility/utils";
import { Api } from "../../api/Api";
import {
  GET_COST_BUDGET_API,
  GET_COST_CENTER_API,
} from "../../constants/ApiUrl";
import moment from "moment";

const initialValues = {
  starting_price: "",
  const_center: "",
  currency: "",
  budget: "",
  min_step: "",
  cool_down_period: 0,
  show_to_supplier: 1,
  reserve_price: 0,
  supplier_bid: "",
  supplier_name: "",
  no_of_participants: 1,
  supplier_stating_price_event: "",
  deliveryDate: "",
};

// const hint =
//   "To get started. add the name of request choose when it starts, what it ends, a minimum step, cool-down period and describe what you want to buy.";
const hint = "Disclose the BID amount of other suppliers to all participants";
const Step2Form = (props) => {
  const {
    setOpenPopupEnglishAuction,
    setOpenPopupStep2,
    setOpenPopupStep3,
    stepLabels,
    setNotify,
    setResendApi,
    projects,
  } = props;
  const [dropdownCostCenter, setDropdownCostCenter] = useState([]);
  const [awardLabel, setAwardLabel] = useState(true);
  const [showAdvSetting, setShowAdvSetting] = useState(false);
  const [auctionSaveDraft, setauctionSaveDraft] = useState(false);
  const [auctionBudget, setAuctionBudget] = useState(0);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [supplierNameCheckBox, setSupplierNameCheckBox] = useState(false);
  const [supplierBidsCheckBox, setSupplierBidsCheckBox] = useState(false);
  const [supplierPriceEventCheckBox, setSupplierPriceEventCheckBox] =
    useState(false);
  const [openConfrmationDailog, setOpenConfrmationDailog] = useState(false);
  const dispatch = useDispatch();
  const [deliveryError, setDeliveryError] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("starting_price" in fieldValues) {
      if (fieldValues.starting_price !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.starting_price)) {
          temp.starting_price = "";
        } else {
          temp.starting_price = "Only Number allow";
        }
      } else {
        temp.starting_price = "This field is required.";
      }
    }

    if ("currency" in fieldValues)
      temp.currency = fieldValues.currency ? "" : "This field is required.";


    if ("min_step" in fieldValues) {
      if (fieldValues.min_step !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.min_step)) {
          temp.min_step = "";
        } else {
          temp.min_step = "Only Number allow";
        }
      } else {
        temp.min_step = "This field is required.";
      }
    }

    if ("cool_down_period" in fieldValues) {
      if (fieldValues.cool_down_period !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.cool_down_period)) {
          temp.cool_down_period = "";
          if (fieldValues.cool_down_period > 1440) {
            temp.cool_down_period =
              "Cool down period must be less than 1440 minutes";
          } else {
            temp.cool_down_period = "";
          }
        } else {
          temp.cool_down_period = "Only Number allow";
        }
      } else {
        temp.cool_down_period = "This field is required.";
      }
    }

    if ("reserve_price" in fieldValues) {
      if (/^[0-9\s]*$/.test(fieldValues.reserve_price)) {
        temp.reserve_price = "";
      } else {
        temp.reserve_price = "Only Number allow";
      }
    }

    if ("no_of_participants" in fieldValues) {
      if (/^[0-9\s]*$/.test(fieldValues.no_of_participants)) {
        temp.no_of_participants = "";
      } else {
        temp.no_of_participants = "Only Number allow";
      }
    }

    if ("deliveryDate" in fieldValues) {
      let todayDate = moment().format("MM-DD-YYYY");

      let deliveryDateFormatted = moment(deliveryDate).format("MM-DD-YYYY");

      if (moment(todayDate).isAfter(deliveryDateFormatted)) {
        temp.deliveryDate = "Delivery date must be greater than current date";
      } else {
        temp.deliveryDate = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      if (!auctionSaveDraft) {
        values.supplier_stating_price_event = supplierPriceEventCheckBox;
        values.supplier_bid = supplierBidsCheckBox;
        values.supplier_name = supplierNameCheckBox;
        values.awarding_commitment = awardLabel;
        values.deliveryDate = deliveryDate;

        const finalValues = removeNullValueForPaylaod(values);

        dispatch(FormStep2Add(finalValues));
        setOpenPopupStep2(false);
        setOpenPopupStep3(true);
      } else {
        values.supplier_stating_price_event = supplierPriceEventCheckBox;
        values.supplier_bid = supplierBidsCheckBox;
        values.supplier_name = supplierNameCheckBox;
        values.awarding_commitment = awardLabel;
        values.deliveryDate = deliveryDate;
        const finalValues = removeNullValueForPaylaod(values);

        dispatch(FormStep2Add(finalValues));
        let res = await dispatch(auctionSaveAsDraft(finalValues));

        if (res?.data?.responseCode === "00") {
          if (setResendApi) {
            setResendApi(true);
          }
          setOpenPopupStep2(false);
        } else {
          setOpenPopupStep2(false);
        }
      }
    }
  };

  const PressBack = () => {
    setOpenPopupStep2(false);
    setOpenPopupEnglishAuction(true);
  };

  const getAllCostCenter = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_COST_CENTER_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let costCenterList = response.data.costCenter;
          setDropdownCostCenter(costCenterList);
        }
      }
    }
  };

  const getBudgetForAuction = async (e) => {
    const constCenterId = e.target.value;
    if (constCenterId) {
      const { auction } = store.getState();
      const projectId = auction?.AuctionForm1?.project_id;
      if (projectId) {
        const response = await Api(
          `${GET_COST_BUDGET_API}/${projectId}/${constCenterId}`,
          {},
          "get"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            // const allBudget = response?.data?.project;
            // const result = allBudget.reduce(
            //   (total, currentValue) => (total = total + currentValue.budget),
            //   0
            // );
            // setAuctionBudget(parseInt(result));
            setValues({
              ...values,
              // budget: result,
              const_center: constCenterId,
            });
          } else {
            setValues({
              ...values,
              const_center: constCenterId,
            });
          }
        } else {
          setValues({
            ...values,
            const_center: constCenterId,
          });
        }
      }
    }
  };

  const discardChanges = () => {
    setOpenConfrmationDailog(true);
  };

  useEffect(() => {
    getAllCostCenter();
    const { auction } = store.getState();

    if (auction && auction.AuctionForm1.starting_price) {
      setValues(auction.AuctionForm1);
      if (auction?.AuctionForm1?.supplier_stating_price_event) {
        setSupplierPriceEventCheckBox(true);
      }
      if (auction?.AuctionForm1?.supplier_name) {
        setSupplierNameCheckBox(true);
      }
      if (auction?.AuctionForm1?.deliveryDate) {
        setDeliveryDate(auction?.AuctionForm1?.deliveryDate);
      }
      if (auction?.AuctionForm1?.supplier_bid) {
        setSupplierBidsCheckBox(true);
      }
    } else {
      let selectedProject = projects.find(
        (project) => project._id == auction.AuctionForm1?.project_id
      );
      initialValues.currency = selectedProject
        ? selectedProject.currency
        : initialValues.currency;
      setValues(initialValues);
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <p className="profile-title"> Create English Auction</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <CloseIcon
            onClick={() => discardChanges()}
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <Form onSubmit={handleSubmit}>
        <Grid container justifyContent="center">
          <Grid item md={11} xs={12}>
            <FormSteps steps={stepLabels} currStep={2} className="mt-5 mb-5" />
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={6} className="short-field">
            <InputLabel id="starting_price-input">
              Starting Price <span className="mandatorySign">*</span>
            </InputLabel>
            <Controls.Input
              name="starting_price"
              // labelId="starting_price-input"
              fullWidth
              value={values.starting_price}
              onChange={handleInputChange}
              error={errors.starting_price}
              className="small"
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              InputProps={{
                inputComponent: NumericSeparatorInput,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12} className="short-field">
            <InputLabel id="category-input">Cost Center</InputLabel>
            <Controls.Select
              name="const_center"
              // labelId="category-input"
              value={values.const_center}
              fullWidth
              // onChange={handleInputChange}
              options={dropdownCostCenter}
              error={errors.const_center}
              onChange={getBudgetForAuction}
            />
          </Grid>
          <Grid item sm={6} xs={12} className="short-field">
            <InputLabel id="currency-input">
              Currency <span className="mandatorySign">*</span>
            </InputLabel>
            <Controls.Select
              name="currency"
              // labelId="currency-input"
              value={values.currency}
              fullWidth
              onChange={handleInputChange}
              options={employeeService.currencyList()}
              error={errors.currency}
              // disabled={true}
            />
          </Grid>
          <Grid item sm={6} xs={12} className="short-field">
            <InputLabel id="budget-input">Budget</InputLabel>
            <Controls.Input
              name="budget"
              // labelId="budget-input"
              fullWidth
              value={values.budget}
              onChange={handleInputChange}
              error={errors.budget}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              InputProps={{
                inputComponent: NumericSeparatorInput,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12} className="short-field">
            <InputLabel id="min_step-input">
              Minimum Step <span className="mandatorySign">*</span>
            </InputLabel>
            <Controls.Input
              name="min_step"
              // labelId="min_step-input"
              fullWidth
              value={values.min_step}
              onChange={handleInputChange}
              error={errors.min_step}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              InputProps={{
                inputComponent: NumericSeparatorInput,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12} className="short-field">
            <InputLabel id="cool_down_period-input">
              Cool-down Period (min)<span className="mandatorySign">*</span>
            </InputLabel>
            <Controls.Input
              name="cool_down_period"
              // labelId="cool_down_period-input"
              fullWidth
              value={values.cool_down_period}
              onChange={handleInputChange}
              error={errors.cool_down_period}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12} className="delivery-date">
            <InputLabel id="date-input">Delivery Date</InputLabel>
            <Controls.BasicDatePicker
              name="deliveryDate"
              value={deliveryDate}
              onChange={(e) => {
                setDeliveryDate(e);
              }}
              format="dd-mm-yyyy"
              error={errors.deliveryDate}
              className="mui-date-picker-reverse"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} className="awarding-check-alert">
            <Box display="flex">
              <div
                className={
                  "checkbox checkbox-primary-outline " +
                  (awardLabel ? "checked" : "")
                }
                onClick={() => {
                  setAwardLabel(!awardLabel);
                }}
              >
                <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                <CheckIcon aria-label="check-mark" />
              </div>
              <div style={{ paddingTop: "3px" }}>
                <span className="label">Awarding Commitment</span>
                {/* <div className="text">
                  orem ipsum is a pseudo-Latin text used in web design,
                  typography, layout, and printing in place of English to
                  emphasise design elements over content.
                </div> */}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <p className="section-title">Advanced Settings</p>
              <div className="position-rel">
                <RemoveIcon
                  className={
                    "cursor-p  position-abs " +
                    (showAdvSetting ? "d-block" : "d-none")
                  }
                  style={{ right: "0px" }}
                  onClick={() => setShowAdvSetting(false)}
                />
                <AddIcon
                  className={
                    "cursor-p  position-abs " +
                    (!showAdvSetting ? " d-block" : "d-none")
                  }
                  style={{ right: "0px" }}
                  onClick={() => setShowAdvSetting(true)}
                />
              </div>
            </Box>
          </Grid>
          {/* advanced settings */}
          <Grid item xs={12}>
            <Collapse in={showAdvSetting}>
              <Grid
                container
                spacing={3}
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item sm={6} xs={12} className="short-field">
                  <InputLabel id="show_to_supplier-input">
                    Show to Supplier
                  </InputLabel>
                  <Controls.Select
                    name="show_to_supplier"
                    // labelId="show_to_supplier-input"
                    value={values.show_to_supplier}
                    fullWidth
                    onChange={(e) => {
                      handleInputChange(e);
                      if (e.target.value === "2")
                        setSupplierBidsCheckBox(false);
                    }}
                    options={[
                      { id: "1", name: "Best Bid" },
                      { id: "2", name: "Rank" },
                      { id: "3", name: "Best Bid + Rank" },
                    ]}
                    error={errors.show_to_supplier}
                  />
                </Grid>
                <Grid item sm={6} xs={12} className="short-field">
                  <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Grid item sm={6} xs={12}>
                      <div className="d-flex reserve-price-part ">
                        <InputLabel id="reserve_price-input">
                          Reserve Price
                        </InputLabel>
                        <Tooltip
                          title="Minimum price to be accepted for an awarding"
                          classes={{
                            popper: "mui-tooltip mui-tooltip-primary",
                          }}
                          placement="top-start"
                          arrow
                        >
                          <HelpIcon className="hint-icon" />
                        </Tooltip>
                      </div>
                      <Controls.Input
                        name="reserve_price"
                        // labelId="reserve_price-input"
                        fullWidth
                        value={values.reserve_price}
                        onChange={handleInputChange}
                        error={errors.reserve_price}
                        inputProps={{ maxLength: 40 }}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        disabled={!awardLabel}
                        InputProps={{
                          inputComponent: NumericSeparatorInput,
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <InputLabel
                        id="no_of_participants-input"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        Minimum number of participants
                      </InputLabel>
                      <Controls.Input
                        name="no_of_participants"
                        // labelId="no_of_participants-input"
                        fullWidth
                        value={values.no_of_participants}
                        onChange={handleInputChange}
                        error={errors.no_of_participants}
                        inputProps={{ maxLength: 40 }}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          {/* advanced settings */}
          {/* what to disclose section  */}
          <Grid item xs={12}>
            <Collapse in={showAdvSetting}>
              <p
                className={
                  "section-title animate-opacity " +
                  (showAdvSetting ? "__feed" : "__hide")
                }
              >
                What to disclose?
              </p>
            </Collapse>
            <Divider />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xs={12}
            className={
              "animate-opacity " + (showAdvSetting ? "__feed" : "__hide")
            }
          >
            <div
              className={`checkbox-container suplier-bid-checkbox ${
                values.show_to_supplier === "2" ? "disable-supplier-bid" : ""
              }`}
            >
              <div className="d-flex items-center">
                <div
                  className={
                    "checkbox checkbox-primary " +
                    (supplierBidsCheckBox ? "checked" : "")
                  }
                  // supplierBidsCheckBox, setSupplierBidsCheckBox
                  onClick={() => {
                    setSupplierBidsCheckBox(!supplierBidsCheckBox);
                  }}
                >
                  <CheckBoxOutlineBlankIcon aria-label="outer-box" disabled />
                  <CheckIcon aria-label="check-mark" disabled />
                </div>
                Supplier Bid
                <Tooltip
                  title={hint}
                  classes={{ popper: "mui-tooltip mui-tooltip-primary" }}
                  placement="top-start"
                  arrow
                >
                  <HelpIcon className="hint-icon" />
                </Tooltip>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xs={12}
            className={
              "animate-opacity " + (showAdvSetting ? "__feed" : "__hide")
            }
          >
            <div className="checkbox-container">
              <div className="d-flex items-center">
                <div
                  className={
                    "checkbox checkbox-primary " +
                    (supplierNameCheckBox ? "checked" : "")
                  }
                  // supplierNameCheckBox, setSupplierNameCheckBox
                  onClick={() => {
                    setSupplierNameCheckBox(!supplierNameCheckBox);
                  }}
                >
                  <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                  <CheckIcon aria-label="check-mark" />
                </div>
                Supplier's Names
                <Tooltip
                  title="Disclose the name of the suppliers to all participants"
                  classes={{ popper: "mui-tooltip mui-tooltip-primary" }}
                  placement="top-start"
                  arrow
                >
                  <HelpIcon className="hint-icon" />
                </Tooltip>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            sm={8}
            xs={12}
            className={
              "animate-opacity " + (showAdvSetting ? "__feed" : "__hide")
            }
          >
            <div className="checkbox-container">
              <Grid item xs={12} className="disclose-option">
                <div className="d-flex items-center">
                  <div
                    className={
                      "checkbox checkbox-primary " +
                      (supplierPriceEventCheckBox ? "checked" : "")
                    }
                    // const [supplierPriceEventCheckBox, setSupplierPriceEventCheckBox
                    onClick={() => {
                      setSupplierPriceEventCheckBox(
                        !supplierPriceEventCheckBox
                      );
                    }}
                  >
                    <CheckBoxOutlineBlankIcon aria-label="outer-box" />
                    <CheckIcon aria-label="check-mark" />
                  </div>
                  Starting price of the event
                  <Tooltip
                    title="Disclose the Starting price to all participants prior the event start"
                    classes={{ popper: "mui-tooltip mui-tooltip-primary" }}
                    placement="top-start"
                    arrow
                  >
                    <HelpIcon className="hint-icon" />
                  </Tooltip>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        {/* what to disclose section  */}
        <Box
          className="BottomModal"
          display="flex"
          p={1}
          bgcolor="background.paper"
          style={{ marginTop: "20px" }}
        >
          <Grid item xs={4} className="BottomModal-left">
            <Box p={0} flexGrow={1}>
              <Controls.Button
                text="Back"
                disableElevation
                onClick={() => {
                  PressBack();
                }}
                style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                color="default"
                className="btn-mui fix-w-12em"
              />
            </Box>
          </Grid>
          <Grid item xs={8} className="BottomModal-right">
            <Box p={0}>
              <Controls.Button
                text="Save as Draft"
                type="submit"
                disableElevation
                color="default"
                style={{ margin: "0 20px 0 0" }}
                onClick={() => setauctionSaveDraft(true)}
                className="btn draft btn-mui fix-w-12em"
              />
            </Box>
            <Box p={0}>
              <Controls.Button
                type="submit"
                disableElevation
                text="Next"
                className="btn-mui fix-w-12em"
              />
            </Box>
          </Grid>
        </Box>
      </Form>

      <ConfirmationDailog
        openConfrmationDailog={openConfrmationDailog}
        setOpenConfrmationDailog={setOpenConfrmationDailog}
        setDiscardAndClose={setOpenPopupStep2}
        eraseEverything={true}
      />
    </>
  );
};

const VerticalDelimiter = (props) => {
  return <div className="ver-delimiter"></div>;
};

export default Step2Form;
