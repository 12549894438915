import { Api } from "../../../../api/Api";
import {
  GET_SELLER_MY_ALL_BID_API,
  GET_SELLER_MY_BID_API,
} from "../../../../constants/ApiUrl";
import { thousandSepratorHandler } from "../../../../utility/utils";

const GetAuctionBidsBL = async (props) => {
  const {
    currentAuction,
    myBidPage = 0,
    myBidRowsPerPage = 0,
    allBidPage = 0,
    allBidRowsPerPage = 0,
    // mine = true,
    mine,
  } = props;

  let list = [];
  let limit = 0;
  let columns = [];
  let label = "";

  const API = mine
    ? `${GET_SELLER_MY_BID_API}/${currentAuction.auctionId._id}?per_page=${myBidRowsPerPage}&page=${myBidPage}`
    : `${GET_SELLER_MY_ALL_BID_API}/${currentAuction.auctionId._id}?per_page=${allBidRowsPerPage}&page=${allBidPage}`;

  const TableType = currentAuction?.auctionId?.show_to_supplier;
  // const TableType = "3";
  const TakeSupName = currentAuction?.auctionId?.disclose_suppliers_name;
  // const response = await axiosInstance.get(API);
  const response = await Api(API, {}, "get");

  if (response.status === 200) {
    if (mine) {
      if (response.data && response.data.getMyBid !== undefined) {
        list = response.data.getMyBid.map((bid) => {
          let row = [
            bid._id,
            bid.sort,
            bid.createdDate ? new Date(bid.createdDate).toLocaleString() : "-",
            // bid.supplierId ? bid.supplierId.company_name : "-",
          ];

          if (TableType === "1") {
            row.push(bid.supplierId ? bid.supplierId.company_name : "-");
            row.push(
              bid.bid
                ? thousandSepratorHandler(bid.bid) +
                    " " +
                    currentAuction.auctionId.currency
                : "-"
            );
          }

          if (TableType === "2") {
            row.push(bid.supplierId ? bid.supplierId.company_name : "-");
            row.push(
              bid.bid
                ? thousandSepratorHandler(bid.bid) +
                    " " +
                    currentAuction.auctionId.currency
                : "-"
            );
            row.push(bid.rank ? bid.rank : "-");
          }

          if (TableType === "3") {
            row.push(bid.supplierId ? bid.supplierId.company_name : "-");
            row.push(
              bid.bid
                ? thousandSepratorHandler(bid.bid) +
                    " " +
                    currentAuction.auctionId.currency
                : "-"
            );
            row.push(bid.rank ? bid.rank : "-");
          }

          return row;
        });

        limit = response.data.totalRecord;
      }
    } else {
      if (response.data && response.data.allBid !== undefined) {
        list = response.data.allBid.map((bid) => {
          let row = [
            bid._id,
            bid.sort,
            bid.createdDate ? new Date(bid.createdDate).toLocaleString() : "-",
            // bid.supplierId ? bid.supplierId.company_name : "-",
          ];

          if (TableType === "1") {
            if (currentAuction?.auctionId?.disclose_suppliers_name) {
              row.push(bid.supplierId ? bid.supplierId.company_name : "-");
            }

            if (currentAuction?.auctionId?.disclose_suppliers_bid) {
              row.push(
                bid.bid
                  ? thousandSepratorHandler(bid.bid) +
                      " " +
                      currentAuction.auctionId.currency
                  : "-"
              );
            }
          }

          if (TableType === "2") {
            if (currentAuction?.auctionId?.disclose_suppliers_name) {
              row.push(bid.supplierId ? bid.supplierId.company_name : "-");
            }
            if (currentAuction?.auctionId?.disclose_suppliers_bid) {
              row.push(
                bid.bid
                  ? thousandSepratorHandler(bid.bid) +
                      " " +
                      currentAuction.auctionId.currency
                  : "-"
              );
            }
            row.push(bid.rank ? bid.rank : "-");
          }

          if (TableType === "3") {
            if (currentAuction?.auctionId?.disclose_suppliers_name) {
              row.push(bid.supplierId ? bid.supplierId.company_name : "-");
            }
            if (currentAuction?.auctionId?.disclose_suppliers_bid) {
              row.push(
                bid.bid
                  ? thousandSepratorHandler(bid.bid) +
                      " " +
                      currentAuction.auctionId.currency
                  : "-"
              );
            }
            row.push(bid.rank ? bid.rank : "-");
          }
          return row;
        });

        limit = response.data.totalRecord;
      }
    }

    let TableAttr = getTableColumns(
      TableType,
      TakeSupName,
      currentAuction,
      mine
    );
    columns = TableAttr.columns;

    label = TableAttr.label;
  }

  return {
    list: list,
    limit: limit,
    columns: columns,
    label: label,
  };
};

const getTableColumns = (
  TableType,
  TakeSupName = true,
  currentAuction,
  mine
) => {
  let columns = [
    {
      id: "number",
      label: "Bid Number",
      minWidth: 200,
    },

    {
      id: "date",
      label: "Time & Date",
      minWidth: 200,
    },
    {
      id: "name",
      label: "Supplier Name",
      minWidth: 200,
    },
    {
      id: "bid",
      label: "Bid",
      minWidth: 100,
    },
  ];

  let label = "";

  switch (TableType) {
    case "1":
      label = "Bid List (Bid Only)";

      break;

    case "2":
      label = "Bid List (Rank Only)";
      columns.push({
        label: "Rank",
        minWidth: 100,
      });

      break;

    case "3":
      label = "Bid List (Bid+Rank)";
      columns.push({
        label: "Rank",
        minWidth: 100,
      });
      break;

    default:
  }

  if (!mine) {
    if (!currentAuction?.auctionId?.disclose_suppliers_bid) {
      const tempIndex = columns.findIndex((col) => col.id === "bid");
      columns.splice(tempIndex, 1);
    }
    if (!currentAuction?.auctionId?.disclose_suppliers_name) {
      const tempIndex = columns.findIndex((col) => col.id === "name");
      columns.splice(tempIndex, 1);
    }
  }

  return {
    columns: columns,
    label: label,
  };
};

export default GetAuctionBidsBL;
