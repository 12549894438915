/*eslint-disable */
import React, { useEffect, useState, useRef, createRef } from "react";
import { Paper, makeStyles, Box, Grid } from "@material-ui/core";
import { Button, Divider, Stack } from "@mui/material";
import { useForm, Form } from "../../../../components/useForm";
import Controls from "../../../../components/controls/Controls";
import axiosInstance from "../../../../services/AxiosInstance";
import { store } from "../../../../store/store";
import "../../styles/details-comments.css";
import Moment from "moment";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentLiveAuction } from "../../../../store/selectors/auctionSelector";
import { currentUser } from "../../../../store/selectors/AuthSelectors";
import userImage from "../../../../assets/auth/user_icon_2.png";
import { Api } from "../../../../api/Api";
import {
  GET_AUCTION_COMMENT_API,
  POST_COMPANY_AUCTION_API,
} from "../../../../constants/ApiUrl";
import { AUCTION_DRAFT } from "../../../../constants/AppConstants";

const initialFValues = {
  comment: "",
  replaycomment: "",
};

const CommentsTab = (props) => {
  const { currentLiveAuction, isEditor, isOwner, currentUser } = props;
  const userProfileImage = useSelector(
    (state) => state?.auth?.auth?.user?.image_upload
  );

  const [commentList, setCommentList] = useState([]);
  const [replayCommentText, setReplayCommentText] = useState("");
  const [replayToId, setReplayToId] = useState(null);
  const inputEl = useRef(null);
  const textRef = useRef([]);
  textRef.current = commentList.map(
    (ref, index) => (textRef.current[index] = createRef())
  );
  const getAllIComments = async () => {
    const response = await Api(
      `${GET_AUCTION_COMMENT_API}/${currentLiveAuction?._id}/comments`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allComments = response.data.comments;
        setCommentList(allComments);
      }
    }

    // await axiosInstance
    //   .get(`api/buyer//company/auction/${currentLiveAuction?._id}/comments`)
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       let allComments = response.data.comments;
    //       setCommentList(allComments);
    //     }
    //   })
    //   .catch((err) => {});
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("comment" in fieldValues)
      temp.comment = fieldValues.comment ? "" : "This field is required.";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() && (isOwner() || isEditor())) {
      const state = store.getState();
      const userId = state?.auth?.auth?.user?._id;
      const payload = {
        // "replyingTo": "",
        content: values.comment,
        userId: userId,
      };
      const response = await Api(
        `${POST_COMPANY_AUCTION_API}/${currentLiveAuction?._id}/comment`,
        payload,
        "post"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllIComments();
          resetForm();
        }
      }

      // await axiosInstance
      //   .post(
      //     `api/buyer/company/auction/${currentLiveAuction?._id}/comment`,
      //     payload
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       getAllIComments();
      //       resetForm();
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const getReplayText = (e) => {
    const texts = e.target.value;
    if (texts) {
      setReplayCommentText(texts);
    }
  };

  const addReplay = async (data, index) => {
    if (data && data._id && (isOwner() || isEditor())) {
      setReplayToId(data._id);
      const state = store.getState();
      const userId = state?.auth?.auth?.user?._id;
      const payload = {
        replyingTo: data._id,
        content: replayCommentText,
        userId: userId,
      };
      const response = await Api(
        `${POST_COMPANY_AUCTION_API}/${currentLiveAuction?._id}/comment`,
        payload,
        "post"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllIComments();
          setReplayCommentText("");
          setReplayToId(null);
          resetForm();
          textRef.current[index].current.value = "";
        }
      }

      // await axiosInstance
      //   .post(
      //     `api/buyer/company/auction/${currentLiveAuction?._id}/comment`,
      //     payload
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       getAllIComments();
      //       setReplayCommentText("");
      //       setReplayToId(null);
      //       resetForm();
      //       textRef.current[index].current.value = "";
      //     }
      //   })
      //   .catch((err) => {});
    }
  };

  const ClearCommentText = (index) => {
    textRef.current[index].current.value = "";
    setReplayCommentText("");
    setReplayToId(null);
  };

  useEffect(() => {
    if (currentUser) {
      getAllIComments();
    }
  }, [currentUser]);

  return (
    <>
      <Grid container className="auction-comment-container">
        <Stack
          direction="row"
          spacing={1}
          style={{
            width: "100%",
          }}
        >
          <Box>
            <img
              style={{
                width: "2.7em",
                height: "2.7em",
                marginTop: "6px",
                objectFit: "cover",
              }}
              src={
                currentUser.image_upload ? currentUser.image_upload : userImage
                // : "https://www.bootdey.com/img/Content/avatar/avatar2.png"
              }
            />
            {/* </div> */}
          </Box>
          <Box style={{ flexGrow: 1, marginLeft: "1.7em" }}>
            <Form
              className="new-comment-form-container"
              onSubmit={handleSubmit}
            >
              <Grid item xs={12}>
                <Controls.Input
                  name="comment"
                  value={values.comment}
                  onChange={handleInputChange}
                  error={errors.comment}
                  className="comment-text-box"
                />
              </Grid>
              {isOwner() || isEditor() ? (
                <Grid item xs={12}>
                  <div style={{ float: "right", marginTop: "1em" }}>
                    <Controls.Button
                      type="submit"
                      disableElevation
                      text="Add Comment"
                      className="action-btn"
                    />
                  </div>
                </Grid>
              ) : (
                <></>
              )}
            </Form>
          </Box>
        </Stack>
        <Grid item xs={12}>
          <p style={{ marginTop: "0" }}>All Comments</p>
          <Divider className="title-divider" />
        </Grid>

        {commentList.map((comment, ind) => (
          <Grid item xs={12} style={{ marginTop: "1em" }}>
            <div className="comment-container">
              <div>
                <img
                  src={
                    comment?.user?.image_upload
                      ? comment?.user?.image_upload
                      : userImage
                  }
                  style={{
                    width: "2.7em",
                    height: "2.7em",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div>
                <span className="comment-container-title">
                  {comment?.user?.first_name} {comment?.user?.last_name}
                </span>
                <span className="comment-container-time">
                  {Moment(comment?.createdDate).format("MMM DD YYYY LT")}
                </span>
                <h5 className="lineBreak" style={{ marginTop: "0.6em" }}>
                  {comment?.content}
                </h5>
              </div>
            </div>
            <div className="replay-container">
              <div className="replay-container-label">Reply</div>
              {comment.replies.length > 0
                ? comment.replies.map((replay) => (
                    <div className="comment-container">
                      <div>
                        <img
                          src={
                            replay?.user?.image_upload
                              ? replay?.user?.image_upload
                              : userImage
                          }
                          style={{
                            width: "2.7em",
                            height: "2.7em",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div>
                        <span className="comment-container-title">
                          {replay?.user?.first_name} {replay?.user?.last_name}
                        </span>
                        <span className="comment-container-time">
                          {Moment(replay?.createdDate).format("MMM DD YYYY LT")}
                        </span>
                        <h5
                          className="lineBreak"
                          style={{ marginTop: "0.6em" }}
                        >
                          {replay.content}
                        </h5>
                      </div>
                    </div>
                  ))
                : ""}

              <Stack
                direction="row"
                spacing={1}
                style={{
                  width: "100%",
                }}
              >
                <Box>
                  <img
                    style={{
                      width: "2.7em",
                      height: "2.7em",
                      marginTop: "6px",
                      objectFit: "cover",
                    }}
                    src={
                      currentUser.image_upload
                        ? currentUser.image_upload
                        : userImage
                    }
                  />
                </Box>
                <Box style={{ flexGrow: 1 }}>
                  {isOwner() || isEditor() ? (
                    <Form className="new-comment-form-container">
                      <Grid item xs={12}>
                        <Controls.Input
                          name="comment"
                          className="comment-text-box repaly"
                          onChange={getReplayText}
                          inputRef={textRef.current[ind]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ float: "right", marginTop: "1em" }}>
                          <Button
                            variant="contained"
                            className="action-btn cancel"
                            style={{ marginRight: "0.5em" }}
                            onClick={() => ClearCommentText(ind)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            className="action-btn"
                            onClick={() => addReplay(comment, ind)}
                          >
                            Add Replay
                          </Button>
                        </div>
                      </Grid>
                    </Form>
                  ) : (
                    <></>
                  )}
                </Box>
              </Stack>
            </div>

            {/* <div className="comment-container">
              <div>
                <img src="https://www.bootdey.com/img/Content/avatar/avatar2.png" />
              </div>
              <div>
                <span className="comment-container-title">Larray Brown</span>
                <span className="comment-container-time">
                  Sep 26 2021, 10:14 PM
                </span>
                <h5 style={{ marginTop: "0.6em" }}>
                  This is a sample comment because I need to show how the feedback
                  looks. So I need longer to make it seem real. And I am extending
                  this comment yet agian' Cause why not
                </h5>
              </div>
            </div> */}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(CommentsTab));
