import packageJson from "../../package.json";

export class CashService {
  static exceedLimit = false;
  static localVerKey = "oktio-version";

  static clear() {
    // if(this.checkVersionDiff()){
    //clear always after logout ...
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // Makes sure the page reloads. Changes are only visible after you refresh.
      // window.location.reload(true);
    }
    // localStorage.clear();
    // localStorage.setItem(this.localVerKey, packageJson.version);
    // }
  }

  static checkVersionDiff() {
    let version = localStorage.getItem(this.localVerKey);
    return packageJson.version === version;
  }
}
