import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../styles/auction-confirmation-dailog.css";
import Controls from "../../../components/controls/Controls";
import { Box, Grid, InputLabel } from "@material-ui/core";
import { Input, TextField } from "@mui/material";

const ConfirmSendingWinnerEmails = (props) => {
  const {
    openConfrmationDailog,
    setOpenConfrmationDailog,
    isConfirmed,
    setReason,
  } = props;
  const reasonElmRef = React.createRef();

  const cancelAction = () => {
    setOpenConfrmationDailog(false);
  };

  const confirmAction = () => {
    let reasonInput = reasonElmRef.current.querySelector("#reason_input");

    setReason(reasonInput.value);
    isConfirmed(true);
    cancelAction();
  };

  return (
    <>
      <Dialog
        open={openConfrmationDailog}
        onClose={cancelAction}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="auction-confirm-dailog-main-div">
          <DialogTitle id="alert-dialog-title" className="text-center">
            Do you confirm that no suppliers won the auction ?
          </DialogTitle>
          <DialogContent className="text-center">
            <DialogContentText id="alert-dialog-description">
              <InputLabel className="mb-4 text-left" id="reason-input">
                Enter reason (optional) :
              </InputLabel>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="reason"
                id="reason_input"
                labelId="reason-input"
                multiline
                className="mui-input small"
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                autoComplete=""
                ref={reasonElmRef}
              />
            </DialogContentText>
          </DialogContent>
          <Grid container className="mt-5" justifyContent="space-between">
            <Grid item sm={4} xs={12}>
              <Controls.Button
                text="OK"
                className="btn btn-mui btn-primary-lite w-100"
                variant="contained"
                onClick={confirmAction}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Controls.Button
                text="CANCEL"
                className="btn btn-mui btn-danger w-100"
                variant="contained"
                onClick={cancelAction}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmSendingWinnerEmails;
