import { Box, Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import FormInput from "../../../components/FormComponents/FormInput";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragIcon from "@mui/icons-material/DragIndicator";
import { ANSWER_RULES } from "../../../constants/Rules";

const NestedAnswer = ({
  nestIndex,
  control,
  register,
  errors,
  questionList,
  watch,
  setValue,
  isValid,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    keyName: "key",
    name: `question.${nestIndex}.options`,
    mode: { onchange },
  });
  const answerArray = watch(`question.${nestIndex}.options`);

  const [drag, setDrag] = useState(0);
  useEffect(() => {
    if (
      answerArray &&
      answerArray.length === 0 &&
      questionList[nestIndex].questionType !== "FT"
    )
      append();
  }, [answerArray]);

  const handleDragStart = (event, index) => {
    setDrag(index);
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.style.backgroundColor = "#e0e0e0";
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.style.backgroundColor = "transparent";
  };

  const handleDrop = (event, currentIndex) => {
    event.preventDefault();
    event.currentTarget.style.backgroundColor = "transparent";
    // const draggedIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedFields = [...questionList[nestIndex].options];
    const draggedField = updatedFields.splice(drag, 1)[0];
    updatedFields.splice(currentIndex, 0, draggedField);
    setValue(`question.${nestIndex}.options`, updatedFields, {
      shouldValidate: true,
    });
  };

  return (
    <>
      {}
      {fields.map((nestedItem, index) => (
        <Fragment key={nestedItem.key}>
          {(questionList[nestIndex]?.questionType === "SC" ||
            questionList[nestIndex]?.questionType === "MC") && (
            <Grid item xs={12}>
              <div
                className="global-flex-center w-100 g-10"
                draggable
                onDragStart={(event) => handleDragStart(event, index)}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={(event) => handleDrop(event, index)}
              >
                <DragIcon />

                <span>{String.fromCharCode(65 + index)}</span>
                <FormInput
                  id={`question.${nestIndex}.options.${index}.option`}
                  name={`question.${nestIndex}.options.${index}.option`}
                  type="text"
                  register={register}
                  errors={errors}
                  placeholder="Type a question"
                  className="w-100"
                  rules={ANSWER_RULES}
                  errorMsg={
                    errors["question"]?.[nestIndex]?.["options"]?.[index]?.[
                      "option"
                    ]?.message
                  }
                />
                {fields.length - 1 === index && (
                  <AddIcon
                    className={`common-rfx-icon add-rfx-icon ${
                      !isValid ? "item-disabeled" : ""
                    }`}
                    onClick={() => {
                      if (!isValid) return;
                      append({});
                    }}
                  />
                )}
                {index !== 0 && (
                  <RemoveIcon
                    className="common-rfx-icon remove-rfx-icon"
                    onClick={() => remove(index)}
                  />
                )}
              </div>
            </Grid>
          )}
        </Fragment>
      ))}
    </>
  );
};

export default NestedAnswer;
