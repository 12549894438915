import React from "react";
import Modal from "@mui/material/Modal";
import { useForm, Form } from "../../../../components/useForm";
import { InputLabel } from "@mui/material";
import { Api } from "../../../../api/Api";
import { GET_ADMIN_USER_API } from "../../../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { TOAST_AUTO_CLOSE } from "../../../../constants/AppConstants";
import { Box, Grid } from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";

const initialFValues = {
  first_name: "",
  last_name: "",
  email: "",
  role: "Super Admin",
};

const CreateSuperAdminModel = (props) => {
  const { open, handleClose, setResendApi, GetAllSuperAdminBL } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name ? "" : "First Name is required.";
    if ("last_name" in fieldValues)
      temp.last_name = fieldValues.last_name ? "" : "Last Name is required.";
    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "Email is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
        const paylod = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
        };

        const response = await Api(`${GET_ADMIN_USER_API}/add`, paylod, "post");
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            resetForm();

            setResendApi(true);
            handleClose();
          }
        }
      } else {
        toast.error(ValidationMessage.validEmail, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-label="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="popup-form-container">
        <div className="title">Add User</div>

        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <InputLabel id="first-name-input">First Name</InputLabel>
              <Controls.Input
                labelId="first-name-input"
                name="first_name"
                fullWidth
                value={values.first_name}
                onChange={handleInputChange}
                error={errors.first_name}
                inputProps={{ maxLength: 40 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel id="last-name-input">Last Name</InputLabel>
              <Controls.Input
                labelId="first-name-input"
                name="last_name"
                fullWidth
                value={values.last_name}
                onChange={handleInputChange}
                error={errors.last_name}
                className="StepControlInput"
                inputProps={{ maxLength: 40 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="email-input">Email</InputLabel>
              <Controls.Input
                labelId="email-input"
                name="email"
                fullWidth
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                className="StepControlInput"
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="position-input">Role</InputLabel>
              <Controls.Input
                labelId="role-input"
                name="role"
                value="Super Admin"
                fullWidth
                className="StepControlInput"
              />
            </Grid>
          </Grid>
          <Box
            className="BottomModal"
            display="flex"
            p={1}
            bgcolor="background.paper"
          >
            <Grid istem xs={4} className="BottomModal-left">
              <Box p={0} flexGrow={1}>
                <Controls.Button
                  text="Cancel"
                  disableElevation
                  onClick={handleClose}
                  style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                  color="default"
                  className="btn-mui"
                />
              </Box>
            </Grid>
            <Grid item xs={8} className="BottomModal-right">
              <Box p={0}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text="Invite user"
                  className="btn-mui"
                />
              </Box>
            </Grid>
          </Box>
        </Form>
      </Box>
    </Modal>
  );
};

export default CreateSuperAdminModel;
