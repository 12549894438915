/*eslint-disable */
import React, { useEffect, useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import SideMenu from "./SideMenu";
import userImage from "../assets/auth/user_icon_2.png";
// import userImage from "../assets/auth/user_icon_3.png";
import logo2xImage from "../assets/Logo_2.png";
import logoSmText from "../assets/Logo__text.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { logout } from "../store/actions/authActions";
import { withRouter } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  Avatar,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from "@material-ui/core";
import { Stack, SwitchUnstyled } from "@mui/material";
import "../styles/button.css";
import "../styles/badge.css";
import {
  isAdmin,
  isBuyer,
  isSeller,
  isSuperAdmin,
} from "../store/selectors/RoleSelector";
import { changeCurrentSelectedRole } from "../store/actions/authorizationAction";
import { useHistory, useLocation } from "react-router-dom";
import { getCurrentRole } from "../store/selectors/AuthorSelector";
import { currentUser } from "../store/selectors/AuthSelectors";
import Loader from "./Loader";
import CreateAuctionButton from "./CreateAuctionButton";
import BuyerPermissionModal from "./Modals/BuyerPermissionModal";
import { getUserDetails, setRoleInLocalStorage } from "../utility/utils";
import ContactToAdminModal from "./Modals/ContactToAdminModal";
import QuestionMarkIcon from "../assets/images/QuestionMark.svg";
import SupportFormDrawer from "./navbar/SupportFormDrawer";
import { SUPER_ADMIN_ROLE } from "../constants/AppConstants";

const drawerWidth = 313;

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "15px 15px",
    textTransform: "none",
    width: "100%",
    "&$selected": {
      backgroundColor: "rgba(33, 137, 214, 0.14)",
      color: "rgb(26, 88, 159)",
      "&:hover": {
        backgroundColor: "rgba(33, 137, 214, 0.14)",
        color: "rgb(26, 88, 159)",
      },
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    overflowX: "clip",
    width: drawerWidth,
    background:
      "linear-gradient(0deg, rgba(51,54,204,1) 0%, rgba(33,112,255,1) 100%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(12),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(12),
    },
  },
}));

const Appbar = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const selectedRole = useSelector(
    (state) => state?.authorization?.selectedRole
  );

  const [open, setOpen] = React.useState(false);
  const [fullLoder, setFullLoder] = React.useState(false);
  const [showSupportDrawer, setShowSupportDrawer] = React.useState(false);
  const { isSeller, isAdmin, isBuyer, currentRole, currentUser, isSuperAdmin } =
    props;

  const handleDrawerOpen = () => {
    setOpen(true);
    const closedElms = document.querySelectorAll(".__closed");
    for (let index = 0; index < closedElms.length; index++) {
      const closedElm = closedElms[index];
      closedElm.classList.replace("__closed", "__opened");
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
    const closedElms = document.querySelectorAll(".__opened");
    for (let index = 0; index < closedElms.length; index++) {
      const closedElm = closedElms[index];
      closedElm.classList.replace("__opened", "__closed");
    }
  };

  function onLogout() {
    dispatch(logout(props.history));
  }

  //   menu Start
  const [profileMenu, setProfileMenu] = React.useState(false);
  const [supportMenu, setSupportMenu] = React.useState(false);
  const [notifMenu, setNotifMenu] = React.useState(false);
  const [auctionMenu, setAuctionMenu] = React.useState(false);
  const [notifNum, setNotifNum] = React.useState(1);
  const [auctionNum, setAuctionNum] = React.useState(2);
  const [userName, setuserName] = React.useState("");
  const [userRole, setuserRole] = React.useState("");
  const [userProfilePicture, setuserProfilePicture] = React.useState(userImage);
  const [showBuyerModal, setShowBuyerModal] = useState(false);
  const [toggleRole, setToggleRole] = useState("");

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    role: "",
  });

  const [showAdminModal, setShowAdminModal] = useState(false);
  const profileMenueRef = React.useRef(null);
  const supportHelpRef = React.useRef(null);
  const notifMenueRef = React.useRef(null);
  const auctionMenueRef = React.useRef(null);
  const defaultRole = "buyer";
  // (isAdmin ||
  const canToggle = currentRole !== "super_admin" && isSeller && isBuyer;
  const prefix = currentRole === "supplier" ? "/supplier" : "";

  const defRole = canToggle
    ? currentRole
      ? currentRole
      : defaultRole
    : currentRole !== "super_admin"
    ? isSeller
      ? "supplier"
      : "buyer"
    : "super_admin";

  const handleClose = (event, ref) => {
    if (ref.current && ref.current.contains(event.target)) {
      return;
    }
    switch (ref.current.id) {
      case "profile-popup-box":
        setProfileMenu(false);
        break;
      case "support-popup-box":
        setSupportMenu(false);
        break;
      case "auction-popup-box":
        setAuctionMenu(false);
        break;
      case "notification-popup-box":
        setNotifMenu(false);
        break;
    }
  };

  const gotoProfile = () => {
    // history.push(prefix + "/settings");
    history.push({
      pathname: `${prefix}/settings`,
      state: { showProfile: true },
    });
  };

  const changeCurrentRole = (role) => {
    if (!currentUser.isSeller && currentUser.isBuyerRoleRevoked) {
      setShowAdminModal(true);
      return;
    }
    if (!isSeller) {
      setConfirmDialog({
        ...confirmDialog,
        title: "Would you like to create your supplier profile ?",
        isOpen: true,
        role: role === "supplier" && "seller",
      });
      return;
    }
    setToggleRole(role);
    setRoleInLocalStorage(role);
    dispatch(changeCurrentSelectedRole(role));
    history.push("/" + role + "/auction");
  };
  const changeCurrentRoleToBuyer = (role) => {
    if (!currentUser.isBuyer && currentUser.isBuyerRoleRevoked) {
      setShowAdminModal(true);
      return;
    }
    if (!isBuyer) {
      setConfirmDialog({
        ...confirmDialog,
        title:
          "Do you to request a buyer access and  start your 14 days trial ?",
        isOpen: true,
        role,
      });
      return;
    }
    setToggleRole(role);
    setRoleInLocalStorage(role);
    dispatch(changeCurrentSelectedRole(role));
    history.push("/dashboard");
  };

  useEffect(() => {
    if (currentUser) {
      const fullName = currentUser.first_name + " " + currentUser.last_name;
      setuserName(fullName);
      let userRole = !currentUser.isAdmin ? "" : "Admin - ";
      const prefix = currentRole === "supplier" ? "/supplier" : "";

      if (currentUser.isSuperAdmin === true) {
        userRole += "Super";
      } else {
        if (currentUser.isBuyer === true && currentUser.isSeller === false) {
          userRole += "Buyer";
        }
        if (currentUser.isBuyer === false && currentUser.isSeller === true) {
          userRole += "Seller";
        }
        if (currentUser.isBuyer === true && currentUser.isSeller === true) {
          userRole += "Buyer / Seller";
        }
      }
      setuserRole(userRole);
      if (currentUser.image_upload) {
        setuserProfilePicture(currentUser.image_upload);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    addSideBarHoverEvents();
    // changeCurrentRole(defRole);
  }, []);

  const addSideBarHoverEvents = () => {
    const sideBar = document.getElementsByClassName("side-bar-container")[0];
    sideBar.addEventListener("mouseover", handleDrawerOpen);
    sideBar.addEventListener("mouseout", handleDrawerClose);
    return () => {
      sideBar.removeEventListener("resize", handleDrawerOpen);
      sideBar.removeEventListener("mouseout", handleDrawerClose);
    };
  };

  useEffect(() => {
    const userData = getUserDetails();
    setuserProfilePicture(userData?.user?.image_upload);
    const fullName =
      userData?.user?.first_name + " " + userData?.user?.last_name;
    setuserName(fullName);
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.isSuperAdmin) return;
    const urlLoaction = location.pathname;
    if (urlLoaction.includes("/supplier/")) {
      dispatch(changeCurrentSelectedRole("supplier"));
    } else {
      dispatch(changeCurrentSelectedRole("buyer"));
    }
  }, [location.pathname, currentUser?.isSuperAdmin]);

  useEffect(() => {
    setToggleRole(selectedRole);
    setRoleInLocalStorage(selectedRole);
  }, [selectedRole]);

  return (
    <React.Fragment>
      <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
      {/* nav-bar */}
      <AppBar className="app-bar-container">
        <Toolbar className="app-toolbar-container">
          {/* <TextField
            variant="standard"
            placeholder="Search for anything..."
            className="SearchInputList"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchImage} />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          /> */}
          <Typography
            component="h1"
            variant="h6"
            color="primary"
            noWrap
            style={{ flexGrow: 1 }}
          ></Typography>
          <div className="app-toolbar-list">
            {currentRole !== SUPER_ADMIN_ROLE && (
              <div className="profile-list-container support-list-container">
                <Avatar
                  ref={supportHelpRef}
                  aria-controls={
                    supportMenu ? "support-option-list" : undefined
                  }
                  aria-haspopup="true"
                  onClick={() => setSupportMenu(!supportMenu)}
                  src={QuestionMarkIcon}
                  className={`support-option-icon ${
                    supportMenu ? "__active" : "__nonactive"
                  }`}
                  id="support-popup-box"
                />
                <Popper
                  open={supportMenu}
                  anchorEl={supportHelpRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                      className="popup-container"
                    >
                      <Paper>
                        <ClickAwayListener
                          onClickAway={(e) => handleClose(e, supportHelpRef)}
                        >
                          <div>
                            <div className="arrow-icon"></div>
                            <MenuList
                              autoFocusItem={supportMenu}
                              id="support-option-list"
                              onKeyDown={(e) => {
                                if (e.key === "Tab") {
                                  e.preventDefault();
                                  setSupportMenu(false);
                                }
                              }}
                            >
                              <a
                                href={
                                  toggleRole === "buyer"
                                    ? "https://scribehow.com/page/Oktio_Support__Buyer__idsT7LtiTB2iltouyenkDA"
                                    : "https://scribehow.com/page/Oktio_Support__Seller__U2MRCJ9ERhqT3AX0MYLH4w"
                                }
                                target="_blank"
                                className="link-outline-none"
                              >
                                <MenuItem className="text-dark">
                                  Help Center
                                </MenuItem>
                              </a>

                              <Divider />
                              <MenuItem
                                onClick={(e) => {
                                  setShowSupportDrawer(true);
                                  handleClose(e, supportHelpRef);
                                }}
                                className="pb-0"
                              >
                                Contact Us
                              </MenuItem>
                            </MenuList>
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}
            {/* support menu */}
            {/* profile list of options  */}
            <div className="profile-list-container">
              <Avatar
                ref={profileMenueRef}
                aria-controls={profileMenu ? "profile-option-list" : undefined}
                aria-haspopup="true"
                onClick={() => setProfileMenu(!profileMenu)}
                src={userProfilePicture || userImage}
                className={profileMenu ? "__active" : "__nonactive"}
                id="profile-popup-box"
              />
              <Popper
                open={profileMenu}
                anchorEl={profileMenueRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                    className="popup-container"
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={(e) => handleClose(e, profileMenueRef)}
                      >
                        <div>
                          <div className="arrow-icon"></div>
                          <MenuList
                            autoFocusItem={profileMenu}
                            id="profile-option-list"
                            onKeyDown={(e) => {
                              if (e.key === "Tab") {
                                e.preventDefault();
                                setProfileMenu(false);
                              }
                            }}
                          >
                            <MenuItem
                              disabled
                              onClick={(e) => handleClose(e, notifMenueRef)}
                              className="profile-label"
                            >
                              <Stack
                                direction="row"
                                style={{ width: "100%", alignItems: "center" }}
                                spacing={2}
                              >
                                <Grid className="list-item-image-container">
                                  <img src={userProfilePicture || userImage} />
                                </Grid>
                                <Grid container className="list-item-details">
                                  <Grid item xs={12}>
                                    <div className="text">{userName}</div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <small className="sub-text">
                                      {userRole}
                                    </small>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={(e) => {
                                handleClose(e, profileMenueRef);
                                gotoProfile();
                              }}
                              style={{
                                paddingBottom: "0px !important",
                              }}
                            >
                              My Profile
                            </MenuItem>
                            <MenuItem onClick={onLogout}>Log out</MenuItem>
                          </MenuList>
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>

            {/* profile list of options  */}
          </div>
        </Toolbar>
      </AppBar>
      {/* nav-bar */}
      {/* side-bar  */}
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        className="side-bar-container"
      >
        <div className="logo-container">
          <div style={{ display: "flex" }}>
            <div className="logo-image">
              <img
                src={logo2xImage}
                className={open ? "toggle-width-show" : "toggle-width-hide"}
                alt="Logo"
                width="124"
              />
            </div>
            <div
              className={
                "logo-text " +
                (open ? "toggle-opacity-show" : "toggle-opacity-hide")
              }
            >
              <div> Powered by </div>
              <img src={logoSmText} alt="Logo" width="124" />
            </div>
          </div>
        </div>
        {/* <div style={{ display: "block" }}>
          <button
            className={
              "btn btn-success " +
              (open
                ? "add-auction-animation-show"
                : "add-auction-animation-hide")
            }
          >
            <AddIcon />
            <span>Add Auction</span>
          </button>
        </div> */}
        {/* {!isBuyer || isSuperAdmin ? <></> : <CreateAuctionButton open={open} />} */}
        {toggleRole !== "buyer" || isSuperAdmin ? (
          <></>
        ) : (
          <CreateAuctionButton open={open} />
        )}
        <SideMenu></SideMenu>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          className="footer"
        >
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            xs={12}
          >
            {(canToggle || isSeller || isBuyer) && !isSuperAdmin ? (
              <>
                <div
                  className={
                    "side-bar-toggle-btn " +
                    (open ? "animation-toggle-show" : "animation-toggle-hide")
                  }
                >
                  <ToggleButtonGroup
                    variant="outlined"
                    value={toggleRole}
                    exclusive
                    // onChange={handleChange}
                  >
                    <ToggleButton
                      value="buyer"
                      onClick={() => changeCurrentRoleToBuyer("buyer")}
                    >
                      Buyer
                    </ToggleButton>
                    <ToggleButton
                      value="supplier"
                      onClick={() => changeCurrentRole("supplier")}
                    >
                      Supplier
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div
                  className={
                    "side-bar-toggle-switch " +
                    (open ? "animation-toggle-hide" : "animation-toggle-show")
                  }
                >
                  <SwitchUnstyled checked={currentRole === "supplier"} />
                </div>
              </>
            ) : (
              <></>
            )}

            <Grid className="signature">
              {/* <span className="__opened"> Powered by </span> */}
              <img className="__opened" src={logoSmText} alt="OKTIO" />
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
      {/* side-bar  */}

      <BuyerPermissionModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        setToggleRole={setToggleRole}
      />
      <ContactToAdminModal
        confirmDialog={showAdminModal}
        setConfirmDialog={setShowAdminModal}
      />
      <div className="support-drawer-appbar">
        <SupportFormDrawer
          showSupportDrawer={showSupportDrawer}
          setShowSupportDrawer={setShowSupportDrawer}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSuperAdmin: isSuperAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(Appbar));
