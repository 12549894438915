import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Controls from "../../../../../components/controls/Controls";
import Loader from "../../../../../components/Loader/Loader";
import TimeClockImage from "../../../../../assets/Time-Remaining@2x.png";
import { getCurrentAuction } from "../../../../../store/selectors/supplierAspect/auctionSelector";
import { currentUser } from "../../../../../store/selectors/AuthSelectors";
import SetupLiveMonitoringBL from "../../BL/SetupLiveMonitoringBL";
import SocketHandler from "../../../../../services/socket/SocketHandler";
import {
  GOIN_LIVE_BID_GROUP_EVENT,
  SEND_NEW_BID_EVENT,
} from "../../../../../services/socket/constants";
import {
  currentSocketStatus,
  getSocketInstance,
  topSocketEvent,
} from "../../../../../store/selectors/SocketSelector";
import {
  getBestLiveBid,
  getNewestLiveBid,
  isLiveSentEventSuccess,
  getLiveEventStatus,
} from "../../../../../store/selectors/supplierAspect/liveBidSelector";
import { getServerLiveTimestamp } from "../../../../../store/selectors/ServerClockSelector";
import "../../styles/details-live-bid.css";
import NumericSeparatorInput from "../../../../../components/NumericSeparatorInput";
import { thousandSepratorHandler } from "../../../../../utility/utils";
import {
  AUCTION_ACCEPTED,
  SUPPLIER_AUCTION_RUNNING,
  TOAST_AUTO_CLOSE,
} from "../../../../../constants/AppConstants";
import { getCurrentLiveAuction } from "../../../../../store/selectors/auctionSelector";
import { ValidationMessage } from "../../../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";
import { GET_SELLER_MY_BID_API } from "../../../../../constants/ApiUrl";
import { Api } from "../../../../../api/Api";

const LiveBestBid = (props) => {
  const {
    currentUser,
    currentAuction,
    bestLiveBid,
    newestLiveBid,
    isConnected,
    refresLiveBiddingData,
    setNotify,
    serverLiveTS,
    isLiveSentEventSuccess,
    liveEventStatus,
    setResendApi,
    currentLiveAuction,
    getAuctionDetails,
  } = props;

  const [bidCost, setBidCost] = React.useState(null);

  const [weightedBid, setWeightedBid] = React.useState(null);

  const [bidCostLimit, setBidCostLimit] = React.useState(0);

  const [minStep, setMinStep] = React.useState(0);

  const [currBidVal, setCurrBidVal] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [currBidRank, setCurrBidRank] = React.useState(0);

  const [bonMalPercVal, setBonMalPercVal] = React.useState(0);
  const [bonMalAmntVal, setBonMalAmntVal] = React.useState(0);
  const [myBidData, setMyBidData] = React.useState([]);

  const [resvPrice, setResvPrice] = React.useState(
    -1 * Number.MAX_SAFE_INTEGER
  );

  const [timeRemLabel, setTimeRemLabel] = React.useState("--:--:--");
  const [prevBidAmount, setPrevBidAmount] = React.useState(null);
  const [prevBidAmounts, setPrevBidAmounts] = React.useState([]);

  // Function to Get the My Bid Data
  const getAllmyBidAmmountList = async () => {
    const API = `${GET_SELLER_MY_BID_API}/${currentAuction.auctionId._id}?per_page=-1`;
    const response = await Api(API, {}, "get");
    if (response.status === 200) {
      if (response.data && response.data.getMyBid !== undefined) {
        const oldBid = response.data.getMyBid.map((item) => item.bid);
        setMyBidData(oldBid);
      }
    }
  };

  const getColorOfPrice = () => {
    if (getBestBidDetails().bid && resvPrice <= getBestBidDetails().bid) {
      // reserve price has been reached
      return "pass";
    } else {
      return "hazel";
    }
  };

  const calculateTimeLeft = async (dueTime) => {
    if (dueTime >= 0) {
      const hours = Math.floor((dueTime / 3600) % 24);
      const minutes = Math.floor((dueTime / 60) % 60);
      const seconds = Math.floor(dueTime % 60);
      setTimeRemLabel(
        ("0" + hours).substr(-2) +
          ":" +
          ("0" + minutes).substr(-2) +
          ":" +
          ("0" + seconds).substr(-2)
      );
    }
  };

  const setupTimer = (timerSetting) => {
    if (!timerSetting.canCount) {
      setTimeRemLabel(timerSetting.dueTime > 0 ? "00:00:00" : "--:--:--");

      if (
        timerSetting.dueTime > 0 &&
        currentAuction.auctionStatus === SUPPLIER_AUCTION_RUNNING
      ) {
        getAuctionDetails();
        // setResendApi();
      }
    } else {
      if (currentAuction.auctionStatus === AUCTION_ACCEPTED) {
        getAuctionDetails();

        // setResendApi();
      }
      calculateTimeLeft(timerSetting.dueTime);
    }
  };

  const calculateWeightedBid = () => {
    let CpyWeightedBid = bidCost;
    if (bonMalPercVal !== 0) {
      // consider the percetage .
      CpyWeightedBid = Math.round(bidCost + (bidCost * bonMalPercVal) / 100);
      setWeightedBid(CpyWeightedBid);
    } else {
      // consider the fixed amount .
      CpyWeightedBid = Math.round(bidCost + bonMalAmntVal);
      setWeightedBid(CpyWeightedBid);
    }
  };

  const sendNewBid = () => {
    if (
      isConnected === true &&
      currentAuction._id &&
      currentAuction.auctionId
    ) {
      setIsDisabled(true);
      calculateWeightedBid();
      let startDate = new Date(currentAuction.auctionId.startDate);
      let endDate = new Date(currentAuction.auctionId.endDate);
      let serverTime = new Date(serverLiveTS);
      if (
        currentAuction.supplierStatus === AUCTION_ACCEPTED &&
        serverTime - startDate >= 0 &&
        !(endDate - serverTime <= 0)
      ) {
        SocketHandler.emit(SEND_NEW_BID_EVENT, {
          supplierAuctionId: currentAuction?._id,
          auctionId: currentAuction?.auctionId?._id,
          bid: parseInt(bidCost),
          weightedBid: weightedBid,
          supplierId: currentUser?.company?._id,
          createdBy: currentUser?._id,
          groupId: `auction_${currentAuction?.auctionId?._id}_group`,
        });
        setTimeout(() => {
          setIsDisabled(false);
        }, 2000);
      } else {
        toast.error(ValidationMessage.notPlaceBid, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    }
  };

  const getBestBidDetails = () => {
    if (bestLiveBid !== undefined && bestLiveBid.bid !== undefined) {
      return {
        // bid: bestLiveBid.bid,
        bid: bestLiveBid.weightedBid,
      };
    }
    return {
      bid: 0,
    };
  };

  const isItForRankOnly = () => {
    if (currentAuction && currentAuction.auctionId) {
      return currentAuction.auctionId.show_to_supplier === "2";
    }
    return false;
  };

  useEffect(() => {
    if (isConnected === true && currentAuction._id) {
      // join group ...
      SocketHandler.emit(GOIN_LIVE_BID_GROUP_EVENT, {
        groupId: `auction_${currentAuction?.auctionId?._id}_group`,
        supplierAuctionId: currentAuction?._id,
        auctionId: currentAuction?.auctionId?._id,
        type: "supplier",
      });
    }
  }, [isConnected, currentAuction]);

  useEffect(() => {
    if (isConnected === true && currentAuction._id) {
      if (newestLiveBid && newestLiveBid.createdBy && currentUser._id) {
        if (newestLiveBid.createdBy === currentUser._id) {
          setCurrBidVal(newestLiveBid.bid);
          setCurrBidRank(newestLiveBid.rank);
          if (newestLiveBid.bid !== 0) {
            if (newestLiveBid.bid - minStep > 0) {
              setBidCost(Number.parseFloat(newestLiveBid.bid - minStep));
              setBidCostLimit(Number.parseFloat(newestLiveBid.bid - minStep));
            } else {
              setBidCost(0);
              setBidCostLimit(0);
            }
          }
        } else {
          setCurrBidVal(newestLiveBid.bid);
          setCurrBidRank(newestLiveBid.rank);
        }
      } else if (
        newestLiveBid &&
        newestLiveBid.bid.toString().length > 0 &&
        newestLiveBid.rank.toString().length > 0
      ) {
        setCurrBidVal(newestLiveBid.bid);
        setCurrBidRank(newestLiveBid.rank);
      }
    }
  }, [newestLiveBid]);

  useEffect(() => {
    if (isConnected === true && currentAuction && currentAuction._id) {
      let res = SetupLiveMonitoringBL({
        currentAuction: currentAuction,
        serverLiveTS: serverLiveTS,
      });
      setupTimer(res.timeRemaining);
    }
  }, [serverLiveTS]);

  useEffect(() => {
    if (isLiveSentEventSuccess === true) {
      if (liveEventStatus.message) {
        toast.success(liveEventStatus.message, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    } else {
      toast.error(liveEventStatus.message, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  }, [isLiveSentEventSuccess]);

  const resetLiveBidSettings = () => {
    setMinStep(currentAuction.auctionId.minimum_step);
    setResvPrice(currentAuction.auctionId.reserve_price);
    setBidCost(Number.parseFloat(currentAuction.auctionId.starting_price));
    setBidCostLimit(Number.parseFloat(currentAuction.auctionId.starting_price));
    setBonMalPercVal(
      currentAuction.auctionId.bonus_malus_percent === null
        ? 0
        : Number.parseFloat(currentAuction.auctionId.bonus_malus_percent)
    );
    setBonMalAmntVal(
      currentAuction.auctionId.bonus_malus_amount === null
        ? 0
        : Number.parseFloat(currentAuction.auctionId.bonus_malus_amount)
    );
  };

  useEffect(() => {
    if (currentAuction._id && currentUser._id) {
      resetLiveBidSettings();
      // getAllmyBidAmmountList();
    }
  }, [currentAuction, currentUser]);

  return (
    <>
      {refresLiveBiddingData && <Loader />}
      <div className="mui-card supp-auction-details-live-bid-container">
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={12}
            style={{ textAlign: "center" }}
            className={isItForRankOnly() ? "d-none" : "d-block"}
          >
            <div className="number-bis">
              <span>Best Bid</span>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center" }}
            className={isItForRankOnly() ? "d-none" : "d-block"}
          >
            <div className="reserve-price-container">
              <div className={"price " + getColorOfPrice()}>
                {thousandSepratorHandler(getBestBidDetails().bid) +
                  " " +
                  currentAuction?.auctionId?.currency}
              </div>
              <div className={"label"}>
                {getBestBidDetails().bid &&
                resvPrice <= getBestBidDetails().bid ? (
                  <span style={{ color: "#009B73", fontStyle: "italic" }}>
                    Reserve price reached
                  </span>
                ) : (
                  <span
                    style={{ color: "var(---ffc107)", fontStyle: "italic" }}
                  >
                    Reserve price not yet reached
                  </span>
                )}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center" }}
            className={!isItForRankOnly() ? "d-none" : "d-block"}
          >
            <div style={{ fontWeight: "700", fontSize: "24px" }}>Rank</div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center" }}
            className={!isItForRankOnly() ? "d-none" : "d-block"}
          >
            <div className="reserve-price-container">
              <div className={"price " + getColorOfPrice()}>
                {currBidRank}
                <sup className="sm-sup-rank">
                  {currBidRank == 1
                    ? "st"
                    : currBidRank == 2
                    ? "nd"
                    : currBidRank == 3
                    ? "rd"
                    : "th"}
                </sup>
              </div>
            </div>
          </Grid>
          <Grid item xs={10}>
            <div className="mui-card mui-card-primary item-stc-container mt-3">
              <Grid container>
                <Grid item sm={4} className="icon-container">
                  <img src={TimeClockImage} className="icon clr-ffffff" />
                </Grid>
                <Grid item sm={6}>
                  <Grid container className="content-container timer">
                    <Grid item sm={12}>
                      <div className="mui-label">Time Remaining</div>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="text"> {timeRemLabel} </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <div className="minimum-step">
              <div>Minimum Step</div>
              <div>
                {thousandSepratorHandler(minStep) +
                  " " +
                  currentAuction?.auctionId?.currency}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="place-bid-container mt-4">
              <div className="mui-label" style={{ marginBottom: "15px" }}>
                Place your BID
              </div>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={2}>
                  <Controls.Button
                    startIcon={<RemoveIcon />}
                    onClick={() =>
                      bidCost - minStep > 0
                        ? setBidCost(Number.parseFloat(bidCost - minStep))
                        : setBidCost(0)
                    }
                    color="default"
                    className="btn btn-primary btn-mui w-100 decrease "
                  />
                </Grid>

                <Grid item xs={7}>
                  <Controls.Input
                    fullWidth
                    name="price"
                    // value={bidCost + " " + currentAuction?.auctionId?.currency}
                    value={bidCost}
                    onChange={(e) => {
                      setBidCost(e.target.value.replace(/([a-zA-Z ])/g, ""));

                      // e.target.value <= bidCostLimit && e.target.value > 0
                      //   ? setBidCost(e.target.value.replace(/([a-zA-Z ])/g, ""))
                      //   : setBidCost(bidCost);
                    }}
                    InputProps={{
                      inputComponent: NumericSeparatorInput,
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controls.Button
                    startIcon={<AddIcon />}
                    onClick={() =>
                      bidCost + minStep <= bidCostLimit
                        ? setBidCost(Number.parseFloat(bidCost + minStep))
                        : setBidCost(Number.parseFloat(bidCost))
                    }
                    color="default"
                    className="btn btn-primary btn-mui w-100 increase "
                  />
                </Grid>
                {(bidCost > bidCostLimit || bidCost <= 0) && (
                  <div className="error-msg">Please enter valid Bid</div>
                )}
                <Grid item xs={12}>
                  <Controls.Button
                    text="Bid"
                    loader={isDisabled ? true : false}
                    color="default"
                    disabled={isDisabled}
                    className={`btn  btn-mui w-100 mt-5 place-btn button ${
                      isDisabled ? "button-disabled" : "btn-primary"
                    }`}
                    onClick={() => sendNewBid()}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginBottom: "100px" }}
          >
            <div className="minimum-step">
              <div>My Current Bid</div>
              <div>
                {thousandSepratorHandler(currBidVal) +
                  " " +
                  currentAuction?.auctionId?.currency}{" "}
                ({currBidRank})
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuction: getCurrentAuction(state),
    currentUser: currentUser(state),
    socket: getSocketInstance(state),
    isConnected: currentSocketStatus(state),
    topSocketEvent: topSocketEvent(state),
    bestLiveBid: getBestLiveBid(state),
    newestLiveBid: getNewestLiveBid(state),
    serverLiveTS: getServerLiveTimestamp(state),
    isLiveSentEventSuccess: isLiveSentEventSuccess(state),
    liveEventStatus: getLiveEventStatus(state),
    currentLiveAuction: getCurrentLiveAuction(state),
  };
};

export default withRouter(connect(mapStateToProps)(LiveBestBid));
