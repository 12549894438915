import { UPDATE_LIVE_BIDDING_RANK, UPDATE_LIVE_DATA, UPDATE_LIVE_EVENT_STATUS } from "../../constant/supplierAspect/liveBidConstant";

const initialState = {
  best: {},
  new: {},
  totalCount: 0,
  status: 200,
  message: "",
  success: true
};

export default function liveBidReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LIVE_DATA:
      return {
        ...state,
        best: action.payload.best ? action.payload.best : state.best,
        new: action.payload.new ? action.payload.new : state.new,
        totalCount: action.payload.totalCount ? action.payload.totalCount : state.totalCount,
        message: action.payload?.message,
        status: action.payload.status ? action.payload.status : 200,
        success: action.payload.success !== undefined
          && action.payload.success !== ""
          && action.payload.success !== null ?
          action.payload.success : true,
      };

    case UPDATE_LIVE_EVENT_STATUS:
      return {
        ...state,
        message: action.payload?.message,
        status: action.payload.status ? action.payload.status : 200,
        success: action.payload.success !== undefined
          && action.payload.success !== ""
          && action.payload.success !== null ?
          action.payload.success : true,
      };

    case UPDATE_LIVE_BIDDING_RANK:
      let myCurrentBid = Object.assign({}, state.new);
      myCurrentBid['rank'] = action.payload.rank
      return Object.assign({}, state, {
        new: myCurrentBid,
      });

    default:
      return state;
  }
}
