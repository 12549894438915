import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Copyright from "../../components/Copyright";
import Loader from "../../components/Loader/Loader";
import LiveAuctionTabContainer from "./components/LiveAuctionTabContainer";
import LiveAuctionBanner from "./components/LiveAuctionBanner";
import GetAuctionByIdBL from "./BL/GetAuctionByIdBL";
import "./styles/live-index.css";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCurrentLiveAuction,
  shouldRefreshLiveBiddingData,
} from "../../store/selectors/auctionSelector";
import { useParams } from "react-router-dom";
import {
  refreshLiveBiddingDataBuyer,
  setLiveAuctionDetails,
} from "../../store/actions/auctionAction";
import { currentUser } from "../../store/selectors/AuthSelectors";
import { useHistory } from "react-router-dom";
import { setHTTPError } from "../../store/actions/errorHandlerAction";

const LiveAuction = (props) => {
  const { id } = useParams();
  const { refreshLiveData, currentUser } = props;
  const UnAuthorizedRoute = useSelector(
    (state) => state.errorHandler.UnAuthorizedRoute
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadding, setLoadding] = useState(true);
  const [resendApi, setResendApi] = useState(true);

  useEffect(async () => {
    if (resendApi == true) {
      const result = await GetAuctionByIdBL({ id: id });
      setLoadding(false);
      setResendApi(false);
      if (result.success) {
        dispatch(setLiveAuctionDetails(result.auction));
      } else {
        if (result.code === 403) {
          dispatch(
            setHTTPError({
              code: 403,
              message: result.message,
            })
          );
          history.push(UnAuthorizedRoute);
        } else {
          dispatch(
            setHTTPError({
              code: result.code,
              message: result.message,
            })
          );
        }
      }
    }
  }, [resendApi]);

  useEffect(() => {
    if (refreshLiveData == true) {
      dispatch(refreshLiveBiddingDataBuyer(false));
    }
  }, [refreshLiveData]);

  return (
    <>
      {loadding && <Loader />}
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <main className="app-main-section-container live-auction">
          <Container maxWidth="lg">
            <LiveAuctionBanner refreshLiveData={refreshLiveData} />
            <LiveAuctionTabContainer refreshLiveData={refreshLiveData} />
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLiveAuction: getCurrentLiveAuction(state),
    refreshLiveData: shouldRefreshLiveBiddingData(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(LiveAuction));
