import { Api } from "../../../api/Api";
import { GET_COMPANY_AUCTION_API } from "../../../constants/ApiUrl";

const GetAuctionByIdBL = async (props) => {
  const { id } = props;
  let auction = {};
  let message = "details has been fetched successfully !";
  let code = 200;
  let success = true;

  const response = await Api(`${GET_COMPANY_AUCTION_API}/${id}`, {}, "get");
  if (response.status === 200) {
    if (
      response.data &&
      response.data.responseCode === "00" &&
      response.data.auction !== undefined
    ) {
      auction = response.data.auction;
    }
  } else {
    message = response.data.responseDescription
      ? response.data.responseDescription
      : message;
    success = false;
    code = response.status;
  }

  // let response = await axiosInstance.get(`api/buyer/company/auctions/${id}`);

  return {
    auction,
    success,
    code,
    message,
  };
};

export default GetAuctionByIdBL;
