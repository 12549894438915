import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { store } from "../../store/store";
import { Grid, InputAdornment, InputLabel, TextField } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Notification from "../../components/Notification";
import Modal from "@mui/material/Modal";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import Search from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Moment from "moment";
import useWindowDimensions from "../../components/useWindowDimensions";
import TableHeadOptionList from "../../components/TableHeadOptionList";
import TableOptionList from "../../components/TableOptionList";
import TablePaginationBox from "../../components/TablePaginationBox";
import "./styles/item.css";
import "../Items/item.css";
import { isAdmin, isBuyer, isSeller } from "../../store/selectors/RoleSelector";
import { getCurrentRole } from "../../store/selectors/AuthorSelector";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Api } from "../../api/Api";
import {
  DELETE_ITEM_CATEGORY_API,
  GET_COMPANY_ITEM_CATEGORY_API,
  PATCH_EDIT_ITEMS_CATEGORY_API,
  POST_COMPANY_ADD_ITEM_API,
} from "../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

var columns = [
  {
    label: "Name",
    minWidth: 230,
  },
  {
    label: "Type",
    minWidth: 200,
  },
  {
    label: "Date Added",
    minWidth: 200,
  },
];

const initialFValues = {
  names: "",
};

const CustomPaginationActionsTable = (props) => {
  const { setFullLoder, isAdmin, isSeller, isBuyer, currentRole } = props;
  const { xsScreen, smScreen } = useWindowDimensions();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [open, setOpen] = React.useState(false);
  const [modelTitle, setModelTitle] = React.useState("");
  const handleClose = () => setOpen(false);
  const [categoryGroupList, setCategoryGroupList] = useState([]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editItemId, setEditItemId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const numOfPages = Math.ceil(categoryGroupList.length / rowsPerPage);

  const createNewItem = (itemName) => {
    setIsEditMode(false);
    setEditItemId("");
    resetForm();
    if (itemName === "Group") {
      setModelTitle(itemName);
      setOpen(true);
      setAnchorEl(null);
    } else if (itemName === "Category") {
      setModelTitle(itemName);
      setOpen(true);
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("names" in fieldValues)
      temp.names = fieldValues.names ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() && isAdmin) {
      const { auth } = store.getState();
      if (!isEditMode) {
        const payload = {
          name: values.names,
          itemType: modelTitle.toLowerCase(),
          companyId: auth?.auth?.user?.company?._id,
          userId: auth?.auth?.user?._id,
        };
        const response = await Api(POST_COMPANY_ADD_ITEM_API, payload, "post");
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            setOpen(false);
            resetForm();
            getGroupAndCategoryList();
            setIsEditMode(false);
          }
        }
      } else {
        if (editItemId !== "") {
          const updatePaylod = {
            name: values.names,
            userId: auth?.auth?.user?._id,
            itemType: modelTitle.toLowerCase(),
          };
          const response = await Api(
            `${PATCH_EDIT_ITEMS_CATEGORY_API}/${editItemId}`,
            updatePaylod,
            "patch"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              setOpen(false);
              resetForm();
              getGroupAndCategoryList();
              setEditItemId("");
              setIsEditMode(false);
            }
          }
        } else {
          toast.error(ValidationMessage.somethingWrong, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
      }
    }
  };

  const clickOnCancel = () => {
    setOpen(false);
    resetForm();
  };

  const getGroupAndCategoryList = async (searchText) => {
    let queryString = "";
    if (searchText) {
      queryString = `?search=${searchText}`;
    }
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    setFullLoder(true);
    if (companyId) {
      const response = await Api(
        `${GET_COMPANY_ITEM_CATEGORY_API}/${companyId}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let CatAndGroups = response.data.itemsCatAndGroups;
          setTimeout(() => {
            setCategoryGroupList(CatAndGroups);
          }, 250);
          setTimeout(() => {
            setFullLoder(false);
          }, 300);
        } else {
          setCategoryGroupList([]);
        }
      } else {
        setCategoryGroupList([]);
        setTimeout(() => {
          setFullLoder(false);
        }, 350);
      }
    }
  };

  const deleteItem = async (itemData) => {
    let responseType = itemData?.itemType;
    let finalType = responseType[0].toUpperCase() + responseType.substring(1);

    const itemId = itemData?._id;
    if (itemId && isAdmin) {
      const response = await Api(
        `${DELETE_ITEM_CATEGORY_API}/${itemId}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        setFullLoder(false);
        if (response.data.responseCode === "00") {
          const newCategoryGroupList = categoryGroupList.filter(
            (item) => item._id !== itemId
          );
          setCategoryGroupList(newCategoryGroupList);
          const numOfPages = Math.ceil(
            newCategoryGroupList.length / rowsPerPage
          );
          if (page > numOfPages) {
            setPage(numOfPages);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (categoryGroupList.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [categoryGroupList, page]);

  const editRow = async (itemData) => {
    if (itemData && isAdmin) {
      setIsEditMode(true);
      setValues({
        names: itemData.name,
      });
      setEditItemId(itemData._id);
      setOpen(true);
      setModelTitle(itemData.itemType);
    }
  };

  const getSearchString = (e) => {
    const searchText = e.target.value;
    getGroupAndCategoryList(searchText);
  };

  useEffect(() => {
    if (isAdmin) {
      columns.push({
        label: "",
      });
    }
    getGroupAndCategoryList();
  }, []);

  return (
    <>
      <p className="profile-title">Items</p>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          style={{ textAlign: xsScreen ? "center" : "start" }}
        >
          <TextField
            onChange={getSearchString}
            placeholder="Search Using Keyword or Tags..."
            className="select-box select-box-primary search-box"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {!isAdmin ? (
          <></>
        ) : (
          <Grid
            item
            sm={6}
            xs={12}
            style={{ textAlign: xsScreen ? "center" : "end" }}
          >
            <Button
              aria-controls="demo-customized-menu"
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              className="setting-button"
              endIcon={<KeyboardArrowDownIcon />}
            >
              <AddOutlinedIcon style={{ fontSize: "20px" }} />
              <span>Add Category</span>
            </Button>
            <TableHeadOptionList
              id="demo-customized-menu"
              MenuListProps={{
                "aria-label": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={() => createNewItem(null)}
            >
              <MenuItem onClick={() => createNewItem("Group")}>
                <div>New Group</div>
              </MenuItem>
              <MenuItem onClick={() => createNewItem("Category")}>
                <div>New Category</div>
              </MenuItem>
            </TableHeadOptionList>
          </Grid>
        )}
      </Grid>
      <TableContainer className="table-container mt-5" component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "700" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? categoryGroupList.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
              : categoryGroupList
            ).map((cateGroup) => (
              <TableRow key={cateGroup._id}>
                <TableCell>{cateGroup.name}</TableCell>
                <TableCell style={{ textTransform: "capitalize" }}>
                  {cateGroup.itemType}
                </TableCell>
                <TableCell>
                  {Moment(cateGroup.createdDate).format("DD/MM/YYYY LT")}
                </TableCell>
                {!isAdmin ? (
                  <></>
                ) : (
                  <TableCell>
                    <Box>
                      <PopupState variant="popover" popupId="option-list-menu">
                        {(popupState) => (
                          <div>
                            <Button
                              className="action-btn"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </Button>
                            <TableOptionList {...bindMenu(popupState)}>
                              <MenuItem
                                className="bold-line-600"
                                onClick={() => editRow(cateGroup)}
                              >
                                Edit Item
                              </MenuItem>
                              <MenuItem
                                className="bold-line-600 clr-ff0000"
                                onClick={() => deleteItem(cateGroup)}
                              >
                                Delete
                              </MenuItem>
                            </TableOptionList>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {categoryGroupList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                  No records found <br />
                  {/* <i style={{ color: "var(---2170ff-p-)" }}>
                    Please add some categories or groups
                  </i> */}
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePaginationBox
          label={categoryGroupList.length + " categories & groups Found"}
          numOfPages={numOfPages}
          onChangePage={(event, newPage) => setPage(newPage)}
          page={page}
        />
      </TableContainer>
      <Notification notify={notify} setNotify={setNotify} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-label="add-edit-item-cat-settings-modal"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <div className="title">
            {!isEditMode ? "Add" : "Edit"} {modelTitle}
          </div>
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel className="mui-label">{`${modelTitle} Name`}</InputLabel>
                <Controls.Input
                  name="names"
                  fullWidth
                  value={values.names}
                  onChange={handleInputChange}
                  error={errors.names}
                  className="StepControlInput"
                  inputProps={{ maxLength: 30 }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" className="mt-5">
              <Grid item md={4} sm={12}>
                <Controls.Button
                  text="Cancel"
                  type="submit"
                  disableElevation
                  color="default"
                  onClick={() => clickOnCancel()}
                  className="btn btn-primary-lite btn-mui m-0 w-100"
                />
              </Grid>
              <Grid item md={5} sm={12} className={smScreen ? "mt-2" : ""}>
                <Controls.Button
                  type="submit"
                  disableElevation
                  text={!isEditMode ? "Add" : "Save"}
                  className="btn btn-primary btn-mui m-0 w-100"
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(CustomPaginationActionsTable)
);
