import { Box, Grid } from "@material-ui/core";
import { InputLabel, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Controls from "../../../components/controls/Controls";
import { Form, useForm } from "../../../components/useForm";
import { store } from "../../../store/store";
import uploadIcon from "../../../assets/icons/Upload@2x.png";
import attachIcon from "../../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../../assets/icons/CloseX.png";
import {
  ACCEPT_ITEM_ATTACHMENTS,
  ITEM_ATTACHMENTS_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../../constants/AppConstants";
import { ValidationMessage } from "../../../constants/ValidationMessage";
import { Api } from "../../../api/Api";
import {
  POST_ITEM_EDIT_UPLOAD_DOCUMENT_API,
  POST_ITEM_UPLOAD_DOCUMENT_API,
} from "../../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";
import CommonButton from "../../../components/controls/CommonButton";

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

const initialFValues = {
  name: "",
  notes: "",
};

export default function ItemAttachDocPopup(props) {
  const {
    openPopup,
    setOpenPopup,
    setNotify,
    editAttachmentData,
    setEditAttachmentData,
    isEditAttachment,
    setIsEditAttachment,
    itemDetail,
    getAllAttachments,
  } = props;

  const [documentFile, setDocumentFile] = React.useState({});
  const [showLoading, setShowLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("notes" in fieldValues)
      temp.notes = fieldValues.notes ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const { auth } = store.getState();
      const userId = auth?.auth?.user?._id;
      if (!isEditAttachment) {
        if (documentFile && documentFile.name) {
          let formData = new FormData();
          formData.append("id", itemDetail?._id);
          formData.append("title", values.name);
          formData.append("notes", values.notes);
          formData.append("documents", documentFile);
          formData.append("createdBy", userId);
          formData.append("type", "documents");
          setShowLoading(true);
          const response = await Api(
            POST_ITEM_UPLOAD_DOCUMENT_API,
            formData,
            "postMultipart"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              resetForm();
              setOpenPopup(false);
              setDocumentFile({});
              getAllAttachments();
            }
          }
        } else {
          toast.error(ValidationMessage.selectDocument, {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          });
        }
        setShowLoading(false);
      } else {
        if (editAttachmentData && editAttachmentData._id) {
          let formData = new FormData();
          if (documentFile && documentFile.fileName) {
          } else {
            formData.append("documents", documentFile);
          }
          formData.append("id", itemDetail?._id);
          formData.append("title", values.name);
          formData.append("notes", values.notes);
          formData.append("userId", userId);
          formData.append("attachmentId", editAttachmentData?._id);
          formData.append("type", "documents");
          formData.append("createdBy", userId);
          setShowLoading(true);

          const response = await Api(
            POST_ITEM_EDIT_UPLOAD_DOCUMENT_API,
            formData,
            "postMultipart"
          );
          if (response.status === 200) {
            if (response.data.responseCode === "00") {
              resetForm();
              setOpenPopup(false);
              getAllAttachments();
              setEditAttachmentData({});
              setIsEditAttachment(false);
            }
          }

          setShowLoading(false);
        } else {
        }
      }
    }
  };

  async function handleImageChange(e) {
    const selected = e.target.files[0];

    const ALLOWED_TYPES = ACCEPT_ITEM_ATTACHMENTS;
    if (selected && !ALLOWED_TYPES.includes(selected.type)) {
      toast.error(ValidationMessage.itemFileType, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }
    if (selected && Math.round(selected.size / 1024) > ITEM_ATTACHMENTS_SIZE) {
      toast.error(ValidationMessage.itemAttachmentFileSize, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setDocumentFile(selected);
    }
  }

  const pressCancel = () => {
    resetForm();
    setEditAttachmentData({});
    setIsEditAttachment(false);
    setOpenPopup(false);
  };

  const selectedDocRemove = () => {
    setDocumentFile({});
  };

  useEffect(() => {
    if (editAttachmentData && editAttachmentData?.fileName) {
      setDocumentFile(editAttachmentData);
      setValues({
        name: editAttachmentData?.title,
        notes: editAttachmentData?.notes,
      });
    }
  }, [editAttachmentData]);

  return (
    <Modal
      open={openPopup}
      aria-label="item-list-info-details-attach-docs-popup"
      aria-describedby="item-list-info-details-attach-docs-popup"
    >
      <Box className="popup-form-container">
        <div className="title">Attach Document</div>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="image-uploader-container">
                <div className="uploader-header">
                  <img src={uploadIcon} className="upload-icon" alt="" />
                  <p className="upload-title">Drag or drop files here</p>

                  <p className="upload-description">
                    Max. file size is 10mb <br />
                    Files must be .pdf, .exl, .png, or .jpg
                  </p>

                  <label htmlFor="fileUploadAttach">
                    <Controls.Button
                      component="span"
                      text="Choose File"
                      disableElevation
                      className="mui-btn upload-btn"
                    />
                  </label>
                </div>
                <input
                  type="file"
                  id="fileUploadAttach"
                  onChange={handleImageChange}
                  hidden
                />
              </div>
            </Grid>

            {documentFile && (documentFile.name || documentFile.fileName) ? (
              <Grid item xs={12} style={{ alignItems: "flex-end" }}>
                <Box component="div" className="uploaded-files">
                  <Grid container>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      <img src={attachIcon} alt="" className="icon" />
                    </Grid>
                    <Grid item xs={9} className="details">
                      <Grid container>
                        <Grid item xs={12}>
                          <box className="name">
                            {documentFile?.name || documentFile?.fileName}
                          </box>
                        </Grid>
                        <Grid item xs={12}>
                          <box className="size">
                            {bytesToSize(documentFile?.size || 20000)}
                          </box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{ textAlign: "end", margin: "auto" }}
                      onClick={() => selectedDocRemove()}
                    >
                      <img src={closeIcon} alt="" />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={12}>
              <InputLabel className="mui-label">Name</InputLabel>
              <Controls.Input
                // labelId="notes-input"
                name="name"
                className="StepControlInput"
                onChange={handleInputChange}
                value={values.name}
                error={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mui-label">Notes</InputLabel>
              <Controls.Input
                // labelId="notes-input"
                name="notes"
                Rows={4}
                className="StepControlInput"
                onChange={handleInputChange}
                value={values.notes}
                error={errors.notes}
              />
            </Grid>
          </Grid>
          <Box display="flex" mt={4} bgcolor="background.paper">
            <Grid istem xs={4} className="BottomModal-left">
              <Box p={0} flexGrow={1}>
                <Controls.Button
                  text="Cancel"
                  disableElevation
                  onClick={() => pressCancel()}
                  style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                  color="default"
                  className="btn-mui"
                />
              </Box>
            </Grid>
            <Grid item xs={8} className="BottomModal-right">
              <Box p={0}>
                <CommonButton
                  type="submit"
                  disableElevation
                  text="Attach"
                  className="btn-mui"
                  style={{ minWidth: "197px" }}
                  loader={showLoading}
                />
              </Box>
            </Grid>
          </Box>
        </Form>
      </Box>
    </Modal>
  );
}
