import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Search from "@material-ui/icons/Search";
import Loader from "../../../components/Loader";
import "../../../styles/filter-box.css";

import usePrevious from "../../../components/usePrevious";
import RfxFilter from "../Components/RfxFilter";
import RfxSellerTable from "./RfxSellerTable";
import GetAllRfxSeller from "../Components/RfxProcessFunction/GetAllRfxSeller";
import { currentUser } from "../../../store/selectors/AuthSelectors";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import RfxSellerFilter from "./RfxSellerFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
}));

const RfxSellerList = (props) => {
  const { currentUser } = props;
  const classes = useStyles();
  const [showFilterCategories, setShowFilterCategories] = useState(false);
  const [rfxList, setRfxList] = useState([]);
  const [fullLoder, setFullLoder] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataOrder, setDataOrder] = useState("desc");
  const [resendApi, setResendApi] = useState(false);
  const [numOfPages, setNumOfPages] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const prevPageValue = usePrevious(page);
  const [searchKey, setSearchKey] = useState("");
  const [filterValues, setFilterValues] = React.useState({
    status: "-1",
    client: "-1",
    manager: "",
    type: "-1",
  });

  const toggleFilterCategory = () => {
    setShowFilterCategories(!showFilterCategories);
  };

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
    setResendApi(true);
  };

  useEffect(() => {
    if (resendApi) {
      setFullLoder(true);
      setResendApi(false);
      getAllRfxList();
    }
  }, [resendApi]);

  useEffect(() => {
    if (currentUser) {
      setResendApi(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && prevPageValue !== undefined && prevPageValue !== page) {
      setResendApi(true);
    }
  }, [page]);

  const getSearchString = (e) => {
    setSearchKey(e.target.value);
    setResendApi(true);
  };

  const getAllRfxList = () => {
    GetAllRfxSeller({
      companyId: currentUser.company._id,
      rowsPerPage: rowsPerPage,
      page: page,
      searchKey: searchKey,
      filterValues: filterValues,
      dataOrder: dataOrder,
    })
      .then((data) => {
        setFullLoder(false);
        setRfxList(data.list);
        setLimit(data.limit);
        setNumOfPages(Math.ceil(data.limit / rowsPerPage));
      })
      .catch((error) => {
        setFullLoder(false);
      });
  };

  return (
    <>
      <Paper elevation={0} square className={classes.root}>
        <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
        {/* <Loader fullLoder={false} /> */}
        <Grid container style={{ marginTop: "1em" }}>
          <Grid item container xs={12} style={{ alignItems: "center" }}>
            <Grid item xs={3}>
              <h1 className="item-container">RFX</h1>
            </Grid>
            <Grid item xs={9}>
              <Box style={{ float: "right" }}>
                <Controls.Button
                  variant="contained"
                  className={
                    "btn btn-white btn-mui" +
                    (showFilterCategories ? " active" : "")
                  }
                  text="Filter"
                  onClick={toggleFilterCategory}
                  startIcon={
                    <FilterListSharpIcon style={{ color: "#2170FF" }} />
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container className="filter-box-container">
            {showFilterCategories && (
              <Paper elevation={1} className="filter-box">
                <RfxSellerFilter
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  resendApi={resendApi}
                  setResendApi={setResendApi}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Grid item xs={12}>
            <Box sx={{ display: "inline" }}>
              <TextField
                onChange={getSearchString}
                placeholder="Search Using Keyword"
                className="select-box select-box-primary search-box"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box style={{ float: "right" }}>
              <Select
                value={rowsPerPage}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="items-count-dropdown mr-3"
              >
                <MenuItem value={10}>Show 10 entries</MenuItem>
                <MenuItem value={20}>Show 20 entries</MenuItem>
                <MenuItem value={30}>Show 30 entries</MenuItem>
              </Select>
              {/* <Select
                value={dataOrder}
                onChange={(e) => {
                  setDataOrder(e.target.value);
                  setResendApi(true);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="items-count-dropdown"
              >
                <MenuItem value={"desc"}>Sort by Latest</MenuItem>
                <MenuItem value={"asc"}>Sort by Oldest</MenuItem>
              </Select> */}
            </Box>
          </Grid>
          <RfxSellerTable
            rfxList={rfxList}
            setRowsPerPage={setRowsPerPage}
            setResendApi={setResendApi}
            page={page}
            setPage={setPage}
            limit={limit}
            numOfPages={numOfPages}
          />
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(RfxSellerList));
