import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import useWindowDimensions from "../../components/useWindowDimensions";
import Controls from "../../components/controls/Controls";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link as Links } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
// import faceLogo from "../../assets/auth/octioLogo.svg";
// import faceLogo from "../../assets/auth/Oktio_loko.png";
import faceLogo from "../../assets/auth/Logo_Oktio_last.png";
import textLogo from "../../assets/auth/logotxt.png";
import EmailIcon from "@material-ui/icons/Email";
import { connect, useDispatch } from "react-redux";

import { loadingToggleAction } from "../../store/actions/authActions";
import Notification from "../../components/Notification";

import "./styles/auth.css";
import "./styles/reset-forgot.css";
import { Api } from "../../api/Api";
import { VERIFY_FORGOT_PASSWORD_API } from "../../constants/ApiUrl";
import { VALID_EMAIL_REGEX } from "../../constants/AppConstants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(18),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fdfdfd",
    padding: theme.spacing(4),
    borderRadius: "15px",
  },
  paperRight: {
    marginTop: theme.spacing(15),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "transparent",
    padding: theme.spacing(0),
    borderRadius: "15px",
    boxShadow: "none",
    position: "relative",
  },
  logo: {
    background: "rgb(236 236 236 / 25%)",
    padding: "30px 70px",
    opacity: 0.8,
    borderRadius: "50px 0",
    position: "absolute",
    left: "-80px",
    top: "-30px",
    width: "270px",
    // opacity: "0.6",
  },
  logosvg: {
    marginTop: "100px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    border: "0px solid #ccc",
    borderRadius: "30px",
    textTransform: "none",
  },
  linktxt: {
    textDecoration: "none",
    color: "#2170ff",
  },
  normal: {
    textDecoration: "none",
    fontSize: "11px",
    fontWeight: "bold",
    opacity: "0.7",
  },

  textField: {
    border: "0px solid #ccc",
    [`& fieldset`]: {
      borderRadius: 30,
    },
  },

  txtcheckbox: {
    "& .MuiTypography-body1": {
      fontSize: "13px",
    },
  },
  forgot: {
    display: "flex",
    padding: "10px 0 0 0",
    flexDirection: "column",
    justifyContent: "center",
  },
  input: {
    color: "white",
  },
  error: {
    color: "red",
    fontSize: "11px",
    paddingLeft: "43px",
  },
}));
const ForgotPassword = (props) => {
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const [email, setEmail] = useState("");
  let errorsObj = {
    email: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();

  async function onforgotpassword(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (!email) {
      errorObj.email = "Email is required";
      error = true;
    }
    if (email !== "") {
      error = VALID_EMAIL_REGEX.test(email) ? false : true;

      if (error) errorObj.nemail = "Invalid Email";
    }

    setErrors(errorObj);

    if (error) return;
    dispatch(loadingToggleAction(true));

    // dispatch(forgotPasswordAction(email, props.history));
    //--------------------------
    const payload = {
      email: email,
    };
    const response = await Api(VERIFY_FORGOT_PASSWORD_API, payload, "post");
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        dispatch(loadingToggleAction(false));
      } else {
        dispatch(loadingToggleAction(false));
      }
    }

    // axios
    //   .post(
    //     process.env.REACT_APP_BACKEND_API_URL +
    //       `/api/users/verifyForgetPassword`,
    //     payload
    //   )
    //   .then((response) => {
    //     if (response.data.responseCode === "00") {
    //       dispatch(loadingToggleAction(false));
    //       setNotify({
    //         isOpen: true,
    //         message: response?.data?.responseDescription,
    //         type: "success",
    //       });
    //     } else {
    //       dispatch(loadingToggleAction(false));
    //       setNotify({
    //         isOpen: true,
    //         message: response?.data?.responseDescription,
    //         type: "error",
    //       });
    //     }
    //     // dispatch(forgotPasswordConfirmedAction(response.data));
    //   })
    //   .catch((error) => {
    //     setNotify({
    //       isOpen: true,
    //       message: error?.data?.responseDescription,
    //       type: "error",
    //     });
    //   });
  }

  return (
    <div className="auth-box-container reset-forgot">
      <CssBaseline />
      <div style={{ flexGrow: 1, marginBottom: "25px" }}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          style={{ flexDirection: smScreen ? "column-reverse" : "row" }}
        >
          <Grid item xl={4} lg={5} md={6} sm={8} xs={11}>
            <div className="form-box-container">
              {props.showLoading && <Loader />}
              <Grid container className="form-banner-container">
                <Grid item xs={12}>
                  <div className="main-title">Forgot Password</div>
                  <div className="sub-title">
                    Enter the email associated to your account and we'll send an
                    email with instructions to reset your password
                  </div>
                </Grid>
              </Grid>
              <form noValidate onSubmit={onforgotpassword}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item sm={10} xs={12}>
                    <Controls.Input
                      name="email"
                      autoComplete=""
                      id="email"
                      fullWidth
                      required
                      value={email}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon color="primary" fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.email && (
                      <div className="error-msg">{errors.email}</div>
                    )}
                    {errors.nemail && (
                      <div className="error-msg">{errors.nemail}</div>
                    )}
                  </Grid>
                  <Grid item sm={8} xs={12} className="mt-4">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      // disabled={!vcaptcha}
                      className="submit-btn mt-3"
                    >
                      Reset Password
                    </Button>
                  </Grid>
                  <Grid item xs={12} className="mb-3 mt-3">
                    <div className="text-center mt-5 mb-5 fz-16">
                      <Links to="/signin">Go back to Sign in</Links>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
          <Grid item lg={4} md={3} xs={8}>
            <Grid
              container
              justifyContent="center"
              alignItems="end"
              style={{ flexDirection: "column" }}
            >
              <Grid item sm={12} style={{ textAlign: "-webkit-center" }}>
                <div className="face-logo">
                  <img src={faceLogo} />
                </div>
              </Grid>
              <Grid item sm={12}>
                <div className="text-logo-box-container">
                  <div className="text-logo-box"></div>
                  <img src={textLogo} className="text-logo-img" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ForgotPassword);
