/*eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm, Form } from "../../components/useForm";
// import FormSteps from "../../components/FormStepAuction";
import FormSteps from "../../components/Formsteps";
import TableHeadOptionList from "../../components/TableHeadOptionList";
import { Box, Grid } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Controls from "../../components/controls/Controls";
import useWindowDimensions from "../../components/useWindowDimensions";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import {
  FormStep3Add,
  AddLinkData,
  auctionSaveAsDraft,
  AddLibraryDocs,
  AddLibraryDocsTemp,
} from "../../store/actions/auctionAction";
import { store } from "../../store/store";
import Modal from "@mui/material/Modal";
import Notification from "../../components/Notification";
import AddLibrary from "./components/addFromLibrary";
import ConfirmationDailog from "./components/confirmationDailog";
import "./styles/create-auction-step3.css";
import "./styles/lib-attach-modal.css";
import uploadIcon from "../../assets/icons/Upload@2x.png";
import attachIcon from "../../assets/icons/attach_file_black_24dp@2x.png";
import closeIcon from "../../assets/icons/CloseX.png";
import {
  ACCEPT_ITEM_ATTACHMENTS,
  ITEM_ATTACHMENTS_SIZE,
  TOAST_AUTO_CLOSE,
} from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { COMAPNY_API } from "../../constants/ApiUrl";
import { Api } from "../../api/Api";
import { toast, Slide } from "react-toastify";

const initialValues = {
  link: "",
  title: "",
  notes: "",
};

const Step3Form = (props) => {
  const {
    setOpenPopupStep2,
    setOpenPopupStep3,
    setOpenPopupStep4,
    stepLabels,
    setResendApi,
  } = props;
  const [error, setError] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupLib, setOpenPopupLib] = useState(false);
  const [documentFile, setDocumentFile] = useState({ files: [] });
  const [linkData, setLinkData] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDocsLibrary, setSelectedDocsLibrary] = useState([]);

  const [tempDocsLibrary, setTempDocsLibrary] = useState([]);
  const [docsList, setDocsList] = useState([]);

  const [openConfrmationDailog, setOpenConfrmationDailog] = useState(false);
  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const [listOfDocsAndLink, setListOfDocsAndLink] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const dispatch = useDispatch();
  const openMenu = Boolean(anchorEl);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("link" in fieldValues) {
      const regex = new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
      );
      if (fieldValues.link !== "") {
        if (!regex.test(fieldValues.link)) {
          temp.link = "Please enter valid link";
        } else {
          temp.link = "";
        }
      } else {
        temp.link = "This field is required.";
      }
    }

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";
    if ("notes" in fieldValues)
      temp.notes = fieldValues.notes ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const linkObj = {
        link: values.link,
        title: values.title,
        notes: values.notes,
      };
      linkData.push(linkObj);
      const neItems = [...linkData];
      setLinkData(neItems);

      listOfDocsAndLink.push(linkObj);
      const dataOfFile = [...listOfDocsAndLink];

      setListOfDocsAndLink(dataOfFile);
      resetForm();
      setOpenPopup(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const selected = e.dataTransfer.files;

    handleImageChange(selected);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  async function handleImageChange(fileData) {
    const ALLOWED_TYPES = ACCEPT_ITEM_ATTACHMENTS;
    const newFiles = [...fileData];

    let allFiles = [...documentFile.files];

    for (let i = 0; i < newFiles.length; ++i) {
      const selected = newFiles[i];
      if (!ALLOWED_TYPES.includes(selected.type)) {
        toast.error(ValidationMessage.itemFileType, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
        continue;
      }

      if (Math.round(selected.size / 1024) > ITEM_ATTACHMENTS_SIZE) {
        toast.error(ValidationMessage.itemAttachmentFileSize, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
        continue;
      }
      if (ALLOWED_TYPES.includes(selected.type)) {
        allFiles.push(selected);
        listOfDocsAndLink.push(selected);
      }
    }
    if (allFiles.length > 0) {
      setDocumentFile({ files: allFiles });
      setListOfDocsAndLink([...listOfDocsAndLink]);
      setAnchorEl(null);
    }
  }

  const removeAttachedDocument = (index) => {
    const updatedDocumentDataForList = [...listOfDocsAndLink];
    updatedDocumentDataForList.splice(index, 1);
    setListOfDocsAndLink(updatedDocumentDataForList);

    const updatedDocumentFile = [...documentFile.files];
    updatedDocumentFile.splice(index, 1);
    setDocumentFile({ files: updatedDocumentFile });

    const updatedLinkData = [...linkData];
    updatedLinkData.splice(index, 1);
    setLinkData(updatedLinkData);

    toast.error("Attachment removed.", {
      transition: Slide,
      autoClose: TOAST_AUTO_CLOSE,
    });
  };

  const clickOnNext = () => {
    dispatch(FormStep3Add(documentFile));

    if (linkData && linkData.length > 0) {
      dispatch(AddLinkData({ links: linkData }));
    }
    if (selectedDocsLibrary && selectedDocsLibrary.length > 0) {
      dispatch(AddLibraryDocs({ libDocs: selectedDocsLibrary }));
      dispatch(AddLibraryDocsTemp({ libDocsTemp: tempDocsLibrary }));
    }
    setOpenPopupStep3(false);
    setOpenPopupStep4(true);
  };

  const PressBack = () => {
    dispatch(FormStep3Add(documentFile));

    if (linkData && linkData.length > 0) {
      dispatch(AddLinkData({ links: linkData }));
    }

    if (selectedDocsLibrary && selectedDocsLibrary.length > 0) {
      dispatch(AddLibraryDocs({ libDocs: selectedDocsLibrary }));
      dispatch(AddLibraryDocsTemp({ libDocsTemp: tempDocsLibrary }));
    }

    // Close the current popup and open the previous one
    setOpenPopupStep3(false);
    setOpenPopupStep2(true);
  };

  const saveAsDraft = async () => {
    dispatch(FormStep3Add(documentFile));
    if (linkData && linkData.length > 0) {
      dispatch(AddLinkData({ links: linkData }));
    }
    if (selectedDocsLibrary && selectedDocsLibrary.length > 0) {
      dispatch(AddLibraryDocs({ libDocs: selectedDocsLibrary }));
    }
    let res = await dispatch(auctionSaveAsDraft());
    if (res?.data?.responseCode === "00") {
      if (setResendApi) {
        setResendApi(true);
      }
      setOpenPopupStep3(false);
    } else {
      setOpenPopupStep3(false);
    }
  };

  const openLinkPopup = () => {
    setOpenPopup(true);
  };

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const addDocumentFromLib = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${COMAPNY_API}/${companyId}/library`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let allDocs = response.data.library;
          setDocsList(allDocs);
          setOpenPopupLib(true);
          setAnchorEl(null);
        } else {
          setDocsList([]);
        }
      } else {
        setDocsList([]);
      }
    }
  };

  const discardChanges = () => {
    setOpenConfrmationDailog(true);
  };

  useEffect(() => {
    const { auction } = store.getState();
    if (auction?.AuctionForm1?.files) {
      const fileData = auction?.AuctionForm1?.files || [];
      const LinkData = auction?.AuctionForm1?.links || [];
      const libDocs = auction?.AuctionForm1?.libDocs || [];
      const libDocsTemp = auction?.AuctionForm1?.libDocsTemp || [];
      setListOfDocsAndLink([...fileData, ...LinkData, ...libDocsTemp]);
      if (fileData && fileData.length > 0) {
        setDocumentFile({ files: fileData });
      }
      if (LinkData && LinkData.length > 0) {
        setLinkData(LinkData);
      }
      if (libDocs && libDocs.length > 0) {
        setSelectedDocsLibrary(libDocs);
      }
      if (
        fileData &&
        fileData.length > 0 &&
        LinkData &&
        LinkData.length > 0 &&
        libDocs &&
        libDocs.length > 0
      ) {
        setDocumentFile({ files: fileData });
        const adddocs = [...fileData, ...LinkData, ...libDocsTemp];

        // setListOfDocsAndLink(adddocs);
        setLinkData(LinkData);
        setSelectedDocsLibrary(libDocs);
      }
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <p className="profile-title"> Create English Auction</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <CloseIcon
            onClick={() => discardChanges()}
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      {/* <Form onSubmit={handleSubmit} className="create-form-container"> */}
      <Grid container justifyContent="center">
        <Grid item md={11} xs={12}>
          <FormSteps steps={stepLabels} currStep={3} className="mt-5 mb-5" />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="create-form-container">
        <Grid item xs={12}>
          {error && (
            <p
              style={{
                color: "red",
                fontSize: "11px",
                margin: "3px 0",
                paddingLeft: "10px",
              }}
            >
              File Not Supported
            </p>
          )}
          <div
            className="image-uploader-container"
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
          >
            {documentFile && (
              <>
                <div className="uploader-header">
                  <img src={uploadIcon} className="upload-icon" />
                  <p className="upload-title">Drag or drop files here</p>
                  <p className="upload-description">
                    Max. file size is 10mb and files must be .pdf, .exl, .png,
                    or .jpg
                  </p>
                  <label htmlFor="fileUpload">
                    <Button
                      aria-controls="upload-doc0option-list"
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      className="mui-btn upload-btn"
                      endIcon={
                        <ArrowDropDownIcon style={{ fontSize: "24px" }} />
                      }
                    >
                      Choose File
                    </Button>
                    <TableHeadOptionList
                      id="create-auction-third-step-buyer-aspect-upload-file-options-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem>
                        <label htmlFor="fileUploadButton">
                          <input
                            type="file"
                            id="fileUploadButton"
                            hidden
                            onChange={(e) => handleImageChange(e.target.files)}
                            value={values.image}
                            multiple
                            accept={ACCEPT_ITEM_ATTACHMENTS}
                          />
                          Attach Document
                        </label>
                      </MenuItem>
                      <MenuItem onClick={() => addDocumentFromLib()}>
                        Add From Library
                      </MenuItem>
                    </TableHeadOptionList>
                    <span style={{ color: "var(---a19b9d)", margin: "0 10px" }}>
                      or
                    </span>
                    <Controls.Button
                      disableElevation
                      text="Add Link"
                      onClick={() => openLinkPopup()}
                      className="mui-btn upload-btn add-link"
                    />
                  </label>
                </div>
                <input
                  type="file"
                  id="fileUpload"
                  onChange={handleImageChange}
                  value={values.image}
                  accept={ACCEPT_ITEM_ATTACHMENTS}
                />
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container style={{ alignItems: "flex-end" }} spacing={2}>
            {listOfDocsAndLink.map((file, index) => (
              <Grid item md={4} sm={6} xs={12}>
                <Box component="div" className="uploaded-files">
                  <Grid container>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      <img
                        // src="/assets/icons/attach_file_black_24dp@2x.png"
                        src={attachIcon}
                        className="icon"
                      />
                    </Grid>
                    <Grid item xs={9} className="details">
                      <Grid container>
                        <Grid item xs={12}>
                          {/* <box className="name"> {file?.name || file?.link || file && file.document ? file?.document?.fileName.split(process.env.REACT_APP_BACKEND_API_URL + "/uploads/") : ""}</box> */}
                          <box className="name">
                            {" "}
                            {file?.name
                              ? file?.name
                              : file?.link
                              ? file?.link
                              : file?.document?.name}
                          </box>
                        </Grid>
                        <Grid item xs={12}>
                          <box className="size">
                            {bytesToSize(
                              file?.size || file?.document?.fileSize || 0
                            )}
                          </box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className="cursor-p"
                      style={{ textAlign: "end", margin: "auto" }}
                      // onClick={() => removeAttachedDocument(file, index)}
                      onClick={() => removeAttachedDocument(index)}
                    >
                      <img
                        src={closeIcon}
                        // src="/assets/icons/CloseX.png"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Box
        className="BottomModal"
        display="flex"
        p={1}
        bgcolor="background.paper"
        style={{ marginTop: "35px" }}
      >
        <Grid istem xs={4} className="BottomModal-left">
          <Box p={0} flexGrow={1}>
            <Controls.Button
              text="Back"
              disableElevation
              onClick={() => {
                PressBack();
              }}
              className="btn-mui fix-w-12em"
              color="default"
              style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
            />
          </Box>
        </Grid>
        <Grid item xs={8} className="BottomModal-right">
          <Box p={0}>
            <Controls.Button
              text="Save as Draft"
              // type="submit"
              disableElevation
              color="default"
              style={{ margin: "0 20px 0 0" }}
              onClick={() => saveAsDraft()}
              className="btn draft btn-mui fix-w-12em"
            />
          </Box>
          <Box p={0}>
            <Controls.Button
              type="submit"
              disableElevation
              text="Next"
              onClick={() => clickOnNext()}
              className="btn-mui fix-w-12em"
            />
          </Box>
        </Grid>
      </Box>
      {/* </Form> */}
      <Notification notify={notify} setNotify={setNotify} />
      {/* add link file popup  */}
      <Modal
        open={openPopup}
        aria-label="create-auction-third-step-buyer-aspect-upload-link-file"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid
              item
              sm={9}
              style={{ textAlign: xsScreen ? "center" : "start" }}
            >
              <p className="profile-title"> Add Link</p>
            </Grid>
            <Grid
              item
              sm={3}
              style={{
                textAlign: xsScreen ? "center" : "end",
              }}
            >
              <CloseIcon
                onClick={() => setOpenPopup(false)}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Form onSubmit={handleSubmit} className="create-form-container mt-3">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel id="starting_price-input">Link</InputLabel>
                <Controls.Input
                  name="link"
                  labelId="link-input"
                  fullWidth
                  value={values.link}
                  onChange={handleInputChange}
                  error={errors.link}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="starting_price-input">Title</InputLabel>
                <Controls.Input
                  name="title"
                  labelId="link-input"
                  fullWidth
                  value={values.title}
                  onChange={handleInputChange}
                  error={errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="starting_price-input">Notes</InputLabel>
                <Controls.Input
                  name="notes"
                  labelId="link-input"
                  fullWidth
                  value={values.notes}
                  onChange={handleInputChange}
                  error={errors.notes}
                  className="fix-h-8em"
                />
              </Grid>
            </Grid>
            <Box
              className="BottomModal"
              display="flex"
              p={1}
              // bgcolor="background.paper"
              style={{ marginTop: "20px" }}
            >
              <Grid container justifyContent="space-between">
                <Grid item sm={4} xs={12}>
                  <Controls.Button
                    text="Cancel"
                    disableElevation
                    onClick={() => {
                      setOpenPopup(false);
                    }}
                    style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                    color="default"
                    className="btn-mui w-100"
                  />
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Controls.Button
                    type="submit"
                    disableElevation
                    text="Save"
                    className="btn-mui w-100"
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        </Box>
      </Modal>
      {/* add link file popup  */}

      {/* add from library  */}

      <Modal
        open={openPopupLib}
        aria-label="attachment-from-lib-popup-buyer-aspect"
        aria-describedby="attachment-from-lib-popup-buyer-aspect-description"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p className="profile-title"> Library</p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopupLib(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          {/* <div className="title"> Library </div> */}
          <AddLibrary
            setOpenPopupLib={setOpenPopupLib}
            docsList={docsList}
            setSelectedDocsLibrary={setSelectedDocsLibrary}
            setListOfDocsAndLink={setListOfDocsAndLink}
            listOfDocsAndLink={listOfDocsAndLink}
            tempDocsLibrary={tempDocsLibrary}
            setTempDocsLibrary={setTempDocsLibrary}
            setDocsList={setDocsList}
          />
        </Box>
      </Modal>
      {/* add from library  */}

      <ConfirmationDailog
        openConfrmationDailog={openConfrmationDailog}
        setOpenConfrmationDailog={setOpenConfrmationDailog}
        setDiscardAndClose={setOpenPopupStep3}
        eraseEverything={false}
      />
    </>
  );
};

export default Step3Form;
