import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Search from "@material-ui/icons/Search";

import Notification from "../../../components/Notification";

import Loader from "../../../components/Loader";
import SuperAdminTable from "./SuperAdminTable";
import CreateSuperAdminModel from "./Components/CreateSuperAdminModel";
import GetAllSuperAdminBL from "./SuperAdminBL/GetAllSuperAdmins";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "100%",
    border: "0px solid #ccc",
    [`& fieldset`]: {
      borderRadius: 6,
    },
  },
  selectAction: {
    "& .MuiInputLabel-root": {
      display: "none",
    },
  },
  toolbars: {
    "& .MuiToolbar-gutters": {
      padding: "12px",
      margin: "0px",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },

  // Forms

  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    "& .MuiDialogTitle-root": {
      padding: "1px",
    },
  },
  dialogTitle: {
    paddingRight: "0px",
    fontWeight: "600px",
  },
  dialog: {
    "& .MuiDialogContent-dividers ": {
      borderTop: "0px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "0px solid rgba(0, 0, 0, 0.12)",
    },
  },

  sectionText: {
    fontWeight: "600",
    fontSize: "24px",
  },
  // Header

  root: {
    backgroundColor: "transparent",
  },
  wrapper: {
    borderRadius: "12px",
    padding: "0 12px",
    backgroundColor: "red",
  },
  pageHeader: {
    padding: theme.spacing(1),
    display: "flex",
    marginBottom: theme.spacing(0),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#2170ff",
  },
  pname: { color: theme.palette.primary.main, fontWeight: "bold" },
  pdesc: { opacity: "0.5" },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  pageTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  warning: { backgroundColor: theme.palette.primary.warning },
  actionbtnRight: {
    position: "relative",
    top: 10,
    right: 10,
  },
  menupos: {},
  filterDiv: {
    width: "100%",
    padding: "1rem",
    margin: ".3rem",
  },
  searchBar: {
    width: "100%",
    margin: "1rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  sort: {
    display: "flex",
  },
  switchToggle: {
    backgroundColor: "red",
  },
}));

const SuperAdminListing = () => {
  const classes = useStyles();

  const [fullLoder, setFullLoder] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [resendApi, setResendApi] = useState(false);
  const [searchKey, setSearchKey] = React.useState("");
  const [showCreateSuperAdminModal, setShowCreateSuperAdminModal] =
    useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setResendApi(true);
  }, [rowsPerPage]);

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      <Paper elevation={0} square className={classes.root}>
        <Loader fullLoder={fullLoder} setFullLoder={setFullLoder} />
        <Grid container className="filter-table-header">
          <Grid item md={4} sm={5} xs={12}>
            <div className="title"> Super Admins</div>
          </Grid>
          <Grid item md={8} sm={7} xs={12} className="buttons-box">
            <Controls.Button
              text="Invite user"
              className="btn-mui"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setShowCreateSuperAdminModal(true)}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} square>
        <Box sx={{ padding: "24px" }}>
          <Grid xs={12}>
            <Box sx={{ display: "inline" }}>
              <TextField
                placeholder="Search Using Keyword or Tags..."
                className="select-box select-box-primary search-box"
                value={searchKey}
                onChange={(event) => {
                  setSearchKey(event.target.value);
                  setResendApi(true);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box style={{ float: "right" }}>
              <Select
                value={rowsPerPage}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="items-count-dropdown"
              >
                <MenuItem value={10} selected>
                  10
                </MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </Box>
          </Grid>

          <SuperAdminTable
            rowsPerPage={rowsPerPage}
            setFullLoder={setFullLoder}
            searchKey={searchKey}
            setResendApi={setResendApi}
            resendApi={resendApi}
          />
        </Box>
      </Paper>
      {showCreateSuperAdminModal && (
        <CreateSuperAdminModel
          open={showCreateSuperAdminModal}
          setResendApi={setResendApi}
          getSuperAdminList={GetAllSuperAdminBL}
          handleClose={() =>
            setShowCreateSuperAdminModal(!showCreateSuperAdminModal)
          }
        />
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default SuperAdminListing;
