/*eslint-disable*/
import { Suspense, useEffect, useState } from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useLocation,
} from "react-router-dom";
import SignIn from "../pages/Signup/Login";
import SupplierInvitation from "../pages/Invitation/SupplierInvitation";
import Signup from "../pages/Signup/Signup";
import ForgotPassword from "../pages/Signup/ForgotPassword";
import Dashboard from "../pages/Dashboard/Dashboard";
import Projects from "../pages/Projects/Projects";
import Items from "../pages/Items/Items";
import Settings from "../pages/Settings/setting";
import SupplierSetting from "../pages/SupplierAspect/Settings/setting";
import Suppliers from "../pages/Suppliers/suppliers";
import CompayUsers from "../pages/CompanyUsers/companyUser";
import SupplierCompanyUsers from "../pages/SupplierAspect/CompanyUsers/companyUser";
import ResetNewPassword from "../pages/Signup/ResetPassword";
import verifyEmail from "../pages/VerifyEmail/VerifyEmail";
import Success from "../pages/Signup/Success";
import { connect, useDispatch } from "react-redux";
import { checkAutoLogin } from "../services/AuthService";
import { currentUser, isAuthenticated } from "../store/selectors/AuthSelectors";
import Auction from "../pages/Auction/Auction";
import SupplierAuction from "../pages/SupplierAspect/Auction/Auction";
import SupplierAuctionDetails from "../pages/SupplierAspect/Auction/AuctionDetails";
import AuctionDetails from "../pages/Auction/AuctionDetails";
import LiveAuction from "../pages/Auction/LiveAuction";
import ProjectDetails from "../pages/Projects/projectdetailsMain";
import AuthGaurd from "./AuthGuard";
import Socket from "./Socket";
import { isAdmin, isBuyer, isSeller } from "../store/selectors/RoleSelector";
import { getCurrentRole } from "../store/selectors/AuthorSelector";
import ErrorPageComponent from "../pages/Error/indexComponent";
import "./App.css";
import "../styles/animation.css";
import "../styles/badge.css";
import "../styles/button.css";
import "../styles/card.css";
import "../styles/checkbox.css";
import "../styles/details-banner.css";
import "../styles/filter-box.css";
import "../styles/image-uploader.css";
import "../styles/input.css";
import "../styles/navbar.css";
import "../styles/popup-form.css";
import "../styles/toolip.css";
import "../styles/ratio-button.css";
import "../styles/select.css";
import "../styles/sidebar.css";
import "../styles/switch.css";
import "../styles/tab.css";
import "../styles/table.css";
import "../styles/toolip.css";
import "../styles/form.css";
import "../styles/page-slider.css";
import "../../node_modules/animate.css/animate.min.css";
import "../assets/css/shimmer.css";
import { changeCurrentSelectedRole } from "../store/actions/authorizationAction";
import SuperAdminCompany from "../pages/SuperAdminAspect/Company/Company";
import Contracts from "../pages/Contracts/Contracts";
import Rfx from "../pages/Rfx/RfxHome";
import RfxDetails from "../pages/Rfx/RfxDetails";
import RfxSellerHome from "../pages/Rfx/RfxSellerView/RfxSellerHome";
import RfxSellerDetails from "../pages/Rfx/RfxSellerView/RfxSellerDetails";
import RfxComapre from "../pages/Rfx/RfxComapre";
import EmailVerificationToast from "../pages/EmailVerificationToast/EmailVerificationToast";

import SuperAdminUsers from "../pages/SuperAdminAspect/AllSuperAdmin/SuperAdmin";
import SuperAdminCreatePassword from "../pages/SuperAdminAspect/AllSuperAdmin/SuperAdminCreatePassword";

function App(props) {
  const { isSeller, isAdmin, isBuyer, currentRole, currentUser } = props;

  const [prefix, setPrefix] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      dispatch(
        changeCurrentSelectedRole(
          supplierRoutes(location, currentRole, isAdmin, isSeller, isBuyer)
        )
      );
      setPrefix(currentRole === "supplier" ? "/supplier" : "");
    }
  }, [currentUser]);

  useEffect(() => {
    checkAutoLogin(dispatch, props.history);
    if (currentUser) {
      dispatch(
        changeCurrentSelectedRole(
          supplierRoutes(location, currentRole, isAdmin, isSeller, isBuyer)
        )
      );
      setPrefix(currentRole === "supplier" ? "/supplier" : "");
    }
    setPrefix(currentRole === "supplier" ? "/supplier" : "");
  }, []);

  let routes = (
    <Switch>
      <Route
        path="/supplierInvitation/:supplierId/:status"
        component={SupplierInvitation}
        exact
      />
      <Route path="/signin" component={SignIn} exact />
      <Route path="/signup" component={Signup} exact />
      <Route path="/forgotpassword" component={ForgotPassword} exact />
      <Route path="/resetpassword/:token" component={ResetNewPassword} exact />
      <Route path="/success" component={Success} exact />
      <Route path="/verifyToast" component={EmailVerificationToast} exact />
      <Route path="/verifyEmail?:token" component={verifyEmail} exact />
      <Route path="/error/403" component={ErrorPageComponent} exact />
      <Route path="/" component={SignIn} exact />
      <Route path="/setPassword" component={SuperAdminCreatePassword} />
    </Switch>
  );

  if (props.isAuthenticated) {
    routes = currentUser?.isSuperAdmin ? (
      <AuthGaurd>
        <Socket>
          <Switch>
            <Route path="/admin/company" component={SuperAdminCompany} exact />
            <Route
              path="/admin/user-listing"
              component={SuperAdminUsers}
              exact
            />
            <Redirect
              to={
                currentRole === "super_admin"
                  ? "/admin/company"
                  : currentRole === "buyer"
                  ? "/dashboard"
                  : prefix + "/auction"
              }
            />
          </Switch>
        </Socket>
      </AuthGaurd>
    ) : (
      <AuthGaurd>
        <Socket>
          <Switch>
            <Route path="/dashboard" component={Dashboard} exact />
            <Route path="/projects" component={Projects} exact />
            {/* it would be better if id of auction included in url (following standard REST api) */}
            {/* means URL ==> auction/{id}/details */}
            <Route
              path="/auction/:id/details/live"
              component={LiveAuction}
              exact
            />
            <Route
              path="/rfx/:id/details/compare"
              component={RfxComapre}
              exact
            />
            <Route
              path="/auction/:id/details"
              component={AuctionDetails}
              exact
            />
            <Route path="/rfx/:id/details" component={RfxDetails} exact />
            <Route path="/project/details" component={ProjectDetails} exact />
            <Route path="/auction" component={Auction} exact />
            <Route path="/suppliers" component={Suppliers} exact />
            <Route path="/rfx" component={Rfx} exact />
            <Route path="/contracts" component={Contracts} exact />
            <Route path="/items" component={Items} exact />
            <Route path="/settings" component={Settings} exact />
            <Route path="/companyusers" component={CompayUsers} exact />

            <Route
              path="/supplier/auction/:id/details"
              component={SupplierAuctionDetails}
              exact
            />
            <Route
              path="/supplier/rfx/:id/details"
              component={RfxSellerDetails}
              exact
            />

            <Route path="/supplier/auction" component={SupplierAuction} exact />
            <Route path="/supplier/rfx" component={RfxSellerHome} exact />
            {/* <Route path="/supplier/dashboard" component={SupplierDashboard} /> */}
            <Route
              path="/supplier/settings"
              component={SupplierSetting}
              exact
            />
            <Route
              path="/supplier/companyusers"
              component={SupplierCompanyUsers}
              exact
            />
            {/* suppliers routes */}

            {/* Errors Routes */}
            <Route path="/error/403" component={ErrorPageComponent} />
            <Route path="/error/500" component={ErrorPageComponent} />
            {/* Errors Routes */}

            <Redirect
              to={
                currentRole === "super_admin"
                  ? "/admin/company"
                  : currentRole === "buyer"
                  ? "/dashboard"
                  : prefix + "/auction"
              }
            />
          </Switch>
        </Socket>
      </AuthGaurd>
    );
  }
  return (
    <div>
      <Suspense fallback={<div> Loading... </div>}>{routes}</Suspense>
    </div>
  );
}

const supplierRoutes = (location, currentRole, isAdmin, isSeller, isBuyer) => {
  if (currentRole === "super_admin") return "super_admin";
  // const UIRs = location.pathname.split("/");
  const urlCheckSupplier = location.pathname.includes("/supplier/");

  if (isSeller === true && isBuyer === true) {
    return urlCheckSupplier ? "supplier" : "buyer";
  } else if (isSeller === true) {
    return "supplier";
  } else {
    return "buyer";
  }
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
