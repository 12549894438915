import { Grid, Box, Tabs, Tab, Typography, Paper } from "@material-ui/core";
import { useState } from "react";
import RfxSellerGeneralInformationTab from "./RfxSellerGeneralInformationTab";
import RfxSellerQuestionTab from "./RfxSellerQuestionTab";
import RfxMessagesTab from "../Components/RfxDetailsComponent/RfxMessagesTab";
import { useSelector } from "react-redux";

const TabWithCount = ({ children, count }) => {
  return (
    <Box className="rfx-tab">
      <Typography className="tab-title" component="div">
        {children}
      </Typography>
      {count >= 0 ? (
        <Typography
          component="div"
          variant="body2"
          className="message-count"
          sx={{ marginLeft: "0.5rem" }}
        >
          {count}
        </Typography>
      ) : null}
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-label={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const RfxSellerTabContainer = ({ getRfxDetails }) => {
  const [value, setValue] = useState(0);
  const GNInfoTabIX = 0;
  const QuestionTABIX = 1;
  const rfxSupplierDetails = useSelector((state) => state.supplierRfx.rfx);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid container className="tab-header-container mt-0">
        <Box
          sx={{
            borderRadius: "25px !important",
          }}
        >
          <Tabs
            className="tab-header"
            id="auction-tab-list"
            value={value}
            onChange={handleChange}
          >
            <Tab label="General Information" />
            <Tab label="Questions" />{" "}
            <Tab
              label={
                <TabWithCount
                  count={rfxSupplierDetails?.openMessagesCount || 0}
                >
                  Messages
                </TabWithCount>
              }
            />
          </Tabs>
        </Box>
      </Grid>
      <Paper
        className={`tab-body-container
         ${
           value === GNInfoTabIX || value === QuestionTABIX
             ? "__clear-page"
             : ""
         }
        `}
      >
        <TabPanel value={value} index={0} labelAria="no-padding">
          <RfxSellerGeneralInformationTab getRfxDetails={getRfxDetails} />
        </TabPanel>

        <TabPanel value={value} index={1} labelAria="no-padding">
          <RfxSellerQuestionTab />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <RfxMessagesTab fromSeller={true} />
        </TabPanel>
      </Paper>
    </>
  );
};

export default RfxSellerTabContainer;
