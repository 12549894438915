import { useState } from "react";
import { useForm, Form } from "../../components/useForm";
import Controls from "../../components/controls/Controls";
import { makeStyles, Grid, Box, TextField } from "@material-ui/core";
import { store } from "../../store/store";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Api } from "../../api/Api";
import { PATCH_CHANGE_PASSWORD_API } from "../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const initialFValues = {
  old_password: "",
  new_password: "",
  cpassword: "",
};
const useStyles = makeStyles((theme) => ({
  errMsg: {
    color: "red",
    fontSize: "14px",
    margin: "3px 0",
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    padding: ".5rem 2rem",
    fontWeight: "700",
  },
  input: {
    display: "none",
  },
  Button: {
    padding: ".4rem 3rem",
    borderRadius: "6px",
    fontWeight: "700",
    fontSize: "14px",
  },

  modalContent: {
    width: "100%",
  },
}));
const ChangePassword = (props) => {
  const { setChangePasswordPopup, changePasswordPopup, setNotify } = props;
  const classes = useStyles();
  const [pass, setPass] = useState("");
  const [password, setPassword] = useState({
    password: "",
    showPassword: false,
  });
  const [newPassword, setNewPassword] = useState({
    Newpassword: "",
    showNewPassword: false,
  });
  const [confirmnewPassword, setConfirmNewPassword] = useState({
    ConfirmNewpassword: "",
    showConfirmNewPassword: false,
  });
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("old_password" in fieldValues)
      temp.old_password = fieldValues.old_password
        ? ""
        : "This field is required.";

    if ("new_password" in fieldValues) {
      if (fieldValues.new_password !== "") {
        if (fieldValues.new_password.length < 8) {
          temp.new_password = "Password  must be 8 character";
        } else {
          if (!/[0-9]/.test(fieldValues.new_password)) {
            temp.new_password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.new_password)) {
              temp.new_password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.new_password)) {
                temp.new_password = "Password  must be 1 Uppercase letter";
              } else {
                if (
                  !/[!@#$*%&_^{}[\]/|\\()?-]/.test(fieldValues.new_password)
                ) {
                  temp.new_password = "Password  must be 1 special character";
                } else {
                  setPass(fieldValues.new_password);
                  temp.new_password = "";
                }
              }
            }
          }
        }
      } else {
        temp.new_password = "This field is required.";
      }
    }

    if ("cpassword" in fieldValues) {
      if (fieldValues.cpassword !== "") {
        if (String(fieldValues.cpassword) !== String(pass)) {
          temp.cpassword = "Password not matched.";
        } else {
          temp.cpassword = "";
        }
      } else {
        temp.cpassword = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };
  const handleClickShowNewPassword = () => {
    setNewPassword({ ...newPassword, Newpassword: !newPassword.Newpassword });
  };
  const handleClickShowConfirmNewPassword = () => {
    setConfirmNewPassword({
      ...confirmnewPassword,
      ConfirmNewpassword: !confirmnewPassword.ConfirmNewpassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmNewPassword = (event) => {
    event.preventDefault();
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const { auth } = store.getState();
      const userId = auth?.auth?.user?._id;
      if (values.new_password !== values.cpassword) {
        toast.error(ValidationMessage.somethingWrong, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      } else {
        const payload = {
          new_password: values.new_password,
          old_password: values.old_password,
        };
        const response = await Api(
          `${PATCH_CHANGE_PASSWORD_API}/${userId}`,
          payload,
          "patch"
        );
        if (response.status === 200) {
          if (response.data.responseCode === "00") {
            setChangePasswordPopup(false);
          }
        }

        // axiosInstance
        //   .patch(`api/users/changePassword/${userId}`, payload)
        //   .then((response) => {
        //     if (response.data.responseCode === "00") {
        //       setChangePasswordPopup(false);
        //       setNotify({
        //         isOpen: true,
        //         message: "Password changed successfully",
        //         type: "success",
        //       });
        //     } else {
        //       setNotify({
        //         isOpen: true,
        //         message: response?.data?.responseDescription,
        //         type: "error",
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     setNotify({
        //       isOpen: true,
        //       message: err?.response?.data?.responseDescription,
        //       type: "error",
        //     });
        //   });
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            {/* <Controls.Input
                     name="old_password"
                     label="Old Password"
                     fullWidth
                     value={values.old_password}
                     onChange={handleInputChange}
                     error={errors.old_password}
                     className="StepControlInput"
                     type="password"
                  /> */}
            <TextField
              className="mui-input"
              variant="outlined"
              id="old_password"
              name="old_password"
              autoComplete="current-old_password"
              fullWidth
              value={values.old_password}
              onChange={handleInputChange}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder="Old Password"
              type={password.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password.showPassword ? (
                        <Visibility color="primary" fontSize="small" />
                      ) : (
                        <VisibilityOff color="primary" fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.old_password && (
              <div className="error-msg">{errors.old_password}</div>
            )}
          </Grid>
          <Grid item xs={12}>
            {/* <Controls.Input
                     name="new_password"
                     label="New Password"
                     fullWidth
                     value={values.new_password}
                     onChange={handleInputChange}
                     error={errors.new_password}
                     className="StepControlInput"
                     type="password"
                  /> */}
            <TextField
              className="mui-input"
              variant="outlined"
              id="new_password"
              name="new_password"
              autoComplete="current-password"
              fullWidth
              value={values.new_password}
              onChange={handleInputChange}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder="New Password"
              type={newPassword.Newpassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownNewPassword}
                    >
                      {newPassword.Newpassword ? (
                        <Visibility color="primary" fontSize="small" />
                      ) : (
                        <VisibilityOff color="primary" fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.new_password && (
              <div className="error-msg">{errors.new_password}</div>
            )}
          </Grid>
          <Grid item xs={12}>
            {/* <Controls.Input
                     name="cpassword"
                     label="Confirm New Password"
                     fullWidth
                     value={values.cpassword}
                     onChange={handleInputChange}
                     error={errors.cpassword}
                     className="StepControlInput"
                     type="password"
                  /> */}
            <TextField
              className="mui-input"
              variant="outlined"
              id="cpassword"
              name="cpassword"
              autoComplete="current-password"
              fullWidth
              value={values.cpassword}
              onChange={handleInputChange}
              inputProps={{ maxLength: 40 }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder="Confirm Password"
              type={confirmnewPassword.ConfirmNewpassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmNewPassword}
                      onMouseDown={handleMouseDownConfirmNewPassword}
                    >
                      {confirmnewPassword.ConfirmNewpassword ? (
                        <Visibility color="primary" fontSize="small" />
                      ) : (
                        <VisibilityOff color="primary" fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.cpassword && (
              <div className="error-msg">{errors.cpassword}</div>
            )}
          </Grid>
        </Grid>
        <Box
          className="BottomModal"
          display="flex"
          p={1}
          bgcolor="background.paper"
        >
          <Grid istem xs={4} className="BottomModal-left">
            <Box p={0} flexGrow={1}>
              <Controls.Button
                text="Cancel"
                disableElevation
                onClick={() => {
                  setChangePasswordPopup(false);
                }}
                style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
                color="default"
                className={classes.Button}
              />
            </Box>
          </Grid>
          <Grid item xs={8} className="BottomModal-right">
            <Box p={0}>
              <Controls.Button
                type="submit"
                disableElevation
                text="Save Change"
                className={classes.Button}
              />
            </Box>
          </Grid>
        </Box>
      </Form>
    </>
  );
};

export default ChangePassword;
