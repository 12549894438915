//AUTHENTICATION API
export const LOGIN_API = "/api/auth";
export const VERIFY_EMAIL_API = "/verifyEmail";
export const EMAIL_VERIFY_TOST_API = "/api/users/verifyEmail";
export const VERIFY_FORGOT_PASSWORD_API = "/api/users/verifyForgetPassword";
export const RESEND_CONFIRMATION_API = "/api/users/ResendConfirmationCode";
export const PATCH_CHANGE_PASSWORD_API = "/api/users/changePassword";
export const PATCH_USER_PASSWORD_CONFIRM_API = "/api/users/passwordConfirm";

//SUPER ADMIN
export const ONLY_SUPER_ADMIN_API = "/api/users/admins";
export const CREATE_SUPER_ADMIN_PASSWORD = "/api/users/admin/setPassword";
export const SUPER_ADMIN_PASSWORD_CHECK_INVITE = "/api/users/checkInvite";

//USER
export const GET_USER_API = "/api/users";
export const GET_ALL_USER_API = "/api/users/getAllUsers";
export const DELETE_PROJECT_USER_API = "/api/buyer/deleteProjectUser";

//AUCTION API
export const AUCTION_LIST_API = "/api/buyer/auctionList";
export const AUCTION_SAVE_API = "/api/buyer/company/addAuction";
export const DASHBOARD_STATISTICS_API = "/api/buyer/dashboard/statistics";
export const GET_ALL_BID_API = "/api/buyer/getAllBidByAuction";
export const GET_ALL_BID_BY_RANK_API = "/api/buyer/getAllBidByAuctionRank";
export const GET_COMPANY_AUCTION_API = "/api/buyer/company/auctions";
export const POST_COMPANY_AUCTION_API = "/api/buyer/company/auction";
export const GET_LIVE_BID_GRAPH_API = "/api/buyer/liveBidGraph";
export const GET_AUCTION_ATTACHMENT_API =
  "/api/buyer/company/auctionsAttachment";
export const GET_AUCTION_COMMENT_API = "/api/buyer//company/auction";
export const POST_AUCTION_COMPANY_SUPPLIER_EDIT_API =
  "/api/buyer/company/auctionCompanySupplierEdit";
export const POST_AUCTION_STATUS_CHANGE_API =
  "/api/buyer/changeAuctionStatusDraftToPublish";
export const POST_AUCTION_WINNER_API = "/api/seller/supplierWinner";
export const POST_AUCTION_ADD_ATTACHMENT_API =
  "/api/buyer/company/auctions/addAttachment";
export const POST_AUCTION_EDIT_ATTACHMENT_API =
  "/api/buyer/company/auctions/editAttachment";
export const POST_AUCTION_EDIT_SUPPLIER_API =
  "/api/buyer/company/auctionEditSupplierInvite";
export const POST_AUCTION_ADD_LINK_API =
  "/api/buyer/company/auctions/addAttachmentLink";
export const POST_AUCTION_EDIT_LINK_API =
  "/api/buyer/company/auctions/editAttachment";
export const POST_AUCTION_ADD_LIBRARY_API =
  "/api/buyer/company/auctions/addLibrary";
export const POST_AUCTION_DRAFT_API = "/api/buyer/suppier/saveAuctionAsDraft";
export const DELETE_AUCTION_ATTACHMENT_API =
  "/api/buyer/company/auctions/removeAttachment";
export const DUPLICATE_AUCTION_API = "/api/buyer/company/duplicateAuction";

//SELLER
export const GET_SELLER_AUCTION_API = "/api/seller/auctions/supplier";
export const GET_SELLER_AUCTION_CLIENT_LIST_API =
  "/api/seller/auctions/supplierClientList";
export const GET_SELLER_AUCTION_ITEM_LIST_API =
  "/api/seller/auctions/supplierAuctionItems";
export const GET_SELLER_AUCTION_STATUS_API =
  "/api/seller/auctions/supplierAuctionStatus";
export const GET_SELLER_MY_BID_API = "/api/seller/getMyBid";
export const GET_SELLER_MY_ALL_BID_API = "/api/seller/getAllBidBySupplier";
export const GET_SELLER_AUCTION_DETAIL_API =
  "/api/seller/auctions/supplierAuctionDetails";
export const GET_SELLER_DETAIL_API = "/api/buyer/company/suppliers";
export const GET_SELLER_SUPPLIER_DETAIL_API = "/api/seller/supplierDetails";
export const GET_CONTACT_SUPPLIER_LIST_API = "/api/seller/contactSupplierList";
export const POST_ADD_SUPPLIER_API = "/api/buyer/addNewSupplier";
export const POST_SUPPLIER_INVITATION_STATUS_API =
  "/api/company/supplierInvitationStatus";
export const POST_SUPPLIER_FILE_API = "/api/buyer/uploadSupplierUserCsv";
export const POST_SUPPLIER_SAVE_IMPORT_DATA_API = "/api/buyer/company";

//PROJECT
export const PROJECT_LIST_API = "/api/buyer/getProjectsList";
export const BUYER_PROJECT_LIST_API = "/api/buyer/getAllProjects";
export const GET_COMPANY_PROJECT_API = "/api/buyer/company/project";
export const GET_BUYER_PROJECT_API = "/api/buyer/project";
export const POST_ADD_BUYER_PROJECT_API = "/api/buyer/addProjectUser";
export const GET_BUYER_PROJECT_AUCTION_API =
  "/api/buyer/getProjectsAuctionList";
export const POST_ADD_PROJECT_API = "/api/buyer/addProject/";
export const POST_EDIT_PROJECT_API = "/api/buyer/editProject";
export const POST_EDIT_PROJECT_USER_API = "/api/buyer/editProjectUser";
export const DELETE_PROJECT_API = "/api/buyer/deleteProject";

//ITEM
export const GET_ITEM_API = "/api/buyer/item";
export const GET_ITEM_CATEGORY_API = "/api/buyer/company/getItemsCategory";
export const GET_COMPANY_ITEM_CATEGORY_API =
  "/api/buyer/company/getItemsCatGroups";
export const GET_AUCTION_ITEM_API = "/api/buyer/company/auctions/item/list/";
export const GET_ITEM_TAB_API = "/api/buyer/auctions/AuctionItems";
export const GET_ITEM_DETAILS_API = "/api/buyer/item/itemDetail";
export const POST_ADD_ITEM_API = "/api/buyer/item/addItem";
export const POST_ITEM_UPLOAD_DOCUMENT_API =
  "/api/buyer/item/document/uploadDocument";
export const POST_ITEM_EDIT_UPLOAD_DOCUMENT_API =
  "/api/buyer/item/document/editUploadDocument";
export const POST_ITEM_DRAFT_API = "/api/buyer/item/saveItemAsDraft";
export const POST_EDIT_ITEM_API = "/api/buyer/item/editItem";
export const POST_AUCTION_EDIT_ITEM_API =
  "/api/buyer/company/auctions/editItem";
export const POST_EDIT_ITEM_DRAFT_API = "/api/buyer/item/EditsaveItemDraft";
export const POST_EDIT_PROJECT_DRAFT_API = "/api/buyer/EditsaveProjectDraft";
export const POST_SAVE_PROJECT_DRAFT_API = "/api/buyer/saveProjectAsDraft";
export const DELETE_ITEM_API = "/api/buyer/item/deleteItem";
export const DELETE_UPLOAD_ITEM_API = "/api/buyer/removeUploadItemAttachment";
export const GET_CONTRACT_STATUS_API = "/api/buyer/company/contract/";

//PROFILE
export const GET_COMPANY_PROFILE_API = "/api/company/profile";
export const PATCH_UPDATE_PROFILE_API = "/api/users/updateProfile";
export const PATCH_v1_UPDATE_PROFILE_API = "/api/users/v1/updateProfile";

//ADMIN
export const GET_ADMIN_USER_API = "/api/users/admin";

//CONTRACT
export const POST_ADD_CONTRACT_API = "/api/buyer/company/addContract";

//OTHER
export const GET_COST_CENTER_API = "/api/buyer/company/getCostCenter";
export const GET_COST_BUDGET_API = "/api/buyer/auctionCostBudget";
export const COMAPNY_API = "/api/buyer/company";
export const ONLY_COMAPNY_API = "/api/company";
export const COMAPNY_VERSION_API = "/api/buyer/v1/company";
export const GET_COMPANY_SUPPLIER_LIST_API =
  "/api/buyer/company/auctionCompanySupplierList";
export const GET_SUPPLIER_LIST_API = "/api/company/suppliersList";
export const GET_SUPPLIER_EMAIL_API = "/api/buyer/company/suppliersEmail";
export const GET_COMPANY_TAG_API = "/api/buyer/company/getTag";
export const GET_DOCUMENT_LIST_API = "/api/buyer/item/documentList";
export const GET_BUISNESS_UNIT_API = "/api/company/getBusinessUnits";
export const GET_SUPPLIER_CATEGORY_API =
  "/api/buyer/company/getSupplierCategories";
export const GET_SUPPLIER_COMPANY_ATTACHMENT_API =
  "/api/company/supplierCompanyAttachment";
export const GET_COMPANY_ATTACHMENT_API = "/api/company/companyAttachment";
export const GET_BUYER_API = "/api/buyer";
export const POST_BUYER_REMINDER_API = "/api/buyer/sendReminder";
export const POST_UPLOAD_SUPPLIER_API = "/api/buyer/uploadSupplierCsv";
export const POST_COMPANY_ATTACHMENT_API = "/api/buyer/company/editAttachment";
export const POST_COMPANY_BUISNESS_API = "/api/company/addBusinessUnit";
export const POST_COMPANY_COST_CENTER_API = "/api/buyer/company/addCostCenter";
export const POST_COMPANY_ADD_ITEM_API = "/api/buyer/company/addItemsCatGroup";
export const POST_ADD_LIBRARY_API = "/api/buyer/library/addDoc";
export const POST_ADD_SUPPLIER_CATEGORY_API =
  "/api/buyer/company/addSupplierCategory";
export const POST_ADD_TAG_API = "/api/buyer/company/addTag";
export const POST_EDIT_ATTACHMENT_API = "/api/company/editCompanyAttachment";
export const POST_ADD_COONTACT_SUPPLIER_API = "/api/seller/addContactSupplier";
export const POST_EDIT_BUDGET_API = "/api/buyer/company/editBudget";
export const PATCH_EDIT_BUSINESS_UNIT_API = "/api/company/editBusinessUnit";
export const PATCH_EDIT_COMPANY_API = "/api/company/editCompany";
export const PATCH_EDIT_COST_CENTER_API = "/api/buyer/company/editCostCenter";
export const PATCH_EDIT_ITEMS_CATEGORY_API =
  "/api/buyer/company/editItemsCatGroup";
export const PATCH_LIBRARY_EDIT_DOC_API = "/api/buyer/library/editDoc";
export const PATCH_EDIT_SUPPLIER_CATEGORY_API =
  "/api/buyer/company/editSupplierCategory";
export const PATCH_EDIT_TAG_API = "/api/buyer/company/editTag";
export const PATCH_EDIT_SUPPLIER_API = "/api/seller/supplierEdit";
export const PATCH_EDIT_CONTACT_SUPPLIER_API =
  "/api/seller/editContactSupplier";
export const DELETE_COMPANY_ATTACHMENT_API =
  "/api/buyer/company/deleteAttachment";
export const DELETE_BUDGET_API = "/api/buyer/deleteBudget";
export const DELETE_BUSINESST_API = "/api/company/deleteBusinessUnit";
export const DELETE_COST_CENTER_API = "/api/buyer/company/deleteCostCenter";
export const DELETE_ITEM_CATEGORY_API =
  "/api/buyer/company/deleteItemsCatGroup";
export const DELETE_LIBRARY_DOC_API = "/api/buyer/library/deleteDoc";
export const DELETE_SUPPLIER_CATEGORY_API =
  "/api/buyer/company/deleteSupplierCategory";
export const DELETE_TAG_API = "/api/buyer/company/deleteTag";
export const DELETE_ATTACHMENT_API = "/api/company/deleteCompanyAttachment";
export const DELETE_SUPPLIER_API =
  "/api/buyer/company/suppliers/deleteSupplier";
export const DELETE_CONTACT_SUPPLIER_API = "/api/seller/deleteContactSupplier";
export const POST_COMPANY_ADD_TAG_API = "/api/buyer/company/addTag";
export const GET_INVITATION_STATUS = "/api/buyer/v1/supplierStatus";

//RFX
export const RFX_SAVE_API = "/api/buyer/company/addRfx";
export const POST_RFX_DRAFT_API = "/api/buyer/supplier/saveRfxAsDraft";
export const RFX_LIST_API = "/api/buyer/rfxList";
export const RFX_DETAILS_API = "/api/buyer/company/rfx";
export const RFX_API = "/api/buyer/rfx";
export const RFX_GET_ITEM_TAB_API = "/api/buyer/rfx";
export const GET_RFX_ATTACHMENT_API = "/api/buyer/company/rfxAttachment";
export const GET_RFX_COMMENT_API = "/api/buyer/company/rfx";
export const POST_ADD_ATTACH_LINK_RFX_API =
  "/api/buyer/company/rfx/addAttachmentLink";
export const POST_EDIT_ATTACH_LINK_RFX_API =
  "/api/buyer/company/rfx/editAttachment";
export const POST_ADD_ATTACH_RFX_API = "/api/buyer/company/rfx/addAttachment";
export const POST_EDIT_ATTACH_RFX_API = "/api/buyer/company/rfx/editAttachment";
export const POST_ADD_LIBRARY_RFX_API = "/api/buyer/company/rfx/addLibrary";
export const REMOVE_ATTACH_RFX_API = "/api/buyer/company/rfx/removeAttachment";
export const POST_RFX_EDIT_ITEM_API = "/api/buyer/company/rfx/editItem";
export const DELETE_ITEM_RFX = "/api/buyer/company/rfx/deleteItem";
export const SEND_REMINDER_RFX = "/api/buyer/sendReminder/rfx";
export const PATCH_EDIT_QUESTION_API = "/api/buyer/company/rfx/editquestion";
export const DELETE_QUESTION_API = "/api/buyer/company/rfx/deleteQuestion";
export const GET_SELLER_RFX_API = "/api/seller/rfx/supplier";
export const GET_SELLER_RFX_DETAILS_API = "/api/seller/rfx/supplierRfxDetails";
export const POST_RFX_STATUS_CHANGE_API =
  "/api/buyer/changeRfxStatusDraftToActive";
export const POST_RFX_INVITATION_STATUS_API =
  "/api/company/supplierRfxInvitationStatus";
export const POST_CREATE_MESSAGE_API = "/api/seller/rfx/message/create";
export const SELLER_RFX_API = "/api/seller/rfx";
export const POST_SELLER_CHAT_API = "/api/seller/rfx/conversation/create";
export const POST_BUYER_CHAT_API = "/api/buyer/rfx/conversation/create";
export const POST_SELLER_RFX_QUESTION_API = "/api/seller/rfx/questionDetails";
export const POST_SELLER_RFX_SEND_ANSWER_API =
  "/api/seller/rfx/question/sendAnswer";
export const GET_SELLER_ITEM_API = "/api/seller/rfx/itemsDetails";
export const SEND_BID_ITEM_API = "/api/seller/rfx/item/sendBid";
export const SEND_LUMPSUM_BID_API = "/api/seller/rfx/bid/lumpsum";
export const POST_BID_ATTACHMENT_UPLOAD_API =
  "/api/seller/rfx/attachment/upload";
export const DELETE_BID_ATTACHMENT_REMOVE_API =
  "/api/seller/rfx/attachment/remove";
export const POST_FINAL_BID_API = "/api/seller/rfx/bid/finalSubmit/send";
export const POST_WIN_RFX = "/api/buyer/rfx/supplierWinner";
export const POST_RFX_MESSAGE_CHANGE_API =
  "/api/buyer/rfx/message/status/change";
export const POST_RFX_ADD_SUPPLIER_API = "/api/buyer/rfx/addNewSupplier";
export const POST_RFX_ADD_QUESTION_API = "/api/buyer/company/rfx/addquestion";
export const POST_RFX_ADD_ITEM_API = "/api/buyer/company/rfx/additems";
export const POST_CREATE_SUPPORT_API = "/api/users/support";
export const DELETE_AUCTION_ITEM_API = "/api/buyer/company/auctions/removeItem";
