/*eslint-disable */
import { useState, useEffect, useRef } from "react";
import { useForm, Form } from "../../components/useForm";
// import FormSteps from "../../components/FormStepAuction";
import FormSteps from "../../components/Formsteps";
import { Box, Grid, Paper } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Controls from "../../components/controls/Controls";
import useWindowDimensions from "../../components/useWindowDimensions";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import UploadIcon from "@mui/icons-material/Upload";
import Search from "@material-ui/icons/Search";
import Autocomplete from "@mui/material/Autocomplete";
import { store } from "../../store/store";
import axiosInstance from "../../services/AxiosInstance";
import ImportItemsPopup from "./components/ImportItems";
import { useDispatch } from "react-redux";
import {
  FormStep4Add,
  auctionSaveAsDraft,
} from "../../store/actions/auctionAction";
import ConfirmationDailog from "./components/confirmationDailog";
import "./styles/create-auction-step4.css";
import { Api } from "../../api/Api";
import {
  GET_AUCTION_ITEM_API,
  GET_ITEM_CATEGORY_API,
} from "../../constants/ApiUrl";
import { toast, Slide } from "react-toastify";
import { ValidationMessage } from "../../constants/ValidationMessage";
import { TOAST_AUTO_CLOSE } from "../../constants/AppConstants";

const initialValues = {
  itemName: "",
  category: "",
  unit: "",
  quantity: "",
};

const columns = [
  {
    label: "Item Name",
  },
  {
    label: "Category",
  },
  {
    label: "Unit",
  },
  {
    label: "Qty",
  },
  {
    label: "",
  },
];

const Step4Form = (props) => {
  const {
    setOpenPopupStep3,
    setOpenPopupStep4,
    setOpenPopupStep5,
    stepLabels,
    setNotify,
    setResendApi,
  } = props;
  const dispatch = useDispatch();
  const tableRowsRef = useRef([]);
  const [allItemList, setAllItemList] = useState([]);
  const [itemForTable, setItemForTable] = useState([]);
  const [openConfrmationDailog, setOpenConfrmationDailog] = useState(false);
  const [importItemsPopup, setImportItemsPopup] = useState(false);
  const [items, setItems] = useState([]);

  const [dropdownCategory, setDropdownCategory] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);

  const changeHandler1 = (e, index) => {
    let newArr = [...items]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value;
    setItems(newArr);
  };

  const { xlScreen, lgScreen, mdScreen, smScreen, xsScreen } =
    useWindowDimensions();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("itemName" in fieldValues)
      temp.itemName = fieldValues.itemName ? "" : "This field is required.";
    if ("category" in fieldValues)
      temp.category = fieldValues.category ? "" : "This field is required.";
    if ("unit" in fieldValues)
      temp.unit = fieldValues.unit ? "" : "This field is required.";
    if ("quantity" in fieldValues)
      temp.quantity = fieldValues.quantity ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const getCategoryName = (category) => {
    const CateItem = dropdownCategory.find(
      (element) => element._id === category
    );
    return CateItem && CateItem.name ? CateItem.name : "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const sameItem = allItemList.find(
        (item) => item.label.trim() === values.itemName.trim()
      );
      const newItem = items.find(
        (item) => item.itemName.trim() === values.itemName.trim()
      );
      if ((sameItem && sameItem.id) || (newItem && newItem.itemName)) {
        toast.error(ValidationMessage.itemExist, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
      const uniqId = Math.floor(100000 + Math.random() * 900000);
      values.category = values.category;
      values.itemId = "R" + uniqId;
      values.itemName = values.itemName;
      items.push(values);
      setItems(items);
      resetForm();
      let newItems = [];

      items.map((it) => {
        let tmp = {
          itemName: it.itemName,
          category: it.category,
          isSelectedCategory: !!it.category,
          unit: it.unit,
          quantity: it.quantity,
          itemId: it.itemId,
        };
        newItems.push(tmp);
      });
      dispatch(FormStep4Add({ Items: newItems }));
    }
  };

  const clickOnNext = () => {
    if (items && items.length > 0) {
      let qtyCheck = false;
      items.map((item) => {
        if (item.quantity == "") {
          qtyCheck = true;
          return item;
        }
      });
      if (!qtyCheck) {
        dispatch(FormStep4Add({ Items: items }));
        setOpenPopupStep4(false);
        setOpenPopupStep5(true);
      } else {
        toast.error(ValidationMessage.itemQuantity, {
          transition: Slide,
          autoClose: TOAST_AUTO_CLOSE,
        });
      }
    } else {
      toast.error(ValidationMessage.addItem, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const saveAsDraft = async () => {
    if (items && items.length > 0) {
      dispatch(FormStep4Add({ Items: items }));

      let res = await dispatch(auctionSaveAsDraft(values));

      if (res?.data?.responseCode === "00") {
        if (setResendApi) {
          setResendApi(true);
        }
        setOpenPopupStep4(false);
      } else {
        setOpenPopupStep4(false);
      }
    }
  };

  const toggleEditableRowMode = (ref) => {
    ref.classList.toggle("__select");
    // toggle option list
    const optionBoxes = ref.querySelectorAll(".option-cell");
    for (let index = 0; index < optionBoxes.length; index++) {
      const box = optionBoxes[index];
      box.classList.toggle("__active");
    }
    // toggle editable field
    const fieldBoxes = ref.querySelectorAll(".editable-field");
    for (let index = 0; index < fieldBoxes.length; index++) {
      const field = fieldBoxes[index];
      field.classList.toggle("__active");
    }
  };

  const getAllItem = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    const response = await Api(
      `${GET_AUCTION_ITEM_API}/${companyId}`,
      {},
      "get"
    );
    if (response.status === 200) {
      if (response.data.responseCode === "00") {
        let allItems = response.data.items;
        let newItemArr = [];
        allItems.map((item) => {
          let temp = {
            category: item.category,
            label: item.item_name,
            unit: item.unit,
            id: item._id,
            itemId: item.itemId,
          };
          newItemArr.push(temp);
        });
        setAllItemList(newItemArr);
      } else {
        setAllItemList([]);
      }
    } else {
      setAllItemList([]);
    }
  };

  const getItemCategory = async () => {
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    if (companyId) {
      const response = await Api(
        `${GET_ITEM_CATEGORY_API}/${companyId}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let CatAndGroups = response.data.itemsCatAndGroups;
          setDropdownCategory(CatAndGroups);
        }
      }
    }
  };

  const getSelectedItem = (data) => {
    if (data) {
      const alreadyAddedItem = items.find((obj) => obj?.id === data?.id);
      if (alreadyAddedItem && alreadyAddedItem.id) return;
      const rendomNum = Math.floor(100000 + Math.random() * 900000);
      const selectedItem = {
        category: data?.category,
        isSelectedCategory: !!data?.category,
        itemId: data.itemId,
        id: data?.id,
        itemName: data.label,
        unit: data.unit,
        quantity: "",
      };

      items.push(selectedItem);
      const neItems = [...items];
      setItems(neItems);
      itemForTable.push(items);
      setItemForTable(itemForTable);
      dispatch(FormStep4Add({ Items: neItems }));
    }
  };

  const removeSelectIndex = (id) => {
    // const removeIndex = items.splice(index, 1);
    const filterData = items.filter((obj) => obj.itemId !== id);

    const neItems = [...filterData];
    setItems(neItems);
  };

  const pressBack = () => {
    dispatch(FormStep4Add({ Items: items }));
    setOpenPopupStep4(false);
    setOpenPopupStep3(true);
  };

  useEffect(() => {
    getAllItem();
    getItemCategory();
    const { auction } = store.getState();
    if (auction?.AuctionForm1?.Items) {
      setItems(auction?.AuctionForm1?.Items);
    }
  }, []);

  const getImportBoxSize = (dir = "left") => {
    if (dir === "left") {
      if (xlScreen) {
        return "w83-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w60-percent-box";
      } else if (mdScreen) {
        return "w73-percent-box";
      } else if (lgScreen) {
        return "w79-percent-box";
      }
    } else {
      if (xlScreen) {
        return "w16-percent-box";
      } else if (xsScreen) {
        return "w100-percent-box";
      } else if (smScreen) {
        return "w40-percent-box";
      } else if (mdScreen) {
        return "w26-percent-box";
      } else if (lgScreen) {
        return "w20-percent-box";
      }
    }
  };

  const discardChanges = () => {
    setOpenConfrmationDailog(true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <p className="profile-title"> Create English Auction</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <CloseIcon
            onClick={() => discardChanges()}
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <div className="create-form-container">
        <Grid container justifyContent="center">
          <Grid item md={11} xs={12}>
            <FormSteps steps={stepLabels} currStep={4} className="mt-5 mb-5" />
          </Grid>
        </Grid>
        <Paper elevation={0} square>
          {/* import item Section  */}
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid className={getImportBoxSize("left")}>
              <Autocomplete
                disablePortal
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                labelId="category-input"
                options={allItemList}
                openOnFocus={true}
                onChange={(event, value) => getSelectedItem(value)}
                getOptionLabel={(option) => (option ? option.label : "")}
                renderInput={(params) => (
                  <Controls.Input fullWidth {...params} />
                )}
                popupIcon={<Search />}
              />
            </Grid>
            <Grid className={getImportBoxSize("right")}>
              <Controls.Button
                component="span"
                text="Import Items"
                onClick={() => setImportItemsPopup(true)}
                disableElevation
                color="default"
                startIcon={<UploadIcon />}
                className="btn-mui btn-white auto-complete-btn"
              />
            </Grid>
          </Grid>
          {/* import item Section  */}
          <TableContainer
            className="table-container editable-table"
            style={{ position: "relative" }}
            component={Paper}
          >
            {/* Dynamic item form  */}
            <Form onSubmit={handleSubmit}>
              <div id="dynamic-item-form-container">
                <TableCell>
                  <Controls.Input
                    name="itemName"
                    fullWidth
                    value={values.itemName}
                    error={errors.itemName}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell>
                  <Controls.Select
                    name="category"
                    labelId="category-input"
                    value={values.category}
                    fullWidth
                    onChange={handleInputChange}
                    options={dropdownCategory}
                    error={errors.category}
                  />
                </TableCell>
                <TableCell>
                  <Controls.Input
                    name="unit"
                    labelId="starting_price-input"
                    fullWidth
                    value={values.unit}
                    error={errors.unit}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell>
                  <Controls.Input
                    name="quantity"
                    labelId="starting_price-input"
                    fullWidth
                    value={values.quantity}
                    error={errors.quantity}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell className="text-center">
                  <Controls.Button
                    type="submit"
                    disableElevation
                    className="btn-mui add-btn"
                    endIcon={<AddIcon className="add-icon m-0" />}
                  />
                </TableCell>
              </div>
            </Form>
            {/* Dynamic item form  */}

            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow
                    key={item._id}
                    ref={(el) => (tableRowsRef.current[index] = el)}
                    className="editable-row"
                  >
                    <TableCell>{item.itemName}</TableCell>
                    <TableCell>
                      <Box className="editable-field ">
                        {getCategoryName(item.category)}
                      </Box>
                      <Box className="editable-field __active">
                        <Controls.Select
                          fullWidth
                          options={dropdownCategory}
                          name="category"
                          value={item.category || ""}
                          onChange={(event) => changeHandler1(event, index)}
                          disabled={!!item.isSelectedCategory}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className="clr-2170ff">
                      <Box className="editable-field ">{item.unit}</Box>
                      <Box className="editable-field __active">
                        <TextField
                          fullWidth
                          name="unit"
                          value={item.unit || ""}
                          onChange={(event) => changeHandler1(event, index)}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="editable-field ">{item.quantity}</Box>
                      <Box className="editable-field __active">
                        <TextField
                          fullWidth
                          name="quantity"
                          value={item.quantity || ""}
                          onChange={(event) => changeHandler1(event, index)}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="option-cell ">
                        <EditIcon
                          className="icon __edit"
                          onClick={() =>
                            toggleEditableRowMode(tableRowsRef.current[index])
                          }
                        />
                        <DeleteOutlineIcon
                          className="icon __edit clr-ff0000"
                          onClick={() => removeSelectIndex(item.itemId)}
                        />
                      </Box>
                      <Box className="option-cell __active">
                        <CheckIcon
                          className="icon __check"
                          onClick={() =>
                            toggleEditableRowMode(tableRowsRef.current[index])
                          }
                        />
                        <CloseIcon
                          className="icon __close"
                          onClick={() =>
                            toggleEditableRowMode(tableRowsRef.current[index])
                          }
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* <Notification notify={notify} setNotify={setNotify} /> */}

        <Box
          className="BottomModal"
          display="flex"
          p={1}
          bgcolor="background.paper"
          style={{ marginTop: "35px" }}
        >
          <Grid istem xs={4} className="BottomModal-left">
            <Box p={0} flexGrow={1}>
              <Controls.Button
                text="Back"
                disableElevation
                onClick={() => {
                  pressBack();
                }}
                className="btn-mui fix-w-12em"
                color="default"
                style={{ backgroundColor: "#E2F2FF", color: "#2170FF" }}
              />
            </Box>
          </Grid>

          <Grid item xs={8} className="BottomModal-right">
            <Box p={0}>
              <Controls.Button
                text="Save as Draft"
                // type="submit"
                disableElevation
                color="default"
                style={{ margin: "0 20px 0 0" }}
                onClick={() => saveAsDraft()}
                className="btn draft btn-mui fix-w-12em"
              />
            </Box>
            <Box p={0}>
              <Controls.Button
                type="submit"
                disableElevation
                text="Next"
                onClick={() => clickOnNext()}
                className="btn-mui fix-w-12em"
              />
            </Box>
          </Grid>
        </Box>
      </div>

      <ImportItemsPopup
        importItemsPopup={importItemsPopup}
        setImportItemsPopup={setImportItemsPopup}
        items={items}
        setItems={setItems}
        setNotify={setNotify}
      />

      <ConfirmationDailog
        openConfrmationDailog={openConfrmationDailog}
        setOpenConfrmationDailog={setOpenConfrmationDailog}
        setDiscardAndClose={setOpenPopupStep4}
        eraseEverything={false}
      />
    </>
  );
};

export default Step4Form;
