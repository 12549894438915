/*eslint-disable */
import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Appbars from "../../../components/Appbar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Copyright from "../../../components/Copyright";
import AuctionBanner from "./components/AuctionBanner";
import { Grid } from "@material-ui/core";
import GeneralInfo from "./components/details/GeneralInfo";
import Items from "./components/details/Items";
import BidList from "./components/details/BidList";
import LiveBestBid from "./components/details/LiveBestBid";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import "./styles/details-index.css";
import { useParams } from "react-router-dom";
import GetAuctionByIdBL from "./BL/GetAuctionByIdBL";
import {
  getCurrentAuction,
  shouldRefreshLiveAuctionSupplier,
  shouldRefreshLiveBiddingData,
} from "../../../store/selectors/supplierAspect/auctionSelector";
import {
  refreshLiveAuctionSupplier,
  refreshLiveBiddingDataSupplier,
  setAuctionDetails,
} from "../../../store/actions/supplierAspect/auctionAction";
import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setHTTPError } from "../../../store/actions/errorHandlerAction";
const UnAuthorizedRoute = "/error/403";

const SupplierAuctionDetails = (props) => {
  const { refresLiveBiddingData, refreshLiveAuction } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadding, setLoadding] = useState(true);
  const [resendApi, setResendApi] = useState(true);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(async () => {
    if (resendApi == true) {
      const data = await GetAuctionByIdBL({ id: id });
      if (data.success === true) {
        dispatch(setAuctionDetails(data.auction));
      } else {
        if (data.code === 403) {
          dispatch(
            setHTTPError({
              code: 403,
              message: data.message,
            })
          );
          history.push(UnAuthorizedRoute);
        }
      }
      setLoadding(false);
      setResendApi(false);
    }
  }, [resendApi]);

  useEffect(() => {
    if (refresLiveBiddingData == true) {
      dispatch(refreshLiveBiddingDataSupplier(false));
    }
  }, [refresLiveBiddingData]);

  useEffect(() => {
    if (refreshLiveAuction == true) {
      setResendApi(true);
      dispatch(refreshLiveAuctionSupplier(false));
    }
  }, [refreshLiveAuction]);
  const getAuctionDetails = async () => {
    const data = await GetAuctionByIdBL({ id: id });
    if (data.success === true) {
      dispatch(setAuctionDetails(data.auction));
    } else {
      if (data.code === 403) {
        dispatch(
          setHTTPError({
            code: 403,
            message: data.message,
          })
        );
        history.push(UnAuthorizedRoute);
      }
    }
    setResendApi(false);
  };
  return (
    <>
      {loadding && <Loader />}
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <Appbars> </Appbars>
        <main className="app-main-section-container">
          <Container maxWidth="lg">
            <AuctionBanner
              setNotify={setNotify}
              refreshLiveAuction={refreshLiveAuction}
              refresLiveBiddingData={refresLiveBiddingData}
            />
            <Grid
              container
              spacing={4}
              className="supp-auction-details-container"
            >
              <Grid item aria-label="general-info" md={6}>
                <GeneralInfo refresLiveBiddingData={refresLiveBiddingData} />
              </Grid>
              <Grid item aria-label="item-bid-list" md={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Items refresLiveBiddingData={refresLiveBiddingData} />
                  </Grid>
                  <Grid item xs={12}>
                    <BidList refresLiveBiddingData={refresLiveBiddingData} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item aria-label="live-bid" md={6}>
                <LiveBestBid
                  setNotify={setNotify}
                  refresLiveBiddingData={refresLiveBiddingData}
                  setResendApi={setResendApi}
                  getAuctionDetails={getAuctionDetails}
                />
              </Grid>
            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
          <Notification notify={notify} setNotify={setNotify} />
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentAuction: getCurrentAuction(state),
    refresLiveBiddingData: shouldRefreshLiveBiddingData(state),
    refreshLiveAuction: shouldRefreshLiveAuctionSupplier(state),
  };
};

export default withRouter(connect(mapStateToProps)(SupplierAuctionDetails));
