import { Box, Grid, Paper } from "@material-ui/core";
import {
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  Modal,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useEffect } from "react";
import useWindowDimensions from "../../../../components/useWindowDimensions";
import TableHeadOptionList from "../../../../components/TableHeadOptionList";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import TableOptionList from "../../../../components/TableOptionList";
import TablePaginationBox from "../../../../components/TablePaginationBox";
import CompanyAttachDocPopup from "./companyAttachDocPopup";
import CompanyAttachLinkPopup from "./companyAttachLinkPopup";
import { store } from "../../../../store/store";
import Moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Notification from "../../../../components/Notification";
import usePrevious from "../../../../components/usePrevious";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "../../../../assets/icons/search-gray.png";
import AddFromLibrary from "./addFromLibrary";
import Controls from "../../../../components/controls/Controls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  isAdmin,
  isBuyer,
  isSeller,
} from "../../../../store/selectors/RoleSelector";
import { getCurrentRole } from "../../../../store/selectors/AuthorSelector";
import { Api } from "../../../../api/Api";
import {
  DELETE_ATTACHMENT_API,
  GET_SUPPLIER_COMPANY_ATTACHMENT_API,
} from "../../../../constants/ApiUrl";
import { TOAST_AUTO_CLOSE } from "../../../../constants/AppConstants";
import { ValidationMessage } from "../../../../constants/ValidationMessage";
import { toast, Slide } from "react-toastify";

const columns = [
  {
    label: "Attachment",
  },
  {
    label: "Name",
    // minWidth: 100
  },
  {
    label: "Notes",
  },
  {
    label: "Added date",
  },
  {
    label: "",
  },
];
const blankSupplier = {};
const CompanyAttachments = (props) => {
  const { setFullLoder } = props;
  const { xsScreen } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [list, setList] = React.useState([]);
  const [limit, setLimit] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [numOfPages, setNumOfPages] = React.useState(0);
  const [documentPopup, setDocumentPopup] = React.useState(false);
  const [linkPopup, setLinkPopup] = React.useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = React.useState({});
  const [alertDailog, setAlertDailog] = React.useState(false);
  const [editAttachmentData, setEditAttachmentData] = React.useState({});
  const [attachmentIsEdit, setAttachmentIsEdit] = React.useState(false);
  const [editLinkData, setEditLinkData] = React.useState({});
  const [linkIsEdit, setLinkIsEdit] = React.useState(false);
  const handleClose = () => setAlertDailog(false);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopupLib, setOpenPopupLib] = React.useState(false);
  const prevPageValue = usePrevious(page);

  const getAllDocuments = async (searchText) => {
    setFullLoder(true);
    const { auth } = store.getState();
    const companyId = auth?.auth?.user?.company?._id;
    let queryString = "";
    if (searchText) {
      queryString = `&search=${searchText}`;
    }
    if (companyId) {
      const response = await Api(
        `${GET_SUPPLIER_COMPANY_ATTACHMENT_API}/${companyId}?per_page=${rowsPerPage}&page=${page}${queryString}`,
        {},
        "get"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          let companyAttachment = response?.data?.attachmentsList;
          setList(companyAttachment);
          setLimit(response?.data?.totalRecord);
        } else {
          setList([]);
          setLimit(0);
        }
        setFullLoder(false);
      } else {
        setList([]);
        setLimit(0);
        setFullLoder(false);
      }

      // axiosInstance
      //   .get(
      //     `api/company/supplierCompanyAttachment/${companyId}?per_page=${rowsPerPage}&page=${page}${queryString}`
      //   )
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       let companyAttachment = response?.data?.attachmentsList;
      //       setList(companyAttachment);
      //       setLimit(response?.data?.totalRecord);
      //     } else {
      //       setList([]);
      //       setLimit(0);
      //     }
      //     setFullLoder(false);
      //   })
      //   .catch((err) => {
      //     setList([]);
      //     setLimit(0);
      //     setFullLoder(false);
      //   });
    } else {
      setList([]);
      setFullLoder(false);
    }
  };

  useEffect(() => {
    if (prevPageValue !== undefined) {
      getAllDocuments();
    }
  }, [page]);

  const deleteDocument = async (attachment) => {
    if (attachment) {
      setAlertDailog(true);
      setDeleteAttachmentId(attachment);
    }
  };

  const confirmDeleteAttachment = async () => {
    if (deleteAttachmentId) {
      setFullLoder(true);
      const response = await Api(
        `${DELETE_ATTACHMENT_API}/${deleteAttachmentId._id}`,
        {},
        "delete"
      );
      if (response.status === 200) {
        if (response.data.responseCode === "00") {
          getAllDocuments();
          setAlertDailog(false);
          setDeleteAttachmentId({});
        }
        setFullLoder(false);
      } else {
        setFullLoder(false);
      }

      // axiosInstance
      //   .delete(`api/company/deleteCompanyAttachment/${deleteAttachmentId._id}`)
      //   .then((response) => {
      //     if (response.data.responseCode === "00") {
      //       getAllDocuments();
      //       setAlertDailog(false);
      //       setDeleteAttachmentId({});
      //       setNotify({
      //         isOpen: true,
      //         message: "Attachment removed successfully.",
      //         type: "success",
      //       });
      //     }
      //     setFullLoder(false);
      //   })
      //   .catch((err) => {
      //     setNotify({
      //       isOpen: true,
      //       message: err?.response?.data?.responseDescription,
      //       type: "error",
      //     });
      //     setFullLoder(false);
      //   });
    }
  };

  const editRecord = (row) => {
    if (row && row?.attachment) {
      setEditAttachmentData(row);
      setAttachmentIsEdit(true);
      setDocumentPopup(true);
      setAnchorEl(null);
    } else if (row && row?.link) {
      setEditLinkData(row);
      setLinkIsEdit(true);
      setLinkPopup(true);
      setAnchorEl(null);
    } else {
      toast.error(ValidationMessage.updateDocument, {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });
    }
  };

  const getSearchString = (e) => {
    const searchText = e.target.value;
    getAllDocuments(searchText);
  };

  useEffect(() => {
    setNumOfPages(Math.ceil(limit / rowsPerPage));
  }, [limit]);

  useEffect(() => {
    getAllDocuments();
  }, []);

  return (
    <Paper
      square
      style={{ marginTop: "5em", padding: "24px", borderRadius: "12px" }}
    >
      <Grid container className="settings-company-section-contaier">
        <Grid item md={4} sm={5} xs={12}>
          <p
            className="profile-title"
            style={{ borderBottomColor: "transparent" }}
          >
            Attachments
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          style={{ textAlign: xsScreen ? "center" : "start" }}
        >
          <TextField
            onChange={getSearchString}
            placeholder="Search Using Keyword or Tags..."
            // className="select-box select-box-primary search-box"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} style={{ width: "20px" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          style={{ textAlign: xsScreen ? "center" : "end" }}
        >
          <Button
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className="btn-mui-table-option"
            endIcon={<KeyboardArrowDownIcon />}
          >
            <AddOutlinedIcon style={{ fontSize: "20px" }} />
            <span>Add Attachment</span>
          </Button>
          <TableHeadOptionList
            id="demo-customized-menu"
            MenuListProps={{
              "aria-label": "company-settings-attachments-option-list",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setDocumentPopup(true);
              }}
              disableRipple
            >
              Attach Document
            </MenuItem>

            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenPopupLib(true);
              }}
              disableRipple
            >
              Add From Library
            </MenuItem>

            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setLinkPopup(true);
              }}
              disableRipple
            >
              URL Link
            </MenuItem>
          </TableHeadOptionList>
        </Grid>
      </Grid>

      <TableContainer className="table-container" component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "700" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item) => (
              <TableRow key={item._id}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ textAlign: "start" }}
                >
                  <div>
                    {item && item.link ? (
                      <LinkOutlinedIcon
                        style={{ color: "#A19B9D", verticalAlign: "middle" }}
                      />
                    ) : (
                      <FilePresentOutlinedIcon
                        style={{ color: "#A19B9D", verticalAlign: "middle" }}
                      />
                    )}
                    <span
                      style={{
                        color: "#2170FF",
                        marginLeft: "1em",
                        verticalAlign: "bottom",
                      }}
                    >
                      {/* <a src={item.link}>{item.file}</a> */}
                      {item && item?.attachment
                        ? item?.attachment?.name
                        : item && item?.link
                        ? item?.link
                        : item && item?.doc
                        ? item?.doc?.document?.name
                        : ""}
                    </span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  {item?.name || "-"}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item?.note || "-"}
                </TableCell>
                <TableCell component="th" scope="row">
                  {Moment(item?.createdDate).format("MMMM DD, YYYY")}
                </TableCell>
                <TableCell>
                  <Box>
                    <PopupState variant="popover" popupId="option-list-menu">
                      {(popupState) => (
                        <div>
                          <Button
                            className="action-btn"
                            variant="contained"
                            {...bindTrigger(popupState)}
                          >
                            <MoreVertIcon />
                          </Button>
                          <TableOptionList {...bindMenu(popupState)}>
                            <MenuItem
                              className="bold-line-600"
                              onClick={() => {
                                editRecord(item);
                                popupState.close();
                              }}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              className="bold-line-600"
                              style={{ color: "red" }}
                              onClick={() => deleteDocument(item)}
                            >
                              Delete
                            </MenuItem>
                          </TableOptionList>
                        </div>
                      )}
                    </PopupState>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {list.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} className="text-center">
                  No records found <br />
                  {/* <i style={{ color: "var(---2170ff-p-)" }}>
                    Please add some attachemnt
                  </i> */}
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePaginationBox
          label={limit + " Attachments Found"}
          numOfPages={numOfPages}
          onChangePage={(event, newPage) => setPage(newPage)}
          page={page}
        />
      </TableContainer>
      <Notification notify={notify} setNotify={setNotify} />

      {/* add Document attachment  */}
      <Modal
        open={documentPopup}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <div className="title">Attach Document</div>
          <CompanyAttachDocPopup
            openPopup={documentPopup}
            setOpenPopup={setDocumentPopup}
            getAllDocuments={getAllDocuments}
            attachmentIsEdit={attachmentIsEdit}
            editAttachmentData={editAttachmentData}
            setEditAttachmentData={setEditAttachmentData}
            setAttachmentIsEdit={setAttachmentIsEdit}
            setNotify={setNotify}
            isFromSupplier={false}
            supplier={blankSupplier}
            setFullLoder={setFullLoder}
          />
        </Box>
      </Modal>
      {/* add Document attachment  */}

      {/* add link image attachment  */}
      <Modal
        open={linkPopup}
        aria-label="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-form-container">
          <div className="title">Add Link</div>
          <CompanyAttachLinkPopup
            openPopup={linkPopup}
            setOpenPopup={setLinkPopup}
            getAllDocuments={getAllDocuments}
            setNotify={setNotify}
            editLinkData={editLinkData}
            linkIsEdit={linkIsEdit}
            setEditLinkData={setEditLinkData}
            setLinkIsEdit={setLinkIsEdit}
            isFromSupplier={false}
            supplier={blankSupplier}
            setFullLoder={setFullLoder}
          />
        </Box>
      </Modal>
      {/* add link image attachment  */}

      {/* add from library  */}
      <Modal
        open={openPopupLib}
        aria-label="attachment-from-lib-company-settings-buyer-aspect"
        aria-describedby="attachment-from-lib-company-settings-buyer-aspect-description"
      >
        <Box className="popup-form-container">
          <Grid container>
            <Grid item xs={9}>
              <p
                className="profile-title"
                style={{ borderBottomColor: "transparent" }}
              >
                Library
              </p>
            </Grid>
            <Grid item xs={3} className="text-end">
              <CloseIcon
                onClick={() => {
                  setOpenPopupLib(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          {/* <div className="title"> Library </div> */}
          <AddFromLibrary
            setOpenPopupLib={setOpenPopupLib}
            setNotify={setNotify}
            getAllAttachments={getAllDocuments}
            isFromSupplier={false}
            supplier={blankSupplier}
            setFullLoder={setFullLoder}
          />
        </Box>
      </Modal>
      {/* add from library  */}

      <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // open={alertDailog}
        // onClose={handleClose}
        // aria-label="alert-dialog-title"
        // aria-describedby="alert-dialog-description"
      >
        <div className="auction-confirm-dailog-main-div">
          <DialogTitle
            id="alert-dialog-title"
            className="auction-confirm-dailog-title"
          >
            Are you sure to delete attachment?
          </DialogTitle>

          <Grid container className="mt-20" justifyContent="space-between">
            <Grid item md={4} xs={12}>
              <Controls.Button
                text="Confirm"
                className="btn btn-primary btn-mui color-red w-100"
                variant="contained"
                onClick={() => confirmDeleteAttachment()}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Controls.Button
                text="Cancel"
                className="btn btn-primary btn-mui draft w-100"
                variant="contained"
                onClick={() => setAlertDailog(false)}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>

      {/* <Dialog
        open={alertDailog}
        onClose={handleClose}
        aria-label="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete attachment?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setAlertDailog(false)}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={() => confirmDeleteAttachment()}>Confirm</Button>
        </DialogActions>
      </Dialog> */}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
  };
};
export default withRouter(connect(mapStateToProps)(CompanyAttachments));
