import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { BUYER_PERMISSIONS } from "../store/constant/buyerGuard";
import { SELLER_PERMISSIONS } from "../store/constant/sellerGuard";
import { getCurrentRole } from "../store/selectors/AuthorSelector";
import { currentUser, isAuthenticated } from "../store/selectors/AuthSelectors";
import { isAdmin, isBuyer, isSeller } from "../store/selectors/RoleSelector";
import { LIST_PERMISSION_KEY } from "../store/constant/authorizationConstant";
import { setHTTPError } from "../store/actions/errorHandlerAction";
const AuthGaurd = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const UnAuthorizedRoute = "/error/403";
  const { isAdmin, currentRole, children, currentUser } = props;
  const ErrorMessage = "Sorry: You Are Not Autherized To Access This !";
  const ErrorCode = 403;

  useEffect(() => {
    //   check all routes accessibility
    switch (location.pathname) {
      case "/auction":
        if (!hasPermission(currentUser.role, "auction", LIST_PERMISSION_KEY)) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/auction/details":
        if (
          !hasPermission(
            currentUser.role,
            "auction_details",
            LIST_PERMISSION_KEY
          )
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/auction/details/live":
        if (
          !hasPermission(
            currentUser.role,
            "auction_details",
            LIST_PERMISSION_KEY
          )
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/companyusers":
        if (
          !hasPermission(
            currentUser.role,
            "company_users",
            LIST_PERMISSION_KEY
          ) ||
          !isAdmin
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/dashboard":
        if (
          !hasPermission(currentUser.role, "dashboard", LIST_PERMISSION_KEY)
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/items":
        if (!hasPermission(currentUser.role, "item", LIST_PERMISSION_KEY)) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/projects":
        if (!hasPermission(currentUser.role, "project", LIST_PERMISSION_KEY)) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/project/details":
        if (
          !hasPermission(
            currentUser.role,
            "project_details",
            LIST_PERMISSION_KEY
          )
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/rfx":
        if (!hasPermission(currentUser.role, "rfx", LIST_PERMISSION_KEY)) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/settings":
        if (!hasPermission(currentUser.role, "settings", LIST_PERMISSION_KEY)) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/suppliers":
        if (!hasPermission(currentUser.role, "supplier", LIST_PERMISSION_KEY)) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      // case "/supplier/dashboard":
      //   if (!hasPermission(currentRole, "supplier_dashboard", LIST_PERMISSION_KEY)) {
      //     dispatch(setHTTPError({
      //       code: ErrorCode,
      //       message: ErrorMessage,
      //     }));
      //     history.push(UnAuthorizedRoute);;
      //   }
      //   break;

      case "/supplier/rfx":
        if (
          !hasPermission(currentUser.role, "supplier_rfx", LIST_PERMISSION_KEY)
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/supplier/settings":
        if (
          !hasPermission(
            currentUser.role,
            "supplier_settings",
            LIST_PERMISSION_KEY
          )
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/supplier/companyusers":
        if (
          !hasPermission(
            currentUser.role,
            "supplier_company_users",
            LIST_PERMISSION_KEY
          ) ||
          !isAdmin
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/supplier/auction":
        if (
          !hasPermission(
            currentUser.role,
            "supplier_auction",
            LIST_PERMISSION_KEY
          )
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      case "/supplier/auction/details":
        if (
          !hasPermission(
            currentUser.role,
            "supplier_auction",
            LIST_PERMISSION_KEY
          )
        ) {
          dispatch(
            setHTTPError({
              code: ErrorCode,
              message: ErrorMessage,
            })
          );
          history.push(UnAuthorizedRoute);
        }
        break;

      default:
        break;
    }
  }, [location.pathname]);

  return children;
};

export function hasPermission(role, route, action) {
  if (role === "buyer") {
    return BUYER_PERMISSIONS[route]
      ? BUYER_PERMISSIONS[route][action]
        ? BUYER_PERMISSIONS[route][action]
        : false
      : false;
  } else if (role === "supplier" || role === "seller") {
    return SELLER_PERMISSIONS[route]
      ? SELLER_PERMISSIONS[route][action]
        ? SELLER_PERMISSIONS[route][action]
        : false
      : false;
  } else if (role === "super_admin") {
    return true;
  } else if (role === "both") {
    return true;
  }
  return false;
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isAdmin: isAdmin(state),
    isSeller: isSeller(state),
    isBuyer: isBuyer(state),
    currentRole: getCurrentRole(state),
    currentUser: currentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(AuthGaurd));
